import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const StyledPeriodUpdateChip = styled(ActionChip)`
  .PeriodUpdateChip {
    &-arrow {
      padding: 0;
      pointer-events: none;
    }
  }
  ${ComponentStyles}
`;

StyledPeriodUpdateChip.propTypes = {
  theme: PropTypes.object
};

StyledPeriodUpdateChip.defaultProps = {
}

export default StyledPeriodUpdateChip;
