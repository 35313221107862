import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Accordion from '@mui/material/Accordion';

const StyledAccordion = styled(Accordion)`
  &.Accordion-density-dense {
    .AccordionSummary {
      padding: 0;
      min-height: ${props => props.theme.layout(36)};
      .MuiAccordionSummary-content {
        margin: ${props => props.theme.layout('1sp')} 0;
      }
      .MuiAccordionSummary-expandIconWrapper {
        color: inherit;
      }

      ${props => props.theme.state2Selector('AccordionSummary', null, ['focusVisible'])} {
        background-color: unset;
        color: ${props => props.theme.property('palette.primary.main')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledAccordion.propTypes = {
  theme: PropTypes.object
};

StyledAccordion.defaultProps = {}

export default StyledAccordion;
