import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntitySectionPanel
  from 'components/organisms/SectionPanels/DatabaseEntitySectionPanel/DatabaseEntitySectionPanel.styles';

const DatabaseEntitySectionPanel = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitySectionPanel');

  // const profileProvider = useProfile();
  // const sectionDefinitions = profileProvider?.sectionDefinitions;
  // const Entity = profileProvider?.data?.data;
  // const collection = profileProvider?.context?.data;
  //
  //const authorize = useAuthorize();

  const sections = useMemo(() => {
    return [];
    // todo: implement later
    // const sections = [];
    // if (sectionDefinitions) {
    //   sectionDefinitions
    //     .sort((a, b) => a.position - b.position)
    //     .forEach((sectionDef) => {
    //       const canEditSection = (entity) => {
    //         return !(sectionDef.readOnly ?? false) && authorize({...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, entity}});
    //       }
    //     });
    // }
    //
    // return sections;
  }, [/*authorize, sectionDefinitions, Entity, collection*/]);

  return <StyledDatabaseEntitySectionPanel ref={ref} {...innerProps}
                                           sections={sections}/>
});

DatabaseEntitySectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DatabaseEntitySectionPanel.defaultProps = {};

export default DatabaseEntitySectionPanel;
