import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAccordion from 'components/atoms/Accordions/Accordion/Accordion.styles';

const Accordion = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Accordion');

  return <StyledAccordion ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledAccordion>
});

Accordion.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Accordion.defaultProps = {};

export default Accordion;
