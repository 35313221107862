import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTeamMembersAvatarGroup
  from 'components/molecules/Avatars/TeamMembersAvatarGroup/TeamMembersAvatarGroup.styles';
import utils from 'helpers/utils';
import AvatarGroup from 'components/atoms/Avatars/AvatarGroup/AvatarGroup';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';

const TeamMembersAvatarGroup = React.forwardRef((props, ref) => {
  const {
    max,
    teamMembers,
    action,
    size,
    isLoading,
    AvatarGroupProps,
    AvatarProps,
    ...innerProps
  } = useComponentProps(props, 'TeamMembersAvatarGroup');

  const renderTeamMemberAvatars = () => {
    return teamMembers?.map((tm, idx) => {
      const name = tm.profile ? `${utils.personName(tm.profile.firstName, tm.profile.lastName)} (${tm.profile.username})` : 'No name';
      return <ActionAvatar key={idx}
                           action={{
                             label: utils.avatarLabel(name),
                             tooltip: name
                           }}
                           size={size}
                           isLoading={isLoading}
                           color={utils.string2Color(name)}
                           TooltipProps={{enterDelay: 0}}/>
    });
  };

  const teamMemberAction = useMemo(() => {
    if (action) {
      return {
        icon: <Icon icon={Add} size="smaller"/>,
        ...action
      }
    }
  }, [action]);

  if (teamMembers) {
    return <StyledTeamMembersAvatarGroup ref={ref} {...innerProps}>
      <AvatarGroup max={max}
                   total={teamMembers?.length}
                   spacing="medium"
                   size={size}
                   {...AvatarGroupProps}>
        {renderTeamMemberAvatars()}
      </AvatarGroup>

      {teamMemberAction ? <ActionIconButton size={size === 'large' ? 'small' : 'smaller'}
                                            density="dense"
                                            color="default"
                                            variant="outlined"
                                            radius="circle"
                                            action={teamMemberAction}/> : null}
    </StyledTeamMembersAvatarGroup>
  }
});

TeamMembersAvatarGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  max: PropTypes.number,
  teamMembers: PropTypes.array,
  action: PropTypes.object,
  isLoading: PropTypes.bool,
  AvatarProps: PropTypes.object
};

TeamMembersAvatarGroup.defaultProps = {
  max: 5
};

export default TeamMembersAvatarGroup;
