import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTable from 'components/atoms/Tables/Table/Table.styles';

const Table = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Table');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTable ref={ref} {...innerProps}/>
});

Table.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Table.defaultProps = {
};

export default Table;
