import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import StyledPeriodUpdateChip from 'components/atoms/Chips/PeriodUpdateChip/PeriodUpdateChip.styles';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import {useComponentProps} from 'helpers/hooks/utils';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import utils from 'helpers/utils';

const PeriodUpdateChip = React.forwardRef((props, ref) => {
  const {
    count,
    period,
    action,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'PeriodUpdateChip');

  const actionMemo = useMemo(() => {
    const color = count !== 0 ? (count > 0 ? 'success' : 'error') : 'primary';

    return {
      ...action,
      label: count !== 0 ? (
        count > 0 ? `+${utils.formatNumber(count ?? 0)} this ${period}` : `${utils.formatNumber(count ?? 0)} this ${period}`
      ) : `no updates this ${period}`,
      color,
      ChipProps: {
        ...action.ChipProps,
        onDelete: count !== 0 ? () => null : null,
        deleteIcon: count !== 0 ? (
          <IconButton className="PeriodUpdateChip-arrow" size="small">
            {count > 0 ? <Icon size="smaller" icon={ArrowUpward}/> :
              <Icon size="smaller" icon={ArrowDownward}/>}
          </IconButton>
        ) : null
      }
    }
  }, [action, count, period])

  return <StyledPeriodUpdateChip ref={ref} {...innerProps}
                                 action={actionMemo}
                                 isLoading={isLoading}
                                 variant="transparent"
                                 showTooltip={false}/>
});

PeriodUpdateChip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  period: PropTypes.oneOf([constants.periods.week, constants.periods.month, constants.periods.year]),
  count: PropTypes.number,
  action: PropTypes.object,
  isLoading: PropTypes.bool
};
PeriodUpdateChip.defaultProps = {
  count: 0,
  period: 'week',
};

export default PeriodUpdateChip;
