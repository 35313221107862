import React from 'react';
import StyledTestPage2 from './TestPage2.styles';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';

const TestPage2 = (props) => {
  const innerProps = useComponentProps(props, 'TestPage2');

  return <StyledTestPage2 {...innerProps}>
    {'Test Page2 text'}
  </StyledTestPage2>
};

TestPage2.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TestPage2.defaultProps = {
};

export default TestPage2;
