import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import StyledWrapper from 'components/templates/Wrappers/Basic/Wrapper/Wrapper.styles';
import utils from 'helpers/utils';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';
import {withMemo} from 'helpers/wrapper';

export const WrapperContext = React.createContext(null)

export function useWrapper () {
  return React.useContext(WrapperContext);
}

const Wrapper = withMemo(React.forwardRef((props, ref) => {
  const {
    wrapper,
    reset,
    scroll,
    collapse,
    ...innerProps
  } = useComponentProps(props, 'Wrapper');

  // SCROLL: bounds are part of the content and scroll with the content so no extra margins needed
  // !SCROLL: fixed headers and footers you need margin to render and correctly stick in the right place
  // COLLAPSE: does not add to bounds because it's invisible
  // scroll margins are always applied to enable scrolling to the right spot
  // scrollBounds --- are used by floating objects like table to make sure it's scroll within the right box

  const dialog = useDialog();

  const toPixels = (rem) => {
    if (rem && rem.toString().endsWith('rem')) {
      return utils.rem2Pixel(rem, innerProps.theme.typography.htmlFontSize);
    } else {
      return rem ?? 0;
    }
  }

  const parentWrapper = useWrapper();
  const boundsPixels = {
    top: toPixels(wrapper?.bounds?.top),
    left: toPixels(wrapper?.bounds?.left),
    right: toPixels(wrapper?.bounds?.right),
    bottom: toPixels(wrapper?.bounds?.bottom)
  };
  const boundsRem = {
    top: innerProps.theme.layout(boundsPixels.top),
    left: innerProps.theme.layout(boundsPixels.left),
    right: innerProps.theme.layout(boundsPixels.right),
    bottom: innerProps.theme.layout(boundsPixels.bottom)
  };

  const boundsCumPixels = {...boundsPixels};
  boundsCumPixels.top += toPixels(parentWrapper?.boundsCum?.top);
  boundsCumPixels.left += toPixels(parentWrapper?.boundsCum?.left);
  boundsCumPixels.bottom += toPixels(parentWrapper?.boundsCum?.bottom);
  boundsCumPixels.right += toPixels(parentWrapper?.boundsCum?.right);

  const boundsCumRem = {
    top: innerProps.theme.layout(boundsCumPixels.top),
    left: innerProps.theme.layout(boundsCumPixels.left),
    right: innerProps.theme.layout(boundsCumPixels.right),
    bottom: innerProps.theme.layout(boundsCumPixels.bottom)
  };

  const scrollBoundsPixels = {
    top: toPixels(wrapper?.scrollBounds?.top),
    left: toPixels(wrapper?.scrollBounds?.left),
    right: toPixels(wrapper?.scrollBounds?.right),
    bottom: toPixels(wrapper?.scrollBounds?.bottom)
  };
  const scrollBoundsRem = {
    top: innerProps.theme.layout(scrollBoundsPixels.top),
    left: innerProps.theme.layout(scrollBoundsPixels.left),
    right: innerProps.theme.layout(scrollBoundsPixels.right),
    bottom: innerProps.theme.layout(scrollBoundsPixels.bottom)
  };

  const scrollBoundsCumPixels = {...scrollBoundsPixels};
  scrollBoundsCumPixels.top += toPixels(parentWrapper?.scrollBoundsCum?.top);
  scrollBoundsCumPixels.left += toPixels(parentWrapper?.scrollBoundsCum?.left);
  scrollBoundsCumPixels.bottom += toPixels(parentWrapper?.scrollBoundsCum?.bottom);
  scrollBoundsCumPixels.right += toPixels(parentWrapper?.scrollBoundsCum?.right);

  const scrollBoundsCumRem = {
    top: innerProps.theme.layout(scrollBoundsCumPixels.top),
    left: innerProps.theme.layout(scrollBoundsCumPixels.left),
    right: innerProps.theme.layout(scrollBoundsCumPixels.right),
    bottom: innerProps.theme.layout(scrollBoundsCumPixels.bottom)
  };

  const boundsMemo = useEffectItem(boundsRem);
  const boundsCumMemo = useEffectItem(boundsCumRem);
  const scrollBoundsMemo = useEffectItem(scrollBoundsRem);
  const scrollBoundsCumMemo = useEffectItem(scrollBoundsCumRem);
  const context = useMemo(() => ({
    bounds: boundsMemo,
    boundsCum: reset ? null : collapse ? parentWrapper.boundsCum : boundsCumMemo,
    scrollBounds: scrollBoundsMemo,
    scrollBoundsCum: reset ? null : scrollBoundsCumMemo,
    parentWrapper
  }), [reset, collapse, parentWrapper, boundsMemo, boundsCumMemo, scrollBoundsMemo, scrollBoundsCumMemo]);

  const isDialog = Boolean(dialog);
  const style = useMemo(() => ({
    marginTop: (reset || scroll || collapse) ? 0 : boundsRem.top,
    marginLeft: (reset || scroll || collapse) ? 0 : boundsRem.left,
    marginRight: (reset || scroll || collapse) ? 0 : boundsRem.right,
    marginBottom: (reset || scroll || collapse) ? 0 : boundsRem.bottom,

    scrollMarginTop: (reset) ? 0 : scrollBoundsCumRem.top,
    scrollMarginLeft: (reset) ? 0 : scrollBoundsCumRem.left,
    scrollMarginRight: (reset) ? 0 : scrollBoundsCumRem.right,
    scrollMarginBottom: (reset) ? 0 : scrollBoundsCumRem.bottom,
  }), [reset, scroll, collapse, boundsRem.top, boundsRem.left, boundsRem.right, boundsRem.bottom,
    scrollBoundsCumRem.top, scrollBoundsCumRem.left, scrollBoundsCumRem.right, scrollBoundsCumRem.bottom]);

  return <WrapperContext.Provider value={context}>
    <StyledWrapper ref={ref} {...innerProps} style={style} $dialog={isDialog}>
      {(wrapper || reset) ? innerProps.children : null}
    </StyledWrapper>
  </WrapperContext.Provider>
}));

Wrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wrapper: PropTypes.any,
  reset: PropTypes.bool,
  scroll: PropTypes.bool,
  collapse: PropTypes.bool
};

Wrapper.defaultProps = {
  children: 'Wrapper text'
};

export default Wrapper;
