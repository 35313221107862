import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDealflowEntitiesFilterWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesFiltersWrapper/DealflowEntitiesFiltersWrapper.styles';
import DealflowEntitiesTableFilters
  from 'components/organisms/TableFilters/DealflowEntitiesTableFilters/DealflowEntitiesTableFilters';

const DealflowEntitiesFiltersWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesFiltersWrapper');

  const tableProvider = useTable();

  return <StyledDealflowEntitiesFilterWrapper ref={ref} {...innerProps}
                                              variant="foldout"
                                              context={<DealflowEntitiesTableFilters />}
                                              open={Boolean(tableProvider.state?.showFilters)}
                                              size="oversize"
                                              ContextDrawerProps={{
                                                onToggle: (e, open) => {
                                                  if (open) {
                                                    tableProvider.openFilters();
                                                  } else {
                                                    tableProvider.closeFilters();
                                                  }
                                                }
                                              }}>
    {innerProps.children}
  </StyledDealflowEntitiesFilterWrapper>
});

DealflowEntitiesFiltersWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesFiltersWrapper.defaultProps = {
  children: 'DealflowEntitiesFiltersWrapper text',
};

export default DealflowEntitiesFiltersWrapper;
