import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Popper from '@mui/material/Popper';
import utils from 'helpers/utils';

const StyledContextPopper = styled(Popper)`
  display: flex;
  z-index: ${props => props.theme.property('zIndex.popper')};
  pointer-events: none;
  
  > .Paper {
    display: flex;
    flex-direction: column;
    pointer-events: all;
    
    > .Wrapper {
      height: 100%;
      width: 100%;
      max-height: inherit;
      max-width: inherit;
      overflow: hidden;
    }
    
    * {
      overscroll-behavior: contain;
    }
    
    ${props => utils.isDefined(props.$minWidth) ? css`
      width: ${props.theme.layout(props.$minWidth)} !important;
      min-width: ${props.theme.layout(props.$minWidth)} !important;
    ` : css``}

    ${props => utils.isDefined(props.$maxWidth) ? css`
      max-width: ${props.theme.layout(props.$maxWidth)} !important;
    ` : css``}

    ${props => utils.isDefined(props.$width) ? css`
      width: ${props.theme.layout(props.$width)} !important;
    ` : css``}
  }

  &[data-popper-placement="bottom"], &[data-popper-placement="top"] {
    justify-content: center;
  }
  
  &.ContextPopper-reverseShadow {
    &[data-popper-placement="bottom"], &[data-popper-placement="bottom-start"], &[data-popper-placement="bottom-end"] {
      > .Paper {
        box-shadow: ${props => props.theme.property(`shadows.${props.$elevation}`)};
      }
    }

    &[data-popper-placement="top"], &[data-popper-placement="top-start"], &[data-popper-placement="top-end"] {
      > .Paper {
        box-shadow: ${props => props.theme.invertShadow(props.$elevation)};
      }
    }
  }
  
  &.ContextPopper {
    > .Paper {
      outline: none;
      padding: ${props => props.theme.spacing(2)};
    }
  }

  &.ContextPopper-outlined {
    > .Paper {
      border: 1px solid ${props => props.theme.property('palette.divider')};
    }
  }
  
  &.ContextPopper-density-dense {
    > .Paper {
      padding: ${props => props.theme.spacing(1)};
    }
  }
  &.ContextPopper-density-denser {
    > .Paper {
      padding: ${props => props.theme.spacing(0.5)};
    }
  }
  &.ContextPopper-density-densest {
    > .Paper {
      padding: 0;
    }
  }

  &.ContextPopper-size-large {
    > .Paper {
      max-width: ${props => props.theme.layout(400)};
      max-height: min(${props => props.theme.layout(480)}, 52vh);
    }
  }
  &.ContextPopper-size-medium {
    > .Paper {
      max-width: ${props => props.theme.layout(320)};
      max-height: min(${props => props.theme.layout(340)}, 48vh);
    }
  }
  &.ContextPopper-size-small {
    > .Paper {
      max-width: ${props => props.theme.layout(240)};
      max-height: min(${props => props.theme.layout(280)}, 32vh);
    }
  }

  &.ContextPopper-stretch {
    > .Paper {
      width: 100%;
      max-width: unset;
    }
  }

  &.ContextPopper-transparent {
    > .Paper {
      background-color: transparent !important;
      border: unset !important;
      box-shadow: unset !important;
    }
  }

  &.ContextPopper-fixed {
    position: fixed !important;
  }
  
  ${ComponentStyles}
`;

StyledContextPopper.propTypes = {
  theme: PropTypes.object
};

StyledContextPopper.defaultProps = {
}

export default StyledContextPopper;
