import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledPeriodUpdateDashboardCard
  from 'components/organisms/Cards/PeriodUpdateDashboardCard/PeriodUpdateDashboardCard.styles';
import PeriodUpdateListItem from 'components/molecules/Lists/PeriodUpdateListItem/PeriodUpdateListItem';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {Database, Dealflow} from 'assets/icons';
import FolderSharp from '@mui/icons-material/FolderSharp';
import List from 'components/atoms/Lists/List/List';
import {useClientStatisticWeeklyUpdates} from 'services/client/statistic/statistic.utils';
import utils from 'helpers/utils';

const PeriodUpdateDashboardCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'PeriodUpdateDashboardCard');

  const [weeklyUpdates, setWeeklyUpdates] = useState(null);

  const [changes, totals, isLoading] = useClientStatisticWeeklyUpdates();

  const isStatsLoading = !utils.isDefined(weeklyUpdates?.totals) || isLoading;

  useEffect(() => {
    if (totals && changes) {
      setWeeklyUpdates({
        totals,
        changes
      });
    }
  }, [totals, changes]);

  const renderUpdatePeriodList = () => {
    const list = [];

    list.push(<PeriodUpdateListItem icon={<Icon icon={Database} />}
                                    count={weeklyUpdates?.totals?.databaseCount || 0}
                                    update={weeklyUpdates?.changes?.databaseCount}
                                    isLoading={isStatsLoading}
                                    action={{
                                      label: 'database',
                                      navigation: {
                                        to: '/database',
                                      }
                                    }} />);

    list.push(<PeriodUpdateListItem icon={<Icon icon={FolderSharp} />}
                                    count={weeklyUpdates?.totals?.collectionCount || 0}
                                    update={weeklyUpdates?.changes?.collectionCount}
                                    isLoading={isStatsLoading}
                                    action={{
                                      label: 'collections',
                                      navigation: {
                                        to: '/collections',
                                      }
                                    }} />)

    list.push(<PeriodUpdateListItem icon={<Icon icon={Dealflow}/>}
                                    count={weeklyUpdates?.totals?.dealflowCount || 0}
                                    update={weeklyUpdates?.changes?.dealflowCount}
                                    isLoading={isStatsLoading}
                                    action={{
                                      label: 'dealflow',
                                      navigation: {
                                        to: '/dealflow',
                                      }
                                    }}/>);

    return <List>
      {list?.length > 0 ? list.map((item, idx) => {
        return <React.Fragment key={idx}>{item}</React.Fragment>;
      }) : null}
    </List>
  };

  return <StyledPeriodUpdateDashboardCard ref={ref} {...innerProps} title="Here's what's happening">
    {renderUpdatePeriodList()}
  </StyledPeriodUpdateDashboardCard>
});

PeriodUpdateDashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool
};

PeriodUpdateDashboardCard.defaultProps = {};

export default PeriodUpdateDashboardCard;
