import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import ClientProfile from 'components/organisms/Profiles/ClientProfile/ClientProfile';
import Add from '@mui/icons-material/Add';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import StyledDealflowSettingPage from 'components/pages/Setting/DealflowSettingPage/DealflowSettingPage.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const info = 'Add and manage your deal flow groups and statuses';

const DealflowSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowSettingPage');

  const contentRef = useRef(null);

  const profileProvider = useProfile();
  const cardDefinitions = profileProvider.cardDefinitions;
  const [internalState, setInternalState] = useState({
    addNew: false,
    lastNew: null
  });

  const authorize = useAuthorize();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const handleShowCard = (card) => {
    if (internalState.addNew && utils.isDefined(internalState.lastNew)) {
      if (card.name.startsWith('statusGroup-new') && card.name !== internalState.lastNew) {
        setInternalState(utils.updater({addNew: false}, true));
      }
    }
    return card.name.startsWith('statusGroup') && (internalState.addNew || !card.name.startsWith('statusGroup-new'));
  };

  const handleCardClose = (e, reason, card) => {
    if (card.name.startsWith('statusGroup-new')) {
      setInternalState(utils.updater({addNew: false}, true));
    }
  };

  const action = useMemo(() => ({
    auth: utils.createAuth({attribute: 'client.statusGroup.create'}),
    label: 'Add group',
    icon: Add,
    iconPosition: 'start',
    onClick: (e) => {
      const newCard = cardDefinitions.find((c) => c.name.startsWith('statusGroup-new'));
      setInternalState(utils.updater({addNew: true, lastNew: newCard?.name}, true));
      e.preventDefault();
    },
    ButtonProps: {
      color: 'success'
    }
  }), [cardDefinitions]);

  const handleCanUpdate = () => {
    return authorize({attribute: 'settings.dealflow.update'});
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const columns = Math.max(1, Math.floor(bBox?.width ? ((bBox?.width ?? 0) / 286) : 4));

  return <StyledDealflowSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                    title="Deal flow"
                                    info={info}
                                    buttons={<ActionButton action={action} />}>
    <DndContext>
      <Box ref={contentRef} className="DealflowSettingPage-content">
        <ClientProfile columns={columns}
                       variant="grid"
                       sortable={true}
                       dragHandle={true}
                       onShowCard={handleShowCard}
                       forceEditNew={internalState.addNew}
                       onDirty={handleDirty}
                       onCanUpdate={handleCanUpdate}
                       ProfileCardProps={{
                         onClose: handleCardClose
                       }}/>
      </Box>
    </DndContext>
  </StyledDealflowSettingPage>
};

DealflowSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowSettingPage.defaultProps = {};

export default DealflowSettingPage;
