import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Edit from '@mui/icons-material/Edit';
import UserList from 'components/molecules/Lists/UserList/UserList';
import StyledUserContextCard from 'components/organisms/Cards/UserContextCard/UserContextCard.styles';

const UserContextCard = React.forwardRef((props, ref) => {
  const {
    user,
    proxies,
    onChange,
    canUpdate,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'UserContextCard', {
    static: ['canUpdate']
  });

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = (open) => {
    setOpen(open);
  }

  const handleSubjectClick = () => {
    setOpen((current) => !current);
  }

  const handleClick = (e, user) => {
    setOpen(false);
    utils.asPromise(onChange)(user)
      .catch(() => {
        setOpen(true);
      });

    e.preventDefault();
  }

  const avatar = user ? `${utils.personName(user.firstName, user.lastName)} (${user.username})` : 'Not assigned';
  const name = user ? utils.personName(user.firstName, user.lastName) : 'Not assigned';
  const color = utils.string2Color(avatar);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledUserContextCard ref={ref} {...innerProps}
                                isLoading={isLoading}>
    <Box ref={anchorRef} className="UserContextCard-content" onClick={canUpdate ? handleSubjectClick : null}>
      <Box className="UserContextCard-subject">
        <ActionAvatar action={{
                        label: utils.avatarLabel(avatar),
                        tooltip: avatar
                      }}
                      size="medium"
                      isLoading={isLoading}
                      color={color}
                      TooltipProps={{enterDelay: 0}}/>
        <Typography className="UserContextCard-name"
                    variant="body1"
                    min={8} max={20}
                    isLoading={isLoading}>{name}</Typography>
      </Box>
      {canUpdate ? <Icon className="UserContextCard-edit" icon={Edit} size="tiny"/> : null}
    </Box>
    <DropdownPopper anchorEl={anchorRef.current}
                    open={open}
                    fullWidth={true}
                    onToggle={handleToggle}
                    density="densest"
                    ContextPopperProps={{
                      size: 'small',
                      stretch: true,
                      density: 'densest'
                    }}>
      <UserList user={user}
                track={true}
                proxies={proxies}
                onClick={handleClick}/>
    </DropdownPopper>
  </StyledUserContextCard>
});

UserContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  user: PropTypes.object,
  proxies: PropTypes.bool,
  onChange: PropTypes.func,
  canUpdate: PropTypes.bool,
  isLoading: PropTypes.bool
};

UserContextCard.defaultProps = {
  proxies: true
};

export default UserContextCard;
