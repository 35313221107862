import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledColumnsDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(440)}, 86vw);
  }
  
  ${ComponentStyles}
`;

StyledColumnsDialog.propTypes = {
  theme: PropTypes.object
};

StyledColumnsDialog.defaultProps = {}

export default StyledColumnsDialog;
