import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityProfilePage
  from 'components/pages/Collection/Entity/CollectionEntityProfilePage/CollectionEntityProfilePage.styles';
import CollectionEntityProfile from 'components/organisms/Profiles/CollectionEntityProfile/CollectionEntityProfile';

const CollectionEntityProfilePage = (props) => {
  const innerProps = useComponentProps(props, 'CollectionEntityProfilePage');

  return <StyledCollectionEntityProfilePage {...innerProps}>
    <CollectionEntityProfile />
  </StyledCollectionEntityProfilePage>
};

CollectionEntityProfilePage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityProfilePage.defaultProps = {
};

export default CollectionEntityProfilePage;
