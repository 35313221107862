import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';

const path = utils.servicePath(import.meta.url);

export class CollectionUploadService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      invalidateParent: true,
      refreshEnabled: false
    }, callbacks);
  }
}
