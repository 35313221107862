import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableFilters from 'components/organisms/TableFilters/TableFilters/TableFilters';

const StyledEntitiesTableFilters = styled(TableFilters)`
  ${ComponentStyles}
`;

StyledEntitiesTableFilters.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesTableFilters.defaultProps = {}

export default StyledEntitiesTableFilters;
