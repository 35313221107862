import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledOutlinedInput from 'components/atoms/Inputs/OutlinedInput/OutlinedInput.styles';
import {withMemo} from 'helpers/wrapper';

const OutlinedInput = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'OutlinedInput');

  return <StyledOutlinedInput ref={ref} {...innerProps} />
}));

OutlinedInput.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

OutlinedInput.defaultProps = {
};

export default OutlinedInput;
