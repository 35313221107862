import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useComponentProps} from 'helpers/hooks/utils';
import {H1, H2, P} from 'components/atoms/Text/Typography/Typography';
import StyledNotFoundPage from 'components/pages/Error/NotFoundPage/NotFoundPage.styles';

const NotFoundPage = (props) => {
  const innerProps = useComponentProps(props, 'NotFoundPage');

  const location = useLocation();
  const error = location.state?.error;

  // the response json is automatically parsed to
  // `error.data`, you also have access to the status
  return <StyledNotFoundPage {...innerProps}>
    <H1>Oops!</H1>
    <H2>This page was not found...</H2>
    {error ? [
      (error.statusCode && <P key={1}>{error.statusCode}</P>),
      (error.data?.message && <P key={2}>{error.data.message}</P>),
      (!error.data?.message && error.data && <P key={3}>{error.data}</P>)
    ] : null}
  </StyledNotFoundPage>
};

NotFoundPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

NotFoundPage.defaultProps = {
};

export default NotFoundPage;
