import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledPaper from 'components/atoms/Papers/Paper/Paper.styles';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

const Paper = withMemo(React.forwardRef((props, ref) => {
  const {
    fullWidth,
    fullHeight,
    ...innerProps
  } = useComponentProps(props, 'Paper', {
    static: ['fullWidth', 'fullHeight']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPaper ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledPaper>
}));

Paper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Paper.defaultProps = {
  children: 'Paper text'
};

export default Paper;
