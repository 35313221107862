import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledCollectionMergeDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(504)}, 86vh);
    max-height: clamp(0px, ${props => props.theme.layout(504)}, 86vh);
  }
  
  .DialogHeader {
    &-title-subtitle {
      margin-top: ${props => props.theme.layout(-1)} !important;
      
      .Typography {
        font-size: ${props => props.theme.fontSize(12)} !important;
      }
    }
  }
  
  .CollectionMergeDialog {
    &-content {
      height: 1px;
      padding: 0;
      overflow: visible;
      position: relative;
    }
    &-explanation {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('0.5sp')};
      margin-top: ${props => props.theme.spacing(2)};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      padding-top: ${props => props.theme.spacing(1)};

      .name {
        font-weight: 500;
      }
      .Typography {
        font-size: ${props => props.theme.fontSize(15)} !important;
      }
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledCollectionMergeDialog.propTypes = {
  theme: PropTypes.object
};

StyledCollectionMergeDialog.defaultProps = {}

export default StyledCollectionMergeDialog;
