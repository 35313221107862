import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableColumnsContent
  from 'components/organisms/TableColumns/TableColumnsContent/TableColumnsContent.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';

const TableColumnsContent = React.forwardRef((props, ref) => {
  const {
    tableColumns,
    columnGroup,
    columns,
    onChange,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.columnGroup, [
      'columns'
    ], false), ...props.columnGroup?.TableColumnsContentProps}, 'TableColumnsContent', {
    children: ['title', 'name', 'column']
  });

  const fields = useMemo(() => {
    return columnGroup.columns.map((column) => ({
      name: column.name,
      label: column.label,
      type: constants.formFieldTypes.checkbox,
      validation: constants.formFieldValidationTypes.boolean,
      conversion: constants.formFieldConversionTypes.none,
      initial: column.active,
      FormFieldProps: {
        autoFocus: false,
        size: 'small',
        InputProps: {
          density: 'dense'
        }
      }
    }));
  }, [columnGroup.columns]);

  const handleChangeDirect = (e) => {
    const column = columnGroup.columns.find((c) => c.name === e.target.name);
    onChange?.(column, e.target.value);
  }

  return <StyledTableColumnsContent ref={ref} {...innerProps} $rows={Math.ceil(fields.length / 3)}>
    <Typography variant="subtitle1">{columnGroup.title}</Typography>
    <InlineForm fields={fields}
                onChangeDirect={handleChangeDirect}/>
  </StyledTableColumnsContent>
});

TableColumnsContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tableColumns: PropTypes.object,
  columnGroup: PropTypes.object,
  columns: PropTypes.array,
  onChange: PropTypes.func
};

TableColumnsContent.defaultProps = {};

export default TableColumnsContent;
