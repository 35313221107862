import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledLoginForm = styled(Box)`
  .Form {
    &-page {
      .title {
        ${props => props.theme.font2Css('h4')};
        margin-bottom: ${props => props.theme.layout('4sp')};
      }
    }

    &-fields {
      display: flex;
      flex-wrap: wrap;
      column-gap: ${props => props.theme.layout('1sp')};
      row-gap: ${props => props.theme.layout('2sp')};
    }

    &-footer {
      margin-top: ${props => props.theme.layout('2sp')};

      Button {
        width: 100%;
        text-transform: none;
      }
    }
  }

  .auth-links {
    display: flex;
    margin-top: 1em;
    margin-bottom: 1em;

    > * {
      margin-right: 1em;
    }
  }

  ${ComponentStyles}
`;

StyledLoginForm.propTypes = {
  theme: PropTypes.object
};

StyledLoginForm.defaultProps = {}

export default StyledLoginForm;
