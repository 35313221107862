import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import utils from 'helpers/utils';
import {
  useAuth,
  useAuthIsProxy
} from 'services/auth/auth.utils';
import ActionList from 'components/molecules/Lists/ActionList/ActionList';
import {useAuthPassLogin, useAuthPassLogout} from 'services/auth/auth.hooks';
import StyledSwitchClientForm from 'components/organisms/Forms/SwitchClientForm/SwitchClientForm.styles';
import constants from 'helpers/constants';
import {useAuthPasses, useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const SwitchClientForm = (props) => {
  const {
    clientId,
    autoFocus,
    onError,
    onSuccess,
    onSwitching,
    ...innerProps
  } = useComponentProps(props, 'SwitchClientForm', {
    children: ['title', 'subtitle', 'clients']
  });

  const auth = useAuth();
  const user = useAuthUser();

  const passLogin = useAuthPassLogin();
  const passLogout = useAuthPassLogout();
  const passes = useAuthPasses();
  const isProxy = useAuthIsProxy();

  const initialised = useRef(false);
  const [internalState, setInternalState] = useState({ switched: false});

  const clientForm = useMemo(() => ({
    state: {
      ...internalState,
      ...utils.cleanObject({clientId})
    },
  }), [internalState, clientId])

  const onSwitchingEvent = useEffectEvent(onSwitching);
  const onErrorEvent = useEffectEvent(onError);
  useEffect(() => {
    if (utils.isDefined(clientForm.state.clientId) && !initialised.current) {
      initialised.current = true;
      if (!isProxy) {
        const pass = passes?.find((pass) => +pass.client.clientId === +clientForm.state.clientId);
        if (pass) {
          onSwitchingEvent?.(true);
          passLogin.mutation.mutate({userId: pass.user.userId});
        } else {
          onErrorEvent?.('Pass not found for client');
        }
      } else {
        onSwitchingEvent?.(true);
        setTimeout(() => {
          passLogout.mutation.mutate({authId: auth?.authId});
        }, constants.delay.minimal);
      }
    }
  }, [passLogin.mutation, passLogout.mutation, passes, clientForm.state.clientId,
    auth?.authId, isProxy, onSwitchingEvent, onErrorEvent]);

  useEffect(() => {
    if (initialised.current) {
      if (passLogin.status.error || passLogout.status.error) {
        onSwitchingEvent?.(false);
        onErrorEvent?.('Switching to client failed');
      }
    }
  }, [passLogin.status.error, passLogout.status.error, onErrorEvent, onSwitchingEvent]);

  const onSuccessEvent = useEffectEvent(onSuccess);
  useEffect(() => {
    const switched = +user?.clientId === +clientForm.state.clientId;
    if (switched) {
      onSuccessEvent?.();
    }
  }, [user?.clientId, clientForm.state.clientId, onSwitchingEvent, onSuccessEvent]);

  const actions = useMemo(() => {
    return (passes || []).reduce((a, pass) => {
      a.push({
        label: `${pass.client.name} [${utils.personName(pass.user.firstName, pass.user.lastName)}]`,
        onClick: () => {
          if (+pass.client.clientId !== +clientId) {
            setInternalState(utils.updater({clientId: +pass.client.clientId}, true));
          } else {
            onSuccessEvent?.();
          }
        },
        icon: <Avatar color="primary">{utils.avatarLabel(pass.client.name) ?? ''}</Avatar>,
        chip: +pass.client.clientId === +clientId ? 'Current' : null,
        chipPosition: 'end',
      });

      return a;
    }, []);
  }, [passes, clientId, onSuccessEvent]);

  return <StyledSwitchClientForm {...innerProps}>
    <ActionList className="SwitchClientForm-clients"
                ListProps={{
                  autoFocus
                }}
                ActionListItemProps={{
                  ListItemButtonProps: {
                    variant: 'directional',
                    direction: 'right',
                    color: 'primary'
                  }
                }}
                actions={actions} />
  </StyledSwitchClientForm>
};

SwitchClientForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  clientId: PropTypes.number,
  autoFocus: PropTypes.bool,
  onSwitching: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

SwitchClientForm.defaultProps = {};

export default SwitchClientForm;
