import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledEmailBarWrapper = styled(ToolbarWrapper)`
  height: 100vh;

  .PageBar-rightFields {
    order: 2;
    max-width: fit-content;
    
    .Form-fields {
      flex-direction: row;
      
      .FormField {
        width: ${props => props.theme.layout(240)};
      }
    }
  }
  .PageBar-rightSwitches {
    order: 1;
    margin-right: ${props => props.theme.spacing(4)};  
  }
  .PageBar-rightActions {
    order: 3;
  }
  
  .ToolbarWrapper-content {
    overflow: hidden;
  }
  
  .EmailBarWrapper {
    &-content {
      display: flex;
      height: 100%;
      width: 100%;
      padding: ${props => props.theme.spacing(3)};
      gap: ${props => props.theme.layout('2sp')};
    }
    
    &-original {
      flex: 1 1 calc(50% - ${props => props.theme.spacing(3 / 2)});
      overflow: auto;
    }
    
    &-preview {
      flex: 1 1 calc(50% - ${props => props.theme.spacing(3 / 2)});

      iframe {
        border: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEmailBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledEmailBarWrapper.defaultProps = {
};

export default StyledEmailBarWrapper;
