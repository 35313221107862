import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip.styles';
import utils from 'helpers/utils';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';

const ChartTooltip = React.forwardRef((props, ref) => {
  const {
    arrow,
    active,
    activateByPayload,
    activePayload,
    activeIndex,
    title,
    label,
    coordinate,
    payload,
    offset,
    wrapper,
    enterDelay,
    ...innerProps
  } = useComponentProps(props, 'ChartTooltip');

  const popperRef = useRef(null);
  const [open, setOpen] = useState(false);

  const wrapperBbox = useBbox(() => wrapper, ['left', 'top']);

  const payloads = activateByPayload ? payload?.filter((p) => `${p.dataKey}-${activeIndex}` === activePayload) : payload;
  const opening = Boolean(active && (payloads?.length > 0 || title) && wrapperBbox);

  const PopperProps = useMemo(() => ({
    popperRef,
    anchorEl: {
      getBoundingClientRect: () => {
        return new DOMRect(wrapperBbox?.left + coordinate?.x + (offset?.x ?? 0),
          wrapperBbox?.top + coordinate?.y + (offset?.y ?? 0), 0, 0);
      },
    },
    style: { pointerEvents: 'none' }
  }), [coordinate, wrapperBbox, offset]);

  const debouncedOpen = useMemo(() => {
    return utils.debounce((opening) => {
      setOpen(opening);
    }, enterDelay);
  }, [enterDelay]);

  const renderTitle = () => {
    return title ?? <Box className="title">
      {payloads?.map((p, idx) => {
        return <Box key={idx} className="payload">
          <Span>{`${label} (${p.dataKey})`}</Span>
          <Badge variant="inline"
                 color={p.color ?? 'primary'}
                 badgeContent={p.payload[p.dataKey]} />
        </Box>
      })}
    </Box>;
  }

  useEffect(() => {
    debouncedOpen(opening);
    if (!opening) {
      setOpen(false);
    }
  }, [debouncedOpen, opening]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledChartTooltip ref={ref} {...innerProps}
                             className={innerProps.className}
                             title={renderTitle()}
                             open={open}
                             arrow={arrow}
                             variant="light"
                             PopperProps={PopperProps}>
    <Box />
  </StyledChartTooltip>
});

ChartTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  arrow: PropTypes.bool,
  active: PropTypes.bool,
  activateByPayload: PropTypes.bool,
  activePayload: PropTypes.string,
  activeIndex: PropTypes.any,
  coordinate: PropTypes.object,
  payload: PropTypes.array,
  offset: PropTypes.number,
  wrapper: PropTypes.any
};

ChartTooltip.defaultProps = {
  arrow: true,
  enterDelay: 0
};

export default ChartTooltip;
