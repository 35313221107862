import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import StyledDealflowEntitiesTable
  from 'components/organisms/Tables/DealflowEntitiesTable/DealflowEntitiesTable.styles';

const DealflowEntitiesTable = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesTable');

  const authorize = useAuthorize();

  const handleCanUpdate = useCallback((entity, column) => {
    return authorize({attribute: 'dealflow.entity.update', meta: {entity}}) &&
      authorize(column.auth?.update ? {...column.auth?.update, meta: {
          ...column.auth?.update?.meta,
          entity
        }} : {})
  }, [authorize]);

  return <StyledDealflowEntitiesTable ref={ref} {...innerProps}
                                      onCanUpdate={handleCanUpdate}/>
});

DealflowEntitiesTable.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesTable.defaultProps = {
};

export default DealflowEntitiesTable;
