import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableFooter from '@mui/material/TableFooter';

const StyledTableFooter = styled(TableFooter)`
  ${ComponentStyles}
`;

StyledTableFooter.propTypes = {
  theme: PropTypes.object
};

StyledTableFooter.defaultProps = {
}

export default StyledTableFooter;
