import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSourceDatabaseSectionPanelContent
  from 'components/organisms/SectionPanels/SourceDatabaseSectionPanelContent/SourceDatabaseSectionPanelContent.styles';
import SourcesList from 'components/organisms/Lists/SourcesList/SourcesList';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import dom from 'helpers/dom';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';

const SourceDatabaseSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    databaseSources,
    search,
    onSearch,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'SourceDatabaseSectionPanelContent');

  const searchFieldRef = useRef(null);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller',
      debounce: constants.debounce.search
    },
    prefix: <Icon icon={Search} />,
    postfix: search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: () => {
        searchFieldRef.current?.clear();
        dom.focusElement(searchFieldRef.current);
      }
    }} /> : null
  }]), [search]);

  const handleClick = (source) => {
    onSubmit?.({...source, sourceId: source?.sourceId, type: source?.type});
  }

  const handleSearch = (field, value) => {
    onSearch?.(value);
  }

  const renderHeader = () => {
    return <InlineForm className="SourceDatabaseSectionPanelContent-search"
                       onChange={handleSearch}
                       fields={searchFields} />
  }

  return <StyledSourceDatabaseSectionPanelContent ref={ref} {...innerProps} header={renderHeader()}>
    <SourcesList sources={databaseSources}
                 onClick={handleClick}
                 isLoading={databaseSources.status.isLoading}
                 emptyText="No database sources found"
                 SourceCardProps={{
                   variant: 'suggested'
                 }}
                 ListProps={{
                   track: false
                 }}/>
  </StyledSourceDatabaseSectionPanelContent>
});

SourceDatabaseSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  databaseSources: PropTypes.object,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func
};

SourceDatabaseSectionPanelContent.defaultProps = {};

export default SourceDatabaseSectionPanelContent;
