import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCollectionsPage = styled(Page)`
  .CollectionsPage { 
    &-header {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      flex-wrap: nowrap;
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      
      .CollectionThemeGroupsSection {
        height: ${props => props.theme.layout(96)};
      }
      
      .CollectionsTableBar {
        background-color: transparent;
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledCollectionsPage.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsPage.defaultProps = {
};

export default StyledCollectionsPage;
