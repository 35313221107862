import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledBrowserBar from 'components/organisms/Bars/BrowserBar/BrowserBar.styles';

const BrowserBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'BrowserBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBrowserBar ref={ref} {...innerProps}/>
});

BrowserBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

BrowserBar.defaultProps = {
};

export default BrowserBar;
