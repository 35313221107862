import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledDealflowBreakdownGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;

  .GraphCard-content {
    padding-top: ${props => props.theme.spacing(1)};
  }
  
  .CardContent {
    .DealflowPieChart {
      &-chart {
        margin: 0 ${props => props.theme.spacing(5.5)};
      
        ${props => props.theme.breakpoints.down('lg')} {
          margin: 0 ${props => props.theme.spacing(0)};
        }
        ${props => props.theme.breakpoints.between('sm', 'md')} {
          margin: 0 ${props => props.theme.spacing(6)};
        }
      }
      
      &-center {
        ${props => props.theme.breakpoints.down('lg')} {
          transform: scale(0.75);
        }
        ${props => props.theme.breakpoints.down('md')} {
          transform: scale(1);
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowBreakdownGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledDealflowBreakdownGraphCard.defaultProps = {}

export default StyledDealflowBreakdownGraphCard;
