import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntityCard from 'components/molecules/Cards/EntityCard/EntityCard.styles';
import Logo from 'components/atoms/Logos/Logo/Logo';
import Typography from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import constants from 'helpers/constants';
import Chip from 'components/atoms/Chips/Chip/Chip';
import {DragHandleContext, useDragHandle} from 'components/organisms/Utils/DragDrop/DndContext';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {DragIndicator} from '@mui/icons-material';
import utils from 'helpers/utils';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import Link from 'components/atoms/Links/Link/Link';

const EntityCard = React.forwardRef((props, ref) => {
  const {
    entity,
    csi,
    relevancy,
    score,
    showScore,
    showLink,
    showRelevancy,
    showStatus,
    size,
    isLoading,
    onClick,
    dragging,
    dragHandle,
    DragHandleProps,
    ...innerProps
  } = useComponentProps(props, 'EntityCard', {
    children: ['logo', 'info', 'dealflowIcon', 'dragHandle'],
    static: ['dragging', 'isLoading']
  });

  const clickable = Boolean(onClick) && !dragging;
  const handleClick = (e) => {
    onClick?.(e, entity);
  }

  const dragHandleContext = useDragHandle(DragHandleContext);

  const relevancyDef = constants.data.lookup('hml', (relevancy ?? 0));

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityCard ref={ref} {...innerProps}
                           isLoading={isLoading}
                           onClick={clickable ? handleClick : null}>
    <Logo className="EntityCard-logo"
          density="sparse"
          size={size === 'small' ? 'small' : 'large'}
          outlined={true}
          logo={entity.logoUrl}
          altLogo={entity.altLogoUrl}
          fallbackIcon={HomeOutlined}
          isLoading={isLoading} />
    <Box className="EntityCard-info">
      <Typography variant={size === 'small' ? 'subtitle1' : 'subtitle2'}
                  showTooltip={true}
                  min={8} max={20}
                  isLoading={isLoading}>
        {entity.name}
      </Typography>
      {showLink && entity.website ? <Link href={utils.cleanExternalLink(entity.website)}
                                          onClick={(e) => e.stopPropagation()}
                                          target="_blank">
        {utils.displayExternalLink(entity.website)}
      </Link> : null}
      {showRelevancy && relevancyDef ? <Chip variant="transparent"
                                             size="small"
                                             color={relevancyDef.chipColor}
                                             label={relevancyDef.label}
                                             isLoading={isLoading}/> : null}
      {showScore ? <ScoreLinearProgress size="small"
                                        showEmpty={true} score={score ?? 0}
                                        isLoading={isLoading}/> : null}
    </Box>
    {showStatus && csi?.status ? <DealflowIcon className="EntityCard-dealflowIcon"
                                               size="smaller"
                                               statusId={csi?.status}
                                               isLoading={isLoading} />: null}
    {(dragHandle && dragHandleContext) ? <Box className="EntityCard-dragHandle">
      <Icon size="smaller"
            icon={DragIndicator}
            isLoading={isLoading}
            {...utils.filterObject(dragHandleContext, 'isDragging')}
            {...DragHandleProps}/>
    </Box> : null}
  </StyledEntityCard>
});

EntityCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  csi: PropTypes.object,
  relevancy: PropTypes.number,
  score: PropTypes.number,
  showScore: PropTypes.bool,
  showLink: PropTypes.bool,
  showRelevancy: PropTypes.bool,
  showStatus: PropTypes.bool,
  dragging: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'scoring', 'suggestion']), PropTypes.string])
};

EntityCard.defaultProps = {
  showRelevancy: true,
  showStatus: true,
  color: 'primary',
  variant: 'standard'
};

export default EntityCard;
