import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEmailBarWrapper from 'components/templates/Wrappers/Emails/EmailBarWrapper/EmailBarWrapper.styles';
import Download from '@mui/icons-material/Download';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Box from 'components/atoms/Layout/Box/Box';
import Paper from 'components/atoms/Papers/Paper/Paper';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import {useStyles} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import {useAuthorizeAction} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useLinkNavigate, useSplitPath} from 'helpers/hooks/links';
import {useClientControl} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import FileCopy from '@mui/icons-material/FileCopy';
import FolderZip from '@mui/icons-material/FolderZip';
import JSZip from 'jszip';

const EmailBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EmailBarWrapper');

  const zipRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [styled, setStyled] = useState(false);
  const [preview, setPreview] = useState({});
  const [client, setClient] = useState({});

  const styles = useStyles();
  const snackbar = useSnackbar();

  const clientControl = useClientControl();
  const navigate = useLinkNavigate();
  const authorizeAction = useAuthorizeAction();

  const [, pathPostfix] = useSplitPath('email');

  const getCleanContent = useCallback(() => {
    const frame = document.getElementsByTagName('body')[0].querySelector('.EmailBarWrapper-preview iframe');
    let content = '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">'
    content += frame.contentWindow.document.children[0].outerHTML;
    content = content.replace('{{PREVIEW_WHITE_SPACE}}',
      (new Array(32).fill('&zwnj; &nbsp; &shy; &zwnj; &nbsp;  &shy; &zwnj; &nbsp; &shy; &zwnj; &nbsp;  &shy;  &zwnj; &nbsp;   &shy; &zwnj;')).join(' ')
    );

    return content.replace(/([^0-9])(0rem)/ig, '$10').replace(/([^0-9])(0px)/ig, '$10');
  }, []);

  const layoutOptions = useMemo(() => ([
    {
      label: 'Welcome',
      navigation: {
        to: `/dev/email/welcome`,
        resetSearchParams: true
      }
    },
    {
      label: 'Reset password',
      navigation: {
        to: `/dev/email/reset`,
        resetSearchParams: true
      }
    },
    {
      label: 'New password',
      navigation: {
        to: `/dev/email/new`,
        resetSearchParams: true
      }
    },
    {
      label: 'Questionnaire',
      navigation: {
        to: `/dev/email/questionnaire`,
        resetSearchParams: true
      }
    },
    {
      label: 'Daily',
      navigation: {
        to: `/dev/email/daily`,
        resetSearchParams: true
      }
    },
    {
      label: 'Weekly',
      navigation: {
        to: `/dev/email/weekly`,
        resetSearchParams: true
      }
    },
    {
      label: 'Applicant',
      navigation: {
        to: `/dev/email/applicant`,
        resetSearchParams: true
      }
    },
  ].filter((action) => authorizeAction(action)).map((action) => {
    return {
      label: action.label,
      value: action.navigation.to.split('/').slice(-1)[0],
      navigation: action.navigation
    }
  })), [authorizeAction]);

  const layout = layoutOptions.find((opt) => opt.value === pathPostfix) ?? layoutOptions[0];

  const rightFields = useMemo(() => {
    return [{
      name: 'client',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a client',
      initial: client?.value ?? 'venture_iq',
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: [
        {
          label: 'Venture IQ',
          value: 'venture_iq',
          client: 'venture_iq',
          team: null
        },
        {
          label: 'Cemex Ventures',
          value: 'cemex_ventures',
          client: 'cemex_ventures',
          team: null
        }
      ]
    },
    {
      name: 'layout',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a layout',
      initial: layout,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: layoutOptions
    }];
  }, [layoutOptions, layout, client?.value]);

  const rightActions = useMemo(() => [
    {
      label: 'Copy',
      icon: ContentCopy,
      onClick: () => {
        navigator.clipboard.writeText(getCleanContent())
          .then(() => {
            snackbar.show('Copied to clipboard', null,
              {color: 'success', autoHideDuration: constants.delay.short});
          })
          .catch(() => {
            snackbar.show('Failed to copy', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          });
      }
    },
    {
      label: 'Export',
      icon: Download,
      ActionButtonProps: {
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            stretch: 'true',
            transparent: true,
            strategy: 'fixed',
            offset: [0, 8]
          }
        }
      },
      children: [
        {
          label: 'File',
          icon: FileCopy,
          onClick: () => {
            utils.textDownload(getCleanContent(), `${layout.label}.html`, 'text/html');
          }
        },
        {
          label: 'Zip',
          icon: FolderZip,
          onClick: () => {
            zipRef.current = {
              idx: 0,
              org: layoutOptions.findIndex((opt) => opt.value === layout.value),
              files: []
            };
            setPreview({});

            navigate(layoutOptions[0].navigation);
          }
        }
      ]
    }
  ], [snackbar, getCleanContent, layout, layoutOptions, navigate]);

  const rightSwitchActions = useMemo(() => [
    {
      label: 'Styled',
      active: styled,
      onClick: (e) => {
        setStyled((current) => !current);
        e.preventDefault();
      },
    }
  ], [styled]);

  const debouncedConvert = useMemo(() => {
    return utils.debounce(() => {
      const el = document.getElementsByTagName('body')[0].querySelector('.EmailBarWrapper-preview iframe');
      const body = el?.contentWindow?.document?.body;
      if (body) {
        dom.computedStyleToInlineStyle(
          body,
          [
            'padding', 'padding-left', 'padding-right', 'padding-top', 'padding-bottom',
            'margin', 'margin-left', 'margin-right', 'margin-top', 'margin-bottom',
            'min-width', 'max-width', 'min-height', 'max-height',
            'display', 'visibility', 'color', 'background-color',
            'font-size', 'font-weight', 'line-height', 'letter-spacing',
            'line-break', 'text-wrap', 'white-space', 'vertical-align', 'horizontal-align',
            'text-align',
            'outline', 'border-width', 'border-color', 'border.*radius', 'border-spacing',
            'cursor'
          ],
          {
            important: false,
            pixel2Rem: true,
            fontBase: styles.fontBase,
            removeClass: !styled,
            extra: [
              {
                tag: 'body',
                property: 'font-family'
              },
              {
                tag: 'body',
                property: 'font-size'
              },
              {
                tag: 'body',
                property: '-webkit-font-smoothing'
              },
              {
                tag: 'table',
                property: 'border-spacing',
                value: '0'
              },
              {
                tag: 'th',
                property: 'text-align',
                value: 'left'
              },
              {
                tag: 'li',
                property: 'text-align',
                value: 'left'
              },
              {
                tag: 'li',
                property: 'list-style-type'
              },
              {
                tag: 'a',
                property: 'box-sizing'
              },
              {
                tag: 'a',
                property: 'text-decoration',
                value: 'none',
                important: true
              }
            ],
            ignore: [
              /rgba\(0, 0, 0, 0\)/,
              /display: auto/,
              /visibility: visible/,
              /radius: (auto|0rem)/,
              /height: (none|0rem)/,
              /width: (none|0rem)/,
              /font-weight: (400|normal)/,
              /text-wrap: (auto|wrap)/,
              /line-height: (normal)/,
              /vertical-align: (middle|baseline)/,
              /text-align: (start|left)/,
              /radius: 0(px|rem)?$/,
              /min-height: 0(px|rem)?$/,
              /min-width: 0(px|rem)?$/,
              'cursor: auto',
              'letter-spacing: normal',
              'line-break: auto'
            ]
          });

        if (!styled) {
          Array.from(el.contentWindow.document.head.children).forEach((el) => {
            if (el.tagName.toLowerCase() === 'style') {
              el.remove();
            }
          });
        }

        setTimeout(() => {
          setPreview(utils.updater({converted: layout?.value}, true));
        }, constants.debounce.medium);
      }
    }, constants.debounce.shortest);
  }, [styled, styles.fontBase, layout?.value]);

  useEffect(() => {
    if (loaded) {
      const calcPreview = () => {
        const email = document.getElementsByTagName('body')[0].querySelector('.EmailBarWrapper-original .Email');
        const preview = email.querySelector('.preview');
        const table = email.querySelector('.Email-table');
        const hash = `${styled}_${utils.sha1(email.outerHTML)}`;

        let content = '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">';
        content += `<html style="font-size: ${styles.fontBase}px"><head>`;
        Array.from(document.getElementsByTagName('head')[0].getElementsByTagName('link')).forEach((l) => {
          if (l.rel === 'stylesheet') {
            content += l.outerHTML;
          }
        });
        Array.from(document.getElementsByTagName('head')[0].getElementsByTagName('style')).forEach((el) => {
          content += el.outerHTML;
        });

        const backgroundColor = dom.getComputedStyle(table).backgroundColor;
        content += `</head><body class="${email.className}" ${backgroundColor ? ` style="background-color: ${backgroundColor}"`: ''}>`;
        content += preview ? preview.outerHTML : '';
        content += table.outerHTML;
        content += '</body></html>';

        setPreview((current) => {
          if (hash !== current.hash) {
            debouncedConvert();
            return {hash, content, converted: false};
          } else {
            return current;
          }
        });
      };

      calcPreview();
      return utils.observeInterval(calcPreview, constants.debounce.short);
    }
  }, [loaded, styles.fontBase, styled, debouncedConvert]);

  useEffect(() => {
    if (zipRef.current && preview.converted === layout?.value) {
      const blob = new Blob(
        [getCleanContent()],
        {type: 'text/html'}
      );
      zipRef.current.files.push({layout, blob});

      if (zipRef.current.idx < layoutOptions.length - 1) {
        zipRef.current.idx = zipRef.current.idx + 1;
        navigate(layoutOptions[zipRef.current.idx].navigation);
      } else {
        const zip = new JSZip();
        zipRef.current.files.forEach((file) => {
          zip.file(`${file.layout.value}.html`, file.blob);
        });

        const back = (zipRef.current.org !== -1) ? layoutOptions[zipRef.current.org] : null;
        zipRef.current = null;

        zip.generateAsync({
          type: "blob",
          streamFiles: true,
        })
          .then((blob) => {
            utils.blobDownload(blob, `${client?.label ?? 'Venture IQ'}.zip`)
          })
          .finally(() => {
            if (back) {
              navigate(back.navigation);
            }
          })
      }
    }
  }, [preview.converted, client?.label, layout, layoutOptions, getCleanContent, navigate]);

  const handleRef = (el) => {
    if (el) {
      setLoaded(true);
    }
  }

  const handleFieldChange = (field, value) => {
    if (field.name === 'client') {
      setClient(value);
      clientControl.setOverride(value);
    } else {
      navigate(value.navigation);
    }
  }

  return <StyledEmailBarWrapper ref={ref} {...innerProps}
                                ToolbarProps={{
                                  title: 'Emails',
                                  rightFields,
                                  onRightChange: handleFieldChange,
                                  rightActions,
                                  rightSwitchActions
                                }}>
    <Box className="EmailBarWrapper-content">
      <Paper ref={handleRef} className="EmailBarWrapper-original">
        {innerProps.children}
      </Paper>
      <Paper className="EmailBarWrapper-preview">
        <iframe key={preview.hash}
                title="preview"
                srcDoc={preview.content} />
      </Paper>
    </Box>
  </StyledEmailBarWrapper>
});

EmailBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EmailBarWrapper.defaultProps = {
  children: 'CollectionsBar Wrapper text',
};

export default EmailBarWrapper;
