import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntityBrowserPage
  from 'components/pages/Database/Entity/DatabaseEntityBrowserPage/DatabaseEntityBrowserPage.styles';
import EntitiesBrowserList from 'components/organisms/Browsers/EntitiesBrowserList/EntitiesBrowserList';

const DatabaseEntityBrowserPage = (props) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityBrowserPage');

  return <StyledDatabaseEntityBrowserPage {...innerProps}>
    <EntitiesBrowserList />
  </StyledDatabaseEntityBrowserPage>
};

DatabaseEntityBrowserPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityBrowserPage.defaultProps = {
};

export default DatabaseEntityBrowserPage;
