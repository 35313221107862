import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledCheckbox = styled(Checkbox)`
  ${props => !(props.icon || props.checkedIcon) ? css`
    // see also Radio
    &.Checkbox:not(.Mui-checked) {
      svg {
        z-index: 1;
      }

      &:before {
        content: '';
        position: absolute;
        width: ${props => props.theme.layout(16)};
        height: ${props => props.theme.layout(16)};
        background-color: transparent;
      }

      &.Checkbox-size-smaller {
        &:before {
          width: ${props => props.theme.layout(12)};
          height: ${props => props.theme.layout(12)};
        }
      }

      &.Checkbox-size-small {
        &:before {
          width: ${props => props.theme.layout(14)};
          height: ${props => props.theme.layout(14)};
        }
      }

      &.Checkbox-size-large {
        &:before {
          width: ${props => props.theme.layout(22)};
          height: ${props => props.theme.layout(22)};
        }
      }
    }
  ` : null}
  
  &.Skeleton {
    &:before {
      content: unset !important;
    }
    
    width: ${props => props.theme.layout(40)};
    height: ${props => props.theme.layout(40)};

    &.Checkbox-density-denser {
      padding: ${props => props.theme.layout(30)};
    }
    &.Checkbox-density-dense {
      padding: ${props => props.theme.layout(34)};
    }

    &.Checkbox-size-smaller {
      width: ${props => props.theme.layout(32)};
      height: ${props => props.theme.layout(32)};
      
      &.Checkbox-density-denser {
        padding: ${props => props.theme.layout(26)};
      }
      &.Checkbox-density-dense {
        padding: ${props => props.theme.layout(28)};
      }
    }
    
    &.Checkbox-size-small {
      width: ${props => props.theme.layout(38)};
      height: ${props => props.theme.layout(38)};
      
      &.Checkbox-density-denser {
        padding: ${props => props.theme.layout(30)};
      }
      &.Checkbox-density-dense {
        padding: ${props => props.theme.layout(34)};
      }
    }

    &.Checkbox-size-large {
      width: ${props => props.theme.layout(48)};
      height: ${props => props.theme.layout(48)};
      
      &.Checkbox-density-denser {
        padding: ${props => props.theme.layout(38)};
      }
      &.Checkbox-density-dense {
        padding: ${props => props.theme.layout(42)};
      }
    }
  }

  &.Checkbox-size-medium {
    padding: ${props => props.theme.layout(8)};
  }
  
  &.Checkbox-size-smaller {
    svg {
      font-size: ${props => props.theme.fontSize(18.7)};
    }
  }
  
  &.Checkbox-density-denser {
    padding: ${props => props.theme.layout(4)};
  }
  &.Checkbox-density-dense {
    padding: ${props => props.theme.layout(6)};
  }
  
  ${ComponentStyles}
`;

StyledCheckbox.propTypes = {
  theme: PropTypes.object
};

StyledCheckbox.defaultProps = {
}

export default StyledCheckbox;
