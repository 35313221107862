import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper.styles';
import Wrapper, {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import ContextDrawer from 'components/organisms/Drawers/ContextDrawer/ContextDrawer';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';

export const ContextWrapperContext = React.createContext(null)

export function useContextWrapper () {
  return React.useContext(ContextWrapperContext);
}

const ContextWrapper = React.forwardRef((props, ref) => {
  const {
    context,
    anchor,
    variant,
    size,
    open,
    offset,
    animate,
    onToggle,
    ContextDrawerProps,
    ...innerProps
  } = useComponentProps(props, 'ContextWrapper', {
    static: ['open', 'animate'],
    variable: ['anchor'],
    children: ['content', 'drawer']
  });

  const dialog = useDialog();
  const parentWrapper = useWrapper();

  const innerRef = useRef(null);
  const drawerRef = useRef(null);
  const contextWrapper = useMemo(() => ({
    refs: {
      ref: innerRef,
      drawerRef
    }
  }), []);

  useImperativeHandle(ref, () => contextWrapper);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  let width = innerProps.theme.layout(280 + (offset ?? 0));
  switch (size) {
    case 'smaller':
      width = innerProps.theme.layout(224 + (offset ?? 0));
      break;
    case 'small':
      width = innerProps.theme.layout(280 + (offset ?? 0));
      break;
    case 'smallish':
      width = innerProps.theme.layout(298 + (offset ?? 0));
      break;
    case 'medium':
      width = innerProps.theme.layout(335 + (offset ?? 0));
      break;
    case 'large':
      width = innerProps.theme.layout(448 + (offset ?? 0));
      break;
    case 'larger':
      width = innerProps.theme.layout((mdUp ? 520 : 320) + (offset ?? 0));
      break;
    case 'largest':
      width = innerProps.theme.layout((mdUp ? 600 : 400) + (offset ?? 0));
      break;
    case 'huge':
      width = innerProps.theme.layout((mdUp ? 660 : 460) + (offset ?? 0));
      break;
    case 'oversize':
      width = innerProps.theme.layout((mdUp ? 696 : 496) + (offset ?? 0));
      break;
    default:
      width = utils.isNumber(size) ? innerProps.theme.layout(size + (offset ?? 0)) : innerProps.theme.layout(280);
  }

  if (!open) {
    width = variant === 'foldout' ? width : '0rem';
  }

  const wrapper = useMemo(() => {
    return {
      bounds: {
        left: variant === 'foldout' ? '0rem' : (anchor === 'left' ? width : '0rem'),
        right: variant === 'foldout' ? '0rem' : (anchor === 'right' ? width : '0rem')
      },
      scrollBounds: {
        left: variant === 'foldout' ? '0rem' : (anchor === 'left' ? width : '0rem'),
        right: variant === 'foldout' ? '0rem' : (anchor === 'right' ? width : '0rem')
      }
    };
  }, [anchor, width, variant]);

  const renderContent = () => {
    return <Wrapper className="ContextWrapper-content"
                    scroll={variant !== 'foldout'}
                    wrapper={wrapper}>
      {innerProps.children}
    </Wrapper>
  }

  const renderDrawer = () => {
    return <ContextDrawer ref={contextWrapper.refs.drawerRef}
                          className="ContextWrapper-drawer"
                          anchor={anchor}
                          width={width}
                          open={Boolean(open)}
                          variant={variant.includes('foldout', 'temporary') ? 'temporary' : 'persistent'}
                          onToggle={onToggle}
                          ModalProps={{
                            keepMounted: variant !== 'foldout'
                          }}
                          {...ContextDrawerProps}>
      {context}
    </ContextDrawer>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <ContextWrapperContext.Provider value={contextWrapper}>
    <StyledContextWrapper ref={contextWrapper.refs.ref} {...innerProps}
                          $dialog={Boolean(dialog)}
                          $wrapper={parentWrapper}>
      {anchor === 'left' ? renderDrawer() : renderContent()}
      {anchor === 'left' ? renderContent() : renderDrawer()}
    </StyledContextWrapper>
  </ContextWrapperContext.Provider>
});

ContextWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  context: PropTypes.any,
  anchor: PropTypes.string,
  size: PropTypes.any,
  open: PropTypes.bool,
  offset: PropTypes.number,
  animate: PropTypes.bool,
  onToggle: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'temporary', 'foldout']), PropTypes.string]),
  ContextDrawerProps: PropTypes.object
};

ContextWrapper.defaultProps = {
  anchor: 'right',
  variant: 'standard',
  animate: true
};

export default ContextWrapper;
