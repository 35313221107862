import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntityTimelineSectionPanelContent
  from 'components/organisms/SectionPanels/EntityTimelineSectionPanelContent/EntityTimelineSectionPanelContent.styles';

const EntityTimelineSectionPanelContent = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntityTimelineSectionPanelContent');

  return <StyledEntityTimelineSectionPanelContent ref={ref} {...innerProps}/>
});

EntityTimelineSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityTimelineSectionPanelContent.defaultProps = {};

export default EntityTimelineSectionPanelContent;
