import styled from 'styled-components';
import PropTypes from 'prop-types';
import FilledInput from '@mui/material/FilledInput';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledFilledInput = styled(FilledInput)`
  ${ComponentStyles}
`;

StyledFilledInput.propTypes = {
  theme: PropTypes.object
};

StyledFilledInput.defaultProps = {
}

export default StyledFilledInput;
