import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledResponsibleWizardContent
  from 'components/organisms/WizardContent/ResponsibleWizardContent/ResponsibleWizardContent.styles';
import constants from 'helpers/constants';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import comments from 'helpers/comment';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import {useAuthTeamId} from 'services/auth/auth.utils';
import {useClientCallbacks} from 'services/client/client.utils';

const ResponsibleWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    ...innerProps
  } = useComponentProps(props, 'ResponsibleWizardContent');

  const teamId = useAuthTeamId();
  const teamMemberOptions = useClientTeamMemberOptions(teamId, true, false);
  const clientCallbacks = useClientCallbacks();

  const fields = useMemo(() => {
    const responsible = utils.toArray(wizard.data.task?.responsible);
    const mentions = utils.camelcaseEx(comments.commentMentions(wizard.data.task?.comment))
      .filter((m) => m.userId > 0)
      .map((m) => {
        return teamMemberOptions?.find((mb) => +mb.value === +m.userId);
      })
      .filter((_) => (_));

    const mergedResponsible = utils.uniqueArray(responsible.concat(mentions), 'value');

    return [{
      name: 'responsible',
      label: 'Assign to',
      entity: 'task',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.list,
      filter: ['-0'],
      options: clientCallbacks.members,
      initial: mergedResponsible,
      required: true,
      FormFieldProps: {
        showSearch: true,
        multiple: true,
        limitOptions: 8,
        ListProps: {
          catchFocus: false
        },
        renderOptionLabel: (option, props) => {
          const avatar = `${utils.personName(option.profile.firstName, option.profile.lastName)} (${option.profile.username})`;
          const name = utils.personName(option.profile.firstName, option.profile.lastName);

          const avatarLabel = utils.avatarLabel(avatar);
          const color = utils.string2Color(avatar);

          return <Box className="responsible-label">
            <ActionAvatar size="smaller"
                          action={{
                            label: avatarLabel,
                            tooltip: avatar
                          }}
                          color={color}
                          disabled={props?.disabled}
                          TooltipProps={{enterDelay: 0}}/>
            <Typography variant="body2">{name}</Typography>
          </Box>
        }
      }
    }];
  }, [clientCallbacks, teamMemberOptions, wizard.data.task]);

  return <StyledResponsibleWizardContent ref={ref} {...innerProps}
                                         wizard={wizard}
                                         step={step}
                                         fields={fields} />
});

ResponsibleWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object
};

ResponsibleWizardContent.defaultProps = {};

export default ResponsibleWizardContent;
