import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)`
  text-transform: none;
  padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
  min-height: ${props => props.theme.layout(48)};
  
  .Tab {
    &-badge {
      &.Badge-inline {
        .MuiBadge-badge {
          position: relative;
          align-self: center;
          padding: ${props => props.theme.layout(5)};
          margin-left: ${props => props.theme.layout('1sp')};
          font-size: ${props => props.theme.fontSize(11)};

          height: ${props => props.theme.layout(16)};
          min-width: ${props => props.theme.layout(16)};
        }
      }
      
      &.Badge-inlineDot {
        .MuiBadge-badge {
          position: relative;
          align-self: center;
          margin-left: ${props => props.theme.layout('0.5sp')};
          margin-top: ${props => props.theme.layout(0.5)};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTab.propTypes = {
  theme: PropTypes.object
};

StyledTab.defaultProps = {
}

export default StyledTab;
