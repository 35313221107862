import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import {TaskAlt} from '@mui/icons-material';
import constants from 'helpers/constants';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';
import StyledCreditDistributionPieChart
  from 'components/organisms/Charts/CreditDistributionPieChart/CreditDistributionPieChart.styles';

const CreditDistributionPieChart = React.forwardRef((props, ref) => {
  const {
    credits,
    showIcon,
    showTotal,
    showLegend,
    showActiveShape,
    extended,
    isLoading,
    variant,
    visibility,
    onClick,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'CreditDistributionPieChart', {
    static: ['showActiveShape'],
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (credits) {
      credits
        .forEach((c) => {
          const budgetType = constants.data.lookup('budgetPurposes', c.budgetPurpose);

          legend.push({
            id: c.budgetPurpose,
            position: budgetType.position,
            label: budgetType.label,
            color: innerProps.theme.property(`palette.${budgetType.graphColor}`),
            active: visibility?.[c.budgetPurpose] ?? true
          });
        });
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [credits, visibility, innerProps.theme]);

  const [creditData, totalUsed] = useMemo(() => {
    if (credits) {
      const creditData = {
        dataKey: 'value',
        nameKey: 'label',
        data: credits.map((c) => {
          const budgetType = constants.data.lookup('budgetPurposes', c.budgetPurpose);
          return {
            id: c.budgetPurpose,
            position: budgetType.position,
            label: budgetType.label,
            color: innerProps.theme.property(`palette.${budgetType.graphColor}`),
            value: +c.count,
            meta: {
              ...c,
              count: Math.round(+c.count * 100) / 100,
              label: budgetType.label
            }
          }
        })
          .filter((c) => {
            return Boolean(legend.find((l) => l.active && l.id === c.id))
          }),
        TooltipComponent: BreakdownTooltip
      };

      const totalUsed = Math.ceil(creditData.data.reduce((t, d) => t + d.value, 0));

      return [creditData, totalUsed];
    } else {
      return [null, 0];
    }
  }, [credits, legend, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCreditDistributionPieChart ref={ref} {...innerProps}>
    <Box className="CreditDistributionPieChart-chart">
      <Box className="CreditDistributionPieChart-chart-wrapper">
        {creditData ? <PieChart {...creditData}
                                hoveredId={internalState.hoveredId}
                                variant={variant}
                                isLoading={isLoading}
                                showTooltip={!showActiveShape}
                                showActiveShape={showActiveShape}
                                onClick={onClick}
                                TooltipProps={{
                                  variant: 'extended'
                                }}
                                {...PieChartProps} /> : null}

        <Box className="CreditDistributionPieChart-center">
          {showIcon ? <Icon color="primary" icon={TaskAlt} /> : null}

          {showTotal ? <Typography className="totalCount"
                                   variant="h2"
                                   isLoading={isLoading}>
                        {utils.formatNumber(totalUsed || 0)}
                      </Typography> : null}
          {showTotal ? <Typography className="title" variant="caption"
                                   color="text.secondary"
                                   isLoading={isLoading}>Credits spend</Typography> : null}
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="CreditDistributionPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledCreditDistributionPieChart>
});

CreditDistributionPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  credits: PropTypes.array,
  showIcon: PropTypes.bool,
  showTotal: PropTypes.bool,
  showLegend: PropTypes.bool,
  showActiveShape: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onClick: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

CreditDistributionPieChart.defaultProps = {
  variant: 'full'
};

export default CreditDistributionPieChart;
