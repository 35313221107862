import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDealflowStatusTableCell
  from 'components/molecules/TableCells/DealflowStatusTableCell/DealflowStatusTableCell.styles';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import {useClientDealflowStatus} from 'services/client/client.utils';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

const DealflowStatusTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    statusId,
    disabled,
    size,
    active,
    onClick,
    ...innerProps
  } = useComponentProps(props,'DealflowStatusTableCell');

  const status = useClientDealflowStatus(statusId ?? 0);

  const onClickEvent = useEffectEvent(onClick);
  const ActionChipProps = useMemo(() => {
    const color = status?.statusId === 0 ? 'secondary' : utils.deprecatedColor(status?.groupColor);
    return {
      showInactive: true,
      showTooltip: false,
      action: {
        label: status?.name,
        auth: disabled ? utils.createAuth({attribute: 'system.null'}) : null,
        ChipProps: {
          color: color,
          variant: 'outlined',
          size: size,
          selected: active,
          icon: <DealflowIcon groupId={status?.groupId}/>,
          fullWidth: false,
          radius: size === 'large' ? 'round' : 'normal'
        },
        onClick: onClickEvent ? (e) => {
          onClickEvent?.(e);
          e.preventDefault();
        } : null
      }
    };
  }, [onClickEvent, status, disabled, size, active]);

  return <StyledDealflowStatusTableCell ref={ref} {...innerProps}
                                        ActionChipProps={ActionChipProps} />
}));

DealflowStatusTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statusId: PropTypes.number,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

DealflowStatusTableCell.defaultProps = {
  size: 'large'
};

export default DealflowStatusTableCell;
