import {useServiceApiMutation} from 'stores/hooks/query';
import utils from 'helpers/utils';

const path = utils.servicePath(import.meta.url);

export function useCollectionEntityAnswerAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionEntityAnswerUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}
