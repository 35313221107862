import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {P} from 'components/atoms/Text/Typography/Typography';
import StyledNewEmailPage from 'components/pages/Dev/Email/NewEmailPage/NewEmailPage.styles';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const NewEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'NewEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledNewEmailPage {...innerProps}
                                 config={clientConfig}
                                 preview="Your password has been restored, see you in Catalist.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>Your password has just been reset.</P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you can now log in again. Are you still unsure or you suspect someone else did this, or
              do you still have questions? Please let us know.</P>
          </TableCell>
        </TableRow>
      </StyledNewEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

NewEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

NewEmailPage.defaultProps = {
};

export default NewEmailPage;
