import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import ForgotForm from 'components/organisms/Forms/ForgotForm/ForgotForm';
import StyledForgotPage from 'components/pages/Auth/ForgotPage/ForgotPage.styles';

const ForgotPage = (props) => {
  const innerProps = useComponentProps(props, 'ForgotPage');

  return <StyledForgotPage {...innerProps}>
    <ForgotForm className="ForgotPage-form" />
  </StyledForgotPage>
};

ForgotPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ForgotPage.defaultProps = {};

export default ForgotPage;
