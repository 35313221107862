import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableBody from '@mui/material/TableBody';

const StyledTableBody = styled(TableBody)`
  ${ComponentStyles}
`;

StyledTableBody.propTypes = {
  theme: PropTypes.object
};

StyledTableBody.defaultProps = {
}

export default StyledTableBody;
