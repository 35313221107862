import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import Box from 'components/atoms/Layout/Box/Box';
import StyledServicesSettingPage from 'components/pages/Setting/ServicesSettingPage/ServicesSettingPage.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';

const info = 'Select services that you would like to apply on specific collections, or by default on all collections or on all companies that have a deal flow status';

const ServicesSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'ServicesSettingPage');

  const contentRef = useRef(null);

  const profileProvider = useProfile();
  const authorize = useAuthorize();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const handleShowCard = (card) => {
    return card.name.startsWith('service');
  };

  const handleCanUpdate = () => {
    return authorize({attribute: 'settings.services.update'});
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const columns = Math.max(1, Math.floor(bBox?.width ? ((bBox?.width ?? 0) / 232) : 4));

  return <StyledServicesSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                    title="Services"
                                    info={info}>
    <Box ref={contentRef} className="ServicesSettingPage-content">
      <CollectionProfile columns={columns}
                         variant="grid"
                         onShowCard={handleShowCard}
                         onDirty={handleDirty}
                         onCanUpdate={handleCanUpdate}/>
    </Box>
  </StyledServicesSettingPage>
};

ServicesSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ServicesSettingPage.defaultProps = {};

export default ServicesSettingPage;
