import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';

const StyledChooseTaskWizardContent = styled(WizardContent)`
  &.ChooseTaskWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('1sp')};
    
    .CategoryField {
      .ListField-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${props => props.theme.layout('1sp')};
        
        .ListItem {
          aspect-ratio: 194/144;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledChooseTaskWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledChooseTaskWizardContent.defaultProps = {}

export default StyledChooseTaskWizardContent;
