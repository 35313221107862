import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useChartMouse, useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import ChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip';
import ActionLine from 'components/molecules/Charts/ActionLine/ActionLine';
import StyledLineChart from 'components/organisms/Charts/LineChart/LineChart.styles';

const LineChart = React.forwardRef((props, ref) => {
  const {
    data,
    dataKey,
    hoveredId,
    lines,
    showTooltip,
    showCursor,
    showGrid,
    isLoading,
    hideAxis,
    reverse,
    XAxisProps,
    YAxisProps,
    onClick,
    TooltipComponent,
    TooltipProps,
    GridProps,
    ResponsiveContainerProps,
    ...innerProps
  } = useComponentProps(props, 'LineChart', {
    static: ['isLoading'],
    children: ['tooltip', 'line']
  });

  const innerRef = useRef(null);
  const containerRef = useRef(null);

  const LineChart = useMemo(() => ({
    refs: {
      ref: innerRef,
      containerRef
    }
  }), []);

  useImperativeHandle(ref, () => LineChart);

  const chartMouse = useChartMouse();

  const linesMemo = useMemo(() => {
    const sorted = lines
      .sort((a, b) => {
        if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
          return a.position - b.position;
        } else {
          return a.label.localeCompare(b.label);
        }
      });

    return reverse ? sorted.reverse() : lines;
  }, [lines, reverse]);

  const sortedData = useMemo(() => {
    if (data) {
      return data
        .sort((a, b) => {
          if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
            return a.position - b.position;
          } else {
            return (a.label ?? a.name).localeCompare(b.label ?? b.name);
          }
        })
    }
  }, [data]);

  const horizontal = innerProps.layout !== 'vertical';
  const type = innerProps.type ?? (sortedData?.some((v) => !utils.isNumber(v[dataKey])) ? 'category' : 'number');

  const renderLines = () => {
    if (sortedData) {
      return linesMemo
        .map((line, lineIdx) => {
          const color = line.color ?? innerProps.theme.property('palette.primary.main');
          const hovering = utils.isDefined(hoveredId) || chartMouse.isHovering();
          const hovered = utils.isDefined(hoveredId) ? (hoveredId === line.id) :
            chartMouse.isDetailHovering(line.dataKey)

          return <ActionLine key={lineIdx}
                             className={`LineChart-line ${hovering ? (hovered ? 'hover' : 'notHover') : ''}`}
                             name={line.name}
                             dataKey={line.dataKey}
                             type="monotone"
                             hovered={hovered}
                             stroke={color}
                             strokeWidth={2}
                             onClick={onClick}
                             onMouseMove={chartMouse.handleDetailMouseMove(line.dataKey)}
                             {...line.LineProps} />
        });
    }
  }
  innerProps.onMouseOut = innerProps.onMouseOut ?? chartMouse.handleChartMouseOut;
  innerProps.margin = innerProps.margin ?? {
    top: 8, right: 8, bottom: 4, left: 0
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <ResponsiveContainer ref={LineChart.refs.containerRef} {...ResponsiveContainerProps}>
    <StyledLineChart ref={LineChart.refs.ref} {...innerProps} data={sortedData}>
      {showGrid && <CartesianGrid strokeDasharray="3 3" {...GridProps}/>}
      {sortedData?.length > 0 ? <XAxis hide={hideAxis}
                                       dataKey={horizontal ? dataKey : null}
                                       type={horizontal ? type : 'number'}
                                       tickSize={16}
                                       tickLine={false}
                                       allowDecimals={false}
                                       {...XAxisProps}/> : null}
      {sortedData?.length > 0 ? <YAxis hide={hideAxis}
                                       dataKey={!horizontal ? dataKey : null}
                                       type={!horizontal ? type : 'number'}
                                       tickSize={12}
                                       tickLine={false}
                                       allowDecimals={false}
                                       {...YAxisProps}/> : null}
      {showTooltip && <Tooltip className="LineChart-tooltip"
                               cursor={showCursor}
                               content={<TooltipComponent wrapper={LineChart.refs.containerRef.current?.current}
                                                          activateByPayload={true}
                                                          activePayload={chartMouse.hoverPayLoadKey}
                                                          activeIndex={chartMouse.activeIndex}
                                                          {...TooltipProps}/>} />}
      {renderLines()}
    </StyledLineChart>
  </ResponsiveContainer>
});

LineChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dataKey: PropTypes.string,
  hoveredId: PropTypes.any,
  bars: PropTypes.array,
  showTooltip: PropTypes.bool,
  showCursor: PropTypes.bool,
  showGrid: PropTypes.bool,
  isLoading: PropTypes.bool,
  TooltipComponent: PropTypes.any,
  XAxisProps: PropTypes.object,
  YAxisProps: PropTypes.object,
  onClick: PropTypes.func,
  TooltipProps: PropTypes.object,
  GridProps: PropTypes.object,
  ResponsiveContainerProps: PropTypes.object
};

LineChart.defaultProps = {
  dataKey: 'name',
  showTooltip: true,
  showCursor: false,
  lines: [],
  hideAxis: false,
  reverse: false,
  layout: 'horizontal',
  TooltipComponent: ChartTooltip
};

export default LineChart;
