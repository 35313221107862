import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {H1, H2, P} from 'components/atoms/Text/Typography/Typography';
import StyledForbiddenPage from 'components/pages/Error/ForbiddenPage/ForbiddenPage.styles';
import {useLocation} from 'react-router-dom';

const ForbiddenPage = (props) => {
  const innerProps = useComponentProps(props, 'ForbiddenPage')

  const location = useLocation();
  const error = location.state?.error;

  // the response json is automatically parsed to
  // `error.data`, you also have access to the status
  return <StyledForbiddenPage {...innerProps}>
    <H1>Oops!</H1>
    <H2>You can not access this page...</H2>
    {error ? [
      (error.statusCode && <P key={1}>{error.statusCode}</P>),
      (error.data?.message && <P key={2}>{error.data.message}</P>),
      (!error.data?.message && error.data && <P key={3}>{error.data}</P>)
    ] : null}
  </StyledForbiddenPage>
};

ForbiddenPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ForbiddenPage.defaultProps = {
};

export default ForbiddenPage;
