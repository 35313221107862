import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import {
  useColumnState,
  useKanbanState,
  useEffectEvent,
  useListSelection,
  useListState, useGraphState
} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import constants from 'helpers/constants';

export const TableContext = React.createContext(null);

export function useTable () {
  return React.useContext(TableContext);
}

const TableProvider = (props) => {
  const [internalState, setInternalState] = useState({
    list: null,
    testList: null,
    kanbanLists: {},
    showFilters: false,
    showColumns: false,
    showKanban: false,
    showProfile: false,
    showQuery: true,
    settings: {}
  });

  const viewInitialised = useRef(false);
  const listInitialised = useRef(false);

  // see also ProfileProvider
  const optionsMemo = useMemo(() => {
    const options = utils.mergeObjects(props?.options ?? {}, props?.view?.options, true);
    Object.keys(options).forEach((k) => {
      if (options[k]?.options && options[k].options.type) {
        if (props?.view || props.skipStorage) {
          if (props?.view && (props?.view?.name !== 'default') && options[k].options.name) {
            options[k].options.name = `${options[k].options.name}_${props.view.name}`;
          }
          if (props?.view?.skipStorage || props.skipStorage) {
            if (props.skipStorage) {
              options[k].options.searchParams = false;
            }
            options[k].options.type = constants.appState.type.none;
          }
        }
      }
    });

    const columnVisibility = (options?.columnDefinitions || []).reduce((o, columnDef) => {
      if (utils.isDefined(columnDef.visible)) {
        o = o ?? {};
        o[columnDef.id] = columnDef.visible;
      }
      return o;
    }, options?.columnState?.initial?.columnVisibility);

    const columnPinning = (options?.columnDefinitions || []).reduce((o, columnDef) => {
      if (columnDef.pinned) {
        o = {left: [], right: [], ...o};
        o[columnDef.pinned].push(columnDef.id);
      }
      return o;
    }, options?.columnState?.initial?.columnPinning);

    const panelVisibility = (options?.panelDefinitions || []).reduce((o, panelDef) => {
      if (utils.isDefined(panelDef.visible)) {
        o = o ?? {};
        o[panelDef.name] = panelDef.visible;
      }
      return o;
    }, options?.kanbanState?.initial?.panelVisibility);

    const graphVisibility = (options?.graphDefinitions || []).reduce((o, graphDef) => {
      if (utils.isDefined(graphDef.visible)) {
        o = o ?? {};
        o[graphDef.name] = graphDef.visible;
      }
      return o;
    }, options?.graphState?.initial?.graphVisibility);

    return {
      ...options,
      listSelection: {
        ...options?.listSelection,
        options: {
          ...options?.listSelection?.options,
          dataKey: props.dataKey
        }
      },
      columnState: {
        ...options?.columnState,
        initial: {
          ...options?.columnState?.initial,
          columnVisibility: columnVisibility,
          columnPinning: columnPinning
        }
      },
      kanbanState: {
        ...options?.kanbanState,
        initial: {
          ...options?.kanbanState?.initial,
          panelVisibility: panelVisibility
        }
      },
      graphState: {
        ...options?.graphState,
        initial: {
          ...options?.graphState?.initial,
          graphVisibility: graphVisibility
        }
      }
    };
  }, [props.dataKey, props?.options, props?.skipStorage, props?.view]);

  const listState = useListState(optionsMemo?.listState?.initial, optionsMemo?.listState?.options);
  const testListState = useListState(optionsMemo?.testListState?.initial, optionsMemo?.testListState?.options);
  const kanbanListState = useListState(optionsMemo?.kanbanListState?.initial, optionsMemo?.kanbanListState?.options);
  const columnState = useColumnState(optionsMemo?.columnState?.initial, optionsMemo?.columnState?.options);
  const kanbanState = useKanbanState(optionsMemo?.kanbanState?.initial, optionsMemo?.kanbanState?.options);
  const graphState = useGraphState(optionsMemo?.graphState?.initial, optionsMemo?.graphState?.options);

  const listSelection = useListSelection(optionsMemo?.listSelection?.initial, optionsMemo?.listSelection?.options, internalState.list);

  const defaultsMemo = useMemo(() => {
    const defaults = utils.mergeObjects(props.defaults, props?.view?.defaults, true);

    const sort = (optionsMemo?.columnDefinitions || []).reduce((a, columnDef) => {
      if (columnDef.sorted) {
        const sorted = utils.isFunction(columnDef.sorted) ? columnDef.sorted(props?.view) : columnDef.sorted;
        a = utils.addSort(a, {id: columnDef.id, desc: sorted === 'desc'});
      }
      return a;
    }, defaults?.list?.sort);

    const filter = (optionsMemo?.filterGroupDefinitions || []).reduce((a, filterGroupDef) => {
      Object.keys(filterGroupDef.filters || {}).forEach((k) => {
        const filter = filterGroupDef.filters[k];
        if (filter.filtered) {
          const filtered = utils.isFunction(filter.filtered) ? filter.filtered(props?.view) : filter.filtered;
          const filterId = (utils.isFunction(filter.id) ? filter.id(props?.view) : filter.id);
          a = utils.applyFilters(a, {id: filterId, value: filtered}, filterId);
        }
      });
      return a;
    }, defaults?.list?.filter);

    return {
      ...defaults,
      list: {
        ...defaults?.list,
        sort: sort,
        filter: filter
      }
    };
  }, [props.defaults, props?.view, optionsMemo?.columnDefinitions, optionsMemo?.filterGroupDefinitions]);

  const authorize = useAuthorize();

  const columnDefinitions = useMemo(() => {
    const columnDefs = optionsMemo?.columnDefinitions;
    if (columnDefs?.length > 0) {
      return columnDefs
        .filter((columnDef) => !columnDef.hidden && authorize(columnDef.auth?.read ?? {}));
    } else {
      return null;
    }
  }, [authorize, optionsMemo?.columnDefinitions]);

  const filterGroupDefinitions = useMemo(() => {
    const filterGroupDefs = optionsMemo?.filterGroupDefinitions;
    if (filterGroupDefs?.length > 0) {
      return filterGroupDefs
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, optionsMemo?.filterGroupDefinitions]);

  const panelDefinitions = useMemo(() => {
    const panelDefs = optionsMemo?.panelDefinitions;
    if (panelDefs?.length > 0) {
      return panelDefs
        .filter((panelDef) => !panelDef.hidden && authorize(panelDef.auth?.read ?? {}))
        .map((panelDef) => {
          panelDef.subPanels = panelDef.subPanels
            .filter((panelDef) => !panelDef.hidden && authorize(panelDef.auth?.read ?? {}));
          return panelDef;
        });
    } else {
      return null;
    }
  }, [authorize, optionsMemo?.panelDefinitions]);

  const graphDefinitions = useMemo(() => {
    const graphDefs = optionsMemo?.graphDefinitions;
    if (graphDefs?.length > 0) {
      return graphDefs
        .filter((graphDef) => !graphDef.hidden && authorize(graphDef.auth?.read ?? {}));
    } else {
      return null;
    }
  }, [authorize, optionsMemo?.graphDefinitions]);

  const compareEvent = useEffectEvent(props.onCompare)
  const onCompare = useCallback((itmA, itmB) => {
    return compareEvent ? compareEvent?.(itmA, itmB) : (
      props.dataKey ? itmA[props.dataKey] === itmB[props.dataKey] : itmA === itmB
    )
  }, [compareEvent, props.dataKey]);

  const onDataEvent = useEffectEvent(props.onData);
  const onContextEvent = useEffectEvent(props.onContext);
  const context = useMemo(() => {
    return {
      state: {
        ...internalState,
        ...props.state
      },
      listState,
      testListState,
      kanbanListState,
      columnState,
      listSelection,
      kanbanState,
      graphState,
      columnDefinitions,
      panelDefinitions,
      graphDefinitions,
      filterGroupDefinitions,
      list: internalState.list,
      testList: internalState.testList,
      kanbanLists: internalState.kanbanLists,
      data: internalState.data,
      context: props.context,
      view: props.view,
      dataKey: props.dataKey,
      onCompare: onCompare,
      fieldData: props.fieldData,
      updaters: props.updaters,
      defaults: defaultsMemo,
      clearState: () => {
        listState?.clear?.();
        columnState?.clear?.();
        kanbanState?.clear?.();
        graphState?.clear?.();
        listSelection?.clearSelection?.();
        setInternalState(utils.updater({
          showFilters: false,
          showColumns: false,
          showKanban: false,
          showProfile: false,
          showQuery: true,
          settings: {}
        }, true));
      },
      isLoading: () => {
        return Boolean(props.isLoading || (internalState.list?.status?.isLoading || !internalState.list?.status?.isSettled));
      },
      appliedListState: (type) => {
        const defaultFilter = defaultsMemo?.list?.filter;
        const defaultSort = defaultsMemo?.list?.sort;

        const originalState = type ? (type === 'test' ? testListState : kanbanListState) : listState;

        return {
          ...originalState,
          search: originalState.search,
          query: originalState.query,
          filter: utils.addFilter(defaultFilter, originalState.filter),
          sort: utils.addSort(defaultSort, originalState.sort),
          pagination: originalState.pagination
        };
      },
      first: () => {
        return internalState.list?.data?.[0];
      },
      find: () => {
        if (!internalState.data?.status?.isLoading && internalState.data && internalState.list?.data) {
          return internalState.list.data.find((d) => onCompare(d, internalState.data.data));
        } else {
          return null;
        }
      },
      selected: () => {
        if (internalState.data) {
          return internalState.data.status.isLoading ? null : internalState.data.data;
        } else {
          return internalState.list?.data?.[0];
        }
      },
      next: (count = 1) => {
        if (count > 0 && internalState.list?.data?.length > 0) {
          let idx = 0;
          if (internalState.data?.data && internalState.list?.data) {
            idx = internalState.list.data.findIndex((d) => onCompare(d, internalState.data.data));
          }

          const length = internalState.list.data.length;
          const loop = internalState.list.meta.resultsCount === length;
          const newIndex = (count === 1 || loop) ? (idx + Math.min(count, length - 1)) :
            Math.min(idx + count, internalState.list.data.length - 1);
          if (loop || newIndex < internalState.list.data.length) {
            const itmIndex = (newIndex < 0 ? (length + newIndex) : newIndex) % length;
            if (newIndex !== idx) {
              if (count === 1) {
                return internalState.list.data[itmIndex];
              } else {
                if (loop && itmIndex <= idx) {
                  return internalState.list.data.slice(idx + 1).concat(internalState.list.data.slice(0, itmIndex + 1));
                } else {
                  return internalState.list.data.slice(idx + 1, itmIndex + 1);
                }
              }
            }
          }
        }
        return null;
      },
      prev: (count = 1) => {
        if (count > 0 && internalState.list?.data?.length > 0) {
          let idx = 0;
          if (internalState.data?.data && internalState.list?.data) {
            idx = internalState.list.data.findIndex((d) => onCompare(d, internalState.data.data));
          }

          const length = internalState.list.data.length;
          const loop = internalState.list.meta.resultsCount === length;
          const newIndex = (count === 1 || loop) ? (idx - Math.min(count, length - 1)) :
            Math.max(0, idx - count);
          if (loop || newIndex >= 0) {
            const itmIndex = (newIndex < 0 ? (length + newIndex) : newIndex) % length;
            if (newIndex !== idx) {
              if (count === 1) {
                return internalState.list.data[itmIndex];
              } else {
                if (loop && itmIndex >= idx) {
                  return internalState.list.data.slice(0, idx).concat(internalState.list.data.slice(itmIndex)).reverse();
                } else {
                  return internalState.list.data.slice(itmIndex, idx).reverse();
                }
              }
            }
          }
        }
        return null;
      },
      toggleFilters: () => {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            showFilters: !current.showFilters
          })(current);
        });
      },
      openFilters: () => {
        setInternalState(utils.updater({showFilters: true}, true));
      },
      closeFilters: () => {
        setInternalState(utils.updater({showFilters: false}, true));
      },
      toggleColumns: () => {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            showColumns: !current.showColumns
          })(current);
        });
      },
      openColumns: () => {
        setInternalState(utils.updater({showColumns: true}, true));
      },
      closeColumns: () => {
        setInternalState(utils.updater({showColumns: false}, true));
      },
      toggleKanban: () => {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            showKanban: !current.showKanban
          })(current);
        });
      },
      openKanban: () => {
        setInternalState(utils.updater({showKanban: true}, true));
      },
      closeKanban: () => {
        setInternalState(utils.updater({showKanban: false}, true));
      },
      openProfile: (id, settings) => {
        setInternalState(utils.updater({showProfile: {
          id,
          settings
        }}, true));
      },
      closeProfile: () => {
        setInternalState(utils.updater({showProfile: false}, true));
      },
      toggleQuery: () => {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            showQuery: !current.showQuery
          })(current);
        });
      },
      openQuery: () => {
        setInternalState(utils.updater({showQuery: true}, true));
      },
      closeQuery: () => {
        setInternalState(utils.updater({showQuery: false}, true));
      },
      setSettings: (settings) => {
        if (!utils.isFunction(settings)) {
          setInternalState((current) => {
            return utils.updater({
              ...current,
              settings: {
                ...current.settings,
                ...settings
              }
            })(current);
          });
        } else {
          setInternalState((current) => {
            return utils.updater({
              ...current,
              settings: settings(current.settings)
            })(current);
          });
        }
      },
      setReset: (reset) => {
        setInternalState(utils.updater({reset}, true));
      },
      setData: (data) => {
        if (!utils.isFunction(data)) {
          setInternalState((current) => ({
              ...current,
              data
          }));
          onDataEvent?.(data);
        } else {
          setInternalState((current) => ({
              ...current,
              data: data(current.data)
          }));
          onDataEvent?.(data(internalState.data));
        }
      },
      setContext: (context) => {
        if (!utils.isFunction(context)) {
          setInternalState((current) => ({
            ...current,
            context
          }));
          onContextEvent?.(context);
        } else {
          setInternalState((current) => ({
            ...current,
            context: context(current.context)
          }));
          onContextEvent?.(context(internalState.context));
        }
      },
      setList: (list) => {
        setInternalState((current) => ({
          ...current,
          list
        }));
      },
      setTestList: (list) => {
        setInternalState((current) => ({
          ...current,
          testList: list
        }));
      },
      setKanbanList: (id, list) => {
        setInternalState((current) => ({
          ...current,
          kanbanLists: {
            ...current.kanbanLists,
            [id]: list
          }
        }));
      }
    }
  }, [internalState, listState, testListState, kanbanListState, onDataEvent, onContextEvent,
    columnState, listSelection, kanbanState, graphState, props.view,
    props.isLoading, props.fieldData, props.updaters, defaultsMemo, props.dataKey,
    columnDefinitions, panelDefinitions, graphDefinitions, filterGroupDefinitions,
    props.state, props.context, onCompare]);

  useLayoutEffect(() => {
    if (utils.isDefined(props.data?.data)) {
      setInternalState(utils.updater({data: props.data}, true));
    }
  }, [props.data, props.data?.data]);

  useLayoutEffect(() => {
    if (utils.isDefined(props.state)) {
      setInternalState(utils.updater(props.state, true));
    }
  }, [props.state]);

  useLayoutEffect(() => {
    if (viewInitialised.current !== props.view) {
      viewInitialised.current = props.view;
      setInternalState(utils.updater({
        showFilters: false,
        showColumns: false,
        showKanban: false,
        showProfile: false,
        showQuery: true,
        settings: {}
      }, true));
    }
  }, [props.view]);

  useLayoutEffect(() => {
    // detect reordering / filtering etc.
    if (listState) {
      if (listInitialised.current) {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            reset: current.reset > 0 ? (+current.reset + 1) : 1
          })(current);
        });
      } else if (internalState.list?.status?.isSettled) {
        listInitialised.current = true;
      }
    }
  }, [listState, internalState.list?.status?.isSettled]);

  const LoaderComponent = props.LoaderComponent;
  const TestLoaderComponent = props.TestLoaderComponent;
  const KanbanLoaderComponent = props.KanbanLoaderComponent;

  const wrapLoader = (Component, active, children) => {
    return Component ? <Component active={active}>
      {children}
    </Component> : children;
  }

  return <TableContext.Provider value={context}>
    {wrapLoader(LoaderComponent, props.active ?? true,
      wrapLoader(TestLoaderComponent, props.active ?? true,
        wrapLoader(KanbanLoaderComponent, context.state.showKanban, props.children)
      )
    )}
  </TableContext.Provider>
};

TableProvider.propTypes = {
  view: PropTypes.object,
  data: PropTypes.object,
  context: PropTypes.object,
  onData: PropTypes.func,
  onContext: PropTypes.func,
  options: PropTypes.object,
  updaters: PropTypes.object,
  defaults: PropTypes.object,
  state: PropTypes.object,
  onCompare: PropTypes.func,
  isLoading: PropTypes.bool,
  fieldData: PropTypes.object,
  LoaderComponent: PropTypes.any,
  TestLoaderComponent: PropTypes.any,
  KanbanLoaderComponent: PropTypes.any
}

export default TableProvider;
