import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableHead from 'components/atoms/Tables/TableHead/TableHead.styles';
import {withMemo} from 'helpers/wrapper';

const TableHead = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TableHead');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableHead ref={ref} {...innerProps}/>
}));

TableHead.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TableHead.defaultProps = {
};

export default TableHead;
