import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const StyledChartTooltip = styled(Tooltip)`
  .title {
    padding: ${props => props.theme.spacing(0.5)} ${props => props.theme.spacing(0.25)};

    .Badge {
      .MuiBadge-badge {
        font-size: ${props => props.theme.fontSize(11)};
        height: ${props => props.theme.layout(16)};
      }
    }

    .Span {
      font-size: ${props => props.theme.fontSize(13)};
      margin-left: ${props => props.theme.layout('0.5sp')};
      margin-right: ${props => props.theme.layout('1sp')};
    }
    
    .payload {
      display: flex;
      align-items: center;

      .Icon {
        font-size: inherit;
        height: ${props => props.theme.layout(12)};
        width: ${props => props.theme.layout(12)};

        &.Avatar {
          font-size: ${props => props.theme.fontSize(13)};
          height: ${props => props.theme.layout(16)};
          width: ${props => props.theme.layout(16)};
        }
      }

      .Span {
        flex-grow: 1;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledChartTooltip.propTypes = {
  theme: PropTypes.object
};

StyledChartTooltip.defaultProps = {
}

export default StyledChartTooltip;
