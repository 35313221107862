import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSectionPanelFooter = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(3)};
  border-top: 1px solid ${props => props.theme.property('palette.divider')};
  background-color: ${props => props.theme.property('palette.background.paper')};
  gap: ${props => props.theme.layout('1sp')};
  
  &:not(:has(*:not(.SectionPanelFooter-info))):not(:has(.SectionPanelFooter-info > *)) {
    display: none;
  }
  
  .SectionPanelFooter-info {
    ${props => props.theme.font2Css('body2')};
    flex-grow: 1;
  }
  
  &.SectionPanelFooter-split {
    > *:not(.SectionPanelFooter-info):first-of-type {
      order: 1
    }
    > *:not(.SectionPanelFooter-info):last-of-type, > *:not(.SectionPanelFooter-info):not(:first-of-type) {
      order: 3
    }
    .SectionPanelFooter-info {
      order: 2;
    }
    
    &:has(*:nth-child(3)) {
      .SectionPanelFooter-info {
        text-align: center;
      }
    }
  }
  
  &.SectionPanelFooter-orientation {
    &-vertical {
      flex-direction: column;
      align-items: unset;
      padding: ${props => props.theme.spacing(3)};

      .SectionPanelFooter-info {
        display: none;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSectionPanelFooter.propTypes = {
  theme: PropTypes.object
};

StyledSectionPanelFooter.defaultProps = {}

export default StyledSectionPanelFooter;
