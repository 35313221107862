import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import StyledTableFooter from 'components/atoms/Tables/TableFooter/TableFooter.styles';

const TableFooter = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TableFooter');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableFooter ref={ref} {...innerProps}/>
}));

TableFooter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TableFooter.defaultProps = {
};

export default TableFooter;
