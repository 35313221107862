import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import {TaskAlt} from '@mui/icons-material';
import constants from 'helpers/constants';
import StyledTagGroupPieChart from 'components/organisms/Charts/TagGroupPieChart/TagGroupPieChart.styles';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const TagGroupPieChart = React.forwardRef((props, ref) => {
  const {
    breakdown,
    showIcon,
    showTotal,
    showName,
    showLegend,
    showActiveShape,
    extended,
    isLoading,
    variant,
    visibility,
    onClick,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'TagGroupPieChart', {
    static: ['showActiveShape'],
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (breakdown) {
      breakdown.tags
        .concat({tagId: -1, value: '', label: 'No tag', position: constants.numbers.maxInt})
        .forEach((tag, idx) => {
          const bd = breakdown.breakdown?.[tag.value];
          if (bd?.length > 0) {
            legend.push({
              id: tag.tagId,
              position: tag.position ?? idx,
              label: tag.label ?? tag.value,
              color: +tag.tagId > 0 ? utils.number2Color(+tag.tagId) :
                innerProps.theme.property('palette.tagGroupDefault.dark'),
              active: visibility?.[tag.tagId] ?? true
            });
          }
        });
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [breakdown, visibility, innerProps.theme]);

  const [tagsData, total] = useMemo(() => {
    if (breakdown) {
      const tagsData = {
        dataKey: 'value',
        nameKey: 'label',
        data: breakdown.tags
          .concat({tagId: -1, value: '', label: 'No tag', position: constants.numbers.maxInt})
          .map((tag, idx) => {
            const bd = breakdown.breakdown?.[tag.value];
            if (bd?.length > 0) {
              return {
                id: tag.tagId,
                position: tag.position ?? idx,
                label: tag.label ?? tag.value,
                color: +tag.tagId > 0 ? utils.number2Color(+tag.tagId) :
                  innerProps.theme.property('palette.tagGroupDefault.dark'),
                value: bd.length,
                meta: {
                  ...tag,
                  count: bd.length,
                  label: tag.label ?? tag.value
                }
              }
            } else {
              return {}; // empty
            }
          })
            .filter((b) => {
              return Boolean(legend.find((l) => l.active && +l.id === +b.id))
            }),
        TooltipComponent: BreakdownTooltip
      };

      const total = tagsData.data.reduce((t, d) => t + d.value, 0);

      return [tagsData, total];
    } else {
      return [null, 0];
    }
  }, [breakdown, legend, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTagGroupPieChart ref={ref} {...innerProps}>
    <Box className="TagGroupPieChart-chart">
      <Box className="TagGroupPieChart-chart-wrapper">
        {tagsData ? <PieChart {...tagsData}
                              hoveredId={internalState.hoveredId}
                              variant={variant}
                              isLoading={isLoading}
                              showTooltip={!showActiveShape}
                              showActiveShape={showActiveShape}
                              onClick={onClick}
                              TooltipProps={{
                                variant: 'extended'
                              }}
                              {...PieChartProps} /> : null}

        <Box className="TagGroupPieChart-center">
          {showIcon ? <Icon color="primary" icon={TaskAlt} /> : null}

          {showTotal ? <Typography color="primary"
                                  className="totalCount"
                                  isLoading={isLoading}>
                        {utils.formatNumber(total || 0)}
                      </Typography> : null}
          {showTotal ? <H6 className="title" isLoading={isLoading}>Companies</H6> : null}

          {showName ? <H6 className="title" isLoading={isLoading}>{breakdown?.name || ''}</H6> : null}
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="TagGroupPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledTagGroupPieChart>
});

TagGroupPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  breakdown: PropTypes.array,
  showIcon: PropTypes.bool,
  showTotal: PropTypes.bool,
  showName: PropTypes.bool,
  showLegend: PropTypes.bool,
  showActiveShape: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onClick: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

TagGroupPieChart.defaultProps = {
  variant: 'full',
  showName: true
};

export default TagGroupPieChart;
