import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledTableFiltersContent = styled(ListWrapper)`
  height: fit-content;
  
  .TableFiltersContent {
    &-title {
      color: ${props => props.theme.property('palette.blueGrey.400')};
      text-transform: uppercase;
    }
  }
  .ListWrapper-content {
    height: fit-content;
  }
  
  ${ComponentStyles}
`;

StyledTableFiltersContent.propTypes = {
  theme: PropTypes.object
};

StyledTableFiltersContent.defaultProps = {}

export default StyledTableFiltersContent;
