import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTableColumns from 'components/organisms/TableColumns/EntitiesTableColumns/EntitiesTableColumns';

const StyledDatabaseEntitiesTableColumns = styled(EntitiesTableColumns)`
  ${ComponentStyles}
`;

StyledDatabaseEntitiesTableColumns.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitiesTableColumns.defaultProps = {}

export default StyledDatabaseEntitiesTableColumns;
