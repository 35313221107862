import React from 'react';
import PropTypes from 'prop-types';
import StyledCategoryCard from 'components/molecules/Cards/CategoryCard/CategoryCard.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Check from '@mui/icons-material/Check';
import Chip from 'components/atoms/Chips/Chip/Chip';
import Badge from 'components/atoms/Badges/Badge/Badge';

const CategoryCard = React.forwardRef((props, ref) => {
  const {
    icon,
    iconColor,
    iconCount,
    selected,
    title,
    subtitle,
    credits,
    chip,
    variant,
    size,
    density,
    iconDensity,
    showSelected,
    ...innerProps
  } = useComponentProps(props, 'CategoryCard', {
    static: ['selected', 'credits'],
    variable: ['iconDensity'],
    children: ['icon', 'title', 'subtitle', 'chip', 'selected']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCategoryCard ref={ref} {...innerProps}
                             selected={selected}
                             variant="outlined">
    {(variant === 'standard' || showSelected) ? (
      <Box className="CategoryCard-heading">
        {chip ? <Box className="CategoryCard-chip">{utils.isReactElement(chip) ? chip : <Chip label={chip}/>}</Box> : null}
        {selected ? <Icon className="CategoryCard-selected" icon={Check} size="smaller"/> : null}
      </Box>
    ) : null}
    <Box className="CategoryCard-body">
      {icon ? <Box className="CategoryCard-icon">
        {!utils.isArray(icon) ? (new Array(iconCount).fill(null).map((i, idx) => {
          return utils.isReactElement(icon) ? utils.cloneElement(icon, {key: idx}) :
            <Icon key={idx} icon={icon} color={iconColor}/>
        })) : icon.map((ico, idx) => {
          return utils.isReactElement(ico) ? utils.cloneElement(ico, {key: idx}) :
            <Icon key={idx} icon={ico} color={iconColor} />
        })}</Box> : null}
      <Box className="CategoryCard-body-text">
        {title ? <Box className="CategoryCard-title">{utils.isReactElement(title) ? title : <Typography variant="subtitle1">{title}</Typography>}</Box> : null}
        {(variant === 'standard' && subtitle) ?
          <Box className="CategoryCard-subtitle">{utils.isReactElement(subtitle) ? subtitle :
            <Typography variant="caption" color="text.secondary">{subtitle}</Typography>}</Box> : null}
        {(variant === 'inline' && utils.isDefined(credits)) ?
          <Box className="CategoryCard-credits">{utils.isReactElement(credits) ? credits :
            <Typography variant="caption" color="text.secondary">
              <Badge badgeContent={credits ?? '0'} size="small" variant="inline"/>cc per company
            </Typography>}
          </Box> : null}
      </Box>
      {variant === 'inline' ? (
        (chip ? <Box className="CategoryCard-chip">{utils.isReactElement(chip) ? chip : <Chip label={chip}/>}</Box> : null)
      ) : null}
    </Box>
  </StyledCategoryCard>
});

CategoryCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  iconCount: PropTypes.number,
  selected: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  chip: PropTypes.any,
  density: PropTypes.string,
  iconDensity: PropTypes.string,
  showSelected: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'inline']), PropTypes.string])
};

CategoryCard.defaultProps = {
  variant: 'standard',
  color: 'primary',
  iconCount: 1
};

export default CategoryCard;
