import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import MobileStepper from '@mui/material/MobileStepper';

const StyledMobileStepper = styled(MobileStepper)` 
  .MuiMobileStepper-dot {
    background-color: ${props => props.theme.property('palette.divider')};
    
    &.MuiMobileStepper-dotActive {
      background-color: ${props => props.theme.property(`palette.${props.$color}.main`)};
    }
  }
  
  ${ComponentStyles}
`;

StyledMobileStepper.propTypes = {
  theme: PropTypes.object
};

StyledMobileStepper.defaultProps = {}

export default StyledMobileStepper;
