import utils from 'helpers/utils';
import {useCallback, useEffect, useRef} from 'react';
import {useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import dom from 'helpers/dom';
import {useNavLink} from 'helpers/hooks/links';
import {useAuthorizeAction} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';

export function useActionItem (action) {
  const authorizeAction = useAuthorizeAction();
  action = authorizeAction(action);

  const dialog = useDialog();

  const [handleClick, active, href] = useNavLink({
    ...action?.navigation,
    onClick: action?.onClick
  });

  if (action?.navigation) {
    action.href = href;
    action.onClick = handleClick;
    action.active = action.active ?? active;
  }

  if (Boolean(action?.onClick)) {
    action.clickable = true;
  }

  const isDialog = Boolean(dialog);
  const onClickEvent = useEffectEvent(action?.onClick);
  useEffect(() => {
    if (onClickEvent && action?.keyboard) {
      return utils.observeEvent(window, 'keydown', (e) => {
        const keys = action.keyboard.split(/\s?\+\s?/);
        const modifiers = keys.slice(0, -1);
        const key = keys[keys.length - 1].replace(/\s/g, '');

        if (modifiers.length === 0 || modifiers.filter((m) => {
          return e.key?.toLowerCase() === m.toLowerCase() || e.code?.toLowerCase() === m.toLowerCase() ||
            e[m.toLowerCase()] === true || e[m.toLowerCase() + 'Key'] === true;
        }).length === modifiers.length) {
          if (e.key?.toLowerCase() === key.toLowerCase() || e.code?.toLowerCase() === key.toLowerCase()) {
            if (!e.defaultPrevented && !dom.isPartOfForm(e.target) && (isDialog || !dom.isPartOfDialog(e.target))) {
              onClickEvent(utils.createEvent('click'));
            }
          }
        }
      });
    }
  }, [onClickEvent, action?.keyboard, isDialog]);

  return action;
}

export function useActionItemAction (itmAction, defaultAction) {
  const submittingRef = useRef(false);

  const cb = useCallback((...args) => {
    const e = args[0];

    const release = () => {
      submittingRef.current = false;
    };

    if (!submittingRef.current) {
      submittingRef.current = true;

      itmAction?.(e, release, ...args.slice(1));
      if (!e.defaultPrevented) {
        defaultAction?.(e, release, ...args.slice(1));
      }
      setTimeout(() => release(), constants.debounce.dblClick);
    }
  }, [itmAction, defaultAction]);

  if (itmAction || defaultAction) {
    return cb;
  } else {
    return null;
  }
}
