import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useDealflowEntitiesRoutes from 'routes/dealflow/entities/entities.routes';
import DealflowProfileProvider from 'components/organisms/Providers/ProfileProvider/DealflowProfileProvider';
import TaskProfileWrapper from 'components/templates/Wrappers/Tasks/TaskProfileWrapper/TaskProfileWrapper';
import utils from 'helpers/utils';

export default function useDealflowRoutes () {
  const entitiesRoutes = useDealflowEntitiesRoutes();
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <entitiesRoutes.WrapperComponent><Outlet /></entitiesRoutes.WrapperComponent>,
        children: entitiesRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'dealflow.entity.route'
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <DealflowProfileProvider>
          <TaskProfileWrapper>
            {children}
          </TaskProfileWrapper>
        </DealflowProfileProvider>
      },
      route: route
    };
  }, [entitiesRoutes]);
};
