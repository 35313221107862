import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesDealflowBreakdownGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;
  
  .CardContent {
    .DealflowPieChart {
      &-chart {
        margin: ${props => props.theme.spacing(4)} ${props => props.theme.spacing(1)} ${props => props.theme.spacing(4)} ${props => props.theme.spacing(4)};

        ${props => props.theme.breakpoints.down('lg')} {
          margin: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1)};
        }
        ${props => props.theme.breakpoints.between('sm', 'md')} {
          margin: ${props => props.theme.spacing(4)} ${props => props.theme.spacing(1)} ${props => props.theme.spacing(4)} ${props => props.theme.spacing(4)};
        }
      }
      
      &-center {
        ${props => props.theme.breakpoints.down('lg')} {
          transform: scale(0.75);
        }
        ${props => props.theme.breakpoints.down('md')} {
          transform: scale(1);
        }
      }
      
      &-legend {
        flex: 1 0 30%;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesDealflowBreakdownGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesDealflowBreakdownGraphCard.defaultProps = {}

export default StyledCollectionEntitiesDealflowBreakdownGraphCard;
