import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import {TaskAlt} from '@mui/icons-material';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';
import StyledSourceStatsPieChart from 'components/organisms/Charts/SourceStatsPieChart/SourceStatsPieChart.styles';

const legendMap = {
  'suggested': {
    label: 'Suggestions',
    color: 'primary.main',
    position: 3
  },
  'outFiltered': {
    label: 'Outfiltered',
    color: 'text.secondary',
    position: 2
  },
  'processed': {
    label: 'Processed',
    color: 'action.disabled',
    position: 1
  }
}

const SourceStatsPieChart = React.forwardRef((props, ref) => {
  const {
    source,
    onClick,
    showIcon,
    showTotal,
    showName,
    showLegend,
    extended,
    isLoading,
    variant,
    visibility,
    orientation,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'SourceStatsPieChart', {
    variable: ['orientation'],
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (source) {
      Object.keys(legendMap).forEach((k, idx) => {
        legend.push({
          id: k,
          position: idx,
          label: legendMap[k].label,
          color: innerProps.theme.property(`palette.${legendMap[k].color}`),
          active: visibility?.[k] ?? true,
          badge: (source[k] || 0).toString()
        });
      });
    }

    const rows = legend.length;

    return [legend, rows];
  }, [source, visibility, innerProps.theme]);

  const sourceData = useMemo(() => {
    if (source) {
      return {
        dataKey: 'value',
        nameKey: 'label',
        data: Object.keys(legendMap)
          .map((k) => {
            const fill = (k === 'outFiltered' && (source.total ?? 0) === 0);
            if (source[k] > 0 || fill) {
              return {
                id: k,
                position: legendMap[k].position,
                label: legendMap[k].label,
                color: innerProps.theme.property(`palette.${legendMap[k].color}`),
                value: !fill ? (source[k] ?? 0) : 1,
                meta: {
                  ...source,
                  count: source[k],
                  countLabel: `${source[k]} companies`,
                  label: legendMap[k].label
                }
              }
            } else {
              return {}; // empty
            }
          })
            .filter((b) => {
              return Boolean(legend.find((l) => l.active && l.id === b.id))
            }),
        TooltipComponent: BreakdownTooltip
      };
    } else {
      return null;
    }
  }, [source, legend, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSourceStatsPieChart ref={ref} {...innerProps}>
    <Box className="SourceStatsPieChart-chart">
      <Box className="SourceStatsPieChart-chart-wrapper">
        {sourceData ? <PieChart {...sourceData}
                                showHover={false}
                                hoveredId={internalState.hoveredId}
                                variant={variant}
                                onClick={onClick}
                                isLoading={isLoading}
                                showTooltip={false}
                                TooltipProps={{
                                  density: 'sparse'
                                }}
                                PieProps={{
                                  innerRadius: '84%'
                                }}
                                {...PieChartProps} /> : null}

        <Box className="SourceStatsPieChart-center">
          {showIcon ? <Icon color="primary" icon={TaskAlt} /> : null}

          {showTotal ? <Typography variant="body2"
                                   className="totalCount"
                                   isLoading={isLoading}>
              {((source?.total || 0) > 999) ? '999+' : (source?.total || 0)}
          </Typography> : null}
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="SourceStatsPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          colorLabel={true}
                          showTooltip={false}
                          isLoading={isLoading}
                          orientation={orientation === 'vertical' ? 'horizontal' : 'vertical'}
                          rows={rows}
                          BadgeProps={{
                            size: 'smaller',
                            max: 999
                          }}/> : null}
  </StyledSourceStatsPieChart>
});

SourceStatsPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  source: PropTypes.object,
  total: PropTypes.number,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  showTotal: PropTypes.bool,
  showName: PropTypes.bool,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

SourceStatsPieChart.defaultProps = {
  variant: 'full',
  orientation: 'horizontal',
  showTotal: true,
  showLegend: true
};

export default SourceStatsPieChart;
