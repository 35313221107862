import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useListState} from 'helpers/hooks/utils';
import StyledTeamMembersTable from 'components/organisms/Tables/TeamMembersTable/TeamMembersTable.styles';
import utils from 'helpers/utils';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Typography from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import {useAuthClientId, useAuthIsProxy, useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useClientTeamMemberDelete, useClientTeamMemberList} from 'services/client/team/member/member.hooks';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import Link from 'components/atoms/Links/Link/Link';

const TeamMembersTable = React.forwardRef((props, ref) => {
  const {
    team,
    variant,
    canUpdate,
    ...innerProps
  } = useComponentProps(props, 'TeamMembersTable');

  const userId = useAuthUserId();
  const teamId = useAuthTeamId();
  const isProxy = useAuthIsProxy();
  const clientId = useAuthClientId();

  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();

  const deleteTeamMember = useClientTeamMemberDelete();

  const extended = variant === 'extended';

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25,
        filter: isProxy ? [] : [{id: 'userType', value: constants.user.types.regular}]
      },
      options: {
        name: `teamMembers_${team?.teamId}`,
      }
    }
  }), [team?.teamId, isProxy]);

  const listState = useListState(options.listState?.initial, options.listState?.options);

  const teamMemberList = useClientTeamMemberList({
    clientId,
    teamId: team?.teamId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enabled: (clientId >= 0) && (team.teamId >= 0)
  });

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      id: 'member',
      header: 'Member',
      Cell: ({cell}) => {
        const user = cell.row.original;
        return <UserTableCell user={user?.profile}
                              subtitle={!extended ? <Typography color="text.secondary"
                                                                       variant="caption">
                                <Link href={`mailto:${user.profile?.email}`}>{user.profile?.email}</Link>
                              </Typography> : null}
                              short={true}
                              density={!extended ? 'dense' : null}
                              ActionAvatarProps={{
                                size: extended ? 'medium' : 'large'
                              }} />
      }
    });

    if (extended) {
      columns.push({
        accessorKey: 'email',
        id: 'email',
        header: 'Email',
        Cell: ({cell}) => {
          const user = cell.row.original;
          return <TextTableCell title={<Typography color="text.secondary"
                                                   variant="caption">
            <Link href={`mailto:${user.profile?.email}`}>{user.profile?.email}</Link>
          </Typography>} />
        }
      });
    }

    columns.push({
      id: 'delete',
      size: 40,
      minSize: 40,
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      Cell: ({cell}) => {
        const user = cell.row.original;
        const currentPrivateTeam = team.props.isPrivate && +user.userId === userId && +team.teamId === +teamId;
        const enableEditing = Boolean(canUpdate);

        const action = {
          label: 'Remove team member',
          tooltip: currentPrivateTeam ? 'User can not be removed from active team' : (
            user.isDealLeader ? 'User is a deal leader' : (
             (!team.props.isPrivate && +user.openTeamCount <= 1 && user.profile.type !== constants.user.types.proxy) ? 'User must be a member of at least 1 open team' :
                'Remove team member'
            )
          ),
          icon: <Icon icon={Delete} size="smaller" />,
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) :
            utils.createAuth({attribute: 'team.member.delete', meta: {team, user}}),
          onClick: (e) => {
            const handleConfirm = () => {
              deleteTeamMember.mutation?.mutateAsync({
                clientId,
                teamId: team.teamId,
                userId: user.userId
              }).catch(() => {
                snackbar.show('Removing team member failed', null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
            };

            dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this team member?"
                                              explanation="The user will only be removed from this team"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: 'Remove team member',
                                                color: 'error'
                                              }} />, true);
            e.preventDefault();
          },
        };

        return <IconButtonTableCell ActionIconButtonProps={{
                                      variant: 'outlined',
                                      size: 'smaller',
                                      density: 'sparse',
                                      showInactive: true,
                                      action: action }} />
      }
    });

    return columns;
  }, [userId, teamId, clientId, team, extended, canUpdate,
    dialogControl, snackbar, deleteTeamMember.mutation]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTeamMembersTable ref={ref} {...innerProps}
                                 dataKey="userId"
                                 listState={listState}
                                 onFetchMore={teamMemberList.query.fetchNextPage}
                                 onRefetch={teamMemberList.query.refetch}
                                 columns={columnsMemo}
                                 data={teamMemberList.data}
                                 rowCount={teamMemberList.meta?.resultsCount}
                                 debounce={false}
                                 state={{
                                   isLoading: teamMemberList.status.isLoading,
                                   showProgressBars: teamMemberList.status.isLoadingNext
                                 }} />
});

TeamMembersTable.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  hasEmailColumn: PropTypes.bool,
  canUpdate: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'extended']), PropTypes.string])
};

TeamMembersTable.defaultProps = {
  enableSorting: false,
  enableEditing: false,
  enableTableHead: false,
  enableParentScroll: false,
  enableVirtualization: false,
  enableColumnVirtualization: false,
  enableBottomToolbar: false,
  enableStickyHeader: false,
  enableColumnDragging: false,
  enableColumnOrdering: false,
  enableColumnResizing: false,
  enablePinning: false,
  hasEmailColumn: false,
};

export default TeamMembersTable;
