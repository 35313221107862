import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledListHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1.5)};
  row-gap: ${props => props.theme.layout('1.5sp')};

  .ListHeader {
    &-title {
      flex-grow: 1;
      white-space: nowrap;
    }
    
    &-columns {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
  
  ${ComponentStyles}
`;

StyledListHeader.propTypes = {
  theme: PropTypes.object
};

StyledListHeader.defaultProps = {}

export default StyledListHeader;
