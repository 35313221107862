import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledDealflowEntitiesFilterWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesFilterWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesFilterWrapper.defaultProps = {
};

export default StyledDealflowEntitiesFilterWrapper;
