import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledSettingsBarWrapper = styled(ToolbarWrapper)`
  min-height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));

  ${ComponentStyles}
`;

StyledSettingsBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledSettingsBarWrapper.defaultProps = {}

export default StyledSettingsBarWrapper;
