import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {lighten} from 'polished';
import Box from 'components/atoms/Layout/Box/Box';

const StyledActionList = styled(Box)`
  height: 100%;
  overflow: hidden;
  position: relative;
  
  &.ActionList {
    .ActionList {
      &-list {
        padding: 0;
      }
    }
    
    &.ActionList-depth-0 {
      .ActionList {
        &-scroll {
          overflow-y: auto;
          ::-webkit-scrollbar {
            width: ${props => props.theme.layout('1sp')};
          }
          &:not(:hover, :focus) {
            ::-webkit-scrollbar {
              width: 0;
            }
          }
        }
        &-list {
          padding: 0;
        }

        &-footer {
          width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          pointer-events: none;

          height: ${props => props.theme.layout(48)};
          background: linear-gradient(0deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }
    
    &.ActionList-sub {
      &.ActionList-sub1 {
        padding-left: ${props => props.theme.spacing(1)};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
        background-color: ${props => lighten(0.5, props.theme.property('palette.action.hover'))};
      }

      &.ActionList-sub2 {
        padding-left: ${props => props.theme.spacing(1)};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
        background-color: ${props => lighten(0.4, props.theme.property('palette.action.hover'))};
      }

      &.ActionList-sub3 {
        padding-left: ${props => props.theme.spacing(1)};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
        background-color: ${props => lighten(0.3, props.theme.property('palette.action.hover'))};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledActionList.propTypes = {
  theme: PropTypes.object
};

StyledActionList.defaultProps = {
}

export default StyledActionList;
