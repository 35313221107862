import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import {DateField} from '@mui/x-date-pickers';

const StyledDateField = styled(DateField)`
  .DateField {
    &-clear {
      &.MuiInputAdornment {
        &-positionStart {
          margin-left: 0;
        }
        &-positionEnd {
          margin-right: 0;
        }
      }
    }
  }
  
  .MuiInputAdornment-sizeMedium {
    .MuiIconButton-root {
      svg {
        font-size: ${props => props.theme.fontSize(20)};
      }
    }  
  }
  
  ${ComponentStyles}
`;

StyledDateField.propTypes = {
  theme: PropTypes.object
};

StyledDateField.defaultProps = {
}

export default StyledDateField;
