import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import {DatePicker} from '@mui/x-date-pickers';

const StyledDatePicker = styled(DatePicker)`
  ${ComponentStyles}
`;

StyledDatePicker.propTypes = {
  theme: PropTypes.object
};

StyledDatePicker.defaultProps = {
}

export default StyledDatePicker;
