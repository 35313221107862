import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard';

const StyledDealflowDashboardCard = styled(DashboardCard)`
  &.DealflowDashboardCard {
    display: flex;
    flex-direction: column;
    
    .DashboardCard {
      &-header-title {
        ${props => props.theme.font2Css('h6')};
      }

      &-content {
        height: 100%;
        padding: ${props => props.theme.spacing(2)};
        overflow: hidden;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledDealflowDashboardCard.defaultProps = {}

export default StyledDealflowDashboardCard;
