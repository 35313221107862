import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import StyledRadio from 'components/atoms/Inputs/Radio/Radio.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Radio = withMemo(React.forwardRef((props, ref) => {
  const {
    size,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Radio', {
    static: ['customIcon']
  });

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.variant = isLoading ? 'circular' : innerProps.variant;

  innerProps.className = utils.flattenClassName(innerProps.className, {
    customIcon: innerProps.icon || innerProps.checkedIcon
  });

  return <StyledRadio ref={ref} {...innerProps} size={size === 'smaller' ? 'small' : size}/>
}));

Radio.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool
};

Radio.defaultProps = {
  color: 'primary'
};

export default Radio;
