import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';

const StyledChooseEntityWizardContent = styled(WizardContent)`
  &.ChooseEntityWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('1sp')};
    padding-bottom: 0 !important;

    .Form {
      height: 100%;
      overflow: hidden;

      .Form-fields {
        height: 100%;
        overflow: hidden;

        .FormField-name-target {
          height: 100%;
          
          > .InputContainer {
            flex-grow: 1;
            overflow: hidden;
            
            .ListField-search {
              margin-bottom: ${props => props.theme.layout('0.5sp')};
            }
            
            .ListField-empty {
              margin: ${props => props.theme.layout('0.5sp')} 0;
            }
            
            .ListField-list {
              overflow: auto;
              flex-grow: 1;
              padding-bottom: ${props => props.theme.layout('1sp')};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledChooseEntityWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledChooseEntityWizardContent.defaultProps = {}

export default StyledChooseEntityWizardContent;
