import React from 'react';
import utils from 'helpers/utils';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import {useAuth, useLookupMeta} from 'services/auth/auth.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useAuthControl, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useLocation, useMatches, useParams} from 'react-router-dom';
import Navigate from 'components/organisms/Utils/Navigate/Navigate';

export const AuthRoute = ({ children }) => {
  const auth = useAuth();
  const authControl = useAuthControl();
  const location = useLocation();
  const params = useParams();
  const matches = useMatches();
  const snackbar = useSnackbar();

  const match = matches?.find((m) => {
    return utils.comparePath(m.pathname, location.pathname) &&
      utils.isDefined(m.handle?.auth);
  });

  const authContext = {...match?.handle?.auth} || utils.createAuth();
  authContext.meta = utils.isFunction(authContext.meta) ? authContext.meta(match) :
    (authContext.meta ?? {...match?.params});

  authContext.meta = useLookupMeta(authContext.meta);

  authContext.attribute = authContext.attribute ? utils.resolveAttribute(authContext.attribute, params) : authContext.attribute;

  const authorize = useAuthorize();

  if (!authControl.suspended) {
    const valid = authorize(authContext);

    if (!valid) {
      if (!auth?.loggedIn) {
        return <Navigate to='/auth/login'
                         state={{
                           from: utils.comparePath(location.pathname, '/auth/login') ? location?.state?.from : location,
                           resetSearchParams: true
                         }}
                         replace/>
      } else if (location?.state?.recover && !utils.comparePath(location.pathname, '/')) {
        snackbar.show('You are not authorized to see this information. You are redirected to an allowed page.', null,
          {color: 'warning', autoHideDuration: constants.delay.warning});

        return <Navigate to='/'
                         state={{
                           from: location,
                           resetSearchParams: true
                         }}
                         replace={true}/>
      } else {
        logger.trace('Page not allowed', location);
        return <Navigate to='/error/forbidden'
                         state={{
                           from: location,
                           error: {
                             status: constants.http.status.forbidden,
                             statusText: 'This page is not allowed'
                           }
                         }}
                         replace/>
      }
    } else {
      return <React.Fragment>{children}</React.Fragment>
    }
  }

  return <React.Fragment>{children}</React.Fragment>
};


AuthRoute.propTypes = {
}

export default AuthRoute;
