import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useAuthTeamId} from 'services/auth/auth.utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledTaskProfile from 'components/organisms/Profiles/TaskProfile/TaskProfile.styles';
import TaskProfileCardContent from 'components/organisms/Cards/TaskProfileCardContent/TaskProfileCardContent';
import profile from 'helpers/profile';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import {useTaskCompanyAction, useTaskLocation} from 'services/comment/comment.utils';
import constants from 'helpers/constants';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import TaskProgressProfileCard from 'components/organisms/Cards/TaskProgressProfileCard/TaskProgressProfileCard';
import {Span} from 'components/atoms/Text/Typography/Typography';
import logger from 'helpers/logger';

const TaskProfile = (props) => {
  const {
    cards,
    onCanUpdate,
    onClose,
    ...innerProps
  } = useComponentProps(props, 'TaskProfile');

  const profileProvider = useProfile();
  const task = profileProvider.data?.data;
  const cardDefinitions = profileProvider.cardDefinitions;

  const teamId = useAuthTeamId();
  const teamMemberOptions = useClientTeamMemberOptions(teamId, true, false);

  const authorize = useAuthorize();

  const taskLocation = useTaskLocation(task);
  const taskCompanyAction = useTaskCompanyAction(task);

  const onCloseEvent = useEffectEvent(onClose);
  const taskAction = useMemo(() => ({
    ...taskCompanyAction,
    onClose: onCloseEvent
  }), [taskCompanyAction, onCloseEvent]);

  const updateEvent = useEffectEvent(profileProvider.updaters?.updateData);
  const handlePatch = useCallback((task, field, value, onSuccess, onError) => {
    const promises = [];
    if (field.name === 'promise') {
      promises.push(value);
    } else {
      promises.push(updateEvent?.(task, {[field.name]: value}));
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Task save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent]);

  const handleSubmit = useCallback((task, values, fields, actions, onSuccess, onError) => {
    let promise;
    const changes = {};
    Object.keys(values).forEach((k) => {
      const field = fields.find((f) => f.name === k);
      if (field) {
        changes[k] = values[k];
      } else if (k === 'promise') {
        promise = values[k];
      }
    });

    const promises = [];
    if (!utils.isEmpty(changes)) {
      promises.push(updateEvent?.(task, changes));
    }
    if (utils.isDefined(promise)) {
      promises.push(promise);
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Task save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent]);

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const cardsMemo = useMemo(() => {
    if (cardDefinitions) {
      const taskCards = cardDefinitions.map((cardDef) => {
        const canEditCard = (task) => {
          const canEditTask = (onCanUpdateEvent ? onCanUpdateEvent?.(task) : true) &&
            authorize({attribute: 'task.update', meta: {task}});

          return canEditTask && !(cardDef.readOnly ?? false) && authorize({...cardDef.auth?.update, meta: {...cardDef.auth?.update?.meta, task}});
        }

        const fields = cardDef.fields?.map((field) => {
          const canEditField = (task) => {
            return onCanUpdateEvent ? onCanUpdateEvent?.(task, field) : (field.auth?.update ?
              authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, task}}) : true);
          }

          return {
            ...field,
            editable: ({data}) => canEditField(data)
          }
        });

        if (cardDef.name === 'progress') {
          return {
            ...cardDef,
            editable: false,
            Card: ({ref, data, renderedHeader, isDialog, isLoading}) => {
              return <TaskProgressProfileCard ref={ref}
                                              task={data}
                                              isDialog={isDialog}
                                              renderedHeader={renderedHeader}
                                              isLoading={isLoading} />
            },
            hidden: ({state}) => {
              return state.isEditing;
            }
          }
        } else {
          const contents = profile.fields2Contents(fields, [], {ContentComponent: TaskProfileCardContent});

          let title = cardDef.title;
          if (cardDef.name === 'info') {
            title = ({data, isTooltip}) => {
              if (data) {
                const title = (data.actionItem?.title ?? 'Task');
                if (isTooltip) {
                  return 'task';
                } else {
                  return <React.Fragment>
                    <Span>{title} for&nbsp;<ActionLink action={taskAction}/></Span>
                  </React.Fragment>
                }
              }
            }
          }

          if (contents.length > 0) {
            return {
              ...cardDef,
              title: title,
              editable: ({profile}) => canEditCard(profile.data),
              contents: contents,
              onPatch: handlePatch,
              onSubmit: handleSubmit
            }
          } else {
            return null;
          }
        }
      }).filter((_) => (_));

      return profile.mergeCards(taskCards, cards);

    } else {
      return null;
    }
  }, [cardDefinitions, cards, authorize, taskAction, handlePatch, handleSubmit, onCanUpdateEvent]);

  const dataMemo = useMemo(() => {
    let responsible = utils.toArray(task?.actionItem?.responsible)
      .map((m) => {
        return teamMemberOptions?.find((mb) => +mb.value === +m.userId);
      })
      .filter((_) => (_));
    if (responsible.length === 0) {
      responsible = [constants.client.team.allMembers]
    }

    return {
      ...task,
      ...task?.actionItem,
      comment: task?.text,
      location: taskLocation,
      responsible: responsible,
      status: task?.actionItem?.isDone ? 'Closed' : 'Open',
      completedAt: task?.actionItem?.doneWhen,
      completion: (!task?.actionItem?.type || task?.actionItem?.type === constants.task.types.other) ? 'Manually' : 'Automatically',
      collaborationType: constants.data.lookup('taskCollaborationTypes', task?.actionItem?.collaborationType) ??
        constants.data.lookup('taskCollaborationTypes', constants.task.collaborationTypes.collective),
      createdBy: task?.user
    }
  }, [task, teamMemberOptions, taskLocation]);

  return <StyledTaskProfile {...innerProps}
                            cards={cardsMemo}
                            data={dataMemo}/>
};

TaskProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func
};

TaskProfile.defaultProps = {
};

export default TaskProfile;
