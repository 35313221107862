import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {wrapChartComponent} from 'helpers/wrapper';
import utils from 'helpers/utils';
import StyledActionPie from 'components/molecules/Charts/ActionPie/ActionPie.styles';
import {Pie} from 'recharts';

const ActionPie = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    activeShape,
    ...innerProps
  } = useComponentProps(props, 'ActionPie', {
    static: ['clickable']
  });

  const itm = useActionItem(action);
  const handleActionClick = useActionItemAction(itm?.onClick, onClick);

  const handleClick = (data, index, e) => {
    handleActionClick?.(e, data, index);
  }

  const renderActiveShape = (props) => {
    return activeShape?.({
      onClick: (e) => {
        handleActionClick?.(e, props, props.value - 1);
      },
      ...props
    });
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: Boolean(handleActionClick)
  });

  return <StyledActionPie ref={ref} {...innerProps}
                          onClick={handleClick}
                          activeShape={activeShape ? renderActiveShape : null} />
});

ActionPie.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func
};

ActionPie.defaultProps = {
};

export default wrapChartComponent(ActionPie, Pie);
