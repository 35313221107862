import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress.styles';
import constants from 'helpers/constants';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import Typography from 'components/atoms/Text/Typography/Typography';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ScoreLinearProgress = React.forwardRef((props, ref) => {
  const {
    score,
    showPercentage,
    showEmpty,
    color,
    reverse,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ScoreLinearProgress');

  const scoreDef = constants.data.score.lookup(reverse ? (100 - (score ?? 0)) : (score ?? 0));

  return <StyledScoreLinearProgress ref={ref} {...innerProps} $score={score}>
    <Tooltip arrow title={`Score: ${Math.round(score)}%`}>
      <React.Fragment>
        {(score > 0 || showEmpty) ? <LinearProgress variant="determinate"
                                                    value={score}
                                                    color={color ?? scoreDef.color}
                                                    isLoading={isLoading}/> : null}
        {showPercentage ? <Typography variant="caption" color="white" isLoading={isLoading}>
          {`${Math.round(score)}%`}
        </Typography> : null}
      </React.Fragment>
    </Tooltip>
  </StyledScoreLinearProgress>
});

ScoreLinearProgress.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  score: PropTypes.number,
  showPercentage: PropTypes.bool,
  showEmpty: PropTypes.bool,
  color: PropTypes.string,
  reverse: PropTypes.bool,
  isLoading: PropTypes.bool
};

ScoreLinearProgress.defaultProps = {};

export default ScoreLinearProgress;
