import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDialogActions from 'components/atoms/Dialogs/DialogActions/DialogActions.styles';

const DialogActions = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DialogActions');

  return <StyledDialogActions ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDialogActions>
});

DialogActions.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DialogActions.defaultProps = {
  children: 'DialogActions text'
};

export default DialogActions;
