import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Layout/Box/Box';

const StyledContentNavigation = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0;
  
  .ContentNavigation {
    &-title {
      ${props => props.theme.font2Css('body2')};
      margin-right: ${props => props.theme.spacing(0.5)};
    }
  }
  ${ComponentStyles}
`;

StyledContentNavigation.propTypes = {
  theme: PropTypes.object
};

StyledContentNavigation.defaultProps = {
}

export default StyledContentNavigation;
