function fields2Contents (fields, contentSplit = [], props = null) {
  const contents = [];

  let batch = {variant: null, fields: []};
  fields
    .sort((a, b) => a.position - b.position)
    .forEach((f) => {
      if (batch.fields.length > 0 && (
          contentSplit.includes(batch.variant) || batch.variant !== (f.variant ?? 'detail')
         )) {
        contents.push({
          variant: batch.variant,
          fields: batch.fields,
          ...props
        });
        batch = {variant: null, fields: []};
      }

      batch.variant = (f.variant ?? 'detail');
      batch.fields.push(f);
    });

  if (batch.fields.length > 0) {
    contents.push({
      variant: batch.variant,
      fields: batch.fields,
      ...props
    });
  }

  return contents;
}

function mergeCards (cardsA, cardsB) {
  return cardsA.concat((cardsB ?? []).map((card) => {
    const cardA = cardsA.find((ec) => ec.name === card.name)
    if (cardA) {
      cardA.title = card.title ?? cardA.title;
      cardA.action = card.action ?? cardA.action;
      cardA.contents = cardA.contents.concat(card.contents?.map((content) => {
        const fieldContent = cardA.contents?.find((c) => {
          return !c.Content && c?.content?.variant === content.variant;
        });

        // merge similar contents
        if (!content.Content && content.fields?.length > 0 && fieldContent) {
          fieldContent.variant = content.variant ?? fieldContent.variant;
          fieldContent.fields = fieldContent.fields.concat(content.fields);

          return null;
        } else {
          return content;
        }
      }).filter((_) => (_)));

      return null;
    } else {
      return card;
    }
  }).filter((_) => (_)));
} 

const profile = {
  fields2Contents,
  mergeCards
};

export default profile;
