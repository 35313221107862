import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEmail from 'components/pages/Base/Email/Email.styles';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import Table from 'components/atoms/Tables/Table/Table';
import TableHead from 'components/atoms/Tables/TableHead/TableHead';
import TableFooter from 'components/atoms/Tables/TableFooter/TableFooter';
import Typography, {H2, P} from 'components/atoms/Text/Typography/Typography';
import TableBody from 'components/atoms/Tables/TableBody/TableBody';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import Link from 'components/atoms/Links/Link/Link';
import Img from 'components/atoms/Images/Img/Img';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';

const path = utils.componentPath(import.meta.url);

const Email = (props) => {
  const {
    preview,
    header,
    salutation,
    footer,
    claim,
    support,
    ...innerProps
  } = useComponentProps(props, 'Email', {
    styled: ['color']
  });

  const clientConfig = useClientConfig(innerProps.$componentName);
  const config = useMemo(() => {
    return utils.mergeObjects(clientConfig, innerProps.config, true);
  }, [clientConfig, innerProps.config]);

  const component = useClientComponent(config, `${path}/${innerProps.$componentName}`, props);

  const renderHeader = () => {
    if (header) {
      return header;
    } else {
      return <TableRow className="Email-headerRow">
        <TableCell style={{width: '1%'}}>
          <Img alt='logo'
               src="https://public-ventureiq-io.s3.eu-central-1.amazonaws.com/v2.ventureiq.io/images/logo.png"/>
        </TableCell>
        <TableCell style={{width: '99%'}}>
          <Typography variant="brand">Catalist</Typography>
        </TableCell>
      </TableRow>
    }
  }

  const renderFooter = () => {
    if (footer) {
      return footer;
    } else {
      return <TableRow className="Email-footerRow">
        <TableCell colSpan={2}>
          {config?.footer?.text ?? ''}
        </TableCell>
      </TableRow>
    }
  }

  const renderSalutation = () => {
    if (salutation) {
      return salutation;
    } else {
      return <TableRow className="Email-salutationRow">
        <TableCell colSpan={2}>
          <H2 color="primary">Hi {'{{USER_FULLNAME}}'},</H2>
        </TableCell>
      </TableRow>
    }
  }

  const renderClaim = () => {
    if (claim) {
      return claim;
    } else if (config?.claim) {
      return <TableRow className="Email-claimRow">
        <TableCell colSpan={2}>
          <Img alt='claim'
               style={config.claim.style}
               src={config.claim.src}/>
        </TableCell>
      </TableRow>
    }
  }

  const renderSupport = () => {
    if (support) {
      return support;
    } else {
      const name = config?.support?.name ?? 'Team Catalist';
      const email = config?.support?.email ?? '{{SUPPORT_EMAIL}}';
      const pre = config?.support?.pre;
      const post = config?.support?.post;
      const regards = config?.support?.regards ?? 'Kind regards,';
      return <React.Fragment>
        {regards ? <TableRow className={`Email-regardsRow ${config?.support?.center ? 'centered' : ''}`}>
          <TableCell colSpan={2}>
            <P>{regards}</P>
          </TableCell>
        </TableRow> : null}
        {(name || email || post) ? <TableRow className={`Email-supportRow ${config?.support?.center ? 'centered' : ''}`}>
          <TableCell colSpan={2}>
            <P>
              {name ? <React.Fragment>{name}<br/></React.Fragment> : null}
              {pre ? <React.Fragment>{pre}</React.Fragment> : null}
              {email ? <Link href={`mailto:${email}`}>{email}</Link> : null}
              {post ? <React.Fragment>{post}</React.Fragment> : null}
            </P>
          </TableCell>
        </TableRow> : null}
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledEmail {...innerProps}>
        {(config?.preview ?? preview) ? <Box className="preview" style={{
          display: 'none',
          maxHeight: '0px',
          overflow: 'hidden'
        }}>{config?.preview ?? preview}{'{{PREVIEW_WHITE_SPACE}}'}</Box> : null}
        <Table className="Email-table" style={{height: '100%', width: '100%'}}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Table style={{margin: '0px auto'}} className="Table-outer">
                  <TableHead>
                    {renderHeader()}
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Table style={{width: '100%'}} className="Table-inner">
                          <TableHead>
                            {renderClaim()}
                            {renderSalutation()}
                          </TableHead>
                          <TableBody>
                            {innerProps.children}
                            {renderSupport()}
                            <TableRow>
                              <TableCell style={{height: innerProps.theme.spacing(1)}} colSpan={2}/>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    {renderFooter()}
                  </TableFooter>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledEmail>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

Email.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  preview: PropTypes.string,
  header: PropTypes.any,
  salutation: PropTypes.any,
  footer: PropTypes.any,
  claim: PropTypes.any,
  support: PropTypes.any,
  hideSupport: PropTypes.bool
};

Email.defaultProps = {
  color: 'primary'
};

export default Email;
