import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useEntityTagGroupStatus () {
  return useServiceStatus(path);
}

export function useEntityTagGroupUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useEntityTagGroupAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityTagGroupDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
