import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledTeamsSettingPage = styled(Page)`
  min-width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
  
  .TeamsSettingPage {
    &-teams {
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
      &-team {
        aspect-ratio: 536/362;
      }
    }
  }

  ${ComponentStyles}
`;

StyledTeamsSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledTeamsSettingPage.defaultProps = {}

export default StyledTeamsSettingPage;
