import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledStepper from 'components/atoms/Steppers/Stepper/Stepper.styles';
import utils from 'helpers/utils';

const Stepper = React.forwardRef((props, ref) => {
  const {
    connectors,
    ...innerProps
  } = useComponentProps(props, 'Stepper', {
    static: ['connectors']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledStepper ref={ref} {...innerProps} />
});

Stepper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showConnectors: PropTypes.bool
};

Stepper.defaultProps = {
  connectors: true
};

export default Stepper;
