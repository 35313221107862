import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledApplicantPage = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .ApplicantForm {
    padding: ${props => props.theme.spacing(2)};
    width: 100%;
  }
  
  ${ComponentStyles}
`;

StyledApplicantPage.propTypes = {
  theme: PropTypes.object
};

StyledApplicantPage.defaultProps = {
};

export default StyledApplicantPage;
