import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntityRelevancyTableCellEdit
  from 'components/organisms/TableCellEdits/EntityRelevancyTableCellEdit/EntityRelevancyTableCellEdit.styles';
import constants from 'helpers/constants';
import EntityRelevancyTableCell from 'components/molecules/TableCells/EntityRelevancyTableCell/EntityRelevancyTableCell';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import List from 'components/atoms/Lists/List/List';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const EntityRelevancyTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    relevancy,
    size,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'EntityRelevancyTableCellEdit');

  const onChangeEvent = useEffectEvent(onChange);
  const options = useMemo(() => {
    return constants.data.hml.filter((r) => r.value !== relevancy)
      .reduce((a, r) => {
        a.push({
          label: r.label,
          meta: {relevancy: r.value},
          ButtonProps: {
            label: r.label,
            color: r.buttonColor,
            size: size,
            variant: 'flat',
            fullWidth: true
          },
          onClick: (e) => {
            cell.column.columnDef.optimistic.set(cell, r.value);
            table.setEditingCell(null);
            utils.asPromise(onChangeEvent)(cell.row.original, r.value)
              .catch(() => {
                cell.column.columnDef.optimistic.reset(cell);
                table.setEditingCell(cell);
              });

            e.preventDefault();
          }
        })
        return a;
      }, []);
  }, [relevancy, table, cell, size, onChangeEvent]);

  return <StyledEntityRelevancyTableCellEdit ref={ref} {...innerProps}
                                             cell={cell}
                                             table={table}
                                             Anchor={<EntityRelevancyTableCell relevancy={relevancy}
                                                                               active={true}
                                                                               size={size}/>}>
    <List track={true} gap={4}>
      {options.map((action, idx) => {
        return <ListItem key={idx}>
          <ActionButton action={action}
                        fullWidth
                        size={size}
                        variant="flat"/>
        </ListItem>
      })}
    </List>
  </StyledEntityRelevancyTableCellEdit>
}));

EntityRelevancyTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  relevancy: PropTypes.number,
  size: PropTypes.string,
  onChange: PropTypes.func
};

EntityRelevancyTableCellEdit.defaultProps = {
  size: 'medium'
};

export default EntityRelevancyTableCellEdit;


