import React, {useMemo} from 'react'
import DashboardProfileProvider from 'components/organisms/Providers/ProfileProvider/DashboardProfileProvider';
import DashboardContextWrapper
  from 'components/templates/Wrappers/Dashboard/DashboardContextWrapper/DashboardContextWrapper';
import TaskProfileWrapper from 'components/templates/Wrappers/Tasks/TaskProfileWrapper/TaskProfileWrapper';
import DashboardPage from 'components/pages/Dashboard/DashboardPage/DashboardPage';

export default function useDashboardRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <DashboardPage />,

        handle: {
          //scrollKey: 'pathname'
          title: 'Dashboard'
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <DashboardProfileProvider>
          <DashboardContextWrapper>
            <TaskProfileWrapper>
              {children}
            </TaskProfileWrapper>
          </DashboardContextWrapper>
        </DashboardProfileProvider>
      },
      route: route
    };
  }, []);
};
