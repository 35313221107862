import {useServiceApiQuery} from 'stores/hooks/query';
import {useServiceStatus} from 'stores/hooks/service';
import utils from 'helpers/utils';

const path = utils.servicePath(import.meta.url);

export function useTestStatus () {
  return useServiceStatus(path);
}

export function useTestList ({filter}) {
  return useServiceApiQuery(path, 'list', {filter});
}
