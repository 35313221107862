import React, {useMemo} from 'react'
import DealflowEntityProfileProvider
  from 'components/organisms/Providers/ProfileProvider/DealflowEntityProfileProvider';
import DealflowEntitySideBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntitySideBarWrapper/DealflowEntitySideBarWrapper';
import DealflowEntityContextWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityContextWrapper/DealflowEntityContextWrapper';
import EntityTaskProfileWrapper
  from 'components/templates/Wrappers/Tasks/EntityTaskProfileWrapper/EntityTaskProfileWrapper';
import utils from 'helpers/utils';
import DealflowEntityBrowserPage
  from 'components/pages/Dealflow/Entity/DealflowEntityBrowserPage/DealflowEntityBrowserPage';
import DealflowEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityBrowserBarWrapper/DealflowEntityBrowserBarWrapper';
import DealflowEntityProfileBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityProfileBarWrapper/DealflowEntityProfileBarWrapper';
import DealflowEntityProfilePage
  from 'components/pages/Dealflow/Entity/DealflowEntityProfilePage/DealflowEntityProfilePage';

export default function useDealflowEntityRoutes () {
  return useMemo(() => {
    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'entity',
            path: 'entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <DealflowEntityProfileBarWrapper>
          <DealflowEntityProfilePage />
        </DealflowEntityProfileBarWrapper>,

        handle: {
          title: 'Dealflow - company',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.profile.read',
            meta: entityMeta
          })
        }
      },
      {
        path: 'browser',
        element: <DealflowEntityBrowserBarWrapper>
          <DealflowEntityBrowserPage />
        </DealflowEntityBrowserBarWrapper>,

        handle: {
          title: 'Dealflow - browser',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.browser.read',
            meta: entityMeta
          })
        }
      },
    ];

    return {
      WrapperComponent: ({children}) => <DealflowEntityProfileProvider>
        <DealflowEntitySideBarWrapper>
          <DealflowEntityContextWrapper>
            <EntityTaskProfileWrapper>
              {children}
            </EntityTaskProfileWrapper>
          </DealflowEntityContextWrapper>
        </DealflowEntitySideBarWrapper>
      </DealflowEntityProfileProvider>,
      route: route
    };
  }, []);
};
