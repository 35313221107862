import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSimpleQuery = styled(Box)`
  display: flex;
  align-items: center;
  
  .SimpleQuery {
    &-form {
      .FormControl {
        flex-grow: 1;

        .InputContainer {
          .MuiInputBase-root {
            background-color: ${props => props.theme.property('palette.background.paper')};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSimpleQuery.propTypes = {
  theme: PropTypes.object
};

StyledSimpleQuery.defaultProps = {}

export default StyledSimpleQuery;
