import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledClientCollaboratorsProfileCardContent = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: hidden;

  .ClientCollaboratorsProfileCardContent {
    &-content {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    
    &-readOnly {
      height: 100%;
      width: 100%;
      overflow: hidden;
      
      .Table {
        .MuiTableContainer-root {
          padding: 0 ${props => props.theme.spacing(2)};
        }
        
        tr {
          min-height: ${props => props.theme.layout(60)};
          max-height: ${props => props.theme.layout(60)};
        }
        tr:last-child {
          td {
            border-bottom: unset !important;
          }
        }
    
        td {
          padding: ${props => props.theme.layout(0)} ${props => props.theme.layout('1sp')};
        }
    
        th:first-child, td:first-child {
          padding-left: 0;
        }
    
        th:last-child, td:last-child {
          padding-right: 0;
        }
    
        tr:hover td {
          background-color: unset !important;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledClientCollaboratorsProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledClientCollaboratorsProfileCardContent.defaultProps = {}

export default StyledClientCollaboratorsProfileCardContent;
