import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import StyledActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar.styles';
import utils from 'helpers/utils';

const ActionAvatar = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionAvatarProps}, 'ActionAvatar');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm && !showInactive) {
    return <React.Fragment />;
  } else {
    innerProps.className = utils.flattenClassName(innerProps.className);

    return <Tooltip title={action.tooltip}
                    fullWidth={innerProps.fullWidth ?? action.AvatarProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionAvatar ref={ref} {...innerProps}
                          disabled={!itm}
                          onClick={handleClick}
                          {...action.AvatarProps}>
        {action.label}
      </StyledActionAvatar>
    </Tooltip>
  }
});

ActionAvatar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionAvatar.defaultProps = {
};

export default ActionAvatar;
