import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityTimelinePanel from 'components/organisms/Panels/EntityTimelinePanel/EntityTimelinePanel';

const StyledEntityTimelineSectionPanelContent = styled(EntityTimelinePanel)`
  ${ComponentStyles}
`;

StyledEntityTimelineSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityTimelineSectionPanelContent.defaultProps = {}

export default StyledEntityTimelineSectionPanelContent;
