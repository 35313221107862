import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCollectionEntitiesPage = styled(Page)`
  .TableWrapper-content {
    > .Wrapper {
      &:has(.CollectionEntitiesPage-empty) {
        width: 100%;
      }
    }
  }
  
  .CollectionEntitiesPage {
    &-onboarding {
      position: fixed;
      right: ${props => props.theme.layout('4sp')};
      bottom: ${props => props.theme.layout('4sp')};
    }
    
    &-empty {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      padding-top: ${props => props.theme.layout(60)};
      
      .Icon {
        width: ${props => props.theme.layout(148)};
        height: auto;
      }

      .Typography:last-child {
        margin-top: ${props => props.theme.layout('1sp')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesPage.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesPage.defaultProps = {
};

export default StyledCollectionEntitiesPage;
