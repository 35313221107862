import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityBrowserBarWrapper/DealflowEntityBrowserBarWrapper.styles';
import DealflowEntityBrowserBar from 'components/organisms/Bars/DealflowEntityBrowserBar/DealflowEntityBrowserBar';

const DealflowEntityBrowserBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityBrowserBarWrapper');

  return <StyledDealflowEntityBrowserBarWrapper ref={ref} {...innerProps}
                                                variant="sticky">
    {innerProps.children}
  </StyledDealflowEntityBrowserBarWrapper>
});

DealflowEntityBrowserBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityBrowserBarWrapper.defaultProps = {
  ToolbarComponent: DealflowEntityBrowserBar
};

export default DealflowEntityBrowserBarWrapper;
