import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDatabaseEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesColumnsWrapper/DatabaseEntitiesColumnsWrapper.styles';
import DatabaseEntitiesTableColumns
  from 'components/organisms/TableColumns/DatabaseEntitiesTableColumns/DatabaseEntitiesTableColumns';

const DatabaseEntitiesColumnsWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesColumnsWrapper');

  const tableProvider = useTable();

  return <StyledDatabaseEntitiesColumnsWrapper ref={ref} {...innerProps}
                                               variant="foldout"
                                               context={<DatabaseEntitiesTableColumns />}
                                               open={Boolean(tableProvider.state?.showColumns)}
                                               size="huge"
                                               ContextDrawerProps={{
                                                 onToggle: (e, open) => {
                                                   if (open) {
                                                     tableProvider.openColumns();
                                                   } else {
                                                     tableProvider.closeColumns();
                                                   }
                                                 }
                                               }}>
    {innerProps.children}
  </StyledDatabaseEntitiesColumnsWrapper>
});

DatabaseEntitiesColumnsWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesColumnsWrapper.defaultProps = {
  children: 'DatabaseEntitiesColumnsWrapper text',
};

export default DatabaseEntitiesColumnsWrapper;
