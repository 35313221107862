import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useChartMouse, useComponentProps} from 'helpers/hooks/utils';
import StyledPieChart from 'components/organisms/Charts/PieChart/PieChart.styles';
import {
  Cell, Legend,
  ResponsiveContainer, Sector,
  Tooltip,
} from 'recharts';
import ChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import ActionPie from 'components/molecules/Charts/ActionPie/ActionPie';

const PieChart = React.forwardRef((props, ref) => {
  const {
    data,
    dataKey,
    nameKey,
    hoveredId,
    onClick,
    showTooltip,
    showLegend,
    showHover,
    showActiveShape,
    square,
    isLoading,
    PieProps,
    TooltipComponent,
    TooltipProps,
    ResponsiveContainerProps,
    ...innerProps
  } = useComponentProps(props, 'PieChart', {
    static: ['isLoading', 'clickable'],
    children: ['tooltip', 'pie']
  });

  const innerRef = useRef(null);
  const containerRef = useRef(null);

  const clickable = Boolean(onClick);

  const
    pieChart = useMemo(() => ({
    refs: {
      ref: innerRef,
      containerRef
    }
  }), []);

  useImperativeHandle(ref, () => pieChart);

  const chartMouse = useChartMouse();

  const half = innerProps.variant === 'half';

  const handleContainerRef = (element) => {
    containerRef.current = element;
    setTimeout(() => {
      const pieChart = element?.current?.querySelector('.recharts-pie');
      if (pieChart) {
        pieChart.tabIndex = '-1';
      }
    }, constants.delay.shortest);
  };

  const sortedData = useMemo(() => {
    if (data) {
      return data
        .sort((a, b) => {
          if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
            return a.position - b.position;
          } else {
            return (a.label ?? a.name).localeCompare(b.label ?? b.name);
          }
        })
    }
  }, [data]);

  const activeIndex = useMemo(() => {
    return sortedData.findIndex((entry, cellIdx) => {
      return chartMouse.isDetailHovering(dataKey, cellIdx);
    });
  }, [sortedData, dataKey, chartMouse]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      onClick
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 24) * cos;
    const my = cy + (outerRadius + 24) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 12;
    const ey = my;
    const textAlign = cos >= 0 ? 'left' : 'right';
    const labelWidth = (!square || (Math.abs(midAngle - 180) > 30 && Math.abs(midAngle - 360) > 30)) ? 100 : 60;
    const fx = (ex + (cos >= 0 ? 1 : -1) * 4) + ((cos >= 0 ? 0 : -1) * labelWidth);
    const fy = ey - 18;

    return <g className="PieChart-activeShape" onClick={onClick}>
      <Sector cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}/>
      <Sector cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}/>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill} fill="none"/>
      <foreignObject x={Math.max(0, fx)}
                     y={Math.max(0, fy)}
                     width={labelWidth + (fx < 0 ? fx : 0)}
                     height={200}
                     style={{
                       pointerEvents: 'none',
                       textAlign: textAlign
                     }}>
        <Box className="PieChart-activeShape-tick">
          <Typography className="PieChart-activeShape-label"
                      showTooltip={true}
                      variant="body2">
            {payload.label}
          </Typography>
          <Typography className="PieChart-activeShape-percentage"
                      variant="caption">{`${(percent * 100).toFixed(0)}%`}</Typography>
        </Box>
      </foreignObject>
    </g>
  };

  innerProps.onMouseOut = innerProps.onMouseOut ?? chartMouse.handleChartMouseOut;

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable
  });

  return <ResponsiveContainer ref={handleContainerRef} {...ResponsiveContainerProps}>
    <StyledPieChart ref={pieChart.refs.ref} {...innerProps}>
      <ActionPie data={sortedData}
                 dataKey={dataKey}
                 nameKey={nameKey}
                 onClick={onClick}
                 startAngle={half ? 180 : 360}
                 endAngle={0}
                 innerRadius={half ? '88%' : (showActiveShape ? '53%' : '79%')}
                 outerRadius={showActiveShape ? '64%' : '100%'}
                 onMouseOver={chartMouse.handleDetailMouseMove(dataKey)}
                 stroke='none'
                 paddingAngle={5}
                 activeIndex={activeIndex}
                 activeShape={showActiveShape ? renderActiveShape : null}
                 {...PieProps}>
        {sortedData
          .map((entry, cellIdx) => {
            const hovering = showHover && (utils.isDefined(hoveredId) || chartMouse.isHovering());
            const hovered = showHover && (utils.isDefined(hoveredId) ? (hoveredId === entry.id) :
              chartMouse.isDetailHovering(dataKey, cellIdx));

            return <Cell key={cellIdx}
                         className={`PieChart-pie ${hovering ? (hovered ? 'hover' : 'notHover') : ''}`}
                         fill={entry.color}/>
          })}
      </ActionPie>

      {showLegend && <Legend className="PieChart-legend"/>}
      {showTooltip && <Tooltip className="PieChart-tooltip"
                               content={<TooltipComponent wrapper={pieChart.refs.containerRef.current?.current}
                                                          activateByPayload={true}
                                                          activePayload={chartMouse.hoverPayLoadKey}
                                                          activeIndex={chartMouse.activeIndex}
                                                          {...TooltipProps} />} />}
    </StyledPieChart>
  </ResponsiveContainer>
});

PieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dataKey: PropTypes.string,
  nameKey: PropTypes.string,
  hoveredId: PropTypes.any,
  onClick: PropTypes.func,
  showLegend: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showActiveShape: PropTypes.bool,
  square: PropTypes.bool,
  isLoading: PropTypes.bool,
  PieProps: PropTypes.object,
  TooltipComponent: PropTypes.any,
  TooltipProps: PropTypes.object,
  ResponsiveContainerProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

PieChart.defaultProps = {
  dataKey: 'value',
  nameKey: 'name',
  variant: 'full',
  showTooltip: true,
  showHover: true,
  square: true,
  TooltipComponent: ChartTooltip
};

export default PieChart;
