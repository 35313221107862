import {useCallback} from 'react';
import utils from 'helpers/utils';
import {useCollectionTagGroupUpdate} from 'services/collection/tagGroup/tagGroup.hooks';

export function useCollectionTagGroupPatch () {
  const tagGroupUpdate = useCollectionTagGroupUpdate();

  return useCallback((collection, tagGroup) => {
    const original = collection.tagGroups?.find((tg) => +tg.groupId === +tagGroup.groupId);
    const changes = (Object.keys(tagGroup).length > 1) ? utils.changedFormFields(original, tagGroup) : tagGroup;

    if (!utils.isEmpty(changes)) {
      return tagGroupUpdate.mutation.mutateAsync({
        collectionId: collection.collectionId,
        groupId: tagGroup.groupId,
        ...original,
        ...{
          ...changes,
          tags: utils.underscoreEx(changes.tags ?? original.tags)
        }
      });
    } else {
      return Promise.resolve();
    }
  }, [tagGroupUpdate.mutation]);
}
