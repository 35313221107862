import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledInputLabel from 'components/atoms/Labels/InputLabel/InputLabel.styles';
import {withMemo} from 'helpers/wrapper';

const InputLabel = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'InputLabel');

  return <StyledInputLabel ref={ref} {...innerProps}>{innerProps.children}</StyledInputLabel>
}));

InputLabel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

InputLabel.defaultProps = {
  children: 'InputLabel text'
};

export default InputLabel;
