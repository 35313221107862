import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import ActionFab from 'components/molecules/Fabs/ActionFab/ActionFab';
import StyledTinderBar from 'components/organisms/Bars/TinderBar/TinderBar.styles';

const TinderBar = React.forwardRef((props, ref) => {
  const {
    deleteAction,
    addAction,
    ActionFabProps,
    ...innerProps
  } = useComponentProps(props, 'TinderBar');

  const deleteActionMemo = useMemo(() => ({
    icon: Delete,
    ActionFabProps: {
      color: 'error',
      size: 'larger',
      IconProps: {
        size: 'larger'
      }
    },
    ...deleteAction
  }), [deleteAction]);

  const addActionMemo = useMemo(() => ({
    icon: Add,
    ActionFabProps: {
      color: 'primary',
      size: 'larger',
      IconProps: {
        size: 'larger'
      }
    },
    ...addAction
  }), [addAction]);

  return <StyledTinderBar ref={ref} {...innerProps}>
    <ActionFab action={deleteActionMemo} showInactive={true} {...ActionFabProps} />
    <ActionFab action={addActionMemo} showInactive={true} {...ActionFabProps} />
  </StyledTinderBar>
});

TinderBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  deleteAction: PropTypes.object,
  addAction: PropTypes.object
};

TinderBar.defaultProps = {
  elevation: 2
};

export default TinderBar;
