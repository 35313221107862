import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Email from 'components/pages/Base/Email/Email';

const StyledApplicantEmailPage = styled(Email)`
  ${ComponentStyles}
`;

StyledApplicantEmailPage.propTypes = {
  theme: PropTypes.object
};

StyledApplicantEmailPage.defaultProps = {
};

export default StyledApplicantEmailPage;
