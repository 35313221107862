import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledCollectionEntitiesSuggestionsDialog = styled(Dialog)`
  &.CollectionEntitiesSuggestionsDialog {
    .MuiDialog-paper {
      max-width: unset;
      max-height: unset;
      width: 80vw;
      height: calc(90vh - ${props => props.theme.layout(42)});
      transform: translateY(${props => props.theme.layout('-1sp')});
      overflow: visible;
      
      > .Wrapper {
        overflow: visible;
      }
      
      .ContextWrapper {
        height: 100%;
        min-height: unset;
        position: relative;
        
        .ContextWrapper-content {
          height: 100%;
          .ToolbarWrapper {
            height: 100%;
            .ToolbarWrapper-content {
              .CollectionEntitiesSuggestionBrowser {
                height: 100%;
              }
            }
          }
        }
      }
      
      .CollectionEntitiesSuggestionsBarWrapper {
        > .ToolbarWrapper-content {
          border-radius: ${props => props.theme.radius(1)};
          overflow: hidden;  
        }
        
        .CollectionEntitiesSuggestionsBar {
          position: absolute;
          height: fit-content;
          top: calc(100%);

          .Toolbar {
            height: fit-content;
          }
        }
      }

      .CollectionEntityBrowserBarWrapper {
        .CollectionEntityBrowserBar {
          height: ${props => props.theme.layout(72)};
          .Toolbar {
            height: ${props => props.theme.layout(72)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesSuggestionsDialog.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesSuggestionsDialog.defaultProps = {}

export default StyledCollectionEntitiesSuggestionsDialog;
