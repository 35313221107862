import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledKanbanPanel = styled(Card)`
  border-radius: ${props => props.theme.radius(2)};
  
  .KanbanPanel {
    &-header {
      height: ${props => props.theme.layout(48)};
      color: ${props => props.theme.property('palette.white.main')};
      background-color: ${props => props.theme.property(`palette.${props.$color}.main`) ??
        props.theme.property('palette.tagGroupDefault.dark')};
      .MuiCardHeader-title > * {
        display: flex;
        align-items: center;
        white-space: nowrap;
        
        .Icon {
          margin-right: ${props => props.theme.layout('1sp')};
        }
        svg {
          color: ${props => props.theme.property('palette.white.main')};
          text {
            color: ${props => props.theme.property(`palette.${props.$color}.main`)};
          }
        }
      }
    }

    &-content {
      padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
    }
  }
  ${ComponentStyles}
`;

StyledKanbanPanel.propTypes = {
  theme: PropTypes.object
};

StyledKanbanPanel.defaultProps = {}

export default StyledKanbanPanel;
