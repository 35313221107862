import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';

const StyledDealflowBarChart = styled(BarChart)`
  &.BarChart {
    .BarChart-bar {
      &.hover {
        stroke: transparent;
        filter: unset;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowBarChart.propTypes = {
};

StyledDealflowBarChart.defaultProps = {
}

export default StyledDealflowBarChart;
