import React, {useRef, useState} from 'react';
import {useAuthResetPassword} from 'services/auth/auth.hooks';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import StyledForgotForm from 'components/organisms/Forms/ForgotForm/ForgotForm.styles';
import Form from 'components/organisms/Forms/Form/Form';
import {H6} from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';

const pages = {
  0: {
    title: 'Forgot password',
    fields: {
      email: {
        label: 'Email',
        inlineLabel: 'email',
        type: 'text',
        validation: 'email',
        initial: null,
        required: true,
        FormFieldProps: {
          autoFocus: true,
          hiddenLabel: true,
          inputProps: {
            autocomplete: 'username'
          }
        }
      }
    }
  }
};

const ForgotForm = (props) => {
  const innerProps = useComponentProps(props, 'ForgotForm');

  const doPasswordReset = useAuthResetPassword();
  const [success, setSuccess] = useState(false);

  const formRef = useRef(null);

  const handleSubmit = (values, actions) => {
    const formik = formRef.current?.formik;

    doPasswordReset.mutation.mutateAsync(values)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error?.response?.status < constants.http.status.serverError) {
          formik.setErrors({
            email: 'Invalid email',
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleChange = () => {
    doPasswordReset.status.clearAll();
  };

  return <StyledForgotForm {...innerProps}>
    {!success ? <Form ref={formRef}
                      pages={pages}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      SubmitButtonProps={{children: 'Reset password'}}/> :
      <H6>We have sent you an email with a link to reset your password</H6>}
    <Box className='auth-links'>
      <ActionLink action={{
        label: 'Log in instead?',
        navigation: {
          to: `/auth/login`,
          replace: true
        }
      }}/>
    </Box>
  </StyledForgotForm>
};

ForgotForm.propTypes = {};

ForgotForm.defaultProps = {};

export default ForgotForm;
