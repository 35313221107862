import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionEntityBrowserBar
  from 'components/organisms/Bars/CollectionEntityBrowserBar/CollectionEntityBrowserBar.styles';

const CollectionEntityBrowserBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityBrowserBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntityBrowserBar ref={ref} {...innerProps}/>
});

CollectionEntityBrowserBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityBrowserBar.defaultProps = {
};

export default CollectionEntityBrowserBar;
