import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledInputAdornment from 'components/atoms/Inputs/InputAdornment/InputAdornment.styles';
import {withMemo} from 'helpers/wrapper';

const InputAdornment = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'InputAdornment');

  return <StyledInputAdornment ref={ref} {...innerProps} />
}));

InputAdornment.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

InputAdornment.defaultProps = {
};

export default InputAdornment;
