import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCardActions from 'components/atoms/Cards/CardActions/CardActions.styles';

const CardActions = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CardActions');

  return <StyledCardActions ref={ref} {...innerProps} />
});

CardActions.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

CardActions.defaultProps = {};

export default CardActions;
