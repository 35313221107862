import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledScoreTableCell = styled(BaseTableCell)`
  .ScoreLinearProgress {
    width: 100%;
  }
  
  ${ComponentStyles}
`;

StyledScoreTableCell.propTypes = {
  theme: PropTypes.object
};

StyledScoreTableCell.defaultProps = {
}

export default StyledScoreTableCell;
