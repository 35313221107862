import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard';
import utils from 'helpers/utils';

const StyledTasksDashboardCard = styled(DashboardCard)`
  &.TasksDashboardCard {
    padding: ${props => props.theme.spacing(2)};
    background-color: ${props => props.theme.property('palette.primary.states.hover')};

    .DashboardCard-header {
      padding: 0 0 ${props => props.theme.spacing(1)} 0;

      .MuiCardHeader-action {
        margin-right: ${props => props.theme.layout('0.5sp')};
      }
    }
    
    .DashboardCard-content {
      padding: 0;
      .TasksList {
        padding: 0;
        
        .DataList-footer {
          background: linear-gradient(0deg, ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))} 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }
  }

  .DashboardCard-content {
    height: 1px;
    flex-grow: 1;
  }
  
  ${ComponentStyles}
`;

StyledTasksDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledTasksDashboardCard.defaultProps = {}

export default StyledTasksDashboardCard;
