import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledDealflowEntityContextWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityContextWrapper/DealflowEntityContextWrapper.styles';
import DealflowEntitySectionPanel
  from 'components/organisms/SectionPanels/DealflowEntitySectionPanel/DealflowEntitySectionPanel';
import {useSplitPath} from 'helpers/hooks/links';
import useMediaQuery from '@mui/material/useMediaQuery';

const DealflowEntityContextWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityContextWrapper');

  const profileProvider = useProfile();
  const [open, setOpen] = useState(true);
  const [size, setSize] = useState('medium');

  const [, pathPostfix] = useSplitPath('dealflow');
  const pathKey = pathPostfix.split('/')?.[1] ?? 'profile';

  const handleChange = (section) => {
    setSize(section.size ?? 'medium');
  }

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleContextToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!mobile);
  }, [mobile]);

  return <StyledDealflowEntityContextWrapper ref={ref} {...innerProps}
                                             variant={mobile ? 'temporary' : 'standard'}
                                             context={<DealflowEntitySectionPanel onChange={handleChange}/>}
                                             open={open && Boolean(profileProvider.state?.showContext?.[pathKey])}
                                             onToggle={handleContextToggle}
                                             size={size}>
    {innerProps.children}
  </StyledDealflowEntityContextWrapper>
});

DealflowEntityContextWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DealflowEntityContextWrapper.defaultProps = {};

export default DealflowEntityContextWrapper;
