import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TaskSectionPanel from 'components/organisms/SectionPanels/TaskSectionPanel/TaskSectionPanel';

const StyledEntityTaskSectionPanel = styled(TaskSectionPanel)`
  .EntityTaskSectionPanel-profile {
    .ListWrapper-content {
      overflow-y: auto;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityTaskSectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledEntityTaskSectionPanel.defaultProps = {}

export default StyledEntityTaskSectionPanel;
