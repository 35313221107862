import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import {useServiceSetDataEx} from 'stores/hooks/store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useCollectionStatus () {
  return useServiceStatus(path);
}

export function useCollectionSet () {
  return useServiceSetDataEx(path);
}

export function useCollectionList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list',
    {search, filter, sort, page, pageSize, allowPublic: false, ...rest}, queryOptions);
}

export function useCollectionGet ({collectionId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {collectionId}, queryOptions);
}

export function useCollectionAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionUpdateQuery (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'collections/:collectionId/updateQuery',
    ...mutationOptions
  });
}

export function useCollectionClone (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/fork',
    ...mutationOptions
  });
}

export function useCollectionMerge (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    refetchChildren: true,
    overridePath: 'collections/:collectionId/merge/:mergedCollectionId',
    ...mutationOptions
  });
}

export function useCollectionUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useCollectionDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionShare (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/share/:clientId',
    ...mutationOptions
  });
}

export function useCollectionUnShare (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'collections/:collectionId/share/:clientId',
    ...mutationOptions
  });
}

export function useCollectionPublish (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/publish',
    ...mutationOptions
  });
}

export function useCollectionUnPublish (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'collections/:collectionId/publish',
    ...mutationOptions
  });
}

export function useCollectionDeleteBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'collections/bulk/delete',
    ...mutationOptions
  });
}

export function useCollectionFavoriteAddBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/bulk/favorite',
    ...mutationOptions
  });
}

export function useCollectionFavoriteDeleteBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'collections/bulk/favorite',
    ...mutationOptions
  });
}

export function useCollectionUpdateBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'collections/bulk/patch',
    ...mutationOptions
  });
}

// other
export function useCollectionGenerateTagGroups (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'collections/:collectionId/generateTagGroups',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useCollectionGenerateSources ({collectionId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'post', {collectionId}, {
    overridePath: 'collections/:collectionId/generateSources',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
