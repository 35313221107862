import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';

const path = utils.servicePath(import.meta.url);

export class CollectionQuestionnaireService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      invalidateParent: true,
      watch: ['answer'],
      match: (cache, entity, id, meta) => {
        return entity === 'answer' && (
          utils.toArray(meta?.context?.$store?.['entityIds']).find((pId) =>
            utils.toArray(cache?.data?.entityIds).find((cId) => +pId === +cId))
        )
      },
      api: {
        query: {
          stats: (params) => {
            return {
              ...this.api.default.query.get(params),
              action: async (http) => {
                const {
                  path,
                  questionnaireIds,
                  ...rest
                } = params;

                const httpParams = utils.filterObject(rest, [this.key]);

                return Promise.all(questionnaireIds.map((questionnaireId) => {
                  const resolvedPath = utils.resolvePath(path, {questionnaireId, ...rest});

                  return http.get(resolvedPath, httpParams);
                }))
                  .then((res) => {
                    const first = res.length > 0 ? res[0] : null;
                    const meta = utils.responseMeta(first);

                    return {
                      meta,
                      data: res?.map((resp) => resp.data.data)
                    };
                  });
              }
            }
          }
        }
      }
    }, callbacks);
  }
}
