import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {DragHandleContext, useDragHandle} from 'components/organisms/Utils/DragDrop/DndContext';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {DragIndicator} from '@mui/icons-material';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import StyledTagForm from 'components/organisms/Forms/TagForm/TagForm.styles';

const TagForm = React.forwardRef((props, ref) => {
  const {
    tag,
    first,
    readOnly,
    disabled,
    active,
    error,
    hasPoints,
    autoTagType,
    onChange,
    onErrors,
    onDelete,
    ...innerProps
  } = useComponentProps(props, 'TagForm');

  const formRef = useRef(null);

  const [internalValue] = useState({...tag, ...tag?.autoTagParams});
  
  const dragHandleContext = useDragHandle(DragHandleContext);
  
  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'value',
      label: 'Name',
      type: constants.formFieldTypes.text,
      format: constants.formFieldFormatTypes.lower,
      validation: constants.formFieldValidationTypes.tag,
      entity: 'tagGroup',
      formGroup: 'header',
      initial: internalValue.value,
      readOnly: readOnly,
      required: true,
      disabled: disabled,
      FormFieldProps: {
        error: error ? 'Duplicate name' : null,
        variant: 'inlineLabel',
        size: 'smaller',
        hiddenLabel: true
      }
    });

    if (hasPoints) {
      fields.push({
        name: 'points',
        label: 'Points',
        type: constants.formFieldTypes.text,
        validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative,
          `${constants.formFieldValidationTypes.min}(-1000)`, `${constants.formFieldValidationTypes.max}(1000)`],
        format: constants.formFieldFormatTypes.int,
        postfix: 'points',
        entity: 'tagGroup',
        formGroup: 'header',
        initial: internalValue.points ?? 0,
        readOnly: readOnly,
        required: true,
        disabled: disabled,
        FormFieldProps: {
          variant: 'inlineLabel',
          size: 'smaller',
          fullWidth: false,
          hiddenLabel: true,
          hiddenPlaceholder: true
        }
      });
    }

    if (autoTagType === constants.collection.autoTagTypes.byKeywords) {
      fields.push({
        name: 'keywords',
        label: 'Keywords',
        description: 'Describe the tag in keywords',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.list,
        conversion: constants.formFieldConversionTypes.label,
        initial: internalValue.keywords ?? [],
        formGroup: 'body',
        entity: 'tagGroup',
        FormFieldProps: {
          multiple: true,
          createOption: true,
          openDirect: false,
          openOnFocus: false,
          hiddenLabel: false,
          TagProps: {
            variant: 'filled'
          },
          ChipProps: {
            variant: 'filled'
          },
          ChipListProps: {
            variant: 'standard'
          }
        }
      })
    }

    return fields;
  }, [internalValue, error, hasPoints, autoTagType, readOnly, disabled]);

  const handleSubmit = (values, actions) => {
    if (autoTagType === constants.collection.autoTagTypes.byKeywords) {
      values.autoTagParams = { keywords: values.keywords ?? [] };
      delete values.keywords;
    } else {
      values.autoTagParams = null;
    }

    utils.asPromise(onChange)(values)
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  const handleChange = () => {
    formRef.current?.submit();
  }

  const handleValidating = (validating, dirty, errors, formik) => {
    onErrors?.(Object.keys(formik.errors).length > 0);
  }

  const dragAction = useMemo(() => ({
    icon: <Icon icon={DragIndicator} size="smaller" />,
    tooltip: 'Move tag',
    TooltipProps: dragHandleContext?.isDragging ? {
      open: false
    } : null,
    IconButtonProps: {
      ...utils.filterObject(dragHandleContext, 'isDragging'),
      disabled: (disabled || readOnly || !active),
      onPointerDown: (e) => {
        if (disabled || readOnly) {
          e.preventDefault();
        } else {
          dragHandleContext?.onPointerDown?.(e);
        }
      }
    },
    onClick: () => null
  }), [disabled, readOnly, active, dragHandleContext]);

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    label: 'Remove tag',
    tooltip: 'Remove tag',
    icon: Delete,
    ActionIconButtonProps: {
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: (disabled || readOnly || !active)
    },
    onClick: (e) => {
      onDeleteEvent?.(e)
    }
  }), [disabled, readOnly, active, onDeleteEvent]);

  const renderActions = () => {
    return <Box className="TagForm-actions">
      {dragHandleContext ? <ActionIconButton action={dragAction}
                                             disableRipple={true}
                                             size="smaller"
                                             density="sparse"
                                             variant="outlined"/> : null}
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="contained"
                        color="error"
                        action={deleteAction}/>
    </Box>
  }

  const renderFormContent = (groups) => {
    const header = groups.find((g) => g.name === 'header');
    const body = groups.find((g) => g.name === 'body');

    return <React.Fragment>
      <Box className="TagForm-header">
        <Box className="Form-fields">
          {header.rendered}
        </Box>
        {renderActions()}
      </Box>
      {body ? <Box className="TagForm-body">
        <Box className="Form-fields">
          {body.rendered}
        </Box>
      </Box> : null}
    </React.Fragment>
  }

  return <StyledTagForm ref={ref} {...innerProps}>
    <InlineForm ref={formRef}
                className="TagForm-form"
                autoFocus={internalValue.tagId < 0 && !first}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onValidating={handleValidating}
                renderContent={renderFormContent}
                fields={fields}/>
  </StyledTagForm>
});

TagForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tag: PropTypes.object,
  first: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  error: PropTypes.bool,
  hasPoints: PropTypes.bool,
  autoTagType: PropTypes.string,
  onChange: PropTypes.func,
  onErrors: PropTypes.func,
  onDelete: PropTypes.func
};

TagForm.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default TagForm;
