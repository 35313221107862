import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCheckboxTableCell from 'components/molecules/TableCells/CheckboxTableCell/CheckboxTableCell.styles';
import ActionCheckbox from 'components/molecules/Inputs/ActionCheckbox/ActionCheckbox';
import {withMemo} from 'helpers/wrapper';

const CheckboxTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    ActionCheckboxProps,
    ...innerProps
  } = useComponentProps(props, 'CheckboxTableCell');

  return <StyledCheckboxTableCell ref={ref} {...innerProps}>
    <ActionCheckbox {...ActionCheckboxProps} />
  </StyledCheckboxTableCell>
}));

CheckboxTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionCheckboxProps: PropTypes.object
};

CheckboxTableCell.defaultProps = {
  value: 'CheckboxTableCell text'
};

export default CheckboxTableCell;


