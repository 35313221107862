import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionBasicsSectionPanelContent = styled(Box)`
  &.CollectionBasicsSectionPanelContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    .Profile {
      flex-grow: 1;
      min-height: unset;
      padding-top: ${props => props.theme.spacing(1)};
      padding-bottom: ${props => props.theme.spacing(2)};
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionBasicsSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionBasicsSectionPanelContent.defaultProps = {}

export default StyledCollectionBasicsSectionPanelContent;
