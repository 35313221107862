import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useClientStatus () {
  return useServiceStatus(path);
}

export function useClientList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useClientGet ({clientId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId}, queryOptions);
}

export function useClientAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useClientUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useClientDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

// other
export function useClientLimits ({clientId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId}, {
    overridePath: 'clients/:clientId/limits',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useClientUsage ({clientId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId}, {
    overridePath: 'clients/:clientId/usage',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
