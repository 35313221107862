import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledTextField = styled(FormControl)`
  &.TextField-multiline {
    &:not(.TextField-markdown) {
      .TextField {
        &-readOnly {
          white-space: pre-line;
          word-wrap: break-word;
        }
      }
    }
  }
  
  .TextField-readOnly {
    display: flex;
    
    .placeholder {
      color: ${props => props.theme.property('palette.text.disabled')};
    }
    
    .MuiInputAdornment-root {
      margin: 0;
      height: unset;
      min-height: unset;
      max-height: unset;
      color: unset;
      font: inherit;
      > * {
        font: inherit;
        color: unset;
      }
    }
    
    .MuiInputAdornment-positionStart {
      margin-right: ${props => props.theme.spacing(0.5)};
    }
    .MuiInputAdornment-positionEnd {
      margin-left: ${props => props.theme.spacing(0.5)};
    }
  }

  .Icon {
    font-size: ${props => props.theme.fontSize(20)};
  }
  
  .MuiInputAdornment-root {
    .Icon {
      color: ${props => props.theme.property('palette.action.active')};
    }
    
    .MuiButtonBase-root {
      font-size: ${props => props.theme.fontSize(16)};
      padding: ${props => props.theme.layout(2)};
    }
  }
  .MuiInputAdornment-positionStart {
    margin-left: ${props => props.theme.layout(-3)};
    .IconButton {
      margin-left: ${props => props.theme.layout(-2)};
    }
  }
  .MuiInputAdornment-positionEnd {
    margin-right: ${props => props.theme.layout(-3)};
    .IconButton {
      margin-right: ${props => props.theme.layout(-2)};
    }
  }

  ${props => props.theme.state2Selector('TextField', null, 'disabled')} {
    .MuiInputAdornment-root {
      color: ${props => props.theme.property('palette.action.disabled')};
      * {
        color: ${props => props.theme.property('palette.action.disabled')};
      }
    }
  }
  
  //sizes
  &.TextField-size-smaller {
    .Icon {
      font-size: ${props => props.theme.fontSize(20)};
    }

    .MuiInputBase-root {
      font-size: ${props => props.theme.fontSize(15)};
      input::placeholder, textarea::placeholder {
        font-size: ${props => props.theme.fontSize(15)};
      }
    }
    
    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        padding: ${props => props.theme.layout(2)};
      }
    }

    &.TextField-multiline {
      .MuiInputBase-root {
        padding: ${props => props.theme.layout(8)} ${props => props.theme.layout(12)};
      }
    }
    
    &:not(.TextField-multiline) {
      .MuiInputBase-root {
        height: ${props => props.theme.layout(36)};
        min-height: ${props => props.theme.layout(36)};
        padding-top: ${props => props.theme.layout(4)};
        padding-bottom: ${props => props.theme.layout(4)};

        .MuiInputBase-input {
          padding-top: ${props => props.theme.layout(8)};
          padding-bottom: ${props => props.theme.layout(8)};
        }

        &:has(.MuiInputAdornment-positionStart) {
          padding-left: ${props => props.theme.layout(12)};

          .MuiInputAdornment-positionStart {
            margin-right: ${props => props.theme.layout(6)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(0)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionStart)) {
          .MuiInputBase-input {
            padding-left: ${props => props.theme.layout(12)};
          }
        }

        &:has(.MuiInputAdornment-positionEnd) {
          padding-right: ${props => props.theme.layout(12)};

          .MuiInputAdornment-positionEnd {
            margin-left: ${props => props.theme.layout(4)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(0)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionEnd)) {
          .MuiInputBase-input {
            padding-right: ${props => props.theme.layout(12)};
          }
        }
      }
    }
  }
  
  &.TextField-size-smallest {
    .Icon {
      font-size: ${props => props.theme.fontSize(18)};
    }

    .MuiInputBase-root {
      font-size: ${props => props.theme.fontSize(14)};
      input::placeholder, textarea::placeholder {
        font-size: ${props => props.theme.fontSize(14)};
      }
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        padding: ${props => props.theme.layout(2)};
      }
    }

    &.TextField-multiline {
      .MuiInputBase-root {
        padding: ${props => props.theme.layout(6)} ${props => props.theme.layout(10)};
      }
    }
    
    &:not(.TextField-multiline) {
      .MuiInputBase-root {
        height: ${props => props.theme.layout(32)};
        min-height: ${props => props.theme.layout(32)};
        padding: ${props => props.theme.layout(2)} 0;

        .MuiInputBase-input {
          padding-top: ${props => props.theme.layout(4)};
          padding-bottom: ${props => props.theme.layout(4)};
        }

        &:has(.MuiInputAdornment-positionStart) {
          padding-left: ${props => props.theme.layout(12)};

          .MuiInputAdornment-positionStart {
            margin-right: ${props => props.theme.layout(4)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(-1)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionStart)) {
          .MuiInputBase-input {
            padding-left: ${props => props.theme.layout(10)};
          }
        }

        &:has(.MuiInputAdornment-positionEnd) {
          padding-right: ${props => props.theme.layout(12)};

          .MuiInputAdornment-positionEnd {
            margin-left: ${props => props.theme.layout(4)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(-1)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionEnd)) {
          .MuiInputBase-input {
            padding-right: ${props => props.theme.layout(10)};
          }
        }
      }
    }
  }
  
  &.TextField-size-tiny {
    .Icon {
      font-size: ${props => props.theme.fontSize(8)};
    }

    .MuiInputBase-root {
      font-size: ${props => props.theme.fontSize(13)};
      input::placeholder, textarea::placeholder {
        font-size: ${props => props.theme.fontSize(13)};
      }
    }
    
    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        padding: ${props => props.theme.layout(2)};
      }
    }

    &.TextField-multiline {
      .MuiInputBase-root {
        padding: ${props => props.theme.layout(4)} ${props => props.theme.layout(8)};
      }
    }
    
    &:not(.TextField-multiline) {
      .MuiInputBase-root {
        height: ${props => props.theme.layout(20)};
        min-height: ${props => props.theme.layout(20)};
        padding: ${props => props.theme.layout(2)} 0;

        .MuiInputBase-input {
          padding-top: ${props => props.theme.layout(4)};
          padding-bottom: ${props => props.theme.layout(4)};
        }

        &:has(.MuiInputAdornment-positionStart) {
          padding-left: ${props => props.theme.layout(6)};

          .MuiInputAdornment-positionStart {
            margin-right: ${props => props.theme.layout(4)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(0)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionStart)) {
          .MuiInputBase-input {
            padding-left: ${props => props.theme.layout(8)};
          }
        }

        &:has(.MuiInputAdornment-positionEnd) {
          padding-right: ${props => props.theme.layout(6)};

          .MuiInputAdornment-positionEnd {
            margin-left: ${props => props.theme.layout(4)};

            &:has(.MuiButtonBase-root) {
              margin-top: ${props => props.theme.layout(0)};
            }
          }
        }

        &:not(:has(.MuiInputAdornment-positionEnd)) {
          .MuiInputBase-input {
            padding-right: ${props => props.theme.layout(8)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTextField.propTypes = {
  theme: PropTypes.object
};

StyledTextField.defaultProps = {
}

export default StyledTextField;
