import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextCard from 'components/molecules/Cards/ContextCard/ContextCard';

const StyledRelevancyContextCard = styled(ContextCard)`
  &.RelevancyContextCard-inline {
    .Button {
      min-width: ${props => props.theme.layout(112)};
    }
  }
  ${ComponentStyles}
`;

StyledRelevancyContextCard.propTypes = {
  theme: PropTypes.object
};

StyledRelevancyContextCard.defaultProps = {}

export default StyledRelevancyContextCard;
