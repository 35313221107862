import React, {useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledClientCollaboratorsProfileCardContent
  from 'components/organisms/Cards/ClientCollaboratorsProfileCardContent/ClientCollaboratorsProfileCardContent.styles';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import Table from 'components/organisms/Tables/Table/Table';
import constants from 'helpers/constants';
import {useClientUserList} from 'services/client/user/user.hooks';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import Edit from '@mui/icons-material/Edit';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const ClientCollaboratorsProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    client,
    isDialog,
    isLoading,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'ClientCollaboratorsProfileCardContent', {
    static: ['isEditing']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const isEditing = content.state.isEditing;

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 15,
        filter: [
          {id: 'userType', value: constants.user.types.proxy},
          {id: 'activePasses', value: true}
        ]
      },
      options: {
        name: 'collaborators',
      }
    }
  }), []);

  const listState = useListState(options.listState?.initial, options.listState?.options);

  const userList = useClientUserList({
    clientId: client.clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    enabled: !isLoading && utils.isDefined(client?.clientId),
    ...constants.queryOptions.infinite
  });

  const onPatchEvent = useEffectEvent(onPatch);
  const handleChange = useCallback((row, cell) => (field, value, onSuccess, onError) => {
    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);

    return utils.asPromiseCallback(onPatchEvent)({
      name: `collaborator-${cell.row.original.userId}`
    }, {
      userId: cell.row.original.userId,
      [field.name]: value
    })
      .catch(() => {
        onError?.();
        cell.column.columnDef.optimistic.reset(cell);
      });
  }, [onPatchEvent]);

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      id: 'name',
      header: 'name',
      size: 200,
      Cell: ({cell}) => {
        const user = cell.row.original;
        return <UserTableCell user={user}
                              short={true}
                              ActionAvatarProps={{
                                size: 'medium'
                              }} />
      }
    });

    columns.push({
      accessorKey: 'authGroup',
      id: 'authGroup',
      header: 'Role',
      size: 120,
      maxSize: 120,
      enableEditing: (row) => {
        return authorize({attribute: 'collaborator.delete', meta: {collaborator: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const authGroup = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const role = constants.data.lookup('roles', authGroup);

        const options = constants.data.roles
          .filter((v) => {
            return authorize({
              attribute: `collaborator.column.authGroup.update.${v.value}`,
              meta: {collaborator: row.original}
            });
          });

        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          required: true,
          initial: role.value,
          options: options
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={role?.label} />}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const authGroup = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const role = constants.data.lookup('roles', authGroup);
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={role?.label} action={action} />
      }
    });

    columns.push({
      id: 'delete',
      size: 100,
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      Cell: ({cell}) => {
        const user = cell.row.original;

        const action = {
          label: 'Remove collaborator',
          tooltip: user.isDealLeader ? 'User is a deal leader' : 'Remove collaborator',
          icon: <Icon icon={Delete} size="smaller" />,
          auth: utils.createAuth({attribute: 'collaborator.delete', meta: {collaborator: user}}),
          onClick: (e) => {
            const handleConfirm = () => {
              const field = {
                name: `collaborator-${cell.row.original.userId}`
              };

              return utils.asPromiseCallback(onPatchEvent)(field, null)
                .catch(() => {
                  snackbar.show('Removing collaborator failed', null,
                    {color: 'error', autoHideDuration: constants.delay.error});
                });
            }

            dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this collaborator?"
                                              explanation="The collaborator will only be removed from this client"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: 'Remove collaborator',
                                                color: 'error'
                                              }} />, true);
            e.preventDefault();
          },
        };

        return <IconButtonTableCell ActionIconButtonProps={{
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          showInactive: true,
          action: action }} />
      }
    });

    return columns;
  }, [dialogControl, snackbar, authorize, onPatchEvent, handleChange]);

  const renderReadOnly = () => {
    return <Box className="ClientCollaboratorsProfileCardContent-readOnly">
      <Table ref={ref} {...innerProps}
             dataKey="userId"
             columns={columnsMemo}
             data={userList.data}
             listState={listState}
             rowCount={userList.meta?.resultsCount}
             enableSorting={false}
             enableEditing={true}
             enableTableHead={false}
             enableParentScroll={false}
             enableVirtualization={false}
             enableColumnVirtualization={false}
             enableBottomToolbar={false}
             enableStickyHeader={false}
             enableColumnDragging={false}
             enableColumnOrdering={false}
             enableColumnResizing={false}
             enablePinning={false}
             debounce={false}
             state={{
               isLoading: userList.status.isLoading,
               showProgressBars: userList.status.isLoadingNext
             }} />
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledClientCollaboratorsProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="ClientCollaboratorsProfileCardContent-content">
      {renderReadOnly()}
    </Box>
  </StyledClientCollaboratorsProfileCardContent>
});

ClientCollaboratorsProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  client: PropTypes.object,
  isDialog: PropTypes.bool,
  isLoading: PropTypes.bool,
  fieldData: PropTypes.object,
  onPatch: PropTypes.func
};

ClientCollaboratorsProfileCardContent.defaultProps = {
};

export default ClientCollaboratorsProfileCardContent;


