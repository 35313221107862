import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFormLabel from 'components/atoms/Labels/FormLabel/FormLabel.styles';
import {withMemo} from 'helpers/wrapper';

const FormLabel = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'FormLabel');

  return <StyledFormLabel ref={ref} {...innerProps}>{innerProps.children}</StyledFormLabel>
}));

FormLabel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FormLabel.defaultProps = {
  children: 'FormLabel text'
};

export default FormLabel;
