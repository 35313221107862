import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityBrowserBar from 'components/organisms/Bars/EntityBrowserBar/EntityBrowserBar';

const StyledDealflowEntityBrowserBar = styled(EntityBrowserBar)`
  ${ComponentStyles}
`;

StyledDealflowEntityBrowserBar.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntityBrowserBar.defaultProps = {
};

export default StyledDealflowEntityBrowserBar;
