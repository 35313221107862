import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledTaskAddWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0;
    }
    &-step {
      &:has(.ResponsibleWizardContent) {
        height: 100% !important;
        overflow: hidden;
      }
      
      .WizardContent {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};

        .FormField {
          row-gap: ${props => props.theme.layout('0.5sp')};

          .FormLabel, .InputLabel {
            ${props => props.theme.font2Css('body2')};
            transform: scale(1);
          }
        }
      }

      .FormField-name-collaborationType {
        &.CategoryField {
          .ListField-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: ${props => props.theme.layout('1sp')};

            .ListItem {
              aspect-ratio: 192/182;
              
              .CategoryCard-icon {
                .Icon {
                  font-size: ${props => props.theme.fontSize(32)};
                  &[data-testId="ManIcon"] {
                    fill-opacity: ${props => props.theme.property('palette.action.disabledOpacity')};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTaskAddWizard.propTypes = {
  theme: PropTypes.object
};

StyledTaskAddWizard.defaultProps = {}

export default StyledTaskAddWizard;
