import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCategoriesSettingPage = styled(Page)`
  background-color: unset;
  
  .CategoriesSettingPage {
    &-content {
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};

      .CollectionProfile {
        background-color: unset;
        padding: 0;
        max-width: 100%;

        .ProfileCard {
          &-title {
            ${props => props.theme.font2Css('h6')};
          }

          &-content {
            padding: 0;
          }
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledCategoriesSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledCategoriesSettingPage.defaultProps = {}

export default StyledCategoriesSettingPage;
