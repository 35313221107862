import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledTractionAreaChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('3sp')};
  height: 100%;
  overflow: hidden;
  
  // see graph margins
  margin-left: ${props => props.theme.layout(-32)};
  width: calc(100% + ${props => props.theme.layout(32)});

  .TractionAreaChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;

      .recharts-cartesian {
        &-axis {
          &-tick-value {
            fill: ${props => utils.rgba2Rgb(props.theme.property('palette.text.disabled'))};
          }

          &-line {
            stroke: transparent;
          }
        }
        
        &-grid {
          .recharts-cartesian-grid-horizontal {
            line:first-child {
              stroke: transparent;
            }
          }
        }
      }
    }
    
    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledTractionAreaChart.propTypes = {
};

StyledTractionAreaChart.defaultProps = {
}

export default StyledTractionAreaChart;
