import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledEntitiesUploadDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: unset;
    max-height: unset;
    width: 80vw;
    height: 90vh;
  }
  
  .EntitiesUploadDialog {
    &-content {
      height: 1px;
      padding: 0;
      position: relative;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesUploadDialog.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesUploadDialog.defaultProps = {}

export default StyledEntitiesUploadDialog;
