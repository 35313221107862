import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard';

const StyledThemeBreakdownDashboardCard = styled(DashboardCard)` 
  &.ThemeBreakdownDashboardCard {
    .DashboardCard {
      &-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ${props => props.theme.spacing(2)};

        &-title {
          ${props => props.theme.font2Css('h6')};
        }
        
        .MuiCardHeader-content {
          white-space: nowrap;
          flex: 1;
          font-weight: 400;
        }

        .MuiCardHeader-action {
          display: flex;
          justify-content: end;
          align-items: center;
          flex: 1;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        padding-bottom: ${props => props.theme.spacing(2)};
        row-gap: ${props => props.theme.layout('3sp')};

        .barInfo {
          display: flex;
          flex-direction: column;
          row-gap: ${props => props.theme.layout('0.5sp')};

          .Span {
            font-weight: 700;
          }

          .LinearProgress {
            height: ${props => props.theme.layout(8)};
            background-color: ${props => props.theme.property('palette.primary.states.selected')};
          }
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledThemeBreakdownDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledThemeBreakdownDashboardCard.defaultProps = {}

export default StyledThemeBreakdownDashboardCard;
