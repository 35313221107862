import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import StyledKanbanPanel from 'components/organisms/Kanbans/KanbanPanel/KanbanPanel.styles';
import KanbanPanelContent from 'components/organisms/Kanbans/KanbanPanelContent/KanbanPanelContent';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Badge from 'components/atoms/Badges/Badge/Badge';

const KanbanPanel = React.forwardRef((props, ref) => {
  const {
    kanban,
    panel,
    lists,
    dataKey,
    title,
    icon,
    contents,
    onClick,
    onChange,
    onCanUpdate,
    disableReorder,
    isLoading,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.panel, [
      'title', 'icon', 'color', 'contents'
    ], false), ...props.panel?.KanbanPanelProps}, 'KanbanPanel', {
    styled: ['color'],
    children: ['header', 'content']
  });

  const innerRef = useRef(null);
  const contentRefs = useRef({});
  const [resultsCount, setResultsCount] = useState(0);

  const kanbanPanel = useMemo(() => ({
    refs: {
      ref: innerRef,
      contentRefs
    },
    reset: () => {
      Object.keys(contentRefs.current).forEach((kp) => {
        contentRefs.current[kp].reset();
      });
    }
  }), []);

  useImperativeHandle(ref, () => kanbanPanel);

  const contentsMemo = useMemo(() => {
    if (contents) {
      return contents
        .sort((a, b) => a.position - b.position);
    } else {
      return null;
    }
  }, [contents]);

  const isPanelLoading = contentsMemo?.reduce?.((t, content) => t ||
    (lists?.[content.id]?.status?.isLoading && !lists?.[content.id]?.status?.isSettled), false);

  const renderBadge = () => {
    return <Badge color="white" isLoading={isPanelLoading} badgeContent={<Span>{resultsCount}</Span>} />;
  }

  const renderTitle = () => {
    return <React.Fragment>
      {icon ? (utils.isReactElement(icon) ? icon : <Icon icon={icon}/>) : null}
      {title ? (utils.isReactElement(title) ? title :
        <Typography variant="subtitle1" isLoading={isPanelLoading} min={8} max={20}>{title}</Typography>) : null}
    </React.Fragment>
  }

  const handleContentRef = (content) => (el) => {
    contentRefs.current[content.id] = el;
    const count = Object.keys(contentRefs.current).reduce((t, k) => t + (contentRefs.current[k]?.state.count ?? 0), 0);
    setResultsCount(utils.updater(count ?? 0));
  }

  return <StyledKanbanPanel ref={innerRef} {...innerProps}>
    <CardHeader className="KanbanPanel-header"
                badge={renderBadge()}
                title={renderTitle()} />
    <CardContent className="KanbanPanel-content">
      {contentsMemo?.map((content) => {
        return <KanbanPanelContent key={content.id}
                                   ref={handleContentRef(content)}
                                   content={content}
                                   panel={panel}
                                   kanban={kanban}
                                   list={lists?.[content.id]}
                                   dataKey={dataKey}
                                   onClick={onClick}
                                   onChange={onChange}
                                   onCanUpdate={onCanUpdate}
                                   disableReorder={disableReorder}
                                   isLoading={isPanelLoading}/>
      })}
    </CardContent>
  </StyledKanbanPanel>
});

KanbanPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  kanban: PropTypes.object,
  panel: PropTypes.object,
  lists: PropTypes.object,
  dataKey: PropTypes.string,
  title: PropTypes.any,
  icon: PropTypes.any,
  contents: PropTypes.array,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onCanUpdate: PropTypes.func,
  disableReorder: PropTypes.bool,
  isLoading: PropTypes.bool
};

KanbanPanel.defaultProps = {
  elevation: 1
};

export default KanbanPanel;
