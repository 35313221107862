import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledInputOutline from 'components/atoms/Fieldsets/InputOutline/InputOutline.styles';
import {withMemo} from 'helpers/wrapper';

const InputOutline = withMemo(React.forwardRef((props, ref) => {
  const {
    label,
    ...innerProps
  }= useComponentProps(props, 'InputOutline');

  return <StyledInputOutline ref={ref} {...innerProps} aria-hidden="true">
      <legend><span>{label}</span></legend>
  </StyledInputOutline>
}));

InputOutline.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

InputOutline.defaultProps = {
  label: 'InputOutline text'
};

export default InputOutline;
