import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './Button.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Component from 'components/organisms/Utils/Component/Component';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const Button = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    grouped,
    badge,
    flatBackground,
    plainBorder,
    isLoading,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'Button', {
    static: ['clickable', 'disabled', 'active', 'selected', 'grouped', 'flatBackground', 'plainBorder']
  });

  const clickable = Boolean(innerProps.onClick || innerProps.href || innerProps.type);
  const variant = innerProps.variant === 'flat' ? 'contained' : innerProps.variant;

  const renderLabel = () => {
    if (badge) {
      return <Component Original={badge}
                        className="Button-badge"
                        variant="inline"
                        size="small"
                        disabled={innerProps.disabled}
                        isLoading={isLoading}
                        {...BadgeProps}>
        {innerProps.children ?? innerProps.label}
      </Component>;
    } else {
      return innerProps.children ?? innerProps.label;
    }
  }

  const renderButton = () => {
    return <StyledButton ref={ref} {...innerProps} variant={variant} $clickable={clickable}>
      {renderLabel()}
    </StyledButton>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable
  });

  if (isLoading) {
    return <Skeleton>{renderButton()}</Skeleton>
  } else {
    return renderButton();
  }
}));

Button.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  label: PropTypes.string,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  isLoading: PropTypes.bool,
  BadgeProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text', 'flat']), PropTypes.string]),
};

Button.defaultProps = {
  label: 'Button text',
  size: 'medium',
  variant: 'contained',
  color: 'primary',
  selected: false,
  flatBackground: false,
  plainBorder: false
};

export default Button;
