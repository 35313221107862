import React from 'react'
import RouteProvider from 'components/organisms/Providers/RouteProvider/RouteProvider';
import StoreProvider from 'components/organisms/Providers/StoreProvider/StoreProvider';

const AppRoutes = () => {
  return <StoreProvider>
    <RouteProvider />
  </StoreProvider>;
};

AppRoutes.propTypes = {
}

export default AppRoutes;
