import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {Span} from 'components/atoms/Text/Typography/Typography';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import utils from 'helpers/utils';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import StyledEntityMergeDialog from 'components/organisms/Dialogs/EntityMergeDialog/EntityMergeDialog.styles';
import EntityMergeWizard from 'components/organisms/Wizards/EntityMergeWizard/EntityMergeWizard';
import CallMerge from '@mui/icons-material/CallMerge';

const EntityMergeDialog = React.forwardRef((props, ref) => {
  const {
    entity,
    title,
    subtitle,
    onClose,
    onSubmit,
    EntityMergeWizardProps,
    ...innerProps
  } = useComponentProps(props, 'EntityMergeDialog');

  const innerRef = useRef(null);
  const wizardRef = useRef(null);
  const [internalState, setInternalState] = useState({
    error: null,
    isPrevEnabled: false,
    isNextEnabled: false
  });

  useImperativeHandle(ref, () => innerRef.current);

  const backButtonAction = useMemo(() => ({
    label: 'Back',
    icon: ChevronLeft,
    ButtonProps: {
      color: 'primary',
      variant: 'text'
    },
    auth: internalState.isSubmitting ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      wizardRef.current?.goToPrevStep?.();
      e.preventDefault();
    }
  }), [internalState.isSubmitting]);

  const nextButtonAction = useMemo(() => ({
    label: internalState.isNextEnabled ? 'Next' : 'Merge company',
    icon: internalState.isNextEnabled ? ChevronRight : CallMerge,
    iconPosition: internalState.isNextEnabled ? 'end' : 'start',
    ButtonProps: {
      color: internalState.isNextEnabled ? 'primary' : 'success',
    },
    auth: (internalState.isSubmitting || (!internalState.isNextEnabled && !internalState.isSubmitEnabled)) ?
      utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      wizardRef.current?.goToNextStep?.();
      e.preventDefault();
    }
  }), [internalState.isNextEnabled, internalState.isSubmitEnabled, internalState.isSubmitting]);

  const handleChange = (isPrevEnabled, isNextEnabled, isSubmitEnabled, isSubmitting) => {
    setInternalState(utils.updater({
      isPrevEnabled,
      isNextEnabled,
      isSubmitEnabled,
      isSubmitting
    }, true));
  }

  const handleSubmit = (merge, actions, onSuccess, onError) => {
    setInternalState(utils.updater({isSubmitting: false}, true));

    utils.asPromise(onSubmit)(merge)
      .then(() => {
        onSuccess?.();
        onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        onError?.();
        setInternalState(utils.updater({
          error: 'Merging company failed'
        }, true));
      })
      .finally(() => {
        setInternalState(utils.updater({isSubmitting: false}, true));
      });
  };

  const handleClose = (e, reason) => {
    if (!wizardRef.current?.isDirty() || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  };

  const handleError = (error, validation) => {
    setInternalState(utils.updater({
      error: (error && !validation) ? error : null
    }, true));
  }

  const renderButtons = () => {
    return <React.Fragment>
      {internalState.isPrevEnabled ? <ActionButton key="back"
                                                   showInactive={true}
                                                   action={backButtonAction}/> : null}
      <ActionButton key="next"
                    showInactive={true}
                    action={nextButtonAction}/>
    </React.Fragment>
  }

  const renderInfo = () => {
    if (internalState.error) {
      return <Span color="error">{internalState.error}</Span>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityMergeDialog ref={innerRef} {...innerProps}
                                  width={544}
                                  onClose={handleClose}>
    <DialogHeader className="EntityMergeDialog-header"
                  title={title ?? 'Merge company'}
                  subtitle={subtitle}/>
    <DialogContent className="EntityMergeDialog-content">
      <EntityMergeWizard ref={wizardRef}
                         variant="standard"
                         className="EntityMergeDialog-wizard"
                         showProgressBar={true}
                         entity={entity}
                         onError={handleError}
                         onSubmit={handleSubmit}
                         onChange={handleChange}
                         {...EntityMergeWizardProps} />
    </DialogContent>
    <DialogFooter className="EntityMergeDialog-footer"
                  variant="split"
                  info={renderInfo()}
                  buttons={renderButtons()} />
  </StyledEntityMergeDialog>
});

EntityMergeDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  EntityMergeWizardProps: PropTypes.object
};

EntityMergeDialog.defaultProps = {
};

export default EntityMergeDialog;
