import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledComponentField = styled(FormControl)`
  ${ComponentStyles}
`;

StyledComponentField.propTypes = {
  theme: PropTypes.object
};

StyledComponentField.defaultProps = {
}

export default StyledComponentField;
