import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {
  createBrowserRouter,
  isRouteErrorResponse,
  RouterProvider,
  ScrollRestoration,
  useLocation,
  useMatches,
  useRouteError
} from 'react-router-dom';
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import system from 'helpers/system';
import App from 'app/App';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import useRootRoutes from 'routes/root.routes';
import constants from 'helpers/constants';
import Navigate from 'components/organisms/Utils/Navigate/Navigate';

export const RouteContext = React.createContext(null)
export const RouterControlContext = React.createContext(null)

export function useRouter () {
  return React.useContext(RouteContext);
}

export function useRouterControl () {
  return React.useContext(RouterControlContext);
}

const RouterControl = (props) => {
  const location = useLocation();
  const matches = useMatches();

  const [pageState, setPageState] = useState({});

  const appName = system.appShortName();
  const match = matches?.find((m) => {
    return utils.comparePath(m.pathname, location.pathname) &&
      utils.isDefined(m.handle?.title);
  });

  const getKey = useCallback((location, matches) => {
    const match = matches?.find((m) => m.handle?.scrollKey &&
      utils.comparePath(m.pathname, location.pathname));

    if (match?.handle?.scrollKey === 'search') {
      return utils.cleanPath(location.pathname) + location.search;
    } else if (match?.handle?.scrollKey === 'pathname') {
      return location.pathname;
    }

    return location.key;
  }, []);

  const context = useMemo(() => ({
    pageState,
    setPageTitle: (title) => {
      setPageState((current) => {
        return current.title !== title ? {...current, title} : current;
      });
    },
    setPageState: (state) => {
      setPageState(utils.updater(state))
    }
  }), [pageState]);

  useLayoutEffect(() => {
    setPageState(utils.updater({title: match?.handle?.title}, true));
  }, [match?.handle?.title]);

  useEffect(() => {
    document.title = pageState.title ? `${pageState.title} | ${appName}` : appName;
  }, [pageState.title, appName]);

  return <RouterControlContext.Provider value={context}>
    {props.children}
    <ScrollRestoration getKey={getKey} />
  </RouterControlContext.Provider>
}

const RouteError = () => {
  const location = useLocation();
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === constants.http.status.notFound) {
    // the response json is automatically parsed to
    // `error.data`, you also have access to the status
    return <Navigate to={'/error/notFound'} replace state={{
      from: location,
      error
    }} />
  } else {
    // rethrow to let the parent error boundary handle it
    // when it's not a special case for this route
    throw error;
  }
}

const RouteProvider = (props) => {
  const rootRoutes = useRootRoutes();

  const routes = useMemo(() => {
    return [
      {
        path: '',
        element: <RouterControl>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </RouterControl>,

        children: rootRoutes,

        errorElement: <RouteError />
      }
    ]
  }, [rootRoutes]);

  const router = useMemo(() => {
    return createBrowserRouter(routes);
  }, [routes]);

  return <RouteContext.Provider value={router}>
    <RouterProvider router={router}>
      {props.children}
    </RouterProvider>
  </RouteContext.Provider>
}

RouteProvider.propTypes = {
  root: PropTypes.array
}

export default RouteProvider;
