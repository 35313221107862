import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledDealflowEntitiesColumnsWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesColumnsWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesColumnsWrapper.defaultProps = {
};

export default StyledDealflowEntitiesColumnsWrapper;
