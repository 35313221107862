import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class ClientStatisticService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'statisticId',
      refreshEnabled: false,
      invalidateFromParent: true,
      watch: ['entity', 'comment'],
      match: () => {
        // match all watches, invalidates on all changes
        return true; // todo: maybe check more specific
      },
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType === constants.dataTypes.list) {
          return data ? Object.keys(data).reduce((a, k) => {
            // a statistic is unique based on query keys / filters used
            const statisticId = `${k}_${utils.sha1(meta?.dataKey.keys)}`;
            return a.concat([{statisticId, data: data[k]}]);
          }, []) : null;
        } else {
          // a statistic is unique based on query keys / filters used
          const statisticId = `${meta.context?.$store.statisticId}_${utils.sha1(meta?.dataKey.keys)}`;
          return data ? {
            statisticId,
            data: (!data.hasOwnProperty('total') && data.hasOwnProperty(meta.context?.$store.statisticId)) ?
              data[meta.context?.$store.statisticId] : data
          } : null
        }
      }
    }, callbacks);
  }
}
