import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledOnboarding from 'components/organisms/Onboardings/Onboarding/Onboarding.styles';
import {usePopper} from 'components/molecules/Poppers/ContextPopper/ContextPopper';
import Box from 'components/atoms/Layout/Box/Box';
import Close from '@mui/icons-material/Close';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import utils from 'helpers/utils';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import Stepper from 'components/atoms/Steppers/Stepper/Stepper';
import Step from 'components/atoms/Steppers/Step/Step';
import StepContent from 'components/atoms/Steppers/StepContent/StepContent';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import StepButton from 'components/atoms/Steppers/StepButton/StepButton';
import {ArrowDropDown} from '@mui/icons-material';

const Onboarding = withMemo(React.forwardRef((props, ref) => {
  const {
    title,
    steps,
    ...innerProps
  } = useComponentProps(props, 'Onboarding');

  const [internalState, setInternalState] = useState({activeStep: -1});

  const popper = usePopper();

  useLayoutEffect(() => {
    const sIdx = steps.findIndex((s) => !s.completed);
    setInternalState((current) => {
      return utils.updater({
        ...current,
        activeStep: current.activeStep !== -1 ? current.activeStep : sIdx
      })(current);
    })
  }, [steps]);

  const renderHeader = () => {
    const action = {
      icon: <Icon icon={Close}/>,
      tooltip: 'Close',
      color: 'white',
      onClick: () => {
        popper?.close?.();
      }
    }

    return <Box className="Onboarding-header">
      {title ? <Box className="Onboarding-header-title">{utils.isReactElement(title) ? title : <H6>{title}</H6>}</Box> : null}
      <ActionIconButton action={action}
                        size="smaller"
                        density="dense"
                        variant="transparent"/>
    </Box>
  }

  const handleStepClick = (idx) => () => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        activeStep: current.activeStep === idx ? -1 : idx
      })(current);
    })
  }

  const renderStepper = () => {
    return <Stepper className="Onboarding-stepper"
                    connectors={false}
                    nonLinear={true}
                    variant="onboarding"
                    activeStep={internalState.activeStep}
                    orientation="vertical">
      {steps.map((step, idx) => (
        <Step key={step.label} completed={step.completed}>
          <StepButton hideNumber={true}
                      onClick={handleStepClick(idx)}
                      expandIcon={<Icon size="smaller" icon={ArrowDropDown} />}>
            <Typography variant="subtitle2">{step.label}</Typography>
          </StepButton>
          <StepContent>
            <Typography variant="body2">{step.description}</Typography>
            <ActionButton action={step.action} size="small"/>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  }

  return <StyledOnboarding ref={ref} {...innerProps}>
    {renderHeader()}
    {renderStepper()}
  </StyledOnboarding>
}));

Onboarding.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  steps: PropTypes.array
};

Onboarding.defaultProps = {
  elevation: 0,
  radius: 'roundest'
};

export default Onboarding;
