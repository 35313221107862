import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDatabaseEntityProfileBar
  from 'components/organisms/Bars/DatabaseEntityProfileBar/DatabaseEntityProfileBar.styles';

const DatabaseEntityProfileBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityProfileBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDatabaseEntityProfileBar ref={ref} {...innerProps} />
});

DatabaseEntityProfileBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityProfileBar.defaultProps = {
};

export default DatabaseEntityProfileBar;
