import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import DealflowTooltip from 'components/molecules/Tooltips/DealflowTooltip/DealflowTooltip';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledDealflowRundownBarChart from 'components/organisms/Charts/DealflowRundownBarChart/DealflowRundownBarChart.styles';
import AddBox from '@mui/icons-material/AddBox';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const DealflowRundownBarChart = React.forwardRef((props, ref) => {
  const {
    dealflow,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowRundownBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const client = useAuthClient();
  const groups = client?.dealflowGroups;

  const [groupsMemo, dealflowMemo] = useMemo(() => {
    if (groups && dealflow && dealflow.find((df) => df.count > 0)) {
      const count = dealflow.reduce((t, df) => t + df.count, 0);
      const leaders = dealflow.reduce((a, df) => {
        df.leaders.forEach((l) => {
          let leader = a.find((ld) => (!utils.isDefined(l.leader) && !utils.isDefined(ld.leader)) ||
            (+ld.leader?.userId === +l.leader?.userId));
          if (!leader) {
            leader = utils.clone(l, true);
            a.push(leader);
          } else {
            leader.count += l.count;
          }
        });

        return a;
      }, []);

      return [groups.concat({
        groupId: -1,
        label: 'Added to dealflow',
        position: -1,
        color: 'black',
        statuses: [],
        DealflowIconProps: {
          icon: AddBox
        }
      }), dealflow.concat({
        groupId: -1,
        count,
        leaders
      })];
    } else {
      return [null, null];
    }
  }, [groups, dealflow]);

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (dealflowMemo && groupsMemo) {
      legend = groupsMemo
        .filter((g) => !g.statuses.find((status) => +status.statusId === 0))
        .filter((g) => dealflowMemo.find((df) => +df.groupId === +g.groupId))
        .map((g) => {
          return {
            id: g.groupId,
            position: g.position,
            label: g.label,
            color: utils.deprecatedColor(g.color),
            active: visibility?.[g.groupId] ?? true,
            meta: g,
          }
        });
    }

    const rows = Math.ceil(legend.length / 6);

    return [legend, rows];
  }, [groupsMemo, dealflowMemo, visibility]);

  const dealflowData = useMemo(() => {
    if (dealflowMemo && groupsMemo) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: groupsMemo
          .filter((g) => !g.statuses.find((status) => +status.statusId === 0))
          .map((g) => {
            return {
              id: g.groupId,
              position: g.position,
              dataKey: g.groupId,
              name: g.groupId,
              label: g.label,
              color: utils.deprecatedColor(g.color)
            }
          })
          .filter((g) => {
            return Boolean(legend.find((l) => l.active && +l.id === +g.id))
          }),
        data: dealflowMemo
          .reduce((a, df) => {
            const group = groupsMemo.find((g) => +g.groupId === +df.groupId);

            return a.concat([{
              id: df.groupId,
              position: group?.position,
              name: group?.label,
              [df.groupId]: df.count,
              [`${df.groupId}-meta`]: {
                ...group,
                members: df.leaders.map((l) => ({
                  ...utils.camelcase(l.leader),
                  count: l.count
                })).sort((a, b) => {
                  if (!utils.isDefined(a.userId) && !utils.isDefined(b.userId)) {
                    return 0;
                  } else if (!utils.isDefined(a.userId)) {
                    return -1
                  } else if (!utils.isDefined(b.userId)) {
                    return 1
                  } else {
                    return a.count - b.count;
                  }
                })
              }
            }]);
          }, [])
          .filter((d) => {
            return Boolean(legend.find((l) => l.active && +l.id === +d.id))
          })
          .sort((a, b) => a.position - b.position),
        TooltipComponent: DealflowTooltip
      };
    }
  }, [dealflowMemo, groupsMemo, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowRundownBarChart ref={ref} {...innerProps}>
    <Box className="DealflowRundownBarChart-chart">
      <BarChart {...dealflowData}
                showGrid
                showTooltip
                showCursor
                isLoading={isLoading}
                radius={4}
                barSize={72}
                layout="horizontal"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  variant: 'members',
                  placement: 'right'
                }}
                margin={{
                  top: 0, right: 0, bottom: 4, left: -24
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="DealflowRundownBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledDealflowRundownBarChart>
});

DealflowRundownBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  BarChartProps: PropTypes.object
};

DealflowRundownBarChart.defaultProps = {
};

export default DealflowRundownBarChart;
