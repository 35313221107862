import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {wrapChartComponent} from 'helpers/wrapper';
import {Bar} from 'recharts';
import utils from 'helpers/utils';
import StyledLegend from 'components/molecules/Charts/Legend/Legend.styles';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import Circle from '@mui/icons-material/Circle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import List from 'components/atoms/Lists/List/List';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import Badge from 'components/atoms/Badges/Badge/Badge';

const Legend = React.forwardRef((props, ref) => {
  const {
    legend,
    onClick,
    variant,
    orientation,
    showTooltip,
    colorLabel,
    isLoading,
    onMouseEnter,
    onMouseLeave,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'Legend', {
    styled: ['rows'],
    static: ['clickable', 'hoverable', 'isLoading'],
    variable: ['orientation']
  });

  const clickable = Boolean(onClick);
  const hoverable = Boolean(onMouseEnter);

  const handleMouseEnter = (item) => (e) => {
    onMouseEnter?.(e, item);
  }

  const handleMouseLeave = (item) => (e) => {
    onMouseLeave?.(e, item);
  }

  const handleClick = (item) => (e) => {
    onClick?.(e, item);
  }

  const icon = variant === 'bar' ? Circle : CircleOutlined;

  innerProps.className = utils.flattenClassName(innerProps.className, {clickable, hoverable});

  return <StyledLegend ref={ref} {...innerProps}>
    <List className="Legend-list">
      {legend
        .sort((a, b) => {
          if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
            return a.position - b.position;
          } else {
            return a.label.localeCompare(b.label);
          }
        })
        .map((item, idx) => {
          return <ListItem key={idx}
                           className={item.active ? 'active' : ''}
                           onMouseEnter={handleMouseEnter(item)}
                           onMouseLeave={handleMouseLeave(item)}
                           onClick={handleClick(item)}>
            {(!item.avatar && !item.badge) ? <Icon className={!item.color ? 'defaultColor' : ''}
                                                   color={item.color}
                                                   size="smaller"
                                                   icon={item.icon ?? icon} /> : null}
            {item.avatar ? <ActionAvatar className={!item.color ? 'defaultColor' : ''}
                                         action={{
                                           label: utils.avatarLabel(item.avatar),
                                           tooltip: item.avatarTooltip
                                         }}
                                         color={item.color}
                                         size="small"
                                         {...item.ActionAvatarProps}/> : null}
            <Box className="Legend-item-content">
              <Span className="Legend-item-title">
                <Span color={colorLabel ? item.color : null} showTooltip={showTooltip} isLoading={isLoading}>
                  {item.label}
                </Span>
                {item.badge ? <Badge variant="inline"
                                     color={item.color}
                                     isLoading={isLoading}
                                     badgeContent={item.badge}
                                     {...BadgeProps}/> : null}
              </Span>
              {item.explanation ? <Box className="Legend-item-explanation">
                <Span showTooltip={true} isLoading={isLoading}>{item.explanation}</Span>
              </Box> : null}
            </Box>
          </ListItem>
        })}
    </List>
  </StyledLegend>
});

Legend.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  legend: PropTypes.array,
  rows: PropTypes.number,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['bar', 'line', 'area']), PropTypes.string]),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  showTooltip: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  BadgeProps: PropTypes.object
};

Legend.defaultProps = {
  variant: 'bar',
  showTooltip: true
};

export default wrapChartComponent(Legend, Bar);
