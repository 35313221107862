import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCompletenessBarChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .CompletenessBarChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledCompletenessBarChart.propTypes = {
};

StyledCompletenessBarChart.defaultProps = {
}

export default StyledCompletenessBarChart;
