import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfile from 'components/organisms/Profiles/EntityProfile/EntityProfile';

const StyledCollectionEntityProfile = styled(EntityProfile)`
  ${ComponentStyles}
`;

StyledCollectionEntityProfile.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityProfile.defaultProps = {
};

export default StyledCollectionEntityProfile;
