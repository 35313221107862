import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledRelevancyContextCard from 'components/organisms/Cards/RelevancyContextCard/RelevancyContextCard.styles';
import constants from 'helpers/constants';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import utils from 'helpers/utils';

const RelevancyContextCard = React.forwardRef((props, ref) => {
  const {
    relevancy,
    title,
    canUpdate,
    onChange,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'RelevancyContextCard');

  const inline = innerProps.variant === 'inline';
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onChangeEvent = useEffectEvent(onChange);
  const relevancyDef = constants.data.lookup('hml', (relevancy ?? 0));
  const options = useMemo(() => {
    return constants.data.hml.filter((r) => r.value !== relevancy)
      .reduce((a, r) => {
        a.push({
          label: r.label,
          meta: {relevancy: r.value},
          ButtonProps: {
            label: r.label,
            color: `${r.buttonColor}`,
            variant: 'flat'
          },
          onClick: (e) => {
            setOpen(false);
            utils.asPromise(onChangeEvent)(r.value)
              .catch(() => {
                setOpen(true);
              });

            e.preventDefault();
          }
        })
        return a;
      }, []);
  }, [relevancy, onChangeEvent]);

  const handleToggle = (open) => {
    setOpen(open);
  }

  const action = useMemo(() => ({
    label: relevancyDef.label ?? 'None',
    ButtonProps: {
      color: `${relevancyDef.buttonColor}`,
      variant: 'flat',
      fullWidth: true,
      active: open,
      disabled: !canUpdate
    },
    onClick: () => {
      setOpen((current) => !current);
    }
  }), [open, canUpdate, relevancyDef]);

  return <StyledRelevancyContextCard ref={ref} {...innerProps}
                                     title={title}
                                     isLoading={isLoading}>
    <ActionButton ref={anchorRef}
                  className="RelevancyContextCard-chip"
                  action={action}
                  showInactive={true}
                  isLoading={isLoading}/>
    <DropdownPopper anchorEl={anchorRef.current}
                    open={open}
                    fullWidth={true}
                    onToggle={handleToggle}
                    density="sparse"
                    ContextPopperProps={{
                      size: 'small',
                      stretch: !inline
                    }}>
      <List track={true} gap={4}>
        {options.map((action, idx) => {
          return <ListItem key={idx}>
            <ActionButton style={inline ? {minWidth: `${innerProps.theme.layout(112)}`} : null}
                          fullWidth={!inline} action={action}/>
          </ListItem>
        })}
      </List>
    </DropdownPopper>
  </StyledRelevancyContextCard>
});

RelevancyContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  relevancy: PropTypes.number,
  title: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'inline']), PropTypes.string])
};

RelevancyContextCard.defaultProps = {
  title: 'Personal relevance',
  variant: 'standard'
};

export default RelevancyContextCard;
