import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledTasksDashboardCard from 'components/organisms/Cards/TasksDashboardCard/TasksDashboardCard.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import ActionSwitch from 'components/molecules/Switches/ActionSwitch/ActionSwitch';
import TasksList from 'components/organisms/Lists/TasksList/TasksList';
import {useCommentToggleTaskDone} from 'services/comment/comment.utils';
import {useCommentList} from 'services/comment/comment.hooks';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';

const TasksDashboardCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TasksDashboardCard', {
    children: ['tasks', 'stepper']
  });

  const authorize = useAuthorize();
  const profileProvider = useProfile();
  const cardState = profileProvider.cardState;

  const tasksSwitched = Boolean(cardState.cardSettings['tasks']?.filter?.switched);
  const tasks = useCommentList({
    page: 0,
    pageSize: 10,
    filter: tasksSwitched ? [{id: 'open', value: true}, {id: 'responsible', value: true}] : [{id: 'open', value: true}],
    sort: [{id: 'dueDate', desc: false}, {id: 'createdAt', desc: true}],
    task: true
  }, {
    ...constants.queryOptions.infinite
  });

  const setCardSettingsEvent = useEffectEvent(cardState.setCardSettings);
  const switchAction = useMemo(() => ({
    label: 'My tasks',
    active: tasksSwitched,
    onClick: (e) => {
      setCardSettingsEvent?.((current) => ({
        ...current,
        'tasks': {
          ...current?.['tasks'],
          filter: { switched: !tasksSwitched }
        }
      }));
      e.preventDefault();
    },
  }), [setCardSettingsEvent, tasksSwitched])

  const renderActionSwitch = () => {
    return <ActionSwitch action={switchAction}
                         SwitchProps={{
                           size: 'medium',
                           color: 'primary'
                         }}/>
  }

  const toggleDone = useCommentToggleTaskDone();
  const taskCardProps = useMemo(() => ({
    onToggle: (task, done) => {
      return toggleDone?.(task, done);
    },
    onCanUpdate: (task, markDone) => {
      return authorize({
        attribute: markDone ? 'task.markDone' : 'task.update',
        meta: {task}
      });
    },
    orientation: 'horizontal',
    size: 'small',
    showRelation: true
  }), [authorize, toggleDone]);

  const handleClick = (e, task) => {
    if (!e.defaultPrevented) {
      profileProvider.openTask(task.commentId);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTasksDashboardCard ref={ref} {...innerProps}
                                   title={'Tasks'}
                                   context={renderActionSwitch()}>
    {(tasks.data?.length > 0 || tasks.status.isLoading) ?
      <TasksList className="TasksDashboardCard-tasks"
                 tasks={tasks}
                 onClick={handleClick}
                 TaskCardProps={taskCardProps} /> : null}
    {(tasks.data?.length === 0 && !tasks.status.isLoading) ?
      <Box className="DashboardCard-empty">
        <P>No task(s) found</P>
      </Box> : null}
  </StyledTasksDashboardCard>
});

TasksDashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

TasksDashboardCard.defaultProps = {};

export default TasksDashboardCard;
