import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTableColumnsContent = styled(Box)`
  width: 100%;
  
  .Form {
    .Form-fields {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(${props => props.$rows}, auto);
      grid-auto-flow: column;
      gap: unset;
      padding: ${props => props.theme.spacing(0.5)};

      .CheckboxField {
        min-height: unset !important;
      }
    }
  }
  ${ComponentStyles}
`;

StyledTableColumnsContent.propTypes = {
  theme: PropTypes.object
};

StyledTableColumnsContent.defaultProps = {}

export default StyledTableColumnsContent;
