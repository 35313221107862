import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';
import {processCollection} from 'services/portal/collection/collection.utils';

const path = utils.servicePath(import.meta.url);

export class PortalCollectionService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      refreshHasList: false,
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processCollection);
          } else {
            return processCollection(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
