import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import StyledEntitiesBrowserList from 'components/organisms/Browsers/EntitiesBrowserList/EntitiesBrowserList.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const EntitiesBrowserList = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntitiesBrowserList');

  const tableProvider = useTable();

  const profileProvider = useProfile();
  const entity = profileProvider.data?.data;

  const nextEntitiesMemo = useEffectItem(tableProvider.next(2));
  const prevEntitiesMemo = useEffectItem(tableProvider.prev(2));
  const items = useMemo(() => {
    if (entity) {
      return (prevEntitiesMemo ?? []).filter((e) => {
        return +e.entityId !== +entity.entityId &&
          !(nextEntitiesMemo ?? []).find((ep) => +ep.entityId === +e.entityId);
      })
        .concat([entity])
        .concat((nextEntitiesMemo ?? []).filter((e) => +e.entityId !== +entity.entityId))
        .map((entity) => ({
          id: entity.entityId,
          url: entity.website,
          screenshots: entity.websiteScreenshots
        }));
    } else {
      return [];
    }
  }, [nextEntitiesMemo, prevEntitiesMemo, entity]);

  return <StyledEntitiesBrowserList ref={ref} {...innerProps} items={items} selectedId={entity?.entityId}/>
});

EntitiesBrowserList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  items: PropTypes.array,
  selected: PropTypes.object,
  BrowserProps: PropTypes.object
};

EntitiesBrowserList.defaultProps = {
  showControls: false,
  color: 'primary'
};

export default EntitiesBrowserList;
