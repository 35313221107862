import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Actionbar from 'components/organisms/Snackbars/Actionbar/Actionbar';

const StyledCollectionsActionbar = styled(Actionbar)` 
  ${ComponentStyles}
`;

StyledCollectionsActionbar.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsActionbar.defaultProps = {}

export default StyledCollectionsActionbar;
