import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogContent from '@mui/material/DialogContent';

const StyledDialogContent = styled(DialogContent)` 
  ${ComponentStyles}
`;

StyledDialogContent.propTypes = {
  theme: PropTypes.object
};

StyledDialogContent.defaultProps = {}

export default StyledDialogContent;
