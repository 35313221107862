import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ViewBar from 'components/organisms/Bars/ViewBar/ViewBar';

const StyledEntitiesViewBar = styled(ViewBar)`
  ${ComponentStyles}
`;

StyledEntitiesViewBar.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesViewBar.defaultProps = {
};

export default StyledEntitiesViewBar;
