import React, {useLayoutEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import AnalysisScoreTableCell from 'components/molecules/TableCells/AnalysisScoreTableCell/AnalysisScoreTableCell';
import StyledAnalysisScoreTableCellEdit
  from 'components/organisms/TableCellEdits/AnalysisScoreTableCellEdit/AnalysisScoreTableCellEdit.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {Span, P} from 'components/atoms/Text/Typography/Typography';
import Edit from '@mui/icons-material/Edit';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {withMemo} from 'helpers/wrapper';

const AnalysisScoreTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    entity,
    collection,
    score,
    fieldData,
    onClick,
    onEdit,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreTableCellEdit');

  const [hoverScore, setHoverScore] = useState(null);

  const editable = Boolean(onEdit);

  const handleHover = (e, data) => {
    const score = data?.[`${data?.dataKey}-meta`];
    if (score) {
      setHoverScore(score);
    }
  }

  const handleMouseLeave = () => {
    table.setEditingCell((current) => {
      if (current?.id === cell?.id) {
        return null;
      } else {
        return current;
      }
    });
  }

  const fields = useMemo(() => {
    if (hoverScore) {
      if (hoverScore.tagGroupId > 0) {
        return utils.initializeFormFields([{
          name: 'tagGroup',
          label: <Box>
            <Span>{hoverScore.tagGroup.name}</Span>
            <Typography color="text.secondary" nowrap={true} variant="caption">{hoverScore.points} points</Typography>
          </Box>,
          entity: 'entity',
          tagGroupId: hoverScore.tagGroupId,
          tagGroup: hoverScore.tagGroup,
          required: false,
          readOnly: true,
          FormFieldProps: {
            variant: 'staticLabel',
            hiddenIcons: false,
            fullWidth: true
          }
        }], entity);
      }
    }
  }, [hoverScore, entity]);

  const onEditEvent = useEffectEvent(onEdit);
  const action = useMemo(() => ({
    label: 'Edit categories',
    auth: !editable ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      onEditEvent?.();
      e.preventDefault();
    }
  }), [editable, onEditEvent]);

  useLayoutEffect(() => {
    setHoverScore(score);
  }, [score]);

  return <StyledAnalysisScoreTableCellEdit ref={ref} {...innerProps}
                                           cell={cell}
                                           table={table}
                                           autoFocus={false}
                                           onMouseLeave={handleMouseLeave}
                                           TableCellPopperProps={{
                                             density: 'densest',
                                             placement: 'bottom',
                                             stretch: true,
                                             width: 404,
                                             minWidth: 306,
                                             maxWidth: 404
                                           }}
                                           Anchor={<AnalysisScoreTableCell ref={ref}
                                                                           entity={entity}
                                                                           collection={collection}
                                                                           onHover={handleHover}
                                                                           onClick={onClick}/>}>
    {fields ? <InlineForm className="AnalysisScoreTableCellEdit-form"
                          fields={fields}
                          fieldData={fieldData} /> : null}
    {!fields ? <Box className="AnalysisScoreTableCellEdit-unassigned">
      <Box className="AnalysisScoreTableCellEdit-unassigned-title">
        <Span>Unassigned</Span>
        <Typography color="text.secondary" nowrap={true} variant="caption">{hoverScore?.points ?? 0} points</Typography>
      </Box>
      <P className="AnalysisScoreTableCellEdit-unassigned-content">
        This company scored {hoverScore?.scored} points of potentially {hoverScore?.total} points
      </P>
    </Box> : null}
    <ActionButton action={action}
                  fullWidth={true}
                  size="small"
                  variant="contained"/>
  </StyledAnalysisScoreTableCellEdit>
}));

AnalysisScoreTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  status: PropTypes.number,
  onChange: PropTypes.func
};

AnalysisScoreTableCellEdit.defaultProps = {
  size: 'large'
};

export default AnalysisScoreTableCellEdit;
