import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledLogoutPage = styled(Page)`
  .H6 {
    width: 100%;
    text-align: center;
  }

  .LogoutPage {
    &-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledLogoutPage.propTypes = {
  theme: PropTypes.object
};

StyledLogoutPage.defaultProps = {
};

export default StyledLogoutPage;
