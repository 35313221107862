import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class TestService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'id',
      api: {
        query: {
          list: (params) => {
            return {
              ...this.api.default.query.list(params),
              action: async () => {
                const {filter} = params;

                if (filter === 'r') {
                  logger.trace('RESPONSE ERROR');
                  // eslint-disable-next-line no-throw-literal
                  throw {
                    response : {status: constants.http.status.serverError}
                  };
                  // return http.get('auth/xxx', {}, {
                  //   blka: 'ewfef'
                  // });
                } else if (!utils.isNumber(filter)) {
                  logger.trace('SET SET SET');
                  throw new Error('This is wrong');
                } else {
                  logger.trace('RESET RESET RESET');
                  await utils.sleep(filter ? 2000 : 1000);

                  const data = [{id: 1}, {id: 2}, {id: 3}, {id: 4}];
                  if (filter) {
                    return {meta: {time: new Date()}, data: data.filter((d) => d.id >= filter)};
                  } else {
                    return {meta: {time: new Date()}, data};
                  }
                }
              }
            }
          }
        }
      }
    }, callbacks);
  }
}
