import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToggleButtonGroup from 'components/atoms/Toggles/ToggleButtonGroup/ToggleButtonGroup';

const StyledActionToggleButtonGroup = styled(ToggleButtonGroup)`
  ${ComponentStyles}
`;

StyledActionToggleButtonGroup.propTypes = {
  theme: PropTypes.object
};

StyledActionToggleButtonGroup.defaultProps = {
}

export default StyledActionToggleButtonGroup;
