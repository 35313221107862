import React, {useMemo} from 'react'
import CollectionEntitiesTableProvider
  from 'components/organisms/Providers/TableProvider/CollectionEntitiesTableProvider';
import useCollectionEntityRoutes from 'routes/collections/collection/entities/entity/entity.routes';
import {Outlet} from 'react-router-dom';
import CollectionEntitiesBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesBarWrapper/CollectionEntitiesBarWrapper';
import CollectionEntitiesFiltersWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesFiltersWrapper/CollectionEntitiesFiltersWrapper';
import CollectionEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesTableBarWrapper/CollectionEntitiesTableBarWrapper';
import useCollectionEntitiesGraphsRoutes from 'routes/collections/collection/entities/graphs/graphs.routes';
import CollectionEntityProfileWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityProfileWrapper/CollectionEntityProfileWrapper';
import CollectionEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesColumnsWrapper/CollectionEntitiesColumnsWrapper';
import utils from 'helpers/utils';
import CollectionEntitiesPage from 'components/pages/Collection/Entity/CollectionEntitiesPage/CollectionEntitiesPage';

export default function useCollectionEntitiesRoutes () {
  const graphRoutes = useCollectionEntitiesGraphsRoutes();
  const collectionEntityRoutes = useCollectionEntityRoutes();
  return useMemo(() => {
    const collectionMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: {
          name: 'collection',
          path: 'collection', // store path
          id: match?.params?.collectionId, // id from match
          params: match?.params
        }
      };
    };

    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'collection',
            path: 'collection', // store path
            id: match?.params?.collectionId, // id from match
            params: match?.params
          },
          {
            name: 'entity',
            path: 'collection/entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <CollectionEntitiesPage />,

        handle: {
          title: 'Collection - companies',
          auth: utils.createAuth({
            attribute: 'collection.entity.list',
            meta: collectionMeta
          })
        }
      },
      {
        path: 'graphs',
        element: <graphRoutes.WrapperComponent><Outlet /></graphRoutes.WrapperComponent>,
        children: graphRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'collection.entity.graph.route',
            meta: collectionMeta
          })
        }
      },
      {
        path: ':entityId',
        element: <collectionEntityRoutes.WrapperComponent><Outlet /></collectionEntityRoutes.WrapperComponent>,
        children: collectionEntityRoutes.route,

        handle: {
          title: 'Collection - company',
          auth: utils.createAuth({
            attribute: 'collection.entity.route',
            meta: entityMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <CollectionEntitiesTableProvider>
          <CollectionEntitiesFiltersWrapper>
            <CollectionEntitiesColumnsWrapper>
              <CollectionEntitiesBarWrapper>
                <CollectionEntitiesTableBarWrapper>
                  <CollectionEntityProfileWrapper>
                    {children}
                  </CollectionEntityProfileWrapper>
                </CollectionEntitiesTableBarWrapper>
              </CollectionEntitiesBarWrapper>
            </CollectionEntitiesColumnsWrapper>
          </CollectionEntitiesFiltersWrapper>
        </CollectionEntitiesTableProvider>
      },
      route: route
    };
  }, [collectionEntityRoutes, graphRoutes]);
};
