import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionEntitiesTableBar
  from 'components/organisms/Bars/CollectionEntitiesTableBar/CollectionEntitiesTableBar.styles';
import {useSplitPath} from 'helpers/hooks/links';
import constants from 'helpers/constants';

const CollectionEntitiesTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTableBar');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const [, pathPostfix] = useSplitPath('entities');

  const isSuggestion = tableProvider?.view?.name === 'suggestions';
  const entitiesCount = collection?.newInCollection?.find((c) => c.scope === constants.collection.scopes.inside)?.total;

  const filterAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'collection.entity.filters', meta: {collection: collection} }),
  }), [collection]);

  const columnAction = useMemo(() => ({
    auth: (!isSuggestion && entitiesCount === 0) ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({ attribute: 'collection.entity.columns', meta: {collection: collection} }),
  }), [collection, isSuggestion, entitiesCount]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntitiesTableBar ref={ref} {...innerProps}
                                           hideColumn={Boolean(pathPostfix)}
                                           filterAction={filterAction}
                                           columnAction={columnAction} />
});

CollectionEntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTableBar.defaultProps = {
};

export default CollectionEntitiesTableBar;
