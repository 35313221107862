import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {PieChart} from 'recharts';

const StyledPieChart = styled(PieChart)` 
  &.PieChart {
    &-isLoading {
      .PieChart-pie {
        fill-opacity: 0.2;
        pointer-events: none;
      }
    }
    
    .PieChart-pie, .recharts-pie {
      * {
        outline: none;
      }
    }
    .recharts-pie {
      .PieChart-pie {
        &.hover {
          stroke: ${props => props.theme.property('palette.background.paper')};
          filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.4));
        }
        &.notHover {
          fill-opacity: 0.3;
        }
      }

      .PieChart-activeShape {
        &-tick {
          display: flex;
          flex-direction: column;
        }
        
        .Typography {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-percentage {
          margin-top: ${props => props.theme.layout(-2)};
          color: ${props => props.theme.property('palette.text.secondary')};
        }
      }
    }
  }

  &.PieChart-clickable {
    .recharts-pie {
      .PieChart-pie {
        &.hover {
          cursor: pointer;
        }
      }

      .PieChart-activeShape {
        .recharts-sector {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledPieChart.propTypes = {
  theme: PropTypes.object
};

StyledPieChart.defaultProps = {}

export default StyledPieChart;
