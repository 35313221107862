import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledBrowser = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
  
  background-color: ${props => rgba(props.theme.property(`palette.${props.$color}`)?.main, 0.02)};

  .Browser {
    &-controls {
      display: flex;
      .H6 {
        flex-grow: 1;
      }  
    }
    
    &-frame {
      flex-grow: 1;
      z-index: 1;

      overflow-x: hidden;
      overflow-y: auto;
    }
    
    &-screenshots {
      .ImageListItem {
        width: 100%;
        overflow-x: hidden;
        flex-direction: column-reverse;
        
        .ImageListItemBar {
          color: ${props => props.theme.property('palette.white.main')};
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.3) 100%);
          background-color: ${props => props.theme.property('palette.white.main')};

          column-gap: ${props => props.theme.layout('0.5sp')};
          
          > *:has(.MuiImageListItemBar-title) {
            padding-bottom: ${props => props.theme.spacing(0.75)};
          }
          
          .IconButton {
            margin-left: ${props => props.theme.spacing(0.25)};
            margin-top: ${props => props.theme.spacing(0.25)};
          }
        }
      }
    }
    
    &-center {
      position: absolute;
      height: 100%;
      width: 100%;
      
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      > * {
        pointer-events: all;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledBrowser.propTypes = {
  theme: PropTypes.object
};

StyledBrowser.defaultProps = {
};

export default StyledBrowser;
