import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useApplicantUploadStatus () {
  return useServiceStatus(path);
}

export function useApplicantUploadList ({applicantId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {applicantId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useApplicantUploadGet ({applicantId, uploadId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {applicantId, uploadId}, queryOptions);
}

export function usePortalApplicantUploadAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function usePortalApplicantUploadUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function usePortalApplicantUploadDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
