import React, {useRef} from 'react';
import {useAuthPassword} from 'services/auth/auth.hooks';
import {useComponentProps} from 'helpers/hooks/utils';
import Form from 'components/organisms/Forms/Form/Form';
import PropTypes from 'prop-types';
import StyledResetForm from 'components/organisms/Forms/ResetPasswordForm/ResetPasswordForm.styles';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';

const pages = {
  0: {
    title: 'Reset password',
    fields: {
      newPassword: {
        label: 'New password',
        inlineLabel: 'new password',
        type: 'password',
        validation: 'password',
        initial: null,
        required: true,
        FormFieldProps: {
          autoFocus: true,
          hiddenLabel: true,
          inputProps: {
            autocomplete: 'new-password'
          }
        }
      },
      confirmNewPassword: {
        label: 'Confirm new password',
        inlineLabel: 'confirm new password',
        type: 'password',
        validation: ['password', 'match(newPassword)'],
        initial: null,
        required: true,
        FormFieldProps: {
          hiddenLabel: true,
          inputProps: {
            autocomplete: 'new-password'
          }
        }
      },
    }
  }
};

const ResetPasswordForm = (props) => {
  const {
    hash,
    ...innerProps
  } = useComponentProps(props, 'ResetPasswordForm');

  const doPassword = useAuthPassword();

  const formRef = useRef(null);

  const handleSubmit = (values, actions) => {
    const formik = formRef.current?.formik;
    doPassword.mutation.mutateAsync({
      hash: hash,
      password: values.newPassword,
    })
      .then(() => {
        actions.setSubmitting(false);
      })
      .catch((error) => {
        if (error?.response?.status < constants.http.status.serverError) {
          formik.setErrors({
            newPassword: 'Invalid password',
            confirmNewPassword: 'Invalid password'
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleChange = () => {
    doPassword.status.clearAll();
  };

  return <StyledResetForm {...innerProps}>
    <Form ref={formRef}
          pages={pages}
          onChange={handleChange}
          onSubmit={handleSubmit}
          SubmitButtonProps={{children: 'Reset password'}}/>
    <Box className='auth-links'>
      <ActionLink action={{
        label: 'Log in instead?',
        navigation: {
          to: `/auth/login`,
          replace: true
        }
      }}/>
    </Box>
  </StyledResetForm>
};

ResetPasswordForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  hash: PropTypes.string
};

ResetPasswordForm.defaultProps = {};

export default ResetPasswordForm;

