import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogActions from 'components/atoms/Dialogs/DialogActions/DialogActions';

const StyledDialogFooter = styled(DialogActions)`
  padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
  border-top: 1px solid ${props => props.theme.property('palette.divider')};
  white-space: nowrap;
  
  .DialogFooter-info {
    ${props => props.theme.font2Css('body2')};
    flex-grow: 1;
    margin-right: ${props => props.theme.spacing(1)};
    
    &, .Span {
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: ${props => props.theme.fontSize(13)};
      line-height: ${props => props.theme.lineHeight('1.3f')};
    }
  }

  > *:not(.DialogFooter-info) {
    min-width: fit-content;
  }
  
  &.DialogFooter-split {
    > *:not(.DialogFooter-info):first-of-type {
      order: 1
    }
    > *:not(.DialogFooter-info):last-of-type, > *:not(.DialogFooter-info):not(:first-of-type) {
      order: 3
    }
    .DialogFooter-info {
      order: 2;
    }

    &:has(*:nth-child(3)) {
      .DialogFooter-info {
        text-align: center;
        margin-left: ${props => props.theme.spacing(1)};
        margin-right: ${props => props.theme.spacing(1)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDialogFooter.propTypes = {
  theme: PropTypes.object
};

StyledDialogFooter.defaultProps = {}

export default StyledDialogFooter;
