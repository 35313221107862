import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCenterWrapper = styled(Box)`
  display: flex;

  align-items: center;
  justify-content: center;

  min-height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));
  width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));

  > * {
    text-align: center;
  
    max-width: ${props => props.theme.layout(props.$centerWidth)};
    width: ${props => props.$centerWidth ? props.theme.layout('100%') : null};
    height: unset;
    min-height: unset;
  }

  ${ComponentStyles}
`;

StyledCenterWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCenterWrapper.defaultProps = {
};

export default StyledCenterWrapper;
