import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowEntityProfileBar
  from 'components/organisms/Bars/DealflowEntityProfileBar/DealflowEntityProfileBar.styles';

const DealflowEntityProfileBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityProfileBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntityProfileBar ref={ref} {...innerProps} />
});

DealflowEntityProfileBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityProfileBar.defaultProps = {
};

export default DealflowEntityProfileBar;
