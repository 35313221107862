import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';

const StyledChooseCollectionCategoriesWizardContent = styled(WizardContent)`
  ${ComponentStyles}
`;

StyledChooseCollectionCategoriesWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledChooseCollectionCategoriesWizardContent.defaultProps = {}

export default StyledChooseCollectionCategoriesWizardContent;
