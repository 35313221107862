import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &.ListWrapper {
    &-stickyHeader {
      .ListWrapper {
        &-header {
          position: sticky;
          top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
        }
      }
    }

    &-stickyFooter {
      .ListWrapper {
        &-footer {
          position: sticky;
          bottom: ${props => props.$wrapper?.boundsCum?.bottom ?? 0};
        }
      }
    }
  }
  
  .ListWrapper {
    &-content {
      height: 1px;
      flex-grow: 1;
    }

    &-header, &-footer {
      width: 100%;
      z-index: ${props => props.theme.property('zIndex.appBar')};
    }
  }

  ${ComponentStyles}
`;

StyledListWrapper.propTypes = {
  theme: PropTypes.object
};

StyledListWrapper.defaultProps = {}

export default StyledListWrapper;
