import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import StyledIconField from 'components/molecules/Fields/IconField/IconField.styles';

const IconField = withMemo(React.forwardRef((props, ref) => {
  const {
    iconSet,
    groupSize,
    IconButtonProps,
    IconProps,
    ...innerProps
  } = useComponentProps(props, 'IconField');

  const options = useMemo(() => {
    const icons = constants.icons[iconSet];
    return icons ? Object.keys(icons).map((icon) => ({
      value: icon,
      label: icon,
      icon: icons[icon]
    })) : [];
  }, [iconSet]);

  const renderOption = (option, props) => {
    const handleClick = () => {
      props.onChange?.({
        target: {
          name: props.name,
          checked: !props.checked
        }
      })
    };

    return <IconButton variant="outlined"
                       color={option.value}
                       selected={props.checked}
                       disabled={props.disabled}
                       onClick={handleClick}
                       {...IconButtonProps}>
      <Icon icon={option.icon} {...IconProps} />
    </IconButton>
  };

  const renderReadOnlyOption = (option) => {
    return <IconButton variant="outlined"
                       color={option.value}
                       disabled={true}
                       {...IconButtonProps}>
      <Icon icon={option.icon}
            color={option.value}
            {...IconProps} />
    </IconButton>
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledIconField ref={ref} {...innerProps}
                          options={options}
                          $columns={innerProps.columns}
                          $rows={innerProps.rows}
                          renderOption={renderOption}
                          renderReadOnlyOption={renderReadOnlyOption}
                          ListProps={{
                            orientation: 'horizontal'
                          }}/>
}));

IconField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  iconSet: PropTypes.string,
  groupSize: PropTypes.number,
  ColorIconButtonProps: PropTypes.object,
  ColorIconProps: PropTypes.object
};

IconField.defaultProps = {
  columns: 4,
  rows: 4
};

export default IconField;
