import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledRadio = styled(Radio)`
  ${props => !(props.icon || props.checkedIcon) ? css`
    // see also Checkbox
    &.Radio:not(.Mui-checked) {
      svg {
        z-index: 1;
      }
      
      &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: ${props => props.theme.layout(16)};
        height: ${props => props.theme.layout(16)};
        background-color: transparent;
      }
      
      &.Radio-size-smaller {
        &:before {
          width: ${props => props.theme.layout(12)};
          height: ${props => props.theme.layout(12)};
        }
      }

      &.Radio-size-small {
        &:before {
          width: ${props => props.theme.layout(14)};
          height: ${props => props.theme.layout(14)};
        }
      }

      &.Radio-size-large {
        &:before {
          width: ${props => props.theme.layout(22)};
          height: ${props => props.theme.layout(22)};
        }
      }
    }
  ` : null}
  
  &.Skeleton {
    &:before {
      content: unset !important;
    }
    
    width: ${props => props.theme.layout(40)};
    height: ${props => props.theme.layout(40)};

    &.Radio-density-denser {
      padding: ${props => props.theme.layout(30)};
    }
    &.Radio-density-dense {
      padding: ${props => props.theme.layout(34)};
    }

    &.Radio-size-smaller {
      width: ${props => props.theme.layout(32)};
      height: ${props => props.theme.layout(32)};

      &.Radio-density-denser {
        padding: ${props => props.theme.layout(26)};
      }
      &.Radio-density-dense {
        padding: ${props => props.theme.layout(28)};
      }
    }
    
    &.Radio-size-small {
      width: ${props => props.theme.layout(38)};
      height: ${props => props.theme.layout(38)};

      &.Radio-density-denser {
        padding: ${props => props.theme.layout(30)};
      }
      &.Radio-density-dense {
        padding: ${props => props.theme.layout(34)};
      }
    }

    &.Radio-size-large {
      width: ${props => props.theme.layout(48)};
      height: ${props => props.theme.layout(48)};

      &.Radio-density-denser {
        padding: ${props => props.theme.layout(38)};
      }
      &.Radio-density-dense {
        padding: ${props => props.theme.layout(42)};
      }
    }
  }


  &.Radio-size-medium {
    padding: ${props => props.theme.layout(8)};
  }
  
  &.Radio-size-smaller {
    svg {
      font-size: ${props => props.theme.fontSize(18.7)};
    }
  }
  
  &.Radio-density-denser {
    padding: ${props => props.theme.layout(4)};
  }
  &.Radio-density-dense {
    padding: ${props => props.theme.layout(6)};
  }
  
  ${ComponentStyles}
`;

StyledRadio.propTypes = {
  theme: PropTypes.object
};

StyledRadio.defaultProps = {
}

export default StyledRadio;
