import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useCollectionEntityStatus () {
  return useServiceStatus(path);
}

export function useCollectionEntityList ({collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useCollectionSuggestionList ({collectionId, ...rest}, queryOptions = {}) {
  return useCollectionEntityList({
    collectionId: collectionId,
    filter: [
      {id: 'scope', value: constants.collection.scopes.outside}
    ].concat(
      !utils.isDefined(constants.collection.suggestion.threshold) ? [] :
        {id: 'collection_analysis_points_basic', value:`${constants.collection.suggestion.threshold}:${constants.numbers.maxInt}`}
    ),
    ...rest
  }, queryOptions);
}

export function useCollectionRejectionList ({collectionId, ...rest}, queryOptions = {}) {
  return useCollectionEntityList({
    collectionId: collectionId,
    filter: [
      {id: 'scope', value: constants.collection.scopes.rejected}
    ],
    ...rest
  }, queryOptions);
}

export function useCollectionEntityGet ({collectionId, entityId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {collectionId, entityId}, queryOptions);
}

export function useCollectionEntityAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionEntityUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useCollectionEntityDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionEntityEnrich (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'entities/:entityId/enrich?collectionId=:collectionId',
    ...mutationOptions
  });
}

export function useCollectionEntityAssign (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/entities/:entityId',
    hasInvalidData: true,
    ...mutationOptions
  });
}

export function useCollectionEntityUnAssign (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/entities/:entityId',
    hasInvalidData: true,
    ...mutationOptions
  });
}
