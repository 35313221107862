import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesViewBar from 'components/organisms/Bars/EntitiesViewBar/EntitiesViewBar.styles';
import {useCommentGet} from 'services/comment/comment.hooks';
import {useTaskResponsibility} from 'services/comment/comment.utils';
import {useAuthUserId} from 'services/auth/auth.utils';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useLocation} from 'react-router-dom';
import {useLinkNavigate} from 'helpers/hooks/links';
import constants from 'helpers/constants';

const EntitiesViewBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntitiesViewBar');

  const [internalState, setInternalState] = useState({
    showMoreDialog: false,
    showFinishDialog: false
  });

  const userId = useAuthUserId();
  const tableProvider = useTable();

  const location = useLocation();
  const navigate = useLinkNavigate();

  const view = tableProvider?.view;
  const selectedEntityId = tableProvider.selected()?.entityId;

  const taskView = view?.activeViews?.find((v) => v.name === 'task');
  const hasTaskView = Boolean(taskView);
  const taskId = hasTaskView ? +taskView.params?.[1] : null;
  const activeView = hasTaskView ? taskView.params?.slice(-1)[0] === 'active' : null;
  const task = useCommentGet({
    commentId: taskId
  }, { enabled: hasTaskView && taskId > 0 });

  const [isResponsible,, openTasks, totalTasks] = useTaskResponsibility(task.data, userId);
  const done = !tableProvider.list?.data?.find((d) => {
    return openTasks?.find((ot) => +ot.relationId === +d.entityId || +ot.relationId === +d.originalEntityId)
  });

  const actionableView = hasTaskView ? taskView : null;
  const closableView = hasTaskView ? taskView : null;
  const loaded = utils.isDefined(tableProvider.list?.data) && !tableProvider.list?.status?.isLoading && !tableProvider.list?.status?.isReloading &&
    (!hasTaskView || (task?.data && task?.status?.isSettled && !task?.status?.isLoading));

  const refetchEvent = useEffectEvent(tableProvider.list?.query?.refetch);
  useEffect(() => {
    if (hasTaskView) {
      refetchEvent?.();
    }
  }, [hasTaskView, selectedEntityId, refetchEvent]);

  const debouncedDialog = useMemo(() => {
    return utils.debounce((activeView, hasTaskView, loaded, openTasks, done) => {
      if (activeView) {
        if (hasTaskView && openTasks && loaded) {
          let showingDialog = false;
          if (openTasks?.length === 0) {
            showingDialog = true;
            setInternalState(utils.updater({
              showMoreDialog: false,
              showFinishDialog: true
            }, true));
          } else if (done) {
            showingDialog = true;
            setInternalState(utils.updater({
              showMoreDialog: true,
              showFinishDialog: false
            }, true));
          }

          if (!showingDialog) {
            setInternalState(utils.updater({
              showMoreDialog: false,
              showFinishDialog: false
            }, true));
          }
        }
      }
    }, constants.debounce.short);
  }, []);

  useEffect(() => {
    debouncedDialog(activeView, hasTaskView, loaded, openTasks, done);
  }, [activeView, hasTaskView, loaded, openTasks, done, debouncedDialog]);

  const handleConfirmClose = (e, reason) => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    if (reason !== 'confirmButtonClick') {
      if (closableView) {
        const path = utils.removePathParams(location.pathname + location.search, `custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_active`);
        navigate({
          to: `${path}${path.includes('?') ? '&' : '?'}custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_closed`,
          keepSearchParams: false,
          replace: true
        });
      }
    }
  }

  const handleConfirmMore = () => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    let path = `${location.pathname}?custom=${actionableView.name}`
    if (closableView) {
      path = `${location.pathname}?custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_active`;
    }

    navigate({
      to: path,
      keepSearchParams: false,
      replace: true
    });
  }

  const handleConfirmFinish = () => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    navigate({to: location.pathname, replace: true});
  }

  if (activeView || isResponsible) {
    innerProps.progress = innerProps.progress ?? (!hasTaskView ? null : (
        !((totalTasks?.length ?? 0) > 0) ? 0 :
          (((totalTasks?.length ?? 0) - openTasks?.length)) / (totalTasks?.length ?? 0) * 100)
    );
    innerProps.progressText = innerProps.progressText ?? (
      hasTaskView ? `${(totalTasks?.length ?? 0) - (openTasks?.length ?? 0)} / ${totalTasks?.length ?? 0}` : null
    );

    innerProps.progressTooltip = innerProps.progressTooltip ?? (
      hasTaskView ? 'Task progress' : null
    )
  }
  innerProps.isLoading = innerProps.isLoading || tableProvider.isLoading() || task.status.isLoading;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesViewBar ref={ref} {...innerProps}>
    <ConfirmDialog question="You have finished the tasks for the selected companies, do you want to finish the task for the rest of the companies?"
                   onClose={handleConfirmClose}
                   onConfirm={handleConfirmMore}
                   open={internalState.showMoreDialog}
                   ConfirmButtonProps={{
                     children: 'Continue',
                     color: 'primary'
                   }}/>
    <ConfirmDialog question="You have finished the task for all the companies, do you want to close the view?"
                   onClose={handleConfirmClose}
                   onConfirm={handleConfirmFinish}
                   open={internalState.showFinishDialog}
                   ConfirmButtonProps={{
                     children: 'Close view',
                     color: 'success'
                   }}/>
    {innerProps.children}
  </StyledEntitiesViewBar>
});

EntitiesViewBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntitiesViewBar.defaultProps = {
  color: 'primary'
};

export default EntitiesViewBar;
