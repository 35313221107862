import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowStatusContextCard
  from 'components/organisms/Cards/DealflowStatusContextCard/DealflowStatusContextCard.styles';
import {useClientDealflowStatus} from 'services/client/client.utils';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import DealflowStatusList from 'components/molecules/Lists/DealflowStatusList/DealflowStatusList';
import utils from 'helpers/utils';

const DealflowStatusContextCard = React.forwardRef((props, ref) => {
  const {
    statusId,
    onChange,
    canUpdate,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusContextCard');

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const status = useClientDealflowStatus(statusId);

  const handleToggle = (open) => {
    setOpen(open);
  }

  const handleClick = (e, status) => {
    setOpen(false);
    utils.asPromise(onChange)(status?.statusId)
      .catch(() => {
        setOpen(true);
      });

    e.preventDefault();
  }

  const action = useMemo(() => {
    const color = status?.statusId === 0 ? 'secondary' : utils.deprecatedColor(status?.groupColor);
    return {
      auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) : null,
      label: status?.name,
      ChipProps: {
        color: color,
        variant: 'outlined',
        size: 'large',
        icon: <DealflowIcon groupId={status?.groupId}/>,
        fullWidth: true,
        radius: 'round'
      },
      onClick: () => {
        setOpen((current) => !current);
      }
    }
  }, [status, canUpdate]);

  return <StyledDealflowStatusContextCard ref={ref} {...innerProps}
                                          title="Deal flow status"
                                          isLoading={isLoading}>
    <ActionChip ref={anchorRef}
                action={action}
                showInactive={true}
                showTooltip={false}
                isLoading={isLoading} />
    <DropdownPopper anchorEl={anchorRef.current}
                    open={open}
                    fullWidth={true}
                    onToggle={handleToggle}
                    density="densest"
                    ContextPopperProps={{
                      size: 'small',
                      stretch: true,
                      density: 'densest'
                    }}>
      <DealflowStatusList statusId={statusId}
                          track={true}
                          onClick={handleClick}/>
    </DropdownPopper>
  </StyledDealflowStatusContextCard>
});

DealflowStatusContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statusId: PropTypes.number,
  onChange: PropTypes.func,
  canUpdate: PropTypes.bool,
  isLoading: PropTypes.bool
};

DealflowStatusContextCard.defaultProps = {};

export default DealflowStatusContextCard;
