import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import MenuItem from '@mui/material/MenuItem';

const StyledMenuItem = styled(MenuItem)`
  &.MenuItem-size-smaller {
    font-size: ${props => props.theme.fontSize(15)} !important;
  }
  &.MenuItem-size-smallest {
    font-size: ${props => props.theme.fontSize(14)} !important;
  }
  
  ${ComponentStyles}
`;

StyledMenuItem.propTypes = {
  theme: PropTypes.object
};

StyledMenuItem.defaultProps = {
}

export default StyledMenuItem;
