import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledPageBar from 'components/organisms/Bars/PageBar/PageBar.styles';
import {H4} from 'components/atoms/Text/Typography/Typography';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import ActionTabs from 'components/molecules/Tabs/ActionTabs/ActionTabs';
import ActionToggleButtonGroup from 'components/molecules/Toggles/ActionToggleButtonGroup/ActionToggleButtonGroup';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import ActionSwitch from 'components/molecules/Switches/ActionSwitch/ActionSwitch';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import ViewBar from 'components/organisms/Bars/ViewBar/ViewBar';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';

const PageBar = React.forwardRef((props, ref) => {
  const {
    title,
    center,
    leftActions,
    leftSwitchActions,
    rightSwitchActions,
    rightActions,
    tabActions,
    toggleActions,
    leftFields,
    onLeftChange,
    rightFields,
    onRightChange,
    showViewBar,
    ViewBarComponent,
    isLoading,
    InlineFormSwitchProps,
    ActionSwitchProps,
    ActionMenuButtonProps,
    ActionIconButtonProps,
    ActionButtonProps,
    ActionTabsProps,
    ActionToggleButtonGroupProps,
    ...innerProps
  } = useComponentProps(props, 'PageBar', {
    children: ['toolbar', 'title', 'navigation', 'tabs', 'togglesButtons', 'actions', 'center']
  });

  const wrapper = useWrapper();
  const dialog = useDialog();
  const innerRef = useRef(null);

  const pageBar = useMemo(() => ({
    refs: {
      ref: innerRef
    }
  }), []);

  useImperativeHandle(ref, () => pageBar);

  const prepSwitches = (items) => {
    if (items?.length > 0) {
      return items.map((action, idx) => {
        return <ActionSwitch key={idx}
                             action={action}
                             {...ActionSwitchProps}/>
      });
    }
  }

  const prepButtons = (items) => {
    if (items?.length > 0) {
      return items.map((action, idx) => {
        if (action.label) {
          return <ActionButton key={idx}
                               action={action}
                               {...ActionButtonProps}/>
        } else {
          return <ActionIconButton key={idx}
                                   action={action}
                                   {...ActionIconButtonProps}/>
        }
      });
    }
  }

  const renderCenter = () => {
    let renderedCenter;
    if (center) {
      renderedCenter = center;
    } else if (tabActions?.length > 0) {
      renderedCenter = <ActionTabs className="PageBar-tabs"
                                   textColor="inherit"
                                   actions={tabActions}
                                   {...ActionTabsProps} />
    } else if (toggleActions?.length > 0) {
      renderedCenter = <ActionToggleButtonGroup className="PageBar-togglesButtons"
                                                actions={toggleActions}
                                                size="small"
                                                color="primary"
                                                {...ActionToggleButtonGroupProps} />
    }

    if (renderedCenter) {
      return <Box className="PageBar-center-wrapper">
        {renderedCenter}
      </Box>
    }
  }

  innerProps.elevation = innerProps.elevation ?? 0;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPageBar ref={innerRef} {...innerProps}
                        color={'white'}
                        enableColorOnDark
                        $wrapper={wrapper}
                        $dialog={Boolean(dialog)}>
    <Toolbar className="PageBar-toolbar">
      {leftActions?.length > 0 ? <Box className="PageBar-navigation PageBar-leftActions">{prepButtons(leftActions)}</Box> : null}
      {leftSwitchActions?.length > 0 ? <Box className="PageBar-switches PageBar-leftSwitches">
        {prepSwitches(leftSwitchActions)}
      </Box> : null}
      {leftFields ? <InlineForm className="PageBar-fields PageBar-leftFields" onChange={onLeftChange} fields={leftFields} /> : null}
      {title ? <Box className="PageBar-title">{utils.isReactElement(title) ? title :
        <H4 isLoading={isLoading} min={8} max={20}>{title}</H4>}</Box> : null}
      <Box className="PageBar-center">
        {renderCenter()}
      </Box>
      {rightFields ? <InlineForm className="PageBar-fields PageBar-rightFields" onChange={onRightChange} fields={rightFields} /> : null}
      {rightSwitchActions?.length > 0 ? <Box className="PageBar-switches PageBar-rightSwitches">
        {prepSwitches(rightSwitchActions)}
      </Box> : null}
      {rightActions?.length > 0 ? <Box className="PageBar-actions PageBar-rightActions">
        {prepButtons(rightActions)}
      </Box> : null}
    </Toolbar>
    {showViewBar ? <ViewBarComponent /> : null}
    {innerProps.children}
  </StyledPageBar>
});

PageBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  center: PropTypes.any,
  leftActions: PropTypes.array,
  leftSwitchActions: PropTypes.array,
  rightActions: PropTypes.array,
  rightSwitchActions: PropTypes.array,
  tabActions: PropTypes.array,
  toggleActions: PropTypes.array,
  leftFields: PropTypes.array,
  onLeftChange: PropTypes.func,
  rightFields: PropTypes.array,
  onRightChange: PropTypes.func,
  showViewBar: PropTypes.bool,
  isLoading: PropTypes.bool,
  ActionTabsProps: PropTypes.object,
  ActionToggleButtonGroup: PropTypes.object,
  ActionButtonProps: PropTypes.object,
  ActionIconButtonProps: PropTypes.object,
  ActionMenuButtonProps: PropTypes.object,
  ActionSwitchProps: PropTypes.object
};

PageBar.defaultProps = {
  showViewBar: true,
  ViewBarComponent: ViewBar
};

export default PageBar;
