import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useDealflowEntitiesGraphsRoutes from 'routes/dealflow/entities/graphs/graphs.routes';
import useDealflowEntityRoutes from 'routes/dealflow/entities/entity/entity.routes';
import DealflowEntitiesTableProvider from 'components/organisms/Providers/TableProvider/DealflowEntitiesTableProvider';
import DealflowEntitiesFiltersWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesFiltersWrapper/DealflowEntitiesFiltersWrapper';
import DealflowEntitiesBarWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesBarWrapper/DealflowEntitiesBarWrapper';
import DealflowEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesTableBarWrapper/DealflowEntitiesTableBarWrapper';
import DealflowEntityProfileWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityProfileWrapper/DealflowEntityProfileWrapper';
import DealflowEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesColumnsWrapper/DealflowEntitiesColumnsWrapper';
import utils from 'helpers/utils';
import DealflowEntitiesKanbanPage
  from 'components/pages/Dealflow/Entity/DealflowEntitiesKanbanPage/DealflowEntitiesKanbanPage';
import DealflowEntitiesPage from 'components/pages/Dealflow/Entity/DealflowEntitiesPage/DealflowEntitiesPage';

export default function useDealflowEntitiesRoutes () {
  const graphRoutes = useDealflowEntitiesGraphsRoutes();
  const dealflowEntityRoutes = useDealflowEntityRoutes();
  return useMemo(() => {
    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'entity',
            path: 'entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <DealflowEntitiesKanbanPage />,

        handle: {
          title: 'Dealflow - kanban',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.kanban.list'
          })
        }
      },
      {
        path: 'table',
        element: <DealflowEntitiesPage />,

        handle: {
          title: 'Dealflow - companies',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.list'
          })
        }
      },
      {
        path: 'graphs',
        element: <graphRoutes.WrapperComponent><Outlet /></graphRoutes.WrapperComponent>,
        children: graphRoutes.route,

        handle: {
          title: 'Dealflow - graphs',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.graph.route'
          })
        }
      },
      {
        path: ':entityId',
        element: <dealflowEntityRoutes.WrapperComponent><Outlet /></dealflowEntityRoutes.WrapperComponent>,
        children: dealflowEntityRoutes.route,

        handle: {
          title: 'Dealflow - company',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.route',
            meta: entityMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <DealflowEntitiesTableProvider>
          <DealflowEntitiesFiltersWrapper>
            <DealflowEntitiesColumnsWrapper>
              <DealflowEntitiesBarWrapper>
                <DealflowEntitiesTableBarWrapper>
                  <DealflowEntityProfileWrapper>
                    {children}
                  </DealflowEntityProfileWrapper>
                </DealflowEntitiesTableBarWrapper>
              </DealflowEntitiesBarWrapper>
            </DealflowEntitiesColumnsWrapper>
          </DealflowEntitiesFiltersWrapper>
        </DealflowEntitiesTableProvider>
      },
      route: route
    };
  }, [dealflowEntityRoutes, graphRoutes]);
};
