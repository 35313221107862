import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledSwitchTeamPage = styled(Page)`
  display: flex;
  flex-direction: column;
  
  .SwitchTeamPage {
    &-content {
      flex-grow: 1;
      overflow: hidden;
      padding-top: ${props => props.theme.layout('2sp')};
      padding-bottom: ${props => props.theme.layout('2sp')};
    }
    
    &-loading {
      display: none;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;    
    }
    
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: ${props => props.theme.property('palette.background.default')};
    }

    &-form {
      min-height: ${props => props.theme.layout(154)};
      max-height: calc(60vh - ${props => props.theme.layout(116)});
    }
    
    ${props => props.$switching ? css`
      &-content {
        display: none;
      }
      &-loading {
        display: flex;
      }
    ` : null};
  }
  ${ComponentStyles}
`;

StyledSwitchTeamPage.propTypes = {
  theme: PropTypes.object
};

StyledSwitchTeamPage.defaultProps = {
};

export default StyledSwitchTeamPage;
