import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class ClientTeamMemberService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'userId',
      refreshEnabled: false,
      invalidateParent: true,
      watch: ['user'],
      process: (data, meta) => {
        const processClientTeamMember = (data) => {
          return utils.camelcaseEx(data);
        };

        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            data = data.map(processClientTeamMember);
          } else {
            data = processClientTeamMember(data);
          }
        } else {
          return data;
        }

        return data;
      }
    }, callbacks);
  }
}
