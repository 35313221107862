import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';

const StyledActionChipList = styled(ChipList)`
  ${ComponentStyles}
`;

StyledActionChipList.propTypes = {
  theme: PropTypes.object
};

StyledActionChipList.defaultProps = {
}

export default StyledActionChipList;
