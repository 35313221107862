import {useAuthClientId} from 'services/auth/auth.utils';
import {useClientTeamAdd, useClientTeamGet, useClientTeamUpdate} from 'services/client/team/team.hooks';
import utils from 'helpers/utils';
import {useCallback, useMemo} from 'react';
import constants from 'helpers/constants';

export function useClientTeamMembers (teamId, addProxies = true) {
  const clientId = useAuthClientId();
  const team = useClientTeamGet({clientId, teamId: teamId}, {enabled: clientId >= 0});

  return useMemo(() => {
    if (team?.data?.members) {
      return team.data.members
        .filter((member) => [constants.user.types.regular, constants.user.types.proxy].includes(member.profile.type))
        .filter((member) => addProxies || member.profile.type !== constants.user.types.proxy)
        .map((member) => {
          member = utils.camelcase(member);
          member.profile = utils.camelcase(member.profile);
          return member;
        });
    } else {
      return null;
    }
  }, [team?.data?.members, addProxies]);
}

export function useClientTeamMemberOptions (teamId, addProxies = true, addTeam = true) {
  const teamMembers = useClientTeamMembers(teamId, addProxies);

  return useMemo(() => {
    if (teamMembers) {
      return (addTeam ? [constants.client.team.allMembers] : []).concat(teamMembers).map((tm) => ({
        ...tm,
        label: `${utils.personName(tm.profile.firstName, tm.profile.lastName)} (${tm.profile.username})`,
        display: tm.profile.username,
        value: +tm.userId,
        id: +tm.userId,
        allSelectedOption: +tm.userId === 0
      }));
    }
  }, [teamMembers, addTeam]);
}

export function useClientTeamCreate (mutationOptions = {}) {
  const teamAdd = useClientTeamAdd(mutationOptions);

  return useCallback((clientId, team) => {
    return teamAdd.mutation.mutateAsync({
      clientId,
      ...utils.underscore(team)
    });
  }, [teamAdd.mutation]);
}

export function useClientTeamPatch () {
  const clientId = useAuthClientId();
  const teamUpdate = useClientTeamUpdate({enabled: utils.isDefined(clientId)});

  return useCallback((team, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(team, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return teamUpdate.mutation.mutateAsync({
        clientId,
        teamId: team.teamId,
        team: {...changes}
      });
    } else {
      return Promise.resolve();
    }
  }, [clientId, teamUpdate.mutation]);
}
