import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledSourceSelectionDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(525)}, 86vh);
    max-height: clamp(0px, ${props => props.theme.layout(525)}, 86vh);
  }

  .DialogHeader {
    border-bottom: unset;  
    padding-bottom: 0;
  }
  
  .DialogContent {
    padding: 0;
  }
  
  ${ComponentStyles}
`;

StyledSourceSelectionDialog.propTypes = {
  theme: PropTypes.object
};

StyledSourceSelectionDialog.defaultProps = {}

export default StyledSourceSelectionDialog;
