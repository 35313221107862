import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import StyledTagsField from 'components/molecules/Fields/TagsField/TagsField.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import Chip from 'components/atoms/Chips/Chip/Chip';
import Add from '@mui/icons-material/Add';
import TextField from 'components/molecules/Fields/TextField/TextField';
import List from 'components/atoms/Lists/List/List';
import dom from 'helpers/dom';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import {arrayMove} from '@dnd-kit/sortable';
import {withMemo} from 'helpers/wrapper';

const TagsField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    placeholder,
    value,
    helperText,
    autoFocus,
    sortable,
    size,
    format,
    onBlur,
    onChange,
    ListProps,
    ChipProps,
    ListItemProps,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'TagsField', {
    children: ['input', 'addIconButton', 'label', 'helper']
  });

  const innerRef = useRef(null);
  const [focusActive, setFocusActive] = React.useState(false);
  const [internalState, setInternalState] = useState({
    inputValue: '',
    showInput: false
  });

  const tagsField = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState
    }
  }), [internalState]);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('.List'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const doChange = (value) => {
    onChange?.({
      target: {
        name: name,
        value: value ?? []
      }
    });
  };

  const doAddTag = () => {
    const tag = tagsField.state.inputValue;
    if (tag.length > 0) {
      let value = selection.concat({
        label: tag,
        value: null,
        position: selection.reduce((p, opt) => Math.max(p, opt.position), -1) + 1
      });
      doChange(value);
    }

    setInternalState(utils.updater({
      showInput: false,
      inputValue: ''
    }, true));
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!innerProps.readOnly);
  };

  const handleBlur = (e) => {
    setFocusActive(false);

    if (focusActive) {
      if (innerRef.current && !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
        onBlur?.({
          target: {
            name: name
          }
        });
      }
    }
  };

  const handleAddClick = (e) => {
    setInternalState(utils.updater({
      showInput: true,
      inputValue: ''
    }, true));
    e.preventDefault();
  };

  const handleDeleteClick = (key) => () => {
    doChange(selection.filter((opt) => opt.key !== key));
  };

  const handleInputBlur = () => {
    doAddTag();
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      doAddTag();
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    setInternalState(utils.updater({
      inputValue: e.target.value
    }, true));
  };

  const selection = useMemo(() => {
    const getOption = (v, idx) => {
      let opt;
      if (utils.isObject(v)) {
        opt = {...v, position: v.position ?? idx};
      } else if (v && utils.isString(v)) {
        opt = {label: v, value: null, position: idx};
      } else if (utils.isDefined(v)) {
        opt = {label: '???', value: v, position: idx};
      } else {
        opt = null;
      }

      opt.key = opt?.value ?? opt?.label;

      return opt;
    };

    return utils.toArray(value, true)
      .map((t, idx) => getOption(t, idx))
      .filter((_) => (_));
  }, [value]);

  const renderReadOnly = () => {
    return <Box className="TagsField-readOnly Input-readOnly">
      <List className="TagsField-list" gap={4} {...ListProps}>
        {selection
          .sort((a, b) => a.position - b.position)
          .map((v, idx) => {
            return <ListItem key={idx} density="densest" {...ListItemProps}>
              <Chip size={size}
                    label={v.label}
                    {...ChipProps} />
            </ListItem>
          })}
      </List>
    </Box>
  }

  const handleDragDrop = (id, containerId, position) => {
    let newValue = utils.clone(utils.toArray(selection, true), true);
    const option = newValue.find((opt) => opt.key === id);

    if (option && option.position !== position) {
      newValue = arrayMove(newValue.sort((a, b) => a.position - b.position), option.position, position);
      newValue.forEach((opt, idx) => {
        opt.position = idx;
      });

      doChange(newValue.sort((a, b) => a.position - b.position));
    }
  }

  const showInput = !(selection?.length > 0) || tagsField.state.showInput;

  const renderTags = () => {
    return <DndContext>
      <List track={true}
            catchFocus={false}
            sortable={!innerProps.disabled ? sortable : false}
            onDragDrop={handleDragDrop}
            className="TagsField-list"
            gap={4}
            {...ListProps}>
        {selection
          .sort((a, b) => a.position - b.position)
          .map((v, idx) => {
            return <ListItem key={idx}
                             data-key={v.key}
                             data-droppable={v.droppable !== false}
                             density="densest"
                             {...ListItemProps}>
              <Chip size={size}
                    label={v.label}
                    disabled={innerProps.disabled}
                    onPointerDown={(e) => {
                      // prevent dragging on chip delete icon
                      if (dom.isPartOfParent(e.target, null, 'MuiChip-deleteIcon')) {
                        e.preventDefault();
                      }
                    }}
                    onDelete={handleDeleteClick(v.key)}
                    {...ChipProps}/>
            </ListItem>
          })}
      </List>
    </DndContext>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTagsField ref={innerRef} {...innerProps}
                          onFocus={handleFocus}
                          onBlur={handleBlur}>
    <InputLabel {...utils.cleanObject({
                  htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                  shrink: innerProps.readOnly || innerProps.disabled || null
                })}
                className={utils.classNames('TagsField-label', InputLabelProps?.className)}
                {...InputLabelProps}>
      {label}
    </InputLabel>
    <InputContainer className="TagsField-container">
      {selection?.length > 0 ? (!innerProps.readOnly ? renderTags() : renderReadOnly()) : null}
      {!innerProps.readOnly ? <Box className="TagsField-add">
        {!showInput ? <ActionIconButton className="TagsField-addIconButton"
                                        variant="outlined"
                                        density="denser"
                                        IconProps={{
                                          size: 'tiny'
                                        }}
                                        action={{
                                          icon: Add,
                                          tooltip: 'Add tag',
                                          onClick: handleAddClick,
                                          IconButtonProps: {
                                            disabled: innerProps.disabled
                                          }
                                        }}
                                        onClick={handleAddClick} /> : null}
        {showInput ? <TextField className="TagsField-input"
                                placeholder={placeholder}
                                value={internalState.inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                onBlur={handleInputBlur}
                                autoFocus={focusActive}
                                label={label}
                                disabled={innerProps.disabled}
                                size={size === 'medium' ? 'smaller' : (size === 'small' ? 'smallest' : size)}
                                error={Boolean(innerProps.error)}
                                {...utils.cleanObject({
                                  format,
                                  hiddenLabel: true,
                                  hiddenHelperText: true,
                                  readOnly: innerProps.readOnly,
                                })} {...InputProps} /> : null}
      </Box> : null}
      <FormHelperText component="div"
                      {...FormHelperTextProps}
                      className={utils.classNames('TagsField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledTagsField>
}));

TagsField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  sortable: PropTypes.bool,
  format: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  ListProps: PropTypes.object,
  InputProps: PropTypes.object,
  ListItemProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  ChipProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object
};

TagsField.defaultProps = {
  sortable: true,
  size: 'medium'
};

export default TagsField;
