import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useTagStatus () {
  return useServiceStatus(path);
}

export function useTagList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useTagGet ({tagId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {tagId}, queryOptions);
}
