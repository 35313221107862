import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Typography from '@mui/material/Typography';
import utils from 'helpers/utils';

const StyledTypography = styled(Typography)`
  &.Typography-span {
    &.Skeleton {
      display: inline-block;
    }
  }
  &.Typography-p {
    margin-top: ${props => props.theme.spacing(2)};
    margin-bottom: ${props => props.theme.spacing(2)};
  }

  ${props => props.$lineHeight ? css`
    line-height: ${props.theme.lineHeight(props.$lineHeight)};
  ` : null}
  
  ${props => props.variant ? css`
    ${props.theme.font2Css(props.variant)};

    ${props => utils.isDefined(props.$lineHeight) ? css`
      line-height: ${props.theme.lineHeight(`${props.$lineHeight}f`)};
    ` : null}
  ` : null}
  
  ${props => props.$color ? css`
    color: ${props.theme.property(`palette.${props.$color}`)?.main ?? props.theme.property(`palette.${props.$color}`)};
  ` : null}
  
  &.Typography-disabled {
    color: ${props => props.theme.property('palette.action.disabled')};
  }

  &.Typography-copyText {
    cursor: pointer
  }

  &.Typography-nowrap {
    white-space: nowrap;
  }
  
  ${ComponentStyles}
`;

StyledTypography.propTypes = {
  theme: PropTypes.object
};

StyledTypography.defaultProps = {
};

export default StyledTypography;
