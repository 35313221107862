import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledCollectionEntitiesTableBarWrapper = styled(ToolbarWrapper)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesTableBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTableBarWrapper.defaultProps = {
};

export default StyledCollectionEntitiesTableBarWrapper;
