import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledEntityTeamRelevancyTableCellEdit = styled(DropdownTableCellEdit)`
  ${ComponentStyles}
`;

StyledEntityTeamRelevancyTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledEntityTeamRelevancyTableCellEdit.defaultProps = {}

export default StyledEntityTeamRelevancyTableCellEdit;
