import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function usePortalClientStatus () {
  return useServiceStatus(path);
}

export function usePortalClientGet ({clientId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId}, queryOptions);
}
