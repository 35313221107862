import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import CollectionsTableProvider from 'components/organisms/Providers/TableProvider/CollectionsTableProvider';
import useCollectionRoutes from 'routes/collections/collection/collection.routes';
import utils from 'helpers/utils';
import CollectionsBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionsBarWrapper/CollectionsBarWrapper';
import CollectionsTableBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionsTableBarWrapper/CollectionsTableBarWrapper';
import CollectionsPage from 'components/pages/Collection/CollectionsPage/CollectionsPage';

export default function useCollectionsRoutes () {
  const collectionRoutes = useCollectionRoutes();
  return useMemo(() => {
    const collectionMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: {
          name: 'collection',
          path: 'collection', // store path
          id: match?.params?.collectionId, // id from match
          params: match?.params
        }
      };
    };

    const route = [
      {
        path: '',
        element: <CollectionsBarWrapper>
          <CollectionsTableBarWrapper>
            <CollectionsPage/>
          </CollectionsTableBarWrapper>
        </CollectionsBarWrapper>,

        handle: {
          //scrollKey: 'full', // slow on infinite scroll
          title: 'Collections',
          auth: utils.createAuth({ attribute: 'collection.list' })
        },
      },
      {
        path: ':collectionId/entities',
        element: <collectionRoutes.WrapperComponent><Outlet /></collectionRoutes.WrapperComponent>,
        children: collectionRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'collection.entity.route',
            meta: collectionMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: CollectionsTableProvider,
      route: route
    };
  }, [collectionRoutes]);
};
