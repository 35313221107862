import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityProfileBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityProfileBarWrapper/CollectionEntityProfileBarWrapper.styles';
import CollectionEntityProfileBar
  from 'components/organisms/Bars/CollectionEntityProfileBar/CollectionEntityProfileBar';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const CollectionEntityProfileBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityProfileBarWrapper');

  const profileProvider = useProfile();

  innerProps.variant = innerProps.variant ?? (profileProvider.state.isEditing ? 'sticky' : 'inset');

  return <StyledCollectionEntityProfileBarWrapper ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledCollectionEntityProfileBarWrapper>
});

CollectionEntityProfileBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityProfileBarWrapper.defaultProps = {
  children: 'CollectionEntityProfileBarWrapper text',
  ToolbarComponent: CollectionEntityProfileBar
};

export default CollectionEntityProfileBarWrapper;
