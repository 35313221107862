import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntityProfileFoldout
  from 'components/organisms/Foldouts/DealflowEntityProfileFoldout/DealflowEntityProfileFoldout.styles';

const DealflowEntityProfileFoldout = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityProfileFoldout');

  return <StyledDealflowEntityProfileFoldout ref={ref} {...innerProps} />
});

DealflowEntityProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityProfileFoldout.defaultProps = {};

export default DealflowEntityProfileFoldout;
