import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Button from 'components/atoms/Buttons/Button/Button';
import Xframe from 'components/molecules/Frames/Xframe/Xframe';
import SimpleLoader from 'components/molecules/Loaders/SimpleLoader/SimpleLoader';
import Box from 'components/atoms/Layout/Box/Box';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import Link from 'components/atoms/Links/Link/Link';
import StyledBrowser from 'components/organisms/Browsers/Browser/Browser.styles';
import ImageList from 'components/atoms/Lists/ImageList/ImageList';
import ImageListItem from 'components/atoms/Lists/ImageListItem/ImageListItem';
import ImageListItemBar from 'components/atoms/Lists/ImageListItemBar/ImageListItemBar';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {OpenInBrowser} from '@mui/icons-material';
import Img from 'components/atoms/Images/Img/Img';

const Browser = React.forwardRef((props, ref) => {
  const {
    url,
    screenshots,
    showControls,
    ...innerProps
  } = useComponentProps(props, 'Browser', {
    styled: ['color'],
    children: ['controls', 'frame', 'loader', 'screenshots', 'error']
  });

  const innerRef = useRef(null);
  const frameRef = useRef(null);
  const [internalState, setInternalState] = useState({
    loaded: false
  });

  const browser = useMemo(() => ({
    refs: {
      ref: innerRef,
      frameRef
    },
    state: {
      ...internalState
    }
  }), [internalState]);

  useImperativeHandle(ref, () => browser);

  const handlePageSuccess = (originalUrl, actualUrl) => {
    setInternalState(utils.updater({loaded: true, loading: false, failed: false, url: actualUrl}, true));
  };
  const handlePageLoading = (originalUrl, actualUrl) => {
    setInternalState(utils.updater({loading: true, failed: false, url: actualUrl}, true));
  };

  const handlePageFailed = (originalUrl, actualUrl) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        loaded: url === originalUrl ? false : current.loaded,
        loading: false,
        failed: true,
        url: actualUrl
      })(current)
    });
  };

  const renderBackup = () => {
    if (browser.state.failed && !browser.state.loaded && !browser.state.loading) {
      if (screenshots?.length > 0) {
        return <ImageList className="Browser-screenshots" gap={0} cols={1}>
          {screenshots.map((screenshot, idx) => {
            return <ImageListItem key={idx}>
              <Img src={screenshot.image} alt={screenshot.url}/>
              <ImageListItemBar title={<Link href={utils.cleanExternalLink(screenshot.url)}
                                             color="white.main"
                                             target="_blank">
                                         {utils.displayExternalLink(screenshot.url)}
                                       </Link>}
                                position="below"
                                actionIcon={
                                  <IconButton color="white"
                                              density="densest"
                                              onClick={() => utils.externalLink(screenshot.url)}>
                                    <Icon icon={OpenInBrowser}/>
                                  </IconButton>
                                }
                                actionPosition="left"/>
            </ImageListItem>
          })}
        </ImageList>
      } else {
        return <Box className="Browser-center">
          {browser.state.url?.length > 0 ? <Box>
            <Span>Failed to load website </Span>
            <Link href={utils.cleanExternalLink(browser.state.url)} target="_blank">click here</Link>
            <Span> to open a new tab</Span>
            {browser.state.loaded ? <React.Fragment>
              <Span> or go </Span>
              <Link href="#" onClick={() => frameRef.current.back()}>back</Link>
            </React.Fragment> : null}
          </Box> : <Span>Please add a valid website first.</Span>}
        </Box>
      }
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBrowser ref={innerRef} {...innerProps}>
    {!browser.state.failed ? <Box className="Browser-center" style={{zIndex: browser.state.loading ? 2 : 0}}>
      <SimpleLoader className="Browser-loader" title={(!browser.state.loaded || !browser.state.loading) ? 'Loading website' : null} />
    </Box> : null}
    {showControls ? <Box className="Browser-controls">
      <H6>{browser.state.url}</H6>
      <Box className="buttons">
        <Button onClick={() => frameRef.current?.reload()}>Reload</Button>
        <Button onClick={() => frameRef.current?.forward()}>Forward</Button>
        <Button onClick={() => frameRef.current?.back()}>Back</Button>
      </Box>
    </Box> : null}
    <Box className="Browser-frame">
      <Xframe ref={frameRef}
              url={url}
              style={{display: (browser.state.failed || !browser.state.loaded) ? 'none' : 'block'}}
              onSuccess={handlePageSuccess}
              onLoading={handlePageLoading}
              onWaiting={handlePageLoading}
              onFailed={handlePageFailed} />
      {renderBackup()}
    </Box>
  </StyledBrowser>
});

Browser.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  url: PropTypes.string,
  screenshots: PropTypes.array,
  showControls: PropTypes.bool
};

Browser.defaultProps = {
  showControls: false,
  color: 'primary'
};

export default Browser;
