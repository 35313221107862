import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledXframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  
  ${ComponentStyles}
`;

StyledXframe.propTypes = {
};

StyledXframe.defaultProps = {
}

export default StyledXframe;
