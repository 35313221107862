import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityBrowserBar from 'components/organisms/Bars/EntityBrowserBar/EntityBrowserBar';

const StyledCollectionEntityBrowserBar = styled(EntityBrowserBar)`
  ${ComponentStyles}
`;

StyledCollectionEntityBrowserBar.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityBrowserBar.defaultProps = {
};

export default StyledCollectionEntityBrowserBar;
