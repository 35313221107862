import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import EntityTaskProfileProvider from 'components/organisms/Providers/ProfileProvider/EntityTaskProfileProvider';
import EntityTaskProfileFoldout from 'components/organisms/Foldouts/EntityTaskProfileFoldout/EntityTaskProfileFoldout';
import StyledEntityTaskProfileWrapper
  from 'components/templates/Wrappers/Tasks/EntityTaskProfileWrapper/EntityTaskProfileWrapper.styles';
import EntityTaskSectionPanel from 'components/organisms/SectionPanels/EntityTaskSectionPanel/EntityTaskSectionPanel';

const EntityTaskProfileWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntityTaskProfileWrapper');

  const profileProvider = useProfile();
  const entity = profileProvider.data?.data;

  const handleClose = () => {
    profileProvider.closeTask();
  }

  const renderContext = () => {
    return <EntityTaskProfileProvider entityId={entity?.entityId ?? -1} taskId={profileProvider.state?.showTask ?? -1}>
      <EntityTaskProfileFoldout onClose={handleClose}>
        <EntityTaskSectionPanel onClose={handleClose} />
      </EntityTaskProfileFoldout>
    </EntityTaskProfileProvider>
  }

  return <StyledEntityTaskProfileWrapper ref={ref} {...innerProps}
                                         variant="foldout"
                                         context={renderContext()}
                                         open={Boolean(profileProvider.state?.showTask)}
                                         size="larger"
                                         ContextDrawerProps={{
                                           onToggle: (e, open) => {
                                             if (open) {
                                               profileProvider.openTask(profileProvider.state?.showTask);
                                             } else {
                                               profileProvider.closeTask();
                                             }
                                           }
                                         }}>
    {innerProps.children}
  </StyledEntityTaskProfileWrapper>
});

EntityTaskProfileWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityTaskProfileWrapper.defaultProps = {
  children: 'EntityTaskProfileWrapper text',
};

export default EntityTaskProfileWrapper;
