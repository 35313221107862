import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import StyledEntitySectionPanel from 'components/organisms/SectionPanels/EntitySectionPanel/EntitySectionPanel.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import RelevancyContextCard
  from 'components/organisms/Cards/RelevancyContextCard/RelevancyContextCard';
import DealflowStatusContextCard from 'components/organisms/Cards/DealflowStatusContextCard/DealflowStatusContextCard';
import EntityTasksContextCard from 'components/organisms/Cards/EntityTasksContextCard/EntityTasksContextCard';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {
  useEntityCsi,
  useEntityDealLeader,
  useEntityPersonalRelevancy,
  useEntityExternalRelevancy
} from 'services/entity/entity.utils';
import EntityTimelineSectionPanelContent
  from 'components/organisms/SectionPanels/EntityTimelineSectionPanelContent/EntityTimelineSectionPanelContent';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import Add from '@mui/icons-material/Add';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntityProfile from 'components/organisms/Profiles/EntityProfile/EntityProfile';
import section from 'helpers/section';
import EntityAddSectionPanelContent
  from 'components/organisms/SectionPanels/EntityAddSectionPanelContent/EntityAddSectionPanelContent';
import SectionPanelFooter from 'components/molecules/SectionPanels/SectionPanelFooter/SectionPanelFooter';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import UserContextCard from 'components/organisms/Cards/UserContextCard/UserContextCard';
import EntityFilesContextCard from 'components/organisms/Cards/EntityFilesContextCard/EntityFilesContextCard';
import utils from 'helpers/utils';
import Badge from 'components/atoms/Badges/Badge/Badge';

const EntitySectionPanel = React.forwardRef((props, ref) => {
  const {
    sections,
    showProfile,
    newProfile,
    onClose,
    onNext,
    onCloseCard,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'EntitySectionPanel');

  const client = useAuthClient();
  const profileProvider = useProfile();
  const sectionDefinitions = profileProvider?.sectionDefinitions;
  const entity = profileProvider?.data?.data;

  const dialogControl = useDialogControl();
  const authorize = useAuthorize();

  const snackbar = useSnackbar();

  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);
  const openTaskEvent = useEffectEvent(profileProvider.openTask);
  const updateCsiEvent = useEffectEvent(profileProvider.updaters?.updateCsi);
  const entityAssignEvent = useEffectEvent(profileProvider.updaters?.assignItems);

  const onNextEvent = useEffectEvent(onNext);
  const onCloseEvent = useEffectEvent(onClose);
  const onCloseCardEvent = useEffectEvent(onCloseCard);
  const sectionsMemo = useMemo(() => {
    if (sectionDefinitions) {
      const entitySections = [];

      sectionDefinitions
        .forEach((sectionDef) => {
          const canEditSection = (entity) => {
            return !(sectionDef.readOnly ?? false) && authorize({
              ...sectionDef.auth?.update,
              meta: {
                ...sectionDef.auth?.update?.meta,
                entity
              }
            });
          }

          const canEditCard = (card, entity) => {
            return canEditSection(entity) && !(card.readOnly ?? false) && authorize({
              ...card.auth?.update,
              meta: {
                ...card.auth?.update?.meta,
                entity
              }
            });
          }

          if (newProfile) {
            if (sectionDef.name === 'profile') {
              entitySections.push({
                ...sectionDef,
                Content: () => {
                  return <EntityAddSectionPanelContent key={newProfile}
                                                       onNext={onNextEvent}
                                                       onClose={onCloseEvent}/>
                }
              });
            }
          } else {
            if (sectionDef.name === 'profile' && showProfile) {
              entitySections.push({
                ...sectionDef,
                Content: () => {
                  const handleCanUpdate = (entity) => {
                    return canEditSection(entity) && authorize(sectionDef.auth?.update ? {
                      ...sectionDef.auth?.update,
                      meta: {
                        ...sectionDef.auth?.update?.meta,
                        entity
                      }
                    } : {});
                  }

                  return <EntityProfile gap={8} columns={1}
                                        onCloseCard={onCloseCardEvent}
                                        onCanUpdate={handleCanUpdate}/>
                }
              });
            } else if (sectionDef.name === 'options') {
              entitySections.push({
                ...sectionDef,
                cards: sectionDef.cards?.reduce((a, card) => {
                  if (card.name === 'dealLeader') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const dealLeaderOriginal = useEntityDealLeader(data);
                        const [dealLeader, updateCsiOptimistic] = useOptimistic(dealLeaderOriginal, updateCsiEvent);

                        const handleChange = (value) => {
                          return updateCsiOptimistic?.(value, data, {leaderId: value.userId})
                            .catch((err) => {
                              snackbar.show('Saving deal leader failed', null,
                                {color: 'error', autoHideDuration: constants.delay.error});

                              throw err;
                            })
                        };

                        return <UserContextCard user={dealLeader}
                                                title="Deal leader"
                                                proxies={Boolean(client?.props?.proxyCanBeDealLeader)}
                                                onChange={handleChange}
                                                canUpdate={canEditCard(card, data)}
                                                isLoading={isLoading} />
                      }
                    });
                  } else if (card.name === 'tasks') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const switched = Boolean(profileProvider?.state?.settings?.tasksSwitched);

                        const handleSwitch = (switched) => {
                          setSettingsEvent?.({tasksSwitched: switched});
                        }

                        const handleClick = (e, task) => {
                          if (!e.defaultPrevented) {
                            openTaskEvent?.(task.commentId);
                          }
                        }

                        return <EntityTasksContextCard entity={data}
                                                       switched={switched}
                                                       onSwitch={handleSwitch}
                                                       onClick={handleClick}
                                                       canUpdate={canEditCard(card, data)}
                                                       isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'files') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        return <EntityFilesContextCard entity={data}
                                                       canUpdate={canEditCard(card, data)}
                                                       isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'personalRelevancy') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const relevancyValue = useEntityPersonalRelevancy(data);
                        const [relevancy, toggleRelevancyOptimistic] = useOptimistic(relevancyValue || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleRelevancyOptimistic?.(value, data, {relevancy: value})
                            .catch((err) => {
                              snackbar.show('Saving relevance failed', null,
                                  {color: 'error', autoHideDuration: constants.delay.error});

                              throw err;
                            })
                        };

                        return <RelevancyContextCard relevancy={relevancy}
                                                     onChange={handleChange}
                                                     canUpdate={canEditCard(card, data)}
                                                     isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'externalRelevancy') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const relevancyValue = useEntityExternalRelevancy(data);
                        const [relevancy, toggleRelevancyOptimistic] = useOptimistic(relevancyValue || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleRelevancyOptimistic?.(value, data, {relevancy: value})
                            .catch((err) => {
                              snackbar.show('Saving relevance failed', null,
                                  {color: 'error', autoHideDuration: constants.delay.error});

                              throw err;
                            })
                        };

                        return <RelevancyContextCard relevancy={relevancy}
                                                     title="External relevance"
                                                     onChange={handleChange}
                                                     canUpdate={canEditCard(card, data)}
                                                     isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'dealflowStatus') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const csi = useEntityCsi(data);
                        const [statusId, toggleStatusOptimistic] = useOptimistic(csi?.status || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleStatusOptimistic?.(value, data, {status: value})
                            .catch((err) => {
                              snackbar.show('Saving status failed', null,
                                  {color: 'error', autoHideDuration: constants.delay.error});

                              throw err;
                            })
                        };

                        return <DealflowStatusContextCard statusId={statusId}
                                                          onChange={handleChange}
                                                          canUpdate={canEditCard(card, data)}
                                                          isLoading={isLoading}/>
                      }
                    });
                  }

                  return a;
                }, []),
                Footer: ({data, isLoading}) => {
                  const collections = data?.collections;
                  const buttonActions = useMemo(() => ([
                    {
                      auth: utils.createAuth({attribute: 'collection.update'}),
                      onClick: () => {
                        const handleSubmit = (collections) => {
                            if (collections.length > 0) {
                              const message = `Adding the company to the collection${collections.length > 1 ? 's' : ''}`;
                              return utils.observePromise(Promise.all(collections.map((collection) => {
                                return entityAssignEvent?.({
                                  collectionId: collection.collectionId,
                                  entityId: data.entityId
                                });
                              })), () => {
                                return snackbar.show(`${message}...`, null, {
                                  color: 'info',
                                  autoHideDuration: null
                                });
                              }, () => {
                                return snackbar.show(`The company was added to the collection${collections.length > 1 ? 's' : ''}`, null,
                                  {color: 'success', autoHideDuration: constants.delay.success});
                              }, () => {
                                return snackbar.show(`${message} failed`, null,
                                  {color: 'error', autoHideDuration: constants.delay.error});
                              }, (id) => {
                                snackbar.hide(id);
                              });
                            }
                        };

                        dialogControl.show(<CollectionSelectionDialog title="Add to collection"
                                                                      subtitle="For 1 company"
                                                                      collections={collections}
                                                                      hideCollections={true}
                                                                      onSubmit={handleSubmit}/>, true);
                      },
                      label: 'Add to collection',
                      icon: Add,
                      ActionButtonProps: {
                        color: 'primary',
                        radius: 'round',
                        fullWidth: true
                      }
                    }
                  ]), [data?.entityId, collections]);

                  return <SectionPanelFooter className="EntitySectionPanel-options-footer"
                                             orientation="vertical"
                                             buttons={buttonActions.map?.((action, idx) => {
                                               return <ActionButton key={idx} action={action} isLoading={isLoading}/>
                                             })} />
                }
              });
            } else if (sectionDef.name === 'timeline') {
              entitySections.push({
                ...sectionDef,
                TabProps: ({data, isLoading}) => {
                  return (!isLoading && data?.unreadComments > 0) ? {
                    badge: <Badge color="warning"/>,
                    BadgeProps: {
                      variant: 'inlineDot'
                    }
                  } : null;
                },
                Content: ({data, isLoading}) => {
                  const mentioned = Boolean(profileProvider?.state?.settings?.timelineMentioned);

                  return <EntityTimelineSectionPanelContent entity={data}
                                                            mentioned={mentioned}
                                                            canUpdate={canEditSection(data)}
                                                            isLoading={isLoading}/>
                }
              });
            }
          }
        });

      return section.mergeSections(entitySections, sections)
        .sort((a, b) => a.position - b.position);
    } else {
      return [];
    }
  }, [authorize, dialogControl, snackbar, sectionDefinitions, client, sections, showProfile, newProfile,
    profileProvider?.state?.settings?.tasksSwitched, profileProvider?.state?.settings?.timelineMentioned,
    setSettingsEvent, onCloseEvent, onNextEvent, onCloseCardEvent,
    updateCsiEvent, entityAssignEvent, openTaskEvent]);

  const handleChange = (section, reason) => {
    onChange?.(section, reason);
    if (!['sectionInit', 'sectionNotFound'].includes(reason)) {
      profileProvider.setSettings({activeSection: section?.name});
    }
  }

  const active = sectionsMemo
    .find((s) => s.name === profileProvider?.state?.settings?.activeSection) ?? sectionsMemo[0];

  return <StyledEntitySectionPanel ref={ref} {...innerProps}
                                   data={entity}
                                   dataKey={profileProvider.dataKey}
                                   active={active}
                                   sections={sectionsMemo}
                                   onChange={handleChange}
                                   isLoading={profileProvider.isLoading()}/>
});

EntitySectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onCloseCard: PropTypes.func,
  onCanUpdate: PropTypes.func,
  showProfile: PropTypes.bool,
  newProfile: PropTypes.bool,
  sections: PropTypes.array
};

EntitySectionPanel.defaultProps = {};

export default EntitySectionPanel;
