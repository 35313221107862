import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import StyledSettingsHeaderWrapper
  from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';
import SettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader';
import ArrowBack from '@mui/icons-material/ArrowBack';

const SettingsHeaderWrapper = React.forwardRef((props, ref) => {
  const {
    title,
    info,
    showBack,
    backAction,
    buttons,
    ...innerProps
  } = useComponentProps(props, 'SettingsHeaderWrapper');

  const wrapper = useWrapper();

  const leftActions = useMemo(() => {
    return showBack ? [
      {
        navigation: {
          to: '../'
        },
        tooltip: 'Back',
        icon: ArrowBack,
        ActionIconButtonProps: {
          color: 'default',
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          IconProps: {
            size: 'smaller'
          }
        },
        ...backAction
      },
    ] : null;
  }, [showBack, backAction]);

  const renderHeader = () => {
    return <SettingsHeader title={title} info={info} buttons={buttons} />
  }

  return <StyledSettingsHeaderWrapper ref={ref} {...innerProps}
                                      $wrapper={wrapper}
                                      variant="inset"
                                      ToolbarProps={{
                                        center: renderHeader(),
                                        leftActions: leftActions,
                                        variant: 'inset'
                                      }}>
    {innerProps.children}
  </StyledSettingsHeaderWrapper>
});

SettingsHeaderWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  info: PropTypes.any,
  showBack: PropTypes.bool,
  backAction: PropTypes.object,
  buttons: PropTypes.any
};

SettingsHeaderWrapper.defaultProps = {
  ToolbarComponent: HeaderBar
};

export default SettingsHeaderWrapper;
