import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledMenuItem from 'components/atoms/Menus/MenuItem/MenuItem.styles';
import {withMemo} from 'helpers/wrapper';
import dom from 'helpers/dom';

const MenuItem = withMemo(React.forwardRef((props, ref) => {
  const {
    size,
    ...innerProps
  } = useComponentProps(props, 'MenuItem');

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (innerProps.selected && innerRef.current) {
      dom.scrollIntoView(innerRef.current, {behavior: 'instant'});
    }
  }, [innerProps.selected]);

  return <StyledMenuItem ref={innerRef} {...innerProps}>{innerProps.children}</StyledMenuItem>
}));

MenuItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

MenuItem.defaultProps = {
  children: 'MenuItem text'
};

export default MenuItem;
