import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Span} from 'components/atoms/Text/Typography/Typography';

const StyledDealflowStatusText = styled(Span)` 
  ${ComponentStyles}
`;

StyledDealflowStatusText.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusText.defaultProps = {}

export default StyledDealflowStatusText;
