import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TaskAddDialog from 'components/organisms/Dialogs/TaskAddDialog/TaskAddDialog';

const StyledEntitiesTaskAddDialog = styled(TaskAddDialog)`
  ${ComponentStyles}
`;

StyledEntitiesTaskAddDialog.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesTaskAddDialog.defaultProps = {}

export default StyledEntitiesTaskAddDialog;
