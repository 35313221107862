import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDivider from 'components/atoms/Dividers/Divider/Divider.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Divider = withMemo(React.forwardRef((props, ref) => {
  const {
    fullWidth,
    ...innerProps
  } = useComponentProps(props, 'Divider', {
    static: ['fullWidth'],
    variable: ['orientation']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDivider ref={ref} {...innerProps} />
}));

Divider.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Divider.defaultProps = {
};

export default Divider;
