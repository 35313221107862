import React from 'react';
import StyledApplicantPage from 'components/pages/Portal/ApplicantPage/ApplicantPage.styles';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import ApplicantForm from 'components/organisms/Forms/ApplicantForm/ApplicantForm';

const ApplicantPage = (props) => {
  const innerProps = useComponentProps(props, 'ApplicantPage');

  return <StyledApplicantPage {...innerProps}>
    <ApplicantForm className="ApplicantPage-form"/>
  </StyledApplicantPage>
};

ApplicantPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ApplicantPage.defaultProps = {
};

export default ApplicantPage;
