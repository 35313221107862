import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTaskAddWizard from 'components/organisms/Wizards/TaskAddWizard/TaskAddWizard.styles';
import ChooseTaskWizardContent
  from 'components/organisms/WizardContent/ChooseTaskWizardContent/ChooseTaskWizardContent';
import constants from 'helpers/constants';
import ResponsibleWizardContent
  from 'components/organisms/WizardContent/ResponsibleWizardContent/ResponsibleWizardContent';
import utils from 'helpers/utils';
import {useClientCallbacks} from 'services/client/client.utils';

const TaskAddWizard = React.forwardRef((props, ref) => {
  const {
    taskTypes,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'TaskAddWizard');

  const clientCallbacks = useClientCallbacks();

  const steps = useMemo(() => ([
    {
      name: 'type',
      label: 'Choose task',
      shortLabel: 'Task',
      Content: (props) => {
        return <ChooseTaskWizardContent ref={props.ref}
                                        className={props.className}
                                        taskTypes={taskTypes}
                                        wizard={props.wizard}
                                        step={props.step}
                                        onComplete={props.onComplete}
                                        onError={props.onError}
                                        onDirty={props.onDirty}/>
      }
    },
    {
      name: 'comment',
      label: 'Task comment',
      shortLabel: 'Comment',
      fields: [
        {
          name: 'type',
          entity: 'task',
          type: constants.formFieldTypes.category,
          conversion: constants.formFieldTypes.none,
          FormFieldProps: {
            hidden: true
          },
        },
        {
          name: 'title',
          label: 'Title',
          entity: 'task',
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.text,
          relation: `type(${constants.task.types.other})`,
          required: true,
          FormFieldProps: {
            variant: 'staticLabel'
          },
        },
        {
          name: 'comment',
          label: 'Comment',
          entity: 'task',
          type: constants.formFieldTypes.markdown,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          FormFieldProps: {
            variant: 'staticLabel',
            minRows: 4,
            maxRows: 8,
            mentions: [{
              trigger: '@',
              data: (search, callback) => clientCallbacks.members({search, callback})
            }]
          },
        }
      ]
    },
    {
      name: 'responsible',
      shortLabel: 'Assign',
      Content: (props) => {
        return <ResponsibleWizardContent ref={props.ref}
                                         className={props.className}
                                         wizard={props.wizard}
                                         step={props.step}
                                         onComplete={props.onComplete}
                                         onError={props.onError}
                                         onDirty={props.onDirty}/>
      }
    },
    {
      name: 'collaborationType',
      label: 'Type of task',
      shortLabel: 'Type',
      enableStep: (data) => !utils.isDefined(data?.task?.type.collaborationType) && data?.task?.responsible?.length > 1,
      fields: [
        {
          name: 'collaborationType',
          label: 'Type of task',
          entity: 'task',
          type: constants.formFieldTypes.category,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          options: 'taskCollaborationTypes',
          required: true,
          FormFieldProps: {
            CategoryCardProps: {
              iconDensity: 'dense'
            },
          },
        }
      ]
    },
    {
      name: 'dueDate',
      shortLabel: 'Specify deadline',
      fields: [
        {
          name: 'specifyDueDate',
          target: 'state',
          entity: 'task',
          label: 'Do you want to specify a deadline?',
          inlineLabel: 'This',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.boolean,
          conversion: constants.formFieldConversionTypes.value,
          options: [{label: 'Yes', value: true}, {label: 'No', value: false}],
          required: true
        },
        {
          name: 'dueDate',
          label: 'Deadline',
          entity: 'task',
          type: constants.formFieldTypes.date,
          validation: [constants.formFieldValidationTypes.date, constants.formFieldValidationTypes.notInThePast],
          conversion: constants.formFieldConversionTypes.date,
          relation: 'specifyDueDate(true)',
          required: true
        }
      ]
    },
  ]), [taskTypes, clientCallbacks]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    return onSubmit?.(data.task, actions, onSuccess, onError);
  }

  return <StyledTaskAddWizard ref={ref} {...innerProps}
                              variant="simple"
                              steps={steps}
                              dataKey="task"
                              onSubmit={handleSubmit} />
});

TaskAddWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  taskTypes: PropTypes.array,
  onSubmit: PropTypes.func
};

TaskAddWizard.defaultProps = {};

export default TaskAddWizard;
