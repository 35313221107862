import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesRecentChangesGraphCard = styled(GraphCard)`
  .GraphCard-content {
    padding-top: ${props => props.theme.spacing(1)};
    padding-bottom: ${props => props.theme.spacing(1.5)};
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesRecentChangesGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesRecentChangesGraphCard.defaultProps = {}

export default StyledCollectionEntitiesRecentChangesGraphCard;
