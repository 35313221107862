import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Profile from 'components/organisms/Profiles/Profile/Profile';

const StyledCollectionProfile = styled(Profile)`
  .ProfileCard {
    &-title {
      ${props => props.theme.font2Css('subtitle1')};
    }
  }
  ${ComponentStyles}
`;

StyledCollectionProfile.propTypes = {
  theme: PropTypes.object
};

StyledCollectionProfile.defaultProps = {
};

export default StyledCollectionProfile;
