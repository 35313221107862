import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTeamsSettingsCardHeader = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
  padding: ${props => props.theme.spacing(2)};
  gap: ${props => props.theme.spacing(1)};
  
  .TeamsSettingsCardHeader {
    &-info {
      width: 1px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      
      &-title {
        display: flex;
        align-items: center;
        
        .FieldInline {
          flex-grow: 1;
          overflow: hidden;
          .Typography {
            ${props => props.theme.font2Css('h6')}
          }
        }
      }
      
      &-subtitle {
        display: flex;
        align-items: center;
        gap: ${props => props.theme.spacing(1)};
      }
    }
    
    &-buttons {
      display: flex;
      align-self: flex-start;
      align-items: flex-start;
      gap: ${props => props.theme.spacing(1)};
      
      .Button {
        white-space: nowrap;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTeamsSettingsCardHeader.propTypes = {
  theme: PropTypes.object
};

StyledTeamsSettingsCardHeader.defaultProps = {}

export default StyledTeamsSettingsCardHeader;
