import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesTableFilters
  from 'components/organisms/TableFilters/CollectionEntitiesTableFilters/CollectionEntitiesTableFilters.styles';

const CollectionEntitiesTableFilters = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTableFilters');

  return <StyledCollectionEntitiesTableFilters ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledCollectionEntitiesTableFilters>
});

CollectionEntitiesTableFilters.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTableFilters.defaultProps = {};

export default CollectionEntitiesTableFilters;
