import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledForm = styled(Box)`
  width: 100%;
  
  .Form {
    &-fields {
      display: flex;
      flex-wrap: wrap;
      column-gap: ${props => props.theme.layout('1sp')};
      row-gap: ${props => props.theme.layout('2sp')};
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: ${props => props.theme.layout('2sp')};
      column-gap: ${props => props.theme.layout('1sp')};

      .Form-loader, .Form-error, .Form-success {
        flex-grow: 1;
      }

      .Form-error {
        color: ${props => props.theme.property('palette.error.main')};
      }

      .Form-success {
        color: ${props => props.theme.property('palette.success.main')};
      }

      .Form-success, .Form-error {
        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: ${props => props.theme.fontSize(15)};
      }
      
      .Button {
        white-space: nowrap;
        min-width: fit-content;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledForm.propTypes = {
  theme: PropTypes.object
};

StyledForm.defaultProps = {}

export default StyledForm;
