import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import useErrorBoundary from "use-error-boundary";
import {useSnackbar} from '../SnackbarProvider/SnackbarProvider';
import ErrorPage from 'components/pages/Error/ErrorPage/ErrorPage';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import CenterWrapper from 'components/templates/Wrappers/Basic/CenterWrapper/CenterWrapper';

const ErrorProvider = (props) => {
  const [errorPage, setErrorPage] = useState(false);
  const snackbar = useSnackbar();
  const {ErrorBoundary, didCatch, error, reset} = useErrorBoundary((error, errorInfo) => {
    // For logging/reporting
    logger.error('Error', error, errorInfo);
  })

  const resetError = useCallback(() => {
    setErrorPage(false);
    reset()
  }, [reset]);

  useEffect(() => {
    if (didCatch) {
      setErrorPage((current) => !current ? true : current);
      if (props.showSnackbar) {
        snackbar.show(`Something went very wrong: ${error.message}`, 'Close',
          {color: 'error', autoHideDuration: constants.delay.error}, resetError);
      }
    }
  }, [error, didCatch, props.showSnackbar, snackbar, resetError]);

  if (didCatch && errorPage) {
    return <CenterWrapper centerWidth={800}><ErrorPage error={error} /></CenterWrapper>
  } else {
    return <ErrorBoundary>
      {props.children}
    </ErrorBoundary>
  }
};

ErrorProvider.propTypes = {
  showSnackbar: PropTypes.bool
}

ErrorProvider.defaultProps = {
  showSnackbar: true
}

export default ErrorProvider;
