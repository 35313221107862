import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import {MentionsInput} from 'react-mentions';

const StyledMentionsInput = styled(MentionsInput)`
  outline: none;
  font: inherit;
  
  * {
    outline: none;
    font: inherit;
  }
  
  > div {
    position: relative;
    > div:first-child {
      z-index: 1;
      pointer-events: none;
    }
  }
  
  textarea, input {
    border: unset;
  }

  .text-test {
    visibility: hidden;
    position: absolute;
    font: inherit;
  }
  
  ${ComponentStyles}
`;

StyledMentionsInput.propTypes = {
  theme: PropTypes.object
};

StyledMentionsInput.defaultProps = {
}

export default StyledMentionsInput;
