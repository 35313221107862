import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ImageListItem from '@mui/material/ImageListItem';

const StyledImageListItem = styled(ImageListItem)` 
  ${ComponentStyles}
`;

StyledImageListItem.propTypes = {
  theme: PropTypes.object
};

StyledImageListItem.defaultProps = {}

export default StyledImageListItem;
