import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Drawer from 'components/atoms/Drawers/Drawer/Drawer';
import utils from 'helpers/utils';

const StyledMenuDrawer = styled(Drawer)`
  &.MenuDrawer {
    &-anchorTop, &-anchorBottom {
      width: auto;
      .MuiPaper-root {
          width: auto;
      }
    }
  }

  &.MenuDrawer > .MuiPaper-root {
    border-width: 0;
    width: ${props => utils.isDefined(props.$width) ? props.$width : null};
  }
  
  &.MenuDrawer-persistent {
    &:not(.MenuDrawer-open) > .MuiPaper-root {
      width: 0;
    }
  }

  .MenuDrawer {
    &-content {
      height: ${props => props.theme.layout('100%')};
      padding: ${props => props.theme.spacing(2)} 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: ${props => props.theme.layout('1sp')};
      
      position: relative;
    }
    
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};

      .IconButton {
        width: ${props => props.theme.layout('5sp')};
        height: ${props => props.theme.layout('5sp')};
        font-size: ${props => props.theme.layout(20)};
      }
      
      &-middle {
        flex-grow: 1;
      }
      
      //&-middle {
      //  position: absolute;
      //  width: 100%;
      //  height: 100%;
      //  justify-content: center;
      //}
    }
    
    &-logo {
      height: ${props => props.theme.layout('5sp')}; 
      margin-bottom: ${props => props.theme.layout('2sp')};
    }
    
    &-avatar {
      align-self: center;
      width: ${props => props.theme.layout('5sp')};
      height: ${props => props.theme.layout('5sp')};
      font-size: ${props => props.theme.fontSize(15)};

      margin-bottom: ${props => props.theme.layout('2sp')};
    }
  }
  
  &.MenuDrawer-temporary {
    &:not(.MenuDrawer-open) {
      visibility: hidden;
    }
  }
  
  ${ComponentStyles}
`;

StyledMenuDrawer.propTypes = {
};

StyledMenuDrawer.defaultProps = {
}

export default StyledMenuDrawer;
