import {useServiceApiMutation} from 'stores/hooks/query';
import utils from 'helpers/utils';

const path = utils.servicePath(import.meta.url);

export function useCollectionTagGroupAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionTagGroupUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useCollectionTagGroupMove (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'collections/:collectionId/moveTagGroup', ...mutationOptions
  });
}

export function useCollectionTagGroupDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

