import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileFoldout from 'components/organisms/Foldouts/ProfileFoldout/ProfileFoldout';

const StyledCollectionProfileFoldout = styled(ProfileFoldout)`
  ${ComponentStyles}
`;

StyledCollectionProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledCollectionProfileFoldout.defaultProps = {}

export default StyledCollectionProfileFoldout;
