import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';
import {rgba} from 'polished';

const StyledDashboardCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .DashboardCard {
    &-header {
      padding-top: 0;
      padding-bottom: ${props => props.theme.spacing(1)};
      
      .MuiCardHeader-content {
        margin-right: ${props => props.theme.spacing(2)};
        width: 1px;
        flex-grow: 1;
        * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .MuiCardHeader-action {
        margin: 0;
      }
    }
    
    &-actions {
      padding: 0 ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(2)};
    }

    &-empty {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: -${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(1)} 0;
      }
    }
  }

  &.DashboardCard-density-densest {
    padding: 0;
  }

  ${ComponentStyles}
`;

StyledDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledDashboardCard.defaultProps = {}

export default StyledDashboardCard;
