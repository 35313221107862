import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import Box from 'components/atoms/Layout/Box/Box';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import ClientProfile from 'components/organisms/Profiles/ClientProfile/ClientProfile';
import StyledClientSettingPage from 'components/pages/Setting/ClientSettingPage/ClientSettingPage.styles';
import {AccountCircle} from '@mui/icons-material';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {H4} from 'components/atoms/Text/Typography/Typography';

const ClientSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'ClientSettingPage');

  const profileProvider = useProfile();
  const client = profileProvider?.data?.data;
  const isLoading = !client;

  const authorize = useAuthorize();

  const handleShowCard = (card) => {
    return ['profile', 'budget', 'features', 'collaborators'].includes(card.name);
  };

  const handleCanUpdate = (client, field = null) => {
    return authorize({attribute: 'settings.clients.update'}) &&
      authorize({attribute: 'client.update', meta: {client}}) &&
      (!field || authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, client}}));
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const renderTitle = () => {
    return <Box className="ClientSettingPage-title">
      <Icon icon={AccountCircle} isLoading={isLoading} size="large" color="warning"/>
      <H4 isLoading={isLoading} showTooltip={true} min={8} max={20}>{client?.name}</H4>
    </Box>
  }

  return <StyledClientSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                  title={renderTitle()}
                                  showBack={true}>
    <DndContext>
      <Box className="ClientSettingPage-content">
        <ClientProfile onShowCard={handleShowCard}
                       onDirty={handleDirty}
                       onCanUpdate={handleCanUpdate}/>
      </Box>
    </DndContext>
  </StyledClientSettingPage>
};

ClientSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ClientSettingPage.defaultProps = {};

export default ClientSettingPage;
