import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledDatabaseEntityProfileBarWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityProfileBarWrapper/DatabaseEntityProfileBarWrapper.styles';
import DatabaseEntityProfileBar from 'components/organisms/Bars/DatabaseEntityProfileBar/DatabaseEntityProfileBar';

const DatabaseEntityProfileBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityProfileBarWrapper');

  const profileProvider = useProfile();

  innerProps.variant = innerProps.variant ?? (profileProvider.state.isEditing ? 'sticky' : 'inset');

  return <StyledDatabaseEntityProfileBarWrapper ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDatabaseEntityProfileBarWrapper>
});

DatabaseEntityProfileBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityProfileBarWrapper.defaultProps = {
  children: 'DatabaseEntityProfileBarWrapper text',
  ToolbarComponent: DatabaseEntityProfileBar
};

export default DatabaseEntityProfileBarWrapper;
