import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import DateFieldBase from 'components/atoms/Inputs/DateField/DateField';
import TextField from 'components/molecules/Fields/TextField/TextField';
import StyledDateField from 'components/molecules/Fields/DateField/DateField.styles';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import {withMemo} from 'helpers/wrapper';

const DateField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    variant,
    placeholder,
    error,
    success,
    helperText,
    value,
    size,
    readOnly,
    timeAgo,
    autoFocus,
    hiddenLabel,
    hiddenHelperText,
    hiddenPlaceholder,
    fullWidth,
    clearable,
    onBlur,
    onChange,
    inputProps,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'DateField');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const [internalValue, setInternalValue] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [focusActive, setFocusActive] = React.useState(false);

  const handleChange = (value) => {
    setInternalValue(value);
    onChange({
      target: {
        name,
        value: value ? value.toDate() : null
      }
    });
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!readOnly);
  };

  const handleBlur = (e) => {
    if (!isOpen) {
      setFocusActive(false);

      if (focusActive) {
        if (innerRef.current && !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
          onBlur?.({
            target: {
              name: name
            }
          });
        }
      }
    }
  };

  const handleClear = () => {
    handleChange(null);
  }

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const slots = utils.mergeObjects({
    field: DateFieldBase
  }, innerProps.slots);

  const slotProps = utils.mergeObjects({
    popper: {
      placement: 'bottom-end'
    },
    desktopPaper: {
      elevation: 2
    },
    field: {
      slots: {
        textField: TextField,
      },
      onClear: handleClear,
      format: constants.dates.format
    },
    textField: utils.cleanObject({
      autoFocus,
      variant,
      hiddenLabel,
      hiddenHelperText,
      hiddenPlaceholder,
      fullWidth,
      label,
      name,
      readOnly,
      placeholder,
      error,
      success,
      size,
      helperText,
      onFocus: handleFocus,
      onBlur: handleBlur,
      focused: focusActive,
      inputProps,
      InputProps,
      InputLabelProps,
      FormHelperTextProps
    })
  }, innerProps.slotProps);

  useLayoutEffect(() => {
    setInternalValue(value ? utils.dayjs(value) : null);
  }, [value]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (!readOnly) {
    return <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDateField ref={innerRef} {...innerProps}
                       slots={slots}
                       slotProps={slotProps}
                       value={internalValue}
                       onOpen={handleOpen}
                       onClose={handleClose}
                       onChange={handleChange}/>
    </LocalizationProvider>
  } else {
    return <StyledDateField as={FormControl}
                            ref={innerRef}
                            className={innerProps.className}
                            {...utils.cleanObject({
                              hiddenLabel,
                              hiddenHelperText,
                              hiddenPlaceholder,
                              fullWidth,
                              readOnly,
                              error,
                              success,
                              variant
                            })}>
      <InputLabel size={size} shrink={true} {...InputLabelProps}
                  className={utils.classNames('DateField-label', InputLabelProps?.className)}>
        {label}
      </InputLabel>
      <InputContainer className="DateField-container">
        <Box className="DateField-readOnly Input-readOnly">
          {internalValue ? <Span>{timeAgo ? utils.timeAgo(internalValue.toDate()) : internalValue.format(constants.dates.format)}</Span> :
            ((placeholder && !hiddenPlaceholder) ? <Span className="placeholder">{placeholder}</Span> : <Span>&nbsp;</Span>)}
        </Box>
        <FormHelperText component="div" {...FormHelperTextProps}
                        className={utils.classNames('DateField-helper', FormHelperTextProps?.className)}>
          {helperText}
        </FormHelperText>
      </InputContainer>
    </StyledDateField>
  }
}));

DateField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.string,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  timeAgo: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  hiddenHelperText: PropTypes.bool,
  hiddenPlaceholder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clearable: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

DateField.defaultProps = {
  inputProps: {},
  clearable: true,
  size: 'small'
};

export default DateField;
