import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {wrapChartComponent} from 'helpers/wrapper';
import {Bar} from 'recharts';
import StyledActionBar from 'components/molecules/Charts/ActionBar/ActionBar.styles';
import utils from 'helpers/utils';

const ActionBar = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'ActionBar', {
    static: ['clickable']
  });

  const itm = useActionItem(action);
  const handleActionClick = useActionItemAction(itm?.onClick, onClick);

  const handleClick = (data, index, e) => {
    handleActionClick?.(e, data, index);
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: Boolean(handleActionClick)
  });

  return <StyledActionBar ref={ref} {...innerProps}
                          onClick={handleClick} />
});

ActionBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func
};

ActionBar.defaultProps = {
};

export default wrapChartComponent(ActionBar, Bar);
