import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDialogTitle from 'components/atoms/Dialogs/DialogTitle/DialogTitle.styles';

const DialogTitle = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DialogTitle');

  return <StyledDialogTitle ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDialogTitle>
});

DialogTitle.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DialogTitle.defaultProps = {
  children: 'DialogTitle text'
};

export default DialogTitle;
