import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Fab from 'components/atoms/Fabs/Fab/Fab';

const StyledActionFab = styled(Fab)`
  ${ComponentStyles}
`;

StyledActionFab.propTypes = {
  theme: PropTypes.object
};

StyledActionFab.defaultProps = {
}

export default StyledActionFab;
