import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToggleButton from 'components/atoms/Toggles/ToggleButton/ToggleButton';

const StyledActionToggleButton = styled(ToggleButton)`
  ${ComponentStyles}
`;

StyledActionToggleButton.propTypes = {
  theme: PropTypes.object
};

StyledActionToggleButton.defaultProps = {
}

export default StyledActionToggleButton;
