import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledCollectionSectionPanel
  from 'components/organisms/SectionPanels/CollectionSectionPanel/CollectionSectionPanel.styles';
import CollectionBasicsSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionBasicsSectionPanelContent/CollectionBasicsSectionPanelContent';
import CollectionCategoriesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionCategoriesSectionPanelContent/CollectionCategoriesSectionPanelContent';
import CollectionSourcesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionSourcesSectionPanelContent/CollectionSourcesSectionPanelContent';
import CollectionServicesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionServicesSectionPanelContent/CollectionServicesSectionPanelContent';

const CollectionSectionPanel = React.forwardRef((props, ref) => {
  const {
    onChange,
    ...innerProps
  } = useComponentProps(props, 'CollectionSectionPanel');

  const profileProvider = useProfile();
  const sectionDefinitions = profileProvider?.sectionDefinitions;

  const collection = profileProvider?.data?.data;

  const authorize = useAuthorize();

  const sectionsMemo = useMemo(() => {
    if (sectionDefinitions) {
      const sections = [];

      sectionDefinitions
        .forEach((sectionDef) => {
          const handleCanUpdate = (collection, field = null) => {
            return authorize(sectionDef.auth?.update ? {...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, collection}} : {}) &&
              (!field || authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, collection}}));
          }

          if (sectionDef.name === 'basics') {
            sections.push({
              ...sectionDef,
              Content: ({section}) => <CollectionBasicsSectionPanelContent section={section}
                                                                           onCanUpdate={handleCanUpdate}/>
            });
          } else if (sectionDef.name === 'categories') {
            sections.push({
              ...sectionDef,
              Content: ({section, data, isLoading}) => <CollectionCategoriesSectionPanelContent section={section}
                                                                                                collection={data}
                                                                                                isLoading={isLoading}
                                                                                                onCanUpdate={handleCanUpdate}/>
            });
          } else if (sectionDef.name === 'sources') {
            sections.push({
              ...sectionDef,
              Content: ({section, data, isLoading}) => <CollectionSourcesSectionPanelContent section={section}
                                                                                             collection={data}
                                                                                             isLoading={isLoading}
                                                                                             onCanUpdate={handleCanUpdate}/>
            });
          } else if (sectionDef.name === 'services') {
            sections.push({
              ...sectionDef,
              Content: ({section}) => <CollectionServicesSectionPanelContent section={section}
                                                                             onCanUpdate={handleCanUpdate}/>
            });
          }
        });

      return sections;
    } else {
      return [];
    }
  }, [sectionDefinitions, authorize]);

  const handleChange = (section, reason) => {
    onChange?.(section, reason);
    if (!['sectionInit', 'sectionNotFound'].includes(reason)) {
      profileProvider.setSettings({activeSection: section?.name});
    }
  }

  const active = sectionsMemo
    .find((s) => s.name === profileProvider?.state?.settings?.activeSection) ?? sectionsMemo[0];

  return <StyledCollectionSectionPanel ref={ref} {...innerProps}
                                       data={collection}
                                       dataKey={profileProvider.dataKey}
                                       active={active}
                                       sections={sectionsMemo}
                                       onChange={handleChange}
                                       isLoading={profileProvider.isLoading()}/>
});

CollectionSectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onChange: PropTypes.func
};

CollectionSectionPanel.defaultProps = {};

export default CollectionSectionPanel;
