import {getPalette, themeFactory} from 'theme/base/base';
import constants from 'helpers/constants';

const palette = getPalette(constants.palettes.light);

const themeOverrides = {
  name: 'light',
  palette: {
    mode: 'light',
    ...palette
  },
  components: {
    // MuiButtonBase: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       // fontSize: '3.4rem',
    //     },
    //   },
    // }
  },
  mixins: {
    // Button: css`
    //   &.Button-light {
    //     &.MuiButton-outlined {
    //       border-color: ${props => darken(props.theme.property('palette.tonalOffset'), props.theme.property('palette.grey.300'))};
    //     }
    //   }
    // `,
  },
};

const themeLight = themeFactory(themeOverrides);

export default themeLight;

