import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesList from 'components/organisms/Lists/EntitiesList/EntitiesList';

const StyledDatabaseEntitiesList = styled(EntitiesList)`
  ${ComponentStyles}
`;

StyledDatabaseEntitiesList.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitiesList.defaultProps = {
};

export default StyledDatabaseEntitiesList;
