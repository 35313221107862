import {useCallback} from 'react';
import utils from 'helpers/utils';
import {useClientStatusReorder, useClientStatusUpdate} from 'services/client/status/status.hooks';
import {arrayMove} from '@dnd-kit/sortable';

export function useClientStatusPatch () {
  const statusGroupUpdate = useClientStatusUpdate();

  return useCallback((client, statusGroup) => {
    const original = client.dealflowGroups?.find((dg) => +dg.groupId === +statusGroup.groupId);
    const changes = (Object.keys(statusGroup).length > 1) ? utils.changedFormFields(original, statusGroup) : statusGroup;

    if (!utils.isEmpty(changes)) {
      return statusGroupUpdate.mutation.mutateAsync({
        clientId: client.clientId,
        groupId: statusGroup.groupId,
        ...utils.underscoreEx(original),
        ...utils.underscoreEx({
          ...changes,
          statuses: changes.statuses ?? original.statuses
        })
      });
    } else {
      return Promise.resolve();
    }
  }, [statusGroupUpdate.mutation]);
}

export function useClientStatusMove () {
  const statusGroupReorder = useClientStatusReorder();

  return useCallback((client, groupId, position) => {
    let dealflowGroups = utils.clone(client.dealflowGroups, true);
    const originalIndex = dealflowGroups?.findIndex((dg) => +dg.groupId === +groupId);

    if (originalIndex !== -1) {
      dealflowGroups = arrayMove(dealflowGroups, originalIndex, position);
      dealflowGroups.forEach((dg, idx) => {
        dg.position = idx;
      });

      return statusGroupReorder.mutation.mutateAsync({
        clientId: client.clientId,
        statusGroups: utils.underscoreEx(dealflowGroups)
      });
    } else {
      return Promise.resolve();
    }
  }, [statusGroupReorder.mutation]);
}
