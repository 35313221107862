import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const DialogFooter = React.forwardRef((props, ref) => {
  const {
    info,
    buttons,
    ...innerProps
  } = useComponentProps(props, 'DialogFooter');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDialogFooter ref={ref} {...innerProps}>
    <Box className="DialogFooter-info">
      {info}
    </Box>
    {buttons}
  </StyledDialogFooter>
});

DialogFooter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  info: PropTypes.any,
  buttons: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'split']), PropTypes.string])
};

DialogFooter.defaultProps = {
};

export default DialogFooter;
