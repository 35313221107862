import React, {useEffect, useRef, useState} from 'react';
import StyledLogoutPage from 'components/pages/Auth/LogoutPage/LogoutPage.styles';
import PropTypes from 'prop-types';
import {useAuth, useAuthSet, useAuthToken} from 'services/auth/auth.utils';
import {useAuthLogout} from 'services/auth/auth.hooks';
import {useComponentProps} from 'helpers/hooks/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';
import {useLinkNavigate} from 'helpers/hooks/links';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import utils from 'helpers/utils';
import Navigate from 'components/organisms/Utils/Navigate/Navigate';

const LogoutPage = (props) => {
  const innerProps = useComponentProps(props, 'LogoutPage');

  const auth = useAuth();
  const setAuthLocal = useAuthSet(constants.appState.type.local);
  const setAuthSession = useAuthSet(constants.appState.type.session);

  const loggingOut = useRef(false);
  const token = useAuthToken();

  const [internalState, setInternalState] = useState({});

  const logout = Boolean(auth?.loggedIn || token);

  const doLogout = useAuthLogout();
  const navigate = useLinkNavigate();

  useEffect(() => {
    if (logout) {
      if (!loggingOut.current) {
        loggingOut.current = true;
        if (auth?.authId) {
          doLogout.mutation.mutateAsync({authId: auth?.authId})
            .then(() => {
              setInternalState(utils.updater({logoutSuccess: true}, true));
            })
            .catch(() => {
              setInternalState(utils.updater({logoutFailed: true}, true));

              setAuthLocal(null);
              setAuthSession(null);
              navigate({to: '/auth/login', replace: true});
            });
        } else {
          setAuthLocal(null);
          setAuthSession(null);
          setInternalState(utils.updater({logoutSuccess: true}, true));
        }
      }
    } else {
      setInternalState(utils.updater({logoutSuccess: true}, true));
    }
  }, [logout, setAuthLocal, setAuthSession, doLogout.mutation, auth?.authId, navigate])

  const renderForm = () => {
    return <Box className="LogoutPage-loading">
      <CircularProgress />
      <H6>Logging out</H6>
      {internalState.logoutSuccess ? <Navigate to="/auth/login"
                                               replace={true}/> : null}
    </Box>
  }

  return <StyledLogoutPage {...innerProps}>
    {renderForm()}
  </StyledLogoutPage>
};

LogoutPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

LogoutPage.defaultProps = {
};

export default LogoutPage;
