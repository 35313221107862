import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import StepButton from '@mui/material/StepButton';

const StyledStepButton = styled(StepButton)`
  &.StepButton-expandIcon {
    .MuiStepLabel-root {
      flex-grow: 1;
    }
    .MuiStepLabel-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      &.Mui-active {
        .IconButton, .Icon {
          transform: rotate(180deg);
        } 
      }
    }  
  }
  
  &.StepButton-hideNumber {
    .MuiStepIcon-root {
      .MuiStepIcon-text {
        display: none
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledStepButton.propTypes = {
  theme: PropTypes.object
};

StyledStepButton.defaultProps = {}

export default StyledStepButton;
