import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Tabs from '@mui/material/Tabs';
import constants from 'helpers/constants';

const StyledTabs = styled(Tabs)`
  ${props => props.textColor ? css`
    .MuiBadge-badge {
      ${props => props.theme.color2Css('MuiBadge-badge', null, props.textColor, {
        type: constants.colorTypes.button
      })};
    }
  ` : null}

  .MuiTabScrollButton-root {
    border: 1px solid ${props => props.theme.property('palette.divider')} !important;
    border-radius: ${props => props.theme.radius(1)};
    width: unset;
    height: unset;
    align-self: center;
    opacity: 1;

    ${props => props.theme.color2Css('MuiTabScrollButton-root', null, 'default', {
      interactive: true,
      type: constants.colorTypes.outlined,
    })}
  }

  &.Tabs-size-small {
    .MuiTabScrollButton-root {
      padding: ${props => props.theme.layout(5)};
      svg {
        font-size: ${props => props.theme.fontSize(12)};
      }
    }
  }
  
  &.Tabs-density-dense {
    height: ${props => props.theme.layout(42)};
    min-height: ${props => props.theme.layout(42)};  
    
    .Tab {
      height: ${props => props.theme.layout(42)};
      min-height: ${props => props.theme.layout(42)};
    }
  }

  &.Tabs-disabled {
    .MuiTab-root {
      color: ${props => props.theme.property('palette.action.disabled')};
    }
    .MuiTabs-indicator {
      background-color: ${props => props.theme.property('palette.action.disabled')};
    }
    .MuiTabScrollButton-root {
      pointer-events: none;
      color: ${props => props.theme.state2Color(props.$color, 'disabled', 
        constants.colorTypes.outlined, constants.colorTargets.foreground)};
      border-color: ${props => props.theme.state2Color(props.$color, 'disabled', 
        constants.colorTypes.outlined, constants.colorTargets.background)};
    }
  }
  
  ${ComponentStyles}
`;

StyledTabs.propTypes = {
};

StyledTabs.defaultProps = {
}

export default StyledTabs;
