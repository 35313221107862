import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTabPanel = styled(Box)`
  &.TabPanel-hidden {
    display: none;
  }
  
  ${ComponentStyles}
`;

StyledTabPanel.propTypes = {
  theme: PropTypes.object
};

StyledTabPanel.defaultProps = {}

export default StyledTabPanel;
