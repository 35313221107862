import {useAuthClientId} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import {useCallback, useMemo} from 'react';
import {useClientCustomFieldCache, useClientCustomFieldUpdate} from 'services/client/customField/customField.hooks';

export function useClientCustomFieldGroups () {
  const clientId = useAuthClientId();
  const clientCustomFields = useClientCustomFieldCache({clientId}, {enabled: utils.isDefined(clientId)});
  const customFields = clientCustomFields.data?.data;

  return useMemo(() => {
    const groupNames = customFields?.reduce((acc, f) => {
      if (f.groupName) {
        acc.push(utils.upperFirst(f.groupName));
      }
      return acc;
    }, []);
    return utils.uniqueArray(groupNames ?? [], null, true)
      .sort((a, b) => a.localeCompare(b));
  }, [customFields]);
}

export function useClientCustomFieldPatch () {
  const clientId = useAuthClientId();
  const updateField = useClientCustomFieldUpdate();

  return useCallback((field, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(field, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return updateField.mutation.mutateAsync({
        clientId,
        fieldId: field.fieldId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [clientId, updateField.mutation]);
}
