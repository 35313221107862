import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {P} from 'components/atoms/Text/Typography/Typography';
import StyledApplicantEmailPage from 'components/pages/Dev/Email/ApplicantEmailPage/ApplicantEmailPage.styles';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const ApplicantEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'ApplicantEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledApplicantEmailPage {...innerProps}
                                       config={clientConfig}
                                       preview="Thank you for your application, your application is confirmed.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>Your application has been confirmed.</P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>We appreciate your interest and the time you've invested in submitting your application.</P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you're application will be processed. Are you unsure or you suspect someone else did
              this, or do you have questions? Please let us know.</P>
          </TableCell>
        </TableRow>
      </StyledApplicantEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
}

ApplicantEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ApplicantEmailPage.defaultProps = {
};

export default ApplicantEmailPage;
