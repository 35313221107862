import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {wrapChartComponent} from 'helpers/wrapper';
import {Line} from 'recharts';
import utils from 'helpers/utils';
import StyledActionLine from 'components/molecules/Charts/ActionLine/ActionLine.styles';

const LineChartDot = (props) => {
  const { cx, cy, r,
    width, height, index,
    stroke, fill, strokeWidth, dataKey,
    payload, value, hoverDot, hovered,
    onMouseMove, onClick } = props;

  const [active, setActive] = useState(false);
  const clickable = Boolean(onClick);

  const handleMouseEnter = (e) => {
    onMouseMove?.(payload, index, e);
    setActive(true);
  }

  const handleMouseLeave = () => {
    setActive(false);
  }

  const handleMouseMove = (e) => {
    onMouseMove?.(payload, index, e);
  }

  const handleClick = (e) => {
    onClick?.(e, payload, dataKey);
  }

  if (utils.isDefined(value) && (!hoverDot || hovered)) {
    return <circle r={r}
                   x={cx - 10}
                   y={cy - 10}
                   cx={cx}
                   cy={cy}
                   className={`LineChartDot recharts-dot recharts-line-dot ${clickable ? 'LineChartDot-clickable' : ''}`}
                   stroke={stroke}
                   strokeWidth={strokeWidth}
                   fill={active ? stroke : fill}
                   width={width}
                   height={height}
                   onClick={handleClick}
                   onMouseEnter={handleMouseEnter}
                   onMouseLeave={handleMouseLeave}
                   onMouseMove={handleMouseMove}/>
  }
};

const ActionLine = React.forwardRef((props, ref) => {
  const {
    action,
    dataKey,
    hovered,
    hoverDot,
    onClick,
    onMouseMove,
    LineChartDotProps,
    ...innerProps
  } = useComponentProps(props, 'ActionLine', {
    static: ['clickable']
  });

  const itm = useActionItem(action);
  const handleActionClick = useActionItemAction(itm?.onClick, onClick);

  const handleClick = (e, payload) => {
    handleActionClick?.(e, payload, dataKey);
  }

  const renderDot = () => {
    return <LineChartDot r={4}
                         hovered={hovered}
                         hoverDot={hoverDot}
                         onClick={handleClick}
                         onMouseMove={onMouseMove}
                         {...LineChartDotProps}/>;
  }

  innerProps.dot = innerProps.dot ?? renderDot();

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: false
  });

  return <StyledActionLine ref={ref}
                           {...innerProps}
                           dataKey={dataKey}
                           onMouseMove={onMouseMove} />
});

ActionLine.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  hoverDot: PropTypes.bool,
  hovered: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseMove: PropTypes.func,
  LineChartDotProps: PropTypes.object
};

ActionLine.defaultProps = {
  dot: null,
  activeDot: false
};

export default wrapChartComponent(ActionLine, Line);
