import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSectionPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .SectionPanel {
    &-tabs {
      padding: 0 ${props => props.theme.spacing(1.5)};
      box-shadow: inset 0px -1px 0px ${props => props.theme.property('palette.divider')};

      justify-content: flex-start;

      .MuiTabs-scroller {
        flex: unset;
        width: unset;
      }
      
      .Tab {
        height: ${props => props.theme.layout(42)};
        min-height: ${props => props.theme.layout(42)};
      }
      
      &:has(.MuiTabs-scrollButtons) {
        padding: 0 !important;
      }
    }
    
    &-panel {
      flex-grow: 1;
      height: 1px;
    }
  }
  
  ${ComponentStyles}
`;

StyledSectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledSectionPanel.defaultProps = {}

export default StyledSectionPanel;
