import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSettingsBarWrapper
  from 'components/templates/Wrappers/Settings/SettingsBarWrapper/SettingsBarWrapper.styles';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';

const SettingsBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'SettingsBarWrapper');

  const wrapper = useWrapper();

  return <StyledSettingsBarWrapper ref={ref} {...innerProps}
                                   $wrapper={wrapper}
                                   ToolbarProps={{title: 'Settings'}}>
    {innerProps.children}
  </StyledSettingsBarWrapper>
});

SettingsBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

SettingsBarWrapper.defaultProps = {};

export default SettingsBarWrapper;
