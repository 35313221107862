import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import StyledDealflowEntitiesPage
  from 'components/pages/Dealflow/Entity/DealflowEntitiesPage/DealflowEntitiesPage.styles';
import DealflowEntitiesTable from 'components/organisms/Tables/DealflowEntitiesTable/DealflowEntitiesTable';
import EntitiesActionbar from 'components/organisms/Snackbars/EntitiesActionbar/EntitiesActionbar';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';

const DealflowEntitiesPage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesPage');

  const tableProvider = useTable();

  const authorize = useAuthorize();

  const handleCanRowClick = (entity) => {
    return authorize({attribute: 'dealflow.profile.read', meta: {entity}});
  }

  const navigate = useLinkNavigate();
  const handleRowClick = (e, entity) => {
    navigate({
      event: e,
      to: '../' + entity.entityId,
      keepSearchParams: true
    });
  }

  return <StyledDealflowEntitiesPage as={TableWrapper} {...innerProps}
                                     borders={{left: '3sp', right: '3sp'}}>
    <DealflowEntitiesTable onCanRowClick={handleCanRowClick}
                           onRowClick={handleRowClick}
                           ActionbarComponent={<EntitiesActionbar tableProvider={tableProvider}
                                                                  showDownload={true}/>} />
  </StyledDealflowEntitiesPage>
};

DealflowEntitiesPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesPage.defaultProps = {
};

export default DealflowEntitiesPage;
