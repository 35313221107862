import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesList from 'components/organisms/Lists/EntitiesList/EntitiesList';

const StyledCollectionEntitiesList = styled(EntitiesList)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesList.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesList.defaultProps = {
};

export default StyledCollectionEntitiesList;
