import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledCollectionVisibilityTableCellEdit = styled(DropdownTableCellEdit)`
  .Chip-size-large {
    min-width: ${props => props.theme.layout(96)};
  }
  .Chip-size-medium {
    min-width: ${props => props.theme.layout(80)};
  }
  
  ${ComponentStyles}
`;

StyledCollectionVisibilityTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledCollectionVisibilityTableCellEdit.defaultProps = {
}

export default StyledCollectionVisibilityTableCellEdit;
