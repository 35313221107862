import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';
import FormStyles from 'styles/mixins/Form.styles';

const StyledProfileSettingPage = styled(Page)`
  &.ProfileSettingPage {
    .ToolbarWrapper-content {
      display: flex;
      flex-direction: column;
      row-gap: ${props => props.theme.spacing(2.5)};
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
      min-width: unset;
      max-width: ${props => props.theme.layout(444)};
      align-self: flex-start;
    }
    
    .ProfileSettingPage-form {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.spacing(3)};

      .FormControl-inlineLabel {
        > .InputLabel, > .FormLabel {
          font-size: ${props => props.theme.fontSize(16)} !important;
          color: ${props => props.theme.property('palette.text.primary')};
        }

        &:not(.FormControl-readOnly) {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout(8)};
          }
          
          &.CheckboxField, &.SwitchField {
            > .InputLabel, > .FormLabel {
              margin-top: ${props => props.theme.layout(6)};
            }
          }
        }
      }
    }
    
    .ProfileSettingPage-keys {
      display: flex;
      flex-direction: column;
      row-gap: ${props => props.theme.spacing(2)};

      &-liveData, &-portal {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        height: ${props => props.theme.layout(40)};

        &-label {
          min-width: ${props => props.theme.layout(140)};
          max-width: ${props => props.theme.layout(140)};
          margin-right: ${props => props.theme.layout('1sp')};
          ${props => props.theme.font2Css('body1')};
        }

        &-key {
          width: 1px;
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledProfileSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledProfileSettingPage.defaultProps = {}

export default StyledProfileSettingPage;
