import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Avatar from '@mui/material/Avatar';
import constants from 'helpers/constants';

const StyledAvatar = styled(Avatar)`
  ${props => props.theme.color2Css('Avatar', null, props.$color, {type: constants.colorTypes.button})}
  
  // sizes (see also avatarGroup)
  &.Avatar-size-larger {
    width: ${props => props.theme.layout(36)};
    height: ${props => props.theme.layout(36)};
    font-size: ${props => props.theme.fontSize(14)} !important;
  }
  &.Avatar-size-large {
    width: ${props => props.theme.layout(32)};
    height: ${props => props.theme.layout(32)};
    font-size: ${props => props.theme.fontSize(14)} !important;
  }
  &.Avatar-size-medium {
    width: ${props => props.theme.layout(26)};
    height: ${props => props.theme.layout(26)};
    font-size: ${props => props.theme.fontSize(13)} !important;
  }
  &.Avatar-size-small {
    width: ${props => props.theme.layout(24)};
    height: ${props => props.theme.layout(24)};
    font-size: ${props => props.theme.fontSize(12)} !important;
  }
  &.Avatar-size-smaller {
    width: ${props => props.theme.layout(22)};
    height: ${props => props.theme.layout(22)};
    font-size: ${props => props.theme.fontSize(11)} !important;
  }
  &.Avatar-size-smallest {
    width: ${props => props.theme.layout(20)};
    height: ${props => props.theme.layout(20)};
    font-size: ${props => props.theme.fontSize(9)} !important;
  }
  &.Avatar-size-tiny {
    width: ${props => props.theme.layout(18)};
    height: ${props => props.theme.layout(18)};
    font-size: ${props => props.theme.fontSize(8)} !important;
  }

  &.Avatar-outlined {
    outline: 2px solid ${props => props.theme.property('palette.background.paper')};
  }

  &.Avatar-clickable {
    cursor: pointer;
  }
  &.Avatar-disabled {
    pointer-events: none;
  }

  ${ComponentStyles}
`;

StyledAvatar.propTypes = {
  theme: PropTypes.object
};

StyledAvatar.defaultProps = {
}

export default StyledAvatar;
