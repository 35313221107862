import utils from 'helpers/utils';
import {useMemo} from 'react';
import constants from 'helpers/constants';

export function processTimelineItem (data) {
  const item = utils.camelcaseEx(data);

  const getTimelineId = () => {
    return utils.sha1({
      userId: item.user?.userId,
      createdAt: item.createdAt,
      recordType: item.recordType,
      timelineType: item.timelineType,
      commentId: item.commentId,
      relationId: item.relationId,
      collectionId: item.collectionId,
      extraInfo: item.extraInfo
    });
  }

  return {
    ...item,
    timelineId: getTimelineId(),
    createdAt: new Date(item.createdAt),
    updatedAt: new Date(item.updatedAt),
  };
}

export function useTimelineLocation (item) {
  return useMemo(() => {
    if (item) {
      const entities = item.entities ?? utils.toArray(item.entity, true);

      if (+item.collection?.collectionId > 0) {
        return {
          ...constants.data.lookup('timelineLocations', constants.timeline.locations.collection),
          label: item.collection.name
        };
      }
      if (!entities.some((e) => !(+e?.csi?.status > 0))) {
        return constants.data.lookup('timelineLocations', constants.timeline.locations.dealflow);
      }
      return constants.data.lookup('timelineLocations', constants.timeline.locations.database);
    } else {
      return constants.data.lookup('timelineLocations', constants.timeline.locations.database);
    }
  }, [item]);
}
