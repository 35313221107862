import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useChartMouse, useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {CartesianGrid, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import ChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip';
import StyledBarChart from 'components/organisms/Charts/BarChart/BarChart.styles';
import ActionBar from 'components/molecules/Charts/ActionBar/ActionBar';

const BarChart = React.forwardRef((props, ref) => {
  const {
    data,
    dataKey,
    hoveredId,
    bars,
    showTooltip,
    showCursor,
    showGrid,
    showLabels,
    animate,
    isLoading,
    radius,
    gap,
    hideAxis,
    reverse,
    onClick,
    onHover,
    XAxisProps,
    YAxisProps,
    TooltipComponent,
    TooltipProps,
    GridProps,
    ResponsiveContainerProps,
    ...innerProps
  } = useComponentProps(props, 'BarChart', {
    static: ['isLoading', 'hideAxis'],
    children: ['tooltip', 'bar']
  });

  const innerRef = useRef(null);
  const containerRef = useRef(null);

  const BarChart = useMemo(() => ({
    refs: {
      ref: innerRef,
      containerRef
    }
  }), []);

  useImperativeHandle(ref, () => BarChart);

  const chartMouse = useChartMouse();

  const barsMemo = useMemo(() => {
    const sorted = bars
      .sort((a, b) => {
        if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
          return a.position - b.position;
        } else {
          return a.label.localeCompare(b.label);
        }
      });

    return reverse ? sorted.reverse() : bars;
  }, [bars, reverse]);

  const sortedData = useMemo(() => {
    if (data) {
      return data
        .sort((a, b) => {
          if (utils.isDefined(a.position) && utils.isDefined(b.position)) {
            return a.position - b.position;
          } else {
            return (a.label ?? a.name).localeCompare(b.label ?? b.name);
          }
        })
    }
  }, [data]);

  const horizontal = innerProps.layout !== 'vertical';
  const type = innerProps.type ?? (sortedData?.some((v) => !utils.isNumber(v[dataKey])) ? 'category' : 'number');

  const renderBars = () => {
    if (sortedData) {
      const cells = {};
      sortedData.forEach((d) => {
        cells[d[dataKey]] = cells[d[dataKey]] ?? {
          start: null, end: null
        };

        barsMemo.forEach((b, idx) => {
          if (utils.isDefined(d[b.dataKey]) && d[b.dataKey] !== 0) {
            cells[d[dataKey]].start = cells[d[dataKey]].start ?? idx;
            cells[d[dataKey]].end = idx;
          }
        });
      });

      const handleLabelValue = (data) => {
        const dataKey = data.tooltipPayload?.[0]?.dataKey;
        if (utils.isDefined(dataKey)) {
          if ((!horizontal && +data.width >= 36) || (horizontal && +data.height >= 36)) {
            if (utils.isDefined(data[`${dataKey}-meta`]?.barLabel)) {
              return data[`${dataKey}-meta`]?.barLabel;
            } else if (+data[dataKey] !== 0) {
              return data[dataKey];
            }
          }
        }
      };

      return barsMemo
        .map((bar, barIdx) => {
          const color = bar.color ?? innerProps.theme.property('palette.primary.main');
          const transform = gap ?
            (horizontal ? `translateY(${barIdx > 0 ? (gap * barIdx) : 0}px)` :
              `translateX(${barIdx > 0 ? (gap * barIdx) : 0}px)`) : null;
          return <ActionBar key={barIdx}
                            name={bar.name}
                            dataKey={bar.dataKey}
                            fill={color}
                            stackId={bar.stackId ?? 1}
                            style={{transform}}
                            isAnimationActive={animate}
                            onClick={onClick}
                            onMouseMove={chartMouse.handleDetailMouseMove(bar.dataKey)}
                            {...bar.BarProps}>
            {showLabels ? <LabelList valueAccessor={handleLabelValue}
                                     position={horizontal ? 'insideTop' : 'insideRight'}
                                     offset={10}/> : null}
            {sortedData.map((d, cellIdx) => {
              const hovering = utils.isDefined(hoveredId) || chartMouse.isHovering();
              const hovered = utils.isDefined(hoveredId) ? (hoveredId === bar.id) :
                chartMouse.isDetailHovering(bar.dataKey, cellIdx);
              const cellRadius = (utils.isDefined(radius) && !hideAxis) ? (
                !horizontal ? (
                  cells[d[dataKey]].start === cells[d[dataKey]].end ? [hideAxis ? radius : 0, radius, radius, hideAxis ? radius : 0] : (barIdx === cells[d[dataKey]].start ? [hideAxis ? radius : 0, 0, 0, hideAxis ? radius : 0] :
                    barIdx === cells[d[dataKey]].end ? [0, radius, radius, 0] : null)
                ) : (
                  cells[d[dataKey]].start === cells[d[dataKey]].end ? [radius, radius, hideAxis ? radius : 0, hideAxis ? radius : 0] : (barIdx === cells[d[dataKey]].start ? [0, 0, hideAxis ? radius : 0, hideAxis ? radius : 0] :
                    barIdx === cells[d[dataKey]].end ? [radius, radius, 0, 0] : null)
                )
              ) : null;
              return <Cell key={`${bar.dataKey}-${cellIdx}`}
                           className={`BarChart-bar ${hovering ? (hovered ? 'hover' : 'notHover') : ''}`}
                           radius={cellRadius}
                           fill={color}/>
            })}
          </ActionBar>
        });
    }
  }

  const onHoverEvent = useEffectEvent(onHover);
  useEffect(() => {
    if (chartMouse.isHovering()) {
      let hoverIdx, hoverData;
      barsMemo.some((bar) => {
        const foundIndex = sortedData.findIndex((d, cellIdx) => {
          return chartMouse.isDetailHovering(bar.dataKey, cellIdx);
        });

        if (foundIndex !== -1) {
          hoverIdx = foundIndex;
          hoverData = {
            ...bar,
            ...sortedData[foundIndex]
          };
          return true;
        } else {
          return false;
        }
      });

      if (hoverIdx !== -1) {
        onHoverEvent?.(null, hoverData, hoverIdx);
      }
    } else {
      onHoverEvent?.();
    }
  }, [barsMemo, chartMouse, sortedData, onHoverEvent]);

  innerProps.onMouseOut = innerProps.onMouseOut ?? chartMouse.handleChartMouseOut;
  innerProps.margin = innerProps.margin ?? (hideAxis ? {
    bottom: 0, top: (horizontal && gap) ? (((bars?.length - 1) ?? 0) * gap) : 0,
    left: 0, right: (!horizontal && gap) ? (((bars?.length - 1) ?? 0) * gap) : 0
  } : {
    top: 0, right: 0, bottom: 4, left: 0
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <ResponsiveContainer ref={BarChart.refs.containerRef} {...ResponsiveContainerProps}>
    <StyledBarChart ref={BarChart.refs.ref} {...innerProps} data={sortedData} $radius={radius}>
      {showGrid && <CartesianGrid strokeDasharray="3 3" {...GridProps}/>}
      {sortedData?.length > 0 ? <XAxis hide={hideAxis}
                                       dataKey={horizontal ? dataKey : null}
                                       type={horizontal ? type : 'number'}
                                       tickSize={16}
                                       tickLine={false}
                                       allowDecimals={false}
                                       {...XAxisProps}/> : null}
      {sortedData?.length > 0 ? <YAxis hide={hideAxis}
                                       dataKey={!horizontal ? dataKey : null}
                                       type={!horizontal ? type : 'number'}
                                       tickSize={12}
                                       tickLine={false}
                                       allowDecimals={false}
                                       {...YAxisProps}/> : null}
      {showTooltip && <Tooltip className="BarChart-tooltip"
                               cursor={showCursor}
                               content={<TooltipComponent wrapper={BarChart.refs.containerRef.current?.current}
                                                          activateByPayload={true}
                                                          activePayload={chartMouse.hoverPayLoadKey}
                                                          activeIndex={chartMouse.activeIndex}
                                                          {...TooltipProps}/>}/>}
      {renderBars()}
    </StyledBarChart>
  </ResponsiveContainer>
});

BarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dataKey: PropTypes.string,
  hoveredId: PropTypes.any,
  bars: PropTypes.array,
  showTooltip: PropTypes.bool,
  showCursor: PropTypes.bool,
  showGrid: PropTypes.bool,
  showLabels: PropTypes.bool,
  animate: PropTypes.bool,
  isLoading: PropTypes.bool,
  radius: PropTypes.number,
  gap: PropTypes.number,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  TooltipComponent: PropTypes.any,
  XAxisProps: PropTypes.object,
  YAxisProps: PropTypes.object,
  TooltipProps: PropTypes.object,
  GridProps: PropTypes.object,
  ResponsiveContainerProps: PropTypes.object
};

BarChart.defaultProps = {
  dataKey: 'name',
  showTooltip: true,
  showCursor: false,
  radius: 0,
  gap: 0,
  bars: [],
  animate: true,
  hideAxis: false,
  reverse: false,
  showLabels: false,
  stackOffset: 'none',
  layout: 'horizontal',
  TooltipComponent: ChartTooltip
};

export default BarChart;
