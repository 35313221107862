import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Kanban from 'components/organisms/Kanbans/Kanban/Kanban';

const StyledDealflowKanban = styled(Kanban)`
  ${ComponentStyles}
`;

StyledDealflowKanban.propTypes = {
  theme: PropTypes.object
};

StyledDealflowKanban.defaultProps = {
};

export default StyledDealflowKanban;
