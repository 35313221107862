import React, {useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowEntitiesKanbanPage
  from 'components/pages/Dealflow/Entity/DealflowEntitiesKanbanPage/DealflowEntitiesKanbanPage.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import DealflowKanban from 'components/organisms/Kanbans/DealflowKanban/DealflowKanban';

const DealflowEntitiesKanbanPage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesKanbanPage');

  const tableProvider = useTable();

  const openKanbanEvent = useEffectEvent(tableProvider.openKanban);
  const closeKanbanEvent = useEffectEvent(tableProvider.closeKanban);
  useLayoutEffect(() => {
    openKanbanEvent?.();
    return () => closeKanbanEvent?.();
  }, [openKanbanEvent, closeKanbanEvent]);

  const handleClick = (e, entity) => {
    tableProvider.openProfile(entity.entityId);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntitiesKanbanPage {...innerProps}>
    <DealflowKanban onClick={handleClick}/>
  </StyledDealflowEntitiesKanbanPage>
};

DealflowEntitiesKanbanPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesKanbanPage.defaultProps = {
};

export default DealflowEntitiesKanbanPage;
