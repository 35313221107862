import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowTooltip from 'components/molecules/Tooltips/DealflowTooltip/DealflowTooltip.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import Divider from 'components/atoms/Dividers/Divider/Divider';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';

const DealflowTooltip = React.forwardRef((props, ref) => {
  const {
    activeIndex,
    activateByPayload,
    activePayload,
    payload,
    variant,
    ...innerProps
  } = useComponentProps(props, 'DealflowTooltip', {
    children: ['content']
  });

  const extended = variant === 'extended' || variant === 'members';
  const payloads = activateByPayload ? payload?.filter((p) => `${p.dataKey}-${activeIndex}` === activePayload) : payload;

  const renderTitle = () => {
    const pl = payloads?.[0];
    if (pl) {
      const group = pl.payload[`${pl.dataKey}-meta`] ?? pl.payload?.['meta'];
      const color = group.noDealflow ? 'secondary' : utils.deprecatedColor(group.color);
      const statuses = group?.statuses?.filter((s) => !!s.count);
      const members = group?.members?.filter((s) => !!s.count);

      return <Box className="DealflowTooltip-content">
        <Box className="title">
          <DealflowIcon groupId={group.groupId} color={color} {...group.DealflowIconProps}/>
          <Span color={color}>{group.label}</Span>
          <Badge variant="inline" color={color} badgeContent={group?.countLabel ?? group?.count ?? pl.payload[pl.dataKey]}/>
        </Box>
        {extended && statuses?.length > 0 && [
          <Divider key="divider" />,
          <Box key="extended" className="extended">
            {statuses.map((s, idx) =>
              <Box key={idx} className="status">
                <Span>{s.name}</Span>
                <Badge className="count" variant="inline"
                       color={innerProps.theme.property('palette.primary.states.focusVisible')}
                       badgeContent={s.countLabel ?? s.count}/>
              </Box>)}
          </Box>
        ]}
        {extended && members?.length > 0 && [
          <Divider key="divider" />,
          <Box key="extended" className="extended">
            {members.map((l, idx) => {
              const name = (l?.firstName || l?.lastName) ? utils.personName(l?.firstName, l?.lastName) : 'Not assigned';
              const color = utils.string2Color(name ?? '');

              return <Box key={idx} className="leader">
                <Avatar className="avatar" size="smaller" alt={name} color={color}>
                  {utils.avatarLabel(name)}
                </Avatar>
                <Span>{name}</Span>
                <Badge className="count" variant="inline"
                       color={innerProps.theme.property('palette.primary.states.focusVisible')}
                       badgeContent={l.countLabel ?? l.count}/>
              </Box>
            })}
          </Box>
        ]}
      </Box>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowTooltip ref={ref} {...innerProps}
                                payload={payload}
                                title={renderTitle()}
                                activeIndex={activeIndex}
                                activateByPayload={activateByPayload}
                                activePayload={activePayload}/>
});

DealflowTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  activeIndex: PropTypes.any,
  activateByPayload: PropTypes.bool,
  activePayload: PropTypes.any,
  payload: PropTypes.array,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'extended', 'members']), PropTypes.string])
};

DealflowTooltip.defaultProps = {
  title: 'DealflowTooltip text'
};

export default DealflowTooltip;
