import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledEntityMergeDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(504)}, 86vh);
    max-height: clamp(0px, ${props => props.theme.layout(504)}, 86vh);
  }
  
  .DialogHeader {
    &-title-subtitle {
      margin-top: ${props => props.theme.layout(-1)} !important;
      
      .Typography {
        font-size: ${props => props.theme.fontSize(12)} !important;
      }
    }
  }
  
  .EntityMergeDialog {
    &-content {
      height: 1px;
      padding: 0;
      overflow: visible;
      position: relative;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityMergeDialog.propTypes = {
  theme: PropTypes.object
};

StyledEntityMergeDialog.defaultProps = {}

export default StyledEntityMergeDialog;
