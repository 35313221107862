import {css} from 'styled-components';

/* Test styles are used for components with text, spans labels, buttons etc. */
const ChartStyles = css`
  // overrides
  ${props => props.theme.property('mixins.ChartStyles', '')}
  ${props => props.theme.convert2Css('components.ChartStyles.styleOverrides.root')}
`

export default ChartStyles;
