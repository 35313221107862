import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ButtonGroup from '@mui/material/ButtonGroup';

const StyledButtonGroup = styled(ButtonGroup)`
  border-radius: ${props => props.theme.radius(2)};

  &.ButtonGroup {
    &.ButtonGroup {
      > .MuiButtonBase-root, > * > .MuiButtonBase-root {
        border: 1px solid ${props => props.theme.property('palette.divider')};
        border-radius: 0;
        margin-left: 0 !important;
        border-left-width: 0.5px !important;
        border-right-width: 0.5px !important;
      }

      > .MuiButtonBase-root:first-of-type, > *:first-of-type > .MuiButtonBase-root {
        border-radius: ${props => props.theme.radius(2)} 0 0 ${props => props.theme.radius(2)};
        border-left-width: 1px !important;
      }

      > .MuiButtonBase-root:last-of-type, > *:last-of-type > .MuiButtonBase-root {
        border-radius: 0 ${props => props.theme.radius(2)} ${props => props.theme.radius(2)} 0;
        border-right-width: 1px !important;
      }
    }
  }

  &.ButtonGroup-radius-square {
    border-radius: ${props => props.theme.radius(1)};
    
    > .MuiButtonBase-root:first-of-type, > *:first-of-type > .MuiButtonBase-root {
      border-radius: ${props => props.theme.radius(1)} 0 0 ${props => props.theme.radius(1)};
    }
    > .MuiButtonBase-root:last-of-type, > *:last-of-type > .MuiButtonBase-root {
      border-radius: 0 ${props => props.theme.radius(1)} ${props => props.theme.radius(1)} 0;
    }
  }

  ${ComponentStyles}
`;

StyledButtonGroup.propTypes = {
};

StyledButtonGroup.defaultProps = {
}

export default StyledButtonGroup;
