import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import StyledActionCheckbox from 'components/molecules/Inputs/ActionCheckbox/ActionCheckbox.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';

const ActionCheckbox = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionCheckboxProps}, 'ActionCheckbox');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    return <Tooltip title={action.tooltip} showDisabled={false}
                    fullWidth={innerProps.fullWidth ?? action.CheckboxProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionCheckbox ref={ref} {...innerProps}
                            icon={action.icon ? <Icon icon={action.icon}/> : null}
                            checkedIcon={action.checkedIcon ? <Icon icon={action.checkedIcon}/> :
                              (action.icon ? <Icon icon={action.icon}/> : null)}
                            checked={itm?.active}
                            disabled={!itm}
                            onClick={handleClick}
                            {...action.CheckboxProps}/>
    </Tooltip>
  }
});

ActionCheckbox.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionCheckbox.defaultProps = {
  label: 'ActionIconButton text'
};

export default ActionCheckbox;
