import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {Span} from 'components/atoms/Text/Typography/Typography';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import utils from 'helpers/utils';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import StyledCollectionCategoryWizardDialog
  from 'components/organisms/Dialogs/CollectionCategoryWizardDialog/CollectionCategoryWizardDialog.styles';
import CollectionCategoryWizard from 'components/organisms/Wizards/CollectionCategoryWizard/CollectionCategoryWizard';
import Add from '@mui/icons-material/Add';

const CollectionCategoryWizardDialog = React.forwardRef((props, ref) => {
  const {
    collection,
    title,
    subtitle,
    onClose,
    onSubmit,
    CollectionCategoryWizardProps,
    ...innerProps
  } = useComponentProps(props, 'CollectionCategoryWizardDialog');

  const innerRef = useRef(null);
  const wizardRef = useRef(null);
  const [internalState, setInternalState] = useState({
    error: null,
    isPrevEnabled: false,
    isNextEnabled: false
  });

  useImperativeHandle(ref, () => innerRef.current);

  const backButtonAction = useMemo(() => ({
    label: 'Back',
    icon: ChevronLeft,
    ButtonProps: {
      color: 'primary',
      variant: 'text'
    },
    auth: internalState.isSubmitting ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      wizardRef.current?.goToPrevStep?.();
      e.preventDefault();
    }
  }), [internalState.isSubmitting]);

  const nextButtonAction = useMemo(() => ({
    label: internalState.isNextEnabled ? 'Next' : 'Create categories',
    icon: internalState.isNextEnabled ? ChevronRight : Add,
    iconPosition: internalState.isNextEnabled ? 'end' : 'start',
    ButtonProps: {
      color: internalState.isNextEnabled ? 'primary' : 'success',
    },
    auth: (internalState.isSubmitting || (!internalState.isNextEnabled && !internalState.isSubmitEnabled)) ?
      utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      wizardRef.current?.goToNextStep?.();
      e.preventDefault();
    }
  }), [internalState.isNextEnabled, internalState.isSubmitEnabled, internalState.isSubmitting]);

  const handleChange = (isPrevEnabled, isNextEnabled, isSubmitEnabled, isSubmitting) => {
    setInternalState(utils.updater({
      isPrevEnabled,
      isNextEnabled,
      isSubmitEnabled,
      isSubmitting
    }, true));
  }

  const handleSubmit = (tagGroups, actions, onSuccess, onError) => {
    setInternalState(utils.updater({isSubmitting: false}, true));

    utils.asPromise(onSubmit)(tagGroups)
      .then(() => {
        onSuccess?.();
        onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        onError?.();
        setInternalState(utils.updater({
          error: 'Creating categories failed'
        }, true));
      })
      .finally(() => {
        setInternalState(utils.updater({isSubmitting: false}, true));
      });
  };

  const handleClose = (e, reason) => {
    if (!wizardRef.current?.isDirty() || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  };

  const handleError = (error, validation) => {
    setInternalState(utils.updater({
      error: (error && !validation) ? error : null
    }, true));
  }

  const renderButtons = () => {
    return <React.Fragment>
      {internalState.isPrevEnabled ? <ActionButton key="back"
                                                   showInactive={true}
                                                   action={backButtonAction}/> : null}
      <ActionButton key="next"
                    showInactive={true}
                    action={nextButtonAction}/>
    </React.Fragment>
  }

  const renderInfo = () => {
    if (internalState.error) {
      return <Span color="error">{internalState.error}</Span>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionCategoryWizardDialog ref={innerRef} {...innerProps}
                                               width={544}
                                               onClose={handleClose}>
    <DialogHeader className="CollectionCategoryWizardDialog-header"
                  title={title ?? 'Category wizard'}
                  subtitle={subtitle}/>
    <DialogContent className="CollectionCategoryWizardDialog-content">
      <CollectionCategoryWizard ref={wizardRef}
                                variant="standard"
                                className="CollectionCategoryWizardDialog-wizard"
                                showProgressBar={true}
                                collection={collection}
                                onError={handleError}
                                onSubmit={handleSubmit}
                                onChange={handleChange}
                                {...CollectionCategoryWizardProps} />
    </DialogContent>
    <DialogFooter className="CollectionCategoryWizardDialog-footer"
                  variant="split"
                  info={renderInfo()}
                  buttons={renderButtons()} />
  </StyledCollectionCategoryWizardDialog>
});

CollectionCategoryWizardDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  CollectionCategoryWizardProps: PropTypes.object
};

CollectionCategoryWizardDialog.defaultProps = {
};

export default CollectionCategoryWizardDialog;
