import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Link from 'components/atoms/Links/Link/Link';

const StyledNavLink = styled(Link)`
  text-decoration: none;
  ${ComponentStyles}
`;

StyledNavLink.propTypes = {
  theme: PropTypes.object
};

StyledNavLink.defaultProps = {
};

export default StyledNavLink;
