import React, {useEffect, useRef, useState} from 'react';
import StyledLoginPage from './LoginPage.styles';
import PropTypes from 'prop-types';
import LoginForm from 'components/organisms/Forms/LoginForm/LoginForm';
import {useLocation} from 'react-router-dom';
import {
  useAuth,
  useAuthClientId,
  useAuthIsProxy,
  useAuthSetTeamId,
  useAuthTeamId,
  useAuthToken,
  useAuthUserId
} from 'services/auth/auth.utils';
import {useAuthPassLogin, useAuthPassLogout, useAuthRenew} from 'services/auth/auth.hooks';
import {useComponentProps} from 'helpers/hooks/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useClientUserUpdate} from 'services/client/user/user.hooks';
import {useAuthPasses, useAuthTeams} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useLinkNavigate} from 'helpers/hooks/links';
import Navigate from 'components/organisms/Utils/Navigate/Navigate';

const LoginPage = (props) => {
  const innerProps = useComponentProps(props, 'LoginPage');

  const [internalState, setInternalState] = useState({});

  const auth = useAuth();
  const token = useAuthToken();
  const location = useLocation();
  const teams = useAuthTeams();
  const passes = useAuthPasses();
  const isProxy = useAuthIsProxy();
  const clientId = useAuthClientId();
  const teamId = useAuthTeamId();
  const userId = useAuthUserId();

  const snackbar = useSnackbar();
  const navigate = useLinkNavigate();

  const renewed = useRef(false);
  const passLogout = useRef(false);
  const passLogin = useRef(false);
  const teamSwitch = useRef(false);

  const overrideToken = utils.location2URL(location?.state?.from)?.searchParams?.get('key') ||
    utils.location2URL(location?.state?.from)?.searchParams?.get('token');
  const activatePass = utils.location2URL(location?.state?.from)?.searchParams?.get('activatePass');
  const activateTeam = utils.location2URL(location?.state?.from)?.searchParams?.get('activateTeam');

  const renew = (token || overrideToken) && !auth?.loggedIn;

  const doRenew = useAuthRenew({overrideApiToken: overrideToken});
  const doPassLogin = useAuthPassLogin();
  const doPassLogout = useAuthPassLogout();

  const userUpdate = useClientUserUpdate();
  const setAuthTeamId = useAuthSetTeamId();

  useEffect(() => {
    if (renew && !renewed.current) {
      renewed.current = true;
      doRenew.mutation.mutateAsync()
        .then(() => {
          setInternalState(utils.updater({renewSuccess: true}, true));
        })
        .catch((error) => {
          setInternalState(utils.updater({renewFailed: true}, true));

          if (error?.response?.status === constants.http.status.forbidden) {
            snackbar.show('Your session has expired', null,
              {color: 'warning', autoHideDuration: constants.delay.warning});

            navigate({to: '/auth/logout', resetState: false});
          }
        });
    }
  }, [snackbar, navigate, doRenew.mutation, renew, auth?.authId]);

  useEffect(() => {
    if (auth?.loggedIn && +activatePass > 0 && !passLogout.current) {
      passLogout.current = true;

      if (isProxy && +clientId !== +activatePass) {
        doPassLogout.mutation.mutateAsync({authId: auth?.authId})
          .then(() => {
            setInternalState(utils.updater({logoutSuccess: true}, true));
          })
          .catch(() => {
            snackbar.show('Pass activation failed', null,
              {color: 'warning', autoHideDuration: constants.delay.warning});

            setInternalState(utils.updater({logoutFailed: true}, true));
          });
      } else {
        setInternalState(utils.updater({logoutSuccess: true}, true));
      }
    }
  }, [snackbar, doPassLogout.mutation, auth?.authId, auth?.loggedIn, clientId, isProxy, activatePass]);

  useEffect(() => {
    if (auth?.loggedIn && +activatePass > 0 && internalState.logoutSuccess && !passLogin.current) {
      passLogin.current = true;

      const pass = passes?.find((pass) => +pass.client.clientId === +activatePass);
      if (!pass) {
        snackbar.show('Pass activation failed', null,
          {color: 'warning', autoHideDuration: constants.delay.warning});

        setInternalState(utils.updater({passFailed: true}, true));
      } else {
        if (+clientId !== +activatePass) {
          doPassLogin.mutation.mutateAsync({userId: pass.user.userId})
            .then(() => {
              setInternalState(utils.updater({passSuccess: true}, true));
            })
            .catch(() => {
              snackbar.show('Pass activation failed', null,
                {color: 'warning', autoHideDuration: constants.delay.warning});

              setInternalState(utils.updater({passFailed: true}, true));
            });
        } else {
          setInternalState(utils.updater({passSuccess: true}, true));
        }
      }
    }
  }, [snackbar, doPassLogout.mutation, doPassLogin.mutation, auth?.authId, auth?.loggedIn,
    clientId, isProxy, activatePass, internalState.logoutSuccess, passes]);

  useEffect(() => {
    if (auth?.loggedIn && +activateTeam > 0 && (!(+activatePass > 0) || internalState.passSuccess) && !teamSwitch.current) {
      teamSwitch.current = true;

      const team = teams?.find((team) => +team.clientId === +activateTeam);
      if (!team) {
        snackbar.show('Team activation failed', null,
          {color: 'warning', autoHideDuration: constants.delay.warning});

        setInternalState(utils.updater({teamFailed: true}, true));
      } else {
        if (+teamId !== +activateTeam) {
          userUpdate.mutation.mutateAsync({clientId, userId, team_id: activateTeam})
            .then(() => {
              setAuthTeamId(activateTeam);
              setInternalState(utils.updater({teamSuccess: true}, true));
            })
            .catch(() => {
              snackbar.show('Team activation failed', null,
                {color: 'warning', autoHideDuration: constants.delay.warning});

              setInternalState(utils.updater({teamFailed: true}, true));
            });
        } else {
          setInternalState(utils.updater({teamSuccess: true}, true));
        }
      }
    }
  }, [snackbar, auth?.loggedIn, userUpdate.mutation, setAuthTeamId, userId, clientId,
    teamId, isProxy, activatePass, activateTeam, internalState.passSuccess, teams]);

  const renderForm = () => {
    const isRenew = (renew || renewed.current) && !internalState.renewFailed;
    const isRenewed = isRenew && internalState.renewSuccess;
    const isPassActivate = (+activatePass > 0 || passLogin.current) && !internalState.passFailed;
    const isPassActivated = isPassActivate && internalState.passSuccess;
    const isTeamActivate = (+activateTeam > 0 || teamSwitch.current) && !internalState.teamFailed;
    const isTeamActivated = isTeamActivate && internalState.teamSuccess;

    if (auth?.loggedIn || isRenew) {
      const defaultLogin = !isRenew && !(+activatePass > 0) && !isTeamActivate;
      const showTeams = auth?.loggedIn && defaultLogin &&
        (teams.filter((t) => !t.props.isPrivate || t.isMember).length > 1);
      const isBusy = (isRenew && !isRenewed) || (isPassActivate && !isPassActivated) || (isTeamActivate && !isTeamActivated);

      return <Box className="LoginPage-loading">
        <CircularProgress />
        <H6>Preparing user data</H6>
        {!isBusy ? (showTeams ? <Navigate to="/auth/teams"
                                          state={{
                                            from: location.state?.from,
                                            resetSearchParams: true
                                          }}
                                          replace={true}/> :
          <Navigate to={utils.validRedirect(location.state?.from)}
                    state={{
                      from: location,
                      recover: true,
                      resetSearchParams: true
                    }}
                    replace={true}/>) : null}
      </Box>
    } else {
      return <LoginForm className="LoginPage-form"/>
    }
  }

  return <StyledLoginPage {...innerProps}>
    {renderForm()}
  </StyledLoginPage>
};

LoginPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

LoginPage.defaultProps = {
};

export default LoginPage;
