import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileBar from 'components/organisms/Bars/ProfileBar/ProfileBar';

const StyledEntityProfileBar = styled(ProfileBar)`
  ${ComponentStyles}
`;

StyledEntityProfileBar.propTypes = {
  theme: PropTypes.object
};

StyledEntityProfileBar.defaultProps = {
};

export default StyledEntityProfileBar;
