import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledResetPage = styled(Page)`
  .ResetPage {
    &-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
    }

    &-auth-links {
      display: flex;
      margin-top: 1em;
      margin-bottom: 1em;

      > * {
        margin-right: 1em;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledResetPage.propTypes = {
  theme: PropTypes.object
};

StyledResetPage.defaultProps = {}

export default StyledResetPage;
