import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledClientNotificationList = styled(Box)`
  &:has(.DataList-empty) {
    width: ${props => props.theme.layout(222)};
    height: ${props => props.theme.layout(50)};
    
    .DataList-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      .DataList-empty {
        font-size: ${props => props.theme.fontSize(14)};
      }
    }
  }
  
  &:not(:has(.DataList-empty)) {
    width: ${props => props.theme.layout(322)};
    max-height: min(${props => props.theme.layout(460)}, 50vh);

    .TimelineList {
      height: 100%;
      max-height: inherit;
      margin-right: 0;

      .DataList-list {
        width: 100%;
        overflow-x: hidden;
        max-height: inherit;
        padding: ${props => props.theme.spacing(2)};

        .TimelineCard-comment {
          .TimelineCard-content {
            background-color: ${props => props.theme.property('palette.alert.infoFill')};
          }
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledClientNotificationList.propTypes = {
  theme: PropTypes.object
};

StyledClientNotificationList.defaultProps = {}

export default StyledClientNotificationList;
