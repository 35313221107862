import utils from 'helpers/utils';
import {useMemo} from 'react';
import {useCollectionStatGet, useCollectionStatList} from 'services/collection/stat/stat.hooks';

export function useCollectionStatCompleteness (collectionId, search, filter) {
  const completenessGet = useCollectionStatGet({
    collectionId,
    statId: 'completeness',
    search, filter
  }, {enabled: collectionId > 0});

  const completeness = useMemo(() => {
    if (utils.isDefined(completenessGet?.data?.data)) {
      return utils.camelcaseEx(completenessGet?.data?.data);
    } else {
      return null;
    }
  }, [completenessGet?.data?.data]);

  return [completeness, completenessGet?.status?.isLoading];
}

export function useCollectionStatCollectionTags (collectionId, search, filter) {
  const statsList = useCollectionStatList({
    collectionId,
    stats: ['collectionTags'],
    search, filter
  }, {enabled: collectionId > 0});

  const collectionTagsStat = statsList?.data?.[0];

  const collectionTags = useMemo(() => {
    if (utils.isDefined(collectionTagsStat?.data)) {
      return utils.camelcase(collectionTagsStat?.data, true);
    } else {
      return null;
    }
  }, [collectionTagsStat?.data]);

  return [collectionTags, statsList?.status?.isLoading];
}

export function useCollectionStatRecentChanges (collectionId, year, months, search, filter) {
  const recentChangesGet = useCollectionStatGet({
    collectionId,
    statId: 'recentChanges',
    year, months,
    search, filter
  }, {enabled: collectionId > 0});

  const recentChanges = useMemo(() => {
    if (utils.isDefined(recentChangesGet?.data?.data)) {
      return utils.camelcaseEx(recentChangesGet?.data?.data);
    } else {
      return null;
    }
  }, [recentChangesGet?.data?.data]);

  return [recentChanges, recentChangesGet?.status?.isLoading];
}
