import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {H6, P} from 'components/atoms/Text/Typography/Typography';
import Button from 'components/atoms/Buttons/Button/Button';
import Link from 'components/atoms/Links/Link/Link';
import StyledWeeklyEmailPage from 'components/pages/Dev/Email/WeeklyEmailPage/WeeklyEmailPage.styles';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const WeeklyEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'WeeklyEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledWeeklyEmailPage {...innerProps}
                                    config={clientConfig}
                                    preview="There is new activity for you in Catalist. Check it out here.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>There is new activity for you in Catalist. Here is an overview of the current activity for you.</P>
          </TableCell>
        </TableRow>
        <TableRow className="WeeklyEmailPage-recentRow">
          <TableCell colSpan={2}>
            <H6>Recent activity:</H6>
            <List>
              <ListItem>You have {'{{UNREAD_COMMENTS}}'} unread comment(s)</ListItem>
              <ListItem>You have {'{{NEW_TASKS}}'} new task(s)</ListItem>
            </List>
          </TableCell>
        </TableRow>
        <TableRow className="WeeklyEmailPage-commentsAndTasksRow">
          <TableCell colSpan={2}>
            <H6>Comments and Tasks:</H6>
            <List>
              <ListItem>You have {'{{UNREAD_COMMENTS_TOTAL}}'} unread comment(s) in total
                and {'{{UNREAD_COMMENTS_TEAM}}'} unread team comment(s)</ListItem>
              <ListItem>You have {'{{OPEN_TASKS}}'} open task(s) in total and {'{{OPEN_TASKS_TEAM}}'} team
                task(s)</ListItem>
            </List>
          </TableCell>
        </TableRow>
        <TableRow className="WeeklyEmailPage-recentCommentsRow">
          <TableCell colSpan={2}>
            <H6>Most recent comment(s):</H6>
            <List>
              <ListItem>
                <P>{'{{UNREAD_COMMENTS_ITEM_TITLE}}'}</P>
                <P color="text.secondary">{'{{UNREAD_COMMENTS_ITEM_TEXT}}'}</P>
              </ListItem>
            </List>
          </TableCell>
        </TableRow>
        <TableRow className="WeeklyEmailPage-newTasksRow">
          <TableCell colSpan={2}>
            <H6>New task(s):</H6>
            <List>
              <ListItem>
                <P>{'{{NEW_TASKS_ITEM_TITLE}}'}</P>
                <P color="text.secondary">{'{{NEW_TASKS_ITEM_TEXT}}'}</P>
                <P color="text.secondary" className="due">{'{{NEW_TASKS_ITEM_DATE}}'}</P>
              </ListItem>
            </List>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>You can find all this information easily on the Catalist dashboard page. Use this link to go to the
              dashboard:</P>
          </TableCell>
        </TableRow>
        <TableRow className="WeeklyEmailPage-dashboardButtonRow">
          <TableCell colSpan={2}>
            <Button component="a" href="{{DASHBOARD_LINK}}" variant="contained" size="medium">Open dashboard</Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>
              Or cut and paste this text if the link doesn't work:<br/>
              <Link className="system" href="{{DASHBOARD_LINK}}">{'{{DASHBOARD_LINK}}'}</Link>
            </P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you can now see your activity. Are you still unsure, or do you still have questions?
              Please let us know.</P>
          </TableCell>
        </TableRow>
      </StyledWeeklyEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

WeeklyEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

WeeklyEmailPage.defaultProps = {
};

export default WeeklyEmailPage;
