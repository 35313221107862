import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';

const path = utils.servicePath(import.meta.url);

export function useClientTeamStatus () {
  return useServiceStatus(path);
}

export function useClientTeamList ({clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useClientTeamGet ({clientId, teamId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId, teamId}, queryOptions);
}

export function useClientTeamAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useClientTeamUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useClientTeamDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
