import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import {TaskAlt} from '@mui/icons-material';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';
import StyledRelevancyDistributionPieChart
  from 'components/organisms/Charts/RelevancyDistributionPieChart/RelevancyDistributionPieChart.styles';

const RelevancyDistributionPieChart = React.forwardRef((props, ref) => {
  const {
    distribution,
    total,
    onClick,
    showIcon,
    showTotal,
    showName,
    showLegend,
    extended,
    isLoading,
    variant,
    visibility,
    orientation,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'RelevancyDistributionPieChart', {
    variable: ['orientation'],
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (distribution) {
      distribution.forEach((d) => {
        if (d.count > 0) {
          legend.push({
            id: d.id,
            position: d.position,
            label: d.label,
            explanation: d.explanation,
            color: d.color,
            active: visibility?.[d.id] ?? true
          });
        }
      });
    }

    const rows = legend.length;

    return [legend, rows];
  }, [distribution, visibility]);

  const distributionData = useMemo(() => {
    if (distribution) {
      return {
        dataKey: 'value',
        nameKey: 'label',
        data: distribution
          .map((d) => {
            if (d.count > 0) {
              return {
                id: d.id,
                position: d.position,
                label: d.label,
                color: d.color,
                value: d.count,
                meta: {
                  ...d,
                  count: d.count,
                  countLabel: `${d.count} companies`,
                  label: d.label
                }
              }
            } else {
              return {}; // empty
            }
          })
            .filter((b) => {
              return Boolean(legend.find((l) => l.active && l.id === b.id))
            }),
        TooltipComponent: BreakdownTooltip
      };
    } else {
      return null;
    }
  }, [distribution, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledRelevancyDistributionPieChart ref={ref} {...innerProps}>
    <Box className="RelevancyDistributionPieChart-chart">
      <Box className="RelevancyDistributionPieChart-chart-wrapper">
        {distributionData ? <PieChart {...distributionData}
                                      hoveredId={internalState.hoveredId}
                                      variant={variant}
                                      onClick={onClick}
                                      isLoading={isLoading}
                                      showTooltip={true}
                                      TooltipProps={{
                                        density: 'sparse'
                                      }}
                                      {...PieChartProps} /> : null}

        <Box className="RelevancyDistributionPieChart-center">
          {showIcon ? <Icon color="primary" icon={TaskAlt} /> : null}

          {showTotal ? <Typography color="primary"
                                  className="totalCount"
                                  isLoading={isLoading}>
                        {utils.formatNumber(total || 0)}
                      </Typography> : null}
          {showTotal ? <H6 className="title" isLoading={isLoading}>Tasks</H6> : null}

          {showName ? <H6 className="title" isLoading={isLoading}>Relevance</H6> : null}
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="RelevancyDistributionPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          orientation={orientation === 'vertical' ? 'horizontal' : 'vertical'}
                          rows={rows}/> : null}
  </StyledRelevancyDistributionPieChart>
});

RelevancyDistributionPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  distribution: PropTypes.array,
  total: PropTypes.number,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  showTotal: PropTypes.bool,
  showName: PropTypes.bool,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

RelevancyDistributionPieChart.defaultProps = {
  variant: 'full',
  orientation: 'vertical',
  showName: true
};

export default RelevancyDistributionPieChart;
