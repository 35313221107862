import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useExportAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'export/:filename',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useExportDownloadGet ({taskId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {taskId}, {
    overridePath: 'export/downloadUrl?taskId=:taskId',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
