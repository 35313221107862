import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledStepContent from 'components/atoms/Steppers/StepContent/StepContent.styles';

const StepContent = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'StepContent');

  return <StyledStepContent ref={ref} {...innerProps} />
}));

StepContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

StepContent.defaultProps = {};

export default StepContent;
