import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FooterBar from 'components/organisms/Bars/FooterBar/FooterBar';

const StyledSuggestionBar = styled(FooterBar)`
  background-color: transparent;
  border-top: unset !important;
  
  &.SuggestionBar.PageBar {
    .PageBar {
      &-toolbar {
        align-items: flex-end;
        padding: 0;
      }
      &-title {
        align-self: center;
        color: ${props => props.theme.property('palette.background.paper')};
        
        * {
          ${props => props.theme.font2Css('body1')};
        }
      }
    }

    .ActionSwitch {
      .FormControlLabel {
        color: ${props => props.theme.property('palette.background.paper')};
        * {
          ${props => props.theme.font2Css('body1')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSuggestionBar.propTypes = {
  theme: PropTypes.object
};

StyledSuggestionBar.defaultProps = {
};

export default StyledSuggestionBar;
