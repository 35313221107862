import countries from 'helpers/data/countries.json';
import industries from 'helpers/data/industries.json';
import currencies from 'helpers/data/currencies.json';
import dayjs from 'dayjs';
import {darken, lighten} from 'polished';
import Lock from '@mui/icons-material/Lock';
import People from '@mui/icons-material/People';
import Public from '@mui/icons-material/Public';
import YouTube from '@mui/icons-material/YouTube';
import Facebook from '@mui/icons-material/Facebook';
import GitHub from '@mui/icons-material/GitHub';
import Instagram from '@mui/icons-material/Instagram';
import Link from '@mui/icons-material/Link';
import {
  AutoTags,
  Bloomberg,
  CatalistDatabase,
  CatalistSuggestions,
  CheckList,
  CompanyInfo,
  Crunchbase,
  Database,
  Dealflow,
  Excel,
  Google,
  Places,
  GPT,
  Guardian,
  Hoovers,
  LinkedIn,
  Logo,
  ManCheck,
  Newsfeed,
  NewYorkTimes,
  Owler,
  PitchBook,
  Reddit,
  Scrape,
  SimilarWeb,
  Traction,
  Video,
  Vimeo,
  XIcon,
  Xing
} from 'assets/icons';
import SourceCordis from 'assets/images/cordis-logo.png';
import Patents from 'assets/images/epo-logo.png';
import StarHalf from '@mui/icons-material/StarHalf';
import Apartment from '@mui/icons-material/Apartment';
import EuroSymbol from '@mui/icons-material/EuroSymbol';
import Gavel from '@mui/icons-material/Gavel';
import Newspaper from '@mui/icons-material/Newspaper';
import School from '@mui/icons-material/School';
import Science from '@mui/icons-material/Science';
import Wifi from '@mui/icons-material/Wifi';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import Work from '@mui/icons-material/Work';
import Person from '@mui/icons-material/Person';
import Man from '@mui/icons-material/Man';
import Close from '@mui/icons-material/Close';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Feed from '@mui/icons-material/Feed';
import Source from '@mui/icons-material/Source';
import ListAlt from '@mui/icons-material/ListAlt';
import utilsBasic from 'helpers/utils.basic';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import HourglassTop from '@mui/icons-material/HourglassTop';
import CheckBox from '@mui/icons-material/CheckBox';
import {
  AccessTime,
  AcUnit,
  Bedtime,
  Coffee,
  DirectionsBike,
  Factory,
  Lightbulb,
  Mail, MailOutline,
  Recycling,
  Stars,
  ThumbUp,
  Whatshot
} from '@mui/icons-material';

const numbers = {
  maxInt: 2147483647,
  maxBigInt: 9200000000000000000,
  randomInt: 65536,
  million: 1000000,
  billion: 1000000000,
  KB: (1024),
  MB: (1024 * 1024),
  GB: (1024 * 1024 * 1024),
  minYear: 1
};

const http = {
  status: {
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    invalidMethod: 405,
    conflict: 409,
    serverError: 500,
    notImplemented: 501
  }
}

const analyserTypes = [
  {
    label: 'Collection category analyser',
    value: 'collection_tag_analyser'
  },
  {
    label: 'Manual analyser',
    value: 'manual_analyser'
  },
  {
    label: 'Just points analyser',
    value: 'just_points_analyser'
  },
  {
    label: 'Field analyser',
    value: 'field_analyser'
  },
  {
    label: 'Keyword analyser',
    value: 'keyword_analyser'
  },
  {
    label: 'Questionnaire analyser',
    value: 'questionnaire_analyser'
  },
  {
    label: 'GPT analyser',
    value: 'gpt_analyser'
  }
];

const autoTagTypes = [
  {
    label: 'No auto-assign',
    value: 'just_points_analyser',
    icon: Close,
    color: 'action.active'
  },
  {
    label: 'Autotags by keywords',
    value: 'keyword_analyser',
    icon: Google,
    price: 5
  },
  {
    label: 'Autotags by AI',
    value: 'gpt_analyser',
    icon: GPT,
    price: 5
  }
]

const tractionPeriods = [
  {
    label: '3M',
    value: '3m',
    months: 3,
    color: 'primary'
  },
  {
    label: '6M',
    value: '6m',
    months: 6,
    color: 'primary'
  },
  {
    label: '1Y',
    value: '1y',
    months: 12,
    color: 'primary'
  },
  {
    label: '3Y',
    value: '3y',
    months: 12 * 3,
    color: 'primary'
  }
];

const tractionTypes = [
  {
    label: 'FTE',
    value: 'fte'
  },
  {
    label: 'Funding',
    value: 'funding'
  }
];

const patentPeriods = [
  {
    label: '3Y',
    value: '3y',
    years: 3,
    color: 'primary'
  },
  {
    label: '5Y',
    value: '5y',
    years: 5,
    color: 'primary'
  },
  {
    label: '15Y',
    value: '15y',
    years: 15,
    color: 'primary'
  },
  {
    label: '25Y',
    value: '25y',
    years: 25,
    color: 'primary'
  }
];

const patentLevels = [
  {
    label: 'Section',
    value: 'section',
    level: 6
  },
  {
    label: 'Class',
    value: 'class',
    level: 7
  },
  {
    label: 'Subclass',
    value: 'subclass',
    level: 8
  }
];

const smallRange = [
  {
    value: 1,
    label: '1 - 5',
  }, {
    value: 6,
    label: '6 - 10',
  }, {
    value: 11,
    label: '11 - 20',
  }, {
    value: 21,
    label: '21 - 30',
  }, {
    value: 31,
    label: '31 - 50',
  }, {
    value: 51,
    label: '51 - 70',
  }, {
    value: 71,
    label: '71 - 100',
  }, {
    value: 101,
    label: '100+',
  }];

const mediumRange = [
  {
    value: 1,
    label: '1 - 10',
  }, {
    value: 11,
    label: '11 - 50',
  }, {
    value: 51,
    label: '51 - 200',
  }, {
    value: 201,
    label: '201 - 500',
  }, {
    value: 501,
    label: '501 - 1000',
  }, {
    value: 1001,
    label: '1001 - 5000',
  }, {
    value: 5001,
    label: '5001 - 10000',
  }, {
    value: 10001,
    label: '10000+',
  }];

const largeRange = [
  {
    value: 1,
    label: '1 - 500',
  }, {
    value: 501,
    label: '501 - 1000',
  }, {
    value: 1001,
    label: '1001 - 5000',
  }, {
    value: 5001,
    label: '5001 - 10000',
  }, {
    value: 10001,
    label: '10001 - 50000',
  }, {
    value: 50001,
    label: '50001 - 100000',
  }, {
    value: 100001,
    label: '100001 - 1000000',
  }, {
    value: 1000001,
    label: '1000000+',
  }];

const moneyRange = [
  {
    value: 1,
    label: '1 - 100k',
  }, {
    value: 100000,
    label: '100k - 2m',
  }, {
    value: 2000000,
    label: '2m - 5m',
  }, {
    value: 5000000,
    label: '5m - 10m',
  }, {
    value: 10000000,
    label: '10m - 20m',
  }, {
    value: 20000000,
    label: '20m - 50m',
  }, {
    value: 50000000,
    label: '50m - 100m',
  }, {
    value: 100000000,
    label: '100m+',
  }];

const fundingStatus = [
  {
    value: 'operating',
    label: 'Operating',
  }, {
    value: 'ipo',
    label: 'IPO',
  }, {
    value: 'acquired',
    label: 'Acquired',
  }, {
    value: 'closed',
    label: 'Closed',
  }];

const fundingRounds = [
  {
    value: 'preseed',
    label: 'Pre-Seed Round'
  },
  {
    value: 'seed',
    label: 'Seed Round'
  },
  {
    value: 'series_a',
    label: 'Series A'
  },
  {
    value: 'series_b',
    label: 'Series B'
  },
  {
    value: 'series_c',
    label: 'Series C'
  },
  {
    value: 'series_d',
    label: 'Series D'
  },
  {
    value: 'later_series',
    label: 'Later Series'
  },
];

const businessModels = [
  {
    label: 'B2B',
    value: 'B2B',
  }, {
    label: 'B2C',
    value: 'B2C',
  }, {
    label: 'B2B2C',
    value: 'B2B2C',
  }, {
    label: 'B2G',
    value: 'B2G',
  }
];

const toggleYesNo = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  }
];

const gtSmallRange = [
  {
    label: '<= 0',
    value: 'N'
  },
  {
    value: 1,
    label: '>= 1',
  }, {
    value: 6,
    label: '>= 6',
  }, {
    value: 11,
    label: '>= 11',
  }, {
    value: 21,
    label: '>= 21',
  }, {
    value: 31,
    label: '>= 31',
  }, {
    value: 41,
    label: '>= 41',
  }, {
    value: 51,
    label: '>= 51',
  }, {
    value: 61,
    label: '>= 61',
  }, {
    value: 71,
    label: '>= 71',
  }, {
    value: 81,
    label: '>= 81',
  }, {
    value: 91,
    label: '>= 91',
  }];

const gtPointRange = [
  {label: '<= 0', value: 'N'},
  {label: '>= 1', value: 1},
  {label: '>= 26', value: 26},
  {label: '>= 51', value: 51},
  {label: '>= 101', value: 101},
  {label: '>= 501', value: 501},
  {label: '>= 1001', value: 1001},
  {label: '>= 5001', value: 5001},
  {label: '>= 10001', value: 10001}
];

const ssoVendors = [
  {
    label: 'Google',
    value: 'google',
    system: true
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
    system: true
  },
  {
    label: 'Apple',
    value: 'apple',
    system: true
  },
  {
    label: 'Saml',
    value: 'saml',
    system: false
  },
  {
    label: 'WS Federation',
    value: 'ws-saml',
    system: false
  },
  {
    label: 'Azure Ad',
    value: 'azure-ad',
    system: false
  },
  {
    label: 'oAuth2',
    value: 'oauth2',
    system: false
  },
];

const profiles = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Cluster',
    value: 'cluster'
  },
  {
    label: 'Name check',
    value: 'name_check'
  },
  {
    label: 'Company check',
    value: 'company_check'
  },
  {
    label: 'Universe',
    value: 'universe'
  }
]

const entityTypes = [
  {
    label: 'Company',
    value: 'company',
    icon: Work
  },
  {
    label: 'University / Research',
    value: 'university',
    icon: School
  },
  {
    label: 'Scientific',
    value: 'scientific',
    icon: Science
  },
  {
    label: 'Government',
    value: 'government',
    icon: Gavel
  },
  {
    label: 'News',
    value: 'news',
    icon: Newspaper
  },
  {
    label: 'Entertainment',
    value: 'entertainment',
    icon: Video
  },
  {
    label: 'Social media',
    value: 'social media',
    icon: Wifi
  },
  {
    label: 'E-commerce',
    value: 'e-commerce',
    icon: AlternateEmail
  },
  {
    label: 'Investor',
    value: 'investor',
    icon: EuroSymbol
  },
  {
    label: 'Person',
    value: 'person',
    icon: Person
  }
];

const regions = [
  {
    label: 'Africa',
    value: '002'
  },
  {
    label: 'Asia',
    value: '142'
  },
  {
    label: 'Central America',
    value: '013'
  },
  {
    label: 'Europe',
    value: '150'
  },
  {
    label: 'Northern America',
    value: '021'
  },
  {
    label: 'Oceania',
    value: '009'
  },
  {
    label: 'South America',
    value: '005'
  }
];

const links = [
  {
    label: 'Website',
    value: 'website',
    tooltip: 'Homepage',
    icon: Public,
    clean: true
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
    tooltip: 'LinkedIn',
    icon: LinkedIn,
    clean: true
  },
  {
    label: 'Crunchbase',
    value: 'crunchbase',
    tooltip: 'Crunchbase',
    icon: Crunchbase,
    clean: true
  },
  {
    label: 'X',
    value: 'twitter',
    hostname: 'x',
    tooltip: 'X',
    icon: XIcon,
    clean: true
  },
  {
    label: 'Owler',
    value: 'owler',
    tooltip: 'Owler',
    icon: Owler,
    clean: true
  },
  {
    label: 'Hoovers',
    value: 'hoovers',
    tooltip: 'Hoovers',
    icon: Hoovers,
    clean: true
  },
  {
    label: 'Bloomberg',
    value: 'bloomberg',
    tooltip: 'Bloomberg',
    icon: Bloomberg,
    clean: true
  },
  {
    label: 'Instagram',
    value: 'instagram',
    tooltip: 'Instagram',
    icon: Instagram,
    clean: true
  },
  {
    label: 'SimilarWeb',
    value: 'similarweb',
    tooltip: 'SimilarWeb',
    icon: SimilarWeb,
    clean: true
  },
  {
    label: 'Xing',
    value: 'xing',
    tooltip: 'Xing',
    icon: Xing,
    clean: true
  },
  {
    label: 'PitchBook',
    value: 'pitchbook',
    tooltip: 'PitchBook',
    icon: PitchBook,
    clean: true
  },
  {
    label: 'GitHub',
    value: 'github',
    tooltip: 'GitHub',
    icon: GitHub,
    clean: true
  },
  {
    label: 'Facebook',
    value: 'facebook',
    tooltip: 'Facebook',
    icon: Facebook,
    clean: true
  },
  {
    label: 'YouTube',
    value: 'youtube',
    tooltip: 'YouTube',
    icon: YouTube,
    clean: false
  },
  {
    label: 'Vimeo',
    value: 'vimeo',
    tooltip: 'Vimeo',
    icon: Vimeo,
    clean: false
  },
  {
    label: 'Other',
    value: 'link',
    tooltip: 'Other link',
    icon: Link,
    clean: false
  }
]

const score = [
  {
    value: 'low',
    label: 'Low',
    min: 0,
    max: 25,
    color: 'error'
  },
  {
    value: 'mid-low',
    label: 'Mid-low',
    min: 26,
    max: 50,
    color: 'warning'
  },
  {
    value: 'mid-high',
    label: 'Mid high',
    min: 51,
    max: 75,
    color: 'primary'
  },
  {
    value: 'high',
    label: 'High',
    min: 76,
    max: 100,
    color: 'success'
  },
];

const diskSourceTypes = [
  {
    label: 'LinkedIn',
    value: 'linkedin'
  },
  {
    label: 'Crunchbase',
    value: 'crunchbase'
  }
];

const taskTypes = [
  {
    value: 'dealflow',
    label: 'Set deal flow status',
    icon: Dealflow,
    collaborationType: 'collective'
  },
  {
    value: 'relevancy',
    label: 'Set personal relevance',
    icon: StarHalf,
    collaborationType: 'individual'
  },
  {
    value: 'profile',
    label: 'Complete profile',
    icon: Apartment,
    collaborationType: null
  },
  {
    value: 'questionnaire',
    label: 'Complete questionnaire',
    icon: CheckList,
    system: true,
    collaborationType: 'collective'
  },
  {
    value: 'verifyLinks',
    label: 'Verify links',
    icon: CheckList,
    system: true,
    collaborationType: 'individual'
  },
  {
    value: 'verifyCocNumber',
    label: 'Verify CoC number',
    icon: CheckList,
    system: true,
    collaborationType: 'individual'
  },
  {
    value: 'other',
    label: 'Other',
    icon: MoreHoriz,
    collaborationType: null
  }
];

const taskLocations = [
  {
    value: 'database',
    label: 'Database',
    icon: Database
  },
  {
    value: 'dealflow',
    label: 'Deal flow',
    icon: Dealflow
  },
  {
    value: 'collection',
    label: 'Collection',
    icon: FolderSharp
  },
];

const taskCollaborationTypes = [
  {
    value: 'individual',
    label: 'Individual',
    icon: [ManCheck, ManCheck, ManCheck, ManCheck],
    helper: 'Everyone needs to complete this task'
  },
  {
    value: 'collective',
    label: 'Collective',
    icon: [Man, Man, ManCheck, Man],
    helper: 'Only one of the assigned needs to complete this task'
  },
];

const timelineLocations = taskLocations;

const hml = [
  {
    value: 0,
    label: 'None',
    chipColor: 'secondary',
    buttonColor: 'relevancyNoneButton'
  },
  {
    value: 1,
    label: 'Low',
    chipColor: 'error',
    buttonColor: 'relevancyLowButton'
  },
  {
    value: 2,
    label: 'Medium',
    chipColor: 'warning',
    buttonColor: 'relevancyMediumButton'
  },
  {
    value: 3,
    label: 'High',
    chipColor: 'success',
    buttonColor: 'relevancyHighButton'
  }
];

const collectionVisibility = [
  {
    value: 'user',
    label: 'Private',
    color: 'warning',
    icon: Lock
  },
  {
    value: 'client',
    label: 'Team',
    color: 'success',
    icon: People
  },
  {
    value: 'public',
    label: 'Public',
    color: 'default',
    icon: Public
  }
]

const collectionProjectTypes = [
  {
    label: 'Find quality investment targets',
    value: 'quality_investment'
  },
  {
    label: 'Find novel technology supplier(s)',
    value: 'novel_technology'
  },
  {
    label: 'Find qualified sales leads',
    value: 'sales_leads'
  },
  {
    label: 'Find acquisition targets',
    value: 'acquisition_targets'
  },
  {
    label: 'Build market map',
    value: 'market_map'
  }
];

const relevancyDistributionTypes = [
  {
    label: 'Team vs External',
    value: 'teamVsExternal'
  },
  {
    label: 'Team members',
    value: 'teamMembers'
  }
];

const periodMonths = [
  {
    label: 'This month',
    value: 1
  },
  {
    label: 'Last 3 months',
    value: 3
  },
  {
    label: 'Last 6 months',
    value: 6
  },
  {
    label: 'Last 12 months',
    value: 12
  }
];

const periodYears = [
  {
    label: 'This year',
    value: 1
  },
  {
    label: 'Last 2 years',
    value: 2
  },
  {
    label: 'Last 3 years',
    value: 3
  }
];


const completeness = [
  {
    label: 'General',
    value: 'general',
    fields: ['location', 'fte', 'industry', 'oneliner', 'description', 'followers', 'website', 'linkedin', 'websitetext']
  },
  {
    label: 'Financial',
    value: 'financial',
    fields: ['totalFunding', 'fundingStatus', 'fundingRoundsNumber', 'lastFundingDate', 'revenue', 'operatingIncome']
  }
]

const recentChanges = [
  {
    label: 'General',
    value: 'general',
    fields: ['location', 'fte', 'industry', 'oneliner', 'totalFunding', 'revenue', 'website', 'linkedin', 'followers']
  }
]

const roles = [
  {
    label: 'Comment only',
    value: 'COMMENT_ONLY'
  },
  {
    label: 'Standard',
    value: 'CLIENT'
  },
  {
    label: 'Admin',
    value: 'CLIENT_ADMIN'
  },
  {
    label: 'Standard',
    value: 'USER'
  },
  {
    label: 'Admin',
    value: 'ADMIN'
  },
  {
    label: 'Developer',
    value: 'DEV'
  }
];

const customFieldRendererTypes = [
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Textarea',
    value: 'markdown'
  },
  {
    label: 'Number',
    value: 'numeric'
  },
  {
    label: 'Monetary',
    value: 'monetary'
  },
  {
    label: 'Date',
    value: 'date'
  },
  {
    label: 'Boolean',
    value: 'boolean'
  }
];

const querySourceTypes = [
  {
    label: 'Crunchbase',
    value: 'crunchbase'
  },
  {
    label: 'Patents',
    value: 'patents'
  },
  {
    label: 'GitHub',
    value: 'github'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const queryPartTypes = [
  {
    label: 'If',
    value: 'IF'
  },
  {
    label: 'And',
    value: 'AND'
  },
  {
    label: 'Or',
    value: 'OR'
  }
];

const queryTermFunctors = [
  {
    label: 'Or',
    value: 'OR'
  },
  {
    label: 'And',
    value: 'AND'
  },
  {
    label: 'And not',
    value: 'AND_NOT'
  },
  {
    label: 'Or not',
    value: 'OR_NOT'
  }
];

const queryFilterOperators = [
  {
    label: 'Is',
    value: 'IS',
    multiple: true
  },
  {
    label: 'Not is',
    value: 'NOT_IS',
    isNot: true,
    multiple: true
  },
  {
    label: 'Contains',
    value: 'CONTAINS',
    contains: true,
    multiple: true
  },
  {
    label: 'Contains all',
    value: 'CONTAINS_ALL',
    contains: true,
    multiple: true
  },
  {
    label: 'Not contains',
    value: 'NOT_CONTAINS',
    isNot: true,
    contains: true,
    multiple: true
  },
  {
    label: 'Not contains all',
    value: 'NOT_CONTAINS_ALL',
    isNot: true,
    contains: true,
    multiple: true
  },
  {
    label: 'Greater then',
    value: 'GT',
    isValue: true
  },
  {
    label: 'Greater then equal',
    value: 'GTE',
    isValue: true
  },
  {
    label: 'Less then',
    value: 'LT',
    isValue: true
  },
  {
    label: 'Less then equal',
    value: 'LTE',
    isValue: true
  },
  {
    label: 'Between',
    value: 'BETWEEN',
    isBetween: true
  },
  {
    label: 'Not between',
    value: 'NOT_BETWEEN',
    isBetween: true,
    isNot: true
  },
  {
    label: 'Empty',
    value: 'EMPTY',
    isEmpty: true
  },
  {
    label: 'Not empty',
    value: 'NOT_EMPTY',
    isNot: true,
    isEmpty: true
  }
]

const budgetPurposes = [
  {
    label: 'General',
    value: 'general',
    icon: Logo,
    position: 1,
    graphColor: 'pink.500'
  },
  {
    label: 'Sources',
    value: 'sources',
    icon: CatalistDatabase,
    graphColor: 'success.main',
    position: 2
  },
  {
    label: 'Enrichment',
    value: 'enrichment',
    icon: LinkedIn,
    iconColor: '#0077B5',
    graphColor: 'primary.main',
    position: 3
  },
  {
    label: 'Autotags',
    value: 'analysis',
    icon: AutoTags,
    graphColor: 'warning.main',
    position: 4
  }
];

// extra options
const planOptions = [
  {
    label: 'Create and edit profiles',
    value: 'hasProfiles',
    properties: []
  },
  {
    label: 'Unlimited thematic collections',
    value: 'hasCollections',
    properties: []
  },
  {
    label: 'Custom deal flow pipeline',
    value: 'hasDealflow',
    properties: []
  },
  {
    label: 'Deal flow analytics',
    value: 'hasDealflowAnalytics',
    properties: []
  },
  {
    label: 'Search & filter',
    value: 'hasFilters',
    properties: []
  },
  {
    label: 'Web plug in',
    value: 'hasPlugin',
    properties: []
  },
  {
    label: 'Collaborate (rate, comment, add files)',
    value: 'hasCollaboration',
    properties: []
  },
  {
    label: 'Tasks and reminders',
    value: 'hasTasks',
    properties: []
  },
  {
    label: 'Custom fields',
    value: 'hasCustomFields',
    properties: []
  },
  {
    label: 'Opportunity scoring',
    value: 'hasOpportunityScoring',
    properties: []
  },
  {
    label: 'Create multiple & private team',
    value: 'hasTeams',
    properties: ['hasTeams', 'hasPrivateTeams']
  },
  {
    label: 'Questionnaire',
    value: 'hasQuestionnaires',
    properties: ['hasQuestionnaires']
  },
  {
    label: 'Catalist database',
    value: 'hasSearch',
    properties: ['hasSearch']
  },
  {
    label: 'Patent data',
    value: 'hasPatents',
    properties: ['hasPatents']
  },
  {
    label: 'Crunchbase data',
    value: 'hasCrunchbase',
    properties: ['hasCrunchbase']
  },
  {
    label: 'LinkedIn enrichment',
    value: 'hasLinkedinEnrichment',
    properties: []
  },
  {
    label: 'Company.info enrichment',
    value: 'hasCompanyInfoEnrichment',
    properties: []
  },
  {
    label: 'Traction data',
    value: 'hasTraction',
    properties: ['hasTraction']
  },
  {
    label: 'Rule based tagging/scoring',
    value: 'hasRuleBasedTagging',
    properties: ['hasAnalytics', 'hasAnalyticsT2']
  },
  {
    label: 'AI based tagging/scoring',
    value: 'hasAIBasedTagging',
    properties: ['hasAnalytics', 'hasAnalyticsT2']
  },
  {
    label: 'Web scraping',
    value: 'hasWebScraping',
    properties: ['canScrapeWebsites']
  },
  {
    label: 'Google search scraping',
    value: 'hasGoogleScraping',
    properties: ['canScrapeWebsites']
  },
  {
    label: 'Google places scraping',
    value: 'hasGooglePlacesScraping',
    properties: ['canScrapeWebsites']
  },
  {
    label: 'Email scraping',
    value: 'hasEmailScraping',
    properties: ['canScrapeWebsites']
  },
  {
    label: 'News feed scraping',
    value: 'hasFeedScraping',
    properties: ['canScrapeWebsites']
  }
]

const planSections = [
  {
    label: 'Deal flow management',
    value: 'dealflowManagement',
    options: planOptions.filter((p) => ['hasProfiles', 'hasCollections', 'hasDealflow', 'hasDealflowAnalytics',
      'hasFilters', 'hasPlugin'].find((o) => o === p.value))
  },
  {
    label: 'Collaborate & Assess',
    value: 'collaborateAndAssess',
    options: planOptions.filter((p) => ['hasCollaboration',
      'hasTasks', 'hasCustomFields', 'hasOpportunityScoring'].find((o) => o === p.value))
  },
  {
    label: 'Cross-team collaboration',
    value: 'crossTeamCollaboration',
    options: planOptions.filter((p) => ['hasTeams', 'hasQuestionnaires'].find((o) => o === p.value))
  },
  {
    label: 'Integrated data sources',
    value: 'integratedDataSources',
    options: planOptions.filter((p) => ['hasSearch', 'hasPatents', 'hasCrunchbase'].find((o) => o === p.value))
  },
  {
    label: 'Enrichment services',
    value: 'enrichmentServices',
    options: planOptions.filter((p) => ['hasLinkedinEnrichment', 'hasCompanyInfoEnrichment', 'hasTraction',
      'hasRuleBasedTagging', 'hasAIBasedTagging'].find((o) => o === p.value))
  },
  {
    label: 'Source scraping',
    value: 'sourceScraping',
    options: planOptions.filter((p) => ['hasWebScraping',
      'hasGoogleScraping', 'hasFeedScraping', 'hasGooglePlacesScraping', 'hasEmailScraping'].find((o) => o === p.value))
  }
]

const plans = [
  {
    label: 'Catalist Essential',
    value: 'basic',
    subtitle: 'Deal flow',
    except: ['hasTeams', 'hasQuestionnaires', 'hasPatents', 'hasCrunchbase',
      'hasRuleBasedTagging', 'hasAIBasedTagging',
      'hasTraction', 'hasWebScraping', 'hasGoogleScraping', 'hasFeedScraping', 'hasGooglePlacesScraping', 'hasEmailScraping'],
    credits: 1000,
    seats: 5,
    price: 115
  },
  {
    label: 'Catalist Scale',
    value: 'intermediate',
    subtitle: 'Deal flow & Data',
    except: ['hasQuestionnaires', 'hasWebScraping', 'hasGoogleScraping', 'hasFeedScraping',
      'hasGooglePlacesScraping', 'hasEmailScraping'],
    credits: 4000,
    seats: 5,
    price: 395
  },
  {
    label: 'Catalist Advanced',
    value: 'advanced',
    subtitle: 'Deal sourcing',
    color: 'amber.400',
    except: [],
    credits: 24000,
    seats: 5,
    price: 695
  },
  {
    label: 'Enterprise',
    value: 'custom',
    subtitle: 'Custom',
    except: [],
    color: 'success',
    custom: true
  }
]

const sourceSubTypes = [
  {
    label: 'RSS',
    value: 'rss'
  },
  {
    label: 'Reddit',
    value: 'reddit',
    icon: Reddit
  },
  {
    label: 'The Guardian',
    value: 'guardian',
    icon: Guardian
  },
  {
    label: 'New York Times',
    value: 'nyt',
    icon: NewYorkTimes
  }
]

const sourceTypes = [
  {
    label: 'Suggestions',
    value: 'Suggestions',
    period: '1 day',
    icon: CatalistSuggestions,
    description: 'Catalist creates suggestions for you based on the information already in your collection',
    enabled: true
  },
  {
    label: 'Database',
    value: 'Database',
    period: '1 day',
    icon: CatalistDatabase,
    description: 'Create a filter to search the Catalist database and the companies will be suggested to you',
    enabled: true
  },
  {
    label: 'Collection',
    value: 'Collection',
    period: '1 day',
    icon: FolderSharp,
    description: 'You can combine other collections as a source to your collection',
    color: 'primary',
    enabled: false
  },
  {
    label: 'Google search',
    value: 'Google',
    description: 'Enter a search query and we will do the rest. Interesting companies will be suggested to you',
    period: '3 months',
    icon: Google,
    color: 'primary',
    enabled: true
  },
  {
    label: 'Google Places search',
    value: 'Places',
    description: 'Enter a search query and we will do the rest. Interesting companies will be suggested to you',
    period: '3 months',
    icon: Places,
    color: 'primary',
    enabled: true
  },
  {
    label: 'Website scrape',
    value: 'Website',
    description: 'Enter a URL and we will do the rest. When we find an interesting company, we let you know',
    period: '3 months',
    icon: Scrape,
    color: 'primary',
    enabled: true
  },
  {
    label: 'Email scrape',
    value: 'Email',
    description: 'Forward email to the generated email address and we will do the rest. Interesting companies will be suggested to you',
    period: '1 day',
    icon: MailOutline,
    color: 'primary',
    enabled: true
  },
  {
    label: 'X search',
    value: 'Twitter',
    description: 'We will look for interesting companies related to a hashtag you determine',
    period: '3 months',
    icon: XIcon,
    enabled: false
  },
  {
    label: 'Newsfeed',
    value: 'WebFeed',
    description: 'Select a news source and let us do the searching',
    period: '1 month',
    subTypes: sourceSubTypes.filter((st) => ['rss', 'reddit', 'nyt', 'guardian'].includes(st.value)),
    icon: Newsfeed,
    color: 'warning',
    enabled: true
  },
  {
    label: 'Cordis',
    value: 'Cordis',
    period: '3 months',
    img: SourceCordis,
    description: 'We will find projects on Cordis that might interest you',
    enabled: true
  },
  {
    label: 'File upload',
    value: 'FileUpload',
    description: 'Select a file to upload',
    period: '1 minute',
    icon: Excel,
    color: 'green',
    enabled: true
  },
  {
    label: 'Collection',
    value: 'Collection',
    description: 'Collaborate by using a collection as a source',
    period: '3 months',
    icon: FolderSharp,
    color: 'primary',
    enabled: false
  },
  {
    label: 'Curated list',
    value: 'CuratedList',
    description: 'This list is maintaned by Venture IQ analysts',
    period: '3 months',
    icon: ListAlt,
    color: 'primary',
    enabled: false
  }
];

const sourceFilterTypes = [
  {
    label: 'Extend filters',
    value: 'extend'
  },
  {
    label: 'Override filters',
    value: 'override'
  }
]

const services = [
  {
    label: 'Autotags',
    value: 'autotags',
    oneliner: 'Let Catalist categorise the companies for you',
    description: 'Enhance your Catalist experience with our \'Autotags\' service! Create your own categories and let Catalist apply them to the company profiles. Gain valuable insights into the market space and more, all with a single click. Elevate your understanding, refine your leads, and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'Autotags\' service! Create your own categories and let Catalist apply them to the company profiles.',
    activation: 'Upgrade today and unlock a realm of business success!',
    images: [
      {
        icon: AutoTags,
        alt: 'Autotags'
      }
    ],
    period: 'once',
    price: 5,
    client: true,
    collection: true
  },
  {
    label: 'LinkedIn enrichment',
    value: 'linkedinEnrichment',
    source: 'linkedin',
    oneliner: 'Complete company profiles with latest LinkedIn data',
    description: 'Enhance your Catalist experience with our \'LinkedIn enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from LinkedIn. Gain insights into personnel, popularity changes, and more, all with a single click. Elevate your understanding, refine your leads, and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'LinkedIn enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from LinkedIn.',
    activation: 'Upgrade today and unlock a realm of business success!',
    images: [
      {
        icon: LinkedIn,
        alt: 'LinkedIn',
        color: '#0077B5'
      },
      {
        icon: Logo,
        alt: 'Catalist'
      }
    ],
    period: '1 month',
    price: 5,
    client: true,
    collection: true
  },
  {
    label: 'Company.info enrichment',
    value: 'companyInfoEnrichment',
    source: 'company-info',
    oneliner: 'Complete company profiles with latest Company.info data',
    description: 'Enhance your Catalist experience with our \'Company.info enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from Company.info. Gain insights into personnel, financial changes, and more, all with a single click. Elevate your understanding, refine your leads, and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'Company.info enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from Company.info.',
    activation: 'Upgrade today and unlock a realm of business success!',
    images: [
      {
        icon: CompanyInfo,
        alt: 'Company.info'
      },
      {
        icon: Logo,
        alt: 'Catalist'
      }
    ],
    period: '1 year',
    price: 80,
    client: true,
    collection: true
  },
  {
    label: 'Patents enrichment',
    value: 'patentEnrichment',
    source: 'patents',
    oneliner: 'Complete company profiles with latest Patent data',
    description: 'Enhance your Catalist experience with our \'Patents enrichment\' service! Expand the information of found company profiles with valuable patent data. Gain insights into new and existing patent applications, and more, all with a single click. Elevate your understanding and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'Patents enrichment\' service! Expand the information of found company profiles with valuable patent data.',
    activation: 'Upgrade your plan to activate this feature and unlock a realm of business success!',
    images: [
      {
        img: Patents,
        alt: 'LinkedIn'
      },
      {
        icon: Logo,
        alt: 'Catalist'
      }
    ],
    price: 0,
    plan: true,
    client: true,
    collection: true
  },
  {
    label: 'Crunchbase enrichment',
    value: 'crunchbaseEnrichment',
    source: 'crunchbase',
    oneliner: 'Complete company profiles with latest Crunchbase data',
    description: 'Enhance your Catalist experience with our \'Crunchbase enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from Crunchbase. Gain insights into funding, personnel changes, and more, all with a single click. Elevate your understanding and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'Crunchbase enrichment\' service! Expand the information of found company profiles with valuable data sourced directly from Crunchbase.',
    activation: 'Upgrade your plan to activate this feature and unlock a realm of business success!',
    images: [
      {
        icon: Crunchbase,
        alt: 'Crunchbase',
        color: '#146aff'
      },
      {
        icon: Logo,
        alt: 'Catalist'
      }
    ],
    price: 0,
    plan: true,
    client: true,
    collection: true
  },
  {
    label: 'Traction',
    value: 'traction',
    oneliner: 'Get valuable insights in company profiles by tracking key changes',
    description: 'Enhance your Catalist experience with our \'Traction\' service! Monitor historical changes on key data points in your company profiles. Gain valuable insights through traction graphs and traction indicators, all with a single click. Elevate your understanding and amplify your business opportunities with this powerful addition to our online tool.',
    shortDescription: 'Enhance your Catalist experience with our \'Traction\' service! Monitor historical changes on key data points in your company profiles.',
    images: [
      {
        icon: Traction,
        alt: 'Traction'
      }
    ],
    price: 0,
    always: true,
    client: true,
    collection: true
  }
];

const constants = {};

constants.viqInfo = {
  name: 'Venture IQ',
}

constants.auth = {
  group: {
    all: 'ALL',
    commentOnly: 'COMMENT_ONLY',
    client: 'CLIENT',
    clientAdmin: 'CLIENT_ADMIN',
    user: 'USER',
    admin: 'ADMIN',
    dev: 'DEV'
  },
  level: {
    all: 0,
    commentOnly: 1,
    client: 3,
    clientAdmin: 4,
    user: 5,
    admin: 6,
    dev: 7
  }
}

constants.user = {
  types: {
    regular: 'regular',
    proxy: 'proxy',
    agent: 'agent',
    adfs: 'adfs'
  }
}

constants.numbers = {
  ...numbers,
  position: {
    fieldGap: 1000,
    columnGap: 1000,
    groupGap: 1000,
    cardGap: 1000,
    sectionGap: 1000,
    panelGap: 1000,
    graphGap: 1000,
    filterGap: 1000,
    presetGap: 1000,
    minPosition: 1,
    maxPosition: 1000 * 1000
  }
};

constants.http = http;

constants.dates = {
  format: 'LL'
}

constants.periods = {
  week: 'week',
  month: 'month',
  year: 'year',
}

constants.appState = {
  type: {
    none: 'none',
    temp: 'temp',
    session: 'session',
    local: 'local'
  },
  scope: {
    global: 'global',
    user: 'user',
    team: 'team'
  }
}

constants.selection = {
  max: {
    database: 1000,
    dealflow: 1000,
    collection: 1000,
    user: 10,
    collections: 100,
    client: 10
  }
}

constants.colorTypes = {
  base: 'base',
  state: 'state',
  input: 'input',
  button: 'button',
  outlined: 'outlined',
  chip: 'chip',
  accent: 'accent',
  accentText: 'accentText',
  accentBackground: 'accentBackground',
  transparent: 'transparent',
  paper: 'paper'
}

constants.colorTargets = {
  foreground: 'foreground',
  ripple: 'ripple',
  background: 'background',
  border: 'border',
}

constants.dataTypes = {
  list: 'list',
  entity: 'entity',
  other: 'other'
}

constants.resetTypes = {
  logout: 'logout',
  client: 'client',
  team: 'team',
}

constants.delay = {
  minimal: 300,
  shortest: 500,
  switch: 500,
  shorter: 1000,
  close: 500,
  dimensions: 1000,
  short: 1500,
  refresh: 1500,
  success: 1500,
  warning: 2000,
  waiting: 3000,
  medium: 3000,
  error: 3000,
  long: 5000,
  longer: 8000,
  token: 30000,
  idle: 3 * 60 * 1000
}

constants.debounce = {
  micro: 10,
  minimal: 100,
  shortest: 300,
  dblClick: 300,
  hover: 500,
  search: 500,
  input: 500,
  selection: 300,
  short: 400,
  browser: 600,
  medium: 800,
  init: 1000,
  chart: 1000,
  query: 1000,
  invalidate: 1000,
  google: 500,
  cooldown: 500,
  refresh: 3000,
  long: 1600,
  extraLong: 3200
}

constants.queryOptions = {
  infinite: {
    getNextPageParam: (lastPage, pages) => {
      const resultsCount = lastPage?.response?.meta?.resultsCount;
      const hasMore = lastPage?.response?.meta?.hasMore;
      if (utilsBasic.isDefined(resultsCount)) {
        const doneCount = pages.reduce((t, p) => t + (p.response.data?.data?.length ?? 0), 0);
        return (doneCount < resultsCount) ? +lastPage?.response?.meta?.page + 1 : null;
      } else if (utilsBasic.isDefined(hasMore)) {
        return hasMore ? +lastPage?.response?.meta?.page + 1 : null;
      } else {
        return +lastPage?.response?.meta?.page + 1;
      }
    },
    infiniteQuery: true
  },
  runOnce: {
    // will not automatically refetch, but is cached,
    // works for enabled: true/false not always displayed info (like filters)
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  },
  runOnceNotStale: {
    // skips data if it's stale or invalidated
    // works for lookups: enabled: true/false, stale data is not relevant
    skipStaleData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  },
  runOnceFresh: {
    // will not automatically refetch, always fresh
    // works best with timestamps in query keys or file downloads
    cacheTime: 0,
    staleTime: 0,
    forceNewData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  }
}

constants.fieldDataTypes = {
  text: 'text',
  citext: 'citext',
  int: 'int',
  boolean: 'boolean',
  numeric: 'numeric',
  date: 'date',
  timestamp: 'timestamp',
  jsonb: 'jsonb',
  url: 'url'
}

constants.fieldRenderers = {
  default: 'default',
  hml: 'hml',
  textarea: 'textarea',
  int: 'int',
  date: 'date',
  year: 'year',
  email: 'email',
  text: 'text',
  percentageInt: 'percentageInt',
  percentageNumber: 'percentageNumber',
  location: 'location',
  employees: 'employees',
  businessModel: 'businessModel',
  entityType: 'entityType',
  industry: 'industry',
  financeHistory: 'financeHistory',
  fundingStatus: 'fundingStatus',
  fundingRound: 'fundingRound',
  stars: 'stars',
  currency: 'currency',
  phone: 'phone',
  numeric: 'numeric',
  monetary: 'monetary',
  markdown: 'markdown',
  boolean: 'boolean',
  tag: 'tag',
  feed: 'feed',
  collection: 'collection',
  label: 'label',
  keyword: 'keyword',
  ipc: 'ipc',
  cpc: 'cpc',
  article: 'article',
  screenshot: 'screenshot',
  link: 'link',
  plan: 'plan'
}

constants.formFieldValidationTypes = {
  text: 'text',
  int: 'int',
  number: 'number',
  url: 'url',
  email: 'email',
  username: 'username',
  phone: 'phone',
  password: 'password',
  file: 'file',
  country: 'country',
  location: 'location',
  list: 'list',
  unique: 'unique',
  year: 'year',
  date: 'date',
  notInTheFuture: 'notInTheFuture',
  notInThePast: 'notInThePast',
  boolean: 'boolean',
  truthy: 'truthy',
  positive: 'positive',
  negative: 'negative',
  nonNegative: 'nonNegative',
  min: 'min',
  max: 'max',
  match: 'match',
  tag: 'tag',
  status: 'status',
  collection: 'collection',
  label: 'label',
  query: 'query',
  component: 'component'
}

constants.formFieldConversionTypes = {
  none: 'none',
  text: 'text',
  invert: 'invert',
  int: 'int',
  number: 'number',
  value: 'value',
  label: 'label',
  date: 'date',
  year: 'year',
  location: 'location',
  tag: 'tag',
  collection: 'collection',
  labelObject: 'labelObject',
  employees: 'employees',
  entityType: 'entityType',
  businessModel: 'businessModel',
  fundingStatus: 'fundingStatus',
  fundingRound: 'fundingRound',
  industry: 'industry',
  currency: 'currency',
  user: 'user',
  link: 'link',
  plan: 'plan',
  author: 'author',
  component: 'component'
}

constants.formFieldFormatTypes = {
  int: 'int',
  number: 'number',
  percentageInt: 'percentageInt',
  percentageNumber: 'percentageNumber',
  lower: 'lower',
  upper: 'upper'
}

constants.formSectionTypes = {
  standard: 'standard',
  info: 'info'
}

constants.formFieldTypes = {
  text: 'text',
  textarea: 'textarea',
  markdown: 'markdown',
  password: 'password',
  autocomplete: 'autocomplete',
  suggestion: 'suggestion',
  cloud: 'cloud',
  list: 'list',
  category: 'category',
  slider: 'slider',
  checkbox: 'checkbox',
  switch: 'switch',
  number: 'text',
  monetary: 'autocomplete',
  date: 'date',
  file: 'file',
  stars: 'stars',
  financeHistory: 'financeHistory',
  tags: 'tags',
  color: 'color',
  icon: 'icon',
  popper: 'popper',
  component: 'component'
}

constants.acceptTypes = {
  document: ['pdf', 'xls', 'xlsx', 'xlsm', 'doc', 'docx', 'docm'],
  data: ['csv', 'dbx'],
  presentation: ['ppt', 'pptx', 'pptm'],
  image: ['png', 'jpg', 'jpeg'],
  message: ['vcf', 'eml', 'emlx', 'email', 'vcard', 'msg'],
  zip: ['p7z', 'zip', 'gz'],
  video: ['mov', 'mp4'],
  audio: ['mp3', 'wav']
}

constants.acceptTypes.all = Object.keys(constants.acceptTypes)
  .reduce((a, k) => a.concat(constants.acceptTypes[k]), []);

constants.filetypes = {
  default: 'text/plain'
}

constants.palettes = {
  light: 'light',
  dark: 'dark'
}

constants.filters = {
  system: {
    custom: true,
    switch: true
  }
}

constants.query = {
  sourceTypes: {
    crunchbase: 'crunchbase',
    patents: 'patents',
    github: 'github',
    other: 'other'
  },
  partTypes: {
    if: 'IF',
    and: 'AND',
    or: 'OR'
  },
  termFunctors: {
    or: 'OR',
    and: 'AND',
    andNot: 'AND_NOT',
    orNot: 'OR_NOT'
  },
  filterOperators: {
    is: 'IS',
    notIs: 'NOT_IS',
    contains: 'CONTAINS',
    notContains: 'NOT_CONTAINS',
    containsAll: 'CONTAINS_ALL',
    notContainsAll: 'NOT_CONTAINS_ALL',
    gt: 'GT',
    gte: 'GTE',
    lt: 'LT',
    lte: 'LTE',
    empty: 'EMPTY',
    notEmpty: 'NOT_EMPTY',
    between: 'BETWEEN',
    notBetween: 'NOT_BETWEEN'
  }
}

constants.upload = {
  fieldDefinition: {
    marketCap: {
      label: 'Market capitalization'
    },
    relevancy: {
      label: 'Relevance'
    },
    dealflowStatus: {
      type: constants.formFieldTypes.text
    },
    dealflowStatusCreatedAt: {
      type: constants.formFieldTypes.date,
      conversion: constants.formFieldConversionTypes.date
    },
    dealLeader: {
      type: constants.formFieldTypes.text
    },
    dealLeaderCreatedAt: {
      type: constants.formFieldTypes.date,
      conversion: constants.formFieldConversionTypes.date
    },
    commentCreatedAt: {
      type: constants.formFieldTypes.date,
      conversion: constants.formFieldConversionTypes.date
    },
    financeHistory: {
      type: constants.formFieldTypes.text
    }
  }
}

constants.sources = {
  types: {
    collection: 'Collection',
    curatedList: 'CuratedList',
    cordis: 'Cordis',
    google: 'Google',
    website: 'Website',
    twitter: 'Twitter',
    webFeed: 'WebFeed',
    fileUpload: 'FileUpload',
    database: 'Database',
    suggestions: 'Suggestions',
    places: 'Places',
    email: 'Email',
  },
  subTypes: {
    rss: 'rss',
    reddit: 'reddit',
    guardian: 'guardian',
    nyt: 'nyt'
  },
  filterTypes: {
    extend: 'extend',
    override: 'override'
  }
}

constants.services = {
  types: {
    autotags: 'autotags',
    linkedinEnrichment: 'linkedinEnrichment',
    companyInfoEnrichment: 'companyInfoEnrichment',
    patentEnrichment: 'patentEnrichment',
    crunchbaseEnrichment: 'crunchbaseEnrichment',
    traction: 'traction'
  },
  periods: {
    never: 'never',
    once: 'once',
    month: '1 month',
    months3: '3 months',
    months6: '6 months',
    year: '1 year'
  }
}

constants.upload.fieldDefinition.lookup = (name) => {
  return constants.upload.fieldDefinition?.[name];
};

constants.query.filterOperatorGroups = {
  static: [
    constants.query.filterOperators.is,
    constants.query.filterOperators.notIs,
    constants.query.filterOperators.empty,
    constants.query.filterOperators.notEmpty
  ],
  terms: [
    constants.query.filterOperators.contains,
    constants.query.filterOperators.notContains,
    constants.query.filterOperators.containsAll,
    constants.query.filterOperators.notContainsAll
  ],
  dynamic: [
    constants.query.filterOperators.contains,
    constants.query.filterOperators.notContains,
    constants.query.filterOperators.containsAll,
    constants.query.filterOperators.notContainsAll,
    constants.query.filterOperators.empty,
    constants.query.filterOperators.notEmpty
  ],
  range: [
    constants.query.filterOperators.gte,
    constants.query.filterOperators.gt,
    constants.query.filterOperators.lte,
    constants.query.filterOperators.lt,
    constants.query.filterOperators.between,
    constants.query.filterOperators.notBetween,
    constants.query.filterOperators.empty,
    constants.query.filterOperators.notEmpty
  ]
}

constants.client = {
  groupDefinition: {
    profile: {
      position: 1,
      title: 'Profile',
      fields: {
        name: {
          label: 'Name',
          required: true
        },
        plan: {
          label: 'Plan',
          path: 'props',
          required: true
        },
        currency: {
          label: 'Currency',
          path: 'props'
        },
        description: {
          label: 'Description'
        }
      }
    },
    budget: {
      position: 2,
      title: 'Budget',
      anchor: 'right',
      fields: {
        userLimit: {
          label: 'Seats',
          path: 'props',
          format: constants.formFieldFormatTypes.int,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          required: true
        },
        monthlyCreditLimit: {
          label: 'Credits',
          path: 'props',
          format: constants.formFieldFormatTypes.int,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          required: true
        },
        dailyExportLimit: {
          label: 'Daily export limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        monthlyExportLimit: {
          label: 'Monthly export limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        dailyGoogleLimit: {
          label: 'Daily google limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        monthlyChatGptLimit: {
          label: 'Monthly ChatGPT limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        monthlyNebulaLimit: {
          label: 'Monthly nebula limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        monthlyBrightDataLimit: {
          label: 'Monthly bright data limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        },
        monthlyCompanyInfoLimit: {
          label: 'Monthly company.info limit',
          path: 'props',
          type: constants.formFieldTypes.text,
          validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.positive],
          conversion: constants.formFieldConversionTypes.int,
          format: constants.formFieldFormatTypes.int,
          required: true
        }
      }
    },
    features: {
      position: 3,
      title: 'Features',
      anchor: 'right',
      fields: {
        hasVersion2: {
          label: 'Version 2',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasTeams: {
          label: 'Open teams',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasPrivateTeams: {
          label: 'Private teams',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasUserManagement: {
          label: 'User management',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasSearch: {
          label: 'Search database',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasPatents: {
          label: 'Patent data',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasCrunchbase: {
          label: 'Crunchbase data',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasCrunchbaseExport: {
          label: 'Export crunchbase data',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasAnalytics: {
          label: 'Basic analytics',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasAnalyticsT2: {
          label: 'Expert analytics',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasGptAnalyser: {
          label: 'GPT analyser',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasQuestionnaires: {
          label: 'Questionnaires',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasTraction: {
          label: 'Traction history',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        canScrapeWebsites: {
          label: 'Allow scraping',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        bulkUpdateMetrics: {
          label: 'Allow metrics',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasLiveData: {
          label: 'Live data',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasPortal: {
          label: 'Online portal',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasFindWebsiteLinks: {
          label: 'Url finder',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasFindLinkedinLinks: {
          label: 'LinkedIn finder',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasFindCocNumbers: {
          label: 'CoC number finder',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasEnrichProfiles: {
          label: 'Enrich profiles',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasEnrichCompanyInfoProfiles: {
          label: 'Enrich profiles with company.info',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        hasNotifications: {
          label: 'Notifications',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        },
        proxyCanBeDealLeader: {
          label: 'Collaborator as deal leader',
          path: 'props',
          variant: 'options',
          type: constants.formFieldTypes.switch
        }
      }
    },
    collaborators: {
      position: 5,
      title: 'Collaborators',
      anchor: 'left'
    },
    plan: {
    }
  },
  plan: {
    types: {
      custom: 'custom',
      basic: 'basic',
      intermediate: 'intermediate',
      advanced: 'advanced',
    }
  },
  budget: {
    types: {
      general: 'general',
      sources: 'sources',
      enrichment: 'enrichment',
      analysis: 'analysis',
      export: 'export'
    }
  },
  user: {
    allUsers: {
      userId: 0,
      profile: {
        userId: 0,
        firstName: 'All users',
        lastName: '',
        username: 'user'
      }
    }
  },
  team: {
    allMembers: {
      userId: 0,
      profile: {
        userId: 0,
        firstName: 'All team members',
        lastName: '',
        username: 'team'
      }
    }
  },
  relevancyDistributionType: {
    teamVsExternal: 'teamVsExternal',
    teamMembers: 'teamMembers'
  }
}

// plan fields = property fields
constants.client.groupDefinition.plan.fields = {...constants.client.groupDefinition.budget.fields, ...constants.client.groupDefinition.features.fields};

constants.dealflow = {
  entity: {
    groupDefinition: {
      companyInfo: {
        title: 'Company info',
        anchor: 'left',
        position: (2 * constants.numbers.position.groupGap),
        tablePosition: (2 * constants.numbers.position.groupGap),
        fields: {}
      },
      relevancy: { // add group for columns
        title: 'Relevance',
        position: (5 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (5 * constants.numbers.position.groupGap), // see same entity position
        fields: {
          personalRelevancy: {
            position: 1
          },
          teamRelevancy: {
            position: 2
          },
          externalRelevancy: {
            position: 3
          },
        }
      },
      dealflow: { // add group for columns
        title: 'Dealflow',
        position: (6 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (6 * constants.numbers.position.groupGap), // see same entity position
        fields: {
          dealflowStatus: {
            position: 1
          },
          dealLeader: {
            position: 2
          },
          dealflowAdded: {
            position: 3
          },
          dealflowChanged: {
            position: 4
          }
        }
      }
    },
    columnDefinition: {
      dealflowStatus: {
        visible: true
      },
      dealLeader: {
        visible: true
      },
      dealflowAdded: {
        visible: true
      },
      dealflowChanged: {
        visible: true
      },
      employees: {
        visible: false
      },
      exactFte: {
        visible: false
      },
      totalFunding: {
        visible: false
      },
      dateFounded: {
        visible: false
      }
    },
    filterGroupDefinition: {},
    sectionDefinition: {},
    graphDefinition: {
      breakdown: {},
      monthlyBreakdown: {},
      development: {},
      tasks: {},
      openTasks: {},
      rundown: {}
    },
    presetDefinition: {
      browse: {
        title: 'Browse',
        position: (1 * constants.numbers.position.presetGap),
        columns: {
          dateFounded: false,
          employees: false,
          exactFte: false,
          totalFunding: false,
          teamRelevancy: false,
          personalRelevancy: false,
          externalRelevancy: false,
          dealflowStatus: true,
          dealLeader: true,
          dealflowAdded: true,
          dealflowChanged: true
        }
      }
    },
    view: {
      entity: {
        position: numbers.maxInt,
        title: 'you are viewing the deal flow in custom company view'
      },
      task: {
        title: 'you are viewing the deal flow in custom task view'
      },
      dealflow: {
        title: 'you are viewing the deal flow in custom breakdown view',
      },
      dealflowMonthly: {
        title: 'you are viewing the deal flow in custom monthly breakdown view',
      },
      dealflowDevelopment: {
        title: 'you are viewing the deal flow in custom development view',
      },
      dealflowRundown: {
        title: 'you are viewing the deal flow in custom rundown view',
      }
    }
  }
};

constants.database = {
  entity: {
    groupDefinition: {
      activity: {
        title: 'Activity',
        position: (2 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (2 * constants.numbers.position.groupGap),
        fields: {
          collections: {
            position: 6
          }
        }
      }
    },
    columnDefinition: {
      collections: {
        visible: true
      }
    },
    filterGroupDefinition: {
      basics: {
        title: 'Basics',
        position: 0,
        filters: {
          profile: {
            position: 1,
            label: 'Profile',
            filterKey: 'terms',
            operators: constants.query.filterOperatorGroups.terms
          }
        }
      },
      dealflow: {
        filters: {
          dealflowAdded: {
            hidden: true
          },
          dealflowChanged: {
            hidden: true
          }
        }
      },
      discover: {
        filters: {
          dateFounded: {
            options: 'years',
            filterKey: 'date_founded',
            position: (7 * constants.numbers.position.filterGap),
            operators: constants.query.filterOperatorGroups.range
          },
          employees: {
            options: '',
            filterKey: 'employees',
            position: (8 * constants.numbers.position.filterGap),
            type: constants.formFieldTypes.text,
            format: constants.formFieldFormatTypes.int,
            validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.int,
            operators: constants.query.filterOperatorGroups.range
          },
          totalFunding: {
            options: 'currencyConversions',
            filterKey: 'total_funding',
            renderer: constants.fieldRenderers.monetary,
            position: (11 * constants.numbers.position.filterGap),
            type: constants.formFieldTypes.autocomplete,
            format: constants.formFieldFormatTypes.int,
            validation: constants.formFieldValidationTypes.int,
            conversion: constants.formFieldConversionTypes.value,
            operators: constants.query.filterOperatorGroups.range
          },
          lastFundingAmount: {
            options: 'currencyConversions',
            filterKey: 'last_funding_amount',
            position: (12 * constants.numbers.position.filterGap),
            renderer: constants.fieldRenderers.monetary,
            type: constants.formFieldTypes.autocomplete,
            format: constants.formFieldFormatTypes.int,
            validation: constants.formFieldValidationTypes.int,
            conversion: constants.formFieldConversionTypes.value,
            operators: constants.query.filterOperatorGroups.range
          },
          cocNumber: {
            filterKey: 'coc_number',
            position: (14 * constants.numbers.position.filterGap) + 1,
            operators: constants.query.filterOperatorGroups.static
          },
          links: {
            position: (15 * constants.numbers.position.filterGap) + 1,
            operators: constants.query.filterOperatorGroups.dynamic
          },
          sources: {
            position: (15 * constants.numbers.position.filterGap) + 2,
            operators: constants.query.filterOperatorGroups.terms
          },
          collections: {
            position: (15 * constants.numbers.position.filterGap) + 3,
            operators: constants.query.filterOperatorGroups.terms
          },
          tags: {
            position: (15 * constants.numbers.position.filterGap) + 4,
            operators: constants.query.filterOperatorGroups.terms
          },
          cpc: {
            position: (15 * constants.numbers.position.filterGap) + 5,
            operators: constants.query.filterOperatorGroups.terms
          },
          ipc: {
            position: (15 * constants.numbers.position.filterGap) + 6,
            operators: constants.query.filterOperatorGroups.terms
          },
          keywords: {
            position: (15 * constants.numbers.position.filterGap) + 7,
            operators: constants.query.filterOperatorGroups.terms
          }
        }
      },
      client: {
        filters: {
          clientTags: {
            hidden: true
          },
          clientAnalysisSimilarity: {
            hidden: true
          },
          clientAnalysisPointsAdvanced: {
            hidden: true
          },
          clientAnalysisScoreAdvanced: {
            hidden: true
          }
        }
      },
      traction: {
        filters: {
          firstActivity: {
            options: 'years',
            filterKey: 'first_activity',
            operators: constants.query.filterOperatorGroups.range
          },
          lastActivity: {
            options: 'years',
            filterKey: 'last_activity',
            operators: constants.query.filterOperatorGroups.range
          },
          twitterFollowers: {
            options: '',
            filterKey: 'twitter_followers',
            type: constants.formFieldTypes.text,
            format: constants.formFieldFormatTypes.int,
            validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.int,
            operators: constants.query.filterOperatorGroups.range
          },
          twitterLikes: {
            options: '',
            filterKey: 'twitter_likes',
            type: constants.formFieldTypes.text,
            format: constants.formFieldFormatTypes.int,
            validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.int,
            operators: constants.query.filterOperatorGroups.range
          },
          repoStars: {
            options: '',
            filterKey: 'repo_stars',
            type: constants.formFieldTypes.text,
            format: constants.formFieldFormatTypes.int,
            validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.int,
            operators: constants.query.filterOperatorGroups.range
          },
          repoForks: {
            options: '',
            filterKey: 'repo_forks',
            type: constants.formFieldTypes.text,
            format: constants.formFieldFormatTypes.int,
            validation: [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.int,
            operators: constants.query.filterOperatorGroups.range
          }
        }
      }
    },
    sectionDefinition: {},
    presetDefinition: {
      browse: {
        title: 'Browse',
        position: (1 * constants.numbers.position.presetGap),
        columns: {
          collections: true,
          personalRelevancy: false,
          teamRelevancy: false,
          externalRelevancy: false
        }
      },
      compare: {
        title: 'Compare',
        position: (2 * constants.numbers.position.presetGap),
        columns: {
          collections: true
        }
      },
      process: {
        title: 'Process',
        position: (3 * constants.numbers.position.presetGap),
        columns: {
          collections: true
        }
      }
    },
    view: {
      entity: {
        position: numbers.maxInt,
        title: 'you are viewing the database in custom company view'
      },
      task: {
        title: 'you are viewing the database in custom task view'
      }
    }
  }
};

constants.timeline = {
  locations: {
    database: 'database',
    dealflow: 'dealflow',
    collection: 'collection'
  }
}

constants.task = {
  types: {
    dealflow: 'dealflow',
    relevancy: 'relevancy',
    profile: 'profile',
    questionnaire: 'questionnaire',
    verifyLinks: 'verifyLinks',
    verifyCocNumber: 'verifyCocNumber',
    other: 'other'
  },
  collaborationTypes: {
    individual: 'individual',
    collective: 'collective'
  },
  locations: {
    database: 'database',
    dealflow: 'dealflow',
    collection: 'collection'
  },
  groupDefinition: {
    info: {
      title: 'Info',
      anchor: 'right',
      position: 1,
      fields: {
        location: {
          position: 1,
          label: 'Location',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.none,
          readOnly: true,
          FormFieldProps: {
            readOnlyChip: true,
            hiddenIcons: false,
            ChipProps: {
              variant: 'outlined',
              color: 'primary',
              size: 'medium'
            }
          }
        },
        responsible: {
          position: 2,
          label: 'Assigned to',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.none,
          options: 'members',
          required: true,
          readOnly: true,
          FormFieldProps: {
            multiple: true,
            showSearch: true,
            readOnlyChip: false
          }
        },
        comment: {
          position: 3,
          label: 'Comment',
          type: constants.formFieldTypes.markdown,
          validation: constants.formFieldValidationTypes.text,
          required: true
        },
        collaborationType: {
          position: 4,
          label: 'Type',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          options: 'taskCollaborationTypes',
          required: true
        },
        completion: {
          position: 5,
          label: 'Completion',
          type: constants.formFieldTypes.text,
          readOnly: true,
        },
        status: {
          position: 6,
          label: 'Status',
          type: constants.formFieldTypes.text,
          readOnly: true,
        },
        completedAt: {
          position: 7,
          label: 'Completed on',
          type: constants.formFieldTypes.date,
          conversion: constants.formFieldConversionTypes.date,
          readOnly: true
        },
        dueDate: {
          position: 8,
          label: 'Deadline',
          type: constants.formFieldTypes.date,
          validation: [constants.formFieldValidationTypes.date, constants.formFieldValidationTypes.notInThePast],
          conversion: constants.formFieldConversionTypes.date
        },
        createdBy: {
          position: 9,
          label: 'Created by',
          placeholder: 'Unknown author',
          type: constants.formFieldTypes.text,
          conversion: constants.formFieldConversionTypes.author,
          readOnly: true
        },
        createdAt: {
          position: 10,
          label: 'Created on',
          type: constants.formFieldTypes.date,
          conversion: constants.formFieldConversionTypes.date,
          readOnly: true
        }
      }
    },
    progress: {
      title: 'Progress',
      anchor: 'right',
      position: 2,
      fields: {}
    }
  },
  sectionDefinition: {
    profile: {
      title: 'Profile',
      variant: 'profile',
      position: 1,
      size: 'larger'
    }
  },
  view: {}
}

constants.collection = {
  groupDefinition: {
    basics: {
      title: 'Basics',
      anchor: 'right',
      position: 1,
      fields: {
        name: {
          position: 1,
          label: 'Name',
          type: constants.formFieldTypes.text,
          required: true
        },
        projectType: {
          position: 2,
          label: 'Goal',
          type: constants.formFieldTypes.autocomplete,
          conversion: constants.formFieldConversionTypes.value,
          options: 'collectionProjectTypes'
        },
        description: {
          position: 3,
          label: 'Description',
          placeholder: 'Describe this collection',
          type: constants.formFieldTypes.markdown,
          FormFieldProps: {
            minRows: 3,
            maxRows: 6
          }
        },
        rationale: {
          position: 4,
          label: 'Rationale',
          placeholder: 'Describe the reason behind this collection',
          type: constants.formFieldTypes.markdown,
          FormFieldProps: {
            minRows: 3,
            maxRows: 6
          }
        },
        projectTopics: {
          position: 5,
          label: 'Topics',
          description: 'Describe the companies you are looking for in tags',
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.label,
          format: constants.formFieldFormatTypes.lower,
          FormFieldProps: {
            multiple: true,
            createOption: true,
            openDirect: false,
            openOnFocus: false,
            hiddenLabel: false,
            TagProps: {
              variant: 'filled',
              color: 'primary'
            },
            ChipProps: {
              variant: 'filled',
              color: 'primary'
            },
            ChipListProps: {
              variant: 'standard'
            }
          }
        },
        visibility: {
          position: 6,
          label: 'Privacy',
          type: constants.formFieldTypes.autocomplete,
          conversion: constants.formFieldConversionTypes.value,
          options: 'collectionVisibility',
          required: true
        },
        author: {
          position: 7,
          label: 'Created by',
          placeholder: 'Unknown author',
          type: constants.formFieldTypes.text,
          conversion: constants.formFieldConversionTypes.author,
          readOnly: true
        },
        createdAt: {
          position: 8,
          label: 'Created on',
          type: constants.formFieldTypes.date,
          conversion: constants.formFieldConversionTypes.date,
          readOnly: true
        }
      }
    }
  },
  presetDefinition: {},
  columnDefinition: {
    name: {
      pinned: 'left'
    },
    favorite: {
      pinned: 'left'
    },
    entities: {
      header: 'Results'
    },
    labels: {
      header: 'Tags',
      entity: 'collection',
      visible: true,
      sortable: false,
      validation: `${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.label})`,
      FormFieldProps: {
        fullHeight: true,
        createOption: true,
        openOnFocus: false,
        sorted: true,
        ChipProps: {
          variant: 'outlined',
          color: 'secondary'
        }
      }
    },
    visibility: {},
    dealflow: {},
    actions: {}
  },
  filterGroupDefinition: {},
  sectionDefinition: {
    basics: {
      title: 'Basics',
      variant: 'standard',
      size: 'larger',
      position: 1
    },
    categories: {
      title: 'Categories',
      variant: 'standard',
      size: 'oversize',
      position: 2
    },
    sources: {
      title: 'Sources',
      variant: 'standard',
      size: 'oversize',
      position: 3
    },
    services: {
      title: 'Services',
      variant: 'standard',
      size: 'oversize',
      position: 4
    }
  },
  view: {},
  entity: {
    locations: {
      database: 'database',
      dealflow: 'dealflow',
      collection: 'collection'
    },
    groupDefinition: {
      activity: {
        title: 'Activity',
        position: (1 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (1 * constants.numbers.position.groupGap),
        fields: {
          feeds: {
            position: 7,
            readOnly: true,
            FormFieldProps: {
              multiple: true,
              hiddenIcons: false,
              ChipListProps: {
                variant: 'compact'
              },
              ChipProps: {
                variant: 'outlined',
                color: 'primary',
                size: 'medium',
                icon: Feed
              }
            }
          }
        }
      },
      companyInfo: { // add group for columns
        position: (2 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (2 * constants.numbers.position.groupGap), // see same entity position
        fields: {
          addedAt: {
            position: 2,
            hidden: true,
            readOnly: true
          }
        }
      },
      relevancy: { // add group for columns
        title: 'Relevance',
        position: (5 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (5 * constants.numbers.position.groupGap), // see same entity position
        fields: {
          personalRelevancy: {
            position: 1
          },
          teamRelevancy: {
            position: 2
          },
          externalRelevancy: {
            position: 3
          }
        }
      },
      dealflow: { // add group for columns
        title: 'Dealflow',
        position: (6 * constants.numbers.position.groupGap), // see same entity position
        tablePosition: (6 * constants.numbers.position.groupGap), // see same entity position
        fields: {
          dealflowStatus: {
            position: 1
          },
          dealLeader: {
            position: 2
          },
          dealflowAdded: {
            position: 3
          },
          dealflowChanged: {
            position: 4
          }
        }
      },
      collectionTags: {
        title: 'Collection categories',
        anchor: 'right',
        position: (6 * constants.numbers.position.groupGap) + 1,
        tablePosition: (7 * constants.numbers.position.groupGap),
        fields: {
          collectionAnalysisSimilarity: {
            position: 1,
            hidden: true
          },
          collectionAnalysisScoreGraph: {
            position: 2,
            variant: 'scoreGraph',
            has: ['points']
          },
          collectionAnalysisPointsAdvanced: {
            position: 3,
            hidden: true,
            has: ['points']
          },
          collectionAnalysisScoreAdvanced: {
            position: 4,
            hidden: true,
            has: ['points']
          }
        }
      },
      questionnaireAnswers: {
        title: 'Questionnaire answers',
        anchor: 'right',
        position: (9 * constants.numbers.position.groupGap) + 1,
        tablePosition: (9 * constants.numbers.position.groupGap)
      }
    },
    columnDefinition: {
      personalRelevancy: {
        header: 'Relevance',
        visible: true,
        sortable: true,
        sortingKey: 'relevancy_personal'
      },
      teamRelevancy: {
        header: 'Team relevance',
        visible: true,
        sortable: true,
        sortingKey: 'collection_relevancy_client',
      },
      externalRelevancy: {
        header: 'External relevance',
        visible: true,
        sortable: true,
        sortingKey: 'collection_relevancy_us',
      },
      addedAt: {
        header: 'Collection added',
        sortable: true,
        sortingKey: 'added_at',
        readOnly: true,
        hidden: false,
        visible: false,
        position: (15 * constants.numbers.position.columnGap) + 1,
        FormFieldProps: {
          timeAgo: true
        }
      },
      collectionAnalysisSimilarity: {
        header: 'Collection similarity',
        group: { // display tags in 1 group
          name: 'scoring',
          title: 'Scoring'
        },
        sortable: true,
        readOnly: true,
        visible: false,
        sortingKey: 'collection_analysis_similarity'
      },
      collectionAnalysisScoreGraph: {
        header: 'Collection score',
        group: { // display tags in 1 group
          name: 'scoring',
          title: 'Scoring'
        },
        readOnly: false,
        visible: true,
        sortingKey: 'collection_analysis_points_advanced|graph',
        has: ['points'],
      },
      collectionAnalysisPointsAdvanced: {
        header: 'Collection scoring points',
        group: { // display tags in 1 group
          name: 'scoring',
          title: 'Scoring'
        },
        sortable: true,
        readOnly: true,
        visible: false,
        sortingKey: 'collection_analysis_points_advanced',
        has: ['points']
      },
      collectionAnalysisScoreAdvanced: {
        header: 'Collection scoring confidence',
        group: { // display tags in 1 group
          name: 'scoring',
          title: 'Scoring'
        },
        sortable: true,
        readOnly: true,
        visible: false,
        sortingKey: 'collection_analysis_score_advanced',
        has: ['points']
      },
      dealflowStatus: {
        visible: false
      },
      dealLeader: {
        visible: false
      },
      feeds: {
        hidden: true
      },
      addRejectCollection: {
        header: 'Actions',
        hidden: true,
        sortable: false,
        position: constants.numbers.maxInt,
      },
    },
    filterGroupDefinition: {
      dealflow: {
        position: (1 * constants.numbers.position.groupGap), // see same position on entity * gap,
        filters: {
          teamRelevancy: {
            filterKey: 'collection_relevancy_client',
            position: (4 * constants.numbers.position.filterGap) + 1
          },
          externalRelevancy: {
            filterKey: 'collection_relevancy_us',
            position: (4 * constants.numbers.position.filterGap) + 2
          }
        }
      },
      collection: {
        title: 'Collection',
        position: (1 * constants.numbers.position.groupGap) + 1,
        filters: {
          collectionTags: {
            position: 1,
          },
          collectionAnalysisSimilarity: {
            label: 'Collection similarity',
            filterKey: 'collection_analysis_similarity',
            position: (1 * constants.numbers.position.filterGap) + 1,
            type: constants.formFieldTypes.list,
            conversion: constants.formFieldConversionTypes.value,
            validation: constants.formFieldValidationTypes.list,
            options: 'gtSmallRange',
            FormFieldProps: {
              multiple: true
            }
          },
          collectionAnalysisPointsAdvanced: {
            label: 'Collection scoring points',
            filterKey: 'collection_analysis_points_advanced',
            position: (1 * constants.numbers.position.filterGap) + 2,
            type: constants.formFieldTypes.list,
            conversion: constants.formFieldConversionTypes.value,
            validation: constants.formFieldValidationTypes.list,
            options: 'gtPointRange',
            has: ['points'],
            FormFieldProps: {
              multiple: true
            }
          },
          collectionAnalysisScoreAdvanced: {
            label: 'Collection scoring confidence',
            filterKey: 'collection_analysis_score_advanced',
            position: (1 * constants.numbers.position.filterGap) + 3,
            type: constants.formFieldTypes.list,
            conversion: constants.formFieldConversionTypes.value,
            validation: constants.formFieldValidationTypes.list,
            options: 'gtSmallRange',
            has: ['points'],
            FormFieldProps: {
              multiple: true
            }
          }
        }
      },
      discover: {
        position: (3 * constants.numbers.position.groupGap), // see same position on entity * gap
        filters: {
          addedAt: {
            label: 'Collection added',
            placeholder: 'Search month',
            filterKey: 'added_at',
            position: (15 * constants.numbers.position.filterGap) + 1,
            type: constants.formFieldTypes.list,
            conversion: constants.formFieldConversionTypes.value,
            validation: constants.formFieldValidationTypes.list,
            options: 'monthsFrom',
            FormFieldProps: {
              showSearch: true,
              multiple: true
            }
          },
          sources: {
            position: (16 * constants.numbers.position.filterGap) + 1,
            options: 'collectionSources'
          }
        }
      }
    },
    sectionDefinition: {
      options: {
        title: 'Options',
        variant: 'standard',
        position: (2 * constants.numbers.position.sectionGap),
        size: 'medium',
        cards: {
          tasks: {
            group: 2,
            position: (1 * constants.numbers.position.cardGap) + 1
          },
          personalRelevancy: {
            group: 3,
            position: (2 * constants.numbers.position.sectionGap) + 1
          },
          externalRelevancy: {
            group: 3,
            position: (2 * constants.numbers.position.sectionGap) + 1
          }
        }
      },
      questionnaire: {
        title: 'Questionnaire',
        variant: 'standard',
        position: (3 * constants.numbers.position.sectionGap) + 1,
        size: 'medium'
      }
    },
    graphDefinition: {
      dealflowBreakdown: {},
      completeness: {},
      recentChanges: {},
      tagDistribution: {},
      relevancyDistribution: {},
      tasks: {},
      openTasks: {}
    },
    presetDefinition: {
      browse: {
        title: 'Browse',
        position: (1 * constants.numbers.position.presetGap),
        columns: {
          collectionTags: true
        }
      },
      compare: {
        title: 'Compare',
        position: (2 * constants.numbers.position.presetGap),
        columns: {
          collectionTags: true
        }
      },
      process: {
        title: 'Process',
        position: (3 * constants.numbers.position.presetGap),
        columns: {
          collectionTags: true
        }
      }
    },
    view: {
      entity: {
        position: numbers.maxInt,
        title: 'you are viewing this collection in custom company view'
      },
      task: {
        title: 'you are viewing this collection in custom task view'
      },
      dealflow: {
        title: 'you are viewing this collection in custom dealflow view'
      },
      completeness: {
        title: 'you are viewing this collection in custom completeness view'
      },
      recentChanges: {
        title: 'you are viewing this collection in custom recent changes view'
      },
      tag: {
        title: 'you are viewing this collection in custom category view',
        filterGroupDefinition: {
          discover: {
            filters: {
              $tagView: {
                hidden: true,
                filterKey: (view) => {
                  const tagView = view?.activeViews?.find((v) => v.name === 'tag');
                  return `group_${tagView?.params?.[0]}`;
                },
                filtered: (view) => {
                  const tagView = view?.activeViews?.find((v) => v.name === 'tag');
                  return tagView?.params?.[1];
                }
              }
            }
          }
        }
      },
      relevancy: {
        title: 'you are viewing this collection in custom relevance view',
        columnDefinition: {
          employees: {
            visible: false
          },
          exactFte: {
            visible: false
          },
          totalFunding: {
            visible: false
          },
          dateFounded: {
            visible: false
          }
        }
      },
      questionnaire: {
        title: 'you are viewing this collection in custom questionnaire view',
        sectionDefinition: {
          questionnaire: {
            position: 1
          }
        }
      },
      suggestions: {
        title: 'you are viewing this collection in custom suggestions view',
        defaults: {
          list: {
            sort: [
              {id: 'collection_analysis_similarity', desc: true},
              {id: 'collection_analysis_points_normalized', desc: true},
              {id: 'added_at', desc: true}
            ]
          }
        },
        columnDefinition: {
          addRejectCollection: {
            hidden: false,
            visible: true,
            pinned: 'left'
          },
          collectionAnalysisSimilarity: {
            hidden: false,
            visible: true
          }
        },
        filterGroupDefinition: {
          discover: {
            filters: {
              $suggestionsView: {
                hidden: true,
                filterKey: 'scope',
                filtered: 'outside'
              }
            }
          }
        }
      },
      rejections: {
        title: 'you are viewing this collection in custom rejection view',
        defaults: {
          list: {
            sort: [
              {id: 'added_at', desc: true}
            ]
          }
        },
        filterGroupDefinition: {
          discover: {
            filters: {
              $rejectionsView: {
                hidden: true,
                filterKey: 'scope',
                filtered: 'rejected'
              }
            }
          }
        }
      },
    }
  },
  scopes: {
    inside: 'inside',
    outside: 'outside',
    rejected: 'rejected',
    all: ['inside', 'outside', 'rejected']
  },
  visibility: {
    user: 'user',
    client: 'client',
    public: 'public'
  },
  questionTypes: {
    basic: 'basic',
    advanced: 'advanced'
  },
  analyserTypes: {
    collectionTagAnalyser: 'collection_tag_analyser',
    manualAnalyser: 'manual_analyser',
    justPointsAnalyser: 'just_points_analyser',
    fieldAnalyser: 'field_analyser',
    keywordAnalyser: 'keyword_analyser',
    questionnaireAnalyser: 'questionnaire_analyser',
    gptAnalyser: 'gpt_analyser'
  },
  autoTagTypes: {
    noAutoAssign: 'just_points_analyser',
    byKeywords: 'keyword_analyser',
    byAI: 'gpt_analyser'
  },
  suggestion: {
    max: 1000,
    threshold: null // todo: think of something, was: 50
  }
}

constants.entity = {
  groupDefinition: {
    systemInfo: {
      title: null,
      position: 0,
      span: 2,
      readOnly: true,
      locked: true, // locked for custom fields
      fields: {
        availableSources: {
          position: 1,
          readOnly: true,
          variant: 'systemInfo'
        }
      }
    },
    activity: {
      title: 'Activity',
      position: 1,
      tablePosition: 1,
      hidden: true,
      locked: true, // locked for custom fields
      fields: {
        tasks: {
          position: 1
        },
        timeline: {
          position: 2
        },
        collections: {
          position: 3,
          readOnly: true,
          FormFieldProps: {
            multiple: true,
            hiddenIcons: false,
            readOnlyAction: (v) => ({
              navigation: {
                to: `/collections/${v.value}/entities`
              }
            }),
            ChipListProps: {
              variant: 'compact'
            },
            ChipProps: {
              variant: 'outlined',
              color: 'primary',
              size: 'medium',
              icon: FolderSharp,
              selected: false
            }
          }
        },
        sources: {
          position: 4,
          readOnly: true,
          FormFieldProps: {
            multiple: true,
            hiddenIcons: false,
            ChipListProps: {
              variant: 'compact'
            },
            ChipProps: {
              variant: 'outlined',
              color: 'warning',
              size: 'medium',
              icon: Source
            }
          }
        }
      }
    },
    companyInfo: {
      title: 'Company info',
      anchor: 'left',
      position: 2,
      tablePosition: 2,
      fields: {
        oneliner: {
          position: 1,
          variant: 'summary'
        },
        name: {
          position: 2,
          required: true
        },
        location: {
          position: 3
        },
        type: {
          position: 4
        },
        dateFounded: {
          position: 5
        },
        employees: {
          position: 6
        },
        exactFte: {
          position: 7
        },
        businessModel: {
          position: 8
        },
        isBankrupt: {
          conversion: constants.formFieldConversionTypes.invert
        },
        industry: {
        },
        generalScore: {
          hidden: true
        },
        financialScore: {
          hidden: true
        },
        technologyScore: {
          hidden: true
        },
        hasPatents: {
          position: constants.numbers.position.groupGap - 1,
          readOnly: true,
          hidden: true
        }
      }
    },
    description: {
      title: 'Description',
      anchor: 'right',
      position: 3,
      tablePosition: 11,
      fields: {
        description: {
          position: 1,
          variant: 'summary'
        }
      },
    },
    technologyDescription: {
      title: 'Technology description',
      anchor: 'right',
      position: 4,
      tablePosition: 12,
      fields: {
        technologyDescription: {
          position: 1,
          variant: 'summary'
        }
      },
    },
    links: {
      title: 'Links',
      anchor: 'left',
      position: 4,
      tablePosition: 15,
      fields: {
        links: {
          hidden: true
        }
      }
    },
    relevancy: {
      title: 'Relevance',
      position: 5,
      tablePosition: 5,
      hidden: true,
      fields: {
        personalRelevancy: {
          position: 1
        },
        teamRelevancy: {
          position: 2
        },
        externalRelevancy: {
          position: 3
        }
      }
    },
    dealflow: {
      title: 'Dealflow',
      position: 6,
      tablePosition: 6,
      hidden: true,
      fields: {
        dealflowStatus: {
          position: 1
        },
        dealLeader: {
          position: 2
        },
        dealflowAdded: {
          position: 3,
          readOnly: true
        },
        dealflowChanged: {
          position: 4,
          readOnly: true
        }
      }
    },
    tags: {
      title: 'Your tags',
      anchor: 'right',
      position: 8,
      tablePosition: 9,
      fields: {
        tags: {
          position: 1,
          variant: 'tags',
          validation: `${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.tag})`,
          format: constants.formFieldFormatTypes.lower,
          FormFieldProps: {
            fullHeight: true,
            createOption: true,
            sorted: true,
            ChipProps: {
              variant: 'outlined',
              color: 'info'
            }
          }
        }
      }
    },
    systemTags: {
      title: 'External tags',
      anchor: 'right',
      position: 9,
      tablePosition: 10,
      fields: {
        systemTags: {
          position: 1,
          variant: 'tags',
          validation: `${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.tag})`,
          format: constants.formFieldFormatTypes.lower,
          FormFieldProps: {
            fullHeight: true,
            createOption: true,
            sorted: true,
            ChipProps: {
              variant: 'outlined',
              color: 'secondary'
            }
          }
        }
      }
    },
    traction: {
      title: 'Traction',
      anchor: 'left',
      position: 3,
      tablePosition: 4,
      fields: {
        tractionGraph: {
          position: 1,
          variant: 'tractionGraph',
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        },
        tractionFte3M: {
          label: 'FTE 3M',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        },
        tractionFte6M: {
          label: 'FTE 6M',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        },
        tractionFte1Y: {
          label: 'FTE 1Y',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        },
        tractionFte3Y: {
          label: 'FTE 3Y',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        },
        tractionFunding3M: {
          label: 'Funding 3M',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.int
        },
        tractionFunding6M: {
          label: 'Funding 6M',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.int
        },
        tractionFunding1Y: {
          label: 'Funding 1Y',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.int
        },
        tractionFunding3Y: {
          label: 'Funding 3Y',
          hidden: true,
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.int
        }
      }
    },
    clientTags: {
      title: 'Client categories',
      anchor: 'right',
      position: 7,
      tablePosition: 8,
      fields: {
        clientAnalysisSimilarity: {
          position: 1,
          hidden: true
        },
        clientAnalysisScoreGraph: {
          position: 2,
          variant: 'scoreGraph',
          has: ['points']
        },
        clientAnalysisPointsAdvanced: {
          position: 3,
          hidden: true,
          has: ['points']
        },
        clientAnalysisScoreAdvanced: {
          position: 4,
          hidden: true,
          has: ['points']
        }
      }
    },
    patents: {
      title: 'Patents',
      anchor: 'right',
      position: 10,
      tablePosition: 16,
      fields: {
        patentGraph: {
          position: 1,
          variant: 'patentGraph',
          readOnly: true,
          traction: true,
          type: constants.formFieldTypes.text,
          format: constants.formFieldFormatTypes.percentageInt
        }
      }
    },
    funding: {
      title: 'Funding',
      anchor: 'left',
      position: 10,
      tablePosition: 3,
      fields: {
        tickerSymbol: {
          position: 1
        },
        marketCap: {
          position: 2
        }
      }
    },
    finance: {
      title: 'Finance',
      anchor: 'left',
      position: 11,
      tablePosition: 13,
      fields: {
        currency: {
          position: 1,
          hidden: true
        },
        financeHistory: {
          position: 2,
          variant: 'finance'
        },
        totalAssets: {
          hidden: true
        },
        operatingIncome: {
          hidden: true
        },
        revenue: {
          hidden: true
        },
        financeHistoryRevenue: {
          hidden: true
        },
        financeHistoryOperatingIncome: {
          hidden: true
        },
        financeHistoryNetProfit: {
          hidden: true
        },
        financeHistoryEbitda: {
          hidden: true
        },
        financeHistoryEbit: {
          hidden: true
        },
        financeHistoryTotalAssets: {
          hidden: true
        },
        financeHistoryTotalDebt: {
          hidden: true
        }
      }
    },
    financialDescription: {
      title: 'Financial description',
      anchor: 'left',
      position: 12,
      tablePosition: 14,
      fields: {
        financialDescription: {
          position: 1,
          variant: 'summary'
        }
      },
    },
    moreAboutCompany: {
      title: 'More about company',
      anchor: 'right',
      position: 14,
      tablePosition: 16,
      fields: {
        legalName: {
          position: 1
        },
        cocNumber: {
          position: 2
        }
      }
    },
    system: {
      hidden: true,
      fields: {
        csep: {
          hidden: true
        },
        logoUrl: {
          hidden: true
        },
        hideLogo: {
          hidden: true
        },
        websiteScreenshots: {
          hidden: true
        }
      }
    }
  },
  columnDefinition: {
    // Company info
    name: {
      header: 'Company name',
      position: 1,
      visible: true,
      sortable: true,
      required: true,
      pinned: 'left'
    },
    tasks: {
      header: 'Tasks',
      sortable: true,
      sortingKey: 'tasks',
      visible: true,
      pinned: 'right',
      position: 1
    },
    timeline: {
      header: 'Timeline',
      sortable: true,
      sortingKey: 'total_comments',
      visible: true,
      pinned: 'right',
      position: 2
    },
    oneliner: {
      visible: true,
      hidden: false,
      FormFieldProps: {
        showTooltip: true
      }
    },
    type: {
      sortable: true,
      visible: false,
      sortingKey: 'eval_type'
    },
    businessModel: {
      visible: false,
      sortable: true
    },
    industry: {
      visible: false,
      sortable: true,
      FormFieldProps: {
        minWidth: 380
      }
    },
    totalFunding: {
      visible: true,
      sortable: true
    },
    fundingStatus: {
      visible: false,
      sortable: true
    },
    fundingRoundsNumber: {
      visible: false,
      sortable: true
    },
    lastFundingRound: {
      visible: false,
      sortable: true
    },
    lastFundingAmount: {
      visible: false,
      sortable: true
    },
    requiredCapital: {
      visible: false,
      sortable: true
    },
    location: {
      visible: true,
      sortable: true,
      sortingKey: 'country'
    },
    dateFounded: {
      header: 'Founding year',
      sortable: true,
      visible: true,
      sortingKey: 'date_founded'
    },
    employees: {
      visible: true,
      sortable: true
    },
    exactFte: {
      visible: true,
      sortable: true
    },
    hasPatents: {
      header: 'Has patents',
      visible: false,
      sortable: true,
      sortingKey: 'has_patents'
    },
    // Description
    financialDescription: {
      hidden: true,
      FormFieldProps: {
        showTooltip: true
      }
    },
    description: {
      hidden: true,
      FormFieldProps: {
        showTooltip: true
      }
    },
    technologyDescription: {
      hidden: true,
      FormFieldProps: {
        showTooltip: true
      }
    },
    // Tags / relevance / status
    systemTags: {
      visible: false,
      sortable: false,
      group: { // display tags in 1 group
        name: 'tags',
        title: 'Tags'
      },
      FormFieldProps: {
        fullHeight: true,
        createOption: true,
        openOnFocus: false,
        sorted: true,
        ChipProps: {
          variant: 'outlined',
          color: 'secondary'
        }
      }
    },
    tags: {
      visible: false,
      sortable: false,
      group: { // display tags in 1 group
        name: 'tags',
        title: 'Tags'
      },
      FormFieldProps: {
        fullHeight: true,
        createOption: true,
        openOnFocus: false,
        sorted: true,
        ChipProps: {
          variant: 'outlined',
          color: 'info'
        }
      }
    },
    collections: {
      header: 'Collections',
      visible: false,
      sortable: false,
      readOnly: true
    },
    sources: {
      header: 'Sources',
      visible: false,
      sortable: false,
      readOnly: true
    },
    personalRelevancy: {
      header: 'Relevance',
      visible: false,
      sortable: true,
      sortingKey: 'relevancy_personal',
    },
    teamRelevancy: {
      header: 'Team relevance',
      visible: false,
      sortable: true,
      sortingKey: 'relevancy_client',
    },
    externalRelevancy: {
      header: 'External relevance',
      visible: false,
      sortable: true,
      sortingKey: 'relevancy_us',
    },
    dealflowStatus: {
      header: 'Deal flow status',
      visible: false,
      sortable: true,
      sortingKey: 'status'
    },
    dealLeader: {
      header: 'Deal leader',
      visible: false,
      sortable: true,
      sortingKey: 'deal_leader'
    },
    dealflowAdded: {
      header: 'Dealflow added',
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'dealflow_added',
      FormFieldProps: {
        timeAgo: true
      }
    },
    dealflowChanged: {
      header: 'Dealflow changed',
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'dealflow_changed',
      FormFieldProps: {
        timeAgo: true
      }
    },
    tractionFte3M: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_fte_3m'
    },
    tractionFte6M: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_fte_6m'
    },
    tractionFte1Y: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_fte_1y'
    },
    tractionFte3Y: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_fte_3y'
    },
    tractionFunding3M: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_funding_3m'
    },
    tractionFunding6M: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_funding_6m'
    },
    tractionFunding1Y: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_funding_1y'
    },
    tractionFunding3Y: {
      visible: false,
      sortable: true,
      readOnly: true,
      sortingKey: 'traction_funding_3y'
    },
    financeHistoryRevenue: {
      header: 'Revenue',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_revenue',
    },
    financeHistoryOperatingIncome: {
      header: 'Operating income',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_operating_income',
    },
    financeHistoryNetProfit: {
      header: 'Net profit',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_net_profit',
    },
    financeHistoryEbitda: {
      header: 'EBITDA',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_ebitda',
    },
    financeHistoryEbit: {
      header: 'EBIT',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_ebit',
    },
    financeHistoryTotalAssets: {
      header: 'Total assets',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_total_assets',
    },
    financeHistoryTotalDebt: {
      header: 'Total debt',
      visible: false,
      readOnly: true,
      sortable: true,
      sortingKey: 'finance_history_total_debt',
    },
    clientAnalysisSimilarity: {
      header: 'Client similarity',
      group: { // display tags in 1 group
        name: 'scoring',
        title: 'Scoring'
      },
      sortable: true,
      readOnly: true,
      visible: false,
      sortingKey: 'client_analysis_similarity'
    },
    clientAnalysisScoreGraph: {
      header: 'Client score',
      group: { // display tags in 1 group
        name: 'scoring',
        title: 'Scoring'
      },
      readOnly: false,
      visible: true,
      sortingKey: 'client_analysis_score_advanced|graph',
      has: ['points']
    },
    clientAnalysisPointsAdvanced: {
      header: 'Client scoring points',
      group: { // display tags in 1 group
        name: 'scoring',
        title: 'Scoring'
      },
      sortable: true,
      readOnly: true,
      visible: false,
      sortingKey: 'client_analysis_points_advanced',
      has: ['points']
    },
    clientAnalysisScoreAdvanced: {
      header: 'Client scoring confidence',
      group: { // display tags in 1 group
        name: 'scoring',
        title: 'Scoring'
      },
      sortable: true,
      readOnly: true,
      visible: false,
      sortingKey: 'client_analysis_score_advanced',
      has: ['points']
    },
    repoStars: {
      header: 'Repository forks',
      sortable: true
    },
    repoForks: {
      header: 'Repository stars',
      sortable: true
    },
    repoMembers: {
      header: 'Repository members'
    },
    repoCreatedAt: {
      header: 'Repository creation date',
    },
    repoWebsiteDate: {
      header: 'Repository website date',
    },
    repoLastActivity: {
      header: 'Repository last activity',
    },
    twitterListed: {
      header: 'X listed'
    },
    twitterLastActivity: {
      header: 'X last activity'
    },
    lastTweet: {
      header: 'X last post'
    },
    twitterLikes: {
      header: 'X likes',
      sortable: true
    },
    twitterWebsiteDate: {
      header: 'X website date'
    },
    twitterCreatedAt: {
      header: 'X created at'
    },
    twitterTweets: {
      header: 'X posts'
    },
    twitterFollowing: {
      header: 'X following'
    },
    twitterFollowers: {
      header: 'X followers',
      sortable: true
    },
    cocNumber: {
      header: 'CoC number',
      sortable: true
    },
    links: {
      hidden: true
    },
    isBankrupt: {
      type: constants.formFieldTypes.list,
      conversion: constants.formFieldConversionTypes.value,
      options: 'toggleYesNoInverted'
    },
    generalScore: {
      hidden: true
    },
    financialScore: {
      hidden: true
    },
    technologyScore: {
      hidden: true
    },
    currency: {
      hidden: true
    },
    financeHistory: {
      hidden: true
    },
    logoUrl: {
      hidden: true
    },
    articles: {
      hidden: true
    },
    hideLogo: {
      hidden: true
    },
    websiteScreenshots: {
      hidden: true
    },
    ipc: {
      hidden: true
    },
    cpc: {
      hidden: true
    },
    csep: {
      hidden: true
    },
    keywordsUrl: {
      hidden: true
    },
    patentRoot: {
      hidden: true
    },
    keywordsRare: {
      hidden: true
    },
    keywords: {
      hidden: true
    },
    suggestedCountry: {
      hidden: true
    },
  },
  filterGroupDefinition: {
    dealflow: {
      title: 'Dealflow',
      position: 1,
      filters: {
        dealflowStatus: {
          label: 'Deal flow status',
          placeholder: 'Search status',
          filterKey: 'status',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'dealflowStatuses',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        dealflowAdded: {
          label: 'Dealflow added',
          placeholder: 'Search month',
          filterKey: 'dealflow_added',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'monthsFrom',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        dealflowChanged: {
          label: 'Dealflow changed',
          placeholder: 'Search month',
          filterKey: 'dealflow_changed',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'monthsFrom',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        dealLeader: {
          label: 'Deal leader',
          placeholder: 'Search leader',
          filterKey: 'leader_id',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          filter: ['-0'],
          options: 'dealLeaders',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        personalRelevancy: {
          label: 'Relevance',
          filterKey: 'relevancy_personal',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'hml',
          filter: ['-0'],
          FormFieldProps: {
            multiple: true
          }
        },
        teamRelevancy: {
          label: 'Team relevance',
          filterKey: 'relevancy_client',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'hml',
          filter: ['-0'],
          FormFieldProps: {
            multiple: true
          }
        },
        externalRelevancy: {
          label: 'External relevance',
          filterKey: 'relevancy_us',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'hml',
          filter: ['-0'],
          FormFieldProps: {
            multiple: true
          }
        }
      }
    },
    client: {
      title: 'Client',
      position: 2,
      filters: {
        clientTags: {
          position: 1
        },
        clientAnalysisSimilarity: {
          position: (1 * constants.numbers.position.filterGap) + 1,
          label: 'Client similarity',
          filterKey: 'client_analysis_similarity',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'gtSmallRange',
          FormFieldProps: {
            multiple: true
          }
        },
        clientAnalysisPointsAdvanced: {
          position: (1 * constants.numbers.position.filterGap) + 2,
          label: 'Client scoring points',
          filterKey: 'client_analysis_points_advanced',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'gtPointRange',
          has: ['points'],
          FormFieldProps: {
            multiple: true
          }
        },
        clientAnalysisScoreAdvanced: {
          position: (1 * constants.numbers.position.filterGap) + 3,
          label: 'Client scoring confidence',
          filterKey: 'client_analysis_score_advanced',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'gtSmallRange',
          FormFieldProps: {
            multiple: true
          }
        }
      }
    },
    discover: {
      title: 'Discover',
      position: 3,
      filters: {
        name: {
          label: 'Name',
          filterKey: 'name',
          type: constants.formFieldTypes.text
        },
        oneliner: {
          label: 'Oneliner',
          filterKey: 'oneliner',
          type: constants.formFieldTypes.text
        },
        description: {
          label: 'Description',
          filterKey: 'description',
          type: constants.formFieldTypes.text
        },
        country: {
          label: 'Country',
          placeholder: 'Search country',
          filterKey: 'country',
          type: constants.formFieldTypes.list,
          validation: `${constants.formFieldValidationTypes.list}(${constants.formFieldValidationTypes.location})`,
          conversion: constants.formFieldConversionTypes.value,
          options: 'countries',
          FormFieldProps: {
            emptyText: 'No country found',
            showSearch: true,
            multiple: true
          }
        },
        region: {
          label: 'Region',
          placeholder: 'Search region',
          filterKey: 'region',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'regions',
          FormFieldProps: {
            multiple: true
          }
        },
        entityType: {
          label: 'Type',
          filterKey: 'eval_type',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'entityTypes',
          FormFieldProps: {
            multiple: true
          }
        },
        dateFounded: {
          label: 'Founding year',
          placeholder: 'Search year',
          filterKey: 'date_founded',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'yearsFrom',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        employees: {
          label: 'Employees',
          filterKey: 'employees',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.employees,
          validation: constants.formFieldValidationTypes.list,
          options: 'employees',
          FormFieldProps: {
            multiple: true
          }
        },
        businessModel: {
          label: 'Business model',
          filterKey: 'business_model',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'businessModels',
          FormFieldProps: {
            multiple: true
          }
        },
        industry: {
          label: 'Industry',
          filterKey: 'industry',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'industriesFilter',
          FormFieldProps: {
            showSearch: true,
            multiple: true,
          }
        },
        totalFunding: {
          label: 'Total funding',
          filterKey: 'total_funding',
          monetary: true,
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'moneyRange',
          FormFieldProps: {
            multiple: true
          }
        },
        lastFundingAmount: {
          label: 'Last funding amount',
          filterKey: 'last_funding_amount',
          monetary: true,
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'moneyRange',
          FormFieldProps: {
            multiple: true,
          }
        },
        fundingStatus: {
          label: 'Funding status',
          filterKey: 'funding_status',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'fundingStatus',
          FormFieldProps: {
            multiple: true,
          }
        },
        lastFundingRound: {
          label: 'Last funding round',
          filterKey: 'last_funding_round',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'fundingRounds',
          FormFieldProps: {
            multiple: true,
          }
        },
        cocNumber: {
          label: 'CoC number',
          filterKey: 'coc_number',
          type: constants.formFieldTypes.text
        },
        links: {
          label: 'Links',
          placeholder: 'Search link',
          filterKey: 'links',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'links',
          FormFieldProps: {
            emptyText: 'No link found',
            showSearch: true,
            multiple: true
          }
        },
        collections: {
          label: 'Collections',
          placeholder: 'Search collection',
          filterKey: 'collections',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'collections',
          FormFieldProps: {
            emptyText: 'No collections found',
            showSearch: true,
            multiple: true
          }
        },
        sources: {
          label: 'Sources',
          placeholder: 'Search source',
          filterKey: 'sources',
          type: constants.formFieldTypes.list,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          options: 'sources',
          FormFieldProps: {
            emptyText: 'No sources found',
            showSearch: true,
            multiple: true
          }
        },
        tags: {
          label: 'Tags',
          placeholder: 'Search tag',
          filterKey: 'tags',
          description: '',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.label,
          validation: constants.formFieldValidationTypes.list,
          format: constants.formFieldFormatTypes.lower,
          options: 'tags',
          FormFieldProps: {
            emptyText: 'No tags found',
            showSearch: true,
            multiple: true
          }
        },
        keywords: {
          label: 'Keywords',
          placeholder: 'Type a keyword',
          variant: 'tags',
          filterKey: 'keywords',
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.list,
          conversion: constants.formFieldConversionTypes.label,
          FormFieldProps: {
            multiple: true,
            createOption: true,
            openDirect: false,
            openOnFocus: false,
            TagProps: {
              variant: 'filled',
            },
            ChipProps: {
              variant: 'filled',
              color: 'warning',
              size: 'small'
            }
          }
        },
        cpc: {
          label: 'Cpc codes',
          placeholder: 'Search cpc code',
          filterKey: 'cpc',
          description: '',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'cpcs',
          FormFieldProps: {
            emptyText: 'No cpc code found',
            showSearch: true,
            multiple: true
          }
        },
        ipc: {
          label: 'Ipc codes',
          placeholder: 'Search ipc code',
          filterKey: 'ipc',
          description: '',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'ipcs',
          FormFieldProps: {
            emptyText: 'No ipc codes found',
            showSearch: true,
            multiple: true
          }
        }
      }
    },
    traction: {
      title: 'Traction',
      position: 4,
      filters: {
        firstActivity: {
          label: 'First activity',
          placeholder: 'Search year',
          filterKey: 'first_activity',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'yearsFrom',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        lastActivity: {
          label: 'Last activity',
          placeholder: 'Search year',
          filterKey: 'last_activity',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'yearsFrom',
          FormFieldProps: {
            showSearch: true,
            multiple: true
          }
        },
        twitterFollowers: {
          label: 'Twitter followers',
          filterKey: 'twitter_followers',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'mediumRange',
          FormFieldProps: {
            multiple: true
          }
        },
        twitterLikes: {
          label: 'Twitter likes',
          filterKey: 'twitter_likes',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'largeRange',
          FormFieldProps: {
            multiple: true
          }
        },
        repoStars: {
          label: 'Repository stars',
          filterKey: 'repo_stars',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'largeRange',
          FormFieldProps: {
            multiple: true
          }
        },
        repoForks: {
          label: 'Repository forks',
          filterKey: 'repo_forks',
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          validation: constants.formFieldValidationTypes.list,
          options: 'mediumRange',
          FormFieldProps: {
            multiple: true
          }
        },
      }
    }
  },
  sectionDefinition: {
    profile: {
      title: 'Profile',
      variant: 'profile',
      position: 1,
      size: 'larger'
    },
    options: {
      name: 'options',
      title: 'Options',
      variant: 'standard',
      size: 'medium',
      position: 2,
      cards: {
        dealLeader: {
          group: 1,
          position: 1
        },
        dealflowStatus: {
          group: 1,
          position: 2
        },
        personalRelevancy: {
          group: 1,
          position: 3
        },
        externalRelevancy: {
          group: 1,
          position: 4
        },
        tasks: {
          group: 3,
          position: 5
        },
        files: {
          group: 4,
          position: 6
        }
      }
    },
    timeline: {
      title: 'Timeline',
      variant: 'timeline',
      size: 'large',
      position: 3
    }
  },
  graphDefinition: {},
  presetDefinition: {
    browse: {
      title: 'Browse',
      position: 1,
      columns: {
        name: true,
        tasks: true,
        timeline: true,
        oneliner: true,
        location: true,
        dateFounded: true,
        employees: true,
        exactFte: true,
        totalFunding: true,
        teamRelevancy: true,
        personalRelevancy: true,
        externalRelevancy: true,
        clientTags: true
      }
    },
    compare: {
      title: 'Compare',
      position: 2,
      columns: {
        name: true,
        tasks: true,
        timeline: true,
        oneliner: true,
        location: true,
        dateFounded: true,
        employees: true,
        exactFte: true,
        totalFunding: true,
        teamRelevancy: true,
        personalRelevancy: true,
        externalRelevancy: true,
        clientTags: true
      }
    },
    process: {
      title: 'Process',
      position: 3,
      columns: {
        name: true,
        tasks: true,
        timeline: true,
        oneliner: true,
        location: true,
        teamRelevancy: true,
        personalRelevancy: true,
        externalRelevancy: true,
        dealflowStatus: true,
        dealLeader: true,
        clientTags: true
      }
    }
  },
  view: {
    entity: {
      position: numbers.maxInt,
      title: 'you are viewing the companies in custom company view'
    },
    task: {
      title: 'you are viewing the companies in custom task view'
    },
    default: {
      title: 'default',
      skipStorage: false,
      groupDefinition: {},
      presetDefinition: {},
      columnDefinition: {},
      filterGroupDefinition: {}
    }
  },
  task: {
    groupDefinition: {},
    sectionDefinition: {},
    view: {}
  },
  financeHistory: {
    revenue: {
      label: 'Revenue'
    },
    operatingIncome: {
      label: 'Operating income'
    },
    netProfit: {
      label: 'Net profit'
    },
    ebitda: {
      label: 'EBITDA'
    },
    ebit: {
      label: 'EBIT'
    },
    totalAssets: {
      label: 'Total assets'
    },
    totalDebt: {
      label: 'Total debt'
    }
  },
  traction: {
    types: {
      fte: 'fte',
      funding: 'funding'
    }
  },
  patent: {
    levels: {
      section: 'section',
      class: 'class',
      subclass: 'subclass'
    }
  },
  disk: {
    sourceTypes: {
      linkedin: 'linkedin',
      crunchbase: 'crunchbase'
    }
  }
}

constants.formFieldValidationTypes.validatorMap = {
  array: constants.formFieldValidationTypes.list,
  int: constants.formFieldValidationTypes.int,
  numeric: constants.formFieldValidationTypes.number,
  citext: constants.formFieldValidationTypes.text,
  date: constants.formFieldValidationTypes.date,
  dateNotInTheFuture: constants.formFieldValidationTypes.notInTheFuture,
  dateNotInThePast: constants.formFieldValidationTypes.notInThePast,
  boolean: constants.formFieldValidationTypes.boolean,
  positiveNumber: constants.formFieldValidationTypes.positive,
  nonNegativeNumber: constants.formFieldValidationTypes.nonNegative,
  uriValidText: constants.formFieldValidationTypes.url,
  phone: constants.formFieldValidationTypes.phone,
  email: constants.formFieldValidationTypes.email,
  url: constants.formFieldValidationTypes.url,
  maxLength: constants.formFieldValidationTypes.max,
  locationObject: constants.formFieldValidationTypes.location
}

constants.formFieldValidationTypes.validatorRequired = (validator) => {
  return ['nonEmptyArray', 'nonEmptyText'].includes(validator);
}

// special default fields
constants.fieldRenderers.nameMap = {
  entity: {
    location: constants.fieldRenderers.location,
    type: constants.fieldRenderers.entityType,
    tags: constants.fieldRenderers.tag,
    systemTags: constants.fieldRenderers.tag,
    collections: constants.fieldRenderers.collection,
    sources: constants.fieldRenderers.collection,
    feeds: constants.fieldRenderers.feed,
    businessModel: constants.fieldRenderers.businessModel,
    cpc: constants.fieldRenderers.cpc,
    ipc: constants.fieldRenderers.ipc,
    articles: constants.fieldRenderers.article,
    websiteScreenshots: constants.fieldRenderers.screenshot,
    keywords: constants.fieldRenderers.keyword,
    keywordsRare: constants.fieldRenderers.keyword,
    dateFounded: constants.fieldRenderers.year,
    yearBankrupt: constants.fieldRenderers.year,
    employees: constants.fieldRenderers.employees,
    oneliner: constants.fieldRenderers.markdown,
    description: constants.fieldRenderers.markdown,
    technologyDescription: constants.fieldRenderers.markdown,
    financialDescription: constants.fieldRenderers.markdown,
    generalScore: constants.fieldRenderers.stars,
    financialScore: constants.fieldRenderers.stars,
    technologyScore: constants.fieldRenderers.stars,
    clientAnalysisSimilarity: constants.fieldRenderers.percentageInt,
    collectionAnalysisSimilarity: constants.fieldRenderers.percentageInt,
    clientAnalysisPointsAdvanced: constants.fieldRenderers.int,
    collectionAnalysisPointsAdvanced: constants.fieldRenderers.int,
    clientAnalysisScoreAdvanced: constants.fieldRenderers.percentageInt,
    collectionAnalysisScoreAdvanced: constants.fieldRenderers.percentageInt,
    addedAt: constants.fieldRenderers.date,
    dealflowAdded: constants.fieldRenderers.date,
    dealflowChanged: constants.fieldRenderers.date,
    financeHistoryRevenue: constants.fieldRenderers.monetary,
    financeHistoryOperatingIncome: constants.fieldRenderers.monetary,
    financeHistoryNetProfit: constants.fieldRenderers.monetary,
    financeHistoryEbitda: constants.fieldRenderers.monetary,
    financeHistoryEbit: constants.fieldRenderers.monetary,
    financeHistoryTotalAssets: constants.fieldRenderers.monetary,
    financeHistoryTotalDebt: constants.fieldRenderers.monetary
  },
  client: {
    name: constants.fieldRenderers.text,
    currency: constants.fieldRenderers.currency,
    plan: constants.fieldRenderers.plan,
    description: constants.fieldRenderers.markdown,
    userLimit: constants.fieldRenderers.int,
    monthlyCreditLimit: constants.fieldRenderers.int
  },
  collection: {
    labels: constants.fieldRenderers.label,
    entities: constants.fieldRenderers.numeric
  }
}

constants.fieldRenderers.dataTypeMap = {
  [constants.fieldDataTypes.int]: constants.fieldRenderers.int,
  [constants.fieldDataTypes.numeric]: constants.fieldRenderers.numeric,
  [constants.fieldDataTypes.boolean]: constants.fieldRenderers.boolean,
  [constants.fieldDataTypes.text]: constants.fieldRenderers.text,
  [constants.fieldDataTypes.citext]: constants.fieldRenderers.text,
  [constants.fieldDataTypes.date]: constants.fieldRenderers.date,
  [constants.fieldDataTypes.timestamp]: constants.fieldRenderers.date,
  [constants.fieldDataTypes.url]: constants.fieldRenderers.link
}

constants.formFieldTypes.rendererMap = {
  [constants.fieldRenderers.text]: constants.formFieldTypes.text,
  [constants.fieldRenderers.textarea]: constants.formFieldTypes.textarea,
  [constants.fieldRenderers.markdown]: constants.formFieldTypes.markdown,
  [constants.fieldRenderers.int]: constants.formFieldTypes.number,
  [constants.fieldRenderers.numeric]: constants.formFieldTypes.number,
  [constants.fieldRenderers.monetary]: constants.formFieldTypes.monetary,
  [constants.fieldRenderers.percentageInt]: constants.formFieldTypes.number,
  [constants.fieldRenderers.percentageNumber]: constants.formFieldTypes.number,
  [constants.fieldRenderers.date]: constants.formFieldTypes.date,
  [constants.fieldRenderers.year]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.email]: constants.formFieldTypes.text,
  [constants.fieldRenderers.phone]: constants.formFieldTypes.text,
  [constants.fieldRenderers.country]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.location]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.hml]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.employees]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.businessModel]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.entityType]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.industry]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.financeHistory]: constants.formFieldTypes.financeHistory,
  [constants.fieldRenderers.currency]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.stars]: constants.formFieldTypes.stars,
  [constants.fieldRenderers.fundingStatus]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.fundingRound]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.plan]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.tag]: constants.formFieldTypes.cloud,
  [constants.fieldRenderers.label]: constants.formFieldTypes.cloud,
  [constants.fieldRenderers.collection]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.feed]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.cpc]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.ipc]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.article]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.screenshot]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.keyword]: constants.formFieldTypes.autocomplete,
  [constants.fieldRenderers.boolean]: constants.formFieldTypes.checkbox,
  [constants.fieldRenderers.link]: constants.formFieldTypes.suggestion
}

constants.formFieldFormatTypes.rendererMap = {
  [constants.fieldRenderers.int]: constants.formFieldFormatTypes.int,
  [constants.fieldRenderers.numeric]: constants.formFieldFormatTypes.number,
  [constants.fieldRenderers.monetary]: constants.formFieldFormatTypes.int,
  [constants.fieldRenderers.percentageInt]: constants.formFieldFormatTypes.percentageInt,
  [constants.fieldRenderers.percentageNumber]: constants.formFieldFormatTypes.percentageNumber
}

constants.formFieldValidationTypes.rendererMap = {
  [constants.fieldRenderers.tag]: constants.formFieldValidationTypes.tag,
  [constants.fieldRenderers.collection]: constants.formFieldValidationTypes.collection,
  [constants.fieldRenderers.label]: constants.formFieldValidationTypes.label,
  [constants.fieldRenderers.link]: constants.formFieldValidationTypes.url,
  [constants.fieldRenderers.monetary]: constants.formFieldValidationTypes.int
}

constants.formFieldConversionTypes.rendererMap = {
  [constants.fieldRenderers.int]: constants.formFieldConversionTypes.int,
  [constants.fieldRenderers.monetary]: constants.formFieldConversionTypes.value,
  [constants.fieldRenderers.numeric]: constants.formFieldConversionTypes.number,
  [constants.fieldRenderers.location]: constants.formFieldConversionTypes.location,
  [constants.fieldRenderers.date]: constants.formFieldConversionTypes.date,
  [constants.fieldRenderers.year]: constants.formFieldConversionTypes.year,
  [constants.fieldRenderers.tag]: constants.formFieldConversionTypes.tag,
  [constants.fieldRenderers.collection]: constants.formFieldConversionTypes.collection,
  [constants.fieldRenderers.label]: constants.formFieldConversionTypes.labelObject,
  [constants.fieldRenderers.employees]: constants.formFieldConversionTypes.employees,
  [constants.fieldRenderers.entityType]: constants.formFieldConversionTypes.entityType,
  [constants.fieldRenderers.businessModel]: constants.formFieldConversionTypes.businessModel,
  [constants.fieldRenderers.fundingStatus]: constants.formFieldConversionTypes.fundingStatus,
  [constants.fieldRenderers.fundingRound]: constants.formFieldConversionTypes.fundingRound,
  [constants.fieldRenderers.industry]: constants.formFieldConversionTypes.industry,
  [constants.fieldRenderers.currency]: constants.formFieldConversionTypes.currency,
  [constants.fieldRenderers.link]: constants.formFieldConversionTypes.link,
  [constants.fieldRenderers.plan]: constants.formFieldConversionTypes.plan
}

constants.data = {
  smallRange,
  mediumRange,
  largeRange,
  gtSmallRange,
  gtPointRange,
  moneyRange,
  ssoVendors,
  regions,
  links,
  score,
  hml,
  roles,
  budgetPurposes,
  planOptions,
  planSections,
  plans,
  taskTypes,
  taskLocations,
  taskCollaborationTypes,
  timelineLocations,
  collectionVisibility,
  collectionProjectTypes,
  customFieldRendererTypes,
  relevancyDistributionTypes,
  sourceTypes,
  sourceSubTypes,
  sourceFilterTypes,
  services,
  periodMonths,
  periodYears,
  completeness,
  recentChanges,
  querySourceTypes,
  queryPartTypes,
  queryTermFunctors,
  queryFilterOperators,
  countries: countries.map((c) => ({
    label: c.name,
    value: c.code
  })),
  industries: industries.map((industry) => ({
    label: industry,
    value: industry
  })),
  industriesFilter: industries.map((industry, idx) => ({
    label: industry,
    value: (idx < industries.length - 1 && industries[idx + 1].startsWith(industries[idx])) ?
      `${industries[idx]}*` : industries[idx]
  })),
  currencies: currencies.map((currency) => ({
    label: currency.name,
    value: currency.code,
    symbol: currency.symbol
  })),
  years: (new Array((new Date()).getFullYear() - constants.numbers.minYear + 1)).fill(null)
    .map((_, i) => ({
      label: `${utilsBasic.fillYear(i + constants.numbers.minYear)}`,
      value: i + constants.numbers.minYear
    })).reverse(),
  yearsFrom: (new Array((new Date()).getFullYear() - constants.numbers.minYear + 1)).fill(null)
    .map((_, i) => ({
      label: `>= ${utilsBasic.fillYear(i + constants.numbers.minYear)}`,
      value: i + constants.numbers.minYear
    })).reverse(),
  yearsRange: (new Array((new Date()).getFullYear() - constants.numbers.minYear + 2)).fill(null)
    .map((_, i) => ({
      label: `${utilsBasic.fillYear(i + constants.numbers.minYear)}`,
      value: i + constants.numbers.minYear
    })),
  yearsRange25: (new Array(Math.ceil(((new Date()).getFullYear() - Math.max(25, Math.floor(constants.numbers.minYear / 25) * 25) + 2) / 25))).fill(null)
    .map((_, i) => ({
      label: `${utilsBasic.fillYear((i * 25) + Math.max(25, Math.floor(constants.numbers.minYear / 25) * 25))}`,
      value: (i * 25) + Math.max(25, Math.floor(constants.numbers.minYear / 25) * 25)
    })).concat((((new Date()).getFullYear() + 1) % 25 !== 0) ? [{
      label: `${utilsBasic.fillYear((new Date()).getFullYear() + 1)}`,
      value: ((new Date()).getFullYear() + 1)
    }] : []),
  monthsFrom:
    ['day', 'week', 'month'].map((p) => {
      const max = (new Date())
      max.setFullYear(3000);
      const date = new Date();

      if (p === 'week') {
        date.setDate(date.getDate() - 6);
      } else if (p === 'month') {
        date.setMonth((new Date()).getMonth() - 1);
      }
      return {
        label: `>= One ${p}`,
        value: `[${dayjs(date).format('YYYY-MM-DD')},${dayjs(max).format('YYYY-MM-DD')}]`
      }
    })
    .concat(
      (new Array(((new Date()).getMonth() + 1) + (12 * 2))).fill(null)
        .map((_, i) => {
          const max = (new Date())
          max.setFullYear(3000);
          const date = (new Date());
          date.setMonth((new Date()).getMonth() - i);
          date.setDate(1);
          return {
            label: `>= ${dayjs(date).format('MMM YYYY')}`,
            value: `[${dayjs(date).format('YYYY-MM-DD')},${dayjs(max).format('YYYY-MM-DD')}]`
          }
        })
    ),
  toggleYesNo,
  toggleYesNoInverted: toggleYesNo.map((opt) => {
    return {...opt, value: !opt.value}
  }),
  profiles,
  entityTypes,
  businessModels,
  fundingStatus,
  fundingRounds,
  employees: mediumRange,
  analyserTypes,
  autoTagTypes,
  tractionTypes,
  tractionPeriods,
  patentLevels,
  patentPeriods,
  diskSourceTypes
};

constants.data.lookup = (name, value) => {
  return constants.data?.[name]?.find((d) => d.value === value);
}

constants.data.score.lookup = (score) => {
  return constants.data.score.find((s) => {
    return Math.round(score) >= s.min && Math.round(score) <= s.max;
  });
};

constants.data.rendererMap = {
  [constants.fieldRenderers.country]: 'countries',
  [constants.fieldRenderers.location]: 'countries',
  [constants.fieldRenderers.tag]: 'tags',
  [constants.fieldRenderers.label]: 'labels',
  [constants.fieldRenderers.entityType]: 'entityTypes',
  [constants.fieldRenderers.year]: 'years',
  [constants.fieldRenderers.currency]: 'currencies',
  [constants.fieldRenderers.employees]: 'employees',
  [constants.fieldRenderers.businessModel]: 'businessModels',
  [constants.fieldRenderers.industry]: 'industries',
  [constants.fieldRenderers.hml]: 'hml',
  [constants.fieldRenderers.fundingStatus]: 'fundingStatus',
  [constants.fieldRenderers.fundingRound]: 'fundingRounds',
  [constants.fieldRenderers.plan]: 'plans',
  [constants.fieldRenderers.monetary]: 'currencyConversions'
}

constants.groupDefinition = {
  lookup: (type, view, groupOrField, isField, mergeFn) => {
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const groupDefinition = Object.keys(definition?.groupDefinition ?? {})
      .map((k) => ({...definition?.groupDefinition[k], name: k}));
    const groupViewDefinition = Object.keys(view?.groupDefinition ?? {})
      .map((k) => ({...view?.groupDefinition[k], name: k}));

    let found = groupDefinition.find((gd) => (!isField && (gd.name === utilsBasic.camelcase(groupOrField) || gd.title === groupOrField)) ||
        Object.keys(gd.fields || {}).find((k) => isField && k === utilsBasic.camelcase(groupOrField)));
    let foundView = groupViewDefinition.find((gd) => (!isField && (gd.name === utilsBasic.camelcase(groupOrField) || gd.title === groupOrField)) ||
      Object.keys(gd.fields || {}).find((k) => isField && k === utilsBasic.camelcase(groupOrField)));

    if (!found) {
      found = groupDefinition.find((gd) => (!isField && gd.name === 'default') ||
        Object.keys(gd.fields || {}).find((k) => isField && k === 'default'));
    }

    if (!foundView) {
      foundView = groupViewDefinition.find((gd) => (!isField && gd.name === 'default') ||
        Object.keys(gd.fields || {}).find((k) => isField && k === 'default'));
    }

    return mergeFn(found, foundView, true);
  }
}

constants.presetDefinition = {
  lookup: (type, view, groupOrColumn, isColumn) => {
    const returnAll = !utilsBasic.isDefined(groupOrColumn);
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const presetDefinition = Object.keys(definition?.presetDefinition ?? {})
      .map((k) => ({...definition?.presetDefinition[k], name: k}));
    const presetViewDefinition = Object.keys(view?.presetDefinition ?? {})
      .map((k) => ({...view?.presetDefinition[k], name: k}));

    let found = presetDefinition.filter((pd) => returnAll || (!isColumn && (pd.name === utilsBasic.camelcase(groupOrColumn) || pd.title === groupOrColumn)) ||
      Object.keys(pd.columns || {}).filter((c) => pd.columns[c]).find((k) => isColumn && k === utilsBasic.camelcase(groupOrColumn)));
    let foundView = presetViewDefinition.filter((pd) => returnAll || (!isColumn && pd.name === utilsBasic.camelcase(groupOrColumn)) ||
      Object.keys(pd.columns || {}).filter((c) => pd.columns[c]).find((k) => isColumn && k === utilsBasic.camelcase(groupOrColumn)));

    if (found.length === 0) {
      found = presetDefinition.filter((pd) => (!isColumn && pd.name === 'default') ||
        Object.keys(pd.columns || {}).filter((c) => pd.columns[c]).find((k) => isColumn && k === 'default'));
    }

    if (foundView.length === 0) {
      foundView = presetViewDefinition.filter((pd) => (!isColumn && pd.name === 'default') ||
        Object.keys(pd.columns || {}).filter((c) => pd.columns[c]).find((k) => isColumn && k === 'default'));
    }

    foundView.forEach((p1) => {
      const idx = found.findIndex((p2) => p1.name === p2.name);
      if (idx !== -1) {
        found[idx] = {
          ...found[idx],
          ...p1,
          columns: {
            ...found[idx].columns,
            ...p1.columns
          }
        }
      } else {
        found.push(p1);
      }
    })

    return found;
  }
}

constants.filterGroupDefinition = {
  lookup: (type, view, groupOrFilter, isFilter, mergeFn) => {
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const filterGroupDefinition = Object.keys(definition?.filterGroupDefinition ?? {})
      .map((k) => ({...definition?.filterGroupDefinition[k], name: k}));
    const filterGroupViewDefinition = Object.keys(view?.filterGroupDefinition ?? {})
      .map((k) => ({...view?.filterGroupDefinition[k], name: k}));

    let found = filterGroupDefinition.find((gd) => (!isFilter && (gd.name === utilsBasic.camelcase(groupOrFilter) || gd.title === groupOrFilter)) ||
      Object.keys(gd.filters || {}).find((k) => isFilter && k === utilsBasic.camelcase(groupOrFilter)));
    let foundView = filterGroupViewDefinition.find((gd) => (!isFilter && (gd.name === utilsBasic.camelcase(groupOrFilter) || gd.title === groupOrFilter)) ||
      Object.keys(gd.filters || {}).find((k) => isFilter && k === utilsBasic.camelcase(groupOrFilter)));

    if (!found) {
      found = filterGroupDefinition.find((gd) => (!isFilter && gd.name === 'default') ||
        Object.keys(gd.filters || {}).find((k) => isFilter && k === 'default'));
    }

    if (!foundView) {
      foundView = filterGroupViewDefinition.find((gd) => (!isFilter && gd.name === 'default') ||
        Object.keys(gd.filters || {}).find((k) => isFilter && k === 'default'));
    }

    return mergeFn(found, foundView, true);
  }
}

constants.columnDefinition = {
  lookup: (type, view, field, mergeFn) => {
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const columnDefinition = Object.keys(definition?.columnDefinition ?? {})
      .map((k) => ({...definition?.columnDefinition[k], name: k}));
    const columnViewDefinition = Object.keys(view?.columnDefinition ?? {})
      .map((k) => ({...view?.columnDefinition[k], name: k}));

    let found = columnDefinition.find((cd) => cd.name === utilsBasic.camelcase(field));
    let foundView = columnViewDefinition.find((cd) => cd.name === utilsBasic.camelcase(field));

    if (!found) {
      found = columnDefinition.find((cd) => cd.name === 'default');
    }

    if (!foundView) {
      foundView = columnViewDefinition.find((cd) => cd.name === 'default');
    }

    return mergeFn(found, foundView, true);
  }
}

constants.sectionDefinition = {
  lookup: (type, view, section, mergeFn) => {
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const sectionDefinition = Object.keys(definition?.sectionDefinition ?? {})
      .map((k) => ({...definition?.sectionDefinition[k], name: k}));
    const sectionViewDefinition = Object.keys(view?.sectionDefinition ?? {})
      .map((k) => ({...view?.sectionDefinition[k], name: k}));

    let found = sectionDefinition.find((sd) => sd.name === utilsBasic.camelcase(section));
    let foundView = sectionViewDefinition.find((sd) => sd.name === utilsBasic.camelcase(section));

    if (!found) {
      found = sectionDefinition.find((sd) => sd.name === 'default');
    }

    if (!foundView) {
      foundView = sectionViewDefinition.find((sd) => sd.name === 'default');
    }

    return mergeFn(found, foundView, true);
  }
}

constants.graphDefinition = {
  lookup: (type, view, graph, mergeFn) => {
    const definition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const graphDefinition = Object.keys(definition?.graphDefinition ?? {})
      .map((k) => ({...definition?.graphDefinition[k], name: k}));
    const graphViewDefinition = Object.keys(view?.graphDefinition ?? {})
      .map((k) => ({...view?.graphDefinition[k], name: k}));

    let found = graphDefinition.find((gd) => gd.name === utilsBasic.camelcase(graph));
    let foundView = graphViewDefinition.find((gd) => gd.name === utilsBasic.camelcase(graph));

    if (!found) {
      found = graphDefinition.find((gd) => gd.name === 'default');
    }

    if (!foundView) {
      foundView = graphViewDefinition.find((gd) => gd.name === 'default');
    }

    return mergeFn(found, foundView, true);
  }
}

constants.icons = {
  dealflow: {
    'hourglass': HourglassTop,
    'eye': RemoveRedEye,
    'check': CheckBox,
    'time': AccessTime,
    'man': Man,
    'factory': Factory,
    'stars': Stars,
    'bulb': Lightbulb,
    'fire': Whatshot,
    'freeze': AcUnit,
    'coffee': Coffee,
    'bike': DirectionsBike,
    'mail': Mail,
    'recycle': Recycling,
    'sleep': Bedtime,
    'thumb': ThumbUp
  }
}

constants.color = { deprecated: {} }

constants.color.deprecated.graph = {
  'blue-1': '#1D8BF1',
  'blue-2': '#145AB8',
  'blue-3': '#76A8FF',
  'light-blue-1': '#AAE1FC',
  'light-blue-2': '#006CB5',
  'light-blue-3': '#004D90',
  'cyan-1': '#A9E8F0',
  'cyan-2': '#008C9D',
  'cyan-3': '#79FFFF',
  'teal-1': '#A9DAD6',
  'teal-2': '#005E52',
  'teal-3': '#9DFFE8',
  'green-1': '#5AC55E',
  'green-2': '#318334',
  'green-3': '#19531E',
  'light-green-1': '#D7EAC1',
  'light-green-2': '#4B8029',
  'light-green-3': '#C5FF85',
  'lime-1': '#EEF2BB',
  'lime-2': '#A6AB26',
  'lime-3': '#F3FF76',
  'yellow-1': '#F8BE21',
  'yellow-2': '#F89E21',
  'yellow-3': '#FFFF82',
  'amber-1': '#7F7455',
  'amber-2': '#FF8300',
  'amber-3': '#FFE174',
  'orange-1': '#8F7B5F',
  'orange-2': '#ED6000',
  'orange-3': '#FFCA75',
  'deep-orange-1': '#FF4C20',
  'deep-orange-2': '#D33A15',
  'deep-orange-3': '#FF9375',
  'red-1': '#EA3E3E',
  'red-2': '#CD2929',
  'red-3': '#AE191A',
  'pink-1': '#E61A58',
  'pink-2': '#BA1751',
  'pink-3': '#FF74A1',
  'purple-1': '#DDB6E4',
  'purple-2': '#6F1E98',
  'purple-3': '#E775FC',
  'deep-purple-1': '#5C33AE',
  'deep-purple-2': '#47299E',
  'deep-purple-3': '#2B1A87',
  'indigo-1': '#BDC3E6',
  'indigo-2': '#2A3795',
  'indigo-3': '#182073',
  'brown-1': '#6E4B3F',
  'brown-2': '#452E29',
  'brown-3': '#362320',
  'blue-grey-1': '#C9D2D7',
  'blue-grey-2': '#3D5059',
  'blue-grey-3': '#F9F9F9',
  'grey-1': '#171717',
  'grey-2': '#565656',
  'grey-3': '#1F1F1F',
  'blue': '#107eb9',
  'green': '#3FC380',
  'ripe-lemon': '#F7CA18',
  'santas-gray': '#9c9ca2',
  'fountain-blue-dark': '#4caeba',
  'link-water-dark': '#cfe5f1',
  'tarawera': '#083f5c',
  'emerald': '#65cf99',
  'marigold-yellow': '#fadf74',
  'french-gray': '#cdcdd0',
  'aqua-island-dark': '#99d1d7',
  'link-water': '#e7f2f8',
  'shakespeare': '#57a4ce',
  'tuna': '#3e3e46',
  'blue-stone': '#00626d',
  'pizza': '#c5a113',
  'aqua-island': '#b2dce1',
  'mid-gray': '#6a6a73',
  'fountain-blue': '#66bac4',
  'half-colonial-white': '#fdf4d0',
  'warning-color': '#ffc1c1'
}

constants.color.deprecated.color = {
  'blue': '#107eb9',
  'blue-light': '#4C9BC5',
  'blue-lighter': lighten(0.3, '#107eb9'),
  'blue-lightest': lighten(0.5, '#107eb9'),
  'blue-dark': darken(0.1, '#107eb9'),
  'blue-darker': darken(0.15, '#107eb9'),
  'green': '#3FC380',
  'green-dark': darken(0.1, '#3FC380'),
  'green-darker': darken(0.15, '#3FC380'),
  'red': '#FC033F',
  'red-dark': darken(0.1, '#FC033F'),
  'red-darker': darken(0.2, '#FC033F'),
  'orange': '#FF8F09',
  'orange-dark': darken(0.1, '#FF8F09'),
  'yellow': '#F7CA18',
  'black': '#00171F',
  'white': '#FFFFFF',
  'purple': '#9A12B3',
  'gray-light': 'rgb(234, 234, 234)',
  'light-gray': 'rgb(234, 234, 234)',
  'gray-lighter': 'rgb(245, 245, 245)',
  'gray': 'rgb(225, 225, 225)',
  'grayer': 'rgb(200, 200, 200)',
  'gray-dark': 'rgb(140, 140, 140)',
  'dark-gray': 'rgb(140, 140, 140)',
  'gray-darker': 'rgb(90, 90, 100)',
  'gray-darkest': 'rgb(50, 50, 60)',
  'bg-color': '#FFFFFF',
  'content-bg-color': 'rgb(234, 234, 234)',
  'border-color': 'rgb(234, 234, 234)',
  'box-bg-color': '#FFFFFF',
  'box-border-color': 'rgb(234, 234, 234)',
  'modal-bg-color': '#FFFFFF',
  'modal-border-color': 'rgb(234, 234, 234)',
  'hover-color': 'rgb(234, 234, 234)',
  'active-color': '#107eb9',
  'selected-color': 'rgb(234, 234, 234)',
  'primary-font-color': 'rgb(90, 90, 100)',
  'input-border-color': 'rgb(234, 234, 234)',
  'input-active-border-color': '#4C9BC5',
  'status-color-a': '#FF8F09',
  'status-color-r': '#FC033F',
  'status-color-h': 'rgb(140, 140, 140)',
  'status-color-p': '#107eb9',
  'status-color-n': 'rgb(234, 234, 234)',
  'status-color-c': '#F7CA18',
  'status-color-green': '#3FC380',
}
constants.color.deprecated.color = {
  ...constants.color.deprecated.color,
  ...constants.color.deprecated.graph
};

constants.color.deprecated.limit = {
  'blue': '#107eb9',
  'green': '#3FC380',
  'ripe-lemon': '#F7CA18',
  'santas-gray': '#9c9ca2',
  'fountain-blue-dark': '#4caeba',
  'link-water-dark': '#cfe5f1',
  'tarawera': '#083f5c',
  'emerald': '#65cf99',
  'marigold-yellow': '#fadf74',
  'french-gray': '#cdcdd0',
  'aqua-island-dark': '#99d1d7',
  'shakespeare': '#57a4ce',
  'tuna': '#3e3e46',
  'blue-stone': '#00626d',
  'pizza': '#c5a113',
  'aqua-island': '#b2dce1',
  'mid-gray': '#6a6a73',
  'fountain-blue': '#66bac4'
}

export default constants;
