import React, {useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import StyledActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';

const ActionIconButton = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    IconProps,
    DropDownPopperProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionIconButtonProps}, 'ActionIconButton', {
    static: ['open']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  const [internalState, setInternalState] = useState({open: false, closed: true});

  const renderButton = (onClick) => {
    return <Tooltip title={action.tooltip} showDisabled={true}
                    fullWidth={innerProps.fullWidth ?? action.IconButtonProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionIconButton ref={innerRef} {...innerProps}
                              disabled={!itm}
                              selected={itm?.active}
                              onClick={onClick}
                              badge={action.badge}
                              color={action.color ?? innerProps.color}
                              {...action.IconButtonProps}>
        <Icon icon={action.icon} {...IconProps}/>
      </StyledActionIconButton>
    </Tooltip>
  }

  const handleButtonClick = (e) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        open: !current.open,
        closed: false
      })(current);
    });
    e.preventDefault();
  }

  const handlePopperToggle = (open) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        open: open,
        closed: open ? false : current.closed
      })(current);
    });
  }

  const handlePopperClosed = () => {
    setInternalState(utils.updater({closed: true}, true));
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    open: internalState.open,
    closed: internalState.closed
  });

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    if (action.children || action.popper) {
      return <React.Fragment>
        {renderButton(handleButtonClick)}
        <DropdownPopper anchorEl={innerRef.current}
                        open={internalState.open}
                        onToggle={handlePopperToggle}
                        onClosed={handlePopperClosed}
                        {...DropDownPopperProps}>
          {action.popper ? action.popper : <List track={true} gap={4}>
            {action.children.map((action, idx) => {
              return <ListItem key={idx}>
                <ActionIconButton action={action}
                                  onClick={() => setInternalState(utils.updater({open: false}, true))}
                                  fullWidth={true}/>
              </ListItem>
            })}
          </List>}
        </DropdownPopper>
      </React.Fragment>
    } else {
      return renderButton(handleClick);
    }
  }
});

ActionIconButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  IconProps: PropTypes.object,
  TooltipProps: PropTypes.object,
  DropDownPopperProps: PropTypes.object
};

ActionIconButton.defaultProps = {
};

export default ActionIconButton;
