import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledDialogHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(2)};
  border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
  gap: ${props => props.theme.layout('1sp')};
  max-height: ${props => props.theme.layout(69)};

  &:has(.DialogHeader-title-subtitle) {
    padding-bottom: ${props => props.theme.layout(11)};
    .DialogHeader {
      &-title-title {
        margin-top: ${props => props.theme.layout(-3)};
      }
      &-title-subtitle {
        margin-top: ${props => props.theme.layout(-4)};
      }
    }
  }
  
  .DialogHeader {
    &-title {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: start;
      white-space: nowrap;
      width: 1px;
      overflow: hidden;

      &-title, &-subtitle {
        display: flex;
        width: 100%;

        > .Typography {
          flex-grow: 1;
          display: inline !important;
          text-overflow: ellipsis;
          overflow: hidden;

          * {
            display: inline !important;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: fit-content;
          }
        }
      }
      
      &-subtitle {
        color: ${props => props.theme.property('palette.primary.main')};
        ${props => props.theme.font2Css('subtitle2')};

        > * {
          ${props => props.theme.font2Css('subtitle2')};
        }
      }
    }

    &-close, span:has(.DialogHeader-close) {
      align-self: baseline;
    }
  }
  
  ${ComponentStyles}
`;

StyledDialogHeader.propTypes = {
  theme: PropTypes.object
};

StyledDialogHeader.defaultProps = {}

export default StyledDialogHeader;
