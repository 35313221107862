import React, {useEffect, useState} from 'react';
import Services from 'services/services';
import {useServiceAuthorize, useServiceInvalidate, useServiceRefresh} from 'stores/hooks/service';
import system from 'helpers/system';
import {usePageVisibility} from 'helpers/hooks/utils';
import {useAppStore} from 'components/organisms/Providers/AppProvider/AppProvider';
import constants from 'helpers/constants';
import {useSystemSynchroniseGet} from 'services/system/system.hooks';
import {useAuth, useAuthClientId, useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import {withMemo} from 'helpers/wrapper';

export const ServiceContext = React.createContext(null)
export const SynchroniseContext = React.createContext(null)

export function useServices () {
  return React.useContext(ServiceContext);
}

export function useServiceStore (path) {
  const services = React.useContext(ServiceContext);

  return services?.find(path);
}

export function useSynchronise () {
  return React.useContext(SynchroniseContext);
}

const services = new Services();

const Refresh = withMemo(({path}) => {
  useServiceRefresh(path);
});

const Service = withMemo(({service}) => {
  const clientId = useAuthClientId();
  const userId = useAuthUserId();
  const teamId = useAuthTeamId();

  const path = service.storePath();
  useServiceAuthorize(path);

  const synchronise = useSynchronise();
  const invalidate = useServiceInvalidate(path);

  // invalidates all that is older than the previous servedAt time
  const changes = synchronise?.filter((change) => service.hasEntity(change.object));
  useEffect(() => {
    changes?.forEach((change) => {
      if (change.removedIds?.length > 0) {
        invalidate(change.object.split('/').slice(-1)[0], new Date(change.removedAt), utils.toArray(change.contextId), [], [], change.removedIds);
      }
      if (change.addedIds?.length > 0) {
        invalidate(change.object.split('/').slice(-1)[0], new Date(change.addedAt), utils.toArray(change.contextId), [], change.addedIds, []);
      }
      if (change.updatedIds) {
        invalidate(change.object.split('/').slice(-1)[0], new Date(change.updatedAt), utils.toArray(change.contextId), change.updatedIds, [], []);
      }
    });
  }, [invalidate, changes]);

  return <Debounce key={`key_${clientId ?? ''}_${userId ?? ''}_${teamId ?? ''}`}
                   timeout={constants.delay.refresh}>
    <Refresh path={path} />
  </Debounce>
});

const Synchronisation = () => {
  const auth = useAuth();
  const services = useServices();

  const isPageVisible = usePageVisibility();
  const [internalState, setInternalState] = useState({});

  const synchronise = useSystemSynchroniseGet({
    syncTime: internalState.syncTime,
    time: internalState.time
  }, {
    ...constants.queryOptions.runOnceFresh,
    enabled: Boolean(auth?.loggedIn)
  });

  useEffect(() => {
    const intervals = system.synchronisationInterval();
    if (!synchronise.status?.isLoading) {
      return utils.observeInterval(() => {
        setInternalState((current) => ({
          ...current,
          syncTime: synchronise.data?.data?.time,
          time: Date.now()
        }));
      }, isPageVisible ? intervals.foreground : intervals.background);
    }
  }, [isPageVisible, synchronise.data?.data?.time, synchronise.status?.isLoading]);

  useEffect(() => {
    if (synchronise.data?.data) {
      setInternalState((current) => ({
        ...current,
        context: synchronise.data?.data?.sync
      }));
    }
  }, [synchronise.data?.data]);

  return <SynchroniseContext.Provider value={internalState.context}>
    {Object.keys(services.services).map((path) => {
      const service = services.services[path];
      return <Service key={service.storePath()}
                      service={service}/>
    })}
  </SynchroniseContext.Provider>
}

const ServiceProvider = ({children}) => {
  services.init(useAppStore());

  return <ServiceContext.Provider value={services}>
    <Synchronisation />
    {children}
  </ServiceContext.Provider>
};

ServiceProvider.propTypes = {
}

export default ServiceProvider;
