import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionVisibilityTableCellEdit
  from 'components/organisms/TableCellEdits/CollectionVisibilityTableCellEdit/CollectionVisibilityTableCellEdit.styles';
import constants from 'helpers/constants';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import CollectionVisibilityTableCell
  from 'components/molecules/TableCells/CollectionVisibilityTableCell/CollectionVisibilityTableCell';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const CollectionVisibilityTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    size,
    visibility,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'CollectionVisibilityTableCellEdit')

  const authorize = useAuthorize();

  const onChangeEvent = useEffectEvent(onChange);
  const options = useMemo(() => {
    return constants.data.collectionVisibility.filter((v) => v.value !== visibility)
      .reduce((a, v) => {
        if (authorize({
          attribute: `collection.column.visibility.update.${v.value}`,
          meta: {collection: cell.row.original}
        })) {
          a.push({
            label: v.label,
            tooltip: v.label,
            icon: v.icon,
            color: v.color,
            meta: {visibility: v.value},
            ChipProps: {
              radius: size === 'large' ? 'round' : null,
            },
            onClick: (e) => {
              cell.column.columnDef.optimistic.set(cell, v.value);
              table.setEditingCell(null);
              utils.asPromise(onChangeEvent)(cell.row.original, v.value)
                .catch(() => {
                  cell.column.columnDef.optimistic.reset(cell);
                  table.setEditingCell(cell);
                });

              e.preventDefault();
            }
          })
        }
        return a;
      }, []);
  }, [authorize, visibility, table, cell, size, onChangeEvent]);

  return <StyledCollectionVisibilityTableCellEdit ref={ref} {...innerProps}
                                                  cell={cell}
                                                  table={table}
                                                  Anchor={<CollectionVisibilityTableCell visibility={visibility}
                                                                                         active={true}
                                                                                         size={size}/>}>
    <List track={true} gap={4}>
      {options.map((action, idx) => {
        return <ListItem key={idx}>
          <ActionChip action={action}
                      fullWidth
                      size={size}
                      variant="outlined"/>
        </ListItem>
      })}
    </List>
  </StyledCollectionVisibilityTableCellEdit>
}));

CollectionVisibilityTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  visibility: PropTypes.string,
  onChange: PropTypes.func
};

CollectionVisibilityTableCellEdit.defaultProps = {
  size: 'large'
};

export default CollectionVisibilityTableCellEdit;
