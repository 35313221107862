import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import {default as CircularProgressBase} from '@mui/material/CircularProgress';
import utils from 'helpers/utils';

const CircularProgress = React.forwardRef((props, ref) => {
  const {
    showPercentage,
    TypographyProps,
    ...innerProps
  } = useComponentProps(props, 'CircularProgress', {
    children: ['label']
  });

  const classNameFlattened = utils.flattenClassName(innerProps.className);

  return <StyledCircularProgress className={classNameFlattened}>
    <CircularProgressBase ref={ref} {...innerProps} />
    {showPercentage ? <Box className="CircularProgress-label">
      <Typography variant="caption"
                  component="div"
                  color="text.secondary"
                  {...TypographyProps}>
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box> : null}
  </StyledCircularProgress>
});

CircularProgress.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showPercentage: PropTypes.bool,
  TypographyProps: PropTypes.object
};

CircularProgress.defaultProps = {
};

export default CircularProgress;
