import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledResolveCollectionCategoriesWizardContent = styled(Box)`
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
  
  .ResolveCollectionCategoriesWizardContent {
    &-subtitle {
      margin-bottom: ${props => props.theme.layout('2sp')};
      
      .H6 {
        color: ${props => props.theme.property('palette.text.secondary')};
      }
    }
    
    &-tagGroups {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('2sp')};
      
      .TagGroupCard {
        border: 1px solid ${props => props.theme.property('palette.divider')};
        
        .TagGroupCard-tags {
          height: 1px;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledResolveCollectionCategoriesWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledResolveCollectionCategoriesWizardContent.defaultProps = {}

export default StyledResolveCollectionCategoriesWizardContent;
