import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const StyledImageListItemBar = styled(ImageListItemBar)` 
  ${ComponentStyles}
`;

StyledImageListItemBar.propTypes = {
  theme: PropTypes.object
};

StyledImageListItemBar.defaultProps = {}

export default StyledImageListItemBar;
