import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileCardContent from 'components/organisms/Cards/ProfileCardContent/ProfileCardContent';

const StyledClientProfileCardContent = styled(ProfileCardContent)`
  ${ComponentStyles}
`;

StyledClientProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledClientProfileCardContent.defaultProps = {
}

export default StyledClientProfileCardContent;
