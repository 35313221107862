import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionTagGroupProfileCardContent
  from 'components/organisms/Cards/CollectionTagGroupProfileCardContent/CollectionTagGroupProfileCardContent.styles';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import Card from 'components/atoms/Cards/Card/Card';
import Button from 'components/atoms/Buttons/Button/Button';
import Add from '@mui/icons-material/Add';
import TagGroupCard from 'components/organisms/Cards/TagGroupCard/TagGroupCard';
import {Span} from 'components/atoms/Text/Typography/Typography';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';
import constants from 'helpers/constants';
import Circle from '@mui/icons-material/Circle';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import ColorField from 'components/molecules/Fields/ColorField/ColorField';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import TagsForm from 'components/organisms/Forms/TagsForm/TagsForm';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const CollectionTagGroupProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    tagGroup,
    collection,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'CollectionTagGroupProfileCardContent', {
    static: ['isAddNew', 'isEditing', 'isDeleted']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  const [formValues, setFormValues] = useState({});

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const client = useAuthClient();
  const hasAutoTags = Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) || Boolean(collection?.autotagPeriod !== constants.services.periods.never);

  const isAddNew = !utils.isDefined(tagGroup?.groupId);
  const isEditing = content.state.isEditing;
  const isLoading = !utils.isDefined(tagGroup?.name) || !utils.isDefined(tagGroup?.tags);

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'name',
      label: 'Name',
      type: constants.formFieldTypes.text,
      entity: 'tagGroup',
      formGroup: 'header',
      initial: tagGroup?.name,
      required: true,
      FormFieldProps: {
        fullWidth: false,
        variant: 'inlineLabel',
        hiddenLabel: true,
        size: 'smaller'
      }
    });

    fields.push({
      title: 'Settings',
      formGroup: 'context',
      section: true
    });

    fields.push({
      name: 'description',
      label: 'Description',
      type: constants.formFieldTypes.textarea,
      entity: 'tagGroup',
      formGroup: 'context',
      initial: tagGroup?.description,
      required: (formValues?.autoTagType ?? tagGroup?.autoTagType) === constants.collection.autoTagTypes.byAI,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: true,
        size: 'smaller',
        minRows: 2
      }
    });

    fields.push({
      name: 'hasPoints',
      label: 'Add points',
      type: constants.formFieldTypes.switch,
      validation: constants.formFieldValidationTypes.boolean,
      entity: 'tagGroup',
      formGroup: 'context',
      initial: tagGroup?.hasPoints ?? false,
      FormFieldProps: {
        variant: 'inlineLabel',
        fullWidth: true
      }
    });

    fields.push({
      name: 'multiselect',
      label: 'Multiselect',
      type: constants.formFieldTypes.switch,
      validation: constants.formFieldValidationTypes.boolean,
      entity: 'tagGroup',
      formGroup: 'context',
      initial: tagGroup?.multiselect ?? false,
      FormFieldProps: {
        variant: 'inlineLabel',
        fullWidth: true
      }
    });

    fields.push({
      name: 'color',
      label: 'Color',
      formGroup: 'context',
      entity: 'tagGroup',
      type: constants.formFieldTypes.popper,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      initial: tagGroup?.color,
      readOnly: false,
      FormFieldProps: {
        hiddenLabel: false,
        variant: 'inlineLabel',
        size: 'smallest',
        fullWidth: true,
        FormFieldComponent: ColorField,
        FormFieldProps: {
          colorSet: 'tagGroup',
          IconButtonProps: {variant: 'outlined', size: 'smaller', density: 'normal'},
          IconProps: {size: 'smaller'},
        },
        PopperProps: {
          ContextPopperProps: {
            density: 'dense',
            size: 'medium',
            placement: 'bottom-end'
          }
        },
        renderButton: (value, {ref, className, readOnly, disabled, onClick}) => {
          if (readOnly) {
            return <Icon icon={Circle} size="small" color={(value?.value ?? value) || 'tagGroupDefault'}/>
          } else {
            return <IconButton ref={ref}
                               className={className}
                               variant="outlined"
                               size="smaller"
                               density="sparse"
                               color={(value?.value ?? value) || 'tagGroupDefault'}
                               disabled={Boolean(disabled || readOnly)}
                               onClick={onClick}>
              <Icon icon={Circle} size="smaller"/>
            </IconButton>
          }
        }
      }
    });

    fields.push({
      name: 'autoTagType',
      label: <Box>
        <Span>Auto-assign tags</Span>
        <ActionIconButton action={{
                            icon: InfoOutlined,
                            tooltip: hasAutoTags ? 'See Autotags service for more info' :
                              'Active the Autotags service to enable',
                            onClick: () => null,
                          }}
                          disableRipple={true}
                          flatBackground={true}
                          size="smaller" density="dense" variant="outlined"
                          TooltipProps={{enterDelay: 0}}
                          IconProps={{size: 'tiny'}} />
      </Box>,
      type: constants.formFieldTypes.category,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      options: 'autoTagTypes',
      initial: tagGroup?.autoTagType ?? constants.collection.autoTagTypes.noAutoAssign,
      formGroup: 'context',
      entity: 'tagGroup',
      required: false,
      disabled: !hasAutoTags,
      FormFieldProps: {
        multiple: false,
        limitOptions: false,
        hiddenIcons: false,
        readOnlyChip: false,
        IconProps: {
          color: 'primary'
        },
        ListProps: {
          orientation: 'vertical',
          catchFocus: false,
          gap: 8
        },
        CategoryCardProps: {
          size: 'smaller',
          density: 'sparse',
          iconDensity: 'denser',
          variant: 'inline',
          showSelected: true
        }
      }
    });

    fields.push({
      name: 'tags',
      label: 'Tags',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.tag}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'tagGroup',
      valueProp: 'tags',
      formGroup: 'content',
      Component: <TagsForm autoTagType={formValues?.autoTagType ?? tagGroup?.autoTagType}
                           hasPoints={formValues?.hasPoints ?? tagGroup?.hasPoints} />,
      initial: {
        value: tagGroup?.tags ?? [],
        errors: false
      },
      required: true,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: false,
        debounce: false,
        autoFocus: false
      }
    });

    return fields
      .filter((f) => !f.readOnly || !utils.isEmpty(f.initial));
  }, [tagGroup, hasAutoTags, formValues?.hasPoints, formValues?.autoTagType]);

  const handleSubmit = (values, actions) => {
    const name = `tagGroup-${tagGroup?.groupId ?? 0}`;
    const fields = [{
      name
    }];

    const changes = {
      [name]: {...tagGroup, groupId: tagGroup?.groupId ?? 0, ...values},
    };

    return onSubmit(changes, actions, fields, changes[name], true);
  }

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, isAddNew || dirty, errors);
  }

  const onValidatingEvent = useEffectEvent(onValidating);
  useEffect(() => {
    if (isAddNew && isEditing) {
      onValidatingEvent?.(false, true, false);
    }
  }, [isAddNew, isEditing, onValidatingEvent]);

  const renderReadOnly = () => {
    return <TagGroupCard tagGroup={tagGroup}
                         hasAutoTags={hasAutoTags}
                         isLoading={isLoading} />
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    setFormValues(utils.updater({[field.name]: (value?.value ?? value)}, true));
  }

  const renderForm = () => {
    const renderHeader = (renderedFields) => {
      return <DialogHeader title={<React.Fragment>{renderedFields}</React.Fragment>} />
    }

    const renderContext = (renderedFields) => {
      return <Box className="CollectionTagGroupProfileCardContent-form-context">
        <Box className="Form-fields">
          {renderedFields}
        </Box>
      </Box>
    }

    const renderFormContent = (groups) => {
      const header = groups.find((g) => g.name === 'header');
      const context = groups.find((g) => g.name === 'context');
      const content = groups.find((g) => g.name === 'content');

      return <React.Fragment>
        {renderHeader(header.rendered)}
        <DialogContent className="ProfileCardDialog-content">
          <ContextWrapper context={renderContext(context.rendered)}
                          open={true}
                          size="smallish">
            <Box className="Form-fields CollectionTagGroupProfileCardContent-form-content">
              {content.rendered}
            </Box>
          </ContextWrapper>
        </DialogContent>
      </React.Fragment>
    }

    return <InlineForm ref={formRef}
                       className="CollectionTagGroupProfileCardContent-form"
                       fields={fields}
                       fieldData={fieldData}
                       renderContent={renderFormContent}
                       onChangeDirect={handleChangeDirect}
                       onSubmit={handleSubmit}
                       onValidating={handleValidating}/>
  }

  const renderNew = () => {
    return <Card className="CollectionTagGroupProfileCardContent-new" fullWidth={true} fullHeight={true}>
      <Button variant="contained"
              startIcon={<Icon icon={Add} />}
              onClick={() => {
                card.edit()
              }}>
        Add category
      </Button>
    </Card>
  };

  delete innerProps.source;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing
  });

  return <StyledCollectionTagGroupProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="CollectionTagGroupProfileCardContent-content">
      {!isDialog ? (isAddNew ? renderNew() : renderReadOnly()) : renderForm()}
    </Box>
  </StyledCollectionTagGroupProfileCardContent>
});

CollectionTagGroupProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  collection: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func
};

CollectionTagGroupProfileCardContent.defaultProps = {
};

export default CollectionTagGroupProfileCardContent;


