import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import StyledMainLoader from 'components/molecules/Loaders/MainLoader/MainLoader.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import {H1} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Portal from '@mui/material/Portal';
import Box from 'components/atoms/Layout/Box/Box';

const MainLoader = (props) => {
  const {
    show,
    ...innerProps
  } = useComponentProps(props, 'MainLoader');

  const scrollYRef = useRef(null);

  useEffect(() => {
    if (show) {
      // When the modal is shown, we want a fixed body
      scrollYRef.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollYRef.current}px`;
    } else {
      document.body.style.position = null;
      document.body.style.top = null;
      if (utils.isDefined(scrollYRef.current)) {
        window.scrollTo(0, scrollYRef.current);
        scrollYRef.current = null;
      }
    }
  }, [show]);

  return <Portal>
    <StyledMainLoader {...innerProps} style={{display: show ? 'unset' : 'none'}}>
      <Box>
        <H1>Loading...</H1>
      </Box>
    </StyledMainLoader>
  </Portal>
};

MainLoader.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

MainLoader.defaultProps = {
  show: false
};

export default MainLoader;
