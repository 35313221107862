import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import StyledPatentBarChart from 'components/organisms/Charts/PatentBarChart/PatentBarChart.styles';
import PatentsTooltip from 'components/molecules/Tooltips/PatentsTooltip/PatentsTooltip';

const PatentBarChart = React.forwardRef((props, ref) => {
  const {
    patents,
    period,
    level,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'PatentBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const patentsMemo = useMemo(() => {
    if (patents && period && level) {
      const flattenCpcs = (p, a = []) => {
        a.push(p);
        if (p.children) {
          p.children.forEach((ch) => flattenCpcs(ch, a))
        }

        return a;
      }

      const from = new Date(`${(new Date()).toISOString().split('-').slice(0, 1).join('-')}-01-01T00:00:00.000Z`);
      from.setYear(from.getFullYear() - (period.years - 1));
      const to = new Date(`${(new Date()).toISOString().split('-').slice(0, 1).join('-')}-01-01T00:00:00.000Z`);

      const points = patents.map((p) => ({
        ...p,
        date: new Date(`${p.filingYear}-01T00:00:00.000Z`),
        cpcs: p.cpcs.reduce((a, cpc) => a.concat(flattenCpcs(cpc)), [])
      }))
        .filter((p) => {
          return p.date.getTime() >= from.getTime() && p.date.getTime() <= to.getTime()
        })
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      const levelCodes = points.reduce((a, p) => {
        p.cpcs.forEach((cpc) => {
          if (+cpc.level === +level.level) {
            if (!a.find((code) => code.code === cpc.cpc)) {
              a.push({
                code: cpc.cpc,
                level: cpc.level,
                title: cpc.title,
                color: utils.string2Color(cpc.cpc)
              });
            }
          }
        });

        return a;
      }, [])
        .sort((a, b) => {
          return (a.level - b.level) ? a.level - b.level : (
            b.code.localeCompare(a.code)
          )
        });

      return points.map((p, idx) => {
        return {
          id: p.filingYear,
          name: p.filingYear,
          label: utils.dayjs(p.date).format('\'YY'),
          position: idx,
          breakdown: levelCodes.map((code, idx) => {
            const cpc = p.cpcs.find((cpc) => {
              return +cpc.level === +level.level && cpc.cpc === code.code;
            });

            return {
              id: code.code,
              label: `${code.code} (${p.filingYear})`,
              description: code.title,
              position: idx,
              count: cpc?.count ?? 0,
              countLabel: `${utils.numberToLabel(cpc?.count ?? 0, 1)} patents`,
              color: code.color
            }
          })
        }
      });
    } else {
      return null
    }
  }, [patents, period, level]);

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (patentsMemo) {
      legend = patentsMemo?.[0]?.breakdown
        .map((bd) => {
          return {
            id: bd.id,
            position: bd.position,
            label: bd.label,
            color: bd.color,
            active: visibility?.[bd.id] ?? true,
            meta: bd
          }
        }) ?? [];
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [patentsMemo, visibility]);

  const patentData = useMemo(() => {
    if (patentsMemo) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: patentsMemo?.[0]?.breakdown
          .map((bd) => {
            return {
              id: bd.id,
              name: bd.id,
              position: bd.position,
              dataKey: bd.id,
              label: bd.label,
              color: bd.color
            }
          })
          .filter((bd) => {
            return Boolean(legend.find((l) => l.active && l.id === bd.id))
          }),
        data: patentsMemo
          .reduce((a, p) => {
            let item = a.find((i) => i.id === p.id);
            if (!item) {
              item = {
                id: p.id,
                name: p.label,
                position: p.position
              };
              a.push(item);
            }

            p.breakdown.forEach((bd) => {
              if (Boolean(legend.find((l) => l.active && l.id === bd.id))) {
                item[bd.id] = bd.count;
                item[`${bd.id}-meta`] = bd
              }
            });

            return a;
          }, [])
          .sort((a, b) => a.position - b.position),
        XAxisProps: {
          tickSize: 4,
          minTickGap: 24,
          interval: 'preserveStartEnd',
          tick: (props) => {
            return <text className="recharts-cartesian-axis-tick-value"
                         orientation={props.orientation}
                         width={props.width} height={props.height}
                         x={props.x} y={props.y}
                         textAnchor={props.payload.index === 0 ? 'start' : (
                           props.payload.index === patentsMemo.length - 1 ? 'end' : 'middle'
                         )}>
              <tspan x={props.x} dy="0.71em">{props.payload.value}</tspan>
            </text>
          }
        },
        YAxisProps: {
          tickSize: 4,
          orientation: 'right',
          tickFormatter: (v) => {
            return `${utils.numberToLabel(v)}`;
          }
        },
        GridProps: {
          strokeDasharray: '0 0',
          vertical: false
        },
        TooltipComponent: PatentsTooltip
      };
    }
  }, [patentsMemo, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPatentBarChart ref={ref} {...innerProps}>
    <Box className="PatentBarChart-chart">
      <BarChart {...patentData}
                showGrid
                showTooltip
                showCursor
                isLoading={isLoading}
                radius={4}
                barSize={16}
                layout="horizontal"
                type="category"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  variant: 'breakdown',
                  placement: 'right',
                  gap: 12
                }}
                margin={{
                  top: 8, right: -22, bottom: -8, left: 32
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="PatentBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledPatentBarChart>
});

PatentBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  traction: PropTypes.array,
  period: PropTypes.object,
  level: PropTypes.object,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  AreaChartProps: PropTypes.object
};

PatentBarChart.defaultProps = {
};

export default PatentBarChart;
