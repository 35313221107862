import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledStepButton from 'components/atoms/Steppers/StepButton/StepButton.styles';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

const StepButton = withMemo(React.forwardRef((props, ref) => {
  const {
    hideNumber,
    expandIcon,
    ...innerProps
  } = useComponentProps(props, 'StepButton', {
    static: ['hideNumber', 'expandIcon']
  });

  const renderChildren = () => {
    if (expandIcon) {
      return <React.Fragment>
        {innerProps.children}
        {expandIcon}
      </React.Fragment>
    } else {
      return innerProps.children;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledStepButton ref={ref} {...innerProps}>
    {renderChildren()}
  </StyledStepButton>
}));

StepButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  label: PropTypes.any,
  expandIcon: PropTypes.any,
  hideNumber: PropTypes.bool
};

StepButton.defaultProps = {};

export default StepButton;
