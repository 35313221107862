import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAccordionDetails from 'components/atoms/Accordions/AccordionDetails/AccordionDetails.styles';

const AccordionDetails = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'AccordionDetails');

  return <StyledAccordionDetails ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledAccordionDetails>
});

AccordionDetails.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

AccordionDetails.defaultProps = {};

export default AccordionDetails;
