import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import StyledTasksList from 'components/organisms/Lists/TasksList/TasksList.styles';
import TaskCard from 'components/molecules/Cards/TaskCard/TaskCard';
import Component from 'components/organisms/Utils/Component/Component';

const TasksList = React.forwardRef((props, ref) => {
  const {
    task,
    tasks,
    Card,
    onClick,
    isLoading,
    TaskCardProps,
    ...innerProps
  } = useComponentProps(props, 'TasksList');

  const TaskCardDefault = ({task, ...props}) => {
    return <TaskCard task={task}
                     fullHeight={innerProps.orientation === 'horizontal'}
                     fullWidth={innerProps.orientation !== 'vertical'}
                     {...props}/>;
  };

  const CardMemo = useEffectItem(Card ?? TaskCardDefault);
  const renderCard = (task, state) => {
    return <Component Original={CardMemo}
                      task={task}
                      selected={state.selected}
                      onClick={onClick}
                      isLoading={state.isLoading}
                      {...TaskCardProps} />
  };

  const isItemEqual = (task, selected) => {
    return task.commentId === selected.commentId;
  }

  return <StyledTasksList ref={ref} {...innerProps}
                          data={tasks?.data}
                          count={tasks.meta?.resultsCount}
                          dataKey="commentId"
                          isItemEqual={isItemEqual}
                          renderItem={renderCard}
                          isLoading={isLoading || tasks.status.isLoading}
                          showProgressBar={tasks.status.isLoadingNext}
                          onFetchMore={tasks.query?.fetchNextPage}
                          ListProps={{
                            track: true,
                            virtualize: true,
                            gap: 8,
                          }}/>
});

TasksList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  task: PropTypes.object,
  tasks: PropTypes.object,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  TaskCardProps: PropTypes.object
};

TasksList.defaultProps = {
  loaderCount: 6
};

export default TasksList;
