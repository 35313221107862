import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledFormLabel = styled(FormLabel)`
  ${ComponentStyles}
`;

StyledFormLabel.propTypes = {
  theme: PropTypes.object
};

StyledFormLabel.defaultProps = {
}

export default StyledFormLabel;
