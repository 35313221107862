import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledDataList = styled(Box)`
  height: 100%;
  position: relative;

  &:has(.ListItem) {
    > .DataList-list {
      .DataList-empty {
        display: none;
      }
    }
  }

  &:not(:has(.ListItem)) {
    > .DataList-list {
      > .List {
        margin: 0 !important;
        padding: 0 !important;
        height: 0 !important;
      }
    }
  }
  
  .DataList {
    &-empty {
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
    
    &-list {
      width: 100%;
      height: 100%;
      outline: none;
      
      > .List {
        padding: 0;
        .ListItem {
          outline: none;
        }
      }
    }

    &-header, &-footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      pointer-events: none;
      height: ${props => props.theme.layout(48)};
    }
    &-header {
      top: 0;
      
      ${props => props.$overflowHeader ? css`
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 100%, ${props => props.theme.property('palette.background.paper')} 0%);
      ` : css``}
    }
    &-footer {
      bottom: 0;
      
      ${props => props.$overflowFooter ? css`
        background: linear-gradient(0deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 100%);
      ` : css``}
    }
    
    &-progressBar {
      height: ${props => props.theme.layout(2)};
      &.hide {
        visibility: hidden;
      }
    }
  }
  
  &.DataList-orientation {
    &-horizontal {
      > .DataList-list {
        overflow-x: auto;
        ::-webkit-scrollbar {
          height: ${props => props.theme.layout('1sp')};
        }
        &:not(:hover, :focus) {
          ::-webkit-scrollbar {
            height: 0;
          }
        }
      }

      > .DataList-progressBar {
        visibility: hidden;
      }
    }
    
    &-vertical {
      > .DataList-list {
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: ${props => props.theme.layout('1sp')};
        }
        &:not(:hover, :focus) {
          ::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDataList.propTypes = {
  theme: PropTypes.object
};

StyledDataList.defaultProps = {}

export default StyledDataList;
