import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableBar from 'components/organisms/Bars/TableBar/TableBar';

const StyledEntitiesTableBar = styled(TableBar)`
  ${ComponentStyles}
`;

StyledEntitiesTableBar.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesTableBar.defaultProps = {
};

export default StyledEntitiesTableBar;
