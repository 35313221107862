import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Badge from '@mui/material/Badge';
import constants from 'helpers/constants';

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    ${props => props.theme.color2Css('MuiBadge-badge', null, props.color, {
      type: constants.colorTypes.button
    })};
  }
  
  &.Badge-inline, &.Badge-inlineDot {
    .MuiBadge-badge {
      position: unset;
      transform: unset;
    }
    &:has(.Skeleton) {
      margin-top: 0 !important;
    }
    .Skeleton {
      align-self: center;
      margin-top: ${props => props.theme.layout(1)};
    }
  }
  
  .Skeleton {
    padding: 0 ${props => props.theme.spacing(6/8)};
    border-radius: ${props => props.theme.radius(2.5)};
    transform: scale(0.9, 0.9) !important;
  }
  
  &.Badge-size-small {
    >.MuiBadge-standard, > .MuiBadge-inline {
      font-size: ${props => props.theme.fontSize(11)};
      padding: ${props => props.theme.layout(1)} ${props => props.theme.layout('0.5sp')} 0 ${props => props.theme.layout('0.5sp')};
      min-width: ${props => props.theme.layout(16)};
      height: ${props => props.theme.layout(16)};
    }
  }
  &.Badge-size-smaller {
    >.MuiBadge-standard, > .MuiBadge-inline {
      font-size: ${props => props.theme.fontSize(10)};
      padding: 0 ${props => props.theme.layout('0.4sp')} 0 ${props => props.theme.layout('0.45sp')};
      min-width: ${props => props.theme.layout(14)};
      height: ${props => props.theme.layout(14)};
    }
  }

  &.Badge-clickable {
    > .MuiBadge-badge {
      cursor: pointer;
    }
  }
  
  &.Badge-disabled {
    > .MuiBadge-badge {
      pointer-events: none;
      color: ${props => props.theme.property('palette.background.paper')};
      background-color: ${props => props.theme.state2Color(props.color, 'disabled', constants.colorTypes.button, constants.colorTargets.background)};
    }
  }
  
  ${ComponentStyles}
`;

StyledBadge.propTypes = {
  theme: PropTypes.object
};

StyledBadge.defaultProps = {
}

export default StyledBadge;
