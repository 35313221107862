import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListItem from 'components/atoms/Lists/ListItem/ListItem.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const ListItem = withMemo(React.forwardRef((props, ref) => {
  const {
    variant,
    dragging,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ListItem', {
    static: ['dragging']
  });

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledListItem ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledListItem>
}));

ListItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool,
  dragging: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'heading']), PropTypes.string])
};

ListItem.defaultProps = {
};

export default ListItem;
