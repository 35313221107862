import React, {Children} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledButtonGroup from 'components/atoms/Buttons/ButtonGroup/ButtonGroup.styles';
import utils from 'helpers/utils';

const ButtonGroup = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ButtonGroup');

  return <StyledButtonGroup ref={ref} {...innerProps}>
    {Children.toArray(innerProps.children).map((child) => {
      return utils.cloneElement(child, {grouped: true});
    })}
  </StyledButtonGroup>
});

ButtonGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text']), PropTypes.string]),
};

ButtonGroup.defaultProps = {
  children: 'ButtonGroup text'
};

export default ButtonGroup;
