import styled from 'styled-components';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledOutlinedInput = styled(OutlinedInput)`
  ${ComponentStyles}
`;

StyledOutlinedInput.propTypes = {
  theme: PropTypes.object
};

StyledOutlinedInput.defaultProps = {
}

export default StyledOutlinedInput;
