import styled from 'styled-components';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledInput = styled(Input)`
  ${ComponentStyles}
`;

StyledInput.propTypes = {
  theme: PropTypes.object
};

StyledInput.defaultProps = {
}

export default StyledInput;
