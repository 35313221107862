import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledTaskProfileWrapper from 'components/templates/Wrappers/Tasks/TaskProfileWrapper/TaskProfileWrapper.styles';
import TaskProfileProvider from 'components/organisms/Providers/ProfileProvider/TaskProfileProvider';
import TaskProfileFoldout from 'components/organisms/Foldouts/TaskProfileFoldout/TaskProfileFoldout';
import TaskSectionPanel from 'components/organisms/SectionPanels/TaskSectionPanel/TaskSectionPanel';

const TaskProfileWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TaskProfileWrapper');

  const profileProvider = useProfile();

  const handleClose = () => {
    profileProvider.closeTask();
  }

  const renderContext = () => {
    return <TaskProfileProvider taskId={profileProvider.state?.showTask ?? -1}>
      <TaskProfileFoldout onClose={handleClose}>
        <TaskSectionPanel onClose={handleClose}/>
      </TaskProfileFoldout>
    </TaskProfileProvider>
  }

  return <StyledTaskProfileWrapper ref={ref} {...innerProps}
                                   variant="foldout"
                                   context={renderContext()}
                                   open={Boolean(profileProvider.state?.showTask)}
                                   size="larger"
                                   ContextDrawerProps={{
                                     onToggle: (e, open) => {
                                       if (open) {
                                         profileProvider.openTask(profileProvider.state?.showTask);
                                       } else {
                                         profileProvider.closeTask();
                                       }
                                     }
                                   }}>
    {innerProps.children}
  </StyledTaskProfileWrapper>
});

TaskProfileWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TaskProfileWrapper.defaultProps = {
  children: 'EntityTaskProfileWrapper text',
};

export default TaskProfileWrapper;
