import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import List from 'components/atoms/Lists/List/List';

const StyledUserList = styled(List)`
  .ListItemButton {
    gap: ${props => props.theme.layout('1sp')};
    padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1.5)};
  }
  ${ComponentStyles}
`;

StyledUserList.propTypes = {
  theme: PropTypes.object
};

StyledUserList.defaultProps = {}

export default StyledUserList;
