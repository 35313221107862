import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Divider from 'components/atoms/Dividers/Divider/Divider';
import StyledBreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';

const BreakdownTooltip = React.forwardRef((props, ref) => {
  const {
    activeIndex,
    activateByPayload,
    activePayload,
    payload,
    variant,
    gap,
    density,
    isPercentage,
    ...innerProps
  } = useComponentProps(props, 'BreakdownTooltip', {
    styled: ['gap'],
    children: ['content']
  });

  const extended = variant === 'extended' || variant === 'breakdown';
  const payloads = activateByPayload ? payload?.filter((p) => `${p.dataKey}-${activeIndex}` === activePayload) : payload;

  const renderTitle = () => {
    const pl = payloads?.[0];
    if (pl) {
      const item = pl.payload[`${pl.dataKey}-meta`] ?? pl.payload?.['meta'];
      const breakdown = item?.breakdown?.filter((s) => !!s.count);

      return <Box className="BreakdownTooltip-content">
        <Box className="title">
          {item.icon ? <Icon className="icon" icon={item.icon} size="small" alt={item.label} color={item.iconColor} /> : null}
          {item.avatar ? <ActionAvatar className="avatar"
                                       action={{
                                         label: utils.avatarLabel(item.avatar),
                                         tooltip: item.avatarTooltip
                                       }}
                                       color={item.avatarColor}
                                       size="smaller"
                                       {...item.ActionAvatarProps}/> : null}
          <Span>{item.label}</Span>
          <Badge variant="inline"
                 color={item.badgeColor}
                 badgeContent={item.countLabel ?? (isPercentage ? `${pl.payload[pl.dataKey]} %` : pl.payload[pl.dataKey])}/>
        </Box>
        {extended && breakdown?.length > 0 && [
          <Divider key="divider" />,
          <Box key="extended" className="extended">
            {breakdown.map((s, idx) =>
              <Box key={idx} className="breakdown">
                {s.icon ? <Icon className="icon" icon={s.icon} size="smaller" alt={s.label} color={s.iconColor} /> : null}
                {s.avatar ? <ActionAvatar className="avatar"
                                          action={{
                                            label: utils.avatarLabel(s.avatar),
                                            tooltip: s.avatarTooltip
                                          }}
                                          color={s.avatarColor}
                                          size="smaller"
                                          {...s.ActionAvatarProps}/> : null}
                <Span>{s.label}</Span>
                <Badge className="count" variant="inline"
                       color={s.badgeColor}
                       badgeContent={s.countLabel ?? (isPercentage ? `${s.count} %` : s.count)}/>
              </Box>)}
          </Box>
        ]}
      </Box>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBreakdownTooltip ref={ref} {...innerProps}
                                 payload={payload}
                                 title={renderTitle()}
                                 activeIndex={activeIndex}
                                 activateByPayload={activateByPayload}
                                 activePayload={activePayload}/>
});

BreakdownTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  activeIndex: PropTypes.any,
  activateByPayload: PropTypes.bool,
  activePayload: PropTypes.any,
  payload: PropTypes.array,
  density: PropTypes.string,
  isPercentage: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'extended', 'members']), PropTypes.string])
};

BreakdownTooltip.defaultProps = {
  title: 'TasksTooltip text',
  gap: 8
};

export default BreakdownTooltip;
