import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledRecentChangesBarChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('3sp')};
  width: 100%;
  height: 100%;
  overflow: hidden;

  .RecentChangesBarChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledRecentChangesBarChart.propTypes = {
};

StyledRecentChangesBarChart.defaultProps = {
}

export default StyledRecentChangesBarChart;
