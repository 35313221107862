import {css} from 'styled-components';

/* Test styles are used for components with text, spans labels, buttons etc. */
const FormStyles = css`
  .InlineForm {
    .FormControl-inlineLabel {
      > .InputLabel, > .FormLabel {
        min-width: ${props => props.theme.layout(140)};
        max-width: ${props => props.theme.layout(140)};
      }
      
      &:not(.FormControl-readOnly) {
        > .InputLabel, > .FormLabel {
          margin-top: ${props => props.theme.layout(10)};
        }

        &.CheckboxField, &.SwitchField {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout(8)};
          }
        }
      }
      
      &.CheckboxField, &.SwitchField {
        > .InputLabel, > .FormLabel {
          align-self: flex-start;
        }

        .InputContainer {
          justify-content: flex-start !important;
        }
      }
    }
    
    .FormControl-staticLabel {
      .FormLabel, .InputLabel {
        width: 100%;
        ${props => props.theme.font2Css('body2')};
        transform: scale(1);
      }
    }

    .FormControl {
      > .InputLabel, > .MuiFormControlLabel-label, > .FormLabel  {
        ${props => props.theme.font2Css('subtitle2')};
      }

      .Input-readOnly {
        ${props => props.theme.font2Css('body2')};
      }
    }
  }
  
  // overrides
  ${props => props.theme.property('mixins.FormStyles', '')}
  ${props => props.theme.convert2Css('components.FormStyles.styleOverrides.root')}
`

export default FormStyles;
