import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {ChatOutlined} from '@mui/icons-material';
import StyledTimelineTableCell from 'components/molecules/TableCells/TimelineTableCell/TimelineTableCell.styles';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';
import Badge from 'components/atoms/Badges/Badge/Badge';

const TimelineTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    comments,
    unread,
    disabled,
    active,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'TimelineTableCell');

  const onClickEvent = useEffectEvent(onClick);
  const ActionChipProps = useMemo(() => ({
    size: 'large',
    color: 'info',
    variant: 'outlined',
    action: {
      icon: ChatOutlined,
      label: `${utils.isDefined(comments) ? (comments > 99 ? '99+' : comments) : 0} comment${comments > 1 ? 's' : ''}`,
      badge: unread ? <Badge color="warning"/> : null,
      ChipProps: {
        selected: active,
        showTooltip: false,
        disabled: disabled
      },
      onClick: (e) => {
        onClickEvent?.(e);
        e.preventDefault();
      }
    }
  }), [onClickEvent, comments, disabled, active, unread]);

  return <StyledTimelineTableCell ref={ref} {...innerProps} ActionChipProps={ActionChipProps}/>
}));

TimelineTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  comments: PropTypes.number,
  unread: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

TimelineTableCell.defaultProps = {
};

export default TimelineTableCell;


