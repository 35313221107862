import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Email from 'components/pages/Base/Email/Email';

const StyledWelcomeEmailPage = styled(Email)`
  &.WelcomeEmailPage {
    .Table-outer {
      .Table.Table-inner {
        .TableRow {
          &.WelcomeEmailPage {
            &-resetButtonRow {
              .TableCell {
                padding-top: ${props => props.theme.spacing(2)};
                padding-bottom: ${props => props.theme.spacing(2)};
              }
            }

            &-pluginButtonRow {
              .TableCell {
                padding-top: ${props => props.theme.spacing(1)};
                padding-bottom: ${props => props.theme.spacing(3)};
              }
            }

            &-gettingStartedRow {
              .H6 {
                font-weight: 500;
              }

              .P {
                padding: 0;
                margin: 0;
              }
            }

            &-pluginRow, &-pluginInstallRow {
              .H6 {
                font-weight: 500;
              }

              .P {
                padding: 0;
                margin: 0;
              }
            }

            &-pluginInstallRow {
              .TableCell {
                padding-bottom: ${props => props.theme.spacing(2)};
              }

              .List {
                padding: ${props => props.theme.spacing(1)} 0 ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};

                .ListItem {
                  list-style-type: disc;
                  display: list-item;

                  padding: ${props => props.theme.spacing(0.5)} 0;
                }
              }
            }
          }

          .TableCell {
            .List {
              .List {
                padding: 0 0 0 ${props => props.theme.spacing(2)};

                .ListItem {
                  list-style-type: disc;
                  display: list-item;
                }
              }
            }

            .ListItem {
              display: block;

              padding: ${props => props.theme.spacing(1)} 0;

              .P {
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledWelcomeEmailPage.propTypes = {
  theme: PropTypes.object
};

StyledWelcomeEmailPage.defaultProps = {
};

export default StyledWelcomeEmailPage;
