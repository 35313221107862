import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledEntityPatentGraphProfileCardContent = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .EntityPatentGraphProfileCardContent {
    &-type {
      position: absolute;
      right: -${props => props.theme.layout('0.5sp')};
      width: ${props => props.theme.layout(116)};
      top: -${props => props.theme.layout('6sp')};

      background-color: ${props => props.theme.property('palette.background.paper')};
    }
    
    &-chart {
      aspect-ratio: 312 / 256;
    }
    
    &-tabs {
      justify-content: center;
      
      .Tab {
        min-width: unset;
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.25)}; 
      } 
      
      .MuiTabs-scroller {
        width: fit-content;
        flex: unset;
      }
    }
    
    &-info {
      width: 100%;
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('0.5sp')};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      padding-top: ${props => props.theme.spacing(1)};
      margin-top: ${props => props.theme.spacing(1.5)};
      
      .Typography {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: ${props => props.theme.layout(2)};
      }
    }
    
    &-empty {
      height: 100%;
      min-height: ${props => props.theme.layout('6sp')};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityPatentGraphProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityPatentGraphProfileCardContent.defaultProps = {}

export default StyledEntityPatentGraphProfileCardContent;
