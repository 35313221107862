import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledFileCard from 'components/molecules/Cards/FileCard/FileCard.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Delete from '@mui/icons-material/Delete';
import Typography from 'components/atoms/Text/Typography/Typography';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Link from 'components/atoms/Links/Link/Link';
import Box from 'components/atoms/Layout/Box/Box';

const FileCard = React.forwardRef((props, ref) => {
  const {
    file,
    isLoading,
    onDelete,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'FileCard', {
    static: ['selected'],
    children: ['title', 'subtitle', 'delete']
  });

  const authorize = useAuthorize();

  const onDeleteEvent = useEffectEvent(onDelete);
  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const deleteAction = useMemo(() => {
    const canDeleteFile = (onCanUpdateEvent ? onCanUpdateEvent?.(file, true) : true) && authorize({
      attribute: 'file.delete', meta: {file}
    });

    return canDeleteFile ? {
      icon: <Icon icon={Delete}/>,
      tooltip: 'Remove file',
      onClick: (e) => {
        onDeleteEvent?.(file);
        e.preventDefault();
        e.stopPropagation();
      }
    } : null;
  }, [file, authorize, onCanUpdateEvent, onDeleteEvent]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledFileCard ref={ref} {...innerProps} variant="outlined">
    <Box className="FileCard-info">
      <Link href="#" isLoading={isLoading}
            onClick={innerProps.onClick}
            showTooltip={true}>
        {file.name ?? file.filename}
      </Link>
      {(file.description || isLoading) ? <Typography className="FileCard-description"
                                                     isLoading={isLoading}
                                                     min={8} max={20}
                                                     variant="caption"
                                                     showTooltip={true}>
        {file.description}
      </Typography> : null}
    </Box>
    {deleteAction ? <ActionIconButton action={deleteAction}
                                      size="smaller"
                                      density="sparse"
                                      variant="transparent"
                                      isLoading={isLoading}
                                      IconProps={{
                                        size: 'smaller'
                                      }}/> : null}
  </StyledFileCard>
});

FileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  file: PropTypes.object,
  selected: PropTypes.bool,
  onDelete: PropTypes.func,
  onCanUpdate: PropTypes.func
};

FileCard.defaultProps = {
};

export default FileCard;
