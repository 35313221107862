import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import StyledActionLogo from 'components/molecules/Images/ActionLogo/ActionLogo.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';

const ActionLogo = React.forwardRef((props, ref) => {
  const {
    action,
    size,
    color,
    onClick,
    TooltipProps,
    IconProps,
    LogoProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionLogoProps}, 'ActionLogo', {
    static: ['disabled', 'clickable'],
    children: ['logo']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm) {
    return <React.Fragment/>;
  } else {
    innerProps.className = utils.flattenClassName(innerProps.className, {
      disabled: !itm,
      clickable: Boolean(handleClick)
    });

    return <StyledActionLogo ref={ref} {...innerProps}>
      <Tooltip title={itm.tooltip}
               placement={itm.tooltipPosition ?? 'bottom'}
               {...TooltipProps} {...itm.TooltipProps}>
        <Box>
          {itm.icon ? <Icon icon={itm.icon}
                            color={color ?? itm.color}
                            size={size ?? itm.size}
                            className="ActionLogo-logo"
                            onClick={handleClick}
                            {...IconProps} /> : null}
          {itm.logo ? <Logo logo={itm.logo}
                            color={color ?? itm.color}
                            size={size ?? itm.size}
                            className="ActionLogo-logo"
                            onClick={handleClick}
                            {...LogoProps}  /> : null}
        </Box>
      </Tooltip>
    </StyledActionLogo>
  }
});

ActionLogo.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  color: PropTypes.string,
  TooltipProps: PropTypes.object,
  IconProps: PropTypes.object,
  LogoProps: PropTypes.object
};

ActionLogo.defaultProps = {
};

export default ActionLogo;
