import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledImageList from 'components/atoms/Lists/ImageList/ImageList.styles';

const ImageList = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ImageList');

  return <StyledImageList ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledImageList>
});

ImageList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ImageList.defaultProps = {
  children: 'ImageList text'
};

export default ImageList;
