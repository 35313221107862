import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDatabaseEntityProfilePage = styled(Page)`
  .Profile {
    &:not(.Profile-isEditing) {
      padding-top: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledDatabaseEntityProfilePage.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityProfilePage.defaultProps = {
};

export default StyledDatabaseEntityProfilePage;
