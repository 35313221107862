import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityBrowserBarWrapper/CollectionEntityBrowserBarWrapper.styles';
import CollectionEntityBrowserBar
  from 'components/organisms/Bars/CollectionEntityBrowserBar/CollectionEntityBrowserBar';

const CollectionEntityBrowserBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityBrowserBarWrapper');

  return <StyledCollectionEntityBrowserBarWrapper ref={ref} {...innerProps}
                                                  variant="sticky">
    {innerProps.children}
  </StyledCollectionEntityBrowserBarWrapper>
});

CollectionEntityBrowserBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityBrowserBarWrapper.defaultProps = {
  ToolbarComponent: CollectionEntityBrowserBar
};

export default CollectionEntityBrowserBarWrapper;
