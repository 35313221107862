import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntitiesList from 'components/organisms/Lists/DealflowEntitiesList/DealflowEntitiesList.styles';

const DealflowEntitiesList = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesList');

  return <StyledDealflowEntitiesList ref={ref} {...innerProps} />
});

DealflowEntitiesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesList.defaultProps = {
};

export default DealflowEntitiesList;
