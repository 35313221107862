import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledEntityTimelineTableCellEdit = styled(DropdownTableCellEdit)`
  &.EntityTimelineTableCellEdit {
    width: ${props => props.theme.layout(352)};
    height: min(${props => props.theme.layout(320)}, 50vh);
  }
  
  .TimelinePanel-feed {
    .DataList-list {
      padding: ${props => props.theme.spacing(2)};
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityTimelineTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledEntityTimelineTableCellEdit.defaultProps = {}

export default StyledEntityTimelineTableCellEdit;
