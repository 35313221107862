import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntitiesTableColumns
  from 'components/organisms/TableColumns/DealflowEntitiesTableColumns/DealflowEntitiesTableColumns.styles';

const DealflowEntitiesTableColumns = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesTableColumns');

  return <StyledDealflowEntitiesTableColumns ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDealflowEntitiesTableColumns>
});

DealflowEntitiesTableColumns.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesTableColumns.defaultProps = {};

export default DealflowEntitiesTableColumns;
