import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledTableColumns = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  
  .TableColumns {
    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: hidden;
    }

    &-presets {
      display: flex;
      align-items: baseline;
      padding-bottom: ${props => props.theme.spacing(1)};
      margin-bottom: ${props => props.theme.spacing(2)};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      
      > .Typography {
        margin-right: ${props => props.theme.spacing(2)};
      }
    }
    
    &-groups {
      flex-grow: 1;
      overflow-y: auto;
      padding: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(10)} ${props => props.theme.spacing(3)};
      
      .List {
        padding: 0;
      }
      .TableColumns-group {
        padding: ${props => props.theme.spacing(2)} 0;
      }
      .TableColumns-group:first-child {
        padding-top: 0;
      }
      .TableColumns-group:last-child {
        padding-bottom: 0;
      }
      .TableColumns-group:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
    
    &-empty {
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(2)} 0;
      }
    }
    
    &-toolbar {
      gap: ${props => props.theme.layout('1sp')};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      &-title {
        flex-grow: 1;
      }
    }
    
    &-search {
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      
      .TextField {
        background-color: ${props => props.theme.property('palette.background.default')};
      }
    }
    
    &-save {
      width: 100%;
      position: absolute;
      bottom: ${props => props.theme.layout('3sp')};
      display: flex;
      justify-content: center;
      
      .Fab {
        width: ${props => props.theme.layout(360)};
      }
    }
  }
  ${ComponentStyles}
`;

StyledTableColumns.propTypes = {
  theme: PropTypes.object
};

StyledTableColumns.defaultProps = {}

export default StyledTableColumns;
