import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfile from 'components/organisms/Profiles/EntityProfile/EntityProfile';

const StyledDealflowEntityProfile = styled(EntityProfile)`
  ${ComponentStyles}
`;

StyledDealflowEntityProfile.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntityProfile.defaultProps = {
};

export default StyledDealflowEntityProfile;
