import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';

const StyledResolveEntityMergeWizardContent = styled(WizardContent)`
  &.ResolveEntityMergeWizardContent {
    .ResolveEntityMergeWizardContent {
      &-content {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('6sp')};
      }
      
      &-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: ${props => props.theme.layout('5sp')};
        
        gap: ${props => props.theme.layout('2sp')};
      }
      
      &-explanation {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('0.5sp')};
        
        .name {
          font-weight: 500;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledResolveEntityMergeWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledResolveEntityMergeWizardContent.defaultProps = {}

export default StyledResolveEntityMergeWizardContent;
