import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import utils from 'helpers/utils';
import {useAuth, useAuthClientId, useAuthSetTeamId} from 'services/auth/auth.utils';
import {useClientUserUpdate} from 'services/client/user/user.hooks';
import ActionList from 'components/molecules/Lists/ActionList/ActionList';
import StyledSwitchTeamForm from 'components/organisms/Forms/SwitchTeamForm/SwitchTeamForm.styles';
import {useAuthTeam, useAuthTeams, useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const SwitchTeamForm = (props) => {
  const {
    teamId,
    autoFocus,
    onError,
    onSwitching,
    onSuccess,
    ...innerProps
  } = useComponentProps(props, 'SwitchTeamForm', {
    children: ['teams']
  });

  const auth = useAuth();
  const user = useAuthUser();
  const team = useAuthTeam();
  const teams = useAuthTeams();
  const clientId = useAuthClientId();

  const userUpdate = useClientUserUpdate();
  const setAuthTeamId = useAuthSetTeamId();

  const initialised = useRef(null);
  const [internalState, setInternalState] = useState({});

  const teamForm = useMemo(() => ({
    state: {
      ...internalState,
      ...utils.cleanObject({teamId})
    }
  }), [internalState, teamId]);

  const onErrorEvent = useEffectEvent(onError);
  const onSwitchingEvent = useEffectEvent(onSwitching);
  useEffect(() => {
    if (teamForm.state.teamId > 0 && teamForm.state.teamId !== initialised.current) {
      initialised.current = teamForm.state.teamId;
      if (teamForm.state.teamId > 0) {
        const team = teams?.find((team) => +team.clientId === +teamForm.state.teamId);
        if (team) {
          onSwitchingEvent?.(true);
          userUpdate.mutation.mutateAsync({clientId, userId: user?.userId, team_id: teamForm.state.teamId})
            .then(() => {
              setAuthTeamId(teamForm.state.teamId);
            });
        } else {
          onErrorEvent?.('Team not found');
        }
      }
    }
  }, [clientId, user?.userId, teamForm.state.teamId, teams, userUpdate.mutation, onSwitchingEvent, onErrorEvent, setAuthTeamId]);

  useEffect(() => {
    if (initialised.current) {
      if (userUpdate.status.error) {
        onSwitchingEvent?.(false);
        onErrorEvent?.('Switching to team failed.');
      }
    }
  }, [userUpdate.status.error, onErrorEvent, onSwitchingEvent]);

  const onSuccessEvent = useEffectEvent(onSuccess);
  useEffect(() => {
    const switched = +auth?.teamId === +teamForm.state.teamId;
    if (switched) {
      onSuccessEvent?.();
    }
  }, [auth?.teamId, teamForm.state.teamId, setAuthTeamId, onSuccessEvent, onSwitchingEvent]);

  // show team selection
  const actions = useMemo(() => {
    return (teams || []).reduce((a, t) => {
      a.push({
        label: `${t.name}`,
        auth: utils.createAuth({attribute: 'team.switch', meta: {team: t, user}}),
        onClick: () => {
          if (+t?.teamId !== +team.teamId) {
            setInternalState(utils.updater({teamId: +t.teamId}, true));
          } else {
            onSuccessEvent?.();
          }
        },
        icon: <Avatar color="primary">{utils.avatarLabel(t.name) ?? ''}</Avatar>,
        chip: +t?.teamId === +team?.teamId ? 'Current' : null,
        chipPosition: 'end'
      });

      return a;
    }, []);
  }, [teams, team, user, onSuccessEvent]);

  return <StyledSwitchTeamForm className="SwitchTeamForm-teams" {...innerProps}>
    <ActionList className="SwitchTeamForm-teams"
                ListProps={{
                  autoFocus
                }}
                ActionListItemProps={{
                  ListItemButtonProps: {
                    variant: 'directional',
                    direction: 'right',
                    color: 'primary'
                  }
                }}
                actions={actions}/>
  </StyledSwitchTeamForm>
};

SwitchTeamForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  teamId: PropTypes.number,
  autoFocus: PropTypes.bool,
  onSwitching: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

SwitchTeamForm.defaultProps = {};

export default SwitchTeamForm;
