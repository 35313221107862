import {useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';

const path = utils.servicePath(import.meta.url);

export function useClientBudgetStatus () {
  return useServiceStatus(path);
}

export function useClientBudgetList ({clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}
