import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledIconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell.styles';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {withMemo} from 'helpers/wrapper';

const IconButtonTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    ActionIconButtonProps,
    ...innerProps
  } = useComponentProps(props, 'IconButtonTableCell');

  return <StyledIconButtonTableCell ref={ref} {...innerProps}>
    <ActionIconButton {...ActionIconButtonProps} />
  </StyledIconButtonTableCell>
}));

IconButtonTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionButtonProps: PropTypes.object
};

IconButtonTableCell.defaultProps = {
};

export default IconButtonTableCell;


