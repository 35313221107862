import React, {useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedRef} from 'helpers/hooks/utils';
import StyledDatabaseEntitiesQueryBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesQueryBarWrapper/DatabaseEntitiesQueryBarWrapper.styles';
import DatabaseEntitiesQueryBar from 'components/organisms/Bars/DatabaseEntitiesQueryBar/DatabaseEntitiesQueryBar';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import {useLocation} from 'react-router-dom';
import dom from 'helpers/dom';

const DatabaseEntitiesQueryBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesQueryBarWrapper', {
    static: ['collapse']
  });

  const innerRef = useRef(null);
  const tableProvider = useTable();

  const view = tableProvider?.view;

  const closedRef = useUpdatedRef(!tableProvider.state?.showQuery);
  const [closed, setClosed] = useState(!tableProvider.state?.showQuery);

  useImperativeHandle(ref, () => innerRef.current);

  const openEvent = useEffectEvent(tableProvider.openQuery);
  const closeEvent = useEffectEvent(tableProvider.closeQuery);

  useEffect(() => {
    return utils.observeScroll(window, ({y}) => {
      const bBox = dom.getBbox(innerRef.current?.refs?.ref?.current?.querySelector?.(`.DatabaseEntitiesQueryBar`));
      if (tableProvider.state?.showQuery) {
        if (Math.round(bBox.height) > 0 && Math.round(y) >= Math.round(bBox.height)) {
          closeEvent?.();
        }
      } else {
        if (Math.round(bBox.height) > 0) {
          if (Math.round(y) < Math.round(bBox.height)) {
            openEvent?.();
          }
        } else if (Math.round(y) <= 0) {
          setClosed(false);
        }
      }
    });
  }, [openEvent, closeEvent, tableProvider.state?.showQuery]);

  const location = useLocation();
  useLayoutEffect(() => {
    setClosed(closedRef.current);
  }, [location, closedRef]);

  useEffect(() => {
    if (closed) {
      setClosed(false);
    } else {
      const bBox = dom.getBbox(innerRef.current?.refs?.ref?.current?.querySelector?.(`.DatabaseEntitiesQueryBar`));
      if (utils.isDefined(bBox?.height)) {
        window.scrollTo({top: window.scrollY + bBox.height});
      }
    }
  }, [closed]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    collapse: closed || (view && (view?.name !== 'default'))
  });

  return <StyledDatabaseEntitiesQueryBarWrapper ref={innerRef} {...innerProps}
                                                collapsed={!tableProvider.state?.showQuery}
                                                variant="collapse">
    {innerProps.children}
  </StyledDatabaseEntitiesQueryBarWrapper>
});

DatabaseEntitiesQueryBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesQueryBarWrapper.defaultProps = {
  children: 'DatabaseEntitiesBarWrapper text',
  ToolbarComponent: DatabaseEntitiesQueryBar
};

export default DatabaseEntitiesQueryBarWrapper;
