import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledSwitchDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(440)}, 86vw);
  }
  
  &.SwitchDialog {
    .DialogActions {
      padding: ${props => props.theme.spacing(2)};
    }

    .DialogContent {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 0;
    }
  }

  .SwitchDialog {
    &-info {
      ${props => props.theme.font2Css('body2')};
      flex-grow: 1;
    }
  }
  
  ${ComponentStyles}
`;

StyledSwitchDialog.propTypes = {
  theme: PropTypes.object
};

StyledSwitchDialog.defaultProps = {}

export default StyledSwitchDialog;
