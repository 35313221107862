import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useClientStatisticStatus () {
  return useServiceStatus(path);
}

export function useClientStatisticGet ({clientId, statisticId, search, filter, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId, statisticId, search, filter, ...rest}, queryOptions);
}
