import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCenterWrapper from 'components/templates/Wrappers/Basic/CenterWrapper/CenterWrapper.styles';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import {withMemo} from 'helpers/wrapper';

const CenterWrapper = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CenterWrapper', {
    styled: ['centerWidth']
  });

  const parentWrapper = useWrapper();

  return <StyledCenterWrapper ref={ref} {...innerProps} $wrapper={parentWrapper}>
    {innerProps.children}
  </StyledCenterWrapper>
}));

CenterWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  centerWidth: PropTypes.number
};

CenterWrapper.defaultProps = {
  children: 'Center Wrapper text',
  centerWidth: '100%'
};

export default CenterWrapper;
