import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableCell from 'components/atoms/Tables/TableCell/TableCell.styles';
import {withMemo} from 'helpers/wrapper';

const TableCell = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TableCell');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableCell ref={ref} {...innerProps}/>
}));

TableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TableCell.defaultProps = {
};

export default TableCell;
