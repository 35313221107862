import styled from 'styled-components';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledAutocomplete = styled(Autocomplete)`
  ${ComponentStyles}
`;

StyledAutocomplete.propTypes = {
  theme: PropTypes.object
};

StyledAutocomplete.defaultProps = {
}

export default StyledAutocomplete;
