import {useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import logger from 'helpers/logger';

export function useInvalidateQuery () {
  const queryClient = useQueryClient();

  return useCallback((keys, exact = false, refetch = false, remove = false) => {
    if (remove) {
      logger.trace('Remove cached query', keys, exact);
      if (refetch) {
        queryClient.invalidateQueries({
          queryKey: keys,
          exact: exact,
          refetchType: 'active'
        })
          .catch(() => {
            logger.trace('Invalidate cached query failed', keys);
          })
          .finally(() => {
            queryClient.removeQueries({
              queryKey: keys,
              exact: exact
            });
          });
      } else {
        queryClient.removeQueries({
          queryKey: keys,
          exact: exact
        });
      }
      logger.trace('Remove cached query', keys, queryClient.getQueryCache());
    } else {
      logger.trace('Invalidate cached query', keys, refetch, queryClient.getQueryCache());
      queryClient.invalidateQueries({
        queryKey: keys,
        exact: exact,
        refetchType: refetch ? 'active' : 'none'
      })
        .catch(() => {
          logger.trace('Remove cached query', keys);
          return queryClient.removeQueries({
            queryKey: keys,
            exact: exact
          });
        });
    }
  }, [queryClient]);
}
