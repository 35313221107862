import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitySectionPanel from 'components/organisms/SectionPanels/EntitySectionPanel/EntitySectionPanel';

const StyledDatabaseEntitySectionPanel = styled(EntitySectionPanel)`
  ${ComponentStyles}
`;

StyledDatabaseEntitySectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitySectionPanel.defaultProps = {}

export default StyledDatabaseEntitySectionPanel;
