import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useColumnState, useComponentProps, useListState} from 'helpers/hooks/utils';
import {useAuthClientId} from 'services/auth/auth.utils';
import constants from 'helpers/constants';
import Table from 'components/organisms/Tables/Table/Table';
import SettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import utils from 'helpers/utils';
import {useClientBudgetList} from 'services/client/budget/budget.hooks';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import ChipTableCell from 'components/molecules/TableCells/ChipTableCell/ChipTableCell';
import FolderSharp from '@mui/icons-material/FolderSharp';
import {Database, Dealflow} from 'assets/icons';
import {H5} from 'components/atoms/Text/Typography/Typography';
import Cards from 'components/atoms/Cards/Cards/Cards';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import CreditWalletGraphCard from 'components/organisms/Cards/CreditWalletGraphCard/CreditWalletGraphCard';
import CreditDistributionGraphCard
  from 'components/organisms/Cards/CreditDistributionGraphCard/CreditDistributionGraphCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import StyledCreditsSettingPage from 'components/pages/Setting/CreditsSettingPage/CreditsSettingPage.styles';

const info = 'Monitor your credits use and history';

const CreditsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'CreditsSettingPage');

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        name: 'creditsSettings'
      }
    },
    columnState: {
      initial: {
        columnPinning: {
          left: ['date']
        }
      },
      options: {
        name: 'creditsSettings'
      }
    }
  }), []);

  const listState = useListState(options.listState?.initial, options.listState?.options);
  const columnState = useColumnState(options?.columnState?.initial, options?.columnState?.options);

  const clientId = useAuthClientId();
  const budgetList = useClientBudgetList({
    clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enabled: Boolean(clientId)
  });

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'date',
      id: 'date',
      header: 'Date',
      size: 160,
      minSize: 160,
      maxSize: 200,
      enableEditing: false,
      Cell: ({cell}) => {
        const date = cell.getValue();

        return <TextTableCell title={utils.daysAgo(new Date(date))}/>
      }
    });

    columns.push({
      accessorKey: 'budgetPurpose',
      id: 'budgetPurpose',
      header: 'Service / function',
      maxSize: 380,
      enableEditing: false,
      Cell: ({cell}) => {
        const budgetPurpose = cell.getValue();
        const budgetType = constants.data.lookup('budgetPurposes', budgetPurpose);

        const renderIcon = () => {
          return <Box className="CreditsSettingPage-budgetPurpose">
            <Icon icon={budgetType.icon} color={budgetType.iconColor}/>
          </Box>
        }

        return <TextTableCell title={budgetType.label} icon={renderIcon()}/>
      }
    });

    columns.push({
      accessorKey: 'collection',
      id: 'collection',
      header: 'Used in',
      enableEditing: false,
      Cell: ({cell}) => {
        const collection = utils.camelcase(cell.getValue());
        const budgetPurpose = cell.row.original.budgetPurpose;

        const ActionChipProps = {
          action: (collection && !collection?.isUniverse && budgetPurpose !== 'sources') ? {
            label: collection.name,
            icon: FolderSharp,
            navigation: {
              to: `/collections/${collection.collectionId}/entities`
            }
          } : (((collection?.isUniverse || budgetPurpose === 'enrichment') && budgetPurpose !== 'sources') ? {
            label: 'Dealflow',
            icon: Dealflow,
            navigation: {
              to: '/dealflow'
            }
          } : {
            label: 'Database',
            icon: Database,
            navigation: {
              to: '/database'
            }
          }),
          variant: 'outlined',
          color: 'primary',
          size: 'medium'
        };

        return <ChipTableCell ActionChipProps={ActionChipProps}/>
      }
    });

    columns.push({
      accessorKey: 'credits',
      id: 'credits',
      header: 'Credits',
      minSize: 120,
      maxSize: 120,
      size: 120,
      enableEditing: false,
      Cell: ({cell}) => {
        const credits = cell.getValue();

        return <TextTableCell title={utils.formatNumber(Math.round(credits ?? 0))}/>
      }
    });

    return columns;
  }, []);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const renderSettingsHeader = () => {
    return <Box className="CreditsSettingPage-header">
      <SettingsHeader title="Credits"
                      info={info} />
      <Box className="CreditsSettingPage-header-content">
        <Cards columns={mdUp ? 2 : 1} gap={24}>
          <CardItem>
            <CreditWalletGraphCard/>
          </CardItem>
          <CardItem>
            <CreditDistributionGraphCard />
          </CardItem>
        </Cards>
        <H5>Credit history</H5>
      </Box>
    </Box>
  }

  return <StyledCreditsSettingPage as={TableWrapper} {...innerProps}
                                   dividers={true}
                                   header={renderSettingsHeader()}
                                   borders={{left: '3sp', right: '3sp', bottom: '3sp'}}>
    <Table className="CreditsSettingPage-table"
           dataKey="budgetId"
           enableParentScroll={true}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={true}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={true}
           listState={listState}
           columnState={columnState}
           onFetchMore={budgetList.query.fetchNextPage}
           onRefetch={budgetList.query.refetch}
           columns={columnsMemo}
           data={budgetList.data}
           rowCount={budgetList.meta?.resultsCount}
           state={{
             isLoading: budgetList.status.isLoading,
             showProgressBars: budgetList.status.isLoadingNext
           }}
           muiTableHeadCellProps={{align: 'left'}}
           muiTableBodyCellProps={{align: 'left'}} />
  </StyledCreditsSettingPage>
};

CreditsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CreditsSettingPage.defaultProps = {};

export default CreditsSettingPage;
