function createSource (data) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);

  const getConfigName = (config) => {
    const url = new URL(config)
    return url.hostname.replace(/www\./g, '')
      .replace(/-|\.|\s|\/|,/g, '_').toLowerCase()
  };

  const extraParams = data.type.value === 'Website' ? {
    config: getConfigName(data.link),
    scrapeConfig: {
      verificationDisabled: false,
      configured: false
    }
  } : {};

  return [{
    name: data.name,
    nextRun: date.toISOString(),
    params: {
      collectionId: null,
      publicCollectionId: null,
      query: data.query,
      link: data.link,
      fileEnrichProfiles: false,
      fileOverwriteValues: false,
      fileStaticDataset: false,
      ...data.params,
      ...extraParams
    },
    period: data.type?.period ?? data.period,
    subType: data.type?.subType?.value ?? data.type?.subType ?? data.subType,
    type: data.type?.value ?? data.type,
    comment: data.comment,
  }];
}

const source = {
  createSource,
}

export default source;
