import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledCheckboxField = styled(FormControl)`
  &.CheckboxField {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(40)};
      
      .FormControlLabel {
        .Checkbox {
          align-self: baseline;
        }
      }
    }

    .FormControlLabel {
      width: fit-content;
      align-items: center;

      .MuiFormControlLabel-label {
        display: inline-flex;
        align-self: center;
      }
    }

    .InputContainer {
      .CheckboxField-label {
        margin-right: 0;
      }
    }
  }
  
  &.CheckboxField-size-small {
    .MuiFormControlLabel-label {
      font-size: ${props => props.theme.fontSize(14)};
      margin-top: ${props => props.theme.layout(6)};
    }
  }

  &.CheckboxField-size-medium {
    .FormControlLabel {
      margin-left: ${props => props.theme.layout(-10)} !important;
    }
  }

  &.CheckboxField-size-small {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(36)} !important;
    }

    .FormControlLabel {
      margin-left: ${props => props.theme.layout(-8)} !important;
    }
    .MuiFormControlLabel-label {
      font-size: ${props => props.theme.fontSize(14)};
      margin-top: ${props => props.theme.layout(0.5)};
    }
  }
  
  &.CheckboxField-size-smallest {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(32)} !important;
    }

    .FormControlLabel {
      margin-left: ${props => props.theme.layout(-6)} !important;
    }
    .MuiFormControlLabel-label {
      font-size: ${props => props.theme.fontSize(14)};
      margin-top: ${props => props.theme.layout(0.5)};
    }
  }
  
  ${ComponentStyles}
`;

StyledCheckboxField.propTypes = {
  theme: PropTypes.object
};

StyledCheckboxField.defaultProps = {
}

export default StyledCheckboxField;
