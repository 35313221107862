import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Container from '@mui/material/Container';

const StyledContainer = styled(Container)`
  ${ComponentStyles}
`;

StyledContainer.propTypes = {
  theme: PropTypes.object
};

StyledContainer.defaultProps = {
}

export default StyledContainer;
