import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableCell from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)`
  ${ComponentStyles}
`;

StyledTableCell.propTypes = {
  theme: PropTypes.object
};

StyledTableCell.defaultProps = {
}

export default StyledTableCell;
