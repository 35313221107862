import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAvatarOption from 'components/molecules/Options/AvatarOption/AvatarOption.styles';
import utils from 'helpers/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';

const AvatarOption = React.forwardRef((props, ref) => {
  const {
    avatar,
    label,
    ...innerProps
  } = useComponentProps(props, 'AvatarOption');

  const color = utils.string2Color(avatar);

  return <StyledAvatarOption ref={ref} {...innerProps}>
    {avatar ? <ActionAvatar action={{
                              label: utils.avatarLabel(avatar),
                              tooltip: avatar
                            }}
                            color={color}
                            size="small"
                            TooltipProps={{enterDelay: 0}}/> : null}
    {label ? utils.isReactElement(label) ? label : <Typography variant="body2">{label}</Typography> : null}
  </StyledAvatarOption>
});

AvatarOption.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  avatar: PropTypes.string,
  label: PropTypes.any
};

AvatarOption.defaultProps = {};

export default AvatarOption;
