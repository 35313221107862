import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledBadgeTableCell from 'components/molecules/TableCells/BadgeTableCell/BadgeTableCell.styles';
import ActionBadge from 'components/molecules/Badges/ActionBadge/ActionBadge';
import {withMemo} from 'helpers/wrapper';

const BadgeTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    ActionBadgeProps,
    ...innerProps
  } = useComponentProps(props, 'BadgeTableCell');

  return <StyledBadgeTableCell ref={ref} {...innerProps}>
    <ActionBadge {...ActionBadgeProps} />
  </StyledBadgeTableCell>
}));

BadgeTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionAvatarProps: PropTypes.object
};

BadgeTableCell.defaultProps = {
};

export default BadgeTableCell;


