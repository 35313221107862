import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogActions from '@mui/material/DialogActions';

const StyledDialogActions = styled(DialogActions)` 
  ${ComponentStyles}
`;

StyledDialogActions.propTypes = {
  theme: PropTypes.object
};

StyledDialogActions.defaultProps = {}

export default StyledDialogActions;
