import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfileFoldout from 'components/organisms/Foldouts/EntityProfileFoldout/EntityProfileFoldout';

const StyledDatabaseEntityProfileFoldout = styled(EntityProfileFoldout)`
  ${ComponentStyles}
`;

StyledDatabaseEntityProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityProfileFoldout.defaultProps = {}

export default StyledDatabaseEntityProfileFoldout;
