import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledInputContainer = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  position: relative;

  > * {
    width: 100%;  
  }
  
  ${ComponentStyles}
`;

StyledInputContainer.propTypes = {
  theme: PropTypes.object
};

StyledInputContainer.defaultProps = {
}

export default StyledInputContainer;
