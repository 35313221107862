import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';

const StyledProfileBar = styled(HeaderBar)`
  .ProfileBar {
    &-navigation {
      min-height: unset;
      align-items: flex-start;
      padding-bottom: ${props => props.theme.layout(2)};    
    }
  }
  
  &:not(.ProfileBar-inset) {
    height: unset;
    
    &:has(.ContentNavigation) {
      .PageBar-toolbar {
        margin-bottom: ${props => props.theme.layout('-1sp')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledProfileBar.propTypes = {
  theme: PropTypes.object
};

StyledProfileBar.defaultProps = {
};

export default StyledProfileBar;
