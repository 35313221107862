import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledActionSwitch = styled(Box)` 
  .FormControlLabel {
    margin-right: 0;
  }
  ${ComponentStyles}
`;

StyledActionSwitch.propTypes = {
  theme: PropTypes.object
};

StyledActionSwitch.defaultProps = {}

export default StyledActionSwitch;
