import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileFoldout from 'components/organisms/Foldouts/ProfileFoldout/ProfileFoldout';

const StyledEntityProfileFoldout = styled(ProfileFoldout)`
  .EntityHeading-name {
    ${props => props.theme.font2Css('h5')};
  }
  ${ComponentStyles}
`;

StyledEntityProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledEntityProfileFoldout.defaultProps = {}

export default StyledEntityProfileFoldout;
