import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledResolveCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ResolveCollectionCategoriesWizardContent/ResolveCollectionCategoriesWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import TagGroupCard from 'components/organisms/Cards/TagGroupCard/TagGroupCard';

const ResolveCollectionCategoriesWizardContent = React.forwardRef((props, ref) => {
  const {
    collection,
    wizard,
    step,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'ResolveCollectionCategoriesWizardContent');

  const innerRef = useRef(null);

  const data = wizard.data?.[wizard.dataKey];

  const onCompleteEvent = useEffectEvent(onComplete);
  const resolveCollectionCategoriesWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      onCompleteEvent?.(Boolean(step?.resetNextSteps));
    }
  }), [
    onCompleteEvent,
    step?.resetNextSteps
  ]);

  useImperativeHandle(ref, () => resolveCollectionCategoriesWizardContent);

  const tagGroups = useMemo(() => {
    return data.outputTagGroups
      .filter((tg) => tg.tags?.length > 0)
      .map((tg) => ({
        ...tg,
        tags: tg.tags.map((t) => ({
          ...t,
          value: t.name,
          description: t.reason
        }))
      }));
  }, [data.outputTagGroups]);

  const renderSubtitle = () => {
    let subTitle = `All set! ${tagGroups?.length ?? 0} categor${tagGroups?.length > 1 ? 'ies are' : 'y is'} ready to be added to the collection.`;

    if (!(tagGroups?.length > 0)) {
      subTitle = `No categories where found based on the current information. Please try altering your category name or add more companies to the collection.`;
    }

    return <Box className="ResolveCollectionCategoriesWizardContent-subtitle">
      <H6>{subTitle}</H6>
    </Box>
  };

  const renderCategories = () => {
    if (tagGroups.length > 0) {
      return <Box className="ResolveCollectionCategoriesWizardContent-tagGroups">
        {tagGroups.map((tg, idx) => {
          return <TagGroupCard key={idx}
                               tagGroup={tg}
                               variant="compact"
                               hasAutoTags={false}
                               isLoading={false} />
        })}
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledResolveCollectionCategoriesWizardContent ref={innerRef} {...innerProps}>
    {renderSubtitle()}
    <Box className="ResolveCollectionCategoriesWizardContent-content">
      {renderCategories()}
    </Box>
  </StyledResolveCollectionCategoriesWizardContent>
});

ResolveCollectionCategoriesWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func
};

ResolveCollectionCategoriesWizardContent.defaultProps = {};

export default ResolveCollectionCategoriesWizardContent;
