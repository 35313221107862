import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledFormHelperText = styled(FormHelperText)`
  ${ComponentStyles}
`;

StyledFormHelperText.propTypes = {
  theme: PropTypes.object
};

StyledFormHelperText.defaultProps = {
}

export default StyledFormHelperText;
