import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Badge from 'components/atoms/Badges/Badge/Badge';

const StyledActionBadge = styled(Badge)`
  ${ComponentStyles}
`;

StyledActionBadge.propTypes = {
  theme: PropTypes.object
};

StyledActionBadge.defaultProps = {
}

export default StyledActionBadge;
