import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import {processEntity} from 'services/entity/entity.utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class EntityService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'entityId',
      entity: 'entity',
      list: 'entities',
      separateEntityData: true, // single item contain more info than the list
      overrideListPath: 'entities',
      overrideEntityPath: 'entities/:entityId?returnObject=true',
      match: (cache, entity, id) => {
        return +cache.data?.entityId === +id || +cache.data?.originalEntityId === +id || +cache.data?.stackId === +id;
      },
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processEntity);
          } else {
            return processEntity(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
