import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Snackbar from '@mui/material/Snackbar';
import constants from 'helpers/constants';

const StyledActionbar = styled(Snackbar)`
  z-index: ${props => props.theme.property('zIndex.actionbar')};
  ${props => props.theme.color2Css('Actionbar', 'Actionbar-content', props.$color, {type: constants.colorTypes.paper})};
  
  .Actionbar-content {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    border-radius: ${props => props.theme.radius(2)};  
    padding: calc(${props => props.theme.spacing(2)} - 1px) ${props => props.theme.spacing(2)};
    
    .Actionbar-title {
      display: flex;
      align-items: center;
      margin-right: ${props => props.theme.spacing(8)};
      color: ${props => props.theme.property('palette.white.main')};
      
      &-text {
        font-size: 1rem;
        align-self: center;
        margin-right: ${props => props.theme.spacing(1)};
      }
    }
    
    .Actionbar-actions {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
  }
  ${ComponentStyles}
`;

StyledActionbar.propTypes = {
};

export default StyledActionbar;
