import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSourceUploadSectionPanelContent = styled(Box)`
  margin: ${props => props.theme.spacing(2)};
  
  ${ComponentStyles}
`;

StyledSourceUploadSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledSourceUploadSectionPanelContent.defaultProps = {}

export default StyledSourceUploadSectionPanelContent;
