import React from 'react';
import PropTypes from 'prop-types';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';

const TableText = React.forwardRef(({cell}, ref) => {
  return <TextTableCell ref={ref} title={cell.getValue()?.toString()} />
});

TableText.propTypes = {
  cell: PropTypes.object
};

TableText.defaultProps = {
};

export default TableText;
