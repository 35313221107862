import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import constants from 'helpers/constants';

const StyledDrawer = styled(SwipeableDrawer)`
  ${props => props.theme.color2Css('Drawer', 'MuiPaper-root', props.$color, {
    type: constants.colorTypes.paper,
    childPrefix: '>'
  })};
  
  ${ComponentStyles}
`;

StyledDrawer.propTypes = {
  theme: PropTypes.object
};

StyledDrawer.defaultProps = {
}

export default StyledDrawer;
