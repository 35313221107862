import React, {useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useSortable} from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import utils from 'helpers/utils';
import {DragHandleContext} from 'components/organisms/Utils/DragDrop/DndContext';
import {useEffectItem} from 'helpers/hooks/utils';

const SortableItem = React.forwardRef((props, ref) => {
  const {
    id,
    recycleKey,
    payload,
    position,
    sourceContainerId,
    disabled,
    style,
    dragHandle,
    disableReorder,
    children,
    ...rest
  } = props;

  const sortablePropsMemo = useMemo(() => ({
    id: id,
    data: {
      payload,
      sourceContainerId,
      position
    },
    disabled
  }), [id, payload, position, sourceContainerId, disabled]);

  const {
    active,
    over,
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef
  } = useSortable(sortablePropsMemo);

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const handleRef = useCallback((el) => {
    setNodeRef(el);
    innerRef.current = el;
  }, [setNodeRef]);

  const context = useMemo(() => ({
    ref: setActivatorNodeRef,
    className: 'dragHandle',
    isDragging,
    ...attributes,
    ...listeners
  }), [attributes, listeners, setActivatorNodeRef, isDragging]);

  const orgContainerId = active?.data?.current?.sourceContainerId;
  const overContainerId = over?.data?.current?.sortable?.containerId;

  let canReorder = true;
  if (disableReorder && overContainerId && orgContainerId && overContainerId.toString() === orgContainerId.toString()) {
    canReorder = false;
  }

  const styleMemo = useEffectItem(style);
  const restMemo = useEffectItem(rest);
  const itemProps = useMemo(() => {
    return {
      ref: handleRef,
      style: utils.mergeObjects(styleMemo, canReorder ? {
        opacity: isDragging ? 0.4 : null,
        transform: CSS.Translate.toString(transform),
        transition
      } : { opacity: isDragging ? 0.4 : null, transition }, true),
      ...(!dragHandle ? {...listeners} : {}),
      ...restMemo
    }
  }, [canReorder, isDragging, transform, transition, dragHandle,
    handleRef, styleMemo, restMemo, listeners]);

  return <DragHandleContext.Provider value={context}>
    <React.Fragment key={recycleKey ?? 'recycle'}>
      {utils.cloneElement(children, itemProps)}
    </React.Fragment>
  </DragHandleContext.Provider>
});

SortableItem.propTypes = {
  id: PropTypes.any,
  payload: PropTypes.any,
  disabled: PropTypes.bool,
  dragHandle: PropTypes.bool,
  sourceContainerId: PropTypes.any,
  disableReorder: PropTypes.bool
};

SortableItem.defaultProps = {
};

export default SortableItem;
