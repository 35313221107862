import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntityProfileFoldout from 'components/organisms/Foldouts/EntityProfileFoldout/EntityProfileFoldout.styles';
import EntityHeading from 'components/molecules/Headings/EntityHeading/EntityHeading';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const EntityProfileFoldout = React.forwardRef((props, ref) => {
  const {
    newProfile,
    ...innerProps
  } = useComponentProps(props, 'EntityProfileFoldout');

  const profileProvider = useProfile();
  const entity = profileProvider.data?.data;
  const isLoading = profileProvider.isLoading();

  const renderTitle = () => {
    if (newProfile) {
      return 'New company';
    } else {
      return <EntityHeading className="EntityProfileFoldout-heading"
                            entity={entity}
                            showLinks={false}
                            isLoading={isLoading}/>
    }
  }

  innerProps.title = innerProps.title ?? renderTitle();

  return <StyledEntityProfileFoldout ref={ref} {...innerProps}
                                     isLoading={profileProvider.isLoading()}>
    {innerProps.children}
  </StyledEntityProfileFoldout>
});

EntityProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityProfileFoldout.defaultProps = {};

export default EntityProfileFoldout;
