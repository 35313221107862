import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfile from 'components/organisms/Profiles/EntityProfile/EntityProfile';

const StyledDatabaseEntityProfile = styled(EntityProfile)`
  ${ComponentStyles}
`;

StyledDatabaseEntityProfile.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityProfile.defaultProps = {
};

export default StyledDatabaseEntityProfile;
