import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledTasksBarChart from 'components/organisms/Charts/TasksBarChart/TasksBarChart.styles';
import TasksTooltip from 'components/molecules/Tooltips/TasksTooltip/TasksTooltip';

const TasksBarChart = React.forwardRef((props, ref) => {
  const {
    breakdown,
    showLegend,
    showEmpty,
    isLoading,
    visibility,
    onVisibilityChange,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'TasksBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (breakdown) {
      if (showEmpty || breakdown.find((b) => !b.done && b.count > 0)) {
        legend.push({
          id: 'open',
          position: 0,
          label: 'Open tasks',
          color: innerProps.theme.property('palette.primary.main'),
          active: visibility?.['open'] ?? true
        });
      }
      if (showEmpty || breakdown.find((b) => b.done && b.count > 0)) {
        legend.push({
          id: 'completed',
          position: 1,
          label: 'Completed tasks',
          color: innerProps.theme.property('palette.success.main'),
          active: visibility?.['completed'] ?? true
        })
      }
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [breakdown, visibility, showEmpty, innerProps.theme]);

  const taskData = useMemo(() => {
    if (breakdown) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: legend
          .map((l) => {
            return {
              id: l.id,
              position: l.position,
              dataKey: l.id,
              name: l.id,
              label: l.label,
              color: l.color,
            }
          })
          .filter((b) => {
            return Boolean(legend.find((l) => l.active && l.id === b.id))
          }),
        data: legend
          .reduce((a, l) => {
            const b = breakdown.find((b) => !b.done ? l.id === 'open' : l.id === 'completed')
            return a.concat([{
              id: l.id,
              position: l.position,
              name: l.label,
              [l.id]: b?.count ?? 0,
              [`${l.id}-meta`]: {
                ...b,
                label: l.label,
                types: (b?.types || []).map((t) => {
                  const idx = constants.data.taskTypes.findIndex((tt) => tt.value === t.type);
                  return {
                    position: idx,
                    name: constants.data.taskTypes[idx]?.label,
                    count: t.count
                  };
                }).sort((a, b) => {
                  return a.position - b.position;
                })
            }
            }]);
          }, [])
          .filter((d) => {
            return Boolean(legend.find((l) => l.active && l.id === d.id))
          })
          .sort((a, b) => a.position - b.position),
        XAxisProps: {
          hide: true
        },
        YAxisProps: {
          width: 140,
          padding: {top: 4, bottom: 4}
        },
        margin: {
          top: 0, right: 0, bottom: 0, left: -16
        },
        TooltipComponent: TasksTooltip
      };
    }
  }, [breakdown, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTasksBarChart ref={ref} {...innerProps}>
    <Box className="TasksBarChart-chart">
      <BarChart {...taskData}
                showGrid={false}
                showCursor={false}
                showTooltip
                showLabels
                isLoading={isLoading}
                radius={4}
                barSize={32}
                layout="vertical"
                hoveredId={internalState.hoveredId}
                TooltipProps={{
                  variant: 'extended'
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="TasksBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledTasksBarChart>
});

TasksBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  breakdown: PropTypes.array,
  showLegend: PropTypes.bool,
  showEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  BarChartProps: PropTypes.object
};

TasksBarChart.defaultProps = {
  showEmpty: true
};

export default TasksBarChart;
