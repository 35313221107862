import React, {useEffect, useMemo, useRef} from 'react'
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {usePageVisibility} from 'helpers/hooks/utils';

export const SystemContext = React.createContext(null)

export function useSystem () {
  return React.useContext(SystemContext);
}

const SystemProvider = ({children}) => {
  const systemInfoRef = useRef({});
  const isPageVisible = usePageVisibility();

  const context = useMemo(() => ({
    info: (data) => {
      if (data) {
        systemInfoRef.current = utils.mergeObjects(systemInfoRef.current, data);
      }

      systemInfoRef.current.userIsActive = systemInfoRef.current.lastActivity?.getTime() >= (Date.now() - constants.delay.idle);

      return systemInfoRef.current;
    }
  }), []);

  useEffect(() => {
    return utils.observeEvent(window, 'mousemove', () => {
      systemInfoRef.current.lastActivity = new Date();
    });
  }, []);

  useEffect(() => {
    return utils.observeEvent(window, 'keydown', () => {
      systemInfoRef.current.lastActivity = new Date();
    });
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      systemInfoRef.current.lastActivity = new Date();
    }
  }, [isPageVisible]);

  return <SystemContext.Provider value={context}>
    {children}
  </SystemContext.Provider>
};

SystemProvider.propTypes = {
}

export default SystemProvider;
