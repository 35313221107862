import React, {useLayoutEffect, useMemo} from 'react'
import PropTypes from 'prop-types';
import {GTMProvider as GTMProviderBase, useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import utils from 'helpers/utils';
import system from 'helpers/system';

export const GTMContext = React.createContext(null)

export function useGTM (componentName) {
  const gtmContext = React.useContext(GTMContext);
  const dispatch = useGTMDispatch();

  return useMemo(() => ({
    send: (event, data = {}) => {
      const msg = {
        event: `viq${utils.upperFirst(componentName)}${utils.upperFirst(event)}`,
        ...data
      };

      window.parent.postMessage(JSON.stringify(msg),'*');
      if (gtmContext?.id) {
        dispatch(msg);
      }
    }
  }), [dispatch, componentName, gtmContext]);
}

const GTMProvider = (props) => {
  const clientConfig = useClientConfig('GTMProvider');

  const config = clientConfig?.id ? clientConfig : system.gtmConfig();

  useLayoutEffect(() => {
    // cleanup GTM changes
    let tags = Array.from(document.getElementsByTagName('script'));
    tags = tags
      .reduce((a, tag, idx) => {
        if (tag.src?.toLowerCase()?.includes('gtm.js')) {
          a.push(tag);
          if (!tags[idx + 1].src) {
            a.push(tags[idx + 1]);
          }
          if (!tags[idx + 2].src) {
            a.push(tags[idx + 2]);
          }
        }
        return a;
      }, []);

    tags = tags.concat(Array.from(document.getElementsByTagName('noscript'))
      .reduce((a, tag, idx) => {
        if (tag.innerText?.toLowerCase()?.includes('tag-manager')) {
          a.push(tag);
        }

        return a;
      }, [])
    );

    tags.forEach((tag) => {
      tag.parentNode.removeChild(tag);
    });
  }, [config]);

  return <GTMContext.Provider value={config}>
    <GTMProviderBase state={config?.id ? config : null}>
      {props.children}
    </GTMProviderBase>
  </GTMContext.Provider>
};

GTMProvider.propTypes = {
  theme: PropTypes.any,
}

export default GTMProvider;
