import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';
import constants from 'helpers/constants';

const StyledEntityCard = styled(Card)`
  &.EntityCard {
    display: flex;
    align-items: center;
    padding-top: ${props => props.theme.spacing(1.75)};
    padding-right: ${props => props.theme.spacing(3)};
    padding-bottom: ${props => props.theme.spacing(1.75)};
    padding-left: ${props => props.theme.spacing(1.25)};
    width: 100%;
    
    &-dragging {
      cursor: grabbing !important;
      background-color: ${props => props.theme.state2Color(props.$color, 'hover',
              constants.colorTypes.accentBackground, constants.colorTargets.background, 0.5)};

      * {
        pointer-events: none;
      }

      .Card {
        &-dragHandle {
          .Icon {
            color: unset;
          }
        }
      }
    }
    
    .EntityCard {
      &-info {
        flex-grow: 1;
        padding-left: ${props => props.theme.spacing(1)};
        padding-right: ${props => props.theme.spacing(1)};
        width: 1px;
        
        .Typography {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }
        *:not(:first-child) {
          margin-top: ${props => props.theme.spacing(0.25)};
        }
      }

      &-dragHandle {
        display: flex;
        align-items: center;

        .Icon {
          cursor: grab;
          outline: none;

          color: ${props => props.theme.property('palette.divider')};

          &:hover, &:focus {
            color: unset;
          }
        }
      }
    }
  }

  &.EntityCard-size-small {
    padding: ${props => props.theme.spacing(1)};
    
    .Logo {
      svg {
        font-size: ${props => props.theme.fontSize(16)};
      }
    }
  }
  
  // variants 
  &.EntityCard-scoring {
    padding: ${props => props.theme.spacing(11.5/8)} ${props => props.theme.spacing(1)};
  }
  
  &.EntityCard-suggestion {
    background-color: transparent;
  }
  
  ${ComponentStyles}
`;

StyledEntityCard.propTypes = {
  theme: PropTypes.object
};

StyledEntityCard.defaultProps = {}

export default StyledEntityCard;
