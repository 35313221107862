import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SwitchDialog from 'components/organisms/Dialogs/SwitchDialog/SwitchDialog';

const StyledSwitchTeamDialog = styled(SwitchDialog)`
  &.SwitchTeamDialog {
    .SwitchTeamForm-teams {
      row-gap: ${props => props.theme.layout('1sp')};
      padding: 0;
      
      .ActionList-list {
        padding: 0 ${props => props.theme.spacing(2)} !important;
      }
    }
  }
  
  .SwitchTeamDialog {
    &-form {
      height: 100%;
      overflow: hidden;
      &-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    &-loading {
      display: none;
      flex-direction: row;
      align-items: center;
      column-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
      
      .MuiCircularProgress-root {
        width: ${props => props.theme.layout(24)} !important;
        height: ${props => props.theme.layout(24)} !important;
      }
    }
  }

  ${props => props.$switching ? css`
    .MuiDialog-paper {
      width: unset !important;
    }
    .DialogTitle, .SwitchDialog-actions, .SwitchDialog-title {
      display: none;
    }
    .SwitchTeamDialog-form {
      display: none;
    }
    .SwitchTeamDialog-loading {
      display: flex;
    }
    .DialogContent {
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)} !important;
    }
  ` : null};
  ${ComponentStyles}
`;

StyledSwitchTeamDialog.propTypes = {
  theme: PropTypes.object
};

StyledSwitchTeamDialog.defaultProps = {}

export default StyledSwitchTeamDialog;
