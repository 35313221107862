import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Wrapper, {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import StyledTableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper.styles';
import utils from 'helpers/utils';
import {useStyles} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';

const TableWrapper = React.forwardRef((props, ref) => {
  const {
    header,
    borders,
    dividers,
    onBoxed,
    ...innerProps
  } = useComponentProps(props, 'TableWrapper', {
    static: ['dividers'],
    variable: ['sticky'],
    children: ['header', 'content', 'top', 'left', 'right', 'bottom']
  });

  const innerRef = useRef(null);
  const headerRef = useRef(null);

  const hasBorders = Boolean(borders);
  const [internalState, setInternalState] = useState({
    boxed: Boolean(header) && hasBorders
  });

  useImperativeHandle(ref, () => innerRef.current);

  const headerBbox = useBbox(() => headerRef?.current, ['height']);
  const tableBbox = useBbox(() => {
    return innerRef?.current?.querySelector('.Table')
  }, ['height']);

  const parentWrapper = useWrapper();

  const onBoxedEvent = useEffectEvent(onBoxed);
  useEffect(() => {
    if (headerBbox && hasBorders) {
      return utils.observeScroll(window, ({y}) => {
        const boxed = !Boolean(Math.round(y) >= Math.round(headerBbox.height) && y > 0);
        setInternalState((current) => {
          if (current.boxed !== boxed) {
            onBoxedEvent?.(boxed);
            return {
              ...current,
              boxed
            }
          } else {
            return current;
          }
        });
      });
    }
  }, [headerBbox, hasBorders, onBoxedEvent]);

  const initialised = Boolean(!header || Boolean(headerBbox));

  const styles = useStyles();
  const left = internalState.boxed ? innerProps.theme.layout(borders?.left ?? 0) : '0rem';
  const right = internalState.boxed ? innerProps.theme.layout(borders?.right ?? 0) : '0rem';
  const bottom = internalState.boxed ? innerProps.theme.layout(borders?.bottom ?? 0) : '0rem';
  const wrapper = useMemo(() => {
    return initialised ? {
      bounds: {
        top: utils.pixel2Rem(headerBbox?.height ?? 0, styles?.fontBase),
        left: left,
        right: right,
        bottom: bottom
      },
      scrollBounds: {
        top: 0,
        left: left,
        right: right,
        bottom: bottom
      }
    } : null;
  }, [initialised, left, right, bottom, headerBbox?.height, styles?.fontBase]);

  const bordersMemo = useMemo(() => ({
    left, right, bottom
  }), [left, right, bottom]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableWrapper ref={innerRef} {...innerProps}
                             $wrapper={parentWrapper}
                             $borders={bordersMemo}
                             $headerHeight={utils.pixel2Rem(headerBbox?.height ?? 0, styles?.fontBase)}
                             $tableHeight={utils.pixel2Rem(tableBbox?.height ?? 0, styles?.fontBase)}
                             $boxed={internalState.boxed}>
    {header ? <Box ref={headerRef} className="TableWrapper-header">
      {header}
    </Box> : null}
    <Box className="TableWrapper-content">
      <Box className="TableWrapper-left"/>
      <Wrapper wrapper={wrapper} scroll={true}>
        {innerProps.children}
      </Wrapper>
      <Box className="TableWrapper-right"/>
      <Box className="TableWrapper-bottom"/>
    </Box>
  </StyledTableWrapper>
});

TableWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  header: PropTypes.any,
  borders: PropTypes.any,
  dividers: PropTypes.bool,
  onBoxed: PropTypes.func
};

TableWrapper.defaultProps = {
};

export default TableWrapper;
