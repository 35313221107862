import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import StyledEntityHeading from 'components/molecules/Headings/EntityHeading/EntityHeading.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const EntityHeading = React.forwardRef((props, ref) => {
  const {
    entity,
    showLinks,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityHeading', {
    static: ['isLoading', 'showLinks'],
    children: ['logo', 'name', 'links']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityHeading ref={ref} {...innerProps}>
    <Logo className="EntityHeading-logo"
          size="large"
          density="sparse"
          logo={entity?.logoUrl}
          altLogo={entity?.altLogoUrl}
          fallbackIcon={HomeOutlined}
          isLoading={isLoading}
          outlined={true} />
    <Box className="EntityHeading-info">
      <H6 className="EntityHeading-name" min={8} max={20} isLoading={isLoading} showTooltip={true}>{entity?.name}</H6>
      {showLinks ? (!isLoading ? <Box className="EntityHeading-links">
        {constants.data.links.map((link, idx) => {
          if (entity?.[link.value]?.length > 0) {
            return <Tooltip key={`${link.value}_${idx}`} showDisabled={true}
                            title={link.tooltip}
                            placement={'bottom'}>
              <IconButton size="smaller"
                          density="densest"
                          variant="transparent"
                          onClick={() => utils.externalLink(entity?.[link.value])}>
                <Icon icon={link.icon} size="tiny"/>
              </IconButton>
            </Tooltip>
          } else {
            return null;
          }
        }).filter((_) => (_))}
      </Box> : <Span isLoading={true} min={8} max={20}/>) : null}
    </Box>
  </StyledEntityHeading>
});

EntityHeading.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  showLinks: PropTypes.bool,
  isLoading: PropTypes.bool
};

EntityHeading.defaultProps = {
  showLinks: true
};

export default EntityHeading;
