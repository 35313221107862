import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Checkbox from 'components/atoms/Inputs/Checkbox/Checkbox';

const StyledActionCheckbox = styled(Checkbox)`
  ${ComponentStyles}
`;

StyledActionCheckbox.propTypes = {
  theme: PropTypes.object
};

StyledActionCheckbox.defaultProps = {
}

export default StyledActionCheckbox;
