import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTableFilters from 'components/organisms/TableFilters/EntitiesTableFilters/EntitiesTableFilters';

const StyledCollectionEntitiesTableFilters = styled(EntitiesTableFilters)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesTableFilters.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTableFilters.defaultProps = {}

export default StyledCollectionEntitiesTableFilters;
