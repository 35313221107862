import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledFeedbackHelper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.layout('1sp')};
  margin-top: ${props => props.theme.layout('0.5sp')};
  padding: ${props => props.theme.layout('0.25sp')} ${props => props.theme.layout('0.25sp')} ${props => props.theme.layout('0.25sp')} ${props => props.theme.layout('1sp')};
  background-color: ${props => props.theme.property('palette.primary.states.hover')};
  border-radius: ${props => props.theme.radius(1)};

  .FeedbackHelper {
    &-icon {
      margin-right: -${props => props.theme.layout('0.25sp')};
    }
    &-info {
      width: 1px;
      flex-grow: 1;
      overflow: hidden;
      margin-top: ${props => props.theme.layout(2)};
      
      > * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }
    
    &-value {
      .Span:last-child {
        font-weight: 500;
      }
    }
  }
  
  &.FeedbackHelper-compact {
    .Button {
      min-width: unset;
      
      .MuiButton-startIcon {
        margin: 0;
      }
      
      &.FeedbackHelper-ignore {
        margin-right: -${props => props.theme.layout('0.5sp')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFeedbackHelper.propTypes = {
  theme: PropTypes.object
};

StyledFeedbackHelper.defaultProps = {
}

export default StyledFeedbackHelper;
