import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesSuggestionBrowser
  from 'components/organisms/Browsers/EntitiesSuggestionBrowser/EntitiesSuggestionBrowser.styles';
import EntitiesBrowserList from 'components/organisms/Browsers/EntitiesBrowserList/EntitiesBrowserList';
import TinderBar from 'components/organisms/Bars/TinderBar/TinderBar';
import SplitBar from 'components/organisms/Bars/SplitBar/SplitBar';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';

const EntitiesSuggestionBrowser = React.forwardRef((props, ref) => {
  const {
    addAction,
    deleteAction,
    advancedActions,
    advancedContext,
    canUpdate,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntitiesSuggestionBrowser');

  const tableProvider = useTable();

  const switched = Boolean(tableProvider?.state?.settings?.suggestionsSwitched);

  const renderBar = () => {
    if (!switched) {
      return <TinderBar className="EntitiesSuggestionBrowser-controls"
                        addAction={addAction} deleteAction={deleteAction}/>
    } else {
      return <SplitBar className="EntitiesSuggestionBrowser-controls"
                       actions={advancedActions} context={advancedContext}/>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesSuggestionBrowser ref={ref} {...innerProps}>
    {!isLoading ? <EntitiesBrowserList /> : null}
    {(!isLoading && canUpdate) ? renderBar() : null}
  </StyledEntitiesSuggestionBrowser>
});

EntitiesSuggestionBrowser.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  addAction: PropTypes.object,
  deleteAction: PropTypes.object,
  advancedActions: PropTypes.array,
  advancedContext: PropTypes.any,
  canUpdate: PropTypes.bool,
  isLoading: PropTypes.bool
};

EntitiesSuggestionBrowser.defaultProps = {
  variant: 'standard',
  canUpdate: true
};

export default EntitiesSuggestionBrowser;
