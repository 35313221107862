import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTableHeaderText from 'components/molecules/TableHeaders/TableHeaderText/TableHeaderText.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const TableHeaderText = React.forwardRef((props, ref) => {
  const {
    header,
    ...innerProps
  } = useComponentProps(props, 'TableHeaderText');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const handleRef = (element) => {
    innerRef.current = element;
    if (element?.parentElement) {
      element.parentElement.title = '';
    }
  }

  return <Tooltip title={header} placement="top">
    <StyledTableHeaderText ref={handleRef} {...innerProps}>
      {header}
    </StyledTableHeaderText>
  </Tooltip>
});

TableHeaderText.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  header: PropTypes.string
};

TableHeaderText.defaultProps = {
};

export default TableHeaderText;
