import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledProfileCardDialog = styled(Dialog)`
  .ProfileCardDialog {
    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: ${props => props.theme.spacing(2)};
      padding-bottom: ${props => props.theme.spacing(2)};
      .ProfileCardContent {
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
      
      .ProfileCard-content {
        width: 100%;
      }
    }
  }

  .MuiDialog-paper {
    min-width: clamp(0px, ${props => props.theme.layout(600)}, 86vw);
  }
  &.ProfileCardDialog-size-small {
    .MuiDialog-paper {
      min-width: clamp(0px, ${props => props.theme.layout(400)}, 86vw);
      max-width: ${props => props.theme.layout(400)};
    }
  }
  &.ProfileCardDialog-size-large {
    .MuiDialog-paper {
      min-width: clamp(0px, ${props => props.theme.layout(600)}, 86vw);
      max-width: ${props => props.theme.layout(800)};
    }
  }
  
  &.ProfileCardDialog-basic {
    .ProfileCardDialog-content {
      padding: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledProfileCardDialog.propTypes = {
  theme: PropTypes.object
};

StyledProfileCardDialog.defaultProps = {}

export default StyledProfileCardDialog;
