import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledDropdownTableCellEdit = styled(Box)`
  width: 100%;
  max-height: inherit;
  max-width: inherit;
  overflow: auto;
  overscroll-behavior: contain;

  > .List {
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: 0;
    width: 100%;

    .ListItem {
      padding: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledDropdownTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledDropdownTableCellEdit.defaultProps = {
}

export default StyledDropdownTableCellEdit;
