import React from 'react';
import PropTypes from 'prop-types';
import TableHeaderText from 'components/molecules/TableHeaders/TableHeaderText/TableHeaderText';

const TableHeader = React.forwardRef(({column}, ref) => {
  return <TableHeaderText ref={ref} header={column.columnDef.header} />
});

TableHeader.propTypes = {
  column: PropTypes.object
};

TableHeader.defaultProps = {
};

export default TableHeader;
