import utils from 'helpers/utils';

export function processCollection (collection) {
  collection = utils.camelcase(collection);

  const tagGroups = utils.camelcaseEx(collection.tagGroups ?? []);

  return {
    ...collection,
    tagGroups: tagGroups
  }
}
