import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledDealflowPieChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('4sp')};
  height: 100%;
  
  .DealflowPieChart {
    &-chart {
      flex-grow: 1;
      overflow: hidden;
    }
    
    &-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-height: 100%;
      aspect-ratio: 1;
    }

    &-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;

      .totalCount {
        font-size: ${props => props.theme.fontSize(40)};
        line-height: ${props => props.theme.lineHeight('1.125f')};
        font-weight: 700;
      }

      .H6 {
        text-align: center;
      }
    }
    
    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }  
  
  // variants
  &.DealflowPieChart-half {
    .DealflowPieChart {
      &-center {
        height: 100%;
        justify-content: end;

        .Icon {
          font-size: ${props => props.theme.fontSize(24)};
        }

        .totalCount {
          color: ${props => props.theme.property('palette.text.primary')};
          margin-top: ${props => props.theme.layout(4)};
          margin-bottom: ${props => props.theme.layout(-3)};
          ${props => props.theme.font2Css('h6')};
          line-height: 1;
        }

        .title {
          display: none;
        }
      }
    }
  }

  &.DealflowPieChart-orientation-horizontal {
    width: 100%;
    flex-direction: row;
    .DealflowPieChart {
      &-chart {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowPieChart.propTypes = {
  theme: PropTypes.object
};

StyledDealflowPieChart.defaultProps = {}

export default StyledDealflowPieChart;
