import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledApp = styled(Box)`
  color: ${props => props.theme.property('palette.text.primary')};
  background-color: ${props => props.theme.property('palette.background.paper')};
  
  ${ComponentStyles}
`;

StyledApp.propTypes = {
  theme: PropTypes.object
};

StyledApp.defaultProps = {
}

export default StyledApp;
