import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledInputContainer from 'components/atoms/Layout/InputContainer/InputContainer.styles';
import {withMemo} from 'helpers/wrapper';

const InputContainer = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'InputContainer');

  return <StyledInputContainer ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledInputContainer>
}));

InputContainer.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

InputContainer.defaultProps = {
  children: 'InputContainer text'
};

export default InputContainer;
