import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledDatabaseEntityContextWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledDatabaseEntityContextWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityContextWrapper.defaultProps = {}

export default StyledDatabaseEntityContextWrapper;
