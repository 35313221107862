import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useDatabaseEntitiesRoutes from 'routes/database/entities/entities.routes';
import DatabaseProfileProvider from 'components/organisms/Providers/ProfileProvider/DatabaseEntityProfileProvider';
import utils from 'helpers/utils';

export default function useDatabaseRoutes () {
  const entitiesRoutes = useDatabaseEntitiesRoutes();
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <entitiesRoutes.WrapperComponent><Outlet /></entitiesRoutes.WrapperComponent>,
        children: entitiesRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'database.entity.route'
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <DatabaseProfileProvider>
          {children}
        </DatabaseProfileProvider>
      },
      route: route
    };
  }, [entitiesRoutes]);
};
