import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntityTractionGraphProfileCardContent
  from 'components/organisms/Cards/EntityTractionGraphProfileCardContent/EntityTractionGraphProfileCardContent.styles';
import TractionAreaChart from 'components/organisms/Charts/TractionAreaChart/TractionAreaChart';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {P} from 'components/atoms/Text/Typography/Typography';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Tab from 'components/atoms/Tabs/Tab/Tab';
import Tabs from 'components/atoms/Tabs/Tabs/Tabs';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Icon from 'components/atoms/Icons/Icon/Icon';

const EntityTractionGraphProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    entity,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityTractionGraphProfileCardContent', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  const [internalState, setInternalState] = useState({
    type: constants.data.lookup('tractionTypes', constants.entity.traction.types.fte),
    period: null,
    activeTabIdx: 0
  })

  useImperativeHandle(ref, () => innerRef.current);

  const isEditing = content.state.isEditing;
  const source = entity?.[utils.camelcase(`traction_${internalState.type.value}_source`)];
  const tractionByType = entity?.[utils.camelcase(`traction_${internalState.type.value}_history`)];

  const fields = useMemo(() => {
    const initialOption = constants.data.tractionTypes.find((opt) => opt.value === internalState.type.value);

    return [{
      name: 'type',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a type',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'smaller'
      },
      options: constants.data.tractionTypes
    }];
  }, [internalState.type]);

  const traction = useMemo(() => {
    return (tractionByType ?? [])
      .filter((t) => +t.value !== 0);
  }, [tractionByType]);

  useEffect(() => {
    let periodIdx = constants.data.tractionPeriods.findIndex((period) => {
      const from = new Date(`${(new Date()).toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`);
      from.setMonth(from.getMonth() - (period.months - 1));

      return Boolean(!traction?.some((tp) => {
        return +tp.value > 0 && from.getTime() >
          (new Date(`${(new Date(tp.date)).toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`)).getTime()
      }));
    });

    periodIdx = periodIdx !== -1 ? periodIdx : constants.data.tractionPeriods.length - 1;
    setInternalState(utils.updater({
      activeTabIdx: periodIdx,
      period: constants.data.tractionPeriods[periodIdx]
    }, true));
  }, [traction]);

  const handleTypeChange = (field, value) => {
    setInternalState(utils.updater({type: value}, true));
  };

  const handleTabChange = (e, value) => {
    setInternalState(utils.updater({
      activeTabIdx: value,
      period: constants.data.tractionPeriods[value]
    }, true));
  };

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledEntityTractionGraphProfileCardContent ref={innerRef} {...innerProps}>
    <InlineForm className="EntityTractionGraphProfileCardContent-type"
                onChange={handleTypeChange}
                fields={fields} />
    {(traction?.length > 0 || isLoading) ? <React.Fragment>
      <TractionAreaChart className="EntityTractionGraphProfileCardContent-chart"
                         isLoading={isLoading}
                         traction={traction} period={internalState.period}/>
      <Tabs className="EntityTractionGraphProfileCardContent-tabs"
            size="small"
            density="dense"
            value={internalState.activeTabIdx ?? 0}
            onChange={handleTabChange}>
        {constants.data.tractionPeriods.map((p, idx) => {
          return <Tab key={idx} value={idx} label={p.label}/>
        })}
      </Tabs>
      <Box className="EntityTractionGraphProfileCardContent-info">
        <Icon icon={InfoOutlined} color="primary" size="smaller"/>
        <Typography variant="caption" color="text.secondary">
          {internalState.type.label} traction is based on {constants.data.lookup('diskSourceTypes', source).label} data
        </Typography>
      </Box>
    </React.Fragment> : null}
    {(!(traction?.length > 0) && !isLoading) ?
      <Box className="EntityTractionGraphProfileCardContent-empty">
        <P>No {internalState.type.label.toLowerCase()} traction data found</P>
      </Box> : null}
  </StyledEntityTractionGraphProfileCardContent>
});

EntityTractionGraphProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  isLoading: PropTypes.bool
};

EntityTractionGraphProfileCardContent.defaultProps = {
};

export default EntityTractionGraphProfileCardContent;


