import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BrowserList from 'components/organisms/Browsers/BrowserList/BrowserList';

const StyledEntitiesBrowserList = styled(BrowserList)`
  ${ComponentStyles}
`;

StyledEntitiesBrowserList.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesBrowserList.defaultProps = {
};

export default StyledEntitiesBrowserList;
