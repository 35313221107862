import React from 'react';
import StyledContainer from './Container.styles';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';

const Container = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Container');

  return <StyledContainer ref={ref} {...innerProps}>{innerProps.children}</StyledContainer>
}));

Container.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Container.defaultProps = {
  children: 'Container text',
};

export default Container;
