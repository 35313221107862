import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableRow from 'components/atoms/Tables/TableRow/TableRow.styles';
import {withMemo} from 'helpers/wrapper';

const TableRow = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'TableRow');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableRow ref={ref} {...innerProps}/>
}));

TableRow.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TableRow.defaultProps = {
};

export default TableRow;
