import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ReactMarkdown from 'react-markdown';

const StyledMarkdown = styled(ReactMarkdown)`
  ul {
    margin-left: ${props => props.theme.spacing(2)};
  }
  p:not(:first-child) {
    padding-top: ${props => props.theme.spacing(2)};
  }
  a {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.property('palette.primary.main')};
    
    &:focus {
      text-decoration: underline;
    }
  }
  ${ComponentStyles}
`;

StyledMarkdown.propTypes = {
  theme: PropTypes.object
};

StyledMarkdown.defaultProps = {
}

export default StyledMarkdown;
