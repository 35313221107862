import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledTableFilters = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  
  .TableFilters {
    &-empty {
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
    
    &-toolbar {
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      &-title {
        flex-grow: 1;
      }
    }
    
    &-content {
      display: flex;
      flex-grow: 1;
      height: 1px;
    }
    
    &-tabs {
      min-height: 100%;
      max-height: 100%;
      min-width: ${props => props.theme.layout(227)};
      max-width: ${props => props.theme.layout(227)};

      ${props => props.theme.breakpoints.down('md')} {
        min-width: ${props => props.theme.layout(180)};
      }
      
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      box-shadow: inset -1px 0px 0px ${props => props.theme.property('palette.divider')};
      
      overflow-y: auto;
      &:not(:hover, :focus) {
        ::-webkit-scrollbar {
          width: 0;
        }
      }
      
      .Tabs {
        padding: ${props => props.theme.spacing(1)} 0 ${props => props.theme.spacing(1)} 0;

        .Tab {
          align-items: flex-start;
          margin-left: ${props => props.theme.layout('3sp')};
          
          &:not(.Mui-selected) {
            .Badge .MuiBadge-badge {
              color: ${props => props.theme.property('palette.text.primary')};
              background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
            }
          }
        }

        .Divider {
          margin-top: ${props => props.theme.layout('1sp')};
          margin-bottom: ${props => props.theme.layout('1sp')};
        }
      }
    }
    
    &-search {
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
    }
    
    &-panels {
      width: 100%;
      position: relative;
      
      &-scroll {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        padding-bottom: ${props => props.theme.spacing(8)};

        &:not(:hover, :focus) {
          ::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
    
    &-panel {
      height: fit-content;
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      
      .TableFilters-group {
        &:not(:first-child) {
          margin-top: ${props => props.theme.spacing(1)};
        }
        
        .TableFiltersContent-filter {
          scroll-margin-top: ${props => props.theme.layout(68)};
          scroll-margin-bottom: ${props => props.theme.layout(120)};
        }
      }
    }
    
    &-count {
      width: 100%;
      position: absolute;
      bottom: ${props => props.theme.layout('3sp')};
      display: flex;
      justify-content: center;
      pointer-events: none;

      .Fab {
        pointer-events: all;
        width: min(${props => props.theme.layout(360)}, calc(100% - ${props => props.theme.layout('4sp')}));
        .CircularProgress > * {
          width: ${props =>props.theme.layout(20)} !important;
          height: ${props =>props.theme.layout(20)} !important;
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledTableFilters.propTypes = {
  theme: PropTypes.object
};

StyledTableFilters.defaultProps = {}

export default StyledTableFilters;
