import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledSlider from 'components/atoms/Sliders/Slider/Slider.styles';

const Slider = React.forwardRef((props, ref) => {
  const {
    hideMarkLabels,
    ...innerProps
  } = useComponentProps(props, 'Slider', {
    static: ['hideMarkLabels', 'hideThumbs', 'disabled']
  });

  const hideThumbs = !utils.isDefined(innerProps.defaultValue) &&
    utils.toArray(innerProps.value).filter((v) => utils.isDefined(v)).length === 0;

  innerProps.className = utils.flattenClassName(innerProps.className, {
    hideThumbs
  });

  return <StyledSlider ref={ref} {...innerProps} />
});

Slider.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Slider.defaultProps = {
  color: 'primary'
};

export default Slider;
