import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useLinkNavigate, useSplitPath} from 'helpers/hooks/links';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledDealflowEntitySideBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntitySideBarWrapper/DealflowEntitySideBarWrapper.styles';
import DealflowEntitiesList from 'components/organisms/Lists/DealflowEntitiesList/DealflowEntitiesList';
import useMediaQuery from '@mui/material/useMediaQuery';

const DealflowEntitySideBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitySideBarWrapper');

  const profileProvider = useProfile();
  const [open, setOpen] = useState(true);
  const [animate, setAnimate] = useState(false);

  const navigate = useLinkNavigate();

  const [pathPrefix, pathPostfix] = useSplitPath('dealflow');
  const pathPostSplit = pathPostfix.split('/');
  const postFix = pathPostSplit.length > 1 ? `/${pathPostSplit.slice(1).join('/')}` : '';
  const pathKey = pathPostSplit[1] ?? 'profile';

  const handleClick = (e, entity) => {
    if (entity?.entityId) {
      navigate({
        event: e,
        to: `${pathPrefix}/${entity.entityId}${postFix}`, keepSearchParams: true
      });
    }
  };

  useLayoutEffect(() => {
    setAnimate(false);
  }, [pathKey]);

  useEffect(() => {
    setAnimate(true);
  }, [pathKey]);

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleContextToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!mobile);
  }, [mobile]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntitySideBarWrapper ref={ref} {...innerProps}
                                             variant={mobile ? 'temporary' : 'standard'}
                                             context={<DealflowEntitiesList onClick={handleClick}/>}
                                             open={open && Boolean(profileProvider.state?.showSidebar?.[pathKey])}
                                             animate={animate}
                                             onToggle={handleContextToggle}
                                             size="small"/>
});

DealflowEntitySideBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitySideBarWrapper.defaultProps = {
  anchor: 'left'
};

export default DealflowEntitySideBarWrapper;
