import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TimelinePanel from 'components/organisms/Panels/TimelinePanel/TimelinePanel';

const StyledEntityTimelinePanel = styled(TimelinePanel)`
  ${ComponentStyles}
`;

StyledEntityTimelinePanel.propTypes = {
  theme: PropTypes.object
};

StyledEntityTimelinePanel.defaultProps = {}

export default StyledEntityTimelinePanel;
