import React, {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const DimensionsProvider = (props) => {
  const postDimensions = useCallback(() => {
    const msg = {
      event: `viqDimensions`,
      height: document.body.clientHeight,
      width: document.body.clientWidth,
      scrollHeight: document.body.scrollHeight,
      scrollWidth: document.body.scrollWidth,
      scrollX: window.scrollX,
      scrollY: window.scrollY
    };

    window.parent.postMessage(JSON.stringify(msg),'*');
  }, []);

  useEffect(() => {
    return utils.observeEvent(window, 'keydown', () => {
      postDimensions();
    }, {timeout: constants.debounce.short});
  }, [postDimensions]);

  useEffect(() => {
    return utils.observeEvent(window, 'mousedown', () => {
      postDimensions();
    }, {timeout: constants.debounce.short});
  }, [postDimensions]);

  useEffect(() => {
    return utils.observeScroll(window, () => {
      postDimensions();
    }, {timeout: constants.debounce.short});
  }, [postDimensions]);

  useEffect(() => {
    return utils.observeResize(window, () => {
      postDimensions();
    }, {timeout: constants.debounce.short});
  }, [postDimensions]);

  useEffect(() => {
    return utils.observeResize(document.body, () => {
      postDimensions();
    }, {timeout: constants.debounce.short});
  }, [postDimensions]);

  useEffect(() => {
    return utils.observeInterval(() => {
      postDimensions();
    }, constants.delay.dimensions);
  }, [postDimensions]);

  return <React.Fragment>
    {props.children}
  </React.Fragment>
};

DimensionsProvider.propTypes = {
  theme: PropTypes.any
}

export default DimensionsProvider;
