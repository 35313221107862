import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import CollectionEntitiesTableColumns
  from 'components/organisms/TableColumns/CollectionEntitiesTableColumns/CollectionEntitiesTableColumns';
import StyledCollectionEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesColumnsWrapper/CollectionEntitiesColumnsWrapper.styles';

const CollectionEntitiesColumnsWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesColumnsWrapper');

  const tableProvider = useTable();

  return <StyledCollectionEntitiesColumnsWrapper ref={ref} {...innerProps}
                                                 variant="foldout"
                                                 context={<CollectionEntitiesTableColumns />}
                                                 open={Boolean(tableProvider.state?.showColumns)}
                                                 size="huge"
                                                 ContextDrawerProps={{
                                                   onToggle: (e, open) => {
                                                     if (open) {
                                                       tableProvider.openColumns();
                                                     } else {
                                                       tableProvider.closeColumns();
                                                     }
                                                   }
                                                 }}>
    {innerProps.children}
  </StyledCollectionEntitiesColumnsWrapper>
});

CollectionEntitiesColumnsWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesColumnsWrapper.defaultProps = {
  children: 'CollectionEntitiesColumnsWrapper text',
};

export default CollectionEntitiesColumnsWrapper;
