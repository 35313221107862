import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledSourceCard = styled(Card)`
  position: relative;
  padding: ${props => props.theme.layout('1.5sp')} ${props => props.theme.layout('2sp')};
  background-color: unset;
  box-shadow: unset;
  border: 1px solid ${props => props.theme.property('palette.divider')};

  .SourceCard-content { 
    display: flex;
    gap: ${props => props.theme.layout('2sp')};
    position: relative;
    
    .SourceCard-logo {
      width: fit-content;
      .Icon {
        font-size: ${props => props.theme.fontSize(40)};
      }

      img {
        width: ${props => props.theme.layout(40)};
        height: ${props => props.theme.layout(40)};
      }
    }

    .SourceCard-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 1px;

      .SourceCard-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }

    .SourceCard-details {
      display: flex;
      flex-direction: column;

      .SourceCard-detail {
        display: flex;
        overflow: hidden;

        &-label {
          min-width: ${props => props.theme.layout(104)};
          max-width: ${props => props.theme.layout(104)};
          color: ${props => props.theme.property('palette.text.secondary')};
        }
        
        &-value {
          flex-grow: 1;
          overflow: hidden;
          
          .ActionLink {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    
    .SourceCard-description {
      color: ${props => props.theme.property('palette.text.secondary')};
    }

    .SourceCard-icon {
      display: flex;
      align-items: center;
    }
  }
  
  &.SourceCard-inset {
    padding: 0;
    border: unset;
    display: flex;
    flex-direction: column;
    min-height: ${props => props.theme.layout(280)};
    max-height: ${props => props.theme.layout(280)};

    .SourceCard {
      &-content {
        flex-grow: 1;
        padding: ${props => props.theme.spacing(2)};
        gap: ${props => props.theme.layout('0.25sp')};
        flex-direction: column;

        .SourceCard-logo {
          flex-grow: 1;
        }
      }
      
      &-info {
        width: 100%;
        gap: ${props => props.theme.layout('1sp')};
      }

      &-title {
        ${props => props.theme.font2Css('body2')};
        max-width: fit-content;
      }
      
      &-detail {
        display: flex;
        flex-wrap: nowrap;
        gap: ${props => props.theme.layout('0.5sp')};

        &-icon {
          margin-top: ${props => props.theme.layout(1)};
        }
        &-badge {
          margin-top: ${props => props.theme.layout(2)};
        }
        &-value, &-value > * {
          color: ${props => props.theme.property('palette.text.secondary')};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }

        &-icon.error, &-icon.error > * {
          color: ${props => props.theme.property('palette.error.main')};
        }
        &-value.error, &-value.error > * {
          color: ${props => props.theme.property('palette.error.main')};
        }
      }
      
      &-stats {
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        background-color: ${props => props.theme.property('palette.primary.states.hover')};
        padding: 0 ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(1)};

        min-height: ${120 / 280 * 100}%;
        max-height: ${120 / 280 * 100}%;
        
        .SourceStatsPieChart {
          margin-top: -${props => props.theme.spacing(0.25)};
        }
      }
    }
  }

  &.SourceCard-suggested, &.SourceCard-wizard {
    width: 100%;
    
    .SourceCard-info {
      gap: ${props => props.theme.layout('1sp')};
      
      .SourceCard-title {
        width: fit-content;
        max-width: calc(100% - ${props => props.theme.layout(108)});
      }
    }
    
    .SourceCard-button {
      display: flex;
      align-items: start;
      position: absolute;
      right: 0;
    }
  }

  &.SourceCard-wizard {
    .SourceCard-info {
      .SourceCard-title {
        max-width: calc(100% - ${props => props.theme.layout(128)});
      }
    }
    
    .SourceCard-details {
      .SourceCard-detail {
        &-label {
          min-width: ${props => props.theme.layout(80)};
          max-width: ${props => props.theme.layout(80)};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSourceCard.propTypes = {
  theme: PropTypes.object
};

StyledSourceCard.defaultProps = {}

export default StyledSourceCard;
