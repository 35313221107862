import React, {useMemo} from 'react'
import utils from 'helpers/utils';
import CollectionEntitiesGraphsPage
  from 'components/pages/Collection/Entity/Graph/CollectionEntitiesGraphsPage/CollectionEntitiesGraphsPage';

export default function useCollectionEntitiesGraphsRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <CollectionEntitiesGraphsPage />,

        handle: {
          title: 'Collection - graphs',
          auth: utils.createAuth({ attribute: 'collection.entity.graph.list' })
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, []);
};
