import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCreditDistributionPieChart = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .CreditDistributionPieChart {
    &-chart {
      height: 100%;
      overflow: hidden;
    }
    
    &-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: -${props => props.theme.layout('4.5sp')};
      height: calc(100% + ${props => props.theme.layout('9sp')});
      max-height: unset;
      width: 100%;
      
      aspect-ratio: 1;
    }

    &-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;

      .totalCount {
        line-height: ${props => props.theme.lineHeight('1f')};
      }
      .title {
        text-align: center;
      }
    }
    
    &-legend {
      flex-grow: 1;
      align-items: flex-start;
    }
  }

  // variants
  &.CreditDistributionPieChart-half {
    .CreditDistributionPieChart {
      &-center {
        height: 100%;
        justify-content: end;

        .Icon {
          font-size: ${props => props.theme.fontSize(24)};
        }

        .totalCount {
          margin-top: ${props => props.theme.layout(4)};
          margin-bottom: ${props => props.theme.layout(-3)};
          font-size: ${props => props.theme.fontSize(20)};
          line-height: 1;
          font-weight: 400;
        }

        .title {
          display: none;
        }
      }
    }
  }

  &.CreditDistributionPieChart-showActiveShape {
    .CreditDistributionPieChart {
      &-center {
        margin: 30%;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCreditDistributionPieChart.propTypes = {
  theme: PropTypes.object
};

StyledCreditDistributionPieChart.defaultProps = {}

export default StyledCreditDistributionPieChart;
