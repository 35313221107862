import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTeamMembersAvatarGroup = styled(Box)`
  &.TeamMembersAvatarGroup {
    display: flex;
    align-items: center;
    
    .ActionIconButton {
      padding: ${props => props.theme.layout(6)};
    }
  }
  ${ComponentStyles}
`;

StyledTeamMembersAvatarGroup.propTypes = {
  theme: PropTypes.object
};

StyledTeamMembersAvatarGroup.defaultProps = {}

export default StyledTeamMembersAvatarGroup;
