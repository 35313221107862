import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';

const path = utils.servicePath(import.meta.url);

export function useClientUserStatus () {
  return useServiceStatus(path);
}

export function useClientUserList ({clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, search, filter, sort, page, pageSize, ...rest}, {
    ...queryOptions
  });
}

export function useClientUserGet ({userId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {userId}, {
    ...queryOptions
  });
}

export function useClientUserAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'clients/:clientId/users',
    ...mutationOptions
  });
}

export function useClientUserUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, {
    ...mutationOptions
  });
}

export function useClientUserDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

// bulk
export function useClientUserAddPasses (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'clients/:clientId/users/passes',
    ...mutationOptions
  });
}
