import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import MainLoader from 'components/molecules/Loaders/MainLoader/MainLoader';

export const LoaderContext = React.createContext(null)

export function useLoader () {
  return React.useContext(LoaderContext);
}

const LoaderProvider = (props) => {
  const [show, setShow] = useState(false);

  const {
    LoaderComponent = MainLoader,
    children
  } = props;

  // context
  const context = useMemo(() => ({
    show: () => {
      setShow(true);
    },
    hide: () => {
      setShow(false);
    }
  }), []);

  // inner
  return <React.Fragment>
    <LoaderComponent show={show} />
    <LoaderContext.Provider value={context}>
      {children}
    </LoaderContext.Provider>
  </React.Fragment>
};

LoaderProvider.propTypes = {
  LoaderComponent: PropTypes.elementType
}

export default LoaderProvider;
