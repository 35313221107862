import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionEntitiesTable
  from 'components/organisms/Tables/CollectionEntitiesTable/CollectionEntitiesTable.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ButtonGroupTableCell from 'components/molecules/TableCells/ButtonGroupTableCell/ButtonGroupTableCell';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';

const AddRejectActionCell = React.forwardRef(({cell, collection, isSuggestion,
                                                entityAssign, entityUnAssign, entityAccept,
                                                snackbar, dialogControl}, ref) => {
  const entity = cell.row.original;

  const actions = useMemo(() => {
    return [{
      auth: utils.createAuth({attribute: 'collection.update', meta: {collection}}),
      label: isSuggestion ? 'Remove suggestion' : 'Remove from collection',
      tooltip: isSuggestion ? 'Remove suggestion' : 'Remove from collection',
      icon: Delete,
      color: 'error',
      onClick: (e) => {
        if (isSuggestion) {
          entityUnAssign({collectionId: collection.collectionId, entityIds: [entity.entityId]})
            .catch(() => {
              snackbar.show(`Removing suggestion '${entity.name}' from collection '${collection.name}' failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        } else {
          const handleConfirm = () => {
            return entityUnAssign({entityId: entity.entityId})
              .catch(() => {
                snackbar.show('Removing company from collection failed', null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
          }

          const question = `Are you sure you want to remove '${entity.name}' from '${collection.name}'?`;

          dialogControl.show(<ConfirmDialog question={question}
                                            explanation="The company will only be removed from this collection but will remain in the database"
                                            onConfirm={handleConfirm}
                                            ConfirmButtonProps={{
                                              children: 'Remove company',
                                              color: 'error'
                                            }}/>, true);
        }

        e.preventDefault();
      }
    }, {
      auth: utils.createAuth({attribute: 'collection.update', meta: {collection}}),
      label: 'Add to collection',
      tooltip: 'Add to collection',
      icon: Add,
      color: 'primary',
      onClick: (e) => {
        if (isSuggestion) {
          entityAccept({collectionId: collection.collectionId, entityIds: [entity.entityId]})
            .catch(() => {
              snackbar.show(`Adding company '${entity.name}' to collection '${collection.name}' failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        } else {
          entityAssign({collectionId: collection.collectionId, entityIds: [entity.entityId]})
            .catch(() => {
              snackbar.show(`Adding company '${entity.name}' to collection '${collection.name}' failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        }
        e.preventDefault();
      }
    }];
  }, [collection, entity, isSuggestion, entityAssign, entityUnAssign, entityAccept, dialogControl, snackbar]);

  const ActionButtonGroupProps = useMemo(() => ({
    ActionButtonComponent: ActionIconButton,
    ActionButtonProps: {
      showInactive: true,
      variant: 'outlined',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    },
    radius: 'round',
    variant: 'outlined',
    actions
  }), [actions]);

  return <ButtonGroupTableCell ref={ref}
                               ActionButtonGroupProps={ActionButtonGroupProps}/>
});

const CollectionEntitiesTable = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTable');

  const tableProvider = useTable();
  const isSuggestion = tableProvider?.view?.name === 'suggestions';
  const collection = tableProvider.context?.data;
  const columnDefinitions = tableProvider.columnDefinitions;

  const authorize = useAuthorize();

  const entityUnAssignEvent = useEffectEvent(tableProvider.updaters?.unAssignItems);
  const entityAssignEvent = useEffectEvent(tableProvider.updaters?.assignItems);
  const entityAcceptEvent = useEffectEvent(tableProvider.updaters?.acceptItems);

  const handleCanUpdate = useCallback((entity, column) => {
    return authorize({attribute: 'collection.entity.update', meta: {collection, entity}}) &&
      authorize(column.auth?.update ? {...column.auth?.update, meta: {
          ...column.auth?.update?.meta,
          collection,
          entity
        }} : {})
  }, [collection, authorize]);

  const clearSelectionEvent = useEffectEvent(tableProvider.listSelection?.clearSelection);
  const columns = useMemo(() => {
    const columns = [];
    if (columnDefinitions) {
      columnDefinitions
        .forEach((columnDef) => {
          if (columnDef.name === 'addRejectCollection') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              size: 100,
              minSize: 100,
              enableSorting: columnDef.sortable !== false,
              enableEditing: false,
              Cell: (props) => <AddRejectActionCell {...props}
                                                    collection={collection}
                                                    isSuggestion={isSuggestion}
                                                    entityAssign={entityAssignEvent}
                                                    entityUnAssign={entityUnAssignEvent}
                                                    entityAccept={entityAcceptEvent}
                                                    clearSelection={clearSelectionEvent}/>
            })
          }
        });
    }

    return columns;
  }, [isSuggestion, columnDefinitions, collection, clearSelectionEvent, entityAssignEvent, entityUnAssignEvent, entityAcceptEvent]);


  return <StyledCollectionEntitiesTable ref={ref} {...innerProps}
                                        columns={columns}
                                        onCanUpdate={handleCanUpdate}/>
});

CollectionEntitiesTable.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTable.defaultProps = {
};

export default CollectionEntitiesTable;
