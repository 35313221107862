import React, {useMemo} from 'react';
import Component from 'components/organisms/Utils/Component/Component';
import {useEffectItem, useUpdatedRef} from 'helpers/hooks/utils';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import {withMemo} from 'helpers/wrapper';
import Typography from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';

const TableCellContent = withMemo((props) => {
  const priority = (props.column.current.getIsPinned() === 'left' && props.column.current.getPinnedIndex() < 2) ||
    (props.column.current.getIsPinned() === 'right' && props.column.current.getPinnedIndex() < 2)
  const multiplier = priority ? 1 : 2 + (
    ((props.row.current?.index ?? 0) % 3) +
    (props.table.current.getVisibleLeafColumns().findIndex((c) => c.id === props.column.current.id) % 3)
  );
  return <Debounce placeholder={props.loaders ? props.Skeleton : null}
                   timeout={(!props.debounce || props.header || props.edit) ? 0 : multiplier * constants.debounce.minimal}>
    <Component Original={props.Cell}
               table={props.table.current}
               column={props.column.current}
               row={props.row.current}
               cell={props.cell.current}
               data={props.data}/>
  </Debounce>
})

const TableCell = (props) => {
  const {
    header,
    table,
    column,
    row,
    cell,
    edit,
    wasEditing
  } = props;

  const key = header ? `${column.id}` : `${column.id}_${row.id}`;
  const tableRef = useUpdatedRef(table);
  const columnRef = useUpdatedRef(column);
  const rowRef = useUpdatedRef(row);
  const cellRef = useUpdatedRef(cell);

  const Cell = useEffectItem(header ? column.columnDef.DefaultHeader : (
    edit ? column.columnDef.DefaultEdit : column.columnDef.DefaultCell
  ));
  const isLoading = Boolean(tableRef.current.getState().customIsLoading);
  const debounce = table.getState().debounce && !wasEditing;
  const loaders = table.getState().loaders;

  const size = column.getSize();
  const skeleton = useMemo(() => {
    const netSize = size - 32; // minus padding
    return <Typography isLoading={true}
                       variant="span"
                       min={Math.max(2, (netSize - netSize / 3) / 8)}
                       max={Math.max(4, netSize / 8)}/>
  }, [size]);

  if (!header && !edit && isLoading) {
    return skeleton;
  } else if (Cell) {
    return <TableCellContent key={key}
                             data={rowRef.current?.original}
                             Cell={Cell}
                             Skeleton={skeleton}
                             header={header}
                             edit={edit}
                             debounce={debounce}
                             loaders={loaders}
                             table={tableRef}
                             column={columnRef}
                             row={rowRef}
                             cell={cellRef}/>
  }
}

export default TableCell;
