import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import FilesContextCard from 'components/organisms/Cards/FilesContextCard/FilesContextCard';

const StyledEntityFilesContextCard = styled(FilesContextCard)` 
  ${ComponentStyles}
`;

StyledEntityFilesContextCard.propTypes = {
  theme: PropTypes.object
};

StyledEntityFilesContextCard.defaultProps = {}

export default StyledEntityFilesContextCard;
