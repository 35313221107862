import React, {useLayoutEffect, useMemo} from 'react'
import TableProvider, {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {
  useCollectionAdd, useCollectionDeleteBulk, useCollectionClone,
  useCollectionDelete,
  useCollectionGet,
  useCollectionList, useCollectionMerge
} from 'services/collection/collection.hooks';
import constants from 'helpers/constants';
import {useEffectEvent, useProviderDataState, useProviderView} from 'helpers/hooks/utils';
import {
  useCollectionColumns,
  useCollectionPatch, useCollectionProfileCallbacks, useCollectionSelected,
  useCollectionToggleFavorite, useCollectionToggleLabel,
  useCollectionToggleVisibility
} from 'services/collection/collection.utils';
import {useParams} from 'react-router-dom';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';

const CollectionTableLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState();

  const list = useCollectionList({
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enablePreload: true,
    enabled: (active && listState.active)
  });

  const setListEvent = useEffectEvent(tableProvider.setList);
  useLayoutEffect(() => {
    setListEvent?.(list);
  }, [setListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const CollectionsTableProvider = (props) => {
  const params = useParams();
  const { collectionId } = {...params, ...props};

  const clientId = useAuthClientId();

  const dataKey = 'collectionId';
  const view = useProviderView('collection', props.skipView);

  const client = useClientGet({clientId}, {enabled: clientId >= 0});
  const collection = useCollectionGet({collectionId}, {enabled: +collectionId > 0});

  const [context, onContext] = useProviderDataState(client);
  const [data, onData] = useProviderDataState(collection);

  const profileCallbacks = useCollectionProfileCallbacks();
  const columns = useCollectionColumns(view);

  const isLoading = props?.isLoading || client.status?.isLoading || collection.status?.isLoading;
  
  const columnDefinitions = useMemo(() => {
    return columns?.length > 0 ? columns : null;
  }, [columns]);

  const fieldData = useMemo(() => ({
    callbacks: profileCallbacks
  }), [profileCallbacks]);

  const filterGroupDefinitions = useMemo(() => {
    if (!view || view) { // any view
      return null;
    }
  }, [view]);

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        type: constants.appState.type.local,
        name: 'collections',
        searchParams: true,
        searchParamsRoutes: /^((?!\/entities).)*$/i // apply searchParams only before entities
      }
    },
    columnState: {
      initial: {},
      options: {
        name: 'collections',
        type: constants.appState.type.local
      }
    },
    columnDefinitions,
    filterGroupDefinitions
  }), [columnDefinitions, filterGroupDefinitions]);

  const handleCompare = (itmA, itmB) => {
    return +itmA.collectionId === +itmB.collectionId;
  };

  const updateData = useCollectionPatch();
  const createData = useCollectionAdd();
  const cloneData = useCollectionClone();
  const mergeData = useCollectionMerge();
  const deleteData = useCollectionDelete();
  const deleteDataBulk = useCollectionDeleteBulk();
  const toggleLabel = useCollectionToggleLabel();
  const toggleFavorite = useCollectionToggleFavorite({refetchContext: false});
  const toggleVisibility = useCollectionToggleVisibility({refetchContext: false});
  const selectedItems = useCollectionSelected();
  const updaters = useMemo(() => {
    return {
      updateData: updateData,
      createData: (data) => {
        return createData.mutation.mutateAsync(data);
      },
      cloneData: (data) => {
        return cloneData.mutation.mutateAsync(data);
      },
      mergeData: (data) => {
        return mergeData.mutation.mutateAsync(data);
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync(data);
      },
      deleteDataBulk: (data) => {
        return deleteDataBulk.mutation.mutateAsync(data);
      },
      toggleLabel: toggleLabel,
      toggleFavorite: toggleFavorite,
      toggleVisibility: toggleVisibility,
      selectedItems: selectedItems
    }
  }, [createData.mutation, cloneData.mutation, mergeData.mutation, updateData, deleteData.mutation, selectedItems,
    deleteDataBulk.mutation, toggleLabel, toggleFavorite, toggleVisibility]);

  return <TableProvider {...props}
                        view={view}
                        dataKey={dataKey}
                        data={data}
                        onData={onData}
                        context={context}
                        onContext={onContext}
                        options={options}
                        fieldData={fieldData}
                        updaters={updaters}
                        LoaderComponent={CollectionTableLoader}
                        isLoading={isLoading}
                        onCompare={handleCompare}>
    {props.children}
  </TableProvider>
};

CollectionsTableProvider.propTypes = {
}

export default CollectionsTableProvider;
