import React, {useImperativeHandle, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledActionTabs from 'components/molecules/Tabs/ActionTabs/ActionTabs.styles';
import ActionTab from 'components/molecules/Tabs/ActionTab/ActionTab';
import {useAuthorizeAction} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const ActionTabs = React.forwardRef((props, ref) => {
  const {
    actions,
    value,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'ActionTabs');

  const [internalState, setInternalState] = useState({});

  const authorizeAction = useAuthorizeAction();

  const actionTabs = useMemo(() => ({
    state: {
      ...internalState,
      ...utils.cleanObject({value})
    },
  }), [internalState, value]);

  useImperativeHandle(ref, () => actionTabs);

  const handleTabChange = (e, value) => {
    onChange?.(e, value);

    setInternalState(utils.updater({value}, true));
  }

  const handleTabRef = (value, tab) => {
    if (tab?.active) {
      handleTabChange(null, value);
    }
  }

  const actionsMemo = useMemo(() => {
    return actions
      .filter((action) => {
         return action?.ActionTabProps?.showInactive || authorizeAction(action);
      })
  }, [actions, authorizeAction]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledActionTabs {...innerProps}
                           onChange={handleTabChange}
                           value={actionTabs.state.value ?? 0}>
    {actionsMemo?.map((action, idx) => <ActionTab key={idx}
                                                  ref={(tab) => handleTabRef(idx, tab)}
                                                  value={idx}
                                                  action={action} />)}
  </StyledActionTabs>
});

ActionTabs.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func
};

ActionTabs.defaultProps = {
  actions: []
};

export default ActionTabs;
