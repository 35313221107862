import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import LineChart from 'components/organisms/Charts/LineChart/LineChart';
import StyledDealflowDevelopmentLineChart
  from 'components/organisms/Charts/DealflowDevelopmentLineChart/DealflowDevelopmentLineChart.styles';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const DealflowDevelopmentLineChart = React.forwardRef((props, ref) => {
  const {
    dealflow,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    LineChartProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowDevelopmentLineChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (dealflow) {
      legend = dealflow
        .reduce((a, df) => {
          const year = +(new Date(df.month)).getFullYear();

          if (!a.find((l) => l.id === year)) {
            a.push({
              id: year,
              position: year,
              label: `${year}`,
              color: utils.number2Color(Math.pow(year, 2)),
              active: visibility?.[year] ?? true
            });
          }
          return a;
        }, []);
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [dealflow, visibility]);

  const dealflowData = useMemo(() => {
    if (dealflow) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        lines: dealflow
          .reduce((a, df) => {
            const year = +(new Date(df.month)).getFullYear();
            let item = a.find((y) => y.id === year);
            if (!item) {
              item = {
                id: year,
                position: year,
                dataKey: year,
                year: year,
                label: `${year}`,
                color: utils.number2Color(Math.pow(year, 2))
              };
              a.push(item);
            }

            return a;
          }, [])
          .filter((g) => {
            return Boolean(legend.find((l) => l.active && +l.id === +g.id))
          }),
        data: dealflow
          .reduce((a, df, idx) => {
            const year = +(new Date(df.month)).getFullYear();
            const name = utils.dayjs(new Date(df.month)).format('MMM');
            let item = a.find((i) => i.name === name);
            if (!item) {
              item = {
                name: name,
                position: idx
              }
              a.push(item);
            }
            item[year] = df.count;
            item[`${year}-meta`] = {
              label: `${name} (${year})`,
              countLabel: `${df.count} companies`,
              month: df.month
            }
            return a;
          }, []),
        TooltipComponent: BreakdownTooltip
      };
    }
  }, [dealflow, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowDevelopmentLineChart ref={ref} {...innerProps}>
    <Box className="DealflowDevelopmentLineChart-chart">
      <LineChart {...dealflowData}
                 showGrid
                 showTooltip
                 isLoading={isLoading}
                 layout="horizontal"
                 hoveredId={internalState.hoveredId}
                 onClick={onClick}
                 TooltipProps={{
                   density: 'sparse'
                 }}
                 margin={{
                   top: 8, right: 8, bottom: 4, left: -24
                 }}
                 {...LineChartProps}/>
    </Box>
    {showLegend ? <Legend className="DealflowDevelopmentLineChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          variant="line"
                          rows={rows}/> : null}
  </StyledDealflowDevelopmentLineChart>
});

DealflowDevelopmentLineChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  LineChartProps: PropTypes.object
};

DealflowDevelopmentLineChart.defaultProps = {
};

export default DealflowDevelopmentLineChart;
