import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Email from 'components/pages/Base/Email/Email';

const StyledQuestionnaireEmailPage = styled(Email)`
  &.QuestionnaireEmailPage {
    .Table-outer {
      .Table.Table-inner {
        .TableRow {
          &.QuestionnaireEmailPage {
            &-questionnaireButtonRow {
              .TableCell {
                padding-top: ${props => props.theme.spacing(2)};
                padding-bottom: ${props => props.theme.spacing(2)};
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledQuestionnaireEmailPage.propTypes = {
  theme: PropTypes.object
};

StyledQuestionnaireEmailPage.defaultProps = {
};

export default StyledQuestionnaireEmailPage;
