import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledDealflowEntityContextWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledDealflowEntityContextWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntityContextWrapper.defaultProps = {}

export default StyledDealflowEntityContextWrapper;
