import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import StyledSnackbar from './Snackbar.styles';
import Button from 'components/atoms/Buttons/Button/Button';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import utils from 'helpers/utils';

const Snackbar = React.forwardRef((props, ref) => {
  const {
    action,
    size,
    onClose,
    ButtonProps,
    ...innerProps
  } = useComponentProps(props, 'Snackbar', {
    styled: ['color']
  });

  const wrapper = useWrapper();

  innerProps.message = innerProps.message ?? '';
  if (action) {
    innerProps.action = (
      <Button color="secondary"
              size="small"
              {...ButtonProps}>
        {action}
      </Button>
    );
  }

  const handleClickAway = (e) => {
    onClose?.(e, 'clickaway');
  }

  const onCloseEvent = useEffectEvent(onClose);
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      onCloseEvent?.(e, 'escapeKeyDown');
    }
  }, [onCloseEvent]);

  useEffect(() => {
    return utils.observeEvent(window, 'keydown', handleKeyDown);
  }, [handleKeyDown]);

  return <ClickAwayListener onClickAway={handleClickAway}>
    <StyledSnackbar ref={ref} {...innerProps} onClose={onClose} $wrapper={wrapper} />
  </ClickAwayListener>
});

Snackbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  message: PropTypes.string,
  action: PropTypes.string,
  onClose: PropTypes.func,
  ButtonProps: PropTypes.object
}

Snackbar.defaultProps = {
  message: 'Snackbar text',
  color: 'secondary'
};

export default Snackbar;
