import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledDealflowEntitiesTableBarWrapper = styled(ToolbarWrapper)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesTableBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesTableBarWrapper.defaultProps = {
};

export default StyledDealflowEntitiesTableBarWrapper;
