import React, {useEffect} from 'react'
import AppStore from 'stores/app.store';
import system from 'helpers/system';
import {useSearchParams} from 'react-router-dom';

export const AppContext = React.createContext(null)

export function useAppStore () {
  return React.useContext(AppContext);
}

const app = new AppStore(system.appName());

const AppProvider = ({children}) => {
  const [searchParams] = useSearchParams();

  const verbosity = searchParams.get('verbosity');

  useEffect(() => {
    system.appVerbosity(verbosity);
  }, [verbosity]);

  return <AppContext.Provider value={app}>
    {children}
  </AppContext.Provider>
};

AppProvider.propTypes = {
}

export default AppProvider;
