import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListItemText from 'components/atoms/Text/ListItemText/ListItemText.styles';
import {withMemo} from 'helpers/wrapper';

const ListItemText = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ListItemText', {styled: ['nowrap']});

  return <StyledListItemText ref={ref} {...innerProps}>{innerProps.children}</StyledListItemText>
}));

ListItemText.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ListItemText.defaultProps = {
  children: 'ListItemText text'
};

export default ListItemText;
