import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChipTableCell from 'components/molecules/TableCells/ChipTableCell/ChipTableCell';

const StyledTimelineTableCell = styled(ChipTableCell)`
  .Chip-size-large {
    min-width: ${props => props.theme.layout(130)};
  }
  
  .Chip {
    justify-content: space-around;
  }
  
  ${ComponentStyles}
`;

StyledTimelineTableCell.propTypes = {
  theme: PropTypes.object
};

StyledTimelineTableCell.defaultProps = {
}

export default StyledTimelineTableCell;
