import React, {useState} from 'react';
import StyledTestPage from './TestPage.styles';
import {useTestList, useTestStatus} from 'services/test/test.hooks';
import Snackbar from 'components/organisms/Snackbars/Snackbar/Snackbar';
import NavLink from 'components/atoms/Links/NavLink/NavLink';
import PropTypes from 'prop-types';
import Container from 'components/atoms/Layout/Container/Container';
import {Outlet, useMatch, useResolvedPath} from 'react-router-dom';
import logger from 'helpers/logger';
import {useComponentProps} from 'helpers/hooks/utils';

const TestPage = (props) => {
  const innerProps = useComponentProps(props, 'TestPage');

  // effects
  const [testFilter, setTestFilter] = useState(false);
  const testList = useTestList({filter: testFilter});
  const [testFilter2, setTestFilter2] = useState(2);
  const [testFilter3, setTestFilter3] = useState(2);
  const testList3 = useTestList({filter: testFilter3});
  const testList2 = useTestList({filter: testFilter2});
  const testStatus = useTestStatus();

  const path = useResolvedPath('./:id');
  const showChildren = useMatch({path: path.pathname, end: true});

  const onSnackbarAction2 = () => {
    setTestFilter('r');
    setTestFilter2(false);
    setTestFilter3(3);

    setTimeout(() => { logger.trace('RESET');setTestFilter(false);setTestFilter2(2);setTestFilter3(1); }, 10000);
  }

  return <StyledTestPage {...innerProps}>
    <Snackbar message="Main snackbar" open={true} action="Test" ButtonProps={{onClick: onSnackbarAction2}}/>
    {testStatus?.isLoading ? <p>loading all...<br/></p> : null}
    {testStatus?.isLoadingNoTimeout ? <p>loading all (not timeout)...<br/></p> : null}
    {testList.status.isLoading ? <div>loading...</div> :
      (testList.data || []).map((item) => <div key={item.id}>{item.id}</div>)}
    <NavLink to="." variant="body2" end preventScrollReset>Test Page tex (no reset)</NavLink>
    <NavLink to="../2" relative="path">--Test Page2 text relative to the current path--</NavLink>
    <NavLink to="./2">Test Page2 text</NavLink>
    {testList2.status.isLoading ? <div>loading2...</div> :
      (testList2.data || []).map((item) => <div key={item.id}>{item.id}</div>)}
    List3
    {testList3.status.isLoading ? <div>loading3...</div> :
      (testList3.data || []).map((item) => <div key={item.id}>{item.id}</div>)}

    { showChildren ?
      <Container style={{height: '400px'}}>
        <Outlet />
      </Container> : null }
  </StyledTestPage>
};

TestPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TestPage.defaultProps = {
};

export default TestPage;
