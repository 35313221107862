import React from 'react';
import StyledMainPage from './MainPage.styles';
import ThemeProvider, {useThemeControl} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import Button from 'components/atoms/Buttons/Button/Button';
import {Outlet, useLocation, useMatch, useResolvedPath} from 'react-router-dom';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import PropTypes from 'prop-types';
import Container from 'components/atoms/Layout/Container/Container';
import {useSetRecoilState} from 'recoil';
import logger from 'helpers/logger';
import {useServiceStore} from 'components/organisms/Providers/ServiceProvider/ServiceProvider';
import {useAuthTeamId} from 'services/auth/auth.utils';
import {useComponentProps} from 'helpers/hooks/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H1, H2, H3, P, Span} from 'components/atoms/Text/Typography/Typography';
import {useServiceInvalidate} from 'stores/hooks/service';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useSideMenuWrapper} from 'components/templates/Wrappers/Menus/SideMenuWrapper/SideMenuWrapper';
import system from 'helpers/system';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import AccessAlarm from '@mui/icons-material/AccessAlarm';
import SanitizerSharp from '@mui/icons-material/SanitizerSharp';
import {Logo} from 'assets/icons';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import Box from 'components/atoms/Layout/Box/Box';
import {useApi} from 'helpers/hooks/api';
import {themeFactory} from 'theme/base/base';
import themeLight from 'theme/light/theme';
import {css} from 'styled-components';
import {useLinkNavigate} from 'helpers/hooks/links';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Chip from 'components/atoms/Chips/Chip/Chip';
import LinkedIn from '@mui/icons-material/LinkedIn';
import {useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const customTheme = themeFactory(utils.mergeObjects(themeLight.overrides, {
  name: 'custom',
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterDelay: constants.delay.short
      },
      styleOverrides: {
        tooltip: (theme) => theme.convert2Css(css`
          color: ${theme.property('palette.secondary.contrastText')};
          background-color: ${theme.property('palette.rgb(255,0,0).main')};
          box-shadow: ${theme.colorShadow('background.default', 2)};

          .MuiTooltip-arrow {
            color: ${theme.property('palette.rgb(255,0,0).main')};

            &:before {
              box-shadow: ${theme.colorShadow('background.default', 1)};
            }
          }
        `)
      }
    }
  }
}, true));

const MainPage = (props) => {
  const {theme, ...innerProps} = useComponentProps(props, 'MainPage');
  const oppositeName = theme.name === 'light' ? 'dark' : 'light';
  
  const navigate = useLinkNavigate();
  const snackbar = useSnackbar();
  const location = useLocation();
  const themeControl = useThemeControl();

  const path = useResolvedPath('/*');
  const showChildren = useMatch({path: path.pathname, end: true});

  const gotoCollections = (e) => {
    logger.trace('COLLECTIONS PAGE', e);

    navigate({
      event: e,
      to: '/collections', preventScrollReset: true
    });
  }

  const sideMenuContext = useSideMenuWrapper();
  const toggleMenu = (e) => {
    logger.trace('CONTEXT', sideMenuContext, e);

    sideMenuContext.ref.current.toggle(
      !sideMenuContext.ref.current.state.open
    );
    if (!sideMenuContext.ref.current.state.open) {
      sideMenuContext.ref.current.expand(true);
    }
  }

  const gotoTestPage = (e) => {
    logger.trace('TEST PAGE', e);

    navigate({
      event: e,
      to: '/test', preventScrollReset: false
    });
  }

  const onSnackbarAction = (e) => {
    logger.trace('CLICK SNACK ACTION', e);
  }

  const showSnackbar = (e) => {
    logger.trace('CLICK SNACK', e);
    snackbar.show('My snackbar message', 'Hello', {autoHideDuration: constants.delay.medium}, onSnackbarAction);
  }

  // effects
  const authTeamId = useAuthTeamId();
  const user = useAuthUser();

  const onLogout = () => {
    navigate({
      to: '/auth/logout', resetState: true
    });
  };

  const onLogin = () => {
    navigate({to: '/auth/login', state: {from: location}});
  };

  const userService = useServiceStore('user');
  const setProcessData = useSetRecoilState(userService.atoms.processData(['test']));
  const onChangeItem = () => {
    // fail do refetch
    setProcessData({
      data: {meta: {time: new Date()}, data: {userId: 1, username: 'test'}},
      opts: {dataContext: {$store: {hash: utils.sha1({unique: null})}}}});
  };

  const invalidate = useServiceInvalidate('user');
  const onClearItem = () => {
    // fail do refetch
    invalidate(new Date(), [1, Math.floor(Math.random()*1000)], []);
  };

  const http = useApi();
  const doTest = () => {
    if (http) {
      logger.trace('TEST', Date.now());
      http(null).post('collections/311/users/1?collectionId=311').then((res) => {
        logger.trace('TEST 2', res, Date.now());
      })
    }
  };

  const dfRef = (el) => {
    logger.trace(el)
  }

  return <StyledMainPage {...innerProps}>
    <Icon icon={Logo} className="app-logo" alt="logo" style={{height: '300px'}}/>
    <H1>{system.appName()} - {system.appVersion()}</H1>
    <Typography variant="slogan">{system.appName()} - {system.appVersion()}</Typography>
    <H2>{system.getEnvironment()} - {system.getEnvironment()}</H2>
    <H3><Icon icon={SanitizerSharp}></Icon>user: {user?.username}({user?.userId}) {authTeamId}</H3>
    <H3><Icon icon={AccessAlarm}></Icon>user: {user?.username}({user?.userId}) {authTeamId}</H3>
    <DealflowIcon ref={dfRef} statusId={103} />
    <P>{theme.name + ' Theme Text'}</P>
    <Span>{theme.name + ' Theme Span'}</Span>
    <Markdown>http://ventureiq.nl **TEST**</Markdown>
    <IconButton color="success" variant="transparent"><Icon icon={LinkedIn}/></IconButton>
    <Chip color="success" variant="transparent" onClick={() => null} label={'Transparent'}/>
    <Button color="relevancyHighButton" variant="flat" onClick={onLogout}>{'Logout'}</Button>
    <Button color="relevancyNoneButton" variant="flat" onClick={doTest}>{'Test'}</Button>
    <Button schema="blue" onClick={onChangeItem}>{'Change item'}</Button>
    <Button schema="blue" onClick={onClearItem}>{'Clear item'}</Button>
    <Button schema="blue" onClick={onLogin}>{'Back to login'}</Button>
    <Button schema="blue" size="small" onClick={showSnackbar}>{theme.name + ' Snack Blue Button'}</Button>
    <Button variant="text" size="large" schema="dark" onClick={themeControl.toggle}>{theme.name + ' Dark Mat Button'}</Button>
    <Button variant="text">{'Test variant'}</Button>
    <Button variant="text" size="small">{'Test small variant'}</Button>
    <Button onClick={themeControl.toggle}>{theme.name + ' Custom Button'}</Button>
    <Button schema="blue" onClick={themeControl.toggle}>{theme.name + ' blue Custom Button'}</Button>
    <Button className="last" schema="light" variant="outlined" onClick={themeControl.toggle}>{theme.name + ' Big Light Button2'}</Button>
    <Button label="test label" data="jkenwfjnejf" className="first" onClick={themeControl.toggle}>{theme.name + ' Big Button'}</Button>
    <Button label="test page label" className="test-page first" onClick={gotoTestPage}></Button>
    <Button label="Collections" className="first" onClick={gotoCollections}></Button>
    <Button label="Toggle menu" className="first" onClick={toggleMenu}></Button>
    <ThemeProvider themes={[customTheme]}>
      <Box>
        <P>{customTheme.name + ' Theme Text'}</P>
      </Box>
      <DealflowIcon ref={dfRef} statusId={103} />
    </ThemeProvider>
    <ThemeProvider name={oppositeName}>
      <Box>
        <P>{oppositeName + ' Theme Text'}</P>
      </Box>
    </ThemeProvider>

    { showChildren ?
      <Container maxWidth="xl">
        <Outlet />
      </Container> : null }
  </StyledMainPage>
};

MainPage.propTypes = {
  toggleTheme: PropTypes.func,
  themes: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

MainPage.defaultProps = {
};

export default MainPage;
