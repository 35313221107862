import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextCard from 'components/molecules/Cards/ContextCard/ContextCard';

const StyledDealflowStatusContextCard = styled(ContextCard)` 
  .DealflowStatusContextCard {
    &-chip {
      width: 100%;
    }
  }
  ${ComponentStyles}
`;

StyledDealflowStatusContextCard.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusContextCard.defaultProps = {}

export default StyledDealflowStatusContextCard;
