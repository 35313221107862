import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledDealflowStatusTableCellEdit = styled(DropdownTableCellEdit)` 
  ${ComponentStyles}
`;

StyledDealflowStatusTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusTableCellEdit.defaultProps = {}

export default StyledDealflowStatusTableCellEdit;
