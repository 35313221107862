import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Typography from 'components/atoms/Text/Typography/Typography';
import FormControlLabel from 'components/atoms/Labels/FormControlLabel/FormControlLabel';
import Switch from 'components/atoms/Switches/Switch/Switch';
import StyledActionSwitch from 'components/molecules/Switches/ActionSwitch/ActionSwitch.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';

const ActionSwitch = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    label,
    showInactive,
    isLoading,
    TooltipProps,
    SwitchProps,
    FormControlLabelProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionSwitchProps}, 'ActionSwitch');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    const renderSwitch = () => {
      return <Switch checked={action.active}
                     disabled={!itm}
                     onClick={handleClick}
                     {...SwitchProps}
                     {...action.SwitchProps}/>
    }

    const render = () => {
      return <StyledActionSwitch ref={ref} {...innerProps}>
        {action.label ? <FormControlLabel control={renderSwitch()}
                                          label={<Typography variant="body2">{action.label}</Typography>}
                                          {...FormControlLabelProps}
                                          {...action.FormControlLabelProps}/> : renderSwitch()}
      </StyledActionSwitch>
    }

    return <Tooltip title={action.tooltip}
                    fullWidth={innerProps.fullWidth ?? action.SwitchProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      {isLoading ? <Skeleton>{render()}</Skeleton> : render()}
    </Tooltip>
  }
});

ActionSwitch.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  showInactive: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  TooltipProps: PropTypes.object,
  SwitchProps: PropTypes.object,
  FormControlLabelProps: PropTypes.object
};

ActionSwitch.defaultProps = {};

export default ActionSwitch;
