import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledTeamsSettingsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .TeamsSettingsCard-content {
    padding: 0;
    flex-grow: 1;
    overflow: hidden;

    .TeamMembersTable {
      width: 100%;
      height: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledTeamsSettingsCard.propTypes = {
  theme: PropTypes.object
};

StyledTeamsSettingsCard.defaultProps = {}

export default StyledTeamsSettingsCard;
