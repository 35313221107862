import React, {useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTagsForm from 'components/organisms/Forms/TagsForm/TagsForm.styles';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import TagForm from 'components/organisms/Forms/TagForm/TagForm';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {arrayMove} from '@dnd-kit/sortable';

const TagsForm = React.forwardRef((props, ref) => {
  const {
    tags,
    hasPoints,
    autoTagType,
    readOnly,
    disabled,
    error,
    onChange,
    onBlur,
    onErrors,
    ...innerProps
  } = useComponentProps(props, 'TagsForm');

  const tagRefs = useRef({});

  const [internalState, setInternalState] = useState({
    tags: []
  });

  useLayoutEffect(() => {
    let maxPos = 0, minId = 0;

    const tgs = (tags ?? [])
      .map((t, idx) => {
        maxPos = Math.max(maxPos, idx);
        if (!utils.isDefined(t.tagId)) {
          minId = minId - 1;
        } else if (+t.tagId < 0) {
          minId = Math.min(minId, +t.tagId);
        }

        return {
          ...t,
          pos: idx,
          tagId: !utils.isDefined(t.tagId) ? minId : +t.tagId
        }
      });

    if (!(readOnly || disabled) && tgs.length === 0) {
      tgs.push({tagId: minId - 1, pos: maxPos + 1});
    }

    setInternalState(utils.updater({
      tags: tgs.sort((a, b) => +a.pos - +b.pos)
    }, true));
  }, [tags, readOnly, disabled]);


  const handleAddTag = () => {
    setInternalState((current) => {
      const maxPos = current.tags.reduce((p, t) => Math.max(p, +t.pos), 0);
      const minId = current.tags.reduce((i, t) => t.tagId < 0 ? Math.min(i, +t.tagId) : i, 0);

      return {
        ...current,
        tags: current.tags.concat([{tagId: minId - 1, pos: maxPos + 1}])
      }
    });
  }

  const doErrors = () => {
    const errors = Object.keys(tagRefs.current).reduce((e, k) => e || tagRefs.current[k].errors, false);
    onErrors?.(errors);
    setInternalState(utils.updater({errors}, true));
  }

  const handleDragDrop = (id, containerId, position) => {
    let newValue = utils.clone(internalState.tags, true);
    const idx = internalState.tags.findIndex((t) => +t.tagId === +id);

    if (idx !== position) {
      newValue = arrayMove(internalState.tags, idx, position);
      newValue.forEach((t, idx) => {
        t.pos = idx;
      });

      onChange?.(newValue);
      onBlur?.();
    }
  }

  const handleChange = (tag) => (values) => {
    const idx = internalState.tags.findIndex((t) => +t.tagId === +tag.tagId);
    
    if (idx !== -1) {
      onChange?.([
        ...internalState.tags.slice(0, idx),
        {...tag, ...values},
        ...internalState.tags.slice(idx + 1),
      ].map((t) => ({
        ...t,
        tagId: t.tagId <= 0 ? null : t.tagId
      })));
    }
  }

  const handleDelete = (tag) => () => {
    delete tagRefs.current[tag.tagId];

    onChange?.(internalState.tags
      .filter((t) => +t.tagId !== +tag.tagId)
      .map((t) => ({
        ...t,
        tagId: t.tagId <= 0 ? null : t.tagId
      })));
    doErrors();
    onBlur?.();
  }

  const handleRef = (tag) => (ref) => {
    tagRefs.current[tag.tagId] = {
      ...tagRefs.current[tag.tagId],
      ref,
      errors: ref ? tagRefs.current[tag.tagId]?.errors : null,
    };
  }

  const handleErrors = (tag) => (errors) => {
    tagRefs.current[tag.tagId] = {
      ...tagRefs.current[tag.tagId],
      ref,
      errors
    };

    doErrors();
  }

  const renderTag = (tag, first) => {
    const hasError = internalState.tags.find((t) => t.tagId !== tag.tagId && t.value === tag.value);

    return <TagForm ref={handleRef(tag)}
                    key={tag.tagId}
                    className="TagsForm-tag"
                    tag={tag}
                    first={first}
                    error={Boolean(hasError)}
                    readOnly={readOnly}
                    disabled={disabled}
                    active={internalState.tags.length > 1}
                    hasPoints={hasPoints}
                    autoTagType={autoTagType}
                    onChange={handleChange(tag)}
                    onDelete={handleDelete(tag)}
                    onErrors={handleErrors(tag)}/>
  }

  return <StyledTagsForm ref={ref} {...innerProps} onBlur={onBlur}>
    <DndContext>
      <List sortable={!(readOnly || disabled)}
            dragHandle={true}
            gap={8} catchFocus={false}
            onDragDrop={handleDragDrop}>
        {internalState.tags?.map((tag, idx) => {
          return <ListItem key={tag.tagId} data-key={tag.tagId} density="densest">
            {renderTag(tag, idx === 0)}
          </ListItem>
        })}
      </List>
      {!(readOnly || disabled) ?
        <Button variant="text"
                disabled={internalState.errors}
                startIcon={<Icon icon={Add} />}
                onClick={handleAddTag}>
          Add tag
        </Button> : null}
    </DndContext>
  </StyledTagsForm>
});

TagsForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tags: PropTypes.array,
  hasPoints: PropTypes.bool,
  autoTagType: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onChange: PropTypes.func,
  onErrors: PropTypes.func
};

TagsForm.defaultProps = {};

export default TagsForm;
