import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDealflowEntityBrowserPage = styled(Page)`
  ${ComponentStyles}
`;

StyledDealflowEntityBrowserPage.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntityBrowserPage.defaultProps = {
};

export default StyledDealflowEntityBrowserPage;
