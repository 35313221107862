import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledColorField from 'components/molecules/Fields/ColorField/ColorField.styles';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Circle from '@mui/icons-material/Circle';
import {withMemo} from 'helpers/wrapper';

const ColorField = withMemo(React.forwardRef((props, ref) => {
  const {
    colorSet,
    groupSize,
    IconButtonProps,
    IconProps,
    ...innerProps
  } = useComponentProps(props, 'ColorField');

  const [options, columns, rows] = useMemo(() => {
    const colors = innerProps.theme.property(`colorSet.${colorSet}`);
    let options = colors ? Object.keys(colors).map((color) => ({
      value: colors[color],
      label: color
    })) : [];

    const rows = innerProps.rows ?? 2;
    const columns = innerProps.columns ?? Math.ceil(options.length / (groupSize * rows));
    options = utils.transpose(options
      .reduce((a, c, idx) => {
        a[Math.floor(idx / groupSize)] = a[Math.floor(idx / groupSize)] || [];
        a[Math.floor(idx / groupSize)][groupSize - (idx % groupSize) - 1] = c;
        return a;
      }, [])
      .flatMap((c) => c)
      .reduce((a, c, idx) => {
        a[(idx % groupSize) + Math.floor(idx / groupSize / columns) * groupSize] = a[(idx % groupSize) + Math.floor(idx / groupSize / columns) * groupSize] || [];
        a[(idx % groupSize) + Math.floor(idx / groupSize / columns) * groupSize][Math.floor(idx / groupSize) % columns] = c;
        return a;
      }, []))
      .flatMap((c) => c)
      .filter((_) => (_));

    return [options, columns, (groupSize * rows)];
  }, [colorSet, groupSize, innerProps.columns, innerProps.rows, innerProps.theme]);

  const renderOption = (option, props) => {
    const handleClick = () => {
      props.onChange?.({
        target: {
          name: props.name,
          checked: !props.checked
        }
      })
    };

    return <IconButton variant="outlined"
                       color={option.value}
                       selected={props.checked}
                       disabled={props.disabled}
                       onClick={handleClick}
                       {...IconButtonProps}>
      <Icon icon={Circle} {...IconProps} />
    </IconButton>
  };

  const renderReadOnlyOption = (option) => {
    return <IconButton variant="outlined"
                       color={option.value}
                       disabled={true}
                       {...IconButtonProps}>
      <Icon icon={Circle}
            color={option.value}
            {...IconProps} />
    </IconButton>
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledColorField ref={ref} {...innerProps}
                           options={options}
                           $columns={columns}
                           $rows={rows}
                           renderOption={renderOption}
                           renderReadOnlyOption={renderReadOnlyOption}
                           ListProps={{
                             orientation: 'horizontal'
                           }}/>
}));

ColorField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  colorSet: PropTypes.string,
  groupSize: PropTypes.number,
  ColorIconButtonProps: PropTypes.object,
  ColorIconProps: PropTypes.object
};

ColorField.defaultProps = {
  groupSize: 3
};

export default ColorField;
