import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledActionButtonGroup from 'components/molecules/Buttons/ActionButtonGroup/ActionButtonGroup.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';

const ActionButtonGroup = React.forwardRef((props, ref) => {
  const {
    actions,
    ActionButtonComponent,
    ActionButtonProps,
    ...innerProps
  } = useComponentProps(props, 'ActionButtonGroup');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledActionButtonGroup ref={ref} {...innerProps}>
    {actions?.map((action, idx) => <ActionButtonComponent key={idx}
                                                          action={action}
                                                          {...ActionButtonProps}/>)}
  </StyledActionButtonGroup>
});

ActionButtonGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  ActionButtonComponent: PropTypes.any,
  ActionButtonProps: PropTypes.object
};

ActionButtonGroup.defaultProps = {
  actions: [],
  ActionButtonComponent: ActionButton
};

export default ActionButtonGroup;
