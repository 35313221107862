import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTab from 'components/atoms/Tabs/Tab/Tab.styles';
import Component from 'components/organisms/Utils/Component/Component';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Tab = withMemo(React.forwardRef((props, ref) => {
  const {
    badge,
    label,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'Tab', {
    children: ['badge']
  });

  const renderLabel = () => {
    if (badge) {
      return <Component Original={badge}
                        className="Tab-badge"
                        variant="inline"
                        {...BadgeProps}>
        {label}
      </Component>;
    } else {
      return label;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTab ref={ref} {...innerProps} label={renderLabel()}/>
}));

Tab.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  badge: PropTypes.any,
  label: PropTypes.any,
  BadgeProps: PropTypes.object
};

Tab.defaultProps = {
  label: 'Tab text'
};

export default Tab;
