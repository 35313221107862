import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TaskProfileFoldout from 'components/organisms/Foldouts/TaskProfileFoldout/TaskProfileFoldout';

const StyledEntityTaskProfileFoldout = styled(TaskProfileFoldout)`
  ${ComponentStyles}
`;

StyledEntityTaskProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledEntityTaskProfileFoldout.defaultProps = {}

export default StyledEntityTaskProfileFoldout;
