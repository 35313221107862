import React from 'react';
import PropTypes from 'prop-types';
import {SortableContext as SortableContextBase, verticalListSortingStrategy} from '@dnd-kit/sortable';

const SortableContext = React.forwardRef((props, ref) => {
  return <SortableContextBase ref={ref} {...props} />
});

SortableContext.propTypes = {
  placeholder: PropTypes.any,
  timeout: PropTypes.number,
  strategy: PropTypes.any
};

SortableContext.defaultProps = {
  strategy: verticalListSortingStrategy
};

export default SortableContext;
