import React, {useMemo} from 'react'
import LoginPage from 'components/pages/Auth/LoginPage/LoginPage';
import SwitchTeamPage from 'components/pages/Auth/SwitchTeamPage/SwitchTeamPage';
import LogoutPage from 'components/pages/Auth/LogoutPage/LogoutPage';
import ForgotPage from 'components/pages/Auth/ForgotPage/ForgotPage';
import ResetPage from 'components/pages/Auth/ResetPage/ResetPage';
import BannerWrapper from 'components/templates/Wrappers/Banners/BannerWrapper/BannerWrapper';
import WelcomeBanner from 'components/organisms/Banners/WelcomeBanner/WelcomeBanner';
import utils from 'helpers/utils';

export default function useAuthRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: 'login',
        element: <LoginPage/>,
        handle: {
          auth: utils.createAuth({mustLogin: false})
        }
      },
      {
        path: 'forgot',
        element: <ForgotPage/>,
        handle: {
          auth: utils.createAuth({ mustLogin: false })
        }
      },
      {
        path: 'reset',
        children: [
          {
            path: ':hash',
            element: <ResetPage/>,
            handle: {
              auth: utils.createAuth({ mustLogin: false })
            },
          }
        ]
      },
      {
        path: 'logout',
        element: <LogoutPage />,
        handle: {
          auth: utils.createAuth({ mustLogin: false })
        }
      },
      {
        path: 'teams',
        element: <SwitchTeamPage />,
        handle: {
          auth: utils.createAuth({ attribute: 'team.switch' })
        },
        children: [{
          path: ':teamId',
          handle: {
            auth: utils.createAuth({ attribute: 'team.switch' })
          },
          element: <React.Fragment />,
        }]
      }
    ];

    return {
      WrapperComponent: ({children}) => <BannerWrapper banner={<WelcomeBanner />}>{children}</BannerWrapper>,
      route: route
    };
  }, []);
};
