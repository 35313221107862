import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledRelevancyBreakDownChart
  from 'components/organisms/Charts/RelevancyBreakDownChart/RelevancyBreakDownChart.styles';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import Chip from 'components/atoms/Chips/Chip/Chip';
import TeamMembersAvatarGroup from 'components/molecules/Avatars/TeamMembersAvatarGroup/TeamMembersAvatarGroup';
import {useAuthTeamId} from 'services/auth/auth.utils';
import {useClientTeamMembers} from 'services/client/team/team.utils';

const RelevancyBreakDownChart = React.forwardRef((props, ref) => {
  const {
    relevancies,
    ...innerProps
  } = useComponentProps(props, 'RelevancyBreakDownChart');

  const teamId = useAuthTeamId();
  const teamMembers = useClientTeamMembers(teamId, false);

  const notVoted = useMemo(() => {
    return (teamMembers ?? []).filter((tm) => {
      return !relevancies?.find((r) => +tm.userId === +r.userId)
    });
  }, [teamMembers, relevancies]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledRelevancyBreakDownChart ref={ref} {...innerProps}>
    {constants.data.hml.sort((a, b) => b.value - a.value).map((rDef, idx) => {
      const members = rDef.value > 0 ? (relevancies ?? [])
        .filter((r) => +r.relevancy === +rDef.value)
        .map((r) => teamMembers?.find((tm) => +tm.userId === +r.userId))
        .filter((_) => (_)) : notVoted;

      return <Box key={idx} className="RelevancyBreakDownChart-relevancy">
        <Chip variant="filled"
              size="medium"
              color={rDef.buttonColor}
              label={rDef.label}/>
        <TeamMembersAvatarGroup teamMembers={members}
                                size="small"
                                max={10}/>
      </Box>
    })}
  </StyledRelevancyBreakDownChart>
});

RelevancyBreakDownChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  relevancies: PropTypes.array
};

RelevancyBreakDownChart.defaultProps = {
};

export default RelevancyBreakDownChart;
