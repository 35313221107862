import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Email from 'components/pages/Base/Email/Email';

const StyledResetEmailPage = styled(Email)`
  &.ResetEmailPage {
    .Table-outer {
      .Table.Table-inner {
        .TableRow {
          &.ResetEmailPage {
            &-resetButtonRow {
              .TableCell {
                padding-top: ${props => props.theme.spacing(2)};
                padding-bottom: ${props => props.theme.spacing(2)};
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledResetEmailPage.propTypes = {
  theme: PropTypes.object
};

StyledResetEmailPage.defaultProps = {
};

export default StyledResetEmailPage;
