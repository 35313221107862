import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import StyledDatabaseEntitiesTable
  from 'components/organisms/Tables/DatabaseEntitiesTable/DatabaseEntitiesTable.styles';

const DatabaseEntitiesTable = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTable');

  const authorize = useAuthorize();

  const handleCanUpdate = useCallback((entity, column) => {
    return authorize({attribute: 'database.entity.update', meta: {entity}}) &&
      authorize(column.auth?.update ? {...column.auth?.update, meta: {
          ...column.auth?.update?.meta,
          entity
        }} : {})
  }, [authorize]);

  return <StyledDatabaseEntitiesTable ref={ref} {...innerProps}
                                      enableSorting={false}
                                      onCanUpdate={handleCanUpdate}/>
});

DatabaseEntitiesTable.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTable.defaultProps = {
};

export default DatabaseEntitiesTable;
