import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDealflowMonthlyBreakdownGraphCard
  from 'components/organisms/Cards/DealflowMonthlyBreakdownGraphCard/DealflowMonthlyBreakdownGraphCard.styles';
import DealflowMonthlyBarChart from 'components/organisms/Charts/DealflowMonthlyBarChart/DealflowMonthlyBarChart';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useClientStatisticDealflowMonthly} from 'services/client/statistic/statistic.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';

const DealflowMonthlyBreakdownGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowMonthlyBreakdownGraphCard');

  const tableProvider = useTable();
  const graphState = tableProvider.graphState;

  const [dealflow, setDealflow] = useState(null);

  const navigate = useLinkNavigate();

  const listState = tableProvider.appliedListState();
  const year = graphState.graphSettings['monthlyBreakdown']?.filter?.year;
  const months = graphState.graphSettings['monthlyBreakdown']?.filter?.months ?? 12;

  const [dealflowBreakdown, isLoading] = useClientStatisticDealflowMonthly(
    year,
    months,
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (dealflowBreakdown) {
      setDealflow(dealflowBreakdown);
    }
  }, [dealflowBreakdown]);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodMonths.find((opt) => opt.value === months);

    return [{
      name: 'months',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodMonths
    }];
  }, [months]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'monthlyBreakdown': {
        ...current?.['monthlyBreakdown'],
        filter: {
          ...current?.['monthlyBreakdown']?.filter,
          months: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'monthlyBreakdown': {
        ...current?.['monthlyBreakdown'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    const group = data.tooltipPayload?.[0]?.dataKey;
    let month = data.month;
    if (group && month) {
      month = new Date(month);
      navigate({
        event: e,
        to: `/dealflow/table?custom=dealflowMonthly:${group}_${month.getFullYear()}${(month.getMonth() + 1) < 10 ? '0' : ''}${month.getMonth() + 1}`,
        keepSearchParams: true
      });
    }
  };

  const total = (dealflow ?? []).reduce((t, d) => t + d?.count, 0);

  return <StyledDealflowMonthlyBreakdownGraphCard ref={ref} {...innerProps}
                                                  title="Monthly breakdown"
                                                  context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                 fields={fields} /> : null)}>
    {(total > 0 || isLoading) ?
      <DealflowMonthlyBarChart dealflow={dealflow}
                               showLegend={true}
                               isLoading={isLoading}
                               onClick={handleClick}
                               visibility={graphState.graphSettings['monthlyBreakdown']?.visibility}
                               onVisibilityChange={handleVisibilityChange} /> : null}

    {(total === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No dealflow data found</P>
      </Box> : null}
  </StyledDealflowMonthlyBreakdownGraphCard>
});

DealflowMonthlyBreakdownGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowMonthlyBreakdownGraphCard.defaultProps = {};

export default DealflowMonthlyBreakdownGraphCard;
