import system from 'helpers/system';

function trace () {
  if (!system.isProduction()) {
    if (system.appVerbosity() > 0) {
      console.trace(`${(new Date()).toISOString()}: `, ...arguments);
    } else {
      console.log(`${(new Date()).toISOString()}: `, ...arguments);
    }
  }
}

function debug () {
  trace(...arguments);
}

function error () {
  console.error(`${(new Date()).toISOString()}: `, ...arguments);
}

function warn () {
  console.warn(`${(new Date()).toISOString()}: `, ...arguments);
}

const logger = {
  trace,
  debug,
  error,
  warn
}

export default logger;
