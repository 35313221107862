import React from 'react';
import StyledBox from 'components/atoms/Layout/Box/Box.styles';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';

const Box = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Box');

  return <StyledBox ref={ref} {...innerProps}>{innerProps.children}</StyledBox>
}));

Box.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Box.defaultProps = {
  children: '',
};

export default Box;
