import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledSourcesList = styled(DataList)`
  ${ComponentStyles}
`;

StyledSourcesList.propTypes = {
  theme: PropTypes.object
};

StyledSourcesList.defaultProps = {}

export default StyledSourcesList;
