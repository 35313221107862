import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChipTableCell from 'components/molecules/TableCells/ChipTableCell/ChipTableCell';

const StyledDealflowStatusTableCell = styled(ChipTableCell)` 
  ${ComponentStyles}
`;

StyledDealflowStatusTableCell.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusTableCell.defaultProps = {}

export default StyledDealflowStatusTableCell;
