import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import {TaskAlt} from '@mui/icons-material';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';
import StyledCreditWalletPieChart from 'components/organisms/Charts/CreditWalletPieChart/CreditWalletPieChart.styles';

const CreditWalletPieChart = React.forwardRef((props, ref) => {
  const {
    total,
    used,
    showIcon,
    showTotal,
    showLegend,
    showActiveShape,
    extended,
    isLoading,
    variant,
    visibility,
    onClick,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'CreditWalletPieChart', {
    static: ['showActiveShape'],
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    const legend = [];
    if (utils.isDefined(total) && utils.isDefined(used)) {
      legend.push({
        id: 'left',
        position: 1,
        label: 'Credits left',
        color: innerProps.theme.property(`palette.primary.main`),
        active: visibility?.['left'] ?? true
      });

      legend.push({
        id: 'used',
        position: 2,
        label: 'Credits used',
        color: innerProps.theme.property('palette.creditsDefault.dark'),
        active: visibility?.['used'] ?? true
      });
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [total, used, visibility, innerProps.theme]);

  const creditData = useMemo(() => {
    if (utils.isDefined(total) && utils.isDefined(used)) {
      return {
        dataKey: 'value',
        nameKey: 'label',
        data: [{
            id: 'left',
            position: 1,
            label: 'Credits left',
            color: innerProps.theme.property(`palette.primary.main`),
            value: total > 0 ? (100 - Math.round((+used / total) * 100)) : 0,
            meta: {
              count: total > 0 ? (100 - Math.round((+used / total) * 100)) : 0,
              label: 'Credits left'
            }
          },
          {
            id: 'used',
            position: 2,
            label: 'Credits used',
            color: innerProps.theme.property('palette.creditsDefault.dark'),
            value: total > 0 ? Math.round((+used / total) * 100) : 0,
            meta: {
              count: total > 0 ? Math.round((+used / total) * 100) : 0,
              label: 'Credits used'
            }
          }]
          .filter((c) => {
            return Boolean(legend.find((l) => l.active && l.id === c.id))
          }),
        TooltipComponent: BreakdownTooltip
      };
    } else {
      return null;
    }
  }, [total, used, legend, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCreditWalletPieChart ref={ref} {...innerProps}>
    <Box className="CreditWalletPieChart-chart">
      <Box className="CreditWalletPieChart-chart-wrapper">
        {creditData ? <PieChart {...creditData}
                                hoveredId={internalState.hoveredId}
                                variant={variant}
                                isLoading={isLoading}
                                showTooltip={!showActiveShape}
                                showActiveShape={showActiveShape}
                                onClick={onClick}
                                TooltipProps={{
                                  variant: 'extended',
                                  density: 'dense',
                                  isPercentage: true
                                }}
                                {...PieChartProps} /> : null}

        <Box className="CreditWalletPieChart-center">
          {showIcon ? <Icon color="primary" icon={TaskAlt} /> : null}

          {showTotal ? <Typography className="totalCount"
                                   variant="h2"
                                   isLoading={isLoading}>
                        {total > 0 ? (100 - Math.round((+used / total) * 100)) : 0}%
                      </Typography> : null}
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="CreditWalletPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledCreditWalletPieChart>
});

CreditWalletPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  total: PropTypes.number,
  used: PropTypes.number,
  showIcon: PropTypes.bool,
  showTotal: PropTypes.bool,
  showLegend: PropTypes.bool,
  showActiveShape: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onClick: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

CreditWalletPieChart.defaultProps = {
  variant: 'full'
};

export default CreditWalletPieChart;
