import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDatabaseEntityBrowserBar
  from 'components/organisms/Bars/DatabaseEntityBrowserBar/DatabaseEntityBrowserBar.styles';

const DatabaseEntityBrowserBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityBrowserBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDatabaseEntityBrowserBar ref={ref} {...innerProps} />
});

DatabaseEntityBrowserBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityBrowserBar.defaultProps = {
};

export default DatabaseEntityBrowserBar;
