import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntityProfile
  from 'components/organisms/Profiles/DatabaseEntityProfile/DatabaseEntityProfile.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';


const DatabaseEntityProfile = (props) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityProfile');

  const authorize = useAuthorize();

  const handleCanUpdate = useCallback((entity, field = null) => {
    return authorize({attribute: 'database.entity.update', meta: {entity}}) &&
      (!field || authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, entity}}));
  }, [authorize]);

  const cards = useMemo(() => {
    return []
  }, []);

  return <StyledDatabaseEntityProfile {...innerProps}
                                      cards={cards}
                                      onCanUpdate={handleCanUpdate}/>
};

DatabaseEntityProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object
};

DatabaseEntityProfile.defaultProps = {
};

export default DatabaseEntityProfile;
