import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import {useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';

const Debounce = withMemo((props) => {
  const {
    timeout,
    placeholder,
    condition,
    children,
    onLoaded,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const onLoadedEvent = useEffectEvent(onLoaded);
  useEffect(() => {
    if (timeout && !loaded) {
      return utils.observeTimeout(() => {
        setLoaded(true);
      }, timeout);
    }
  }, [timeout, loaded]);

  useEffect(() => {
    if (condition && (!timeout || loaded)) {
      onLoadedEvent?.();
    }
  }, [timeout, loaded, condition, onLoadedEvent]);

  if (condition && (!timeout || loaded)) {
    return children;
  } else if (placeholder) {
    return placeholder;
  }
})

Debounce.propTypes = {
  placeholder: PropTypes.any,
  timeout: PropTypes.number,
  condition: PropTypes.bool
};

Debounce.defaultProps = {
  timeout: 0,
  condition: true,
  placeholderTimeout: constants.debounce.placeholder
};

export default Debounce;
