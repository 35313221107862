import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledTasksList = styled(DataList)`
  &.TasksList {
    .ListItem {
      &:focus {
        padding-left: 20px;
      }
    }
  }

  &.TasksList-orientation-horizontal {
    height: ${props => props.theme.layout(160)};
  }
  
  ${ComponentStyles}
`;

StyledTasksList.propTypes = {
  theme: PropTypes.object
};

StyledTasksList.defaultProps = {}

export default StyledTasksList;
