import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ButtonBase from '@mui/material/ButtonBase';
import constants from 'helpers/constants';

const StyledButtonBase = styled(ButtonBase)`
  ${props => props.theme.color2Css('ListItemButton', null, props.$color, {
    interactive: true,
    type: constants.colorTypes.accentBackground
  })};

  &.ButtonBase-contents {
    display: contents;
  }
  &.ButtonBase-fullWidth {
    width: 100% !important;
  }
  &.ButtonBase-fullHeight {
    height: 100% !important;
  }
  
  ${ComponentStyles}
`;

StyledButtonBase.propTypes = {
  theme: PropTypes.object
};

StyledButtonBase.defaultProps = {
}

export default StyledButtonBase;
