import {css} from 'styled-components';

/* Test styles are used for components with text, spans labels, buttons etc. */
const MenuStyles = css`
  & {
    .MenuItem {
      > .Icon {
        margin-left: ${props => props.theme.spacing(4)}
      }
    }
  } 
  
  // overrides
  ${props => props.theme.property('mixins.MenuStyles', '')}
  ${props => props.theme.convert2Css('components.MenuStyles.styleOverrides.root')}
`

export default MenuStyles;
