import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesTaskBreakdownGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;
  
  .CardContent {
    padding-top: 0;
    padding-bottom: 0;

    .CollectionEntitiesTaskBreakdownGraphCard {
      &-content {
        height: 100%;
        width: 100%;
        padding: ${props => props.theme.spacing(3)};
        background-color: ${props => props.theme.property('palette.primary.states.selected')};
        border-radius: ${props => props.theme.radius(1)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesTaskBreakdownGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTaskBreakdownGraphCard.defaultProps = {}

export default StyledCollectionEntitiesTaskBreakdownGraphCard;
