import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSwitch from 'components/atoms/Switches/Switch/Switch.styles';
import {withMemo} from 'helpers/wrapper';

const Switch = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Switch');

  return <StyledSwitch ref={ref} {...innerProps} />
}));

Switch.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Switch.defaultProps = {
  color: 'primary'
};

export default Switch;
