import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTable from 'components/organisms/Tables/EntitiesTable/EntitiesTable';

const StyledCollectionEntitiesTable = styled(EntitiesTable)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesTable.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTable.defaultProps = {
};

export default StyledCollectionEntitiesTable;
