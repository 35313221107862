import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDrawer from 'components/atoms/Drawers/Drawer/Drawer.styles';

const Drawer = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Drawer', {
    styled: ['color']
  });

  return <StyledDrawer ref={ref} {...innerProps} />
});

Drawer.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  color: PropTypes.string
};

Drawer.defaultProps = {
  children: 'Drawer text',
};

export default Drawer;
