import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFormControl from 'components/atoms/Controls/FormControl/FormControl.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const FormControl = withMemo(React.forwardRef((props, ref) => {
  const {
    readOnly,
    success,
    fullHeight,
    hiddenHelperText,
    hiddenPlaceholder,
    ...innerProps
  } = useComponentProps(props, 'FormControl', {
    static: ['focused', 'success', 'error', 'readOnly', 'disabled',
      'fullHeight', 'fullWidth', 'hiddenLabel', 'hiddenHelperText', 'hiddenPlaceholder']
  });

  const variant = ['staticLabel', 'inlineLabel', 'outlined'].includes(innerProps.variant) ?
    'outlined' : innerProps.variant;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledFormControl ref={ref} {...innerProps} variant={variant}>{innerProps.children}</StyledFormControl>
}));

FormControl.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'filled', 'outlined', 'staticLabel', 'inlineLabel']), PropTypes.string]),
};

FormControl.defaultProps = {
  children: 'FormControl text',
  variant: 'staticLabel'
};

export default FormControl;
