import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableBar from 'components/organisms/Bars/TableBar/TableBar';

const StyledCollectionsTableBar = styled(TableBar)`
  ${ComponentStyles}
`;

StyledCollectionsTableBar.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsTableBar.defaultProps = {
};

export default StyledCollectionsTableBar;
