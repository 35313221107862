import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionProfileWrapper
  from 'components/templates/Wrappers/Collections/CollectionProfileWrapper/CollectionProfileWrapper.styles';
import CollectionProfileFoldout from 'components/organisms/Foldouts/CollectionProfileFoldout/CollectionProfileFoldout';
import CollectionSectionPanel from 'components/organisms/SectionPanels/CollectionSectionPanel/CollectionSectionPanel';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const CollectionProfileWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionProfileWrapper');

  const foldoutRef = useRef(null);
  const profileProvider = useProfile();

  const [size, setSize] = useState('larger');

  const handleProfileClose = () => {
    profileProvider.closeProfile();
  }

  const handleClose = (e, reason) => {
    foldoutRef.current?.close?.(e, reason);
  }

  const handleToggle = (e, open, reason) => {
    if (!open) {
      foldoutRef.current?.close?.(e, reason);
    }
  }

  const handleChange = (section) => {
    setSize(section.size ?? 'larger');
  }

  const renderContext = () => {
    return <CollectionProfileFoldout ref={foldoutRef}
                                     onClose={handleProfileClose}>
      <CollectionSectionPanel onClose={handleClose}
                              onChange={handleChange}/>
    </CollectionProfileFoldout>
  }

  return <StyledCollectionProfileWrapper ref={ref} {...innerProps}
                                         variant="foldout"
                                         context={renderContext()}
                                         open={Boolean(profileProvider.state?.showProfile)}
                                         size={size}
                                         onToggle={handleToggle}>
    {innerProps.children}
  </StyledCollectionProfileWrapper>
});

CollectionProfileWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionProfileWrapper.defaultProps = {
  children: 'CollectionProfileWrapper text',
};

export default CollectionProfileWrapper;
