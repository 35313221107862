import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Toolbar from '@mui/material/Toolbar';

const StyledToolbar = styled(Toolbar)`
  ${ComponentStyles}
`;

StyledToolbar.propTypes = {
  theme: PropTypes.object
};

StyledToolbar.defaultProps = {
}

export default StyledToolbar;
