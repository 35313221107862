import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useEntityPatentCpcStatus () {
  return useServiceStatus(path);
}

export function useEntityPatentCpcSearch ({search, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {query: search, ...rest}, {
    overridePath: 'entities/patents/cpcs/search',
    ...queryOptions
  });
}
