import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledHeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar.styles';
import {H6} from 'components/atoms/Text/Typography/Typography';

const HeaderBar = React.forwardRef((props, ref) => {
  const {
    title,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'HeaderBar');

  const renderTitle = () => {
    return utils.isReactElement(title) ? title :
        <H6 isLoading={isLoading} min={8} max={20}>{title}</H6>;
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledHeaderBar ref={ref} {...innerProps}
                          title={title ? renderTitle() : null}
                          showViewBar={false}
                          isLoading={isLoading}
                          ActionIconButtonProps={{
                            size: 'smaller',
                            density: 'sparse',
                            variant: 'outlined',
                            IconProps: {
                              size: 'smaller'
                            }
                          }} />
});

HeaderBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'inset']), PropTypes.string])
};

HeaderBar.defaultProps = {
  variant: 'standard',
  color: 'primary'
};

export default HeaderBar;
