import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Bar} from 'recharts';

const StyledActionBar = styled(Bar)`
  &.ActionBar-clickable {
    cursor: pointer;
  }
  
  ${ComponentStyles}
`;

StyledActionBar.propTypes = {
  theme: PropTypes.object
};

StyledActionBar.defaultProps = {
}

export default StyledActionBar;
