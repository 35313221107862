import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledMobileStepper from 'components/atoms/Steppers/MobileStepper/MobileStepper.styles';
import utils from 'helpers/utils';

const MobileStepper = React.forwardRef((props, ref) => {
  const {
    color,
    maxSteps,
    ...innerProps
  } = useComponentProps(props, 'MobileStepper', {
    styled: ['color']
  });

  if (maxSteps) {
    innerProps.steps = Math.min(innerProps.steps, maxSteps);
    innerProps.activeStep = utils.isDefined(innerProps.activeStep) ? (innerProps.activeStep % maxSteps) : innerProps.activeStep;
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledMobileStepper ref={ref} {...innerProps} />
});

MobileStepper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  color: PropTypes.string,
  maxSteps: PropTypes.number
};

MobileStepper.defaultProps = {
  color: 'primary'
};

export default MobileStepper;
