import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Table from 'components/organisms/Tables/Table/Table';

const StyledEntitiesTable = styled(Table)`
  ${ComponentStyles}
`;

StyledEntitiesTable.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesTable.defaultProps = {
};

export default StyledEntitiesTable;
