import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useCollectionUserStatus () {
  return useServiceStatus(path);
}

export function useCollectionUserFavoriteAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/users',
    ...mutationOptions
  });
}

export function useCollectionUserFavoriteDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'collections/:collectionId/users',
    ...mutationOptions
  });
}
