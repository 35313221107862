import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledContentNavigation from 'components/molecules/Navigations/ContentNavigation/ContentNavigation.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {Span} from 'components/atoms/Text/Typography/Typography';

const ContentNavigation = React.forwardRef((props, ref) => {
  const {
    title,
    actions,
    ActionButtonProps,
    ...innerProps
  }= useComponentProps(props, 'ContentNavigation', {
    children: ['title', 'button']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledContentNavigation ref={ref} {...innerProps}>
    {title ? <Span className="ContentNavigation-title">{title}</Span> : null}
    {actions?.map((action, idx) => {
      return <ActionButton key={idx}
                           className="ContentNavigation-button"
                           action={action}
                           size="small"
                           variant="text"
                           {...ActionButtonProps}/>
    })}
  </StyledContentNavigation>
});

ContentNavigation.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  actions: PropTypes.array,
  ActionButtonProps: PropTypes.object
};

ContentNavigation.defaultProps = {
  title: 'ContentNavigation label'
};

export default ContentNavigation;
