import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import StyledSectionPanelFooter from 'components/molecules/SectionPanels/SectionPanelFooter/SectionPanelFooter.styles';

const SectionPanelFooter = React.forwardRef((props, ref) => {
  const {
    info,
    buttons,
    orientation,
    ...innerProps
  } = useComponentProps(props, 'SectionPanelFooter', {
    variable: ['orientation']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSectionPanelFooter ref={ref} {...innerProps}>
    <Box className="SectionPanelFooter-info">
      {info}
    </Box>
    {buttons}
  </StyledSectionPanelFooter>
});

SectionPanelFooter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  info: PropTypes.any,
  buttons: PropTypes.any,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'split']), PropTypes.string])
};

SectionPanelFooter.defaultProps = {
  orientation: 'horizontal'
};

export default SectionPanelFooter;
