import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesSuggestionsBar
  from 'components/organisms/Bars/EntitiesSuggestionsBar/EntitiesSuggestionsBar.styles';

const EntitiesSuggestionsBar = React.forwardRef((props, ref) => {
  const {
    canUpdate,
    ...innerProps
  } = useComponentProps(props, 'EntitiesSuggestionsBar');

  const tableProvider = useTable();

  const switched = Boolean(tableProvider?.state?.settings?.suggestionsSwitched);

  const setSettingsEvent = useEffectEvent(tableProvider.setSettings);
  const switchActions = useMemo(() => [{
    label: 'Advanced mode',
    active: switched,
    auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      setSettingsEvent?.({suggestionsSwitched: !switched});
      e.preventDefault();
    },
    ActionSwitchProps: {
      SwitchProps: {
        color: 'success'
      }
    }
  }], [switched, setSettingsEvent, canUpdate]);

  const entitiesCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${entitiesCount} companies`;
  innerProps.rightSwitchActions = innerProps.rightSwitchActions ?? switchActions;
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesSuggestionsBar ref={ref} {...innerProps} isLoading={tableProvider.isLoading()}/>
});

EntitiesSuggestionsBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  canUpdate: PropTypes.bool
};

EntitiesSuggestionsBar.defaultProps = {
  canUpdate: true
};

export default EntitiesSuggestionsBar;
