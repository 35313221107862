import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCardHeader from 'components/atoms/Cards/CardHeader/CardHeader.styles';
import Component from 'components/organisms/Utils/Component/Component';

const CardHeader = React.forwardRef((props, ref) => {
  const {
    badge,
    ...innerProps
  } = useComponentProps(props, 'CardHeader', {
    children: ['badge']
  });

  const renderTitle = () => {
    if (badge) {
      return <Component Original={badge}
                        className="CardHeader-badge"
                        variant="inline">{innerProps.title}</Component>;
    } else {
      return innerProps.title;
    }
  }

  return <StyledCardHeader ref={ref} {...innerProps} title={renderTitle()}/>
});

CardHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

CardHeader.defaultProps = {};

export default CardHeader;
