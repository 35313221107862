import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTaskProgressStats} from 'services/comment/comment.utils';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import Typography from 'components/atoms/Text/Typography/Typography';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import StyledTaskProgressProfileCard
  from 'components/organisms/Cards/TaskProgressProfileCard/TaskProgressProfileCard.styles';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import Box from 'components/atoms/Layout/Box/Box';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import {useAuthTeamId} from 'services/auth/auth.utils';

const TaskProgressProfileCard = React.forwardRef((props, ref) => {
  const {
    task,
    renderedHeader,
    isDialog,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'TaskProgressProfileCard', {
    static: ['isDialog'],
    children: ['header', 'content', 'list']
  });

  const teamId = useAuthTeamId();
  const teamMemberOptions = useClientTeamMemberOptions(teamId, true, false);

  const progressStats = useTaskProgressStats(task);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTaskProgressProfileCard ref={ref} {...innerProps}>
    {renderedHeader}
    <CardContent className="ProfileCard-content TaskProgressProfileCard-content">
      <List className="TaskProgressProfileCard-list" gap={12}>
        {progressStats?.map((r) => {
          const member = r.userId > 0 ? teamMemberOptions?.find((m) => +m.userId === +r.userId) : r;
          if (member) {
            const avatar = `${utils.personName(member.profile.firstName, member.profile.lastName)} (${member.profile.username})`;
            const name = utils.personName(member.profile.firstName, member.profile.lastName);
            const color = utils.string2Color(avatar);

            const score = Math.round(r.doneCount / (task.on?.ids?.length ?? 1) * 100);
            return <ListItem key={r.userId}>
              <Box className="TaskProgressProfileCard-label">
                <ActionAvatar action={{
                                label: utils.avatarLabel(avatar),
                                tooltip: avatar
                              }}
                              size="small"
                              isLoading={isLoading}
                              color={color}
                              TooltipProps={{enterDelay: 0}}/>
                <Typography variant="body2"
                            min={8} max={20}
                            isLoading={isLoading}>{name}</Typography>
              </Box>
              <ScoreLinearProgress className="TaskProgressProfileCard-progress"
                                   size="small"
                                   showEmpty={true}
                                   score={score} />
            </ListItem>
          } else {
            return null;
          }
        }).filter((_) => (_))}
      </List>
    </CardContent>
  </StyledTaskProgressProfileCard>
});

TaskProgressProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  task: PropTypes.object,
  renderedHeader: PropTypes.any,
  isLoading: PropTypes.bool
};

TaskProgressProfileCard.defaultProps = {
};

export default TaskProgressProfileCard;
