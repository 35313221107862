import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledAnalysisScoreInline = styled(Box)`
  width: 100%;
  height: 100%;
  
  .AnalysisScoreInline {
    &-content {
      width: 100%;
      height: 100%;

      .recharts-label-list {
        .recharts-text {
          fill: ${props => props.theme.property('palette.text.primary')};
          ${props => props.theme.font2Css('caption')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledAnalysisScoreInline.propTypes = {
  theme: PropTypes.object
};

StyledAnalysisScoreInline.defaultProps = {}

export default StyledAnalysisScoreInline;

