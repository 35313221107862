import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntitiesUploadWizardContent = styled(Box)`
  &.EntitiesUploadWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('1sp')};
    
    .Form-fields {
      flex-direction: column;
      row-gap: ${props => props.theme.layout('2sp')};
      
      .FormField {
        row-gap: ${props => props.theme.layout('1sp')};

        .FormLabel, .InputLabel {
          ${props => props.theme.font2Css('subtitle1')};
          transform: scale(1);
        }
      }

      .FormField-name-name, .FormField-name-comment {
        max-width: ${props => props.theme.layout(560)};
      }
      

      .FileField {
        &-dropzone {
          padding-bottom: ${props => props.theme.layout('1sp')};
        }
        &-file {
          .info > p {
            width: unset;
          }
        }
      }
    }
  }
  
  .EntitiesUploadWizardContent {
    &-subtitle {
      .H6 {
        color: ${props => props.theme.property('palette.text.secondary')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesUploadWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesUploadWizardContent.defaultProps = {}

export default StyledEntitiesUploadWizardContent;
