import {useCallback} from 'react';
import {useEntityCsiUpdate} from 'services/entity/csi/csi.hooks';
import utils from 'helpers/utils';
import {useAuthTeamId} from 'services/auth/auth.utils';

export function useEntityCsiPatch () {
  const entityCsiUpdate = useEntityCsiUpdate();
  const teamId = useAuthTeamId();

  return useCallback((entity, changes) => {
    if (!utils.isEmpty(changes)) {
      return entityCsiUpdate.mutation.mutateAsync({
        entityId: entity?.entityId,
        csiId: teamId,
        csi: utils.underscore(changes)
      });
    } else {
      return Promise.resolve();
    }
  }, [entityCsiUpdate.mutation, teamId]);
}
