import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSwitchClientForm = styled(Box)`
  display: flex;
  flex-direction: column;

  .SwitchClientForm {
    &-clients {
      display: flex;
      flex-direction: column;
      row-gap: ${props => props.theme.layout('2sp')};
      padding: 0;
      
      .ListItem {
        padding: 0;
      }
      
      .ListItemButton {
        height: ${props => props.theme.layout(68)};
        background-color: ${props => props.theme.property('palette.background.default')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSwitchClientForm.propTypes = {
  theme: PropTypes.object
};

StyledSwitchClientForm.defaultProps = {}

export default StyledSwitchClientForm;
