import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';

const StyledQueryBar = styled(HeaderBar)`
  height: unset;
  max-height: 100vh;
  background-color: ${props => props.theme.property('palette.primary.states.selected')};
  border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
  row-gap: ${props => props.theme.layout('2sp')};
  padding-bottom: ${props => props.theme.spacing(1.5)};
  
  .PageBar-toolbar {
    display: none;
  }
  
  .QueryBar {
    &-content {
      display: flex;
    }
    
    &-query {
      display: flex;
      flex-grow: 1;
      max-height: 100%;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: ${props => props.theme.layout('2sp')};
      margin-left: ${props => props.theme.layout(184)};
      padding: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} 0 ${props => props.theme.spacing(3)};
    }
    
    &-toggle {
      display: flex;
      justify-content: flex-end;
      width: ${props => props.theme.layout(184)};
      padding-top: ${props => props.theme.spacing(1.5)};
      padding-right: ${props => props.theme.spacing(3)};

      .ToggleButtonGroup {
        align-self: flex-start;
        background-color: ${props => props.theme.property('palette.background.paper')};
      }
    }
    
    &-simple {
      max-width: ${props => props.theme.layout(873)};
      width: 100%;
    }

    &-explanation {
      align-self: center;
      color: ${props => props.theme.property('palette.text.disabled')};
    }
  }

  &.QueryBar-advanced {
    row-gap: ${props => props.theme.layout('1.5sp')};
    .QueryBar {
      &-query {
        align-items: flex-start;
        padding-top: ${props => props.theme.spacing(2)};
        padding-left: ${props => props.theme.spacing(3)};
        margin-left: 0;
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .QueryBar {
      &-query {
        margin-left: 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledQueryBar.propTypes = {
  theme: PropTypes.object
};

StyledQueryBar.defaultProps = {}

export default StyledQueryBar;
