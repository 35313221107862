import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledTaskProfileFoldout from 'components/organisms/Foldouts/TaskProfileFoldout/TaskProfileFoldout.styles';
import Delete from '@mui/icons-material/Delete';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const TaskProfileFoldout = React.forwardRef((props, ref) => {
  const {
    actions,
    ...innerProps
  } = useComponentProps(props, 'TaskProfileFoldout');

  const profileProvider = useProfile();
  const task = profileProvider?.data?.data;

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const deleteEvent = useEffectEvent(profileProvider.updaters?.deleteData);
  const onCloseEvent = useEffectEvent(innerProps.onClose);
  const actionsMemo = useMemo(() => {
    return actions ?? [{
      auth: utils.createAuth({attribute: 'task.delete', meta: {task}}),
      label: 'Remove task',
      tooltip: 'Remove task',
      icon: Delete,
      ActionIconButtonProps: {
        color: 'error',
        variant: 'contained'
      },
      onClick: (e) => {
        const handleConfirm = () => {
          return deleteEvent?.({commentId: task.commentId})
            .catch(() => {
              snackbar.show('Removing task failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            })
            .then(() => {
              onCloseEvent?.(e);
            });
        }

        dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this task?"
                                          explanation="The task will be removed permanently"
                                          onConfirm={handleConfirm}
                                          ConfirmButtonProps={{
                                            children: 'Remove task',
                                            color: 'error'
                                          }}/>, true);

      }
    }];
  }, [task, actions, snackbar, dialogControl, onCloseEvent, deleteEvent]);

  innerProps.title = innerProps.title ?? 'Task details';

  return <StyledTaskProfileFoldout ref={ref} {...innerProps}
                                   actions={actionsMemo}
                                   isLoading={profileProvider.isLoading()}>
    {innerProps.children}
  </StyledTaskProfileFoldout>
});

TaskProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TaskProfileFoldout.defaultProps = {};

export default TaskProfileFoldout;
