import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledChipTableCell = styled(BaseTableCell)`
  ${ComponentStyles}
`;

StyledChipTableCell.propTypes = {
  theme: PropTypes.object
};

StyledChipTableCell.defaultProps = {
}

export default StyledChipTableCell;
