import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesTaskAddDialog from 'components/organisms/Dialogs/EntitiesTaskAddDialog/EntitiesTaskAddDialog.styles';
import constants from 'helpers/constants';

const EntitiesTaskAddDialog = React.forwardRef((props, ref) => {
  const {
    count,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesTaskAddDialog');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const taskTypesMemo = useMemo(() => {
    return constants.data.taskTypes.filter((tt) => {
      return !tt.system;
    });
  }, []);

  const handleSubmit = (task) => {
    return onSubmit?.(task);
  };

  return <StyledEntitiesTaskAddDialog ref={innerRef} {...innerProps}
                                      subtitle={`For ${count} compan${count > 1 ? 'ies' : 'y'}`}
                                      taskTypes={taskTypesMemo}
                                      onSubmit={handleSubmit}/>
});

EntitiesTaskAddDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entities: PropTypes.array,
  onSubmit: PropTypes.func
};

EntitiesTaskAddDialog.defaultProps = {};

export default EntitiesTaskAddDialog;
