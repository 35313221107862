import React, {useMemo} from 'react'
import TestPage2 from 'components/pages/Test/TestPage2/TestPage2';
import TestPage from 'components/pages/Test/TestPage/TestPage';
import MainPage from 'components/pages/Test/MainPage/MainPage';

export default function useTestRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <TestPage />,
        children: [
          {
            path: ':id',
            element: <TestPage2 />,
          }
        ]
      },
      {
        // it renders this element
        element: <MainPage />,

        // when the URL matches this segment
        path: 'mainPage',

        // with this data loaded before rendering
        loader: null,

        // performing this mutation when data is submitted to it
        action: null,

        // and renders this element in case something went wrong
        errorElement: null,

        // custom options like scrollKey,
        // pathname means going to this page will scroll to the last position always
        // default: keep scroll for every instance in history, clicking will go to top
        handle: {
          //scrollKey: 'pathname'
          title: 'MainPage'
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, []);
};
