import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Divider from 'components/atoms/Dividers/Divider/Divider';

const StyledTabDivider = styled(Divider)`
  ${ComponentStyles}
`;

StyledTabDivider.propTypes = {
  theme: PropTypes.object
};

StyledTabDivider.defaultProps = {
}

export default StyledTabDivider;
