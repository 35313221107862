import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledChipList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  
  padding: 0;
  margin: 0;
  gap: ${props => props.theme.layout(2)};

  > li {
    margin: 0;
    overflow: hidden;
    max-width: 100%;

    > * {
      max-width: 100% !important;
    }
  }
  
  &.ChipList-tooltip {
    font-size: ${props => props.theme.fontSize(16)};
    padding: ${props => props.theme.spacing(4/8)} 0;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    
    > li {
      width: 100%;
      display: flex;
      justify-content: center;
      
      > * {
        max-width: 100% !important;
      }
    }
  }
  
  &.ChipList-compact {
    overflow: hidden;
    flex-wrap: wrap;
    align-items: flex-start;

    &.ChipList-compact-nowrap {
      align-items: center;
      flex-wrap: nowrap;
    }

    > li {
      overflow: unset;
      display: inline-flex;
      align-items: flex-start;
    }
  }
  
  &.ChipList-density-sparse {
    gap: ${props => props.theme.layout(4)};
  }
  &.ChipList-density-sparser {
    gap: ${props => props.theme.layout(8)};
  }

  ${ComponentStyles}
`;

StyledChipList.propTypes = {
  theme: PropTypes.object
};

StyledChipList.defaultProps = {
}

export default StyledChipList;
