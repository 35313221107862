import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import AppBar from 'components/atoms/Toolbars/AppBar/AppBar';

const StyledPageBar = styled(AppBar)`
  color: ${props => props.theme.property('palette.text.primary')};
  border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
  
  .PageBar {
    &-toolbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: unset;
      height: ${props => props.theme.layout(67)};
      column-gap: ${props => props.theme.layout('1sp')};
      padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(3)};
    }
    &-navigation {
      padding-right: ${props => props.theme.spacing(1)};

      &:not(:has(*)) {
        margin-right: -${props => props.theme.spacing(1)};
      }
    }
    &-title {
      flex-grow: 1;
      width: 1px; 
      > h4 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    
    &-center {
      display: flex;
      min-width: fit-content;
      align-items: center;
      justify-content: center;
      
      &:has(*) {
        flex: 1 0 5%;
      }
      
      &-wrapper {
        overflow: hidden;
        white-space: nowrap;
        left: 0;
        
        ${props => !props.$dialog ? css`
          width: calc(100vw - ${props.$wrapper?.boundsCum?.left ?? '0px'} - ${props.$wrapper?.boundsCum?.right ?? '0px'});
        ` : css`
          width: 100%;
        `}
        
        position: absolute;
        display: flex;
        justify-content: center;
        pointer-events: none;
        
        > * {
          pointer-events: all;
        }
      }
    }
    
    &-actions {
      display: flex;
      column-gap: ${props => props.theme.layout('1sp')};
      white-space: nowrap;
      align-items: center;

      &:not(:has(*)) {
        margin-left: -${props => props.theme.spacing(1)};
      }
    }
  }
  ${ComponentStyles}
`;

StyledPageBar.propTypes = {
};

StyledPageBar.defaultProps = {
}

export default StyledPageBar;
