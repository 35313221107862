import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledCollectionEntityBrowserBarWrapper = styled(ToolbarWrapper)`
  ${ComponentStyles}
`;

StyledCollectionEntityBrowserBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityBrowserBarWrapper.defaultProps = {
};

export default StyledCollectionEntityBrowserBarWrapper;
