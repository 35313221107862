import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledImageListItem from 'components/atoms/Lists/ImageListItem/ImageListItem.styles';
import {withMemo} from 'helpers/wrapper';

const ImageListItem = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ImageListItem');

  return <StyledImageListItem ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledImageListItem>
}));

ImageListItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ImageListItem.defaultProps = {
  children: 'CardItem text'
};

export default ImageListItem;
