import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledDealflowRundownBarChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .DealflowRundownBarChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;
      
      .recharts-xAxis {
        .recharts-cartesian-axis-tick-value {
          display: none;
        }
      }
    }

    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowRundownBarChart.propTypes = {
};

StyledDealflowRundownBarChart.defaultProps = {
}

export default StyledDealflowRundownBarChart;
