import React, {useMemo} from 'react'
import SettingsBarWrapper from 'components/templates/Wrappers/Settings/SettingsBarWrapper/SettingsBarWrapper';
import utils from 'helpers/utils';
import SettingsWrapper from 'components/templates/Wrappers/Settings/SettingsWrapper/SettingsWrapper';
import {Outlet} from 'react-router-dom';
import useSettingRoutes from 'routes/settings/setting/setting.routes';
import ClientProfileProvider from 'components/organisms/Providers/ProfileProvider/ClientProfileProvider';

export default function useSettingsRoutes () {
  const settingsRoutes = useSettingRoutes();
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <settingsRoutes.WrapperComponent><Outlet /></settingsRoutes.WrapperComponent>,
        children: settingsRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'settings.route'
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <ClientProfileProvider>
          <SettingsBarWrapper>
            <SettingsWrapper>
              {children}
            </SettingsWrapper>
          </SettingsBarWrapper>
        </ClientProfileProvider>
      },
      route: route
    };
  }, [settingsRoutes]);
};
