import React, {useLayoutEffect, useMemo} from 'react'
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import {
  useCollectionEntityAdd, useCollectionEntityAssign, useCollectionEntityDelete,
  useCollectionEntityGet,
  useCollectionEntityList, useCollectionEntityUnAssign
} from 'services/collection/entity/entity.hooks';
import {useParams} from 'react-router-dom';
import utils from 'helpers/utils';
import {useCollectionGet} from 'services/collection/collection.hooks';
import EntitiesTableProvider from 'components/organisms/Providers/TableProvider/EntitiesTableProvider';
import {useEffectEvent, useProviderDataState, useProviderView} from 'helpers/hooks/utils';
import {
  useCollectionEntityColumns, useCollectionEntityFilterGroups, useCollectionEntityGraphs,
  useCollectionEntityPatch, useCollectionEntityCallbacks, useCollectionEntitySelected
} from 'services/collection/entity/entity.utils';
import {useEntityCsiPatch} from 'services/entity/csi/csi.utils';
import {useExportAdd} from 'services/export/export.hooks';
import {useEntityTaskCreate} from 'services/entity/comment/comment.utils';

const CollectionEntitiesTableLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState();

  const list = useCollectionEntityList({
    collectionId: tableProvider.context?.data?.collectionId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enablePreload: true,
    enabled: (active && listState.active) && utils.isDefined(tableProvider.context?.data?.collectionId)
  });

  const setListEvent = useEffectEvent(tableProvider.setList);
  useLayoutEffect(() => {
    setListEvent?.(list);
  }, [setListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const CollectionEntitiesTableTestLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState('test');

  const list = useCollectionEntityList({
    collectionId: tableProvider.context?.data?.collectionId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.runOnce,
    enabled: (active && listState.active) && +tableProvider.context?.data?.collectionId > 0
  });

  const setTestListEvent = useEffectEvent(tableProvider.setTestList);
  useLayoutEffect(() => {
    setTestListEvent?.(list);
  }, [setTestListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const CollectionEntitiesTableProvider = (props) => {
  const params = useParams();
  const { collectionId, entityId } = {...params, ...props};

  const view = useProviderView('collection.entity', props.skipView);

  const collection = useCollectionGet({collectionId}, {enabled: collectionId > 0});
  const entity = useCollectionEntityGet({collectionId, entityId},
    {enabled: (+collectionId > 0 && +entityId > 0)});

  const [context, onContext] = useProviderDataState(collection);
  const [data, onData] = useProviderDataState(entity);

  const columns = useCollectionEntityColumns(view, collection?.data);
  const filterGroups = useCollectionEntityFilterGroups(view, collection?.data);
  const graphs = useCollectionEntityGraphs(view);

  const isLoading = props?.isLoading || collection.status?.isLoading || entity.status?.isLoading;

  const entityProfileCallbacks = useCollectionEntityCallbacks(collection.data);

  const columnDefinitions = useMemo(() => {
    return columns?.length > 0 ? columns : null;
  }, [columns]);

  const filterGroupDefinitions = useMemo(() => {
    return filterGroups?.length > 0 ? filterGroups : null;
  }, [filterGroups]);

  const graphDefinitions = useMemo(() => {
    return graphs?.length > 0 ? graphs : null;
  }, [graphs]);

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        type: constants.appState.type.local,
        name: `collectionEntities_${collectionId}`,
        searchParams: true
      }
    },
    testListState: {
      initial: {
        active: false,
        pageSize: 0
      },
      options: {
        name: `collectionEntities_${collectionId}_test`
      }
    },
    listSelection: {
      options: {
        max: constants.selection.max.collection
      }
    },
    columnState: {
      initial: {},
      options: {
        name: `collectionEntities_${collectionId}`,
        type: constants.appState.type.local
      }
    },
    kanbanState: {
      initial: {},
      options: {
        name: `collectionEntities_${collectionId}`,
        type: constants.appState.type.local
      }
    },
    graphState: {
      initial: {},
      options: {
        name: `collectionEntities_${collectionId}`,
        type: constants.appState.type.local
      }
    },
    columnDefinitions,
    filterGroupDefinitions,
    graphDefinitions
  }), [columnDefinitions, filterGroupDefinitions, graphDefinitions, collectionId]);

  const fieldData = useMemo(() => {
    return {
      tagGroups: collection.data?.tagGroups,
      callbacks: entityProfileCallbacks
    };
  }, [collection.data?.tagGroups, entityProfileCallbacks]);

  const update = useCollectionEntityPatch();
  const create = useCollectionEntityAdd();
  const deleteData = useCollectionEntityDelete();
  const assignItems = useCollectionEntityAssign();
  const unAssignItems = useCollectionEntityUnAssign();
  const acceptItems = useCollectionEntityAssign({deleteItem: true});

  const createTask = useEntityTaskCreate();
  const downloadItems = useExportAdd();
  const selectedItems = useCollectionEntitySelected(collectionId);
  const updateCsi = useEntityCsiPatch();
  const updaters = useMemo(() => {
    return {
      createData: (data) => {
        return create.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      updateData: (entity, changes) => {
        return update(collection.data, entity, changes);
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      assignItems: (data) => {
        return assignItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      unAssignItems: (data) => {
        return unAssignItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      acceptItems: (data) => {
        return acceptItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      createTask: (entities, data) => {
        return createTask(entities, collection.data?.collectionId, data);
      },
      updateCsi: (entity, changes) => {
        return updateCsi(entity, {collectionId: collection.data?.collectionId, ...changes});
      },
      downloadItems: (data) => {
        return downloadItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      selectedItems: selectedItems
    }
  }, [update, create.mutation, deleteData.mutation, assignItems.mutation,
    unAssignItems.mutation, downloadItems.mutation, acceptItems.mutation,
    updateCsi, createTask, selectedItems, collection.data]);

  const defaults = useMemo(() => {
    return utils.mergeObjects({
      list: {
        filter: [{id: 'scope', value: 'inside'}]
      }
    }, props.defaults);
  }, [props.defaults]);

  return <EntitiesTableProvider {...props}
                                view={view}
                                options={options}
                                fieldData={fieldData}
                                context={context}
                                data={data}
                                onContext={onContext}
                                onData={onData}
                                updaters={updaters}
                                defaults={defaults}
                                LoaderComponent={CollectionEntitiesTableLoader}
                                TestLoaderComponent={CollectionEntitiesTableTestLoader}
                                isLoading={isLoading}>
    {props.children}
  </EntitiesTableProvider>
};

CollectionEntitiesTableProvider.propTypes = {
}

export default CollectionEntitiesTableProvider;
