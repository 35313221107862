import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import SuggestionBar from 'components/organisms/Bars/SuggestionBar/SuggestionBar';

const StyledEntitiesSuggestionsBar = styled(SuggestionBar)`
  .PageBar-title {
    padding: ${props => props.theme.layout(6)} 0;
  }
  ${ComponentStyles}
`;

StyledEntitiesSuggestionsBar.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesSuggestionsBar.defaultProps = {
};

export default StyledEntitiesSuggestionsBar;
