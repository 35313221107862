import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledClientCollaboratorsProfileCard = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  aspect-ratio: 536 / 420;
  overflow: hidden;

  transition: ${props => props.theme.transitions.create(['height'], {
    easing: props.theme.property('transitions.easing.easeOut'),
    duration: props.theme.property('transitions.duration.leavingScreen')
  })} !important;

  .ProfileCardContent {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    
    .ProfileCardContent-loader {
      gap: 0;
      padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      
      > .Box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: ${props => props.theme.layout(29.5)} 0;
        
        > * {
          &:first-child {
            flex-grow: 1;
            width: unset !important;
            min-width: unset !important;
            max-width: unset !important;
          }
          &:not(:first-child, :last-child) {
            width: 40%;
          }
        }
      }
    }
  }

  .ProfileCard {
    &-header {
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      
      .MuiCardHeader-action {
        pointer-events: all;

        .ProfileCard-action {
          visibility: visible;
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledClientCollaboratorsProfileCard.propTypes = {
  theme: PropTypes.object
};

StyledClientCollaboratorsProfileCard.defaultProps = {
}

export default StyledClientCollaboratorsProfileCard;
