import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import AppBar from '@mui/material/AppBar';

const StyledAppBar = styled(AppBar)`
  ${ComponentStyles}
`;

StyledAppBar.propTypes = {
  theme: PropTypes.object
};

StyledAppBar.defaultProps = {
}

export default StyledAppBar;
