import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntitiesSuggestionBrowser = styled(Box)`
  position: relative;

  .EntitiesSuggestionBrowser {
    &-controls {
      z-index: 2;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: ${props => props.theme.layout(24)};
    }
  }

  &.EntitiesSuggestionBrowser {
    .Browser {
      height: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesSuggestionBrowser.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesSuggestionBrowser.defaultProps = {}

export default StyledEntitiesSuggestionBrowser;
