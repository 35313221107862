import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import StyledActionTab from 'components/molecules/Tabs/ActionTab/ActionTab.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ActionTab = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionTabProps}, 'ActionTab');

  const innerRef = useRef(null);
  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const actionTab = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    active: itm?.active
  }), [itm?.active]);

  useImperativeHandle(ref, () => actionTab);

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    return <Tooltip title={action.tooltip}
                    fullWidth={innerProps.fullWidth ?? action.TabProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionTab ref={innerRef} {...innerProps}
                       selected={innerProps.selected ?? itm?.active}
                       label={action.label}
                       disabled={!itm}
                       onClick={handleClick}
                       badge={action.badge}
                       iconPosition={action.icon && (action.iconPosition || 'top')}
                       icon={action.icon ? <Icon icon={action.icon} /> : null}
                       {...action.TabProps} />
    </Tooltip>
  }
});

ActionTab.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionTab.defaultProps = {
  label: 'ActionTab text'
};

export default ActionTab;
