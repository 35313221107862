import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledFileUploadDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(600)}, 86vw);
  }
  
  ${ComponentStyles}
`;

StyledFileUploadDialog.propTypes = {
  theme: PropTypes.object
};

StyledFileUploadDialog.defaultProps = {}

export default StyledFileUploadDialog;
