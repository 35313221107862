import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import List from 'components/atoms/Lists/List/List';

const StyledDealflowStatusList = styled(List)`
  &.DealflowStatusList {
    .ListItem {
      &.DealflowStatusList {
        &-group {
          padding: ${props => props.theme.spacing(5.5/8)} ${props => props.theme.spacing(1)};
          background-color: ${props => props.theme.property('palette.action.hover')};

          .MuiListItemText-root {
            margin: 0;
          }

          .DealflowStatusList-group {
            &-icon {
              margin-right: ${props => props.theme.layout('0.5sp')};
            }
            &-text {
              font-weight: 500;
            }
          }
        }

        &-status {
          .ListItemButton {
            padding: ${props => props.theme.spacing(0.5)} ${props => props.theme.spacing(2)};
          }
          .DealflowStatusList-status {
            &-icon {
              margin-left: ${props => props.theme.layout('1sp')};
            }
          }
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledDealflowStatusList.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusList.defaultProps = {}

export default StyledDealflowStatusList;
