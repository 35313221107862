import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledSuggestionBar from 'components/organisms/Bars/SuggestionBar/SuggestionBar.styles';

const SuggestionBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'SuggestionBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSuggestionBar ref={ref} {...innerProps}/>
});

SuggestionBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

SuggestionBar.defaultProps = {
};

export default SuggestionBar;
