import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledFormField = styled(Box)`
  ${props => utils.isDefined(props.$minWidth) ? css`
    width: ${props => props.theme.layout(props.$minWidth)} !important;
    min-width: ${props => props.theme.layout(props.$minWidth)} !important;
  ` : css``}

  ${props => utils.isDefined(props.$maxWidth) ? css`
    max-width: ${props => props.theme.layout(props.$maxWidth)} !important;
  ` : css``}
  
  &.FormField-hidden {
    display: none !important;
  }
  
  ${ComponentStyles}
`;

StyledFormField.propTypes = {
  theme: PropTypes.object
};

StyledFormField.defaultProps = {}

export default StyledFormField;
