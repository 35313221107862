import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledTagsField = styled(FormControl)`
  .TagsField {
    &-container {
      gap: ${props => props.theme.layout('0.5sp')};
    }
    &-label {
      margin-bottom: ${props => props.theme.spacing(0.5)};
    }
    &-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      
      .ListItem {
        max-width: fit-content;
        
        > * {
          flex-grow: 1 !important;
          width: 100% !important;
          max-width: fit-content !important;
        }
      }
    }
    &-add {
      display: flex;
    }
    &-readOnly {      
      .placeholder {        
        color: ${props => props.theme.property('palette.text.disabled')};
      }
    }
  }
  
  .InputContainer > .FormHelperText {
    > * {
      margin-top: ${props => props.theme.layout(-2)};
    }
  }
  ${ComponentStyles}  
`;

StyledTagsField.propTypes = {
  theme: PropTypes.object
};

StyledTagsField.defaultProps = {
}

export default StyledTagsField;
