import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledIconButton from 'components/atoms/Buttons/IconButton/IconButton.styles';
import utils from 'helpers/utils';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import Component from 'components/organisms/Utils/Component/Component';
import {withMemo} from 'helpers/wrapper';

const IconButton = withMemo(React.forwardRef((props, ref) => {
  const {
    grouped,
    badge,
    flatBackground,
    plainBorder,
    isLoading,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'IconButton', {
    static: ['disabled', 'selected', 'grouped', 'clickable', 'flatBackground', 'plainBorder']
  });

  const clickable = Boolean(innerProps.onClick || innerProps.href || innerProps.type);
  const square = ['outlined', 'contained', 'transparent'].includes(innerProps.variant);

  const renderChildren = () => {
    if (badge) {
      return <Component Original={badge}
                        className="IconButton-badge"
                        variant="inline"
                        size="small"
                        disabled={innerProps.disabled}
                        isLoading={isLoading}
                        {...BadgeProps}>
        {innerProps.children}
      </Component>;
    } else {
      return innerProps.children;
    }
  }

  const renderIconButton = () => {
    return <StyledIconButton ref={ref} {...innerProps} $clickable={clickable}>
      {renderChildren()}
    </StyledIconButton>
  };

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable
  });

  if (isLoading) {
    return <Skeleton variant={!square ? 'circular' : 'rounded'}>
      {renderIconButton()}
    </Skeleton>
  } else {
    return renderIconButton();
  }
}));

IconButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  selected: PropTypes.bool,
  isLoading: PropTypes.bool,
  flatBackground: PropTypes.bool,
  plainBorder: PropTypes.bool,
  BadgeProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['menu', 'outlined', 'contained', 'transparent']), PropTypes.string]),
};

IconButton.defaultProps = {
  children: 'Icon button text',
  color: 'default',
  density: 'normal',
  flatBackground: false,
  plainBorder: true
};

export default IconButton;
