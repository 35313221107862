import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Area} from 'recharts';

const StyledActionArea = styled(Area)`
  &.ActionLine-clickable {
    cursor: pointer;
  }
  
  .AreaChartDot-clickable {
    cursor: pointer;
  }
  
  ${ComponentStyles}
`;

StyledActionArea.propTypes = {
  theme: PropTypes.object
};

StyledActionArea.defaultProps = {
}

export default StyledActionArea;
