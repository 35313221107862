import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import utils from 'helpers/utils';
import StyledActionBadge from 'components/molecules/Badges/ActionBadge/ActionBadge.styles';
import {Span} from 'components/atoms/Text/Typography/Typography';

const ActionBadge = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionBadgeProps}, 'ActionBadge');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm && !showInactive) {
    return <React.Fragment />;
  } else {
    innerProps.className = utils.flattenClassName(innerProps.className);

    return <Tooltip title={action.tooltip}
                    fullWidth={innerProps.fullWidth ?? action.BadgeProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionBadge ref={ref} {...innerProps}
                         disabled={!itm}
                         clickable={Boolean(handleClick)}
                         onClick={handleClick}
                         badgeContent={action.label}
                         color={action.color ?? innerProps.color}
                         {...action.BadgeProps}>
        {handleClick ? <Span onClick={handleClick}>{innerProps.children}</Span> : innerProps.children}
      </StyledActionBadge>
    </Tooltip>
  }
});

ActionBadge.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionBadge.defaultProps = {
};

export default ActionBadge;
