import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledChipListTableCell = styled(BaseTableCell)`
  gap: ${props => props.theme.layout('0.5sp')};

  .ChipList {
    align-items: center;
    
    &-density-sparse {
      gap: ${props => props.theme.layout(3)};
    }
  }
  
  ${ComponentStyles}
`;

StyledChipListTableCell.propTypes = {
  theme: PropTypes.object
};

StyledChipListTableCell.defaultProps = {
}

export default StyledChipListTableCell;
