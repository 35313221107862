import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitySectionPanel from 'components/organisms/SectionPanels/EntitySectionPanel/EntitySectionPanel';

const StyledCollectionEntitySectionPanel = styled(EntitySectionPanel)`
  ${ComponentStyles}
`;

StyledCollectionEntitySectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitySectionPanel.defaultProps = {}

export default StyledCollectionEntitySectionPanel;
