import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader.styles';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Close from '@mui/icons-material/Close';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const DialogHeader = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    ...innerProps
  } = useComponentProps(props, 'DialogHeader');

  const dialog = useDialog();

  const onCloseEvent = useEffectEvent(dialog.close);
  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: (e) => {
      onCloseEvent?.(e, 'closeButtonClick');
    }
  }), [onCloseEvent]);

  const renderTitle = () => {
    return <React.Fragment>
      {title ? <Box className="DialogHeader-title-title">{utils.isReactElement(title) ? title : <H6>{title}</H6>}</Box> : null}
      {subtitle ? <Box className="DialogHeader-title-subtitle">{utils.isReactElement(subtitle) ? subtitle : <H6>{subtitle}</H6>}</Box> : null}
    </React.Fragment>
  }

  return <StyledDialogHeader ref={ref} {...innerProps}>
    <Box className="DialogHeader-title">
      {renderTitle()}
    </Box>
    <ActionIconButton size="smaller"
                      density="sparse"
                      variant="outlined"
                      className="DialogHeader-close"
                      IconProps={{
                        size: 'smaller'
                      }}
                      action={closeAction} />
  </StyledDialogHeader>
});

DialogHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any
};

DialogHeader.defaultProps = {
  children: 'DialogHeader text'
};

export default DialogHeader;
