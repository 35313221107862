import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem, useOverflowShadow} from 'helpers/hooks/utils';
import StyledActionList from 'components/molecules/Lists/ActionList/ActionList.styles';
import ActionListItem from 'components/molecules/Lists/ActionListItem/ActionListItem';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import List from 'components/atoms/Lists/List/List';
import Box from 'components/atoms/Layout/Box/Box';
import {useAuthorizeAction} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import dom from 'helpers/dom';

const ActionList = React.forwardRef((props, ref) => {
  const {
    depth,
    nowrap,
    actions,
    closeDelay,
    expandMultiple,
    state,
    ListProps,
    ActionListItemProps,
    ...innerProps
  } = useComponentProps(props, 'ActionList', {
    variable: ['depth'],
    children: ['sub1', 'sub2', 'sub3']
  });

  const innerRef = useRef(null);
  const overflowRef = useRef(null);
  const childRefs = useRef({});

  const [internalState, setInternalState] = useState({open: {}});

  const authorizeAction = useAuthorizeAction();

  const stateMemo = useEffectItem(state);
  const actionList = useMemo(() => ({
    refs: {
      ref: innerRef,
      childRefs: childRefs
    },
    state: {...internalState, ...stateMemo},
    expand: (recurse) => {
      if (actions) {
        setInternalState(utils.updater({
          open: actions.reduce((o, action, idx) => {
            if (action.children) {
              o[idx] = true;
            }
            return o;
          }, {})
        }, true));

        if (recurse) {
          Object.keys(childRefs.current).forEach((k) => childRefs.current[k]?.current?.expand?.(true));
        }
      }
    },
    collapse: (recurse) => {
      setInternalState(utils.updater({open: {}}, true));

      if (recurse) {
        Object.keys(childRefs.current).forEach((k) => {
          childRefs.current[k]?.current?.collapse?.(true)
        });
      }
    }
  }), [internalState, stateMemo, actions]);

  useImperativeHandle(ref, () => actionList);

  useOverflowShadow(internalState.scrollElement, null, overflowRef.current);

  const handleChildRef = (idx) => (actionListItem) => {
    childRefs.current[idx] = actionListItem?.refs?.childRef;
  }

  // save state here we want only 1 or more open list at a time
  const closeTimeout = useRef(null);
  const toggleSubMenu = (idx) => (open, immediate) => {
    const doIt = (closeOthers = false) => {
      if (!open) {
        Object.keys(childRefs.current).forEach((k) => {
          childRefs.current[k]?.current?.collapse?.(true)
        });
      }

      setInternalState((current) => {
        return {
          ...current,
          open: closeOthers ? {[idx]: open} :
            {...current.open, [idx]: open}
        };
      });
    }

    clearTimeout(closeTimeout.current);
    if (open || immediate) {
      doIt(!(closeDelay || expandMultiple) || immediate);
      closeTimeout.current = setTimeout(() => doIt(true), closeDelay);
    } else if (!expandMultiple) {
      closeTimeout.current = setTimeout(() => doIt(true), closeDelay);
    }
  }

  const actionsMemo = useMemo(() => {
    return actions?.filter((action) => authorizeAction(action));
  }, [actions, authorizeAction]);

  const debouncedScrollElement = useMemo(() => {
    return utils.debounce((el) => {
      const element = () => {
        const scrollElement = dom.getScrollElement(el, false, true);
        setInternalState((current) => {
          if (current.scrollElement !== scrollElement) {
            return {...current, scrollElement};
          } else {
            return current;
          }
        });

        return Boolean(scrollElement);
      }

      utils.retry(element);
    }, constants.debounce.minimal);
  }, []);

  const handleRef = (el) => {
    debouncedScrollElement(el);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);
  if (depth > 0) {
    innerProps.className = utils.classNames(innerProps.className, `ActionList-sub ActionList-sub${depth}`);
  }

  return <StyledActionList ref={innerRef} {...innerProps} $depth={depth}>
    <Box ref={handleRef} className="ActionList-scroll">
      <List className="ActionList-list" gap={8} track={true} {...ListProps}>
        {actionsMemo?.map((action, idx) => <ActionListItem key={idx}
                                                           ref={handleChildRef(idx)}
                                                           action={action}
                                                           open={Boolean(actionList.state.open[idx])}
                                                           onToggle={toggleSubMenu(idx)}
                                                           nowrap={nowrap}
                                                           depth={depth}
                                                           {...ActionListItemProps}/>)}
      </List>
    </Box>
    {depth === 0 ? <Box ref={overflowRef} className="ActionList-footer"/> : null}
  </StyledActionList>
});

ActionList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array.isRequired,
  depth: PropTypes.number,
  state: PropTypes.object,
  nowrap: PropTypes.bool,
  expandMultiple: PropTypes.bool,
  closeDelay: PropTypes.number,
  ListProps: PropTypes.object,
  ActionListItemProps: PropTypes.object
};

ActionList.defaultProps = {
  depth: 0,
  actions: [],
  nowrap: false,
  expandMultiple: false,
  closeDelay: constants.delay.medium
};

export default ActionList;
