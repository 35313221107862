import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useCommentStatus () {
  return useServiceStatus(path);
}

export function useCommentList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useCommentGet ({commentId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {commentId}, queryOptions);
}

export function useCommentAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCommentUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useCommentDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCommentMarkDone ( mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/:commentId/done?group=true',
    ...mutationOptions
  });
}

export function useCommentMarkUndone (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'comments/:commentId/done?group=true',
    ...mutationOptions
  });
}

export function useCommentMarkRead ( mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/:commentId/read?group=true',
    ...mutationOptions
  });
}

export function useCommentMarkUnread (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'comments/:commentId/read?group=true',
    ...mutationOptions
  });
}

export function useCommentMarkAllRead ( mutationOptions = {}) {
  // commentId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/clients/read', ...mutationOptions
  });
}

export function useCommentMarkAllUnread ( mutationOptions = {}) {
  // commentId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'comments/clients/read', ...mutationOptions
  });
}
