import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAvatar from 'components/atoms/Avatars/Avatar/Avatar.styles';
import utils from 'helpers/utils';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const Avatar = withMemo(React.forwardRef((props, ref) => {
  const {
    size,
    outlined,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Avatar', {
    styled: ['color'],
    static: ['clickable', 'outlined', 'disabled', 'selected']
  });

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: Boolean(innerProps.onClick)
  });

  const renderAvatar = () => {
    return <StyledAvatar ref={ref} {...innerProps}>
      {innerProps.children}
    </StyledAvatar>;
  }

  if (isLoading) {
    return <Skeleton variant="circular">{renderAvatar()}</Skeleton>
  } else {
    return renderAvatar();
  }
}));

Avatar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  outlined: PropTypes.bool
};

Avatar.defaultProps = {
  children: 'Avatar text'
};

export default Avatar;
