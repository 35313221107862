import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import StyledActionLink from 'components/molecules/Links/ActionLink/ActionLink.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Link from 'components/atoms/Links/Link/Link';

const ActionLink = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionLinkProps}, 'ActionLink');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  if (!itm && !showInactive) {
    return <React.Fragment />;
  } else {
    return <Tooltip title={action.tooltip} showDisabled={true}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionLink as={itm?.navigation?.href ? Link : null}
                        ref={ref} {...innerProps}
                        {...itm?.navigation}
                        onClick={handleClick}
                        disabled={!itm}>
        {action.label}
      </StyledActionLink>
    </Tooltip>
  }
});

ActionLink.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionLink.defaultProps = {
};

export default ActionLink;
