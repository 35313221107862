import React, {useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOverflowShadow} from 'helpers/hooks/utils';
import StyledCollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog.styles';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useEntityCallbacks} from 'services/entity/entity.utils';
import Folder from '@mui/icons-material/Folder';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import CollectionAddDialog from 'components/organisms/Dialogs/CollectionAddDialog/CollectionAddDialog';
import {useCollectionAdd} from 'services/collection/collection.hooks';

const CollectionSelectionDialog = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    onClose,
    onChange,
    onSubmit,
    collections,
    multiple,
    required,
    hideCreate,
    hideCollections,
    collectionFilter,
    disableCollections,
    SaveButtonProps,
    ...innerProps
  } = useComponentProps(props, 'CollectionSelectionDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const overflowRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [adding, setAdding] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selection, setSelection] = useState(null);
  const [scrollElement, setScrollElement] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  const callbacks = useEntityCallbacks();

  const dialogControl = useDialogControl();

  const addCollection = useCollectionAdd();

  useOverflowShadow(scrollElement, null, overflowRef.current);

  const onSubmitEvent = useEffectEvent(onSubmit);
  const onCloseEvent = useEffectEvent(onClose);
  const doSubmit = useCallback((collections) => {
    return utils.asPromise(onSubmitEvent)(collections)
      .then(() => {
        onCloseEvent?.(null, 'saveButtonClick');
      });
  }, [onSubmitEvent, onCloseEvent]);

  const createAction = useMemo(() => ({
    label: 'New collection',
    icon: Add,
    color: 'success',
    auth: !hideCreate ? utils.createAuth({ attribute: 'collection.create' }) :
      utils.createAuth({attribute: 'system.null'}),
    onClick: () => {
      setAdding(true);

      const handleSubmit = (collection) => {
        return addCollection.mutation.mutateAsync(collection)
          .then(({response}) => {
            const newCollection = utils.camelcase(response.data?.data);
            return doSubmit([newCollection]);
          });
      };

      const handleClose = (e, reason) => {
        if (reason !== 'saveButtonClick') {
          setAdding(false);
          dialogControl.hide();
        }
      };

      dialogControl.show(<CollectionAddDialog placement={innerProps.placement} onSubmit={handleSubmit} />, false, handleClose);
    }
  }), [hideCreate, dialogControl, doSubmit, innerProps.placement, addCollection.mutation]);

  const fields = useMemo(() => ([{
    name: 'collection',
    placeholder: 'Search for collection',
    type: constants.formFieldTypes.category,
    validation: Boolean(multiple) ? constants.formFieldValidationTypes.list : constants.formFieldValidationTypes.number,
    initial: hideCollections ? [] : collections?.map((c) => ({
      collection: c,
      label: c.name,
      value: c.collectionId,
      disabled: disableCollections
    })),
    options: collectionFilter ? ({search, ids, filter, callback}) => {
      return callbacks.collections({search, ids, filter: utils.addFilter(filter, collectionFilter), callback});
    } : callbacks.collections,
    prefix: <Icon icon={Search} />,
    filter: hideCollections ? collections?.map((c) => `-${c.collectionId}`) : null,
    FormFieldProps: {
      multiple: Boolean(multiple),
      required: Boolean(required),
      autoFocus: true,
      showSearch: true,
      hiddenLabel: true,
      fullHeight: true,
      variant: 'outlined',
      size: 'smaller',
      emptyText: 'No collections found',
      limitOptions: false,
      ref: (el) => {
        setScrollElement(el?.ref?.current?.querySelector('.ListField-list'));
      },
      ListProps: {
        gap: 6
      },
      CategoryCardProps: {
        variant: 'inline',
        icon: Folder,
        fullHeight: false
      }
    }
  }]), [callbacks, collections, multiple, required, hideCollections, collectionFilter, disableCollections]);

  const handleChangeDirect = (e) => {
    const values = utils.toArray(e.target.value);
    const selected = utils.toArray(selection ?? fields[0].initial);

    const added = values.filter((opt) => !selected.find((sel) => sel.value === opt.value));
    const removed = selected.filter((sel) => !values.find((opt) => sel.value === opt.value));

    if (added.length > 0 || removed.length > 0) {
      const collections = values.map((v) => v.collection);

      setSelection(values);
      if (onChange) {
        (removed.length > 0 ? utils.asPromise(onChange)(removed[0].collection, collections, true) : utils.asPromise(onChange)(added[0].collection, collections, false))
          .catch(() => {
            setError(removed.length > 0 ? 'Removing collection failed' : 'Adding collection failed');
          })
      }
    }

    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    doSubmit(values['collection'].map((v) => v.collection))
      .catch(() => {
        setError('Adding to collection failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = (e) => {
    if (onSubmit) {
      formRef.current?.submit();
    } else {
      innerRef.current?.close?.(e)
    }
  }

  const handleCancelClick = (e) => {
    onClose?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      {!(error ?? validation) ? <ActionButton action={createAction}
                                              variant="contained"
                                              showInactive={!hideCreate}
                                              disabled={submitting} /> : null}
      <Button variant="text"
              children="Cancel"
              startIcon={<Icon icon={Close}/>}
              disabled={submitting}
              onClick={handleCancelClick}/>
      <Button type="submit"
              variant="contained"
              children="Save"
              startIcon={<Icon icon={Save}/>}
              disabled={submitting}
              onClick={handleSubmitClick}
              {...SaveButtonProps}/>
    </React.Fragment>
  }

  return <StyledCollectionSelectionDialog ref={innerRef} {...innerProps} hide={adding} onClose={handleClose}>
    <DialogHeader title={title ?? 'Add to collection'}
                  subtitle={subtitle} />
    <DialogContent>
      <InlineForm ref={formRef}
                  className="CollectionSelectionDialog-form"
                  fields={fields}
                  onValidating={handleValidating}
                  onSubmit={handleSubmit}
                  onChangeDirect={handleChangeDirect}/>
      <Box ref={overflowRef} className="CollectionSelectionDialog-overflow" />
    </DialogContent>
    <DialogFooter className="CollectionSelectionDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledCollectionSelectionDialog>
});

CollectionSelectionDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  collections: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  hideCreate: PropTypes.bool,
  hideCollections: PropTypes.bool,
  collectionFilter: PropTypes.any,
  disableCollections: PropTypes.bool,
  SaveButtonProps: PropTypes.object
};

CollectionSelectionDialog.defaultProps = {
  multiple: true
};

export default CollectionSelectionDialog;
