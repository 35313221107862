import React from 'react';
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Component = withMemo(React.forwardRef((props, ref) => {
  const {
    Original,
    ...rest
  } = props;

  let Converted = Original;
  if (utils.isReactElement(Converted)) {
    return rest.children ? utils.cloneElement(Converted, {...rest, ref}, rest.children) :
      utils.cloneElement(Converted, {...rest, ref});
  } else if (utils.isReactComponent(Converted)) {
    return <Converted ref={ref} {...rest} />
  } else if (utils.isFunction(Converted)) {
    return Converted({...rest, ref});
  }
}));

Component.propTypes = {
  Original: PropTypes.any
};

Component.defaultProps = {
};

export default Component;
