import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledSourceCreateSectionPanelContent
  from 'components/organisms/SectionPanels/SourceCreateSectionPanelContent/SourceCreateSectionPanelContent.styles';
import constants from 'helpers/constants';
import SourceAddDialog from 'components/organisms/Dialogs/SourceAddDialog/SourceAddDialog';
import SourceCard from 'components/organisms/Cards/SourceCard/SourceCard';
import utils from 'helpers/utils';
import {useSourceCreate} from 'services/source/source.utils';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import DataList from 'components/organisms/Lists/DataList/DataList';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const SourceCreateSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    onDialog,
    onCanUpdate,
    collection,
    addSuggestions,
    ...innerProps
  } = useComponentProps(props, 'SourceCreateSectionPanelContent');

  const [internalState, setInternalState] = useState({
    showSourceAddDialog: false,
    showEntitiesUploadDialog: false,
    sourceType: {}
  });

  const authorize = useAuthorize();

  const createSource = useSourceCreate();

  const doClose = () => {
    onDialog?.(false);
    setInternalState(utils.updater({
      showSourceAddDialog: false,
      showEntitiesUploadDialog: false,
      sourceType: null
    }, true));
  };

  const handleClick = (sourceType) => () => {
    if (sourceType.value !== constants.sources.types.suggestions) {
      onDialog?.(true);
      setInternalState({
        showSourceAddDialog: sourceType.value !== constants.sources.types.fileUpload,
        showEntitiesUploadDialog: sourceType.value === constants.sources.types.fileUpload,
        sourceType: sourceType
      });
    } else {
      onSubmit?.({sourceId: 0, type: sourceType.value});
    }
  };

  const renderSourceAddDialog = () => {
    if (internalState.showSourceAddDialog) {
      const handleSubmit = (source) => {
        if (source?.type === constants.sources.types.database) {
          onSubmit?.({...source, sourceId: -1});
          doClose();
        } else {
          return createSource(source)
            .then((res) => {
              const newSource = res.response?.data?.data?.[0];
              setInternalState(utils.updater({showSourceAddDialog: false}, true));
              onSubmit?.({...source, sourceId: newSource?.params?.publicCollectionId});
              doClose();
            });
        }
      }

      const handleClose = (e) => {
        doClose();
        e.preventDefault()
      };

      return <SourceAddDialog title={`Add ${internalState.sourceType.label.toLowerCase()}`}
                              open={true}
                              type={internalState.sourceType}
                              onSubmit={handleSubmit}
                              onClose={handleClose}/>
    }
  };

  const renderEntitiesUploadDialog = () => {
    if (internalState.showEntitiesUploadDialog) {
      const handleSubmit = (source) => {
        return createSource(source)
          .then((res) => {
            const newSource = res.response?.data?.data?.[0];
            setInternalState(utils.updater({showEntitiesUploadDialog: false}, true));
            onSubmit?.({...source, sourceId: newSource?.params?.publicCollectionId});
          });
      }

      const handleClose = (e) => {
        onDialog?.(false);
        setInternalState(utils.updater({showEntitiesUploadDialog: false}, true));
        e.preventDefault()
      };

      return <EntitiesUploadDialog title="Add file upload"
                                   open={true}
                                   onSubmit={handleSubmit}
                                   onClose={handleClose}
                                   EntitiesUploadWizardProps={{isSource: true}}/>
    }
  };

  const renderCard = (source, state) => {
    return <SourceCard source={null}
                       variant="standard"
                       title={source.label}
                       description={source.description}
                       icon={source.icon}
                       img={source.img}
                       fullWidth={true}
                       selected={state.selected}
                       onClick={handleClick(source)}
                       isLoading={state.isLoading}
                       IconProps={{color: source.color}}/>
  }

  const isItemEqual = (source, selected) => {
    return source.value === selected.value;
  }

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const data = useMemo(() => {
    return constants.data.sourceTypes
      .filter((s) => s.enabled)
      .filter((s) => addSuggestions || s.value !== constants.sources.types.suggestions)
      .filter((s) => (onCanUpdateEvent ? onCanUpdateEvent?.(collection) : true) &&
        authorize({attribute: 'collection.source.create', meta: {collection, source: s}}))
  }, [collection, addSuggestions, authorize, onCanUpdateEvent]);

  return <StyledSourceCreateSectionPanelContent ref={ref} {...innerProps}>
    <DataList data={data}
              count={data.length}
              dataKey="value"
              renderItem={renderCard}
              isItemEqual={isItemEqual}
              isLoading={false}
              ListProps={{
                virtualize: false,
                track: true,
                gap: 8
              }} />
    {renderSourceAddDialog()}
    {renderEntitiesUploadDialog()}
  </StyledSourceCreateSectionPanelContent>
});

SourceCreateSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func,
  onDialog: PropTypes.func,
  onSubmit: PropTypes.func,
  collection: PropTypes.object,
  addSuggestions: PropTypes.bool
};

SourceCreateSectionPanelContent.defaultProps = {};

export default SourceCreateSectionPanelContent;
