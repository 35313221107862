import React, {Children, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledSection from 'components/organisms/Sections/Section/Section.styles';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import utils from 'helpers/utils';

const Section = React.forwardRef((props, ref) => {
  const {
    Loader,
    isLoading,
    loaderCount,
    children,
    ...innerProps
  } = useComponentProps(props, 'Section', {
    static: ['isLoading'],
    styled: ['gap']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const bBox = useBbox(() => innerRef.current, ['left', 'right', 'top', 'bottom']);

  const [display, setDisplay] = useState({});
  const [loaders, setLoaders] = useState(loaderCount);

  useLayoutEffect(() => {
    const count = isLoading ? loaders : Children.toArray(children).length;
    setDisplay((current) => {
      if (current.count !== count) {
        return {...current, count, visible: current.visible};
      } else {
        return current;
      }
    })
  }, [children, isLoading, loaders]);

  useEffect(() => {
    const count = Children.toArray(children).length;
    if (count > 0) {
      setLoaders(Math.max(count, 1));
    }
  }, [children]);

  useEffect(() => {
    if (innerRef.current && bBox) {
      const elements = Array.from(innerRef.current.children);
      const rowGap = utils.toInt(dom.getComputedStyle(innerRef.current).rowGap);
      const columnGap = utils.toInt(dom.getComputedStyle(innerRef.current).columnGap);

      if (elements.length > 0) {
        let visibleCount = 0,
          visibleBottom = 0,
          visibleRight = 0;

        elements.some((el) => {
          const box = dom.getBbox(el);
          if (+box.right.toFixed(1) <= +bBox.right.toFixed(1) && +box.left.toFixed(1) >= +bBox.left.toFixed(1) &&
              +box.bottom.toFixed(1) <= +bBox.bottom.toFixed(1) && +box.top.toFixed(1) >= +bBox.top.toFixed(1)) {
            visibleCount += 1;
            visibleBottom = Math.max(box.bottom, visibleBottom);
            visibleRight = Math.max(box.right, visibleRight);
          } else {
            return true;
          }

          return false;
        });

        const avgWidth = visibleCount > 0 ? ((visibleRight - bBox.left - (visibleCount - 1) * rowGap) / visibleCount) : constants.numbers.maxInt;
        const avgHeight = visibleCount > 0 ? ((visibleBottom - bBox.top) / visibleCount) : constants.numbers.maxInt;
        const canFitCount = Math.max(Math.floor((bBox.right - visibleRight) / (avgWidth + (visibleCount > 0 ? columnGap : 0))),
          Math.floor((bBox.bottom - visibleBottom) / (avgHeight + (visibleCount > 0 ? rowGap : 0))));

        setDisplay((current) => {
          const newCount = Math.min(current.count, visibleCount + canFitCount);
          if (current.visible !== newCount) {
            return {...current, visible: newCount};
          } else {
            return current;
          }
        })
      }
    }
  }, [bBox, children]);

  const renderCards = () => {
    return (isLoading && Loader) ? Array(display.visible || 1).fill(null).map((l, idx) => {
      return utils.cloneElement(Loader, {key: idx, style: (idx > display.visible) ? {
        visibility: 'hidden'
      } : null});
    }) : Children.toArray(children).slice(0, (display.visible || 1)).map((child, idx) => {
      if (idx > display.visible) {
        return utils.cloneElement(child, {style: (idx > display.visible) ? {
            visibility: 'hidden'
          } : null});
      } else {
        return child;
      }
    });
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSection ref={innerRef} {...innerProps}>
    {renderCards()}
  </StyledSection>
});

Section.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  loaderCount: PropTypes.number
};

Section.defaultProps = {
  loaderCount: 3
};

export default Section;
