import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import FormStyles from 'styles/mixins/Form.styles';

const StyledWizardContent = styled(Box)`
  ${FormStyles}
  ${ComponentStyles}
`;

StyledWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledWizardContent.defaultProps = {}

export default StyledWizardContent;
