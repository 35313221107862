import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListField from 'components/molecules/Fields/ListField/ListField';

const StyledColorField = styled(ListField)`
  &.ColorField {
    min-width: ${props => props.theme.layout(100)};
    .List {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(${props => props.$columns}, 1fr);
      grid-template-rows: repeat(${props => props.$rows}, 1fr);
      gap: ${props => props.theme.layout('0.5sp')};
    }
  }
  ${ComponentStyles}
`;

StyledColorField.propTypes = {
  theme: PropTypes.object
};

StyledColorField.defaultProps = {
}

export default StyledColorField;
