import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Actionbar from 'components/organisms/Snackbars/Actionbar/Actionbar';

const StyledEntitiesActionbar = styled(Actionbar)` 
  ${ComponentStyles}
`;

StyledEntitiesActionbar.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesActionbar.defaultProps = {}

export default StyledEntitiesActionbar;
