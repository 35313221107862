import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledTeamManagerDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;

    .MuiDialog-paper {
      margin-top: 16vh;
    }
  }
  
  .MuiDialog-paper {
    min-width: ${props => props.theme.layout(600)};
    max-width: ${props => props.theme.layout(600)};

    max-height: min(${props => props.theme.layout(420)}, 86vh);
  }

  .TeamManagerDialog {
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    
    &-usersForm {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      padding: ${props => props.theme.spacing(2)};
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      
      &-form {
        overflow: auto;
        .TextField {
          .MuiInputBase-root {
            background-color: ${props => props.theme.property('palette.background.default')};
          }
        }
      }
    }
    
    &-teamMembers {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
  
  &.TeamManagerDialog-extended {
    .MuiDialog-container {
      .MuiDialog-paper {
        margin-top: 12vh;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTeamManagerDialog.propTypes = {
  theme: PropTypes.object
};

StyledTeamManagerDialog.defaultProps = {}

export default StyledTeamManagerDialog;
