import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import StyledScoreTableCell from 'components/molecules/TableCells/ScoreTableCell/ScoreTableCell.styles';
import {withMemo} from 'helpers/wrapper';

const ScoreTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    ScoreLinearProgressProps,
    ...innerProps
  } = useComponentProps(props, 'ScoreTableCell');

  return <StyledScoreTableCell {...innerProps}>
    <ScoreLinearProgress {...ScoreLinearProgressProps}/>
  </StyledScoreTableCell>
}));

ScoreTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ScoreLinearProgressProps: PropTypes.object
};

ScoreTableCell.defaultProps = {
};

export default ScoreTableCell;


