import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledResetPage from 'components/pages/Auth/ResetPage/ResetPage.styles';
import ResetPasswordForm from 'components/organisms/Forms/ResetPasswordForm/ResetPasswordForm';
import {useLocation, useParams} from 'react-router-dom';
import {useAuthResetHash} from 'services/auth/auth.hooks';
import {useAuth} from 'services/auth/auth.utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import constants from 'helpers/constants';
import Navigate from 'components/organisms/Utils/Navigate/Navigate';

const ResetPage = (props) => {
  const innerProps = useComponentProps(props, 'ResetPage');

  const initialised = useRef(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);

  const { hash } = useParams();
  const auth = useAuth();
  const location = useLocation();

  const doResetHash = useAuthResetHash();

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      doResetHash.mutation.mutateAsync({hash: hash, $httpParams: { intercept: false }})
        .then(() => {
          setVerified(true);
        })
        .catch((error) => {
          if (error?.response?.status === constants.http.status.forbidden) {
            if (error?.response?.status < constants.http.status.serverError) {
              setError('Reset password key is expired or invalid');
            } else {
              setError('Reset password key could not be verified');
            }
          }
        });
    }
  }, [doResetHash.mutation, hash]);

  const renderForm = () => {
    if (auth?.loggedIn) {
      return <Box className="ResetPage-loading">
        <CircularProgress/>
        <H6>Preparing user data</H6>
        <Navigate to="/auth/login"
                  state={{
                    from: location.state?.from,
                    resetSearchParams: true
                  }}
                  replace={true}/>
      </Box>
    } else if (!verified) {
      if (error) {
        return [
          <H6 key={1}>{error}</H6>,
          <Box key={2} className="ResetPage-auth-links">
            <ActionLink action={{
              label: 'Try again?',
              navigation: {
                to: `/auth/forgot`,
              }
            }}/>
            <ActionLink action={{
              label: 'Login instead?',
              navigation: {
                to: `/auth/login`,
              }
            }}/>
          </Box>
        ];
      } else {
        return <Box className="ResetPage-loading">
          <CircularProgress />
          <H6>Verifying</H6>
        </Box>
      }
    } else if (verified) {
      return <ResetPasswordForm className="ResetPage-form" hash={hash}/>
    }
  }

  return <StyledResetPage {...innerProps}>
    {renderForm()}
  </StyledResetPage>
};

ResetPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ResetPage.defaultProps = {};

export default ResetPage;
