import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledMarkdown from 'components/atoms/Formatters/Markdown/Markdown.styles';
import remarkGfm from 'remark-gfm';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Box from 'components/atoms/Layout/Box/Box';
import {withMemo} from 'helpers/wrapper';
import Typography from 'components/atoms/Text/Typography/Typography';

const Markdown = withMemo(React.forwardRef((props, ref) => {
  const {
    showTooltip,
    TooltipProps,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Markdown');

  const renderTooltip = () => {
    if (showTooltip) {
      return <StyledMarkdown {...innerProps}>
        {innerProps.children.toString()}
      </StyledMarkdown>
    }
  }

  const renderMarkdown = () => {
    if (isLoading) {
      return <Typography ref={ref} isLoading={true}>
        {innerProps.children.toString()}
      </Typography>
    } else {
      innerProps.linkTarget = innerProps.linkTarget ?? '_blank';
      innerProps.remarkPlugins = innerProps.remarkPlugins ?? [remarkGfm];

      return <StyledMarkdown ref={ref} {...innerProps} skipHtml={true}>
        {innerProps.children.toString()}
      </StyledMarkdown>
    }
  }

  if (showTooltip) {
    return <Tooltip title={renderTooltip()}
                    placement={'bottom'}
                    {...TooltipProps}>
      <Box>
        {renderMarkdown()}
      </Box>
    </Tooltip>
  } else {
    return renderMarkdown();
  }
}));

Markdown.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showTooltip: PropTypes.bool,
  TooltipProps: PropTypes.object,
  isLoading: PropTypes.bool
};

Markdown.defaultProps = {
  children: 'Markdown text'
};

export default Markdown;
