import React, {useImperativeHandle, useRef} from 'react';
import StyledNavLink from 'components/atoms/Links/NavLink/NavLink.styles';
import utils from 'helpers/utils';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useNavLink} from 'helpers/hooks/links';
import dom from 'helpers/dom';
import {withMemo} from 'helpers/wrapper';

const NavLink = withMemo(React.forwardRef((props, ref) => {
  const {
    to, replace,
    state, relative,
    preventScrollReset,
    keepSearchParams,
    resetSearchParams,
    reloadDocument,
    end, target, onClick,
    ...innerProps
  } = useComponentProps(props, 'NavLink', {static: ['active']});

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  // component code
  const [handleClick, active, href] = useNavLink({
    to, replace,
    state, relative,
    preventScrollReset,
    keepSearchParams,
    resetSearchParams,
    reloadDocument,
    end, target, onClick,
  });

  const handleKeyDown = (e) => {
    if (handleClick && e.target === innerRef.current) {
      if (e.code === 'Space' || (e.code === 'Enter' && !dom.isNativeButton(e.target))) {
        e.target?.click();
        e.preventDefault();
      }
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {...innerProps, active});
  if (utils.isFunction(innerProps.children)) {
    innerProps.children = innerProps.children(active);
  }

  return <StyledNavLink ref={innerRef} {...innerProps}
                        href={href ?? ''}
                        target={target}
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}>
    {innerProps.children}
  </StyledNavLink>
}));

NavLink.propTypes = {
  to: PropTypes.string,
  relative: PropTypes.string,
  replace: PropTypes.bool,
  end: PropTypes.bool,
  preventScrollReset: PropTypes.bool,
  keepSearchParams: PropTypes.bool,
  resetSearchParams: PropTypes.bool,
  reloadDocument: PropTypes.bool,
  state: PropTypes.object,
  target: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

NavLink.defaultProps = {
  children: 'NavLink text',
  end: true
};

export default NavLink;
