import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSettingsHeader = styled(Box)` 
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: ${props => props.theme.spacing(3)};
  
  .SettingsHeader {
    &-head {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')}
    }

    &-title {
      flex-grow: 1;
      width: 1px;

      > * {
        max-width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    &-buttons {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      
      * {
        white-space: nowrap;
      }
    }
    
    &-info {
      margin-top: ${props => props.theme.spacing(1)};
      margin-bottom: -${props => props.theme.spacing(2)};
    }
    
    &-search {
      width: unset;
      min-width: ${props => props.theme.layout(280)};
    }
  }
  
  ${ComponentStyles}
`;

StyledSettingsHeader.propTypes = {
  theme: PropTypes.object
};

StyledSettingsHeader.defaultProps = {}

export default StyledSettingsHeader;
