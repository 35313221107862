import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Actionbar from 'components/organisms/Snackbars/Actionbar/Actionbar';

const StyledUsersActionbar = styled(Actionbar)` 
  ${ComponentStyles}
`;

StyledUsersActionbar.propTypes = {
  theme: PropTypes.object
};

StyledUsersActionbar.defaultProps = {}

export default StyledUsersActionbar;
