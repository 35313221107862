import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';
import {processClient} from 'services/client/client.utils';

const path = utils.servicePath(import.meta.url);

export class AuthService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'authId',
      maxSize: 100, // default 10000
      refreshEnabled: true,  // no background refreshing
      watch: ['user'],
      match: (cache, entity, id) => {
        return false; // renewing auth is expensive, code is here for future solution :)
        // return (entity === 'client' && +cache?.data?.clientId === +id) ||
        //   (entity === 'user' && +cache?.data?.userId === +id) ||
        //   entity === 'team';
      },
      process: (data, meta) => {
        // process
        const processAuth = (data) => {
          if (utils.isObject(data)) {
            const current = {};
            const raw = utils.camelcase(data);

            current.token = raw.token;

            current.type = raw.profile?.type;

            current.user = utils.camelcase(raw.profile);
            current.client = processClient(raw.client ?? raw.profile?.client);
            current.teams = (raw.teams || raw.profile?.teams || []).map((t) => {
              t = utils.camelcaseEx(t);
              return {
                ...t,
                teamId: t.teamId ?? t.clientId
              }
            });
            current.passes = (raw.passes || []).map(utils.camelcase);

            current.holderUserId = utils.camelcase(raw.holder)?.userId;
            current.holderClientId = utils.camelcase(raw.holderClient)?.clientId;

            current.userId = current.user?.userId;
            current.clientId = current.user?.clientId;
            current.teamId = current.user?.teamId;

            // keep an id for storage
            current.authId = current.holderUserId ?? current.userId;

            return current;
          } else {
            return data;
          }
        }

        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processAuth);
          } else {
            return processAuth(data);
          }
        } else {
          return data;
        }
      },
      effects: (prev, next, {state}) => {
        if (next?.context?.$store?.dataType !== constants.dataTypes.other) {
          const refresh = Boolean(next?.context?.$store?.refresh);

          next = next?.data;

          // save app state
          if (next) {
            const proxy = next.type === constants.user.types.proxy;

            state(constants.appState.type.session, this.name, constants.appState.scope.global, (current) => {
              if (!refresh || +current?.userId === +next.userId) {
                return {
                  ...current,
                  loggedIn: true,

                  type: next.type,

                  authId: next.authId,
                  userId: next.userId,
                  clientId: next.clientId,
                  teamId: (+current?.clientId === +next.clientId) ? (current?.teamId ?? next.teamId) : next.teamId,

                  user: next.user,
                  client: next.client,
                  teams: next.teams,
                  passes: next.passes,

                  tokens: {
                    token: next.token,
                    holder: proxy ? (
                      (+current?.userId > 0 && +current?.userId !== +next.userId) ? current?.tokens?.token : current?.tokens?.holder
                    ) : null
                  },

                  $store: {
                    persist: ['tokens', 'teamId']
                  }
                }
              } else {
                // holder token refresh
                return {
                  ...current,
                  tokens: {
                    token: current?.tokens?.token,
                    holder: next.token
                  }
                }
              }
            });

            state(constants.appState.type.local, this.name, constants.appState.scope.global, (current) => {
              if (!refresh || +current?.userId === +next.userId) {
                return {
                  ...current,
                  userId: next.userId,
                  teamId: proxy ? (current?.teamId ?? next.teamId) : null,
                  tokens: {
                    token: next.token,
                    holder: proxy ? (
                      (+current?.userId > 0 && +current?.userId !== +next.userId) ? current?.tokens?.token : current?.tokens?.holder
                    ) : null
                  }
                }
              } else {
                return {
                  ...current,
                  tokens: {
                    token: current?.tokens?.token,
                    holder: next.token
                  }
                }
              }
            });
          } else {
            state(constants.appState.type.session, this.name, constants.appState.scope.global, null);
            state(constants.appState.type.local, this.name, constants.appState.scope.global, null);
          }
        }
      },
      atoms: {
        data: {
          effects: (keys) => [
            ({onSet}) => {
              // called on creation
              logger.trace('DATA EFFECT INIT', keys);

              onSet((newValue, _, isReset) => {
                logger.trace('DATA EFFECT SET', keys, newValue, isReset);
              });
            }
          ]
        }
      },
      api: {
        query: {
          refresh: (params) => {
            return {
              ...this.api.default.query.refresh(params),
              action: (http) => {
                const {
                  ids
                } = params;

                if (params.ids?.length > 0) {
                  logger.trace('Refresh auth', params);

                  return http.post('auth/renew', {
                    intercept: false,
                    ...this.options.refreshListParams
                  })
                    .then((resp) => {
                      return {
                        meta: {
                          ...utils.responseMeta(resp)
                        },
                        data: resp?.data
                      };
                    })
                    .catch((error) => {
                      logger.trace('Refresh auth failed or was denied', error, ids);
                      return {
                        meta: {
                          ...utils.responseMeta(null),
                          invalidate: {
                            failed: true,
                            removedIds: ids
                          }
                        }
                      };
                    });
                }
              }
            }
          }
        },
        mutation: {
          logout: (params) => {
            // fake the logout procedure
            return {
              ...this.api.default.mutation.delete(params),
              action: (http, data) => {
                return {
                  meta: {
                    ...utils.responseMeta(null),
                    invalidate: {
                      removedIds: utils.isArray(data) ? data.map((d) => d[this.key]) : data?.[this.key]
                    }
                  }
                };
              }
            }
          }
        }
      }
    }, callbacks);
  }
}
