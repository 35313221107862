import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';
import constants from 'helpers/constants';

const StyledMainPage = styled(Page)`
  text-align: center;
  
  > .Button:not(:nth-of-type(1)), > .CustomButton:not(:nth-of-type(1)) {
    margin-left: ${props => props.theme.spacing(1)}
  }
  
  > .BigButton {
    &.first, &.last {
      margin-left: ${props => props.theme.spacing(1)}
    }
  }
  
  .ActionContainer, .Container {
    margin-top: ${props => props.theme.spacing(2)};
    
    ${props => props.theme.breakpoints.between('sm', 'md')} {
      // use hard pixels here
      max-width: ${props => props.theme.layout(props.theme.breakpoints.values.sm + 'px')}
    }
  }
  
  .app-logo {
    width: ${props => props.theme.layout(100)};
  }

  .test-page {
    //border: 1px solid;
    //background-color: unset;
    ${props => props.theme.color2Css('BigButton', null, 'primary', 
            {interactive: true, type: constants.colorTypes.button})}
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .app-logo {
      animation: app-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  ${ComponentStyles}
`;

StyledMainPage.propTypes = {
  theme: PropTypes.object
};

StyledMainPage.defaultProps = {
};

export default StyledMainPage;
