import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Tabs from 'components/atoms/Tabs/Tabs/Tabs';

const StyledActionTabs = styled(Tabs)`
  ${ComponentStyles}
`;

StyledActionTabs.propTypes = {
  theme: PropTypes.object
};

StyledActionTabs.defaultProps = {
}

export default StyledActionTabs;
