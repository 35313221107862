import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTableCellPopper from 'components/molecules/Poppers/TableCellPopper/TableCellPopper.styles';
import utils from 'helpers/utils';

const TableCellPopper = React.forwardRef((props, ref) => {
  const {
    anchorEl,
    onMouseEnter,
    onMouseLeave,
    ...innerProps
  } = useComponentProps(props, 'TableCellPopper');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableCellPopper ref={ref} {...innerProps}
                                anchorEl={anchorEl}
                                TransitionProps={{
                                  timeout: 100
                                }}
                                PaperProps={{
                                  variant: 'elevation',
                                  onMouseEnter,
                                  onMouseLeave
                                }}/>
});

TableCellPopper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  anchorEl: PropTypes.any,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

TableCellPopper.defaultProps = {
  children: 'TableCellPopper text'
};

export default TableCellPopper;
