import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTableHeaderText = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  ${ComponentStyles}
`;

StyledTableHeaderText.propTypes = {
  theme: PropTypes.object
};

StyledTableHeaderText.defaultProps = {
}

export default StyledTableHeaderText;
