import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileCardContent from 'components/organisms/Cards/ProfileCardContent/ProfileCardContent';

const StyledCollectionProfileCardContent = styled(ProfileCardContent)`
  .FormField {
    &.CategoryField {
      .ListField-list {
        display: grid;
        padding-top: ${props => props.theme.spacing(1)};
        padding-bottom: ${props => props.theme.spacing(1)};
        grid-template-columns: repeat(4, 1fr);
        gap: ${props => props.theme.layout('1sp')};

        .ListItem {
          height: 100%;
          aspect-ratio: 182/165;
        }
      }
    }

    &-name-projectRegions {
      &.CategoryField {
        .ListField-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: ${props => props.theme.layout('1sp')};

          .ListItem {
            height: unset;
            aspect-ratio: unset;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionProfileCardContent.defaultProps = {
}

export default StyledCollectionProfileCardContent;
