import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FormLabel from 'components/atoms/Labels/FormLabel/FormLabel';
import StyledSliderField from 'components/molecules/Fields/SliderField/SliderField.styles';
import Slider from 'components/atoms/Sliders/Slider/Slider';
import dom from 'helpers/dom';
import {withMemo} from 'helpers/wrapper';

const SliderField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    variant,
    placeholder,
    helperText,
    value,
    size,
    min,
    max,
    autoFocus,
    multiple,
    options,
    minDistance,
    onBlur,
    onChange,
    hiddenPlaceholder,
    showDescription,
    defaultThumbs,
    descriptionLabel,
    descriptionType,
    renderSliderLabel,
    SliderProps,
    IconProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'SliderField', {
    static: ['disabled']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const [sliderValue, minValue, maxValue] = useMemo(() => {
    if (options) {
      const minValue = min ?? options[0]?.value ?? options[0];
      const maxValue = max ?? options[options.length - 1]?.value ?? options[options.length - 1];
      const sliderValue = !utils.isEmpty(value) ?
        (multiple ? (utils.isArray(value) ? value : [value, value]) : value?.[0] ?? value) : (
          defaultThumbs ? (multiple ? [minValue, maxValue] : minValue) : (multiple ? [null, null] : null)
        );

      return [sliderValue, minValue, maxValue];
    } else {
      const sliderValue = !utils.isEmpty(value) ?
        (multiple ? (utils.isArray(value) ? value : [value, value]) : value?.[0] ?? value) : (
          defaultThumbs ? (multiple ? [min, max] : min) : (multiple ? [null, null] : null)
        )

      return [sliderValue, min, max];
    }
  }, [multiple, defaultThumbs, options, value, min, max]);

  const handleChange = (e, value, activeThumb) => {
    if (multiple && minDistance) {
      if (activeThumb === 0) {
        onChange({
          target: {
            name,
            value: [Math.min(value[0], sliderValue[1] - minDistance), sliderValue[1]]
          }
        });
      } else {
        onChange({
          target: {
            name,
            value: [sliderValue[0], Math.max(value[1], sliderValue[0] + minDistance)]
          }
        });
      }
    } else {
      onChange({
        target: {
          name,
          value
        }
      });
    }
  };

  const handleBlur = () => {
    onBlur?.({
      target: {
        name: name
      }
    });
  };

  const renderSlider = () => {
    if (options) {
      return <Slider size={size}
                     step={1}
                     min={minValue}
                     max={maxValue}
                     track="normal"
                     value={sliderValue}
                     disabled={innerProps.disabled}
                     marks={options.map((opt) => ({label: opt?.label ?? opt, value: opt?.value ?? opt}))}
                     valueLabelDisplay="off"
                     onChange={handleChange}
                     {...SliderProps}/>
    } else {
      return <Slider size={size}
                     min={minValue}
                     max={maxValue}
                     value={sliderValue}
                     disabled={innerProps.disabled}
                     onChange={handleChange}
                     {...SliderProps}/>
    }
  }

  const renderReadOnly = () => {
    if (!utils.isEmpty(value)) {
      if (multiple) {
        if (!utils.isEmpty(value)) {
          return <Box className="SliderField-readOnly Input-readOnly">
            {sliderValue.map((v, idx) => {
              return <Box key={idx} className="SliderField-readOnly-item">
                <Icon size="smaller" icon={idx === 0 ? ArrowForward : ArrowBack} {...IconProps}/>
                <Span>{idx === 0 ? (descriptionType === 'time' ? 'After' : '>=') : (descriptionType === 'time' ? 'Before' : '<')} {v?.label ?? v}</Span>
              </Box>
            })}
          </Box>
        }
      } else {
        return <Box className="SliderField-readOnly Input-readOnly">
          <Icon size="smaller" icon={ArrowForward} {...IconProps}/>
          <Span>{descriptionType === 'time' ? 'After' : '>='} {sliderValue?.label ?? value}</Span>
        </Box>
      }
    } else {
      return <Box className="SliderField-readOnly Input-readOnly">
        {(placeholder && !hiddenPlaceholder) ? <Span className="placeholder">{placeholder}</Span> : <Span>&nbsp;</Span>}
      </Box>
    }
  }

  const renderDescription = () => {
    if (utils.isArray(sliderValue)) {
      if (utils.isDefined(sliderValue[0]) && utils.isDefined(sliderValue[1])) {
        const label0 = renderSliderLabel ? renderSliderLabel(sliderValue[0]) : sliderValue[0];
        const label1 = renderSliderLabel ? renderSliderLabel(sliderValue[1]) : sliderValue[1];
        return <Span>{descriptionLabel ?? label} {descriptionType === 'time' ? 'after' : 'greater than'} <strong>{label0}</strong> and {descriptionType === 'time' ? 'before' : 'less than'} <strong>{label1}</strong></Span>
      } else if (utils.isDefined(sliderValue[0])) {
        const label0 = renderSliderLabel ? renderSliderLabel(sliderValue[0]) : sliderValue[0];
        return <Span>{descriptionLabel ?? label} {descriptionType === 'time' ? 'after' : 'greater than'} <strong>{label0}</strong></Span>
      } else if (utils.isDefined(sliderValue[1])) {
        const label1 = renderSliderLabel ? renderSliderLabel(sliderValue[1]) : sliderValue[1];
        return <Span>{descriptionLabel ?? label} {descriptionType === 'time' ? 'before' : 'less than'} <strong>{label1}</strong></Span>
      }
    } else if (utils.isDefined(sliderValue)) {
      const label = renderSliderLabel ? renderSliderLabel(sliderValue) : sliderValue;
      return <Span>{descriptionLabel ?? label} {descriptionType === 'time' ? 'after' : 'greater than'} <strong>{label}</strong></Span>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSliderField ref={innerRef} {...innerProps}
                            onBlur={handleBlur}>
    <FormLabel size={innerProps.size}
               {...utils.cleanObject({
                 htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                 shrink: innerProps.readOnly || innerProps.disabled || null
               })}
               className={utils.classNames('SliderField-label', InputLabelProps?.className)}
               {...InputLabelProps}>
      {label}
    </FormLabel>
    <InputContainer className="SliderField-container">
      {!innerProps.readOnly ? renderSlider() : null}
      {!innerProps.readOnly && showDescription ? <Box className="SliderField-description">
        {renderDescription(sliderValue)}
      </Box> : null}
      {innerProps.readOnly ? renderReadOnly() : null}
      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('SliderField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledSliderField>
}));

SliderField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.string,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  options: PropTypes.array,
  minDistance: PropTypes.number,
  hiddenPlaceholder: PropTypes.bool,
  showDescription: PropTypes.bool,
  descriptionLabel: PropTypes.any,
  descriptionType: PropTypes.oneOfType([PropTypes.oneOf(['time', 'number']), PropTypes.string]),
  renderSliderLabel: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  SliderProps: PropTypes.object,
  IconProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

SliderField.defaultProps = {
  size: 'medium',
  showDescription: false,
  descriptionType: 'time',
  minDistance: 1,
  defaultThumbs: true
};

export default SliderField;
