import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Divider from 'components/atoms/Dividers/Divider/Divider';
import StyledPatentsTooltip from 'components/molecules/Tooltips/PatentsTooltip/PatentsTooltip.styles';

const PatentsTooltip = React.forwardRef((props, ref) => {
  const {
    activeIndex,
    activateByPayload,
    activePayload,
    payload,
    variant,
    ...innerProps
  } = useComponentProps(props, 'PatentsTooltip', {
    children: ['content']
  });

  const payloads = activateByPayload ? payload?.filter((p) => `${p.dataKey}-${activeIndex}` === activePayload) : payload;

  const renderTitle = () => {
    const pl = payloads?.[0];
    if (pl) {
      const cpc = pl.payload[`${pl.dataKey}-meta`] ?? pl.payload?.['meta'];

      return <Box className="PatentsTooltip-content">
        <Box className="title">
          <Span>{cpc.label}</Span>
          <Badge variant="inline" badgeContent={cpc.countLabel ?? pl.payload[pl.dataKey]}/>
        </Box>
        {cpc.description ? [
          <Divider key="divider" />,
          <Box key="description" className="description">
            <Span>{cpc.description}</Span>
          </Box>
        ]: null}
      </Box>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPatentsTooltip ref={ref} {...innerProps}
                               payload={payload}
                               title={renderTitle()}
                               activeIndex={activeIndex}
                               activateByPayload={activateByPayload}
                               activePayload={activePayload}/>
});

PatentsTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  activeIndex: PropTypes.any,
  activateByPayload: PropTypes.bool,
  activePayload: PropTypes.any,
  payload: PropTypes.array
};

PatentsTooltip.defaultProps = {
  title: 'PatentsTooltip text'
};

export default PatentsTooltip;
