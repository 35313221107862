import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';

const StyledActionIconButton = styled(IconButton)`
  ${ComponentStyles}
`;

StyledActionIconButton.propTypes = {
  theme: PropTypes.object
};

StyledActionIconButton.defaultProps = {
}

export default StyledActionIconButton;
