import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledAnalysisScoreTableCell = styled(BaseTableCell)`
  .AnalysisScoreTableCell {
    &-bar {
      width: 100%;
      height: ${props => props.theme.layout(10)};
      margin-top: ${props => props.theme.layout(1)};
    }
  }
  
  ${ComponentStyles}
`;

StyledAnalysisScoreTableCell.propTypes = {
  theme: PropTypes.object
};

StyledAnalysisScoreTableCell.defaultProps = {
}

export default StyledAnalysisScoreTableCell;
