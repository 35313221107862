import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledClientsSettingPage = styled(Page)`
  ${ComponentStyles}
`;

StyledClientsSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledClientsSettingPage.defaultProps = {}

export default StyledClientsSettingPage;
