import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledCollectionEntityContextWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledCollectionEntityContextWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityContextWrapper.defaultProps = {}

export default StyledCollectionEntityContextWrapper;
