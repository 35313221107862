import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListItemAvatar from 'components/atoms/Avatars/ListItemAvatar/ListItemAvatar.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const ListItemAvatar = withMemo(React.forwardRef((props, ref) => {
  const {
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ListItemAvatar');

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.variant = isLoading ? 'circular' : innerProps.variant;

  return <StyledListItemAvatar ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledListItemAvatar>
}));

ListItemAvatar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ListItemAvatar.defaultProps = {
  children: 'ListItemAvatar text',
};

export default ListItemAvatar;
