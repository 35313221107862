import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useAuthClientId} from 'services/auth/auth.utils';
import TeamsSettingsCard from 'components/organisms/Cards/TeamsSettingsCard/TeamsSettingsCard';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import Cards from 'components/atoms/Cards/Cards/Cards';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import TeamAddDialog from 'components/organisms/Dialogs/TeamAddDialog/TeamAddDialog';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import {useClientTeamCreate} from 'services/client/team/team.utils';
import {useClientTeamList} from 'services/client/team/team.hooks';
import utils from 'helpers/utils';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledTeamsSettingPage from 'components/pages/Setting/TeamsSettingPage/TeamsSettingPage.styles';

const info = 'Add and manage dedicated team environments';

const TeamsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'TeamsSettingPage');

  const authorize = useAuthorize();
  const wrapper = useWrapper();

  const clientId = useAuthClientId();
  const teamList = useClientTeamList({clientId}, {enabled: (clientId >= 0)})
  const teams = teamList.data;

  const teamAdd = useClientTeamCreate();

  const [showTeamAddDialog, setShowTeamAddDialog] = useState(false);

  const action = useMemo(() => ({
    label: 'Add team',
    icon: <Icon icon={Add} />,
    auth: !authorize({attribute: 'settings.teams.update'}) ? utils.createAuth({attribute: 'system.null'}) :
        utils.createAuth({attribute: 'team.create'}),
    onClick: (e) => {
      setShowTeamAddDialog(true);
      e.preventDefault();
    },
    ButtonProps: {
      color: 'success'
    }
  }), [authorize]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const columns = lgUp ? 2 : 1;

  const renderTeamAddDialog = () => {
    if (showTeamAddDialog) {
      const handleSubmit = (team) => {
        return teamAdd(clientId, team)
          .then(() => {
            setShowTeamAddDialog(false);
          });
      }

      const handleClose = () => {
        setShowTeamAddDialog(false);
      };

      return <TeamAddDialog open={true}
                            onSubmit={handleSubmit}
                            onClose={handleClose}/>
    }
  };

  return <StyledTeamsSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                 title="Teams"
                                 info={info}
                                 $wrapper={wrapper}
                                 buttons={<ActionButton action={action} />}>
    <Cards className="TeamsSettingPage-teams"
           columns={columns} gap={24}>
      {teams?.length > 0 ? teams.map((team, idx) => {
        return <CardItem>
          <TeamsSettingsCard className="TeamsSettingPage-teams-team"
                             key={idx}
                             team={team}
                             canUpdate={authorize({attribute: 'settings.teams.update'})} />
        </CardItem>
      }) : null}
    </Cards>
    {renderTeamAddDialog()}
  </StyledTeamsSettingPage>
};

TeamsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TeamsSettingPage.defaultProps = {};

export default TeamsSettingPage;
