import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {matchRoutes, useLocation} from 'react-router-dom';
import {useAuthRenew} from 'services/auth/auth.hooks';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import system from 'helpers/system';
import {
  useAuth, useAuthClientId,
  useAuthHolderToken, useAuthIsNormal, useAuthIsPortal,
  useAuthIsProxy, useAuthIsProxyClient, useAuthIsRegular, useAuthIsVentureIq,
  useAuthTeamId, useAuthToken, useAuthUserId
} from 'services/auth/auth.utils';
import {useRouter} from 'components/organisms/Providers/RouteProvider/RouteProvider';
import {useSystem} from 'components/organisms/Providers/SystemProvider/SystemProvider';
import {useEffectEvent} from 'helpers/hooks/utils';
import {linkPath} from 'helpers/hooks/links';
import {useClientTeamList} from 'services/client/team/team.hooks';
import {useClientUserGet, useClientUserList} from 'services/client/user/user.hooks';
import {useClientGet} from 'services/client/client.hooks';
import {usePortalClientGet} from 'services/portal/client/client.hooks';

export const AuthContext = React.createContext(null);

export function useAuthControl () {
  return React.useContext(AuthContext);
}

export function useAuthClient () {
  return React.useContext(AuthContext).client;
}

export function useAuthUser () {
  return React.useContext(AuthContext).user;
}

export function useAuthGroup () {
  return React.useContext(AuthContext).authGroup;
}

export function useAuthTeam () {
  return React.useContext(AuthContext).team;
}

export function useAuthUsers () {
  return React.useContext(AuthContext).users;
}

export function useAuthTeams () {
  return React.useContext(AuthContext).teams;
}

export function useAuthPasses () {
  return React.useContext(AuthContext).passes;
}

export function useAuthorize () {
  const control = useAuthControl();

  return control.authorize;
}

export function useAuthorizeGroup () {
  const control = useAuthControl();

  return control.authorizeGroup;
}

export function useAuthorizeAttribute () {
  const control = useAuthControl();

  return control.authorizeAttribute;
}

export function useAuthorizeAction () {
  const control = useAuthControl();

  return control.authorizeAction;
}

export function useAuthUserStats () {
  return React.useContext(AuthContext).userStats;
}

const AuthProvider = (props) => {
  const auth = useAuth();
  const userId = useAuthUserId();
  const normal = useAuthIsNormal();
  const portal = useAuthIsPortal();
  const regular = useAuthIsRegular();
  const clientId = useAuthClientId();
  const teamId = useAuthTeamId();
  const ventureIq = useAuthIsVentureIq();
  const proxyClient = useAuthIsProxyClient();
  const proxy = useAuthIsProxy();
  const token = useAuthToken();
  const holderToken = useAuthHolderToken();

  const clientGet = useClientGet({clientId}, {enabled: (clientId >= 0) && normal});
  const clientGetPortal = usePortalClientGet({clientId}, {enabled: (clientId >= 0) && portal});
  const client = useMemo(() => {
    return clientGet?.data ?? clientGetPortal?.data ?? auth?.client;
  }, [clientGet?.data, clientGetPortal?.data, auth?.client]);

  const userGet = useClientUserGet({clientId, userId}, {enabled: Boolean(normal && userId > 0)});
  const user = useMemo(() => {
    return userGet?.data ?? auth?.user;
  }, [userGet?.data, auth?.user]);

  const authGroup = useMemo(() => {
    const jwt = utils.extractToken(token);
    return userGet?.data?.authGroup ?? jwt?.authGroup
  }, [userGet?.data, token]);

  const holderAuthGroup = useMemo(() => {
    const jwt = utils.extractToken(holderToken);
    return jwt?.authGroup
  }, [holderToken]);
  
  const passes = useMemo(() => {
    return (user?.passes ?? auth?.passes ?? []).map((pass) => {
      return {
        client: utils.clone(utils.camelcase(pass.client), true),
        user: utils.clone(utils.camelcase(pass.profile), true),
      }
    }).sort((p1, p2) => {
      return p1.client.name.localeCompare(p2.client.name);
    })
  }, [user?.passes, auth?.passes]);

  const usersList = useClientUserList({clientId}, {enabled: normal && (clientId >= 0)});
  const users = useMemo(() => {
    return usersList?.data ?? [];
  }, [usersList?.data]);

  const teamsList = useClientTeamList({clientId}, {enabled: normal && (clientId >= 0)})
  const teams = useMemo(() => {
    return teamsList?.data ?? auth?.teams ?? [];
  }, [teamsList?.data, auth?.teams]);
  
  const team = useMemo(() => {
    return teams?.find((team) => +team.clientId === +teamId);
  }, [teams, teamId]);
  
  const router = useRouter();
  const location = useLocation();

  const [suspended, setSuspended] = useState(false);

  const doRenew = useAuthRenew();
  const doHolderRenew = useAuthRenew({overrideApiToken: holderToken});

  const systemInfo = useSystem();

  const atLeastLevel = useCallback((userGroup, group) => {
    let requiredLevel = constants.auth.level[utils.camelcase(group)];
    let actualLevel = constants.auth.level[utils.camelcase(userGroup || constants.auth.level.all)];

    if (utils.isNumber(requiredLevel) && utils.isNumber(actualLevel)) {
      return actualLevel >= requiredLevel;
    } else {
      return false;
    }
  }, []);

  const atMostLevel = useCallback((userGroup, group) => {
    let requiredLevel = constants.auth.level[utils.camelcase(group)];
    let actualLevel = constants.auth.level[utils.camelcase(userGroup || constants.auth.level.all)];

    if (utils.isNumber(requiredLevel) && utils.isNumber(actualLevel)) {
      return actualLevel <= requiredLevel;
    } else {
      return false;
    }
  }, []);

  const userStats = useMemo(() => {
    const stats = {};

    (users ?? []).forEach((u) => {
      Object.keys(constants.auth.group).forEach((k) => {
        const authGroup = constants.auth.group[k];

        Object.keys(constants.user.types).forEach((t) => {
          const type = constants.user.types[t];

          stats[type] = stats[type] ?? {};
          stats[type].atLeastLevel = stats[type].atLeastLevel ?? {};
          stats[type][authGroup] = stats[type][authGroup] ?? 0;
          stats[type].atLeastLevel[authGroup] = stats[type].atLeastLevel[authGroup] ?? 0;
        });

        if (u.authGroup === authGroup) {
          stats[u.type][authGroup] += 1;
        }
        if (atLeastLevel(u.authGroup, authGroup)) {
          stats[u.type].atLeastLevel[authGroup] += 1;
        }
      });
    });

    return stats;
  }, [users, atLeastLevel]);

  const authorizeGroup = useCallback((group) => {
    return atLeastLevel(authGroup, group);
  }, [authGroup, atLeastLevel]);

  const authorizeAttribute = useCallback((attribute, meta) => {
    const hasRight = (attribute, meta) => {
      const path = (attribute || '').split('.');

      let metaClient, metaCollection, metaTeam, metaUser,
        metaEntity, metaTask, metaComment, metaFile, metaField,
        metaSource, metaService, metaPlan, metaCollaborator;
      let field, filter, column, section, value;

      switch (path[0]) {
        case 'client': {
          metaClient = meta?.client;
          metaPlan = meta?.plan;
          metaService = meta?.service;

          if (utils.isArray(metaClient)) {
            return !metaClient.some((client) => !hasRight(attribute, {...meta, client}));
          }

          switch (path[1]) {
            case 'route':
            case 'read':
              return !metaClient ||
                (metaClient.clientId === clientId && atLeastLevel(authGroup, constants.auth.group.commentOnly)) ||
                (metaClient.clientId !== clientId && ventureIq && atLeastLevel(authGroup, constants.auth.group.user));
            case 'switch':
              return regular ? (passes.length > 0) :
                (proxy && (+(metaClient ?? meta)?.clientId === clientId || +(metaClient ?? meta)?.clientId === 0));
            case 'update':
              return (metaClient?.clientId === clientId && atLeastLevel(authGroup, constants.auth.group.clientAdmin)) ||
                (metaClient?.clientId !== clientId && ventureIq && atLeastLevel(authGroup, constants.auth.group.admin));
            case 'create':
            case 'delete':
              return ventureIq && atLeastLevel(authGroup, constants.auth.group.admin);
            case 'plan':
              switch (path[2]) {
                case 'read':
                case 'upgrade':
                case 'downgrade':
                  return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return !metaPlan ||
                    atLeastLevel(holderAuthGroup, constants.auth.group.admin);
              }
            case 'service':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return !metaService ||
                    !constants.data.lookup('services', metaService.serviceId).readOnly;
              }
            case 'statusGroup':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
              }
            case 'tagGroup':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
              }
            case 'field':
              field = `${path[2]}.${path[3]}`;
              value = path[4];
              switch (field) {
                default:
                  return true;
              }
            case 'filter':
              filter = path.slice(2).join('.');
              switch (filter) {
                default:
                  return true;
              }
            case 'column':
              column = path.slice(2).join('.');
              switch (column) {
                case 'name.update':
                  return false;
                default:
                  return hasRight(`client.field.${column}`, meta);
              }
            case 'entity':
              switch (path[2]) {
                case 'list':
                case 'kanban':
                case 'route':
                  return hasRight('entity.read', meta);
                case 'create':
                  return hasRight('entity.create', meta);
                case 'update':
                  return hasRight('entity.update', meta);
                case 'delete':
                  return hasRight('entity.delete', meta);
                case 'download':
                  return hasRight(`entity.download.${path.slice(3).join('.')}`, meta);
                case 'graph':
                  switch (path[3]) {
                    case 'route':
                    case 'list':
                      return true;
                    case 'breakdown':
                    case 'monthlyBreakdown':
                    case 'development':
                    case 'rundown':
                    case 'tasks':
                    case 'openTasks':
                      return true;
                    default:
                      return false;
                  }
                default:
                  return hasRight(`entity.${path.slice(2).join('.')}`, meta);
              }
            case 'profile':
              switch (path[2]) {
                case 'read':
                case 'route':
                  return hasRight('collection.read', meta);
                default:
                  return false;
              }
            case 'graph':
              switch (path[2]) {
                case 'route':
                case 'list':
                case 'read':
                  return hasRight('collection.read', meta);
                default:
                  return false;
              }
            case 'task':
              return hasRight(`task.${path.slice(2).join('.')}`, meta);
            case 'user':
              return hasRight(`user.${path.slice(2).join('.')}`, meta);
            case 'notifications':
              return true;
            default:
              return false;
          }
        }
        case 'team': {
          metaTeam = meta?.team;
          metaUser = meta?.user;

          if (utils.isArray(metaTeam)) {
            return !metaTeam.some((team) => !hasRight(attribute, {...meta, team}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return !utils.isDefined(client?.props) ? true : (client?.props?.hasTeams || client?.props?.hasPrivateTeams);
            case 'switch':
              return !metaTeam ?
                (teams.filter((t) => !t.props.isPrivate || t.isMember).length > 1) :
                (!metaTeam?.props?.isPrivate || metaTeam?.isMember || metaTeam?.members?.find?.((m) => +m.userId === +userId))
            case 'update':
            case 'create':
              return !ventureIq && (client?.props?.hasTeams || client?.props?.hasPrivateTeams) &&
                atLeastLevel(authGroup, constants.auth.group.clientAdmin);
            case 'delete':
              return !ventureIq && (client?.props?.hasTeams || client?.props?.hasPrivateTeams) &&
                atLeastLevel(authGroup, constants.auth.group.clientAdmin) && (
                (metaTeam?.props?.isPrivate || teams.filter((t) => !t.props.isPrivate).length > 1) &&
                (+metaTeam?.teamId !== +teamId)
              );
            case 'member':
              switch (path[2]) {
                case 'read':
                case 'list':
                  return hasRight('team.read', meta);
                case 'delete':
                  return !ventureIq && hasRight('team.update', meta) && !metaUser?.isDealLeader &&
                    (proxy || metaUser?.profile?.type !== constants.user.types.proxy) &&
                    (metaTeam?.props?.isPrivate || (metaUser?.openTeamCount > 1 || metaUser?.profile?.type === constants.user.types.proxy)) &&
                    !(metaTeam?.props?.isPrivate && +metaUser?.userId === +userId && +metaTeam?.teamId === +teamId);
                case 'create':
                  return !ventureIq && hasRight('team.update', meta);
                default:
                  return false;
              }
            case 'task':
              return hasRight(`task.${path.slice(2).join('.')}`, meta);
            case 'comment':
              return hasRight(`comment.${path.slice(2).join('.')}`, meta);
            default:
              return false;
          }
        }
        case 'collection':
          metaCollection = meta?.collection;
          metaEntity = meta?.entity;
          metaSource = meta?.source;
          metaService = meta?.service;

          if (utils.isArray(metaCollection)) {
            return !metaCollection.some((collection) => !hasRight(attribute, {...meta, collection}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'create':
            case 'clone':
            case 'merge':
            case 'update':
            case 'delete':
              if (!metaCollection) {
                return atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
              } else {
                if (utils.isDefined(metaCollection.universeClientId)) {
                  return path[1] === 'update' && atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                } else {
                  return (
                    ( /* all non public collections */
                      metaCollection.visibility !== 'public' &&
                      clientId === metaCollection.ownerClientId &&
                      atLeastLevel(authGroup, constants.auth.group.client) && (
                        (
                          metaCollection.visibility !== 'client' &&
                          +userId === +metaCollection.ownerUserId
                        ) ||
                        (
                          metaCollection.visibility === 'client' && (
                            ventureIq || +teamId === +metaCollection.teamId
                          )
                        )
                      )
                    ) ||
                    ( /* all public collections only viq */
                      metaCollection.visibility === 'public' &&
                      ventureIq && atLeastLevel(authGroup, constants.auth.group.user)
                    )
                  );
                }
              }
            case 'settings':
            case 'filters':
            case 'columns':
            case 'tasks':
            case 'notifications':
              return true;
            case 'suggestions':
            case 'rejections':
              return atLeastLevel(authGroup, constants.auth.group.client)
            case 'task':
              return hasRight(`task.${path.slice(2).join('.')}`, meta);
            case 'source':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                  return hasRight('collection.update', meta) && (
                    !metaSource || (
                      client?.props?.canScrapeWebsites
                    ) ||
                    [constants.sources.types.suggestions,
                      constants.sources.types.database].includes(metaSource.value)
                  )
                case 'update':
                case 'delete':
                default:
                  return hasRight('collection.update', meta) && (
                    !metaSource || client?.props?.canScrapeWebsites
                  );
              }
            case 'service':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly) && (
                    !metaCollection ||
                    (utils.isDefined(metaCollection.universeClientId) && constants.data.lookup('services', metaService.serviceId).client) ||
                    (!utils.isDefined(metaCollection.universeClientId) && constants.data.lookup('services', metaService.serviceId).client)
                  );
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return hasRight('collection.update', meta) && (
                    !metaService ||
                    !constants.data.lookup('services', metaService.serviceId).readOnly
                  )
              }
            case 'tagGroup':
              switch (path[2]) {
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return hasRight('collection.update', meta);
              }
            case 'wizard':
              return hasRight(`collection.section.${path.slice(2).join('.')}`, meta);
            case 'section':
              section = `${path[2]}.${path[3]}`;
              switch (section) {
                case 'basics.read':
                case 'services.read':
                case 'sources.read':
                case 'categories.read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly)
                case 'basics.create':
                case 'basics.update':
                case 'categories.create':
                case 'categories.update':
                case 'sources.create':
                case 'sources.update':
                case 'services.create':
                case 'services.update':
                  return hasRight('collection.update', meta) && atLeastLevel(authGroup, constants.auth.group.client);
                default:
                  return hasRight(`collection.field.${section}`, meta);
              }
            case 'field':
              field = `${path[2]}.${path[3]}`;
              value = path[4];
              switch (field) {
                case 'visibility.update':
                  switch (value) {
                    case constants.collection.visibility.public:
                      return ventureIq && hasRight('collection.field.visibility.update', meta);
                    case constants.collection.visibility.user:
                    case constants.collection.visibility.client:
                    default:
                      return (!metaCollection ? true : metaCollection.ownerUserId === userId) &&
                        hasRight('collection.update', meta) && (
                          metaCollection?.visibility === constants.collection.visibility.client ||
                          team?.isMember
                        );
                  }
                default:
                  return true;
              }
            case 'filter':
              filter = path.slice(2).join('.');
              switch (filter) {
                default:
                  return true;
              }
            case 'column':
              column = path.slice(2).join('.');
              switch (column) {
                case 'name.update':
                  return false;
                case 'action.read':
                  return hasRight(`collection.update`, meta);
                default:
                  return hasRight(`collection.field.${column}`, meta);
              }
            case 'entity':
              switch (path[2]) {
                case 'list':
                case 'kanban':
                case 'route':
                  return hasRight('collection.read', meta);
                case 'create':
                  return hasRight('collection.update', meta) && hasRight('entity.create', meta);
                case 'delete':
                  return hasRight('collection.update', meta) && hasRight('entity.delete', meta);
                case 'download':
                  return hasRight(`entity.download.${path.slice(3).join('.')}`, meta);
                case 'field':
                  field = `${path[3]}.${path[4]}`;
                  switch (field) {
                    case 'collectionTags.update':
                    case 'collectionAnalysisScoreGraph.update':
                      return hasRight('collection.update', meta) && hasRight('entity.update', meta);
                    default:
                      return hasRight(`entity.field.${path.slice(3).join('.')}`, meta);
                  }
                case 'column':
                  return hasRight(`entity.column.${path.slice(3).join('.')}`, meta) &&
                    hasRight(`collection.entity.field.${path.slice(3).join('.')}`, meta);
                case 'graph':
                  switch (path[3]) {
                    case 'route':
                    case 'list':
                      return hasRight('collection.read', meta);
                    case 'dealflowBreakdown':
                    case 'completeness':
                    case 'recentChanges':
                    case 'tagDistribution':
                    case 'dealflowStatus':
                    case 'relevancyDistribution':
                    case 'tasks':
                    case 'openTasks':
                      return true;
                    default:
                      return false;
                  }
                default:
                  return hasRight(`entity.${path.slice(2).join('.')}`, meta);
              }
            case 'profile':
              switch (path[2]) {
                case 'read':
                case 'route':
                  return hasRight('collection.read', meta);
                default:
                  return false;
              }
            case 'graph':
              switch (path[2]) {
                case 'route':
                case 'list':
                  return hasRight('collection.read', meta);
                default:
                  return false;
              }
            default:
              return false;
          }
        case 'database': {
          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly) && (
                !utils.isDefined(client?.props) ? true : client?.props?.hasSearch
              );
            default:
              return hasRight(`client.${path.slice(1).join('.')}`, meta);
          }
        }
        case 'dealflow': {
          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'graph':
              switch (path[2]) {
                case 'route':
                case 'list':
                  return true;
                default:
                  return false;
              }
            case 'entity':
              switch (path[2]) {
                case 'list':
                case 'kanban':
                case 'route':
                  return hasRight('dealflow.read', meta);
                case 'create':
                  return hasRight('entity.create', meta) && team?.isMember;
                case 'delete':
                  return hasRight('entity.delete', meta) && team?.isMember;
                default:
                  return hasRight(`client.entity.${path.slice(2).join('.')}`, meta);
              }
            default:
              // pass right to client (client.entity) rights
              return hasRight(`client.${path.slice(1).join('.')}`, meta);
          }
        }
        case 'dashboard': {
          switch (path[1]) {
            case 'route':
              return true;
            case 'tasks':
              return hasRight(`task.${path.slice(2).join('.')}`, meta);
            case 'dealflow':
              return hasRight(`dealflow.${path.slice(2).join('.')}`, meta);
            case 'insights':
            case 'recentCollections':
            case 'themeBreakdown':
            case 'periodUpdate':
              return true;
            default:
              return false;
          }
        }
        case 'settings': {
          switch (path[1]) {
            case 'route':
            case 'list':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'profile':
              switch (path[2]) {
                case 'route':
                case 'read':
                case 'create':
                case 'update':
                case 'delete':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                default:
                  return false;
              }
            case 'dealflow':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                default:
                  return false;
              }
            case 'categories':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                default:
                  return false;
              }
            case 'services':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                  return (ventureIq && atLeastLevel(authGroup, constants.auth.group.admin)) ||
                    (!ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin));
                default:
                  return false;
              }
            case 'teams':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return !ventureIq && (client?.props?.hasTeams || client?.props?.hasPrivateTeams) &&
                    atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                  return !ventureIq && (client?.props?.hasTeams || client?.props?.hasPrivateTeams) &&
                    atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                default:
                  return false;
              }
            case 'fields':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.commentOnly);
                case 'create':
                case 'update':
                case 'delete':
                  return !ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                default:
                  return false;
              }
            case 'users':
              switch (path[2]) {
                case 'route':
                case 'read':
                case 'create':
                case 'update':
                case 'delete':
                  return (ventureIq && atLeastLevel(authGroup, constants.auth.group.admin)) ||
                    (!ventureIq && client?.props?.hasUserManagement && atLeastLevel(authGroup, constants.auth.group.clientAdmin));
                default:
                  return false;
              }
            case 'credits':
              switch (path[2]) {
                case 'route':
                case 'read':
                case 'create':
                case 'update':
                case 'delete':
                  return (!ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin));
                default:
                  return false;
              }
            case 'plan':
              switch (path[2]) {
                case 'route':
                case 'read':
                case 'create':
                case 'update':
                case 'delete':
                  return (!ventureIq && atLeastLevel(authGroup, constants.auth.group.clientAdmin));
                default:
                  return false;
              }
            case 'clients':
              switch (path[2]) {
                case 'route':
                case 'read':
                  return ventureIq && atLeastLevel(authGroup, constants.auth.group.user);
                case 'create':
                case 'update':
                case 'delete':
                  return ventureIq && atLeastLevel(authGroup, constants.auth.group.admin);
                default:
                  return false;
              }
            case 'client': {
              switch (path[2]) {
                case 'route':
                case 'read':
                  return hasRight('settings.clients.read', meta) &&
                    hasRight('client.read', meta);
                default:
                  return false;
              }
            }
            default:
              return true;
          }
        }
        case 'search': {
          switch (path[1]) {
            case 'list':
            case 'entity':
            case 'collection':
              return true;
            case 'user':
              return false; // disabled for now
            default:
              return false;
          }
        }
        case 'collaborator': {
          metaCollaborator = meta?.collaborator;

          if (utils.isArray(metaCollaborator)) {
            return !metaCollaborator.some((collaborator) => !hasRight(attribute, {...meta, collaborator}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.user);
            case 'create':
            case 'update':
              return ventureIq && atLeastLevel(authGroup, constants.auth.group.admin);
            case 'delete':
              return ventureIq && atLeastLevel(authGroup, constants.auth.group.admin) &&
                hasRight('user.delete', {...meta, user: metaCollaborator});
            case 'column':
            case 'field':
              return hasRight(`user.${path.slice(1).join('.')}`, {...meta, user: metaCollaborator});
            default:
              return false;
          }
        }
        case 'task': {
          metaTask = meta?.task;

          if (utils.isArray(metaTask)) {
            return !metaTask.some((task) => !hasRight(attribute, {...meta, task}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'create':
              return atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
            case 'update':
            case 'delete':
              return (!metaTask || (+metaTask.userId === +userId && !constants.data.lookup('taskTypes', metaTask?.actionItem?.type)?.system)) &&
                atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
            case 'markDone':
              return (
                !metaTask || (
                  !metaTask.actionItem?.responsible || metaTask.actionItem?.responsible.length === 0 ||
                  metaTask.actionItem?.responsible.find((r) => +r.userId === +userId)
                )
              ) && atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember
            case 'field':
              field = `${path[2]}.${path[3]}`;
              switch (field) {
                case 'type.update':
                  return false;
                default:
                  return true;
              }
            case 'section':
              section = `${path[2]}.${path[3]}`;
              switch (section) {
                default:
                  return hasRight(`task.field.${section}`, meta);
              }
            default:
              return false;
          }
        }
        case 'file': {
          metaFile = meta?.file;

          if (utils.isArray(metaFile)) {
            return !metaFile.some((file) => !hasRight(attribute, {...meta, file}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'create':
            case 'update':
            case 'delete':
              return atLeastLevel(authGroup, constants.auth.group.client);
            default:
              return false;
          }
        }
        case 'comment': {
          metaComment = meta?.comment;

          if (utils.isArray(metaComment)) {
            return !metaComment.some((comment) => !hasRight(attribute, {...meta, comment}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'create':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly) && team?.isMember;
            case 'update':
            case 'delete':
              return (!metaComment || (+metaComment.userId === +userId || atLeastLevel(authGroup, constants.auth.group.clientAdmin))) &&
                atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
            default:
              return false;
          }
        }
        case 'entity': {
          metaEntity = meta?.entity;

          if (utils.isArray(metaEntity)) {
            return !metaEntity.some((entity) => !hasRight(attribute, {...meta, entity}));
          }

          switch (path[1]) {
            case 'list':
            case 'read':
              return true;
            case 'create':
              return atLeastLevel(authGroup, constants.auth.group.client);
            case 'update':
              return atLeastLevel(authGroup, constants.auth.group.client);
            case 'delete':
              return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
            case 'enrich':
              return atLeastLevel(authGroup, constants.auth.group.client);
            case 'merge':
              switch (path[2]) {
                case 'force':
                  return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
                default:
                  return atLeastLevel(authGroup, constants.auth.group.client);
              }
            case 'download':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'profile':
            case 'browser':
              switch (path[2]) {
                case 'read':
                case 'route':
                  return hasRight('entity.read', meta);
                default:
                  return false;
              }
            case 'field':
              field = `${path[2]}.${path[3]}`;
              switch (field) {
                case 'systemTags.read':
                  return !ventureIq;
                case 'systemTags.update':
                  return false;
                case 'personalRelevancy.read':
                case 'personalRelevancy.update':
                  return !proxy && atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
                case 'teamRelevancy.update':
                  return false;
                case 'externalRelevancy.read':
                  return proxyClient;
                case 'externalRelevancy.update':
                  return proxy;
                case 'dealflowStatus.update':
                  return atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
                case 'dealLeader.update':
                  return atLeastLevel(authGroup, constants.auth.group.client) && team?.isMember;
                case 'timeline.update':
                  return hasRight('comment.create', meta);
                case 'patentGraph.read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly) && client?.props?.hasPatents;
                case 'tractionGraph.read':
                case 'tractionFte3M.read':
                case 'tractionFte6M.read':
                case 'tractionFte1Y.read':
                case 'tractionFte3Y.read':
                case 'tractionFunding3M.read':
                case 'tractionFunding6M.read':
                case 'tractionFunding1Y.read':
                case 'tractionFunding3Y.read':
                  return atLeastLevel(authGroup, constants.auth.group.commentOnly) && client?.props?.hasTraction;
                default:
                  return true;
              }
            case 'filter':
              filter = path.slice(2).join('.');
              switch (filter) {
                case 'personalRelevancy.read':
                  return !proxy && atLeastLevel(authGroup, constants.auth.group.client);
                case 'externalRelevancy.read':
                  return proxyClient;
                default:
                  return true;
              }
            case 'column':
              column = path.slice(2).join('.');
              switch (column) {
                case 'name.update':
                  return false;
                default:
                  return hasRight(`entity.field.${column}`, meta);
              }
            case 'section':
              section = `${path[2]}.${path[3]}`;
              switch (section) {
                case 'files.read':
                case 'tasks.read':
                case 'tasks.update':
                  return hasRight(`task.${path[3]}`, meta);
                case 'files.update':
                  return atLeastLevel(authGroup, constants.auth.group.client);
                case 'personalRelevancy.read':
                  return !proxy && atLeastLevel(authGroup, constants.auth.group.client);
                case 'externalRelevancy.read':
                  return proxy;
                default:
                  return hasRight(`entity.field.${section}`, meta);
              }
            case 'task':
              return hasRight(`task.${path.slice(2).join('.')}`, meta);
            case 'file':
              return hasRight(`file.${path.slice(2).join('.')}`, meta);
            case 'columns':
              return true;
            case 'filters':
              return true;
            case 'search':
              switch (path[2]) {
                case 'create':
                case 'update':
                case 'delete':
                default:
                  return client?.props?.hasSearch;
              }
            default:
              return false;
          }
        }
        case 'user': {
          metaUser = meta?.user;

          if (utils.isArray(metaUser)) {
            return !metaUser.some((user) => !hasRight(attribute, {...meta, user}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
              return true;
            case 'read':
              return atLeastLevel(authGroup, constants.auth.group.commentOnly);
            case 'update':
              return !metaUser || (
                ventureIq && (atLeastLevel(authGroup, constants.auth.group.admin) || +metaUser?.userId === +userId)
              ) || (
                !ventureIq && (
                  (client?.props?.hasUserManagement && atLeastLevel(authGroup, constants.auth.group.clientAdmin)) ||
                  +metaUser?.userId === +userId
                )
              );
            case 'create':
            case 'delete':
              return !metaUser || (
                ventureIq && atLeastLevel(authGroup, constants.auth.group.admin) && +metaUser?.userId !== +userId && !metaUser?.isDealLeader
              ) || (
                !ventureIq && client?.props?.hasUserManagement && atLeastLevel(authGroup, constants.auth.group.clientAdmin) &&
                +metaUser?.userId !== +userId && !metaUser?.isDealLeader &&
                (proxy || metaUser?.profile?.type !== constants.user.types.proxy)
              );
            case 'column':
              column = path.slice(2).join('.');
              switch (column) {
                default:
                  return hasRight(`user.field.${column}`, meta);
              }
            case 'field':
              field = `${path[2]}.${path[3]}`;
              value = path[4];
              switch (field) {
                case 'authGroup.create':
                case 'authGroup.update':
                  return !utils.isDefined(value) ? true : (
                    (
                      (ventureIq && metaUser?.type !== constants.user.types.proxy) &&
                      atLeastLevel(value, constants.auth.group.user) && atLeastLevel(authGroup, value)
                    ) || (
                      !(ventureIq && metaUser?.type !== constants.user.types.proxy) &&
                      atLeastLevel(value, constants.auth.group.commentOnly) && atMostLevel(value, constants.auth.group.clientAdmin) &&
                      atLeastLevel(authGroup, value) && (
                        (value === constants.auth.group.commentOnly) ||
                        ( // current user === higher than comment only
                          metaUser &&
                          (metaUser.type !== constants.user.types.regular ||
                           metaUser.authGroup !== constants.auth.group.commentOnly)
                        ) ||
                        (userStats?.regular?.atLeastLevel?.[constants.auth.group.client] < client?.props?.userLimit)
                      )
                    )
                  );
                case 'active.update':
                  return !metaUser || ventureIq || metaUser?.type === constants.user.types.proxy ||
                    metaUser.active || metaUser.authGroup === constants.auth.group.commentOnly ||
                    (userStats?.regular?.atLeastLevel?.[constants.auth.group.client] < client?.props?.userLimit);
                case 'dataToken.read':
                case 'dataToken.update':
                  return client?.props?.hasLiveData;
                case 'portalToken.read':
                case 'portalToken.update':
                  return client?.props?.hasPortal;
                default:
                  return true;
              }
            default:
              return false;
          }
        }
        case 'field': {
          metaField = meta?.field;

          if (utils.isArray(metaField)) {
            return !metaField.some((field) => !hasRight(attribute, {...meta, field}));
          }

          switch (path[1]) {
            case 'route':
            case 'list':
            case 'read':
              return true;
            case 'create':
            case 'update':
            case 'delete':
              return atLeastLevel(authGroup, constants.auth.group.clientAdmin);
            case 'column':
              column = path.slice(2).join('.');
              switch (column) {
                default:
                  return hasRight(`field.field.${column}`, meta);
              }
            case 'field':
              return true;
            default:
              return false;
          }
        }
        case 'portal':
          switch (path[1]) {
            case 'route':
              return false;
            default:
              return false;
          }
        case 'dev':
          switch (path[1]) {
            case 'route':
              return false;
            default:
              return system.appBuild() === 'development';
          }
        case 'test':
          switch (path[1]) {
            default:
              return !system.isProduction();
          }
        case 'system':
          switch (path[1]) {
            case 'null':
              return false;
            default:
              return false;
          }
        default:
          return !path;
      }
    }

    return hasRight(attribute, meta);
  }, [authGroup, holderAuthGroup, userId, clientId, teamId, passes, teams, team?.isMember, userStats,
    regular, proxy, ventureIq, proxyClient, client?.props, atLeastLevel, atMostLevel]);

  const authorize = useCallback((context) => {
    const {group, attribute, meta, mustLogin = true} = utils.createAuth(context);
    const userValid = Boolean(normal || meta?.any || (portal && meta?.portal));
    const groupValid = authorizeGroup(group);
    const attributeValid = authorizeAttribute(attribute, meta);

    if (!mustLogin) {
      return true;
    } else if (auth?.loggedIn) {
      let valid = userValid;
      if (group) {
        // check group Admin etc.
        valid = valid && groupValid;
      }
      if (attribute) {
        valid = valid && attributeValid;
      }

      return valid;
    } else {
      return false;
    }
  }, [auth?.loggedIn, normal, portal, authorizeGroup, authorizeAttribute]);

  const authorizeAction = useCallback((action) => {
    const path = linkPath(action?.navigation, location);

    let authContext = utils.createAuth();
    if (action?.auth) {
      authContext = utils.isFunction(action?.auth) ? action?.auth() : {...action.auth};
      authContext.meta = utils.isFunction(authContext.meta) ? authContext.meta() : authContext.meta;
    } else if (action?.navigation) {
      const matches = matchRoutes(router.routes, path);
      const match = matches?.find((m) => {
        return utils.comparePath(m.pathname, path.pathname) &&
          utils.isDefined(m.route?.handle?.auth);
      });

      if (match?.route?.handle?.auth) {
        authContext = {...match.route.handle.auth};
        authContext.meta = utils.isFunction(authContext.meta) ? authContext.meta(match) :
          (authContext.meta ?? {...match?.params});
      }
    }

    const valid = authorize(authContext);

    if (action && valid) {
      return utils.mergeObjects({
        label: 'name',
        tooltip: null,
        auth: null,
        navigation: null,
        icon: null,
        iconPosition: null,
        children: []
      }, action);
    } else {
      return null;
    }
  }, [authorize, location, router]);

  const context = useMemo(() => ({
    suspended,
    setSuspended,
    client,
    user,
    team,
    authGroup,
    holderAuthGroup,
    teams,
    passes,
    users,
    userStats,
    authorize,
    authorizeGroup,
    authorizeAttribute,
    authorizeAction
  }), [suspended, user, client, authGroup, holderAuthGroup, team, users, userStats, teams, passes,
    authorize, authorizeGroup, authorizeAttribute, authorizeAction]);

  const systemInfoEvent = useEffectEvent(systemInfo.info);
  useEffect(() => {
    if (auth?.loggedIn) {
      // user has been active within the refreshTime interval but is inactive for at least 1 min
      return utils.observeInterval(() => {
        if (!systemInfoEvent?.().userIsActive &&
            (systemInfoEvent?.().lastActivity?.getTime() >= (Date.now() - system.tokenRefreshTime()))) {

          // token has to be older than the refreshInterval couple of hours
          if (systemInfoEvent?.().tokenRenewTime) {
            if (systemInfoEvent?.().tokenRenewTime.getTime() < (Date.now() - system.tokenRefreshInterval())) {
              setSuspended(true);
              Promise.all([
                doRenew.mutation.mutateAsync({refresh: true}),
                proxy ? doHolderRenew.mutation.mutateAsync({refresh: true}) : null
              ])
                .catch(() => {
                  /* SQUASH */
                })
                .finally(() => {
                  systemInfoEvent?.({tokenRenewTime: new Date()});
                  setSuspended(false);
                });
            }
          } else {
            systemInfoEvent?.({tokenRenewTime: new Date()});
          }
        }
      }, constants.delay.token);
    }
  }, [doRenew.mutation, doHolderRenew.mutation, auth?.loggedIn, proxy, systemInfoEvent]);

  return <AuthContext.Provider value={context}>
    {props.children}
  </AuthContext.Provider>
};

AuthProvider.propTypes = {
}

export default AuthProvider;
