import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTable from 'components/organisms/Tables/EntitiesTable/EntitiesTable';

const StyledDealflowEntitiesTable = styled(EntitiesTable)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesTable.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesTable.defaultProps = {
};

export default StyledDealflowEntitiesTable;
