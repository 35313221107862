import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFormControlLabel from 'components/atoms/Labels/FormControlLabel/FormControLabel.styles';
import {withMemo} from 'helpers/wrapper';

const FormControlLabel = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'FormControlLabel');

  return <StyledFormControlLabel ref={ref} {...innerProps}>{innerProps.children}</StyledFormControlLabel>
}));

FormControlLabel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FormControlLabel.defaultProps = {
  children: 'FormControlLabel text'
};

export default FormControlLabel;
