import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItemText from '@mui/material/ListItemText';

const StyledListItemText = styled(ListItemText)`
  ${props => props.$nowrap ? `
    display: flex;
    
    > span {
      white-space: nowrap;
      width: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }` : ''}
  
  ${ComponentStyles}
`;

StyledListItemText.propTypes = {
  theme: PropTypes.object,
  $nowrap: PropTypes.bool
};

StyledListItemText.defaultProps = {
}

export default StyledListItemText;
