import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import AreaChart from 'components/organisms/Charts/AreaChart/AreaChart';
import StyledTractionAreaChart from 'components/organisms/Charts/TractionAreaChart/TractionAreaChart.styles';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const TractionAreaChart = React.forwardRef((props, ref) => {
  const {
    traction,
    period,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    AreaChartProps,
    ...innerProps
  } = useComponentProps(props, 'TractionAreaChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const tractionMemo = useMemo(() => {
    if (traction && period) {
      const from = new Date(`${(new Date()).toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`);
      from.setMonth(from.getMonth() - (period.months - 1));
      const to = new Date(`${(new Date()).toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`);

      const points = traction.map((tp) => ({
        ...tp,
        date: new Date(`${(new Date(tp.date)).toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`)
      })).sort((a, b) => a.date.getTime() - b.date.getTime());

      const history = [];
      const month = new Date(from);
      for (let m = 0; m < period.months; m++) {
        month.setMonth(month.getMonth() + (m > 0 ? 1 : 0));

        const tp = points.find((tp) => tp.date.getTime() === month.getTime()) ??
          points.findLast((tp) => tp.date.getTime() < month.getTime());

        if (tp) {
          history.push({...tp, date: new Date(month)});
        } else {
          history.push({value: 0, date: new Date(month)});
        }
      }

      return history.filter((tp) => {
        return tp.date.getTime() >= from.getTime() && tp.date.getTime() <= to.getTime()
      });
    } else {
      return null
    }
  }, [traction, period]);

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (tractionMemo) {
      legend = [{
        id: period.value,
        position: 1,
        label: period.label,
        color: innerProps.theme.property(`palette.${period.color}.main`),
        active: visibility?.[period.value] ?? true
      }];
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [tractionMemo, visibility, period, innerProps.theme]);

  const tractionData = useMemo(() => {
    if (tractionMemo) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        areas: [{
          id: period.value,
          position: 1,
          dataKey: period.value,
          label: period.label,
          color: innerProps.theme.property(`palette.${period.color}.main`),
          AreaProps: {
            AreaChartDotProps: {
              r: 1
            }
          }
        }]
        .filter((g) => {
          return Boolean(legend.find((l) => l.active && l.id === g.id))
        }),
        data: tractionMemo
          .reduce((a, tp, idx) => {
            const name = utils.dayjs(tp.date).format('MMM \'YY');

            let item = a.find((i) => i.name === name);
            if (!item) {
              item = {
                name: name,
                position: idx
              }
              a.push(item);
            }
            item[period.value] = +tp.value;
            item[`${period.value}-meta`] = {
              label: `${name}`,
              countLabel: `${utils.numberToLabel(tp.value, 1)}`,
              tp: tp
            }
            return a;
          }, []),
        TooltipComponent: BreakdownTooltip,
        XAxisProps: {
          tickSize: 4,
          minTickGap: 32,
          interval: 'preserveStartEnd',
          tick: (props) => {
            return <text className="recharts-cartesian-axis-tick-value"
                         orientation={props.orientation}
                         width={props.width} height={props.height}
                         x={props.x} y={props.y}
                         textAnchor={props.payload.index === 0 ? 'start' : (
                           props.payload.index === tractionMemo.length - 1 ? 'end' : 'middle'
                         )}>
              <tspan x={props.x} dy="0.71em">{props.payload.value}</tspan>
            </text>
          }
        },
        YAxisProps: {
          tickSize: 4,
          orientation: 'right',
          tickFormatter: (v) => {
            return `${utils.numberToLabel(v)}`;
          }
        },
        GridProps: {
          strokeDasharray: '0 0',
          vertical: false
        }
      };
    }
  }, [tractionMemo, legend, period, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTractionAreaChart ref={ref} {...innerProps}>
    <Box className="TractionAreaChart-chart">
      <AreaChart {...tractionData}
                 showGrid
                 showTooltip
                 isLoading={isLoading}
                 layout="horizontal"
                 hoveredId={internalState.hoveredId}
                 onClick={onClick}
                 TooltipProps={{
                   density: 'dense'
                 }}
                 margin={{
                   top: 8, right: -22, bottom: -8, left: 32
                 }}
                 {...AreaChartProps}/>
    </Box>
    {showLegend ? <Legend className="TractionAreaChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          variant="area"
                          rows={rows}/> : null}
  </StyledTractionAreaChart>
});

TractionAreaChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  traction: PropTypes.array,
  period: PropTypes.object,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  AreaChartProps: PropTypes.object
};

TractionAreaChart.defaultProps = {
};

export default TractionAreaChart;
