import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledStep from 'components/atoms/Steppers/Step/Step.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Step = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Step', {
    static: ['active']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledStep ref={ref} {...innerProps} />
}));

Step.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Step.defaultProps = {};

export default Step;
