import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntityBrowserPage
  from 'components/pages/Dealflow/Entity/DealflowEntityBrowserPage/DealflowEntityBrowserPage.styles';
import EntitiesBrowserList from 'components/organisms/Browsers/EntitiesBrowserList/EntitiesBrowserList';

const DealflowEntityBrowserPage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntityBrowserPage');

  return <StyledDealflowEntityBrowserPage {...innerProps}>
    <EntitiesBrowserList />
  </StyledDealflowEntityBrowserPage>
};

DealflowEntityBrowserPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityBrowserPage.defaultProps = {
};

export default DealflowEntityBrowserPage;
