import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledCollectionEntitiesBarWrapper = styled(ToolbarWrapper)`
  .CollectionEntitiesBarWrapper {
    &-title {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: ${props => props.theme.layout('1sp')};
      
      > h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  ${ComponentStyles}
`;

StyledCollectionEntitiesBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesBarWrapper.defaultProps = {
};

export default StyledCollectionEntitiesBarWrapper;
