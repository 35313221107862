import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionSectionPanelHeader = styled(Box)`
  background-color: ${props => props.theme.property(`palette.${props.$color}.states.hover`)};
  
  .CollectionSectionPanelHeader {
    &-head {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)} 0 ${props => props.theme.spacing(3)};
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};
    }

    &-title {
      flex-grow: 1;
      width: 1px;
      
      > * {
        max-width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    &-buttons {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
    
    &-info {
      margin: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(3)} 0 ${props => props.theme.spacing(3)};
    }
  }

  ${ComponentStyles}
`;

StyledCollectionSectionPanelHeader.propTypes = {
  theme: PropTypes.object
};

StyledCollectionSectionPanelHeader.defaultProps = {}

export default StyledCollectionSectionPanelHeader;
