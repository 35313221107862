import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledAdvancedQueryPart = styled(Box)`
  width: 100%;
  
  .AdvancedQueryPart {
    &-parts {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};
      align-items: flex-start;
      width: 100%;
    }
  }
  
  &:not(.AdvancedQueryPart-depth-0) {
    .AdvancedQueryPart {
      &-parts {
        > *:not(:first-child) {
          margin-left: ${props => props.theme.layout(40)};
          max-width: calc(100% - ${props => props.theme.layout(40)});
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledAdvancedQueryPart.propTypes = {
  theme: PropTypes.object
};

StyledAdvancedQueryPart.defaultProps = {}

export default StyledAdvancedQueryPart;
