import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAutocomplete from 'components/atoms/Inputs/Autocomplete/Autocomplete.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Autocomplete = withMemo(React.forwardRef((props, ref) => {
  const {
    fitContent,
    ...innerProps
  } = useComponentProps(props, 'Autocomplete');

  innerProps.componentsProps = fitContent ? utils.mergeObjects({
    paper: {
      style: {
        minWidth: 'fit-content'
      }
    }
  }, innerProps.componentsProps) : innerProps.componentsProps;

  return <StyledAutocomplete ref={ref}
                             {...innerProps} />
}));

Autocomplete.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fitContent: PropTypes.bool
};

Autocomplete.defaultProps = {
};

export default Autocomplete;
