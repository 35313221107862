import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTestPage2 = styled(Box)`
  ${ComponentStyles}
`;

StyledTestPage2.propTypes = {
  theme: PropTypes.object
};

StyledTestPage2.defaultProps = {
};

export default StyledTestPage2;
