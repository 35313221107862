import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledPage from 'components/pages/Base/Page/Page.styles';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';

const Page = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Page')

  const wrapper = useWrapper();

  return <StyledPage ref={ref} {...innerProps} $wrapper={wrapper}/>
});

Page.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Page.defaultProps = {
};

export default Page;
