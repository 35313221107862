import React from 'react';
import PropTypes from 'prop-types';
import {useDndMonitor} from '@dnd-kit/core';
import {useDnd} from 'components/organisms/Utils/DragDrop/DndContext';

const DndMonitorInner = (props) => {
  useDndMonitor(props);

  return <React.Fragment />
}

const DndMonitor = (props) => {
  const dnd = useDnd();

  if (dnd?.active) {
    return <DndMonitorInner {...props} />
  }
}

DndMonitor.propTypes = {
  placeholder: PropTypes.any,
  timeout: PropTypes.number
};

DndMonitor.defaultProps = {
};

export default DndMonitor;
