import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import PageBar from 'components/organisms/Bars/PageBar/PageBar';

const StyledFooterBar = styled(PageBar)`
  height: ${props => props.theme.layout(64)};
  
  &.FooterBar.PageBar {
    .PageBar {
      &-center {
        &-wrapper {
          width: 100%;
          left: 0;
        }
      }

      &-toolbar {
        padding: 0 ${props => props.theme.spacing(3)};
      }
    }
  }
  
  &.FooterBar-density-dense {
    height: unset;

    .PageBar-toolbar {
      height: unset;
      min-height: unset;
      padding: 0 !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledFooterBar.propTypes = {
  theme: PropTypes.object
};

StyledFooterBar.defaultProps = {
};

export default StyledFooterBar;
