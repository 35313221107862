import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledClientPlanProfileCard from 'components/organisms/Cards/ClientPlanProfileCard/ClientPlanProfileCard.styles';

const ClientPlanProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    plan,
    client,
    isDialog,
    renderedHeader,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'ClientPlanProfileCard', {
    static: ['isReadOnly', 'isEditing', 'isDialog', 'isActive', 'isCustom'],
    children: ['content']
  });

  const innerRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const isCustom = Boolean(plan?.custom);
  const isActive = plan?.planId === client?.props?.plan;

  useImperativeHandle(ref, () => innerRef.current);

  const isReadOnly = !card.card.editable;

  useLayoutEffect(() => {
    const editing = card.state.isEditing ?? card.state.card.isEditing ?? card.state.dialog.isEditing;
    setIsEditing(editing);
  }, [card.state.isEditing, card.state.card.isEditing, card.state.dialog.isEditing, card.state.card.submitSuccess]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isReadOnly: isReadOnly,
    isEditing: isEditing,
    isActive: isActive,
    isCustom: isCustom
  });

  return <StyledClientPlanProfileCard ref={innerRef} {...innerProps}>
    {renderedHeader}
    {renderedContent}
  </StyledClientPlanProfileCard>
});

ClientPlanProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  onHide: PropTypes.func,
  client: PropTypes.object,
  plan: PropTypes.object,
  fieldData: PropTypes.object,
  isDialog: PropTypes.bool,
  renderedHeader: PropTypes.any,
  renderedContent: PropTypes.any
};

ClientPlanProfileCard.defaultProps = {
};

export default ClientPlanProfileCard;
