import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import section from 'helpers/section';
import StyledTaskSectionPanel from 'components/organisms/SectionPanels/TaskSectionPanel/TaskSectionPanel.styles';
import TaskProfileSectionPanelContent
  from 'components/organisms/SectionPanels/TaskProfileSectionPanelContent/TaskProfileSectionPanelContent';

const TaskSectionPanel = React.forwardRef((props, ref) => {
  const {
    sections,
    onClose,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'TaskSectionPanel');

  const profileProvider = useProfile();
  const task = profileProvider?.data?.data;
  const sectionDefinitions = profileProvider?.sectionDefinitions;

  const authorize = useAuthorize();

  const onCloseEvent = useEffectEvent(onClose);

  const sectionsMemo = useMemo(() => {
    if (sectionDefinitions) {
      const taskSections = [];

      sectionDefinitions
        .forEach((sectionDef) => {
          const canEditSection = (task) => {
            return !(sectionDef.readOnly ?? false) && authorize({...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, task}});
          }

          if (sectionDef.name === 'profile') {
            taskSections.push({
              ...sectionDef,
              Content: ({data, isLoading}) => {
                return <TaskProfileSectionPanelContent task={data}
                                                       isLoading={isLoading}
                                                       onCanUpdate={canEditSection}
                                                       onClose={onCloseEvent}/>
              }
            });
          }
        });

      return section.mergeSections(taskSections, sections)
        .sort((a, b) => a.position - b.position);
    } else {
      return [];
    }
  }, [authorize, sectionDefinitions, sections, onCloseEvent]);

  const handleChange = (section, reason) => {
    onChange?.(section);
    if (!['sectionInit', 'sectionNotFound'].includes(reason)) {
      profileProvider.setSettings({activeSection: section?.name});
    }
  }

  const active = sectionsMemo
    .find((s) => s.name === profileProvider?.state?.settings?.activeSection) ?? sectionsMemo[0];

  return <StyledTaskSectionPanel ref={ref} {...innerProps}
                                 data={task}
                                 dataKey={profileProvider.dataKey}
                                 active={active}
                                 sections={sectionsMemo}
                                 onChange={handleChange}
                                 isLoading={profileProvider.isLoading()}/>
});

TaskSectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  sections: PropTypes.array,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onCanUpdate: PropTypes.func
};

TaskSectionPanel.defaultProps = {};

export default TaskSectionPanel;
