import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import CardActions from '@mui/material/CardActions';

const StyledCardActions = styled(CardActions)` 
  ${ComponentStyles}
`;

StyledCardActions.propTypes = {
  theme: PropTypes.object
};

StyledCardActions.defaultProps = {}

export default StyledCardActions;
