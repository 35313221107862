import React from 'react';
import GlobalStyles from 'styles/Global.styles';
import StyledApp from './App.styles';
import SnackbarProvider from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import LoaderProvider from 'components/organisms/Providers/LoaderProvider/LoaderProvider';
import ErrorProvider from 'components/organisms/Providers/ErrorProvider/ErrorProvider';
import QueryProvider from 'components/organisms/Providers/QueryProvider/QueryProvider';
import AppProvider from 'components/organisms/Providers/AppProvider/AppProvider';
import ServiceProvider from 'components/organisms/Providers/ServiceProvider/ServiceProvider';
import MenuProvider from 'components/organisms/Providers/MenuProvider/MenuProvider';
import ActionbarProvider from 'components/organisms/Providers/ActionbarProvider/ActionbarProvider';
import ClientProvider from 'components/organisms/Providers/ClientProvider/ClientProvider';
import GTMProvider from 'components/organisms/Providers/GTMProvider/GTMProvider';
import DimensionsProvider from 'components/organisms/Providers/DimensionsProvider/DimensionsProvider';
import DialogProvider from 'components/organisms/Providers/DialogProvider/DialogProvider';
import SystemProvider from 'components/organisms/Providers/SystemProvider/SystemProvider';
import AuthProvider from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {Outlet} from 'react-router-dom';
import {useComponentProps} from 'helpers/hooks/utils';
import AuthIntercept from 'components/organisms/Providers/AuthProvider/AuthIntercept';
import AuthRoute from 'components/organisms/Providers/AuthProvider/AuthRoute';

const App = (props) => {
  const innerProps = useComponentProps(props, 'App');

  return <StyledApp {...innerProps}>
    <GlobalStyles />
    <SystemProvider>
      <SnackbarProvider>
        <LoaderProvider>
          <ErrorProvider>
            <QueryProvider>
              <AppProvider>
                <ServiceProvider>
                  <AuthProvider>
                    <AuthIntercept>
                      <ClientProvider>
                        <DimensionsProvider>
                          <GTMProvider>
                            <DialogProvider>
                              <ActionbarProvider>
                                <MenuProvider>
                                  <AuthRoute>
                                    <Outlet />
                                  </AuthRoute>
                                </MenuProvider>
                              </ActionbarProvider>
                            </DialogProvider>
                          </GTMProvider>
                        </DimensionsProvider>
                      </ClientProvider>
                    </AuthIntercept>
                  </AuthProvider>
                </ServiceProvider>
              </AppProvider>
            </QueryProvider>
          </ErrorProvider>
        </LoaderProvider>
      </SnackbarProvider>
    </SystemProvider>
  </StyledApp>
}

App.propTypes = {
};

App.defaultProps = {
};

export default App;
