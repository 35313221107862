import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesQueryBar from 'components/organisms/Bars/EntitiesQueryBar/EntitiesQueryBar';

const StyledDatabaseEntitiesQueryBar = styled(EntitiesQueryBar)`
  ${ComponentStyles}
`;

StyledDatabaseEntitiesQueryBar.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitiesQueryBar.defaultProps = {
};

export default StyledDatabaseEntitiesQueryBar;
