import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledPeriodUpdateListItem from 'components/molecules/Lists/PeriodUpdateListItem/PeriodUpdateListItem.styles';
import PeriodUpdateChip from 'components/atoms/Chips/PeriodUpdateChip/PeriodUpdateChip';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import ListItemText from 'components/atoms/Text/ListItemText/ListItemText';
import ListItemAvatar from 'components/atoms/Avatars/ListItemAvatar/ListItemAvatar';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import {useActionItem} from 'helpers/hooks/action';

const PeriodUpdateListItem = React.forwardRef((props, ref) => {
  const {
    icon,
    count,
    action,
    update,
    isLoading,
    PeriodUpdateChipProps,
    ...innerProps
  } = useComponentProps(props, 'PeriodUpdateListItem');

  const itm = useActionItem(action);

  if (itm) {
    return <StyledPeriodUpdateListItem ref={ref}
                                       secondaryAction={<PeriodUpdateChip count={update}
                                                                          action={action}
                                                                          isLoading={isLoading}
                                                                          {...PeriodUpdateChipProps}/>}
                                       {...innerProps}>
      <ListItemAvatar isLoading={isLoading}>
        <Avatar className="PeriodUpdateListItem-avatar" color="primary">
          {icon}
        </Avatar>
      </ListItemAvatar>

      <ListItemText className="PeriodUpdateListItem-text"
                    primary={<Span min={5} max={10} isLoading={isLoading}>
                      {utils.formatNumber(count)}</Span>}
                    secondary={<Span variant="body2"
                                     isLoading={isLoading}>Companies in <ActionLink action={action}/></Span>}/>

    </StyledPeriodUpdateListItem>
  }
});

PeriodUpdateListItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.object,
  count: PropTypes.number,
  link: PropTypes.object,
  update: PropTypes.number,
  action: PropTypes.object
};

PeriodUpdateListItem.defaultProps = {
};

export default PeriodUpdateListItem;
