import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTableBar from 'components/organisms/Bars/EntitiesTableBar/EntitiesTableBar';

const StyledCollectionEntitiesTableBar = styled(EntitiesTableBar)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesTableBar.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTableBar.defaultProps = {
};

export default StyledCollectionEntitiesTableBar;
