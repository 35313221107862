import React, {useMemo} from 'react'
import ErrorPage from 'components/pages/Error/ErrorPage/ErrorPage';
import NotFoundPage from 'components/pages/Error/NotFoundPage/NotFoundPage';
import ForbiddenPage from 'components/pages/Error/ForbiddenPage/ForbiddenPage';
import CenterWrapper from 'components/templates/Wrappers/Basic/CenterWrapper/CenterWrapper';
import utils from 'helpers/utils';

export default function useErrorRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <ErrorPage/>,
        handle: {
          auth: utils.createAuth({ mustLogin: false })
        }
      },
      {
        path: 'forbidden',
        element: <ForbiddenPage/>,
        handle: {
          auth: utils.createAuth({ mustLogin: false })
        }
      },
      {
        path: 'notFound',
        element: <NotFoundPage/>,
        handle: {
          auth: utils.createAuth({ mustLogin: false })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => <CenterWrapper centerWidth={800}>{children}</CenterWrapper>, // no wrap
      route: route
    };
  }, []);
};
