import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useOptimistic} from 'helpers/hooks/utils';
import StyledCollectionCard from 'components/organisms/Cards/CollectionCard/CollectionCard.styles';
import Box from 'components/atoms/Layout/Box/Box';
import DealflowPieChart from 'components/organisms/Charts/DealflowPieChart/DealflowPieChart';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import ActionCheckbox from 'components/molecules/Inputs/ActionCheckbox/ActionCheckbox';
import Star from '@mui/icons-material/Star';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {useCollectionToggleFavorite} from 'services/collection/collection.utils';

const CollectionCard = React.forwardRef((props, ref) => {
  const {
    collection,
    onClick,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'CollectionCard', {
    static: ['isLoading'],
    children: ['dealflow', 'content'],
    styled: ['color']
  });

  const toggleFavorite = useCollectionToggleFavorite();
  const [favorite, toggleFavoriteOptimistic] = useOptimistic(collection?.favorite, toggleFavorite);

  const title = collection?.name ?? '';
  const edited = new Date(collection?.lastUpdatedAt);
  const total = collection?.entities ?? 0;
  const dealflow = (collection && !isLoading) ? (collection?.dealflow ?? []) : null;

  const itm = useActionItem({
    navigation: {
      to: `/collections/${collection.collectionId}/entities`
    }
  });
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const favoriteAction = {
    label: 'Favorite',
    tooltip: 'Favorite',
    auth: utils.createAuth({attribute: 'collection.update', meta: {collection}}),
    icon: <Icon icon={Star} color="divider" />,
    checkedIcon: <Icon icon={Star} color="amber.500" />,
    active: favorite,
    onClick: (e) => {
      toggleFavoriteOptimistic(!favorite, collection, !favorite).then();
      e.preventDefault();
    }
  };

  const editedTimeAgo = utils.timeAgo(edited);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionCard ref={ref} {...innerProps}
                               disabled={!itm}
                               onClick={handleClick}>
    <Box className="CollectionCard-dealflow">
      <ActionCheckbox className="CollectionCard-dealflow-favorite"
                      action={favoriteAction}
                      isLoading={isLoading}
                      showInactive={true}/>
      <Box className="CollectionCard-dealflow-chart">
        <DealflowPieChart dealflow={dealflow}
                          total={total}
                          showNoDealflow={true}
                          variant="half"
                          isLoading={isLoading}
                          PieChartProps={{
                            showTooltip: false,
                            ResponsiveContainerProps: {aspect: 1}
                          }}/>
      </Box>
    </Box>
    <Box className="CollectionCard-content">
      <Icon icon={FolderSharp} color="primary" />
      <Box className="CollectionCard-content-text">
        <Typography variant="subtitle1" showTooltip={true} isLoading={isLoading}>{title}</Typography>
        <Typography variant="caption" isLoading={isLoading}>{`Edited ${editedTimeAgo}`}</Typography>
      </Box>
    </Box>
  </StyledCollectionCard>
});

CollectionCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
};

CollectionCard.defaultProps = {
  color: 'primary',
  collection: {
    name: 'Venture IQ',
    favorite: false,
    edited: new Date().toISOString(),
    entities: 12
  }
};

export default CollectionCard;
