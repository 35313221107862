import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSimpleLoader = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .SimpleLoader-title { 
    margin-top: ${props => props.theme.layout('1sp')};
  }
  
  ${ComponentStyles}
`;

StyledSimpleLoader.propTypes = {
  theme: PropTypes.object
};

StyledSimpleLoader.defaultProps = {
}

export default StyledSimpleLoader;
