import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useCollectionQuestionnaireGet} from 'services/collection/questionnaire/questionnaire.hooks';
import StyledCollectionEntitiesViewBar
  from 'components/organisms/Bars/CollectionEntitiesViewBar/CollectionEntitiesViewBar.styles';
import {useLocation} from 'react-router-dom';
import {useLinkNavigate} from 'helpers/hooks/links';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useAuthUserId} from 'services/auth/auth.utils';
import {useCollectionSuggestionList} from 'services/collection/entity/entity.hooks';
import constants from 'helpers/constants';

const CollectionEntitiesViewBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesViewBar');

  const [internalState, setInternalState] = useState({
    showMoreDialog: false,
    showFinishDialog: false
  });

  const userId = useAuthUserId();

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;

  const location = useLocation();
  const navigate = useLinkNavigate();

  const view = tableProvider?.view;
  const selectedEntityId = tableProvider.selected()?.entityId;

  const questionnaireView = view?.activeViews?.find((v) => v.name === 'questionnaire');
  const hasQuestionnaireView = Boolean(questionnaireView);
  const questionnaireId = hasQuestionnaireView ? +questionnaireView.params?.[0] : null;
  const questionnaire = useCollectionQuestionnaireGet({
    collectionId: collection?.collectionId,
    questionnaireId
  }, {
    enabled: hasQuestionnaireView && collection?.collectionId > 0 && questionnaireId > 0
  });

  const suggestionView = view?.activeViews?.find((v) => v.name === 'suggestions');
  const hasSuggestionView = Boolean(suggestionView);
  const suggestionList = useCollectionSuggestionList({
    collectionId: collection?.collectionId,
    feedUpdatedAt: collection?.feedUpdatedAt,
    pageSize: 0
  }, {
    enabled: hasSuggestionView && collection?.collectionId > 0
  });
  const suggestionCount = (suggestionList.meta?.resultsCount ?? 0);

  const actionableView = hasQuestionnaireView ? questionnaireView : (hasSuggestionView ? suggestionView : null);
  const closableView = hasQuestionnaireView ? questionnaireView : null;
  const loaded = utils.isDefined(tableProvider.list?.data) && !tableProvider.list?.status?.isLoading && !tableProvider.list?.status?.isReloading &&
    (!hasQuestionnaireView || (questionnaire?.data && questionnaire?.status?.isSettled && !questionnaire?.status?.isLoading)) &&
    (!hasSuggestionView || (suggestionList?.data && suggestionList?.status?.isSettled && !suggestionList?.status?.isLoading));

  const isResponsible = Boolean(questionnaire.data?.userIds?.find((id) => +id === +userId));
  const activeView = hasQuestionnaireView ? questionnaireView?.params?.slice(-1)[0] === 'active' : hasSuggestionView;
  const openCount = hasQuestionnaireView ? questionnaire.data?.openCount : (
    hasSuggestionView ? suggestionCount : null
  );
  const done = hasQuestionnaireView ? !tableProvider.list?.data?.find((d) => d.hasOpenQuestions) : (
    hasSuggestionView ? (tableProvider.list?.meta?.resultsCount ?? 0) === 0 : false
  );

  const refetchEvent = useEffectEvent(tableProvider.list?.query?.refetch);
  useEffect(() => {
    if (hasQuestionnaireView) {
      refetchEvent?.();
    }
  }, [hasQuestionnaireView, selectedEntityId, refetchEvent]);

  useLayoutEffect(() => {
    setInternalState((current) => {
      if (!hasSuggestionView || suggestionCount > (current.suggestionCount ?? 0)) {
        return { ...current, suggestionCount: suggestionCount ?? 0 };
      } else {
        return current;
      }
    })
  }, [suggestionCount, hasSuggestionView]);

  const debouncedDialog = useMemo(() => {
    return utils.debounce((activeView, hasQuestionnaireView, hasSuggestionView, loaded, openCount, done) => {
      if (activeView) {
        if ((hasQuestionnaireView || hasSuggestionView) && utils.isDefined(openCount) && loaded) {
          let showingDialog = false;
          if (openCount === 0) {
            showingDialog = true;
            setInternalState(utils.updater({
              showMoreDialog: false,
              showFinishDialog: true
            }, true));
          } else if (done) {
            showingDialog = true;
            setInternalState(utils.updater({
              showMoreDialog: true,
              showFinishDialog: false
            }, true));
          }

          if (!showingDialog) {
            setInternalState(utils.updater({
              showMoreDialog: false,
              showFinishDialog: false
            }, true));
          }
        }
      }
    }, constants.debounce.short);
  }, []);

  useEffect(() => {
    debouncedDialog(activeView, hasQuestionnaireView, hasSuggestionView, loaded, openCount, done);
  }, [activeView, hasQuestionnaireView, hasSuggestionView, loaded, openCount, done, debouncedDialog]);

  const handleConfirmClose = (e, reason) => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    if (reason !== 'confirmButtonClick') {
      if (closableView) {
        const path = utils.removePathParams(location.pathname + location.search, `custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_active`);
        navigate({
          to: `${path}${path.includes('?') ? '&' : '?'}custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_closed`,
          keepSearchParams: false,
          replace: true
        });
      }
    }
  }

  const handleConfirmMore = () => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    let path = `${location.pathname}?custom=${actionableView.name}`
    if (closableView) {
      path = `${location.pathname}?custom=${closableView.name}:${closableView.params.slice(0, -1).join('_')}_active`;
    }
    navigate({
      to: path,
      keepSearchParams: false,
      replace: true
    });
  }

  const handleConfirmFinish = () => {
    setInternalState(utils.updater({
      showMoreDialog: false,
      showFinishDialog: false
    }, true));

    navigate({to: location.pathname, replace: true});
  }

  if (activeView || isResponsible) {
    innerProps.progress = hasQuestionnaireView ? (
      !(questionnaire.data?.entityIds?.length > 0) ? 0 :
        (((questionnaire.data?.entityIds?.length ?? 0) - (openCount ?? 0)) / (questionnaire.data?.entityIds?.length ?? 0) * 100)
    ) : (
      hasSuggestionView ? (
        !(internalState.suggestionCount > 0) ? 0 :
          (internalState.suggestionCount - (openCount ?? 0)) / internalState.suggestionCount * 100
      ) : null
    );
    innerProps.progressText = hasQuestionnaireView ?
      `${(questionnaire.data?.entityIds?.length ?? 0) - (openCount ?? 0)} / ${questionnaire.data?.entityIds?.length ?? 0}` : (
        hasSuggestionView ? `${(internalState.suggestionCount ?? 0) - (openCount ?? 0)} / ${internalState.suggestionCount ?? 0}` : null
      )

    innerProps.progressTooltip = hasQuestionnaireView ? 'Questionnaire progress' : (
      hasSuggestionView ? 'Suggestions progress' : null
    )
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntitiesViewBar ref={ref} {...innerProps}
                                          isLoading={tableProvider.isLoading() || questionnaire.status.isLoading}>
    <ConfirmDialog question={hasQuestionnaireView ?
                              'You have finished the questionnaire for the selected companies, do you want to finish the questionnaire for the rest of the companies?' : (
                              hasSuggestionView ? 'You have finished processing the suggestions, do you want to finish processing the rest of the suggestions?' : null
                            )}
                   onClose={handleConfirmClose}
                   onConfirm={handleConfirmMore}
                   open={internalState.showMoreDialog}
                   ConfirmButtonProps={{
                     children: 'Continue',
                     color: 'primary'
                   }}/>
    <ConfirmDialog question={hasQuestionnaireView ?
                              'You have finished the questionnaire for all the companies, do you want to close the view?' : (
                              hasSuggestionView ? 'You have finished processing the suggestions, do you want to close the view?' : null
                            )}
                   onClose={handleConfirmClose}
                   onConfirm={handleConfirmFinish}
                   open={internalState.showFinishDialog}
                   ConfirmButtonProps={{
                     children: 'Close view',
                     color: 'success'
                   }}/>
  </StyledCollectionEntitiesViewBar>
});

CollectionEntitiesViewBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesViewBar.defaultProps = {
  color: 'primary'
};

export default CollectionEntitiesViewBar;
