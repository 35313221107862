import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTabDivider from 'components/atoms/Dividers/TabDivider/TabDivider.styles';
import {withMemo} from 'helpers/wrapper';

const TabDivider = withMemo(React.forwardRef((props, ref) => {
  const {
    indicator,
    textColor,
    value,
    ...innerProps
  } = useComponentProps(props, 'TabDivider');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTabDivider ref={ref} {...innerProps} />
}));

TabDivider.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TabDivider.defaultProps = {
};

export default TabDivider;
