import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTableWrapper = styled(Box)`
  &.TableWrapper {
    &-sticky {
      .TableWrapper-header {
        top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
      }
    }
  }
  
  .TableWrapper {
    &-header {
      position: sticky;
      overflow: hidden;
      left: ${props => props.$wrapper?.boundsCum?.left ?? 0 ?? 0};
      width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));

      z-index: ${props => props.theme.property('zIndex.header')};
      background-color: ${props => props.theme.property('palette.background.paper')};
    }
  }
  
  .TableWrapper-content {
    display: flex;
    
    .Table {
      height: fit-content;
      
      .MuiTableHead-root {
        top: calc(${props => props.$wrapper?.boundsCum?.top ?? '0px'} - 0.5px);
      }
      
      ${props => !props.$boxed ? css`
        padding: 0;
        margin: 0;
      ` : null}
    }
  
    .TableWrapper {
      &-left, &-right, &-bottom {
        position: sticky;
        background-color: ${props => props.theme.property('palette.background.default')};
        z-index: ${props => props.theme.property('zIndex.context')};
      }

      &-bottom {
        position: fixed;
      }
      
      &-left, &-right {
        top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
      }

      &-left, &-right, &-bottom {
        bottom: ${props => props.$wrapper?.boundsCum?.bottom ?? 0};
      }

      &-left, &-bottom {
        left: ${props => props.$wrapper?.boundsCum?.left ?? 0};
      }

      &-right, &-bottom {
        right: ${props => props.$wrapper?.boundsCum?.right ?? 0};
      }

      &-left, &-right {
        height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));
      }
      
      &-left {
        width: ${props => props.$borders.left};
      }

      &-right {
        width: ${props => props.$borders.right};
      }
      
      &-bottom {
        height: ${props => props.$borders.bottom};
        width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
      }
    }
  }

  &.TableWrapper-dividers {
    .TableWrapper {
      ${props => props.$boxed ? css`
        &-left {
          border-right: 1px solid ${props => props.theme.property('palette.divider')};
        }

        &-right {
          border-left: 1px solid ${props => props.theme.property('palette.divider')};
        }

        &-bottom {
          height: max(${props.$borders.bottom}, calc(
            (
             100vh - 
             (${props => props.$tableHeight ?? '0px'} + ${props => props.$headerHeight ?? '0px'} + ${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'})
             + 1px)
          ));
          
          &::before {
            display: block;
            border-top: 1px solid ${props => props.theme.property('palette.divider')};
            width: calc(100% - ${props.$borders.left} - ${props.$borders.right} + 2px);
            margin-left: calc(${props.$borders.left} - 1px);
            content: '';
          }
        }

        &-content {
          .Table {
            margin-bottom: 0;
            
            .MuiTableHead-root {
              tr {
                &:first-child {
                  .TableHeader {
                    border-top: 1px solid ${props => props.theme.property('palette.divider')};
                  }
                }
              }
            }

            .MuiTableBody-root {
              tr {
                td {
                  > p.MuiTypography-root {
                    border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
                  }
                }
              }
            }
          }
        }
      ` : null}
    }
  }
  
  ${ComponentStyles}
`;

StyledTableWrapper.propTypes = {
  theme: PropTypes.object,
  $borders: PropTypes.object
};

StyledTableWrapper.defaultProps = {
};

export default StyledTableWrapper;
