import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Button from 'components/atoms/Buttons/Button/Button';
import Save from '@mui/icons-material/Save';
import Typography from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import {useAuthorize, useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useAuthDataTokenGet, useAuthPortalTokenGet} from 'services/auth/auth.hooks';
import utils from 'helpers/utils';
import {useClientUserPatch} from 'services/client/user/user.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import StyledProfileSettingPage from 'components/pages/Setting/ProfileSettingPage/ProfileSettingPage.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthClientId} from 'services/auth/auth.utils';

const info = 'Edit and update your profile. Generate a live data key to access the Catalist Google Sheets plugin to seamlessly process and interpret your data';

const ProfileSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'ProfileSettingPage', {
    children: ['title', 'form', 'buttons']
  });

  const formRef = useRef(null);
  const wrapper = useWrapper();

  const clientId = useAuthClientId();
  const profileProvider = useProfile();

  const snackbar = useSnackbar();
  const user = useAuthUser();

  const [internalState, setInternalState] = useState({
    liveDataKey: false,
    portalKey: false,
    submitting: false
  });

  const patchUser = useClientUserPatch();
  const liveDataToken = useAuthDataTokenGet({}, {
    enabled: internalState.liveDataKey
  });
  const portalToken = useAuthPortalTokenGet({}, {
    enabled: internalState.portalKey
  });

  const authorize = useAuthorize();

  const fieldsMemo = useMemo(() => ([
    {
      name: 'username',
      label: 'Username',
      inlineLabel: 'username',
      type: constants.formFieldTypes.text,
      validation: [constants.formFieldValidationTypes.username,
        `${constants.formFieldValidationTypes.min}(3)`, `${constants.formFieldValidationTypes.max}(20)`],
      entity: 'user',
      initial: user.username ?? '',
      required: true,
      disabled: true,
      FormFieldProps: {
        variant: 'inlineLabel',
      },
    },
    {
      name: 'email',
      label: 'Email',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.email,
      entity: 'user',
      initial: user.email ?? '',
      required: true,
      disabled: true,
      FormFieldProps: {
        variant: 'inlineLabel',
      },
    },
    {
      name: 'firstName',
      label: 'First name',
      inlineLabel: 'first name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      entity: 'user',
      initial: user.firstName ?? '',
      required: true,
      FormFieldProps: {
        variant: 'inlineLabel',
      },
    },
    {
      name: 'lastName',
      label: 'Last name',
      inlineLabel: 'last name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      entity: 'user',
      initial: user.lastName ?? '',
      required: true,
      FormFieldProps: {
        variant: 'inlineLabel',
      },
    },
    {
      name: 'hasNotifications',
      label: 'Notifications',
      inlineLabel: 'notifications',
      type: constants.formFieldTypes.switch,
      validation: constants.formFieldValidationTypes.boolean,
      conversion: constants.formFieldConversionTypes.value,
      initial: user.hasNotifications ?? false,
      FormFieldProps: {
        variant: 'inlineLabel'
      }
    }
  ]), [user.email, user.username, user.firstName, user.lastName, user.hasNotifications]);

  const handleSubmit = (values, actions, fields) => {
    setInternalState(utils.updater({submitting: true}, true));
    const changes = Object.keys(values).reduce((a, key) => {
      if (fields.find((f) => f.name === key && !f.FormFieldProps?.disabled)) {
        a[key] = values[key];
      }
      return a;
    }, {});

    patchUser(clientId, user, changes)
      .then(() => {
        snackbar.show('User profile was saved', null,
          {color: 'success', autoHideDuration: constants.delay.success});
      })
      .catch(() => {
        snackbar.show('Saving user profile failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      })
      .finally(() => {
        setInternalState(utils.updater({submitting: false}, true));
        actions.setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty) => {
    profileProvider.dirty?.(isDirty);
  }

  const handleSaveClick = (e) => {
    formRef.current?.submit();
    e.preventDefault();
  };

  const renderGenerateLiveDataKey = () => {
    const handleGenerateKeyLiveDataClick = (e) => {
      setInternalState(utils.updater({liveDataKey: true}, true));
    };

    if (authorize({attribute: 'user.field.dataToken.read'})) {
      return <Box className="ProfileSettingPage-keys-liveData">
        <Typography className="ProfileSettingPage-keys-liveData-label"
                    variant="body1">
          Live data
        </Typography>
        {internalState.liveDataKey && liveDataToken?.status?.isSuccess ?
          <Typography className="ProfileSettingPage-keys-liveData-key"
                      copyText={true}>
            {liveDataToken.data?.data ?? ''}
          </Typography> : <Button className="ProfileSettingPage-keys-liveData-button"
                                  disabled={liveDataToken?.status?.isLoading}
                                  label="Generate key"
                                  variant="outlined"
                                  onClick={handleGenerateKeyLiveDataClick}/>}
      </Box>
    }
  };

  const renderGeneratePortalKey = () => {
    const handleGenerateKeyPortalClick = (e) => {
      setInternalState(utils.updater({portalKey: true}, true));
    };

    if (authorize({attribute: 'user.field.portalToken.read'})) {
      return <Box className="ProfileSettingPage-keys-portal">
        <Typography className="ProfileSettingPage-keys-portal-label"
                    variant="body1">
          Portal
        </Typography>
        {internalState.portalKey && portalToken?.status?.isSuccess ?
          <Typography className="ProfileSettingPage-keys-portal-key"
                      copyText={true}>
            {portalToken.data?.data ?? ''}
          </Typography> : <Button className="ProfileSettingPage-keys-portal-button"
                                  disabled={portalToken?.status?.isLoading}
                                  label="Generate key"
                                  variant="outlined"
                                  onClick={handleGenerateKeyPortalClick}/>}
      </Box>
    }
  };


  return <StyledProfileSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                   title="Profile"
                                   info={info}
                                   $wrapper={wrapper}>
    <InlineForm ref={formRef}
                className="ProfileSettingPage-form"
                onSubmit={handleSubmit}
                onValidating={handleValidating}
                fields={fieldsMemo}
                readOnly={!authorize({attribute: 'settings.profile.update'})}/>
    <Box className="ProfileSettingPage-keys">
      {renderGenerateLiveDataKey()}
      {renderGeneratePortalKey()}
    </Box>
    <Box className="ProfileSettingPage-footer">
      <Button className="ProfileSettingPage-buttons-save"
              disabled={internalState.submitting}
              label="Save changes"
              onClick={handleSaveClick}
              startIcon={<Icon icon={Save}/>} />
    </Box>
  </StyledProfileSettingPage>
};

ProfileSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func,
  onDirty: PropTypes.func
};

ProfileSettingPage.defaultProps = {};

export default ProfileSettingPage;
