import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Pie} from 'recharts';

const StyledActionPie = styled(Pie)`
  &.ActionPie-clickable {
    cursor: pointer;
  }
  
  ${ComponentStyles}
`;

StyledActionPie.propTypes = {
  theme: PropTypes.object
};

StyledActionPie.defaultProps = {
}

export default StyledActionPie;
