import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {LineChart} from 'recharts';
import ChartStyles from 'styles/mixins/Chart.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const StyledLineChart = styled(LineChart)`
  display: flex;

  &.LineChart {
    &-isLoading {
      .LineChart-line {
        stroke-opacity: 0.2;
        pointer-events: none;
      }
    }
    
    .LineChart-line {
      &.hover {
        filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.4));
      }
      &.notHover {
        stroke-opacity: 0.3;
      }
    }
  }
  
  .recharts-tooltip-cursor {
    fill: ${props => props.theme.state2Color('primary', 'focusVisible',
            constants.colorTypes.accentBackground, constants.colorTargets.background, 0.5)};
  }

  .recharts-label-list {
    pointer-events: none;
  }
  
  .recharts-cartesian {
    &-axis {
      &-tick-value {
        ${props => props.theme.font2Css('caption')};
        fill: ${props => props.theme.property('palette.text.primary')};
      }

      &-line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
    
    &-grid {
      line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
  }
  
  ${ChartStyles}
  ${ComponentStyles}
`;

StyledLineChart.propTypes = {
};

StyledLineChart.defaultProps = {
}

export default StyledLineChart;
