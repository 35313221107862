import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCardItem = styled(Box)` 
  &.CardItem-grow {
    &:not(.CardItem-fit) {
      > * {
        height: 100%;
        width: 100%;
      }
    }
  }
  
  &.CardItem-fit {
    height: fit-content;
  }

  &.CardItem-dragging {
    pointer-events: none;
    cursor: grabbing !important;
  }
  
  ${ComponentStyles}
`;

StyledCardItem.propTypes = {
  theme: PropTypes.object
};

StyledCardItem.defaultProps = {}

export default StyledCardItem;
