import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledCommentDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
  }
  
  ${ComponentStyles}
`;

StyledCommentDialog.propTypes = {
  theme: PropTypes.object
};

StyledCommentDialog.defaultProps = {}

export default StyledCommentDialog;
