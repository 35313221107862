import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesSuggestionsBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesSuggestionsBarWrapper/CollectionEntitiesSuggestionsBarWrapper.styles';
import CollectionEntitiesSuggestionsBar
  from 'components/organisms/Bars/CollectionEntitiesSuggestionsBar/CollectionEntitiesSuggestionsBar';

const CollectionEntitiesSuggestionsBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesSuggestionsBarWrapper');

  return <StyledCollectionEntitiesSuggestionsBarWrapper ref={ref} {...innerProps}
                                                        anchor="bottom">
    {innerProps.children}
  </StyledCollectionEntitiesSuggestionsBarWrapper>
});

CollectionEntitiesSuggestionsBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesSuggestionsBarWrapper.defaultProps = {
  ToolbarComponent: CollectionEntitiesSuggestionsBar
};

export default CollectionEntitiesSuggestionsBarWrapper;
