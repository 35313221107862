import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Actionbar from 'components/organisms/Snackbars/Actionbar/Actionbar';

const StyledFieldsActionbar = styled(Actionbar)` 
  ${ComponentStyles}
`;

StyledFieldsActionbar.propTypes = {
  theme: PropTypes.object
};

StyledFieldsActionbar.defaultProps = {}

export default StyledFieldsActionbar;
