import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledWizard from 'components/organisms/Wizards/Wizard/Wizard.styles';
import Component from 'components/organisms/Utils/Component/Component';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import Stepper from 'components/atoms/Steppers/Stepper/Stepper';
import Step from 'components/atoms/Steppers/Step/Step';
import StepLabel from 'components/atoms/Steppers/StepLabel/StepLabel';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StepButton from 'components/atoms/Steppers/StepButton/StepButton';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';
import MobileStepper from 'components/atoms/Steppers/MobileStepper/MobileStepper';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';
import Typography, {H4} from 'components/atoms/Text/Typography/Typography';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import StepIcon from 'components/atoms/Steppers/StepIcon/StepIcon';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import SnackbarProvider from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const Wizard = React.forwardRef((props, ref) => {
  const {
    steps,
    dataKey,
    initialData,
    variant,
    showButtons,
    showProgressBar,
    onSubmit,
    onChange,
    onDirty,
    onError,
    SaveActionButtonProps,
    ...innerProps
  } = useComponentProps(props, 'Wizard');

  const dialog = useDialog();
  const wrapper = useWrapper();

  const completeRef = useRef({});
  const [data, setData] = useState(initialData ?? {});
  const [internalState, setInternalState] = useState({
    dirty: false,
    submitting: false,
    stepIndex: 0,
    subStepIndex: null,
    steps: {},
    error: null
  });

  const stepRef = useRef(null);
  const innerRef = useRef(null);
  const contentRef = useRef(null);

  const simple = variant === 'simple';

  const isPrevStepEnabled = useCallback((state = null) => {
    return steps?.length > 0 && steps?.some?.((s, idx) => ((s.enableStep?.(data) ?? true) && idx < state.stepIndex) || (
        idx === state.stepIndex &&
        steps?.[state.stepIndex].subSteps?.some?.((s, idx) => (s.enableStep?.(data) ?? true) &&  idx < state.subStepIndex)
      )
    );
  }, [data, steps]);

  const goToPrevStep = useCallback((state) => {
    if (isPrevStepEnabled(state)) {
      let subSteps = steps[state.stepIndex].subSteps;
      let prevSubStepIndex = subSteps ?
        subSteps.findLastIndex((s, idx) => (s.enableStep?.(data) ?? true) && idx < state.subStepIndex) : null;
      const prevStepIndex = prevSubStepIndex < state.subStepIndex && prevSubStepIndex !== -1 ?
        state.stepIndex : steps.findLastIndex((s, idx) => (s.enableStep?.(data) ?? true) && idx < state.stepIndex);

      if (state.stepIndex !== prevStepIndex) {
        subSteps = steps[prevStepIndex].subSteps;
        prevSubStepIndex = subSteps ?
          subSteps.findLastIndex((s) => (s.enableStep?.(data) ?? true)) : null;
      }

      setInternalState(utils.updater({
        stepIndex: prevStepIndex === -1 ? null : prevStepIndex,
        subStepIndex: prevSubStepIndex === -1 ? null : prevSubStepIndex
      }, true));
    }
  }, [data, steps, isPrevStepEnabled]);

  const isNextStepEnabled = useCallback((state) => {
    return steps?.length > 0 && steps?.some?.((s, idx) => ((s.enableStep?.(data) ?? true) && idx > state.stepIndex) || (
      idx === state.stepIndex &&
      steps?.[state.stepIndex].subSteps?.some?.((s, idx) => (s.enableStep?.(data) ?? true) &&  idx > (state.subStepIndex ?? 0))
    ));
  }, [data, steps]);

  const goToNextStep = useCallback((state) => {
    let subSteps = steps[state.stepIndex].subSteps;
    let nextSubStepIndex = subSteps ?
      subSteps.findIndex((s, idx) => (s.enableStep?.(data) ?? true) && idx > (state.subStepIndex ?? -1)) : null;

    const nextStepIndex = nextSubStepIndex > 0 ?
      state.stepIndex : steps.findIndex((s, idx) => (s.enableStep?.(data) ?? true) && idx > state.stepIndex);
    if (state.stepIndex !== nextStepIndex) {
      subSteps = steps[nextStepIndex].subSteps;
      nextSubStepIndex = subSteps ?
        subSteps.findIndex((s) => (s.enableStep?.(data) ?? true)) : null;
    }

    setInternalState(utils.updater({
      stepIndex: nextStepIndex === -1 ? null : nextStepIndex,
      subStepIndex: nextSubStepIndex === -1 ? null : nextSubStepIndex
    }, true));
  }, [data, steps]);

  const isSubmitEnabled = useCallback((state = null) => {
    const subSteps = steps[state.stepIndex].subSteps;
    const step = subSteps ? subSteps[state.subStepIndex] : steps[state.stepIndex];

    return step && (step.enableSubmit?.(data) ?? true);
  }, [data, steps]);

  const wizard = useMemo(() => {
    const state = {
      ...internalState,
      ...innerProps.state
    };

    const step = steps[state.stepIndex].subSteps ? steps[state.stepIndex].subSteps[state.subStepIndex ?? 0] : steps[state.stepIndex];
    const parentStep = steps[state.stepIndex].subSteps ? steps[state.stepIndex] : null;

    return {
      state,
      refs: {
        ref: innerRef,
        step: stepRef
      },
      data,
      steps,
      dataKey,
      step,
      parentStep,
      goToPrevStep: () => goToPrevStep(state),
      goToNextStep: () => {
        setInternalState(utils.updater({submitting: true}, true));
        stepRef.current?.submit?.();
      },
      isDirty: () => state.dirty,
      isStepsDirty: () => Object.keys(state.steps).some((k) => state.steps[k].dirty ||
        Object.keys(state.steps[k].subSteps ?? {}).some((k) => state.steps[k].subSteps[k].dirty)),
      setData: (data) => {
        if (!utils.isFunction(data)) {
          setData(utils.updater(data, true));
        } else {
          setData(data);
        }
      },
      getStepState: () => {
        if (parentStep) {
          return state.steps[parentStep.name]?.subSteps?.[step.name]?.state ?? {};
        } else {
          return state.steps[step.name]?.state ?? {};
        }
      },
      setStepState: (stepState) => {
        if (parentStep) {
          setInternalState((current) => {
            return {
              ...current,
              steps: {
                ...current.steps,
                [parentStep.name]: {
                  ...current.steps[parentStep.name],
                  subSteps: {
                    ...current.steps[parentStep.name]?.subSteps,
                    [step.name]: {
                      ...current.steps[parentStep.name]?.subSteps?.[step.name],
                      state: {
                        ...current.steps[parentStep.name]?.subSteps?.[step.name]?.state,
                        ...stepState
                      }
                    }
                  }
                }
              }
            }
          })
        } else {
          setInternalState((current) => {
            return {
              ...current,
              steps: {
                ...current.steps,
                [step.name]: {
                  ...current.steps[step.name],
                  state: {
                    ...current.steps[step.name]?.state,
                    ...stepState
                  }
                }
              }
            }
          });
        }
      },
      resetNextSteps: () => {
        const stepIdx = steps.findIndex((s) => s.name === (parentStep ?? step).name);
        const subStepIdx = !parentStep ? -1 :
          steps.find((s) => s.name === parentStep?.name)?.subSteps?.findIndex((s) => s.name === step?.name);
        const parentComplete = !parentStep || subStepIdx >= parentStep.subSteps.length - 1;

        setInternalState((current) => {
          const newSteps = utils.clone(current.steps, true);

          const subSteps = ((parentStep ?? step).subSteps ?? []).reduce((o, s, idx) => ({
            ...o,
            [s.name]: {
              ...newSteps[parentStep.name]?.subSteps?.[s.name],
              state: (idx <= subStepIdx) ? newSteps[parentStep.name]?.subSteps?.[s.name]?.state : null,
              complete: (idx <= subStepIdx) ? newSteps[parentStep.name]?.subSteps?.[s.name]?.complete : false
            }
          }), {});

          newSteps[(parentStep ?? step).name] = {
            ...newSteps[(parentStep ?? step).name],
            complete: parentComplete ? newSteps[(parentStep ?? step).name].complete : false,
            subSteps: subSteps
          }

          steps.forEach((s, idx) => {
            if (idx > stepIdx) {
              if (newSteps[s.name]) {
                newSteps[s.name] = {
                  ...newSteps[s.name],
                  state: null,
                  complete: false
                }

                Object.keys(newSteps[s.name].subSteps ?? {}).forEach((k) => {
                  newSteps[s.name].subSteps[k].state = null;
                  newSteps[s.name].subSteps[k].complete = false;
                })
              }
            }
          });

          return { ...current, steps: newSteps };
        });
      }
    }
  }, [internalState, steps, dataKey, data, goToPrevStep, innerProps.state]);

  useImperativeHandle(ref, () => wizard);

  const handleComplete = (resetNextSteps) => {
    const stepIdx = steps.findIndex((s) => s.name === (wizard.parentStep ?? wizard.step).name);
    const subStepIdx = !wizard.parentStep ? -1 :
      steps.find((s) => s.name === wizard.parentStep?.name)?.subSteps?.findIndex((s) => s.name === wizard.step?.name);
    const parentComplete = !wizard.parentStep || subStepIdx >= wizard.parentStep.subSteps.length - 1;
    const timestamp = new Date();

    setInternalState((current) => {
      const newSteps = utils.clone(current.steps, true);

      const subSteps = ((wizard.parentStep ?? wizard.step).subSteps ?? []).reduce((o, s, idx) => ({
        ...o,
        [s.name]: {
          ...newSteps[wizard.parentStep.name]?.subSteps?.[s.name],
          state: (idx <= subStepIdx || !resetNextSteps) ? newSteps[wizard.parentStep.name]?.subSteps?.[s.name]?.state : null,
          complete: idx === subStepIdx ? timestamp : (
            (idx < subStepIdx || !resetNextSteps) ? newSteps[wizard.parentStep.name]?.subSteps?.[s.name]?.complete : false
          )
        }
      }), {});

      newSteps[(wizard.parentStep ?? wizard.step).name] = {
        ...newSteps[(wizard.parentStep ?? wizard.step).name],
        complete: parentComplete ? timestamp : false,
        subSteps: subSteps
      }

      if (resetNextSteps) {
        steps.forEach((s, idx) => {
          if (idx > stepIdx) {
            if (newSteps[s.name]) {
              newSteps[s.name] = {
                ...newSteps[s.name],
                state: null,
                complete: false
              }

              Object.keys(newSteps[s.name].subSteps ?? {}).forEach((k) => {
                newSteps[s.name].subSteps[k].state = null;
                newSteps[s.name].subSteps[k].complete = false;
              })
            }
          }
        });
      }

      return { ...current, submitting: false, steps: newSteps };
    });
  };

  const handleMsg = useCallback((msg, type) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        dirty: (type === 'dirty') ? (current.dirty || Boolean(msg)) : current.dirty,
        success: (type === 'success') ? msg : current.success,
        error: (type === 'error') ? msg : current.error,
        submitting: (['error', 'success'].includes(type) && utils.isDefined(msg)) ? false : current.submitting,
        steps: !wizard.parentStep ? {
          ...current.steps,
          [wizard.step.name]: {
            ...current.steps?.[wizard.step.name],
            dirty: (type === 'dirty') ? Boolean(msg) : current.steps?.[wizard.step.name]?.dirty,
            success: (type === 'success') ? msg : current.steps?.[wizard.step.name]?.success,
            error: (type === 'error') ? msg : current.steps?.[wizard.step.name]?.error
          }
        } : {
          ...current.steps,
          [wizard.parentStep.name]: {
            ...current.steps?.[wizard.parentStep.name],
            subSteps: {
              ...current.steps?.[wizard.parentStep.name]?.subSteps,
              [wizard.step.name]: {
                ...current.steps?.[wizard.parentStep.name]?.subSteps[wizard.step.name],
                dirty: (type === 'dirty') ? Boolean(msg) : current.steps?.[wizard.parentStep.name]?.subSteps[wizard.step.name]?.dirty,
                success: (type === 'success') ? msg : current.steps?.[wizard.parentStep.name]?.subSteps[wizard.step.name]?.success,
                error: (type === 'error') ? msg : current.steps?.[wizard.parentStep.name]?.subSteps[wizard.step.name]?.error
              }
            }
          }
        },
      })(current);
    });
  }, [wizard.step, wizard.parentStep]);

  const handleDirty = (dirty, submitting = false) => {
    onDirty?.(Object.keys(wizard.steps).some((k) => k !== wizard.step.name && wizard.steps[k].dirty) || dirty, submitting);
    setInternalState(utils.updater({submitting}, true));
    handleMsg(dirty, 'dirty');
  }

  const onErrorEvent = useEffectEvent(onError);
  const handleError = (error, validation) => {
    onErrorEvent?.(error, validation);
    handleMsg(error, 'error')
  };

  const stepState = !wizard.parentStep ? wizard.state.steps?.[wizard.step.name] :
    wizard.state.steps?.[wizard.parentStep.name]?.subSteps?.[wizard.step.name];
  const onSubmitEvent = useEffectEvent(onSubmit);
  useEffect(() => {
    if (Boolean(stepState?.complete) &&
        completeRef.current[`${wizard.state.stepIndex}_${wizard.state.subStepIndex}`] !== (stepState?.complete && stepState?.complete?.getTime())) {
      completeRef.current[`${wizard.state.stepIndex}_${wizard.state.subStepIndex}`] = stepState?.complete?.getTime();
      if (!isNextStepEnabled(wizard.state)) {
        setInternalState(utils.updater({submitting: true}, true));
        onSubmitEvent?.(data, {
            setSubmitting: (value) => {
              setInternalState(utils.updater({submitting: value}, true));
            }
          },
          (msg) => {
            handleMsg(msg ?? 'Saved', 'success');
          },
          (err) => {
            handleMsg(err ?? 'Saving failed', 'error')
          });
      } else {
        goToNextStep(wizard.state);
      }
    }
  }, [stepState?.complete, onSubmitEvent, handleMsg, isNextStepEnabled, goToNextStep, wizard.state, data, dataKey]);

  useLayoutEffect(() => {
    const stepIndex = steps?.length > 0 ? 0 : null;
    setInternalState(utils.updater({
      stepIndex,
      subStepIndex: null
    }, true));
  }, [steps]);

  const onChangeEvent = useEffectEvent(onChange);
  useEffect(() => {
    onChangeEvent?.(isPrevStepEnabled(wizard.state), isNextStepEnabled(wizard.state), isSubmitEnabled(wizard.state), internalState.submitting);
  }, [wizard.state, isPrevStepEnabled, isNextStepEnabled, isSubmitEnabled, onChangeEvent, internalState.submitting]);

  const renderStep = () => {
    const renderedStep = <WizardContent ref={stepRef}
                                        className="Wizard-step-content"
                                        wizard={wizard}
                                        step={wizard.step}
                                        onDirty={handleDirty}
                                        onError={handleError}
                                        onComplete={handleComplete}
                                        {...wizard.step.WizardContentProps} />
    if (wizard.step?.Content) {
      return <Component Original={wizard.step.Content}
                        className="Wizard-step-content"
                        ref={stepRef}
                        wizard={wizard}
                        step={wizard.step}
                        renderedStep={renderedStep}
                        onDirty={handleDirty}
                        onError={handleError}
                        onComplete={handleComplete}
                        {...wizard.step.WizardContentProps} />
    } else {
      return renderedStep;
    }
  };

  const renderInfo = () => {
    const info = wizard.step?.info ?? wizard.parentStep?.info;

    const handleSubStepClick = (idx) => {
      return (e) => {
        setInternalState(utils.updater({
          subStepIndex: idx
        }, true));
        e.preventDefault();
      };
    };

    const renderSubStepper = () => {
      const isCompleted = (s) => {
        return Boolean(wizard.state.steps?.[wizard.parentStep.name]?.subSteps?.[s.name]?.complete);
      }
      const canStep = (subStep, idx) => {
        return (subStep.enableStep?.(data) ?? true) && idx !== wizard.state.subStepIndex;
      }

      return <Stepper className="Wizard-info-content-stepper"
                      orientation="vertical"
                      nonLinear={true}
                      connectors={true}
                      connector={<Box/>}
                      activeStep={wizard.state.subStepIndex}>
        {steps[wizard.state.stepIndex].subSteps.map((step, idx) => {
          const disabledStep = internalState.submitting || (
            (wizard.state.subStepIndex > idx && !isCompleted(step)) || !(step.enableStep?.(data) ?? true)
          );
          const hasBeenPassed = steps[wizard.state.stepIndex].subSteps.filter((step, idxAfter) => {
            return idxAfter > idx && isCompleted(step)
          }).length > 0;
          const completedStep = isCompleted(step) || (disabledStep && (wizard.state.subStepIndex > idx || hasBeenPassed));

          return <Step key={step.name}
                       completed={completedStep}>
            {!disabledStep && isCompleted(step) && canStep(step, idx) ?
              <StepButton color="inherit"
                          onClick={handleSubStepClick(idx)}>{step.shortLabel ?? step.label}</StepButton> :
              <StepLabel className={`Wizard-info-content-stepper-stepLabel${disabledStep ? '-disabled' : ''}`}
                         StepIconComponent={StepIcon}
                         StepIconProps={{hideNumber: true, completed: completedStep}}>{step.shortLabel ?? step.label}</StepLabel>}
          </Step>
        })}
      </Stepper>
    };

    if (info) {
      return <Box className="Wizard-info">
        <Box className="Wizard-info-content">
          <Icon className="Wizard-info-content-backgroundIcon"
                color="primary.dark"
                icon={InfoOutlined}/>
          <H4 className="Wizard-info-content-label">{info.label}</H4>
          <Typography className="Wizard-info-content-description" variant="body2">{info.description}</Typography>
          {utils.isDefined(steps[wizard.state.stepIndex].subSteps) ? renderSubStepper() : <Box/>}
        </Box>
      </Box>
    }
  };

  const renderProgress = () => {
    const completedStepsCount = steps.reduce((a, step, idx) => {
      if (idx < wizard.state.stepIndex && (
        Boolean(wizard.state.steps[step.name]?.complete) ||
        !(step.enableStep?.(data) ?? true)
      )) {
        return a + (step.subSteps?.length ?? 1);
      } else {
        const subStepCompleteCount = (step.subSteps ?? []).reduce((c, subStep, idx) => {
          if (idx < wizard.state.subStepIndex && (
            Boolean(wizard.state.steps?.[step.name]?.subSteps?.[subStep.name]?.complete) ||
            !(subStep.enableStep?.(data) ?? true)
          )) {
            return (c + 1);
          } else {
            return c;
          }
        }, 0);

        return a + subStepCompleteCount;
      }
    }, 0)
    const stepCount = steps.reduce((a, s) => s.subSteps?.length > 0 ? (a + s.subSteps.length) : (a + 1), 0);

    return <MobileStepper className="Wizard-navigation-progress"
                          variant="progress"
                          position="static"
                          steps={stepCount}
                          activeStep={completedStepsCount} />
  };

  const renderStepper = () => {
    const isCompleted = (step) => {
      const hasSubSteps = utils.isDefined(step.subSteps) && step.subSteps.length;
      if (!hasSubSteps) {
        return Boolean(wizard.state.steps?.[step.name]?.complete);
      } else {
        const subStepsState = wizard.state.steps?.[step.name]?.subSteps ?? {};
        const enabledCount = step.subSteps?.reduce?.((a, ss) => (ss.enableStep?.(data) ?? true) ? ++a : a, 0);
        const completedCount = Object.keys(subStepsState).reduce((a, k) => Boolean(subStepsState[k].complete) ? ++a : a, 0);

        return enabledCount === completedCount;
      }
    };

    const canStep = (step, idx) => {
      return (step.enableStep?.(data) ?? true) && wizard.state.stepIndex !== idx;
    }

    const handleStepClick = (idx) => {
      return (e) => {
        const subSteps = steps[idx].subSteps;
        const subStepIndex = subSteps ?
          subSteps.findIndex((s) => (s.enableStep?.(data) ?? true)) : null;

        setInternalState(utils.updater({
          stepIndex: idx,
          subStepIndex: subStepIndex
        }, true));
        e.preventDefault();
      };
    };

    return <Box className="Wizard-navigation-stepper">
      {!simple ? <Stepper nonLinear
                          connectors
                          connector={<Icon icon={ChevronRight} color="primary.states.focusVisible" />}
                          activeStep={wizard.state.stepIndex}>
        {steps.map((step, idx) => {
          const disabledStep = internalState.submitting || (
            (wizard.state.stepIndex > idx && !isCompleted(step)) || !(step.enableStep?.(data) ?? true)
          );
          const hasBeenPassed = steps.filter((step, idxAfter) => {
            return idxAfter > idx && isCompleted(step)
          }).length > 0;
          const completedStep = isCompleted(step) || (disabledStep && (wizard.state.stepIndex > idx || hasBeenPassed));

          return <Step key={step.name}
                       completed={completedStep}>
            {(!disabledStep && isCompleted(step) && canStep(step, idx)) ?
              <StepButton color="inherit"
                          onClick={handleStepClick(idx)}>{step.shortLabel ?? step.label}</StepButton> :
              <StepLabel className={`Wizard-navigation-stepper-stepLabel${disabledStep ? '-disabled' : ''}`}
                         StepIconComponent={StepIcon}
                         StepIconProps={{completed: completedStep}}>
                {step.shortLabel ?? step.label}
              </StepLabel>}
          </Step>
        })}
      </Stepper> : null}
    </Box>
  };

  const prevActions = useMemo(() => [{
    label: 'Back',
    icon: ChevronLeft,
    showInactive: true,
    ActionButtonProps: {
      showInactive: true
    },
    ButtonProps: {
      variant: 'text',
      color: 'primary'
    },
    auth: (!isPrevStepEnabled(wizard.state) || wizard.state.submitting) ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      goToPrevStep(wizard.state);
      e.preventDefault();
    }
  }], [wizard.state, isPrevStepEnabled, goToPrevStep]);

  const save = !isNextStepEnabled(wizard.state);
  const nextActions = useMemo(() => [{
    label: 'Next',
    icon: ChevronRight,
    iconPosition: 'end',
    ActionButtonProps: {
      showInactive: true
    },
    ButtonProps: {
      color: save ? 'success' : 'primary'
    },
    auth: wizard.state.submitting ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: (e) => {
      setInternalState(utils.updater({submitting: true}, true));
      stepRef.current?.submit?.();
      e.preventDefault();
    },
    ...(save ? SaveActionButtonProps : {})
  }], [save, wizard.state, SaveActionButtonProps]);

  const renderStepWrapper = () => {
    return <Box className="Wizard-step">
      {renderStep()}
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledWizard ref={innerRef} {...innerProps}
                       $dialog={Boolean(dialog)}
                       $wrapper={wrapper}
                       anchor="bottom"
                       variant="fixed"
                       offset={simple ? -1 : 0}
                       ToolbarProps={{
                         children: renderProgress(),
                         leftActions: showButtons ? prevActions : null,
                         center: <Box className="Wizard-navigation">
                           {showButtons ? renderStepper() : null}
                         </Box>,
                         rightActions: showButtons ? nextActions : null
                       }}>
    <Box ref={contentRef} className="Wizard-content">
      {Boolean(dialog) ? <SnackbarProvider SnackbarProps={{
                                             anchorOrigin: {
                                               vertical: 'bottom',
                                               horizontal: 'left'
                                             }
                                           }}>
        {renderStepWrapper()}
      </SnackbarProvider> : renderStepWrapper()}
    </Box>
    {renderInfo()}
  </StyledWizard>
});

Wizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  steps: PropTypes.array,
  initialData: PropTypes.object,
  showButtons: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  fieldData: PropTypes.object,
  onSubmit: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'simple']), PropTypes.string])
};

Wizard.defaultProps = {};

export default Wizard;
