import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SwitchDialog from 'components/organisms/Dialogs/SwitchDialog/SwitchDialog';

const StyledSwitchClientDialog = styled(SwitchDialog)`
  &.SwitchClientDialog {
    .SwitchClientForm-clients {
      row-gap: ${props => props.theme.layout('1sp')};
      padding: 0;

      .ActionList-list {
        padding: 0 ${props => props.theme.spacing(2)} !important;
      }
    }
    .DialogContent {
      padding-bottom: ${props => props.theme.spacing(2)};
    }
  }

  .SwitchClientDialog {
    &-form {
      height: 100%;
      overflow: hidden;
      &-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    &-loading {
      display: none;
      flex-direction: row;
      align-items: center;
      column-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
      
      .MuiCircularProgress-root {
        width: ${props => props.theme.layout(24)} !important;
        height: ${props => props.theme.layout(24)} !important;
      }
    }
  }

  ${props => props.$switching ? css`
    .MuiDialog-paper {
      width: unset !important;
    }
    .DialogTitle, .SwitchDialog-actions, .SwitchDialog-title {
      display: none;
    }
    .SwitchClientDialog-form {
      display: none;
    }
    .SwitchClientDialog-loading {
      display: flex;
    }
    .DialogContent {
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)} !important;
    }
  ` : null};
  ${ComponentStyles}
`;

StyledSwitchClientDialog.propTypes = {
  theme: PropTypes.object
};

StyledSwitchClientDialog.defaultProps = {}

export default StyledSwitchClientDialog;
