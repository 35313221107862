import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntitiesTableFilters
  from 'components/organisms/TableFilters/DealflowEntitiesTableFilters/DealflowEntitiesTableFilters.styles';

const DealflowEntitiesTableFilters = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesTableFilters');

  return <StyledDealflowEntitiesTableFilters ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDealflowEntitiesTableFilters>
});

DealflowEntitiesTableFilters.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesTableFilters.defaultProps = {};

export default DealflowEntitiesTableFilters;
