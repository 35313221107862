import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledDealflowEntityProfileBarWrapper
  from 'components/templates/Wrappers/Dealflow/Entities/DealflowEntityProfileBarWrapper/DealflowEntityProfileBarWrapper.styles';
import DealflowEntityProfileBar from 'components/organisms/Bars/DealflowEntityProfileBar/DealflowEntityProfileBar';

const DealflowEntityProfileBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityProfileBarWrapper');

  const profileProvider = useProfile();

  innerProps.variant = innerProps.variant ?? (profileProvider.state.isEditing ? 'sticky' : 'inset');

  return <StyledDealflowEntityProfileBarWrapper ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDealflowEntityProfileBarWrapper>
});

DealflowEntityProfileBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityProfileBarWrapper.defaultProps = {
  children: 'DealflowEntityProfileBarWrapper text',
  ToolbarComponent: DealflowEntityProfileBar
};

export default DealflowEntityProfileBarWrapper;
