import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Line} from 'recharts';

const StyledActionLine = styled(Line)`
  &.ActionLine-clickable {
    cursor: pointer;
  }
  
  .LineChartDot-clickable {
    cursor: pointer;
  }
  
  ${ComponentStyles}
`;

StyledActionLine.propTypes = {
  theme: PropTypes.object
};

StyledActionLine.defaultProps = {
}

export default StyledActionLine;
