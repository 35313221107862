import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledBrowserList from 'components/organisms/Browsers/BrowserList/BrowserList.styles';
import Browser from 'components/organisms/Browsers/Browser/Browser';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import constants from 'helpers/constants';

const BrowserList = React.forwardRef((props, ref) => {
  const {
    items,
    selectedId,
    BrowserProps,
    ...innerProps
  } = useComponentProps(props, 'BrowserList');

  const selectedIdx = items.findIndex((item) => selectedId === item.id);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBrowserList ref={ref} {...innerProps}>
    {items.map((item, idx) => {
      return <Debounce key={item.id}
                       timeout={Math.max(constants.debounce.minimal, Math.abs(selectedIdx - idx) * constants.debounce.browser)}>
        <Browser className={`BrowserList-browser ${selectedId === item.id ? 'visible' : ''}`}
                 url={item.url}
                 screenshots={item.screenshots}
                 {...BrowserProps}/>
      </Debounce>
    })}
  </StyledBrowserList>
});

BrowserList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  items: PropTypes.array,
  selected: PropTypes.object,
  BrowserProps: PropTypes.object
};

BrowserList.defaultProps = {
  showControls: false,
  color: 'primary'
};

export default BrowserList;
