import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';
import {rgba} from 'polished';

const StyledCollectionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 234/192;
  border: 1px solid ${props => props.theme.property('palette.divider')};
  border-radius: ${props => props.theme.radius(2)};
  background-color: ${props => rgba(props.theme.property('palette.primary.main'), 0.02)};

  .CollectionCard-dealflow-chart {
    .dealflowCount {
      color: ${props => props.theme.property('palette.text.primary')};
    }
  }
  
  &:hover {
    .CollectionCard-dealflow-chart {
      .Icon {
        color: ${props => props.theme.property('palette.primary.main')}
      }
    }
  }
  
  &:not(:hover) {
    .CollectionCard-dealflow-chart {
      path {
        fill: ${props => props.theme.property('palette.divider')};
      }
      .Icon {
        color: ${props => props.theme.property('palette.divider')};
      }
    }
  }
  
  .CollectionCard {
    &-dealflow {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-grow: 1;
      padding: ${props => props.theme.spacing(3.25)} ${props => props.theme.spacing(5.125)};
      width: 100%;
      height: 100%;

      &-favorite {
        position: absolute;
        top: ${props => props.theme.layout(4)};
        right:  ${props => props.theme.layout(4)};
      }
      .Checkbox {
        height: ${props => props.theme.layout('4sp')};
        width: ${props => props.theme.layout('4sp')};
      }

      &-chart {
        pointer-events: none;

        position: relative;
        aspect-ratio: 2/1;
        width: 100%;

        .DealflowPieChart {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow-y: hidden;
          
          &-chart {
            height: 100%;
            &-wrapper {
              height: 100%;
            }
          }
        }
      }
    }
    
    &-content {
      display: flex;
      align-items: center;
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      background-color: ${props => props.theme.property('palette.background.paper')};
      padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
      user-select: none;
      
      .Icon {
        font-size: ${props => props.theme.fontSize(32)};
      }
      
      &-text {
        display: flex;
        flex-direction: column;
        padding: 0 ${props => props.theme.spacing(1.125)};
        text-align: left;
        color: ${props => props.theme.property('palette.text.primary')};
        overflow: hidden;
        a {
          text-decoration: none;
        }
        .Typography {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .Typography-caption {
          margin-top: ${props => props.theme.layout(-4)};
        }
      }
    }
  }
  
  &.CollectionCard-isLoading {
    pointer-events: none;
  }
  
  ${ComponentStyles}
`;

StyledCollectionCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionCard.defaultProps = {}

export default StyledCollectionCard;
