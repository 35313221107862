import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledConfirmDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-start;
    
    .MuiDialog-paper {
      margin-top: 16vh;
    }
  }
  
  .DialogHeader {
    border-bottom: unset;
  }
  
  .DialogContent {
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(4)} ${props => props.theme.spacing(3)};
    gap: ${props => props.theme.layout('2sp')};
    
    .ConfirmDialog {
      &-text { 
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('0.5sp')};
      }
      &-explanation {
        color: ${props => props.theme.property('palette.text.secondary')};
      }
      &-challenge {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('0.25sp')};
        
        .ConfirmDialog-challengeHelper {
          font-size: ${props => props.theme.fontSize(13)} !important;
        }
        .FormField-name-test {
          display: none;
        }
      }
    }
  }

  .DialogFooter {
    border-top: unset;
  }
  
  &.ConfirmDialog-size-large {
    .MuiDialog-paper {
      min-width: clamp(0px, ${props => props.theme.layout(744)}, 86vw);
      max-width: clamp(0px, ${props => props.theme.layout(744)}, 86vw);
    }
  }
  &.ConfirmDialog-size-medium {
    .MuiDialog-paper {
      min-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
      max-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
    }
  }
  &.ConfirmDialog-size-small {
    .MuiDialog-paper {
      min-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
      max-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    }
  }
  
  ${ComponentStyles}
`;

StyledConfirmDialog.propTypes = {
  theme: PropTypes.object
};

StyledConfirmDialog.defaultProps = {}

export default StyledConfirmDialog;
