import React, {useLayoutEffect, useMemo} from 'react'
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import EntitiesTableProvider from 'components/organisms/Providers/TableProvider/EntitiesTableProvider';
import {useEffectEvent, useProviderDataState, useProviderView} from 'helpers/hooks/utils';
import {useEntitySearch} from 'services/entity/entity.hooks';
import {
  useDatabaseEntityColumns,
  useDatabaseEntityFilterGroups,
  useDatabaseEntitySelected
} from 'services/entity/entity.utils';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import {useCollectionGet} from 'services/collection/collection.hooks';

const DatabaseEntitiesTableLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState();

  const list = useEntitySearch({
    clientId: tableProvider.context?.data?.clientId,
    query: listState.query,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enablePreload: true,
    enabled: (active && listState.active) && utils.isDefined(listState.query) && tableProvider.context?.data?.clientId >= 0
  });

  const setListEvent = useEffectEvent(tableProvider.setList);
  useLayoutEffect(() => {
    setListEvent?.(list);
  }, [setListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const DatabaseEntitiesTableProvider = (props) => {
  const clientId = useAuthClientId();

  const view = useProviderView('database.entity', props.skipView);

  const client = useClientGet({clientId}, {enabled: Boolean(!props.context && clientId >= 0)});

  const [context, onContext] = useProviderDataState(props.context ?? client);

  const collection = useCollectionGet({collectionId: client?.data?.universeCollectionId},
    {enabled: Boolean(client?.data?.universeCollectionId)});

  const columns = useDatabaseEntityColumns(view);
  const filterGroups = useDatabaseEntityFilterGroups(view, collection?.data);

  const isLoading = props?.isLoading || collection.status?.isLoading;
  
  const columnDefinitions = useMemo(() => {
    return columns?.length > 0 ? columns : null;
  }, [columns]);

  const filterGroupDefinitions = useMemo(() => {
    return filterGroups?.length > 0 ? filterGroups : null;
  }, [filterGroups]);

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        type: constants.appState.type.local,
        name: `databaseEntities`,
        searchParams: true
      }
    },
    testListState: {
      initial: {
        active: false,
        pageSize: 0
      },
      options: {
        name: `databaseEntities_test`
      }
    },
    listSelection: {
      options: {
        max: constants.selection.max.database
      }
    },
    columnState: {
      initial: {},
      options: {
        name: `databaseEntities`,
        type: constants.appState.type.local
      }
    },
    columnDefinitions,
    filterGroupDefinitions
  }), [columnDefinitions, filterGroupDefinitions]);

  const fieldData = useMemo(() => {
    return {};
  }, []);

  const selectedItems = useDatabaseEntitySelected(context?.data?.clientId);
  const updaters = useMemo(() => {
    return {
      selectedItems: selectedItems,
    };
  }, [selectedItems]);

  return <EntitiesTableProvider {...props}
                                view={view}
                                options={options}
                                context={context}
                                onContext={onContext}
                                fieldData={fieldData}
                                updaters={updaters}
                                LoaderComponent={DatabaseEntitiesTableLoader}
                                isLoading={isLoading}>
    {props.children}
  </EntitiesTableProvider>
};

DatabaseEntitiesTableProvider.propTypes = {
}

export default DatabaseEntitiesTableProvider;
