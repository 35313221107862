import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntityProfilePage
  from 'components/pages/Database/Entity/DatabaseEntityProfilePage/DatabaseEntityProfilePage.styles';
import DatabaseEntityProfile from 'components/organisms/Profiles/DatabaseEntityProfile/DatabaseEntityProfile';

const DatabaseEntityProfilePage = (props) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityProfilePage');

  return <StyledDatabaseEntityProfilePage {...innerProps}>
    <DatabaseEntityProfile />
  </StyledDatabaseEntityProfilePage>
};

DatabaseEntityProfilePage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityProfilePage.defaultProps = {
};

export default DatabaseEntityProfilePage;
