import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTableColumns from 'components/organisms/TableColumns/EntitiesTableColumns/EntitiesTableColumns';

const StyledCollectionEntitiesTableColumns = styled(EntitiesTableColumns)`
  ${ComponentStyles}
`;

StyledCollectionEntitiesTableColumns.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTableColumns.defaultProps = {}

export default StyledCollectionEntitiesTableColumns;
