import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader.styles';
import {H5} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InfoPaper from 'components/molecules/Papers/InfoPaper/InfoPaper';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import dom from 'helpers/dom';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';

const SettingsHeader = React.forwardRef((props, ref) => {
  const {
    title,
    info,
    buttons,
    search,
    searchField,
    onSearch,
    ...innerProps
  } = useComponentProps(props, 'SettingsHeader', {
    children: ['title', 'action'],
    styled: ['color']
  });

  const [showInfo, setShowInfo] = useState(false);

  const showSearch = Boolean(onSearch);

  const infoAction = useMemo(() => ({
    tooltip: 'Show info',
    onClick: () => setShowInfo((current) => !current),
    icon: InfoOutlined
  }), []);

  const handleSearch = (field, value) => {
    onSearch?.(value);
  }

  const searchFieldRef = useRef(null);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller',
      debounce: constants.debounce.search,
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: () => {
        searchFieldRef.current?.clear();
        dom.focusElement(searchFieldRef.current);
      }
    }} /> : null,
    ...searchField
  }]), [search, searchField]);

  return <StyledSettingsHeader ref={ref} {...innerProps}>
    <Box className="SettingsHeader-head">
      <Box className="SettingsHeader-title">{utils.isReactElement(title) ? title : <H5>{title}</H5>}</Box>
      {(buttons || info || searchFields) ? <Box className="SettingsHeader-buttons">
        {info ? <ActionIconButton variant="outlined"
                                  action={infoAction}
                                  flatBackground={true}
                                  radius="round"
                                  density="denser" /> : null}
        {showSearch ? <InlineForm className="SettingsHeader-search"
                                  onChange={handleSearch}
                                  fields={searchFields} /> : null}
        {buttons}
      </Box> : null}
    </Box>
    {showInfo ? <ClickAwayListener onClickAway={() => setShowInfo(false)}>
      <Grow in={showInfo}>
        <InfoPaper className="SettingsHeader-info" info={info} elevation={1} />
      </Grow>
    </ClickAwayListener>: null}
  </StyledSettingsHeader>
});

SettingsHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  info: PropTypes.any,
  buttons: PropTypes.array,
  search: PropTypes.string,
  searchField: PropTypes.object,
  onSearch: PropTypes.func
};

SettingsHeader.defaultProps = {};

export default SettingsHeader;
