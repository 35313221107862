import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledMenuList from 'components/atoms/Menus/MenuList/MenuList.styles';

const MenuList = React.forwardRef((props, ref) => {
  const {
    density,
    ...innerProps
  } = useComponentProps(props, 'MenuList');

  innerProps.dense = innerProps.dense ?? density === 'dense';

  return <StyledMenuList ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledMenuList>
});

MenuList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

MenuList.defaultProps = {
  children: 'Menu text'
};

export default MenuList;
