import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityBrowserBar from 'components/organisms/Bars/EntityBrowserBar/EntityBrowserBar';

const StyledDatabaseEntityBrowserBar = styled(EntityBrowserBar)`
  ${ComponentStyles}
`;

StyledDatabaseEntityBrowserBar.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityBrowserBar.defaultProps = {
};

export default StyledDatabaseEntityBrowserBar;
