import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesTableBarWrapper/DatabaseEntitiesTableBarWrapper.styles';
import DatabaseEntitiesTableBar from 'components/organisms/Bars/DatabaseEntitiesTableBar/DatabaseEntitiesTableBar';

const DatabaseEntitiesTableBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTableBarWrapper');

  return <StyledDatabaseEntitiesTableBarWrapper ref={ref} {...innerProps}
                                                variant="sticky">
    {innerProps.children}
  </StyledDatabaseEntitiesTableBarWrapper>
});

DatabaseEntitiesTableBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTableBarWrapper.defaultProps = {
  ToolbarComponent: DatabaseEntitiesTableBar
};

export default DatabaseEntitiesTableBarWrapper;
