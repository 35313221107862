import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCheckbox from 'components/atoms/Inputs/Checkbox/Checkbox.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Checkbox = withMemo(React.forwardRef((props, ref) => {
  const {
    size,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Checkbox', {
    static: ['customIcon']
  });

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.variant = isLoading ? 'circular' : innerProps.variant;

  innerProps.className = utils.flattenClassName(innerProps.className, {
    customIcon: innerProps.icon || innerProps.checkedIcon
  });

  return <StyledCheckbox ref={ref} {...innerProps} size={size === 'smaller' ? 'small' : size}/>
}));

Checkbox.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool
};

Checkbox.defaultProps = {
  color: 'primary'
};

export default Checkbox;
