import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledBaseTableCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: inherit;
  overflow: hidden;
  
  && {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }

  padding: ${props => props.theme.layout(8)} 0;
  &:has(.ChipList .Chip-size-medium) {
    padding: ${props => props.theme.layout(2)} 0;
  }
  
  &:has(.ChipList-compact:not(.ChipList-nowrap)) {
    align-items: unset;
  }
  
  ${ComponentStyles}
`;

StyledBaseTableCell.propTypes = {
  theme: PropTypes.object
};

StyledBaseTableCell.defaultProps = {
}

export default StyledBaseTableCell;
