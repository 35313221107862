import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSourceCreateSectionPanelContent = styled(Box)` 
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${props => props.theme.layout('1sp')};
  padding: ${props => props.theme.spacing(2)};

  .DataList {
    .DataList-list {
      .ListItem {
        > * {
          width: 100%;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSourceCreateSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledSourceCreateSectionPanelContent.defaultProps = {}

export default StyledSourceCreateSectionPanelContent;
