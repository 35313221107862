import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityProfileFoldout
  from 'components/organisms/Foldouts/CollectionEntityProfileFoldout/CollectionEntityProfileFoldout.styles';

const CollectionEntityProfileFoldout = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityProfileFoldout');

  return <StyledCollectionEntityProfileFoldout ref={ref} {...innerProps} />
});

CollectionEntityProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityProfileFoldout.defaultProps = {};

export default CollectionEntityProfileFoldout;
