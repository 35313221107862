import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableRow from '@mui/material/TableRow';

const StyledTableRow = styled(TableRow)`
  ${ComponentStyles}
`;

StyledTableRow.propTypes = {
  theme: PropTypes.object
};

StyledTableRow.defaultProps = {
}

export default StyledTableRow;
