import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Step from '@mui/material/Step';

const StyledStep = styled(Step)` 
  ${ComponentStyles}
`;

StyledStep.propTypes = {
  theme: PropTypes.object
};

StyledStep.defaultProps = {}

export default StyledStep;
