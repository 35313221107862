import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledPage = styled(Box)`
  height: 100%;

  width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
  min-height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));
  overflow: hidden;
  
  ${ComponentStyles}
`;

StyledPage.propTypes = {
  theme: PropTypes.object
};

StyledPage.defaultProps = {
};

export default StyledPage;
