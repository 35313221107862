import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesTableBarWrapper/DealflowEntitiesTableBarWrapper.styles';
import DealflowEntitiesTableBar from 'components/organisms/Bars/DealflowEntitiesTableBar/DealflowEntitiesTableBar';

const DealflowEntitiesTableBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesTableBarWrapper');

  return <StyledDealflowEntitiesTableBarWrapper ref={ref} {...innerProps}
                                                variant="sticky">
    {innerProps.children}
  </StyledDealflowEntitiesTableBarWrapper>
});

DealflowEntitiesTableBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesTableBarWrapper.defaultProps = {
  ToolbarComponent: DealflowEntitiesTableBar
};

export default DealflowEntitiesTableBarWrapper;
