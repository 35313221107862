import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ImageList from '@mui/material/ImageList';

const StyledImageList = styled(ImageList)` 
  ${ComponentStyles}
`;

StyledImageList.propTypes = {
  theme: PropTypes.object
};

StyledImageList.defaultProps = {}

export default StyledImageList;
