import React from 'react'
import {RecoilRoot} from 'recoil';

const StoreProvider = (props) => {
  return <RecoilRoot>
    {props.children}
  </RecoilRoot>
};

StoreProvider.propTypes = {
}

export default StoreProvider;
