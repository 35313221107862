import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TableColumns from 'components/organisms/TableColumns/TableColumns/TableColumns';

const StyledEntitiesTableColumns = styled(TableColumns)`
  ${ComponentStyles}
`;

StyledEntitiesTableColumns.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesTableColumns.defaultProps = {}

export default StyledEntitiesTableColumns;
