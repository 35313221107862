import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useTestRoutes from 'routes/test/test.routes';
import useDevRoutes from 'routes/dev/dev.routes';
import useAuthRoutes from 'routes/auth/auth.routes';
import useDashboardRoutes from 'routes/dashboard/dashboard.routes';
import useCollectionsRoutes from 'routes/collections/collections.routes';
import useErrorRoutes from 'routes/error/error.routes';
import usePortalRoutes from 'routes/portal/portal.routes';
import useDatabaseRoutes from 'routes/database/database.routes';
import useDealflowRoutes from 'routes/dealflow/dealflow.routes';
import useSettingsRoutes from 'routes/settings/settings.routes';
import ClientProfileProvider from 'components/organisms/Providers/ProfileProvider/ClientProfileProvider';
import SideMenuWrapper from 'components/templates/Wrappers/Menus/SideMenuWrapper/SideMenuWrapper';
import TaskProfileWrapper from 'components/templates/Wrappers/Tasks/TaskProfileWrapper/TaskProfileWrapper';
import utils from 'helpers/utils';

export default function useRootRoutes () {
  const collectionsRoutes = useCollectionsRoutes();
  const testRoutes = useTestRoutes();
  const authRoutes = useAuthRoutes();
  const portalRoutes = usePortalRoutes();
  const dashboardRoutes = useDashboardRoutes();
  const dealflowRoutes = useDealflowRoutes();
  const databaseRoutes = useDatabaseRoutes();
  const settingsRoutes = useSettingsRoutes();
  const errorRoutes = useErrorRoutes();
  const devRoutes = useDevRoutes();

  return useMemo(() => {
    return [
      { // self layout
        path: 'auth',
        element: <authRoutes.WrapperComponent><Outlet /></authRoutes.WrapperComponent>,
        children: authRoutes.route
      },
      { // self layout
        path: 'error',
        element: <errorRoutes.WrapperComponent><Outlet /></errorRoutes.WrapperComponent>,
        children: errorRoutes.route
      },
      { // self layout
        path: 'portal',
        element: <portalRoutes.WrapperComponent><Outlet /></portalRoutes.WrapperComponent>,
        children: portalRoutes.route,
        handle: {
          auth: utils.createAuth({attribute: 'portal.route'})
        }
      },
      { // self layout
        path: 'dev',
        element: <devRoutes.WrapperComponent><Outlet /></devRoutes.WrapperComponent>,
        children: devRoutes.route,
        handle: {
          auth: utils.createAuth({attribute: 'dev.route'})
        }
      },
      {
        path: 'test',
        element: <testRoutes.WrapperComponent><Outlet /></testRoutes.WrapperComponent>,
        children: testRoutes.route,
        handle: {
          auth: utils.createAuth({attribute: 'test.route'})
        }
      },
      {
        // it renders this element
        element: <ClientProfileProvider>
          <TaskProfileWrapper>
            <SideMenuWrapper>
              <Outlet />
            </SideMenuWrapper>
          </TaskProfileWrapper>
        </ClientProfileProvider>,

        // when the URL matches this segment
        path: '',

        // with this data loaded before rendering
        loader: null,

        // performing this mutation when data is submitted to it
        action: null,

        // and renders this element in case something went wrong
        errorElement: null,

        // custom options like scrollKey,
        // pathname means going to this page will scroll to the last position always
        // default: keep scroll for every instance in history, clicking will go to top
        handle: {},

        children: [
          {
            path: '',
            element: <dashboardRoutes.WrapperComponent><Outlet /></dashboardRoutes.WrapperComponent>,

            handle: {
              //scrollKey: 'full', // slow on infinite scroll
              auth: utils.createAuth({ attribute: 'dashboard.route' })
            },

            children: dashboardRoutes.route
          },
          {
            path: 'collections',
            element: <collectionsRoutes.WrapperComponent><Outlet /></collectionsRoutes.WrapperComponent>,

            handle: {
              //scrollKey: 'full', // slow on infinite scroll
              auth: utils.createAuth({ attribute: 'collection.route' })
            },

            children: collectionsRoutes.route
          },
          {
            path: 'database',
            element: <databaseRoutes.WrapperComponent><Outlet /></databaseRoutes.WrapperComponent>,

            handle: {
              //scrollKey: 'full', // slow on infinite scroll
              auth: utils.createAuth({ attribute: 'database.route' })
            },

            children: databaseRoutes.route
          },
          {
            path: 'dealflow',
            element: <dealflowRoutes.WrapperComponent><Outlet /></dealflowRoutes.WrapperComponent>,

            handle: {
              //scrollKey: 'full', // slow on infinite scroll
              auth: utils.createAuth({ attribute: 'dealflow.route' })
            },

            children: dealflowRoutes.route
          },
          {
            path: 'settings',
            element: <settingsRoutes.WrapperComponent><Outlet /></settingsRoutes.WrapperComponent>,

            handle: {
              //scrollKey: 'pathname'
              auth: utils.createAuth({ attribute: 'settings.route' })
            },

            children: settingsRoutes.route
          }
        ]
      },
    ];
  }, [authRoutes, errorRoutes, devRoutes, testRoutes, portalRoutes,
    dashboardRoutes, collectionsRoutes, dealflowRoutes, databaseRoutes, settingsRoutes]);
}
