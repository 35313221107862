import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText.styles';
import {withMemo} from 'helpers/wrapper';

const FormHelperText = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'FormHelperText');

  return <StyledFormHelperText ref={ref} {...innerProps}>{innerProps.children}</StyledFormHelperText>
}));

FormHelperText.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FormHelperText.defaultProps = {
  children: 'FormHelperText text'
};

export default FormHelperText;
