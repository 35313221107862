import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import CollectionEntitiesTableFilters
  from 'components/organisms/TableFilters/CollectionEntitiesTableFilters/CollectionEntitiesTableFilters';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionEntitiesFilterWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesFiltersWrapper/CollectionEntitiesFiltersWrapper.styles';

const CollectionEntitiesFiltersWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesFiltersWrapper');

  const tableProvider = useTable();

  return <StyledCollectionEntitiesFilterWrapper ref={ref} {...innerProps}
                                                variant="foldout"
                                                context={<CollectionEntitiesTableFilters />}
                                                open={Boolean(tableProvider.state?.showFilters)}
                                                size="oversize"
                                                ContextDrawerProps={{
                                                  onToggle: (e, open) => {
                                                    if (open) {
                                                      tableProvider.openFilters();
                                                    } else {
                                                      tableProvider.closeFilters();
                                                    }
                                                  }
                                                }}>
    {innerProps.children}
  </StyledCollectionEntitiesFilterWrapper>
});

CollectionEntitiesFiltersWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesFiltersWrapper.defaultProps = {
  children: 'CollectionEntitiesFiltersWrapper text',
};

export default CollectionEntitiesFiltersWrapper;
