import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Drawer from 'components/atoms/Drawers/Drawer/Drawer';
import utils from 'helpers/utils';

const StyledContextDrawer = styled(Drawer)`
  pointer-events: none;
  z-index: ${props => props.theme.property('zIndex.context')};
  
  .ContextDrawer {
    &-content {
      flex-grow: 1;
      height: 1px;
      overflow: hidden;
      
      .ContextWrapper {
        &-content {
          overflow: hidden;
        }
      }
    }
  }

  > .MuiPaper-root {
    top: 0;
    position: absolute;
    height: 100%;
    z-index: ${props => props.theme.property('zIndex.context')};
    pointer-events: all;
  }
  
  &.ContextDrawer-persistent {
    position: sticky;
    top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
    > .MuiPaper-root {
      position: sticky;
      top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
      z-index: ${props => props.theme.property('zIndex.context')};
    }
  }

  &.ContextDrawer-anchor {
    &-left {
      left: ${props => props.$wrapper?.boundsCum?.left ?? 0};
      > .MuiPaper-root {
        left: ${props => props.$wrapper?.boundsCum?.left ?? 0};
      }
    }

    &-right {
      right: ${props => props.$wrapper?.boundsCum?.right ?? 0};
      > .MuiPaper-root {
        right: ${props => props.$wrapper?.boundsCum?.right ?? 0};
      }
    }
  }
  
  &.ContextDrawer-temporary {
    &.ContextDrawer-open {
      pointer-events: all;
    }
    
    &.ContextDrawer-showing {
      > .MuiPaper-root {
        transition: ${props => props.theme.transitions.create(['transform'], {
          easing: props.theme.property('transitions.easing.easeInOut'),
          duration: props.theme.property('transitions.duration.enteringScreen')
        })}, ${props => props.theme.transitions.create(['width'], {
          easing: props.theme.property('transitions.easing.easeIn'),
          duration: props.theme.property('transitions.duration.enteringScreen')
        })} !important;
      }
    }
  }
  
  &.ContextDrawer > .MuiPaper-root {
    width: ${props => utils.isDefined(props.$width) ? props.$width : null};
  }

  &.ContextDrawer-persistent {
    &:not(.ContextDrawer-open) > .MuiPaper-root {
      width: 0;
      border-width: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledContextDrawer.propTypes = {
  theme: PropTypes.object
};

StyledContextDrawer.defaultProps = {}

export default StyledContextDrawer;
