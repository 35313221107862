import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledInlineForm from 'components/organisms/Forms/InlineForm/InlineForm.styles';
import {withMemo} from 'helpers/wrapper';

const InlineForm = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    onChange,
    onChangeDirect,
    onValidating,
    onBusy,
    onReset,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'InlineForm');

  const changedRef = useRef({});
  const [internalState, setInternalState] = useState({
    error: {},
    success: {}
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const applyChanges = (values, errors, fields) => {
    const changedFields = Object.keys(changedRef.current)
      .filter((k) => changedRef.current[k] === (values[k] ?? true))
      .map((k) => k);

    if (changedFields.length > 0) {
      changedFields.forEach((name) => {
        const field = fields?.find((field) => field.name === name);
        if (field && !(field.readOnly || field.disabled) && (field.onChange || onChange) && !errors?.[name]) {
          const value = utils.fieldValue2ConvertedValue(field, values?.[name]);

          const handleSuccess = (msg) => {
            onBusy?.(false);
            setInternalState((current) => {
              return utils.updater({
                ...current,
                success: {
                  ...current.success,
                  [field.parent?.name ?? name]: msg ?? 'Saved'
                },
                error: {
                  ...current.error,
                  [field.parent?.name ?? name]: null
                }
              })(current);
            })
          }

          const handleError = (err) => {
            setInternalState((current) => {
              return utils.updater({
                ...current,
                error: {
                  ...current.error,
                  [field.parent?.name ?? name]: err ?? 'Saving failed'
                },
                success: {
                  ...current.success,
                  [field.parent?.name ?? name]: null
                }
              })(current)
            })
          }

          field.onChange?.(
            value,
            handleSuccess,
            handleError
          );

          onChange?.(
            field,
            value,
            handleSuccess,
            handleError
          );
        }

        changedRef.current[name] = false;
      })
    }
  }

  const handleSubmit = (values, actions) => {
    if (onSubmit) {
      onSubmit?.(values, actions, fields);
    } else {
      actions.setSubmitting(false);
    }
  }

  const handleReset = () => {
    setInternalState(utils.updater({
      success: {},
      error: {}
    }, true));
  }

  const handleChange = (e) => {
    changedRef.current = {...changedRef.current, [e?.target?.name]: (e?.target?.value ?? true)};
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    field?.onChangeDirect?.(e);
    onChangeDirect?.(e);
    onBusy?.(true);

    setInternalState((current) => {
      return utils.updater({
        ...current,
        success: {
          ...current.success,
          [e?.target?.name]: null
        },
        error: {
          ...current.error,
          [e?.target?.name]: null
        }
      })(current);
    });
  };

  const handleValidating = (validating, isDirty, hasErrors, formik, fields) => {
    onValidating?.(validating, isDirty, hasErrors, formik, fields);

    onBusy?.((current) => current || Object.keys(internalState.error)
      .filter((k) => internalState.error[k]).length > 0 || hasErrors);

    if (!validating && isDirty) {
      applyChanges(formik?.values, formik?.errors, fields);
    }
  };

  const pages = useMemo(() => {
    return {
      0: {
        fields: fields?.reduce((o, field) => {
          o[field.name ?? field.title] = {
            ...field,
            FormFieldProps: utils.mergeObjects({
              error: internalState.error[field.name],
              success: internalState.success[field.name]
            }, field.FormFieldProps)
          }
          return o;
        }, {})
      }
    }
  }, [fields, internalState.error, internalState.success]);

  innerProps.onReset = handleReset;
  innerProps.onSubmit = handleSubmit;
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledInlineForm ref={innerRef} {...innerProps}
                           pages={pages}
                           onChange={handleChange}
                           onChangeDirect={handleChangeDirect}
                           onValidating={handleValidating}
                           showButtons={false} />
}));

InlineForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  onChange: PropTypes.func,
  onChangeDirect: PropTypes.func,
  onValidating: PropTypes.func,
  onBusy: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func
};

InlineForm.defaultProps = {
  autoFocus: true
};

export default InlineForm;
