import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCategoryField from 'components/molecules/Fields/CategoryField/CategoryField.styles';
import CategoryCard from 'components/molecules/Cards/CategoryCard/CategoryCard';
import {withMemo} from 'helpers/wrapper';

const CategoryField = withMemo(React.forwardRef((props, ref) => {
  const {
    CategoryCardProps,
    ...innerProps
  } = useComponentProps(props, 'CategoryField');

  const renderOption = (option, props) => {
    const handleClick = () => {
      props.onChange?.({
        target: {
          name: props.name,
          checked: !props.checked
        }
      })
    }

    return <CategoryCard title={option.title ?? option.label}
                         subtitle={option.subtitle ?? option.helper}
                         icon={option.icon}
                         iconColor={option.iconColor ?? option.color}
                         iconCount={option.iconCount}
                         chip={option.chip}
                         credits={option.price ?? option.credits}
                         fullWidth={true}
                         fullHeight={true}
                         selected={props.checked}
                         disabled={props.disabled}
                         onClick={handleClick}
                         {...CategoryCardProps}/>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCategoryField ref={ref} {...innerProps}
                              hiddenSelectionHelper={true}
                              renderOption={renderOption}/>
}));

CategoryField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CategoryField.defaultProps = {
};

export default CategoryField;
