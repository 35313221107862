import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function usePortalApplicantStatus () {
  return useServiceStatus(path);
}

export function usePortalApplicantList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function usePortalApplicantGet ({applicantId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {applicantId}, queryOptions);
}

export function usePortalApplicantAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function usePortalApplicantUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function usePortalApplicantDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

// other
export function usePortalApplicantUploadLogo (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'portal/applicants/:applicantId/uploadLogo',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}
