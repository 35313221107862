import utils from 'helpers/utils';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useClientTimelineList ({clientId, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, page, pageSize, ...rest}, queryOptions);
}

export function useTimelineMarkAllRead ( mutationOptions = {}) {
  // timelineId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'post', {timelineId: 0}, {
    refetchContext: true,
    overridePath: 'clients/:clientId/timeline/read', ...mutationOptions
  });
}

export function useTimelineMarkAllUnread ( mutationOptions = {}) {
  // commentId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'delete', {timelineId: 0}, {
    refetchContext: true,
    overridePath: 'clients/:clientId/timeline/read', ...mutationOptions
  });
}
