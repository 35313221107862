import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledButtonTableCell from 'components/molecules/TableCells/ButtonTableCell/ButtonTableCell.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {withMemo} from 'helpers/wrapper';

const ButtonTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    ActionButtonProps,
    ...innerProps
  } = useComponentProps(props, 'ButtonTableCell');

  return <StyledButtonTableCell ref={ref} {...innerProps}>
    <ActionButton {...ActionButtonProps} />
  </StyledButtonTableCell>
}));

ButtonTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionButtonProps: PropTypes.object
};

ButtonTableCell.defaultProps = {
};

export default ButtonTableCell;


