import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSwitchDialog from 'components/organisms/Dialogs/SwitchDialog/SwitchDialog.styles';
import DialogActions from 'components/atoms/Dialogs/DialogActions/DialogActions';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import DialogTitle from 'components/atoms/Dialogs/DialogTitle/DialogTitle';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import Box from 'components/atoms/Layout/Box/Box';

const SwitchDialog = React.forwardRef((props, ref) => {
  const {
    title,
    form,
    info,
    buttonActions,
    ActionButtonProps,
    ...innerProps
  } = useComponentProps(props, 'SwitchDialog', {
    children: ['title', 'content', 'actions'],
  });

  return <StyledSwitchDialog ref={ref} {...innerProps} elevation={24}>
    {title ? <DialogTitle className="SwitchDialog-title">
      {title}
    </DialogTitle> : null}
    <DialogContent className="SwitchDialog-content">
      {form}
    </DialogContent>
    {(info || buttonActions?.length > 0) ? <DialogActions className="SwitchDialog-actions">
      {info ? <Box className="SwitchDialog-info">{info}</Box>: null}
      {buttonActions?.map((action, idx) => <ActionButton key={idx}
                                                         action={action}
                                                         color="primary"
                                                         {...ActionButtonProps}/>)}
    </DialogActions> : null}
  </StyledSwitchDialog>
});

SwitchDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  form: PropTypes.element,
  buttonActions: PropTypes.array,
  ActionButtonProps: PropTypes.object
};

SwitchDialog.defaultProps = {};

export default SwitchDialog;
