import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledSettingsHeaderWrapper = styled(ToolbarWrapper)`
  min-height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));

  .HeaderBar {
    height: fit-content;
    border-bottom: unset;
    background-color: unset;
    
    .PageBar {
      &-toolbar {
        height: fit-content;
        padding: 0 !important;
      }
      &-center-wrapper {
        width: 100%;
        flex: unset;
        left: 0;
        position: relative;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSettingsHeaderWrapper.propTypes = {
  theme: PropTypes.object
};

StyledSettingsHeaderWrapper.defaultProps = {}

export default StyledSettingsHeaderWrapper;
