import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledActionChipList from 'components/molecules/Chips/ActionChipList/ActionChipList.styles';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const ActionChipList = (props) => {
  const {
    actions,
    ActionChipProps,
    ...innerProps
  } = useComponentProps(props, 'ActionChipList');

  return <StyledActionChipList {...innerProps}>
    {actions?.map((action, idx) => <ActionChip key={idx}
                                               action={action}
                                               {...ActionChipProps} />)}
  </StyledActionChipList>
};

ActionChipList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  ActionChipProps: PropTypes.object
};

ActionChipList.defaultProps = {
};

export default ActionChipList;
