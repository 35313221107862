import React from 'react';
import PropTypes from 'prop-types';
import {useComponent, useComponentProps} from 'helpers/hooks/utils';
import StyledIcon from 'components/atoms/Icons/Icon/Icon.styles';
import utils from 'helpers/utils';
import * as icons from 'assets/icons';
import Home from '@mui/icons-material/Home';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const Icon = withMemo(React.forwardRef((props, ref) => {
  const {
    icon,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Icon', {
    static: ['disabled']
  });

  const component = useComponent(icon);
  const iconComponent = utils.isString(icon) ? (icons[icon]) : component;

  innerProps.className = utils.flattenClassName(innerProps.className);

  const renderIcon = () => {
    return <StyledIcon ref={ref} as={iconComponent} {...innerProps} />;
  }

  if (isLoading) {
    return <Skeleton>{renderIcon()}</Skeleton>
  } else {
    return renderIcon();
  }
}));

Icon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
  color: PropTypes.string
};

Icon.defaultProps = {
  icon: Home
};

export default Icon;
