import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip';

const StyledBreakdownTooltip = styled(ChartTooltip)`
  .BreakdownTooltip {
    &-content {
      padding: ${props => props.theme.spacing(0.5)};
      min-width: ${props => props.theme.layout(160)};

      .Badge {
        .MuiBadge-badge {
          font-size: ${props => props.theme.fontSize(11)};
          height: ${props => props.theme.layout(16)};
        }
      }

      .Span {
        font-size: ${props => props.theme.fontSize(13)};
        margin-left: 0;
        margin-right: ${props => props.theme.layout('1sp')};
      }
      
      .Divider {
        margin-top: ${props => props.theme.spacing(0.75)};
        margin-bottom: ${props => props.theme.spacing(0.75)};
      }

      .title {
        display: flex;
        align-items: center;
        padding: ${props => props.theme.spacing(0.5)} 0;
        ${props => props.theme.font2Css('caption')};
        &:not(:has(.Icon, .Avatar)) {
          font-weight: 500;
        }
        
        .MuiBadge-badge {
          background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
          color: ${props => props.theme.property('palette.text.primary')};
        }
        
        .Icon {
          font-size: inherit;
          height: ${props => props.theme.layout(12)};
          width: ${props => props.theme.layout(12)};
          margin-right: ${props => props.theme.layout('0.5sp')};

          &.Avatar {
            font-size: ${props => props.theme.fontSize(13)};
            height: ${props => props.theme.layout(16)};
            width: ${props => props.theme.layout(16)};
            margin-right: ${props => props.theme.layout('0.5sp')};
          }
        }

        .Avatar {
          margin-right: ${props => props.theme.spacing(1)};
        }
        
        .Span {
          flex-grow: 1;
        }
      }
      
      .Divider {
        margin-left: ${props => props.theme.layout('-1sp')};
        margin-right: ${props => props.theme.layout('-1sp')};
      }
    }
  }

  &.BreakdownTooltip-extended, &.BreakdownTooltip-breakdown {
    .BreakdownTooltip-content {
      min-width: ${props => props.theme.layout(180)};
      .title {
        ${props => props.theme.font2Css('subtitle2')};

        .MuiBadge-badge {
          background-color: ${props => props.theme.property('palette.primary.main')};
          color: ${props => props.theme.property('palette.primary.contrastText')};
        }
        
        .Span {
          font: inherit;
        }
        
        .Icon {
          font-size: inherit;
          height: ${props => props.theme.layout(16)};
          width: ${props => props.theme.layout(16)};

          &.Avatar {
            font-size: ${props => props.theme.fontSize(13)};
            height: ${props => props.theme.layout(16)};
            width: ${props => props.theme.layout(16)};
          }
        }
      }
      
      .extended {
        display: flex;
        flex-direction: column;
        font-size: ${props => props.theme.fontSize(13)};
        padding: ${props => props.theme.layout('0.5sp')} 0;
        gap: ${props => props.theme.layout(props.$gap)};

        .Avatar, .Icon {
          margin-right: ${props => props.theme.layout('0.5sp')};
        }
        
        .breakdown {
          display: flex;
          align-items: center;

          .Span {
            flex-grow: 1;
            font-weight: 400;
            font-size: ${props => props.theme.fontSize(13)};
          }

          .Badge {
            .MuiBadge-badge {
              background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
              color: ${props => props.theme.property('palette.text.primary')};
            }
          }
        }
      }
    }
  }
  
  &.BreakdownTooltip-density-sparse {
    .BreakdownTooltip {
      &-content {
        .Span {
          margin-right: ${props => props.theme.layout('3sp')};
        }
      }
    }
  }

  &.BreakdownTooltip-density-dense {
    .BreakdownTooltip {
      &-content {
        min-width: unset;
        
        .Span {
          margin-right: ${props => props.theme.layout('3sp')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledBreakdownTooltip.propTypes = {
  theme: PropTypes.object
};

StyledBreakdownTooltip.defaultProps = {
}

export default StyledBreakdownTooltip;
