import React, {Children, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledCards from 'components/atoms/Cards/Cards/Cards.styles';
import SortableItem from 'components/organisms/Utils/DragDrop/SortableItem';
import SortableContext from 'components/organisms/Utils/DragDrop/SortableContext';
import SortableOverlay from 'components/organisms/Utils/DragDrop/SortableOverlay';
import DndMonitor from 'components/organisms/Utils/DragDrop/DndMonitor';
import constants from 'helpers/constants';
import {horizontalListSortingStrategy, rectSortingStrategy, verticalListSortingStrategy} from '@dnd-kit/sortable';

const Cards = React.forwardRef((props, ref) => {
  const {
    columns,
    variant,
    orientation,
    sortable,
    containerId,
    reorder,
    renderOverlay,
    dragHandle,
    onCanDrag,
    onDragStart,
    onDragOver,
    onDragDrop,
    onDragStop,
    SortableContextProps,
    ...innerProps
  } = useComponentProps(props, 'Cards', {
    styled: ['gap'],
    static: ['reverse'],
    variable: ['orientation'],
    children: ['column']
  });

  const innerRef = useRef(null);
  const [internalState, setInternalState] = useState({
    added: [],
    removed: [],
    reorder: [],
    initialised: false,
    reverse: false
  });

  const masonry = variant === 'masonry';
  const horizontal = orientation === 'horizontal';

  const bBox = useBbox(() => innerRef.current, ['height', 'width']);

  const cards = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState
    },
    reset: () => {
      setInternalState((current) => ({
        ...current,
        added: [], removed: [], reorder: []
      }));
    }
  }), [internalState]);

  useImperativeHandle(ref, () => cards);

  const items = useMemo(() => {
    let items = [];
    if (innerProps.children) {
      items = Children.toArray(innerProps.children)
        .map((child, idx) => {
          return {
            id: child.props['data-key'] ?? idx,
            droppable: child.props['data-droppable'] !== false,
            position: (idx + 1) * 1000,
            data: child
          }
        });

      internalState.reorder.forEach((ro) => {
        const item = items.find((itm) => itm.id === ro.id);
        if (item) {
          item.position = ro.position;
        }
      });

      items = items
        .concat(internalState.added.filter((a) => !items.find((itm) => a.id === itm.id)))
        .filter((itm) => !internalState.removed.find((r) => r.id === itm.id))
        .sort((a, b) => a.position - b.position);

      items.forEach((item, idx) => {
        item.idx = idx;
      });
    }

    return items;
  }, [internalState.added, internalState.removed, internalState.reorder, innerProps.children]);

  // sortable
  const handleDragStart = ({active}) => {
    setInternalState(utils.updater({dragItem: active}, true));

    onDragStart?.();
  }

  const handleDragEnd = ({over}) => {
    const dragItem = internalState.dragItem;
    setInternalState(utils.updater({dragItem: null}, true));

    const sourceContainerId = dragItem?.data?.current?.sortable?.containerId;
    const overContainerId = over?.data?.current?.sortable?.containerId ?? over?.id;

    if (dragItem && overContainerId) {
      const overIndex = over?.data?.current?.sortable?.index ?? 0;

      if (overIndex >= 0) {
        if (containerId.toString() === overContainerId.toString()) {
          const reordering = containerId.toString() === sourceContainerId.toString();
          if (reorder || !reordering) {
            let itemIndex = items.findIndex((itm) => itm.id === dragItem.id);
            let position = items[overIndex].id === dragItem.id ? items[overIndex].position :
              (!reordering ? (
                (overIndex < (items.length - 1)) ? (items[overIndex]?.position - 1) : (items[overIndex]?.position + 1)
              ) : (
                itemIndex < overIndex ? items[overIndex]?.position + 1 : items[overIndex]?.position - 1
              ));

            let added = [...internalState.added];
            let reorder = [...internalState.reorder];
            const addedIndex = added.findIndex((a) => a.id === dragItem.id);
            if (addedIndex !== -1) {
              added[addedIndex].position = position;
            } else {
              const existing = items.find((a) => a.id === dragItem.id);
              reorder = reorder.filter((r) => r.id !== dragItem.id);
              reorder.push({
                ...existing,
                position
              });
            }

            setInternalState((current) => ({
              ...current,
              added: added,
              reorder: reorder
            }));
          }
        }

        if (containerId.toString() === sourceContainerId.toString()) {
          onDragDrop?.(dragItem.id, overContainerId, overIndex);
        }
      }
    }

    onDragStop?.();
  }

  const handleDragCancel = ({active}) => {
    setInternalState(utils.updater({dragItem: null}, true));

    const payload = active?.data?.current?.payload;
    if (payload) {
      setInternalState((current) => ({
        ...current,
        added: [], removed: []
      }));
    }

    onDragStop?.();
  }

  const handleDragOver = ({over}) => {
    const dragItem = internalState.dragItem;
    const overContainerId = over?.data?.current?.sortable?.containerId ?? over?.id;
    const sourceContainerId = dragItem?.data?.current?.sortable?.containerId;
    const overItem = Boolean(over?.data?.current?.sortable?.containerId);

    if (dragItem) {
      const payload = dragItem?.data?.current?.payload;

      if (payload) {
        if (overContainerId) {
          if (containerId.toString() === overContainerId.toString()) {
            if (!items.find((itm) => itm.id === dragItem.id)) {
              const added = internalState.added.concat((overContainerId.toString() !== sourceContainerId.toString()) ? [{
                id: dragItem.id,
                data: payload,
                droppable: true,
                position: overContainerId.toString() === sourceContainerId.toString() ?
                  dragItem?.data?.current?.position : (
                    overItem ? constants.numbers.maxInt : 0
                  )
              }] : []);
              const removed = internalState.removed.filter((r) => r.id !== dragItem.id);

              setInternalState((current) => ({
                ...current,
                added, removed
              }));

              onDragOver?.(added.map((itm) => itm.id), removed.map((itm) => itm.id));
            }
          } else {
            if (items.find((itm) => itm.id === dragItem.id)) {
              const added = internalState.added.filter((r) => r.id !== dragItem.id);
              const removed = internalState.removed.concat({id: dragItem.id});
              setInternalState((current) => ({
                ...current,
                added, removed
              }));

              onDragOver?.(added.map((itm) => itm.id), removed.map((itm) => itm.id));
            }
          }
        } else {
          const added = internalState.added.filter((r) => r.id !== dragItem.id);
          const removed = internalState.removed.filter((r) => r.id !== dragItem.id);
          setInternalState((current) => ({
            ...current,
            added, removed
          }));

          onDragOver?.(added.map((itm) => itm.id), removed.map((itm) => itm.id));
        }
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && internalState.dragItem) {
      const event = utils.createEvent('keydown', {...e});
      window.document.body.dispatchEvent(event);
      e.stopPropagation();
    }
  }

  const renderWrap = (item, props) => {
    if (sortable && item.droppable) {
      const draggable = onCanDrag?.(item.id) ?? true;
      return <SortableItem key={item.id}
                           id={item.id}
                           dragHandle={dragHandle}
                           payload={item.data}
                           position={item.position}
                           sourceContainerId={internalState.dragItem?.data?.current?.sortable?.containerId}
                           disableReorder={!reorder}
                           disabled={!draggable}
                           {...props}>
        {item.data}
      </SortableItem>
    } else {
      return utils.cloneElement(item.data, props);
    }
  }

  const cardsEl = innerRef.current?.children?.[0];
  const measureMasonry = masonry && columns > 1;
  useLayoutEffect(() => {
    if (cardsEl && (!measureMasonry || !internalState.initialised)) {
      cardsEl.style.height = (measureMasonry && !horizontal) ? '10000px' : 'unset';
      cardsEl.style.width = (measureMasonry && horizontal) ? '10000px' : 'unset';
    }
  }, [measureMasonry, items, cardsEl, horizontal, internalState.initialised]);

  useLayoutEffect(() => {
    if (cardsEl && items.length > 0 && measureMasonry) {
      const measure = () => {
        const cardsEls = Array.from(cardsEl?.querySelectorAll('.CardItem') ?? []);
        if (cardsEls?.length > 0) {
          if (horizontal) {
            let count = 0;
            const widths = cardsEls.reduce((o, c) => {
              const width = c.getBoundingClientRect().width;
              if (c.parentElement.className.includes('grid')) {
                new Array(columns).fill(null).forEach((i, idx) => {
                  o[idx + 1] = o[idx + 1] ?? 0;
                  o[idx + 1] += width + ((width > 0 && o[idx + 1] > 0) ? innerProps.$gap : 0);
                })
              } else {
                o[c.style.order] = o[c.style.order] ?? 0;
                o[c.style.order] += width + ((width > 0 && o[c.style.order] > 0) ? innerProps.$gap : 0);
              }

              if (width > 0) {
                count += 1;
              }
              return o;
            }, {});

            if (count > 0) {
              const max = Object.keys(widths).reduce((h, k) => Math.max(h, widths[k]), 0);
              const maxCol = Object.keys(widths).find((k) => widths[k] === max);

              cardsEl.style.width = columns > 1 ?
                `${Object.keys(widths).reduce((h, k) => Math.max(h, widths[k]), 0)}px` : 'unset';
              cardsEl.style.height = 'unset';

              setInternalState((current) => {
                return utils.updater({
                  ...current,
                  initialised: true,
                  reverse: current.reverse ? +maxCol === 1 : +maxCol > 1
                })(current)
              });
            }
          } else {
            let count = 0;
            const heights = cardsEls.reduce((o, c) => {
              const height = c.getBoundingClientRect().height;
              if (c.parentElement.className.includes('grid')) {
                new Array(columns).fill(null).forEach((i, idx) => {
                  o[idx + 1] = o[idx + 1] ?? 0;
                  o[idx + 1] += height + ((height > 0 && o[idx + 1] > 0) ? innerProps.$gap : 0);
                })
              } else {
                o[c.style.order] = o[c.style.order] ?? 0;
                o[c.style.order] += height + ((height > 0 && o[c.style.order] > 0) ? innerProps.$gap : 0);
              }

              if (height > 0) {
                count += 1;
              }
              return o;
            }, {});

            if (count > 0) {
              const max = Object.keys(heights).reduce((h, k) => Math.max(h, heights[k]), 0);
              const maxCol = Object.keys(heights).find((k) => heights[k] === max);

              cardsEl.style.height = columns > 1 ?
                `${Object.keys(heights).reduce((h, k) => Math.max(h, heights[k]), 0)}px` : 'unset';
              cardsEl.style.width = 'unset';

              setInternalState((current) => {
                return utils.updater({
                  ...current,
                  initialised: true,
                  reverse: current.reverse ? +maxCol === 1 : +maxCol > 1
                })(current)
              });
            }
          }
        }
      }

      return utils.observeRetry(measure, 10);
    }
  }, [bBox, cardsEl, measureMasonry, horizontal, items, columns, innerProps.$gap]);

  const renderChildren = () => {
    const newRow = () => ({
      columns: [],
      spanned: 0
    });

    let rows = [];

    let nextIndex = 0, span, rspan, anchor, lastColumn = null, lastAnchor = null, currentRow, addColumn;
    while (nextIndex < items.length) {
      const item = items[nextIndex];
      span = item.data.props.span ?? 1;
      rspan = item.data.props.rows;
      anchor = item.data.props.anchor;

      if (span > columns) {
        span = columns;
      }

      addColumn = (!utils.isDefined(anchor) || (anchor !== lastAnchor));

      if (!currentRow || !addColumn || (currentRow.spanned + span) > columns) {
        currentRow = newRow();
        currentRow.reverse = anchor === 'right';
        rows.push(currentRow);
        lastAnchor = null;
      }

      for (let cIdx = 0; cIdx < columns; cIdx++) {
        const item = items[nextIndex];
        span = item.data.props.span ?? 1;
        rspan = item.data.props.rows;
        anchor = item.data.props.anchor;

        if (span > columns) {
          span = columns;
        }

        addColumn = (!utils.isDefined(anchor) || (anchor !== lastAnchor));

        const canInsert = (
          ((!utils.isDefined(anchor) && lastColumn !== cIdx) || columns === 1) ||
          (cIdx === 0 && ['left', 'top'].includes(anchor)) ||
          (cIdx > 0 && ['right', 'bottom'].includes(anchor))
        );

        if (canInsert && (!addColumn ? currentRow.spanned : (currentRow.spanned + span)) <= columns) {
          lastAnchor = anchor ?? (currentRow.reverse ? 'right' : 'left');
          nextIndex = nextIndex + 1;
          lastColumn = cIdx;

          if (addColumn) {
            currentRow.columns.push({
              items: [{
                data: item,
                colSpan: span,
                rowSpan: rspan
              }]
            });
            currentRow.spanned += span;
          } else {
            currentRow.columns[(cIdx > currentRow.columns.length - 1) ? (currentRow.columns.length - 1) : cIdx].items.push({
              data: item,
              colSpan: span,
              rowSpan: rspan
            });
          }

          if (nextIndex >= items.length) {
            break;
          }
        }
      }

      if (nextIndex >= items.length) {
        break;
      }
    }

    let order = 0, groupId = 0;
    const rowGroups = rows.reduce((groups, r) => {
      let startCol = r.reverse ? columns : 1;

      const spanned = measureMasonry && r.columns.length < columns && r.spanned >= columns;
      let group = groups.find((g) => g.id === groupId);
      if (!group) {
        group = {
          id: groupId,
          spanned: spanned,
          rows: []
        }
        groups.push(group)
      }

      group.rows.push(r.columns.map((c, cIdx) => {
        const itemsColSpan = c.items.reduce((m, i) => Math.max(m, (i.colSpan ?? 1)), 0);

        const rendered = c.items.map((item) => {
          const style = (measureMasonry && !spanned) ? (
            internalState.reverse ? {
              order: `${(columns + 1) - startCol}`,
              marginLeft: null,
              marginTop: null,
            } : {
              order: `${startCol}`,
              marginRight: (!horizontal && startCol >= columns) ? '10rem' : null,
              marginBottom: (horizontal && startCol >= columns) ? '10rem' : null
            }
          ) : (
            horizontal ? {
              order: spanned ? `${cIdx + 1}` : `${order + 1}`,
              gridRow: `span ${item.colSpan ?? 1}`,
              gridColumn: `span ${item.rowSpan ?? 1}`
            } : {
              order: spanned ? `${cIdx + 1}` : `${order + 1}`,
              gridColumn: `span ${item.colSpan ?? 1}`,
              gridRow: `span ${item.rowSpan ?? 1}`
            });

          order += 1;

          return renderWrap(item.data, {style});
        });

        startCol = r.reverse ? (startCol - itemsColSpan) : (startCol + itemsColSpan);
        return rendered;
      }));

      if (spanned) {
        groupId += 1;
      }

      return groups;
    }, []);

    return rowGroups.map((rg) => {
      if (measureMasonry) {
        return <Box key={rg.id} className={`Cards-cards-section ${rg.spanned ? 'grid' : 'masonry'}`}>
          {rg.rows}
        </Box>
      } else {
        return rg.rows;
      }
    })
  }

  const renderCards = () => {
    return <StyledCards ref={innerRef} {...innerProps}
                        onKeyDown={handleKeyDown}
                        $columns={columns}>
      <Box className="Cards-cards">
        {renderChildren()}
      </Box>
    </StyledCards>
  }
  
  innerProps.className = utils.flattenClassName(innerProps.className, {
    reverse: measureMasonry && internalState.reverse
  });

  if (sortable) {
    return <SortableContext id={containerId?.toString()}
                            items={items}
                            strategy={columns > 1 ? rectSortingStrategy :
                              (orientation === 'horizontal' ? horizontalListSortingStrategy : verticalListSortingStrategy)}
                            {...SortableContextProps}>
      {renderCards()}
      <SortableOverlay animate={false}>
        {internalState.dragItem ? (
          renderOverlay ? renderOverlay?.(internalState.dragItem) :
            utils.cloneElement(internalState.dragItem?.data?.current?.payload, {dragging: true})
        ) : null}
      </SortableOverlay>
      <DndMonitor onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragCancel}
                  onDragOver={handleDragOver} />
    </SortableContext>
  } else {
    return renderCards();
  }
});

Cards.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  gap: PropTypes.number,
  virtualize: PropTypes.bool,
  columns: PropTypes.number,
  sortable: PropTypes.bool,
  containerId: PropTypes.any,
  reorder: PropTypes.bool,
  renderOverlay: PropTypes.func,
  dragHandle: PropTypes.bool,
  onCanDrag: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragDrop: PropTypes.func,
  onDragStop: PropTypes.func,
  SortableContextProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['grid', 'flex', 'masonry']), PropTypes.string]),
};

Cards.defaultProps = {
  orientation: 'vertical',
  variant: 'grid',
  containerId: 'cards',
  reorder: true,
  columns: 1,
  gap: 0
};

export default Cards;
