import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledBaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell.styles';
import {withMemo} from 'helpers/wrapper';

const BaseTableCell = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'BaseTableCell');

  return <StyledBaseTableCell ref={ref} {...innerProps} />
}));

BaseTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

BaseTableCell.defaultProps = {
};

export default BaseTableCell;


