import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import AccordionSummary from '@mui/material/AccordionSummary';

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    white-space: nowrap;
    overflow: hidden;
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-left: ${props => props.theme.spacing(1)};
  }
  
  .AccordionSummary {
    &-badge {
      .MuiBadge-badge {
        position: relative;
        align-self: center;
        padding: ${props => props.theme.layout(5)};
        margin-left: ${props => props.theme.layout('1sp')};
        font-size: ${props => props.theme.fontSize(11)};

        height: ${props => props.theme.layout(16)};
        min-width: ${props => props.theme.layout(16)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledAccordionSummary.propTypes = {
  theme: PropTypes.object
};

StyledAccordionSummary.defaultProps = {}

export default StyledAccordionSummary;
