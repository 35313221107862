import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import StyledCollectionAddDialog from 'components/organisms/Dialogs/CollectionAddDialog/CollectionAddDialog.styles';
import constants from 'helpers/constants';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const CollectionAddDialog = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'CollectionAddDialog', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const authorize = useAuthorize();

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const fields = useMemo(() => {
    return [
      {
        name: 'name',
        entity: 'collection',
        type: constants.formFieldTypes.text,
        required: true
      },
      {
        name: 'visibility',
        entity: 'collection',
        label: 'Privacy',
        type: constants.formFieldTypes.autocomplete,
        conversion: constants.formFieldConversionTypes.value,
        initial: constants.collection.visibility.client,
        options: constants.data.collectionVisibility
          .filter((v) => authorize({
            attribute: `collection.field.visibility.update.${v.value}`
          })),
        required: true
      },
      {
        name: 'description',
        entity: 'collection',
        label: 'Description',
        placeholder: 'Describe this collection',
        type: constants.formFieldTypes.markdown,
        FormFieldProps: {
          minRows: 4,
          maxRows: 8
        }
      },
      {
        name: 'projectTopics',
        entity: 'collection',
        label: 'Topics',
        description: 'Describe the companies you are looking for in tags',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.list,
        conversion: constants.formFieldConversionTypes.label,
        format: constants.formFieldFormatTypes.lower,
        FormFieldProps: {
          multiple: true,
          createOption: true,
          openDirect: false,
          openOnFocus: false,
          hiddenLabel: false,
          TagProps: {
            variant: 'filled',
            color: 'primary'
          },
          ChipProps: {
            variant: 'filled',
            color: 'primary'
          },
          ChipListProps: {
            variant: 'standard'
          }
        }
      }
    ]
  }, [authorize]);

  const handleChange = () => {
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values)
      .then(() => {
        innerProps.onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Creating collection failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              color="success"
              variant="contained"
              children={'Create collection'}
              startIcon={<Icon icon={Add}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledCollectionAddDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="CollectionAddDialog-header"
                  title="Add collection" />
    <DialogContent className="CollectionAddDialog-content" >
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="CollectionAddDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledCollectionAddDialog>
});

CollectionAddDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onSubmit: PropTypes.func
};

CollectionAddDialog.defaultProps = {};

export default CollectionAddDialog;
