import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTableHeaderIcon = styled(Box)`
  height: ${props => props.theme.spacing(`${24/13}f`)};
  margin-top: ${props => props.theme.layout(-1)};
  align-items: baseline;
    
  ${ComponentStyles}
`;

StyledTableHeaderIcon.propTypes = {
  theme: PropTypes.object
};

StyledTableHeaderIcon.defaultProps = {
}

export default StyledTableHeaderIcon;
