import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useEntityCommentStatus () {
  return useServiceStatus(path);
}

export function useEntityCommentList ({entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useEntityCommentGet ({entityId, commentId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {entityId, commentId}, queryOptions);
}

export function useEntityCommentAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityCommentUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useEntityCommentDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityCommentMarkDone (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/:commentId/done?entityId=:entityId',
    ...mutationOptions
  });
}

export function useEntityCommentMarkUndone (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'comments/:commentId/done?entityId=:entityId',
    ...mutationOptions
  });
}

export function useEntityCommentMarkRead ( mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/:commentId/read?entityId=:entityId',
    ...mutationOptions
  });
}

export function useEntityCommentMarkUnread (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    deleteProperty: true,
    overridePath: 'comments/:commentId/read?entityId=:entityId',
    ...mutationOptions
  });
}

export function useEntityCommentMarkAllRead ( mutationOptions = {}) {
  // commentId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'comments/clients/read/:entityId', ...mutationOptions
  });
}

export function useEntityCommentMarkAllUnread ( mutationOptions = {}) {
  // commentId = 0 forces invalidate on all comment watches
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'comments/clients/read/:entityId', ...mutationOptions
  });
}
