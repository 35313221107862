import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledForgotPage = styled(Page)`
  .ForgotPage {
    &-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledForgotPage.propTypes = {
  theme: PropTypes.object
};

StyledForgotPage.defaultProps = {
};

export default StyledForgotPage;
