import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEmail = styled(Box)`
  width: 100%;
  height: 100%;
  
  .Email {
    &-table {
      width: 100%;
      height: 100%;

      .Table {
        border-spacing: 0;
      }

      &.Table {
        background-color: ${props => utils.rgba2Rgb(props.theme.property(`palette.${props.$color}.states.selected`))};

        .TableCell {
          padding: 0;
          border: unset;
          font-size: ${props => props.theme.fontSize(16)};

          .Link.system {
            line-break: anywhere;
          }
        }

        > .TableBody {
          > .TableRow {
            > .TableCell {
              vertical-align: top;

              > .Table {
                margin: 0 auto;
                width: 100%;
                max-width: ${props => props.theme.layout(800)};
              }
            }
          }
        }

        .Table-outer {
          > .TableHead, > .TableBody {
            > .TableRow {
              > .TableCell {
                padding-left: ${props => props.theme.spacing(2)};
                padding-right: ${props => props.theme.spacing(2)};
              }
            }
          }

          > .TableHead {
            > .TableRow {
              > .TableCell {
                border: unset;
                padding-top: ${props => props.theme.spacing(2)};
                padding-bottom: ${props => props.theme.spacing(2)};

                &:first-child {
                  width: 1%;
                  padding-right: ${props => props.theme.spacing(6 / 8)};

                  img {
                    min-height: ${props => props.theme.layout(30)};
                    max-height: ${props => props.theme.layout(30)};
                  }
                }

                &:last-child {
                  width: 99%;
                  padding-left: ${props => props.theme.spacing(6 / 8)};

                  .Typography {
                    font-size: ${props => props.theme.fontSize(24)} !important;
                  }
                }
              }
            }
          }

          > .TableBody {
            > .TableRow {
              > .TableCell {
                
              }
            }
          }

          > .TableFooter {
            > .TableRow {
              > .TableCell {
                min-width: 100%;
                padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(2)};
                
                &:not(:empty) {
                  padding-top: ${props => props.theme.spacing(1)};
                }

                .P {
                  margin: 0;
                  min-width: 100%;
                  text-align: center;
                  line-break: anywhere;
                  .Span {
                    display: inline-block;
                  }
                  .Link {
                    display: inline-block;
                  }
                }
              }
            }
          }

          ${props => props.theme.breakpoints.down('sm')} {
            > .TableHead, > .TableBody, > .TableFooter {
              > .TableRow {
                > .TableCell {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }

            > .TableHead {
              > .TableRow {
                > .TableCell {
                  &:first-child {
                    padding-left: ${props => props.theme.spacing(1.5)};
                  }
                }
              }
            }
          }
        }

        .Table-inner {
          background-color: ${props => props.theme.property('palette.background.paper')};

          .TableHead, .TableBody {
            .TableRow {
              .TableCell {
                &:first-child {
                  padding-left: ${props => props.theme.spacing(6)};
                }

                &:last-child {
                  padding-right: ${props => props.theme.spacing(6)};
                }
              }
              
              &.Email {
                &-claimRow {
                  .TableCell {
                    text-align: center;
                    
                    img {
                      max-height: ${props => props.theme.layout(280)};
                      max-width: 100%;
                      object-fit: contain;
                    }
                  }
                }

                &-regardsRow, &-supportRow {
                  &.centered {
                    .TableCell {
                      width: 100%;
                      text-align: center;
                    }
                  }
                }
                
                &-supportRow {
                  .P {
                    white-space: pre-line;
                    margin-top: ${props => props.theme.spacing(1)};
                  }
                }
              }
            }
          }

          .TableHead {
            .TableCell {
              padding-top: ${props => props.theme.spacing(3)};

              .Typography {
                line-height: ${props => props.theme.lineHeight('1.4f')} !important;
              }
            }
          }

          .TableBody {
            .TableRow {
              .TableCell {
                padding: 0;
                border: unset;
              }

              &:last-child {
                .TableCell {
                  height: ${props => props.theme.spacing(1)};
                  background-color: ${props => props.theme.property('palette.primary.main')};
                }
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEmail.propTypes = {
  theme: PropTypes.object
};

StyledEmail.defaultProps = {
};

export default StyledEmail;
