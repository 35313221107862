import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledDealflowEntitySideBarWrapper = styled(ContextWrapper)`
  .EntitiesList {
    .DataList-list {
      overscroll-behavior: contain;
    }
  }
  ${ComponentStyles}
`;

StyledDealflowEntitySideBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitySideBarWrapper.defaultProps = {
};

export default StyledDealflowEntitySideBarWrapper;
