import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntityProfile
  from 'components/organisms/Profiles/DealflowEntityProfile/DealflowEntityProfile.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';


const DealflowEntityProfile = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntityProfile');

  const authorize = useAuthorize();

  const handleCanUpdate = useCallback((entity, field = null) => {
    return authorize({attribute: 'dealflow.entity.update', meta: {entity}}) &&
      (!field || authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, entity}}));
  }, [authorize]);

  const cards = useMemo(() => {
    return []
  }, []);

  return <StyledDealflowEntityProfile {...innerProps}
                                      cards={cards}
                                      onCanUpdate={handleCanUpdate}/>
};

DealflowEntityProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object
};

DealflowEntityProfile.defaultProps = {
};

export default DealflowEntityProfile;
