import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledChipListTableCell from 'components/molecules/TableCells/ChipListTableCell/ChipListTableCell.styles';
import ActionChipList from 'components/molecules/Chips/ActionChipList/ActionChipList';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const ChipListTableCell = (props) => {
  const {
    active,
    action,
    ActionChipListProps,
    ...innerProps
  } = useComponentProps(props, 'ChipListTableCell');

  return <StyledChipListTableCell {...innerProps}>
    {action ? <ActionIconButton action={action} density="dense"
                                size="smaller" variant="outlined"
                                IconProps={{size: 'tiny'}}/> : null}
    <ActionChipList {...ActionChipListProps} />
  </StyledChipListTableCell>
};

ChipListTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  ActionChipListProps: PropTypes.object
};

ChipListTableCell.defaultProps = {
};

export default ChipListTableCell;


