import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowEntityBrowserBar
  from 'components/organisms/Bars/DealflowEntityBrowserBar/DealflowEntityBrowserBar.styles';

const DealflowEntityBrowserBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntityBrowserBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntityBrowserBar ref={ref} {...innerProps} />
});

DealflowEntityBrowserBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityBrowserBar.defaultProps = {
};

export default DealflowEntityBrowserBar;
