import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionOnboarding = styled(Box)`
  .ActionFab {
    visibility: hidden;
    &-closed {
      visibility: unset;
    }
  }
  ${ComponentStyles}
`;

StyledCollectionOnboarding.propTypes = {
  theme: PropTypes.object
};

StyledCollectionOnboarding.defaultProps = {
}

export default StyledCollectionOnboarding;
