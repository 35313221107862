import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)`
  &.Paper-fullWidth {
    width: 100%;
  }

  &.Paper-fullHeight {
    height: 100%;
  }
  
  ${ComponentStyles}
`;

StyledPaper.propTypes = {
  theme: PropTypes.object
};

StyledPaper.defaultProps = {
}

export default StyledPaper;
