import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCardContent from 'components/atoms/Cards/CardContent/CardContent.styles';

const CardContent = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CardContent');

  return <StyledCardContent ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledCardContent>
});

CardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

CardContent.defaultProps = {
  children: 'CardContent text'
};

export default CardContent;