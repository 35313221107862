import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import PropTypes from 'prop-types';
import constants from 'helpers/constants';
import {rgba} from 'polished';

const StyledTable = styled(Box)`
  width: 100%;
  height: 100%;
  
  .MuiTable-root {
    table-layout: fixed;
  }

  .MuiTableHead-root {
    z-index: ${props => props.theme.property('zIndex.header')} !important;

    .MuiTableRow-root {
      box-shadow: unset;
      min-height: ${props => props.theme.layout(36)};
      max-height: ${props => props.theme.layout(36)};
    }  
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      min-height: ${props => props.theme.layout(72)};
      max-height: ${props => props.theme.layout(72)};
    }
    
    tr {
      ${props => props.theme.color2Css('MuiTableRow-root', null, props.$color, {interactive: false, flatten: true, type: constants.colorTypes.accentBackground})};
      ${props => props.theme.color2Css('MuiTableRow-root', 'MuiTableCell-root', props.$color, {interactive: false, flatten: true, type: constants.colorTypes.accentBackground})};

      td.TableCell:hover {
        outline: unset;
      }
      
      &.clickable {
        cursor: pointer;
        ${props => props.theme.color2Css('MuiTableRow-root', null, props.$color, {interactive: true, flatten: true, type: constants.colorTypes.accentBackground})};
        ${props => props.theme.color2Css('MuiTableRow-root', 'MuiTableCell-root', props.$color, {interactive: true, flatten: true, type: constants.colorTypes.accentBackground})};

        td.TableCell-editable:hover {
          background-color: ${props => props.theme.state2Color(props.$color, 'hover', constants.colorTypes.accentBackground, constants.colorTargets.background, true)} !important;
        }

        &.Mui-selected {
          td.TableCell-editable:hover {
            background-color: ${props => props.theme.state2Color(props.$color, 'selected', constants.colorTypes.accentBackground, constants.colorTargets.background, true)} !important;
          }
        }
      }
    }
  }
  
  .MuiTableHead-root, .MuiTableBody-root {
    tr {
      .TableHeader, .TableCell {
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      }
      
      &:last-child {
        .TableCell {
          ${props => props.$showProgressBars ? css`
            border-bottom: unset;
          ` : css`
            border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
          `}
        }
      }
    }
  }

  .MuiTableContainer-root {
    border-radius: 0;
  }
  
  .MuiTableContainer-root + .MuiToolbar-root {
    box-shadow: unset;
    
    .MuiLinearProgress-root {
      height: ${props => props.theme.layout(3)};
    }
  }
  
  .TableHeader {
    ${props => props.theme.font2Css('tableHeader')};
    padding: ${props => props.theme.layout('0.5sp')} ${props => props.theme.layout('1.5sp')};

    user-select: none;
    -webkit-user-drag: none;
    
    &-mrt-row-select {
      padding: ${props => props.theme.layout('0.5sp')} ${props => props.theme.layout('1.5sp')};
    }
      
    &.MuiTableCell-alignCenter {
      &:has(.ColumnActionMenu) {
        .Mui-TableHeadCell-Content {
          &-Labels {
            padding-left: ${props => props.theme.layout('2sp')};
          }
        }
      }

      &:not(:has(.MuiTableSortLabel-root)) {
        .Mui-TableHeadCell-Content {
          &-Labels {
            padding-left: 0;
            padding-right: ${props => props.theme.layout('1sp')};;
          }
        }
      }
      
      .Mui-TableHeadCell-Content {
        &-Labels {
          justify-content: center;
          * {
            justify-content: center;
          }
        }
      }
    }
    
    .Mui-TableHeadCell-Content {
      &-Labels {
        flex-grow: 1;
        align-self: center;
        padding-left: 0;
      }

      .MuiTableSortLabel-root {
        width: unset;
        transform: unset !important;
        .MuiSvgIcon-root {
          font-size: ${props => props.theme.fontSize(16)};
        }
      }
      
      &-Actions {
        display: flex;
        align-items: center;
        gap: ${props => props.theme.layout('1sp')};
        margin-right: -${props => props.theme.spacing(0.5)};

        .ColumnActionMenu {
          width: ${props => props.theme.layout('2sp')};
          height: ${props => props.theme.layout('2sp')};

          .MuiTouchRipple-root {
            top: ${props => props.theme.layout(2)};
            left: ${props => props.theme.layout(2)};
          }
        }
        .MuiSvgIcon-root {
          width: ${props => props.theme.layout(12)};
        }
      }

      &-Wrapper {
        text-overflow: ellipsis;
      }

      .MuiBox-root:last-child {
        > hr {
          background-color: ${props => props.theme.property('palette.divider')};
          border-width: ${props => props.theme.spacing(1/8)};
          height: ${props => props.theme.spacing('1.25f')};
        }

        &:active {
          > hr {
            background-color: ${props => props.theme.property('palette.primary.main')};
          }
        }
      }

      &:has(.TableHeaderIcon) {
        .Mui-TableHeadCell-Content-Wrapper {
          min-width: 0;
        }
      }
    }
  }

  .TableCell {
    ${props => props.theme.font2Css('tableBody')};

    padding: ${props => props.theme.layout('1sp')} ${props => props.theme.layout('1.5sp')};
    
    &.MuiTableCell-alignCenter {
      padding-left: 0;
      padding-right: ${props => props.theme.layout(34)};

      * {
        justify-content: center;
      }
    }
    
    &-mrt-row-select {
      padding: ${props => props.theme.layout('1sp')} ${props => props.theme.layout('1.5sp')};
    }
  }
  
  .TableHeader, .TableCell {
    &.TableHeader-hovering, &.TableHeader-dragging, &.TableCell-hovering, &.TableCell-dragging {
      border-width: 1.5px !important;
      
      .Mui-TableHeadCell-Content {
        margin-top: -1.5px;
      }
    }
    
    &-pinned {
      z-index: ${props => props.theme.property('zIndex.pinned')};

      &.TableHeader-hovering, &.TableHeader-dragging, &.TableCell-hovering, &.TableCell-dragging {
        opacity: 1;
      }
    }
    
    &-pinned-last {
      box-shadow: unset;
      &:not(.TableHeader-hovering, .TableHeader-dragging, .TableCell-hovering, .TableCell-dragging) {
        &.TableHeader-pinned-left, &.TableCell-pinned-left {
          border-right: 1px solid ${props => props.theme.property('palette.divider')};
        }

        &.TableHeader-pinned-right, &.TableCell-pinned-right {
          border-left: 1px solid ${props => props.theme.property('palette.divider')};
        }
      }
    }
  }
  
  .TableHeader, .TableCell {
    &-mrt-row-select {
      box-shadow: unset;
      max-width: ${props => props.theme.layout(40)};
      min-width: ${props => props.theme.layout(40)};

      .MuiCheckbox-root {
        height: ${props => props.theme.layout('4sp')};
        width: ${props => props.theme.layout('4sp')};
        svg {
          font-size: ${props => props.theme.fontSize(20)};
        }
      }
    }
    
    &-selected {
      background-color: inherit;
    }
  }
  
  .MuiModal-root {
    .MuiDialog-paper {
      overflow: hidden;
    }   
    .MuiPaper-root {
      display: flex;
      flex-direction: column;
    }
    .MuiToolbar-root {
      position: relative;
    }
  }
  
  .Table {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: ${props => props.$wrapper?.boundsCum?.bottom ?? 0};
      pointer-events: none;

      height: ${props => props.theme.layout(48)};
      background: linear-gradient(0deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  
  ${props => props.$parentScroll ? css`
    .MuiTableContainer-root {
      overflow-x: auto;
      overflow-y: hidden;
      max-height: unset;
    }

    .MuiTableRow-root {
      .TableHeader-pinned-left, .TableCell-pinned-left {
        margin-left: 0;
      }
      .TableHeader-pinned-right, .TableCell-pinned-right {
        margin-right: 0;
      }

      th:not(.TableHeader), td:not(.TableCell) {
        background-color: ${props => props.theme.property('palette.background.paper')}; !important;
      }
    }

    .MuiTableBody-root {
      tr {
        td {
          > p.MuiTypography-root {
            position: sticky;
            width: 100%;
            color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
            
            ${props => props.$wrapper?.boundsCum ? css`
              left: ${props => props.$wrapper?.boundsCum?.left ?? '0px'};
              min-width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
              max-width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
            ` : null};
          }
        }
      }
    }

    .MuiToolbar-root {
      position: sticky;
      left: ${props.$wrapper?.boundsCum?.left ?? 0};
      width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
    }

    .Table {
      &-footer {
        position: fixed;
        width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
      }
    }
  `: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    
    .MuiPaper-root {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      .MuiTableContainer-root {
        height: 100%;
      }
    }
  `};
    
  ${props => props.$sticky ? css`
    .MuiTableContainer-root {
      .MuiTableHead-root, .MuiTableFooter-root {     
        opacity: 1;
        z-index: ${props.theme.property('zIndex.appBar')};
        
        th {
          top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
        }
      }
    }
    
    ${props.$parentScroll ? css`
      .MuiTableContainer-root {
        overflow: unset;
        max-height: unset;
      }
    `: null};
  `: null};
  
  ${props => props.$minimalFooter ? css`
    > .MuiPaper-root {
      > .MuiToolbar-root {
        min-height: unset;
        max-height: ${props.$showProgressBars ? props.theme.layout(2) : 0};

        .MuiLinearProgress-root {
          height: ${props => props.theme.layout(2)};
        }
      } 
    }
  ` : css``}
  
  ${ComponentStyles}
`;

StyledTable.propTypes = {
  $sticky: PropTypes.bool
};

export default StyledTable;
