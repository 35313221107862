import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledUpdateForm = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${props => props.theme.layout('2sp')};
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
  border-top: ${props => props.theme.layout(1)} solid ${props => props.theme.property('palette.divider')};
  background-color: ${props => props.theme.property('palette.background.paper')};

  .UpdateForm {
    &-form {
      width: 1px;
      flex-grow: 1;
      .Form-fields {
        .FormControl {
          flex-grow: 1;
          .Input {
            margin: 0;
            font-size: ${props => props.theme.fontSize(14)};
            .MuiInput-input {
              padding: 0;
            }
          }
        }
      }
    }
    
    &-icons {
      display: flex;
      align-items: center;
      column-gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  &.UpdateForm-simple {
    padding: ${props => props.theme.spacing(2)};
    background-color: ${props => props.theme.property('palette.primary.states.hover')};
    
    .FormControl {
      .MuiInputBase-root {
        background-color: ${props => props.theme.property('palette.background.paper')};
        
        .MuiInputAdornment-positionEnd {
          .IconButton {
            ${props => props.theme.state2Selector('IconButton', null, ['hover', 'focus', 'focusVisible'])} {
              background-color: unset !important;
              .Icon {
                color: ${props => props.theme.property('palette.primary.main')};
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledUpdateForm.propTypes = {
  theme: PropTypes.object
};

StyledUpdateForm.defaultProps = {}

export default StyledUpdateForm;
