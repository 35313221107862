import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowStatusList from 'components/molecules/Lists/DealflowStatusList/DealflowStatusList.styles';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from 'components/atoms/Text/Typography/Typography';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Check from '@mui/icons-material/Check';
import ListItemButton from 'components/atoms/Buttons/ListItemButton/ListItemButton';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const DealflowStatusList = React.forwardRef((props, ref) => {
  const {
    statusId,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusList');

  const client = useAuthClient();
  const groups = client?.dealflowGroups;
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (status) => {
    return (e) => {
      onClick?.(e, status);
    }
  }

  const selectedStatusIdx = (groups ?? []).reduce((a, g) => a.concat([{}]).concat(g.statuses), [])
    .findIndex((status) => +statusId === +status.statusId);

  useEffect(() => {
    if (selectedStatusIdx !== -1) {
      setSelectedIndex(selectedStatusIdx);
    }
  }, [selectedStatusIdx]);

  const handleSelectionChange = (e, idx) => {
    setSelectedIndex(idx);
  }

  return <StyledDealflowStatusList ref={ref} {...innerProps}
                                   divider={true}
                                   onSelectionChange={handleSelectionChange}
                                   selectedIndex={selectedIndex}>
    {groups.flatMap((group, groupIdx) => {
      const result = [
        <ListItem key={groupIdx} className="DealflowStatusList-group" variant="heading">
          <DealflowIcon className="DealflowStatusList-group-icon"
                        size="smaller"
                        groupId={group.groupId} />
          <ListItemText primary={<Typography className="DealflowStatusList-group-text" variant="body2">
            {group.label}
          </Typography>}/>
        </ListItem>
      ];

      result.push(group.statuses?.map((status, idx) => {
        return <ListItem className="DealflowStatusList-status" key={`${groupIdx}_${idx}`}>
          <ListItemButton onClick={handleClick(status)} selected={+statusId === +status.statusId}>
            <ListItemText primary={<Typography className="DealflowStatusList-status-text" variant="body2">
              {status.name}
            </Typography>}/>
            {+statusId === +status.statusId ? <Icon className="DealflowStatusList-status-icon"
                                                    size="tiny" color="success" icon={Check}  /> : null}
          </ListItemButton>
        </ListItem>
      }));
      return result
    })}
  </StyledDealflowStatusList>
});

DealflowStatusList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statusId: PropTypes.number,
  onClick: PropTypes.func
};

DealflowStatusList.defaultProps = {};

export default DealflowStatusList;
