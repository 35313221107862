import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ButtonGroup from 'components/atoms/Buttons/ButtonGroup/ButtonGroup';

const StyledActionButtonGroup = styled(ButtonGroup)`
  ${ComponentStyles}
`;

StyledActionButtonGroup.propTypes = {
  theme: PropTypes.object
};

StyledActionButtonGroup.defaultProps = {
}

export default StyledActionButtonGroup;
