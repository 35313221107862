import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledDatabaseEntitiesQueryBarWrapper = styled(ToolbarWrapper)`
  &.DatabaseEntitiesQueryBarWrapper-collapse {
    > .ToolbarWrapper {
      &-toolbar {
        display: none;
      }
    }
  }
  ${ComponentStyles}
`;

StyledDatabaseEntitiesQueryBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitiesQueryBarWrapper.defaultProps = {
};

export default StyledDatabaseEntitiesQueryBarWrapper;
