import {css} from 'styled-components';
import utils from 'helpers/utils';

/* General themed component styles */
const ComponentStyles = css`
  // default
  ${props => props.theme.property('mixins.ComponentStyles', '')}
  ${props => props.theme.convert2Css('components.ComponentStyles.styleOverrides.root')}
  
  // overrides
  ${props => props.theme.property(`mixins.${props.$componentName}`, '')}
  ${props => props.theme.convert2Css(`components.${props.$componentName}.styleOverrides.root`)}
  
  // variants
  ${props => {
    return props.theme.convert2Css(props.$variant?.style);
  }}
    
  // static modifiers can be on or off style is always there
  ${props => {
    const variant = props.$variant?.variant ?? props.variant;
    return (props.$modifiers?.static || []).map((modifier) => {
      let css = '';
      
      const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(modifier)}`, false) ||
              props.theme.property(`components.${props.$componentName}.styleOverrides.${modifier}`, false);
      if (override) {
        css += `
          &.${props.$componentName}-${modifier} {
            ${props.theme.convert2Css(override)}
          }
        `;
      }

      if (variant) {
        const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(`${variant}-${modifier}`)}`, false) ||
                props.theme.property(`components.${props.$componentName}.styleOverrides.${variant}-${modifier}`, false);

        if (override) {
          css += `
            &.${props.$componentName}-${modifier} {
              ${props.theme.convert2Css(override)}
            }
          `;
        }
      }
      
      return css;
    })
  }};

  // variable modifiers change with properties
  ${props => {
    const variant = props.$variant?.variant ?? props.variant;
    return (props.$modifiers?.variable || []).map((modifier) => {
      let css = '';

      if (props[modifier]) {
        const modKey = modifier === 'variant' ? props[modifier] : `${modifier}-${props[modifier]}`;

        const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(modKey)}`, false) || 
                props.theme.property(`components.${props.$componentName}.styleOverrides.${modKey}`, false);
        if (override) {
          css += `
            &.${props.$componentName}-${modKey} {
              ${props.theme.convert2Css(override)}
            }
          `;
        }

        if (variant) {
          const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(`${variant}-${modKey}`)}`, false) ||
                  props.theme.property(`components.${props.$componentName}.styleOverrides.${variant}-${modKey}`, false);
          if (override) {
            css += `
              &.${props.$componentName}-${modKey} {
                ${props.theme.convert2Css(override)}
              }
            `;
          }
        }
      }
      return css;
    })
  }};
  
  // child modifiers hold child overrides, always there
  ${props => {
    const variant = props.$variant?.variant ?? props.variant;
    return (props.$modifiers?.children || []).map((modifier) => {
      let css = '';
      
      const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(modifier)}`, false) ||
              props.theme.property(`components.${props.$componentName}.styleOverrides.${modifier}`, false);
      if (override) {
        return `
          .${props.$componentName}-${modifier} {
            ${props.theme.convert2Css(override)}
          }
        `;
      }

      if (variant) {
        const override = props.theme.property(`components.${props.$componentName}.styleOverrides.${utils.camelcase(`${variant}-${modifier}`)}`, false) ||
                props.theme.property(`components.${props.$componentName}.styleOverrides.${variant}-${modifier}`, false);
        if (override) {
          css += `
              &.${props.$componentName}-${modifier} {
                ${props.theme.convert2Css(override)}
              }
            `;
        }
      }
      
      return css;
    })
  }};
`;

export default ComponentStyles;
