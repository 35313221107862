import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSourceWizardSectionPanelContent = styled(Box)` 
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${props => props.theme.layout('1sp')};
  padding: ${props => props.theme.spacing(2)};

  .DataList {
    .DataList-list {
      .ListItem {
        > * {
          width: 100%;
        }
      }
      
      .DataList-empty {
        .P {
          margin-top: 0;
          color: ${props => props.theme.property('palette.text.secondary')};

          ${props => props.theme.font2Css('subtitle1')};
          font-style: normal;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSourceWizardSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledSourceWizardSectionPanelContent.defaultProps = {}

export default StyledSourceWizardSectionPanelContent;
