import {useCallback} from 'react';
import {useEntityTagGroupUpdate} from 'services/entity/tagGroup/tagGroup.hooks';

export function useEntityTagGroupPatch () {
  const entityTagGroupUpdate = useEntityTagGroupUpdate();

  return useCallback((entity, tagGroup, oldTags, newTags) => {
    const changed = oldTags.find((t1) => !newTags.find((t2) => +t1.tagId === +t2.tagId)) ||
      newTags.find((t1) => !oldTags.find((t2) => +t1.tagId === +t2.tagId));

    if (changed) {
      return entityTagGroupUpdate.mutation.mutateAsync({
        entityId: entity.entityId,
        groupId: tagGroup.groupId,
        tags: newTags
      });
    } else {
      return Promise.resolve();
    }
  }, [entityTagGroupUpdate.mutation]);
}
