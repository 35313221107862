import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useUpdatedRef} from 'helpers/hooks/utils';
import TableCellPopper from 'components/molecules/Poppers/TableCellPopper/TableCellPopper';
import StyledBaseTableCellEdit from 'components/organisms/TableCellEdits/BaseTableCellEdit/BaseTableCellEdit.styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import dom from 'helpers/dom';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const BaseTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    variant,
    cell,
    table,
    autoClose,
    anchor,
    onMouseLeave,
    onMouseEnter,
    TableCellPopperProps,
    ...innerProps
  } = useComponentProps(props, 'BaseTableCellEdit');

  const innerRef = useRef(null);
  const popperRef = useRef(null);
  const canScroll = useRef(true);
  const [init, setInit] = useState(false);
  const [open, setOpen] = useState(false);

  const baseTableCellEdit = useMemo(() => ({
    refs: {
      ref: innerRef,
      popperRef
    }
  }), []);

  useImperativeHandle(ref, () => baseTableCellEdit);

  useEffect(() => {
    let el = (anchor?.getElement() ?? innerRef.current);
    if (el) {
      if (canScroll.current) {
        canScroll.current = false;

        while (el && !Array.from(el.classList).find((c) => c === 'TableCell')) {
          el = el.parentElement;
        }
        dom.scrollIntoView(el, {behavior: 'instant'});
        setOpen(true);
      }
    }
  }, [anchor])

  const tableRef = useUpdatedRef(table);
  const cellRef = useUpdatedRef(cell);

  const doClose = useCallback((e) => {
    // special case select
    const isSelect = e.currentTarget === document &&
      dom.isPartOfParent(e.currentTarget?.activeElement, innerRef.current);

    if (!isSelect) {
      tableRef.current?.setEditingCell(null);
    }
  }, [tableRef]);

  const handleClose = (e, reason) => {
    if (autoClose || reason === 'closeButtonClick') {
      doClose(e);
    }
  }

  const getCellIsEditable = (column, row) => {
    return utils.isFunction(column?.columnDef?.enableEditing) ? column?.columnDef?.enableEditing(row) :
      column?.columnDef?.enableEditing !== false;
  }

  const goNext = useCallback((down) => {
    if (tableRef.current) {
      let found = false;

      const rows = tableRef.current.getFilteredRowModel().flatRows;
      const row = rows.find((r) => r.id === cellRef.current.row.id);
      const cells = row ? row.getVisibleCells() : [];

      const cellIdx = cells.findIndex((c) => c.column.id === cellRef.current.column.id);

      if (cellIdx !== -1) {
        if (!down) {
          const cellsAfter = cells.filter((c, idx) => {
            return idx > cellIdx && getCellIsEditable(c.column, row);
          });

          if (cellsAfter.length > 0) {
            found = true;
            tableRef.current.setEditingCell(cellsAfter[0]);
          }
        } else {
          const rowIdx = rows.findIndex((c) => c.id === cellRef.current.row.id);
          const rowsAfter = rows.filter((r, idx) => {
            const c = r.getVisibleCells()[cellIdx];
            return idx > rowIdx && getCellIsEditable(c.column, r);
          });

          if (rowsAfter.length > 0) {
            found = true;
            tableRef.current.setEditingCell(rowsAfter[0].getVisibleCells()[cellIdx]);
          }
        }
      }

      if (!found) {
        tableRef.current.setEditingCell(null);
      }
    }
  }, [tableRef, cellRef]);

  const goPrev = useCallback((up) => {
    if (tableRef.current) {
      let found = false;

      const rows = tableRef.current.getFilteredRowModel().flatRows;
      const row = rows.find((r) => r.id === cellRef.current.row.id);
      const cells = row ? row.getVisibleCells() : [];

      const cellIdx = cells.findIndex((c) => c.column.id === cellRef.current.column.id);
      if (cellIdx !== -1) {
        if (!up) {
          const cellsBefore = cells.filter((c, idx) => {
            return idx < cellIdx && getCellIsEditable(c.column, row);
          });

          if (cellsBefore.length > 0) {
            found = true;
            tableRef.current.setEditingCell(cellsBefore[cellsBefore.length - 1]);
          }
        } else {
          const rowIdx = rows.findIndex((c) => c.id === cellRef.current.row.id);
          const rowsBefore = rows.filter((r, idx) => {
            const c = r.getVisibleCells()[cellIdx];
            return idx < rowIdx && getCellIsEditable(c.column, r);
          });

          if (rowsBefore.length > 0) {
            found = true;
            tableRef.current.setEditingCell(rowsBefore[rowsBefore.length - 1].getVisibleCells()[cellIdx]);
          }
        }
      }

      if (!found) {
        tableRef.current.setEditingCell(null);
      }
    }
  }, [tableRef, cellRef]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      doClose(e);
      e.preventDefault();
    } else {
      if (e.shiftKey) {
        if (autoClose) {
          if (e.key === 'ArrowLeft') {
            goPrev(false);
            e.preventDefault();
          } else if (e.key === 'ArrowRight') {
            goNext(false);
            e.preventDefault();
          } else if (e.key === 'ArrowUp') {
            goPrev(true);
            e.preventDefault();
          } else if (e.key === 'ArrowDown') {
            goNext(true);
            e.preventDefault();
          }
        }
      }
    }
  }, [autoClose, doClose, goNext, goPrev]);

  useEffect(() => {
    if (init) {
      return utils.observeEvent(window, 'keydown', handleKeyDown);
    } else {
      setInit(true);
    }
  }, [init, handleKeyDown]);

  if (variant === 'popper') {
    return <TableCellPopper ref={popperRef}
                            anchorEl={anchor}
                            onClose={handleClose}
                            onKeyDown={handleKeyDown}
                            open={open}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            {...TableCellPopperProps}>
      <StyledBaseTableCellEdit ref={innerRef} {...innerProps}>
        {innerProps.children}
      </StyledBaseTableCellEdit>
    </TableCellPopper>
  } else {
    return <ClickAwayListener onClickAway={handleClose}>
      <StyledBaseTableCellEdit ref={innerRef} {...innerProps}
                               onMouseEnter={onMouseEnter}
                               onMouseLeave={onMouseLeave}
                               onKeyDown={handleKeyDown}>
        {innerProps.children}
      </StyledBaseTableCellEdit>
    </ClickAwayListener>
  }
}));

BaseTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  autoClose: PropTypes.bool,
  table: PropTypes.object,
  cell: PropTypes.object,
  anchor: PropTypes.any,
  TableCellPopperProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'popper']), PropTypes.string]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

BaseTableCellEdit.defaultProps = {
  children: 'BaseTableCellEdit text',
  autoClose: true
};

export default BaseTableCellEdit;
