import React, {useMemo} from 'react'
import CollectionEntityProfileProvider
  from 'components/organisms/Providers/ProfileProvider/CollectionEntityProfileProvider';
import CollectionEntitySideBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntitySideBarWrapper/CollectionEntitySideBarWrapper';
import CollectionEntityContextWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityContextWrapper/CollectionEntityContextWrapper';
import EntityTaskProfileWrapper
  from 'components/templates/Wrappers/Tasks/EntityTaskProfileWrapper/EntityTaskProfileWrapper';
import utils from 'helpers/utils';
import CollectionEntityProfileBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityProfileBarWrapper/CollectionEntityProfileBarWrapper';
import CollectionEntityProfilePage
  from 'components/pages/Collection/Entity/CollectionEntityProfilePage/CollectionEntityProfilePage';
import CollectionEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityBrowserBarWrapper/CollectionEntityBrowserBarWrapper';
import CollectionEntityBrowserPage
  from 'components/pages/Collection/Entity/CollectionEntityBrowserPage/CollectionEntityBrowserPage';

export default function useCollectionEntityRoutes () {
  return useMemo(() => {
    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'collection',
            path: 'collection', // store path
            id: match?.params?.collectionId, // id from match
            params: match?.params
          },
          {
            name: 'entity',
            path: 'collection/entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <CollectionEntityProfileBarWrapper>
          <CollectionEntityProfilePage />
        </CollectionEntityProfileBarWrapper>,

        handle: {
          title: 'Collection - company',
          auth: utils.createAuth({
            attribute: 'collection.entity.profile.read',
            meta: entityMeta
          })
        }
      },
      {
        path: 'browser',
        element: <CollectionEntityBrowserBarWrapper>
          <CollectionEntityBrowserPage />
        </CollectionEntityBrowserBarWrapper>,

        handle: {
          title: 'Collection - browser',
          auth: utils.createAuth({
            attribute: 'collection.entity.browser.read',
            meta: entityMeta
          })
        }
      },
    ];

    return {
      WrapperComponent: ({children}) => <CollectionEntityProfileProvider>
        <CollectionEntitySideBarWrapper>
          <CollectionEntityContextWrapper>
            <EntityTaskProfileWrapper>
              {children}
            </EntityTaskProfileWrapper>
          </CollectionEntityContextWrapper>
        </CollectionEntitySideBarWrapper>
      </CollectionEntityProfileProvider>,
      route: route
    };
  }, []);
};
