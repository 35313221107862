import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledChooseTaskWizardContent
  from 'components/organisms/WizardContent/ChooseTaskWizardContent/ChooseTaskWizardContent.styles';
import constants from 'helpers/constants';

const ChooseTaskWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    taskTypes,
    ...innerProps
  } = useComponentProps(props, 'ChooseTaskWizardContent');

  const setDataEvent = useEffectEvent(wizard.setData);

  const fields = useMemo(() => ([{
    name: 'type',
    label: 'Choose task',
    inlineLabel: 'Task',
    entity: 'task',
    type: constants.formFieldTypes.category,
    conversion: constants.formFieldTypes.none,
    options: (taskTypes ?? constants.data.taskTypes),
    required: true
  }]), [taskTypes]);

  const handleChange = (field, value) => {
    setDataEvent?.((current) => ({
      ...current,
      [wizard.dataKey]: {
        ...current[wizard.dataKey],
        type: value,
        collaborationType: value?.collaborationType
      }
    }));
  }

  const handleSubmit = (values) => {
    setDataEvent?.((current) => ({
      ...current,
      [wizard.dataKey]: {
        ...current[wizard.dataKey],
        type: values['type'],
        collaborationType: values['type']?.collaborationType
      }
    }));
  }

  return <StyledChooseTaskWizardContent ref={ref} {...innerProps}
                                        wizard={wizard}
                                        step={step}
                                        fields={fields}
                                        onChange={handleChange}
                                        onSubmit={handleSubmit}
                                        InlineFormProps={{autoFocus: false}}/>
});

ChooseTaskWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  taskTypes: PropTypes.array
};

ChooseTaskWizardContent.defaultProps = {};

export default ChooseTaskWizardContent;
