import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useEmailRoutes from 'routes/dev/email/email.routes';
import utils from 'helpers/utils';

export default function useDevRoutes () {
  const emailRoutes = useEmailRoutes();
  return useMemo(() => {
    const route = [
      {
        path: 'email',
        element: <emailRoutes.WrapperComponent><Outlet /></emailRoutes.WrapperComponent>,
        children: emailRoutes.route,
        handle: {
          auth: utils.createAuth({attribute: 'dev.email.route'})
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, [emailRoutes]);
};
