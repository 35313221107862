import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {H1, H2, P} from 'components/atoms/Text/Typography/Typography';
import StyledErrorPage from 'components/pages/Error/ErrorPage/ErrorPage.styles';
import utils from 'helpers/utils';

const ErrorPage = (props) => {
  const {
    error,
    ...innerProps
  } = useComponentProps(props, 'ErrorPage');

  return <StyledErrorPage {...innerProps}>
    <H1>Oops!</H1>
    <H2>An error has occurred...</H2>
    {utils.isObject(error) ? [
      (error.message && <P key={1}>{error.message}</P>),
      (error.stack && <P key={2}>{error.stack}</P>)
    ] : null}
    {!utils.isObject(error) && error && <P>{error}</P>}
  </StyledErrorPage>
};

ErrorPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

ErrorPage.defaultProps = {
};

export default ErrorPage;
