import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {P, Span} from 'components/atoms/Text/Typography/Typography';
import Edit from '@mui/icons-material/Edit';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import StyledAnalysisScoreInlineEdit
  from 'components/organisms/InlineEdits/AnalysisScoreInlineEdit/AnalysisScoreInlineEdit.styles';
import {withMemo} from 'helpers/wrapper';

const AnalysisScoreInlineEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    score,
    fieldData,
    onEdit,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreInlineEdit');

  const editable = Boolean(onEdit);

  const fields = useMemo(() => {
    if (score) {
      if (score.tagGroupId > 0) {
        return utils.initializeFormFields([{
          name: 'tagGroup',
          label: <Box>
            <Span>{score.tagGroup.name}</Span>
            <Typography color="text.secondary" nowrap={true} variant="caption">{score.points} points</Typography>
          </Box>,
          entity: 'entity',
          tagGroupId: score.tagGroupId,
          tagGroup: score.tagGroup,
          required: false,
          readOnly: true,
          FormFieldProps: {
            variant: 'staticLabel',
            hiddenIcons: false,
            fullWidth: true
          }
        }], entity);
      }
    }
  }, [score, entity]);

  const onEditEvent = useEffectEvent(onEdit);
  const action = useMemo(() => ({
    label: 'Edit categories',
    auth: !editable ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      onEditEvent?.(e);
      e.preventDefault();
    }
  }), [editable, onEditEvent]);
  
  return <StyledAnalysisScoreInlineEdit ref={ref} {...innerProps}>
    {fields ? <InlineForm className="AnalysisScoreInlineEdit-form"
                          fields={fields}
                          fieldData={fieldData} /> : null}
    {!fields ? <Box className="AnalysisScoreInlineEdit-unassigned">
      <Box className="AnalysisScoreInlineEdit-unassigned-title">
        <Span>Unassigned</Span>
        <Typography color="text.secondary" nowrap={true} variant="caption">{score?.points ?? 0} points</Typography>
      </Box>
      <P className="AnalysisScoreInlineEdit-unassigned-content">
        This company scored {score?.scored} points of potentially {score?.total} points
      </P>
    </Box> : null}
    <ActionButton action={action}
                  fullWidth={true}
                  size="small"
                  variant="contained"/>
  </StyledAnalysisScoreInlineEdit>
}));

AnalysisScoreInlineEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  score: PropTypes.object,
  fieldData: PropTypes.object,
  onEdit: PropTypes.func
};

AnalysisScoreInlineEdit.defaultProps = {};

export default AnalysisScoreInlineEdit;
