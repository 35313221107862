import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledStepIcon from 'components/atoms/Steppers/StepIcon/StepIcon.styles';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const StepIcon = withMemo(React.forwardRef((props, ref) => {
  const {
    hideNumber,
    ...innerProps
  } = useComponentProps(props, 'StepIcon', {
    static: ['hideNumber']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledStepIcon ref={ref} {...innerProps} />
}));

StepIcon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

StepIcon.defaultProps = {};

export default StepIcon;
