import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAvatarGroup from 'components/atoms/Avatars/AvatarGroup/AvatarGroup.styles';
import utils from 'helpers/utils';

const AvatarGroup = React.forwardRef((props, ref) => {
  const {
    size,
    ...innerProps
  } = useComponentProps(props, 'AvatarGroup', {
    styled: ['color']
  });

  const shown = Math.min(innerProps.children?.length, +innerProps.max - 1);
  if (utils.isDefined(innerProps.total) && (+innerProps.total - shown) > 99) {
    innerProps.total = shown + 99;
  }

  const overflow = +innerProps.total - shown;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAvatarGroup ref={ref} {...innerProps} $overflow={overflow}>
    {innerProps.children}
  </StyledAvatarGroup>
});

AvatarGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

AvatarGroup.defaultProps = {
  size: 'medium',
  color: 'primary'
};

export default AvatarGroup;
