import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useCollectionEntitiesRoutes from 'routes/collections/collection/entities/entities.routes';
import CollectionProfileProvider from 'components/organisms/Providers/ProfileProvider/CollectionProfileProvider';
import CollectionProfileWrapper
  from 'components/templates/Wrappers/Collections/CollectionProfileWrapper/CollectionProfileWrapper';
import TaskProfileWrapper from 'components/templates/Wrappers/Tasks/TaskProfileWrapper/TaskProfileWrapper';
import utils from 'helpers/utils';

export default function useCollectionRoutes () {
  const entitiesRoutes = useCollectionEntitiesRoutes();
  return useMemo(() => {
    const collectionMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: {
          name: 'collection',
          path: 'collection', // store path
          id: match?.params?.collectionId, // id from match
          params: match?.params
        }
      };
    };

    const route = [
      {
        path: '',
        element: <entitiesRoutes.WrapperComponent><Outlet /></entitiesRoutes.WrapperComponent>,
        children: entitiesRoutes.route,

        handle: {
          auth: utils.createAuth({
            attribute: 'collection.entity.route',
            meta: collectionMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <CollectionProfileProvider>
          <CollectionProfileWrapper>
            <TaskProfileWrapper>
              {children}
            </TaskProfileWrapper>
          </CollectionProfileWrapper>
        </CollectionProfileProvider>
      },
      route: route
    };
  }, [entitiesRoutes]);
};
