import React, {useLayoutEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowKanban from 'components/organisms/Kanbans/DealflowKanban/DealflowKanban.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const DealflowKanban = (props) => {
  const {
    onItemClick,
    onCanItemClick,
    ...innerProps
  } = useComponentProps(props, 'DealflowKanban');

  const innerRef = useRef(null);

  const tableProvider = useTable();
  const isLoading = tableProvider.isLoading();

  const authorize = useAuthorize();
  const snackbar = useSnackbar();

  const panelDefinitions = tableProvider.panelDefinitions;

  const panels = useMemo(() => {
    const panels = [];
    if (panelDefinitions) {
      panelDefinitions.forEach((panelDef) => {
        const panel = {
          ...panelDef,
          id: panelDef.name,
          title: panelDef.title,
          icon: <DealflowIcon size="small" groupId={panelDef.id}/>,
          color: panelDef.color,
          position: panelDef.position,
          contents: panelDef.subPanels.reduce((a, subPanelDef) => {
            if (+panelDef.id !== 0) {
              a.push({
                id: +subPanelDef.id,
                title: subPanelDef.title,
                color: subPanelDef.color,
                position: subPanelDef.position,
                emptyText: 'Empty',
                Card: ({item, ...props}) => {
                  return <EntityCard entity={item}
                                     size="small"
                                     radius="round"
                                     variant="outlined"
                                     showStatus={false}
                                     showRelevancy={false}
                                     {...props}/>
                }
              })
            }
            return a;
          }, [])
        }

        if (panel.contents.length > 0) {
          panels.push(panel);
        }
      })

      return panels;
    } else {
      return null;
    }
  }, [panelDefinitions]);

  useLayoutEffect(() => {
    if (tableProvider.state?.reset) {
      innerRef.current?.reset();
    }
  }, [tableProvider.state?.reset]);

  const handleChange = (entity, status) => {
    return tableProvider.updaters?.updateCsi?.(entity, {status})
      .catch((err) => {
        snackbar.show('Saving status failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});

        throw err;
      });
  }

  const handleCanUpdate = (entity) => {
    return authorize({attribute: 'entity.field.dealflowStatus.update', meta: {entity}})
  }

  return <StyledDealflowKanban ref={innerRef}
                               {...innerProps}
                               dataKey={tableProvider.dataKey}
                               isItemEqual={tableProvider.onCompare}
                               panels={panels}
                               lists={tableProvider.kanbanLists}
                               state={tableProvider.kanbanState}
                               onChange={handleChange}
                               onCanUpdate={handleCanUpdate}
                               isLoading={isLoading}/>
};

DealflowKanban.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowKanban.defaultProps = {
};

export default DealflowKanban;
