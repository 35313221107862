import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntityScoreGraphProfileCardContent
  from 'components/organisms/Cards/EntityScoreGraphProfileCardContent/EntityScoreGraphProfileCardContent.styles';
import AnalysisScoreInline from 'components/molecules/Inlines/AnalysisScoreInline/AnalysisScoreInline';
import Divider from 'components/atoms/Dividers/Divider/Divider';

const EntityScoreGraphProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    fields,
    entity,
    collection,
    fieldData,
    ...innerProps
  } = useComponentProps(props, 'EntityScoreGraphProfileCardContent', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  const isEditing = content.state.isEditing;

  const handleClick = () => {
    card.toggle();
  }

  const handleEdit = () => {
    card.toggle();
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledEntityScoreGraphProfileCardContent ref={innerRef} {...innerProps}>
    <AnalysisScoreInline entity={entity}
                         collection={collection}
                         fieldData={fieldData}
                         onClick={!content.state.readOnly ? handleClick : null}
                         onEdit={!content.state.readOnly ? handleEdit : null}/>
    <Divider />
  </StyledEntityScoreGraphProfileCardContent>
});

EntityScoreGraphProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  fields: PropTypes.array,
  entity: PropTypes.object,
  collection: PropTypes.object,
  fieldData: PropTypes.object
};

EntityScoreGraphProfileCardContent.defaultProps = {
};

export default EntityScoreGraphProfileCardContent;


