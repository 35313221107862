import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChipTableCell from 'components/molecules/TableCells/ChipTableCell/ChipTableCell';

const StyledCollectionVisibilityTableCell = styled(ChipTableCell)`
  .Chip-size-large {
    min-width: ${props => props.theme.layout(96)};
  }
  .Chip-size-medium {
    min-width: ${props => props.theme.layout(80)};
  }
  
  ${ComponentStyles}
`;

StyledCollectionVisibilityTableCell.propTypes = {
  theme: PropTypes.object
};

StyledCollectionVisibilityTableCell.defaultProps = {
}

export default StyledCollectionVisibilityTableCell;
