import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitySectionPanel from 'components/organisms/SectionPanels/EntitySectionPanel/EntitySectionPanel';

const StyledDealflowEntitySectionPanel = styled(EntitySectionPanel)`
  ${ComponentStyles}
`;

StyledDealflowEntitySectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitySectionPanel.defaultProps = {}

export default StyledDealflowEntitySectionPanel;
