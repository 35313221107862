import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowBarChartTableCell
  from 'components/molecules/TableCells/DealflowBarChartTableCell/DealflowBarChartTableCell.styles';
import DealflowBarChart from 'components/organisms/Charts/DealflowBarChart/DealflowBarChart';
import Box from 'components/atoms/Layout/Box/Box';
import {withMemo} from 'helpers/wrapper';

const DealflowBarChartTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    total,
    dealflow,
    onClick,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'DealflowBarChartTableCell');

  return <StyledDealflowBarChartTableCell ref={ref} {...innerProps}>
    <Box className="DealflowBarChartTableCell-bar">
      <DealflowBarChart total={total}
                        dealflow={dealflow}
                        onClick={onClick}
                        animate={false}
                        isLoading={isLoading}/>
    </Box>
  </StyledDealflowBarChartTableCell>
}));

DealflowBarChartTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  total: PropTypes.number,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
};

DealflowBarChartTableCell.defaultProps = {
};

export default DealflowBarChartTableCell;


