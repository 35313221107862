import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledToolbar from 'components/atoms/Toolbars/Toolbar/Toolbar.styles';
import {withMemo} from 'helpers/wrapper';

const Toolbar = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Toolbar');

  return <StyledToolbar ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledToolbar>
}));

Toolbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Toolbar.defaultProps = {
  children: 'Toolbar text'
};

export default Toolbar;
