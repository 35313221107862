import React, {useMemo, useState} from 'react'
import utils from 'helpers/utils';
import Component from 'components/organisms/Utils/Component/Component';

export const DialogControlContext = React.createContext(null)

export function useDialogControl () {
  return React.useContext(DialogControlContext);
}

const DialogProvider = (props) => {
  const {
    children
  } = props;

  const [internalState, setInternalState] = useState({
    DialogComponent: null,
    onClose: null,
    open: false,
    clickAway: true
  });

  const handleClose = (e, reason) => {
    if (internalState.onClose) {
      internalState.onClose(e, reason);
    } else {
      if (internalState.clickAway || reason !== 'backdropClick') {
        setInternalState(utils.updater({
          DialogComponent: null,
          open: false,
          onClose: null,
          clickAway: true
        }, true));
      }
    }
  }

  // context
  const context = useMemo(() => ({
    show: (DialogComponent, clickAway = true, onClose = null, open = true) => {
      setInternalState(utils.updater({
        DialogComponent: DialogComponent,
        open: open,
        onClose: onClose,
        clickAway: clickAway
      }, true))
    },
    hide: () => {
      setInternalState(utils.updater({
        DialogComponent: null,
        open: false,
        onClose: null,
        clickAway: true
      }, true))
    }
  }), []);

  return <React.Fragment>
    <DialogControlContext.Provider value={context}>
      {children}
    </DialogControlContext.Provider>
    {internalState.DialogComponent ? <DialogProvider>
      <Component Original={internalState.DialogComponent}
                 open={internalState.open}
                 onClose={handleClose} />
    </DialogProvider> : null}
  </React.Fragment>
};

DialogProvider.propTypes = {
}

DialogProvider.defaultProps = {
};

export default DialogProvider;
