import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledMention from 'components/molecules/Mentions/Mention/Mention.styles';
import dom from 'helpers/dom';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const Mention = React.forwardRef((props, ref) => {
  const {
    id,
    display,
    regex,
    markup,
    displayTransform,
    appendSpaceOnAdd,
    renderSuggestion,
    data,
    readOnly,
    onClick,
    showTooltip,
    renderTooltip,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'Mention', {
    styled: ['color'],
    variable: ['trigger'],
    static: ['active', 'readOnly', 'clickable', 'hoverable']
  });

  const innerRef = useRef(null);
  const [active, setActive] = useState(false);
  const [meta, setMeta] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  const clickable = onClick;
  const hoverable = showTooltip;
  const disabled = !onClick && !showTooltip;

  const renderDefaultTooltip = (id, display, meta) => {
    return !meta ? '...' : meta.label;
  }

  const renderTooltipEvent = useEffectEvent(renderTooltip ?? renderDefaultTooltip);
  useEffect(() => {
    if (!readOnly && renderTooltipEvent) {
      return utils.observeMouseMove(window, ({x, y}) => {
        const bBox = dom.getBbox(innerRef.current);
        const over = Boolean(x >= bBox.left && x <= bBox.right && y >= bBox.top && y <= bBox.bottom);
        const parent = innerRef.current.parentElement.parentElement;
        setActive((current) => {
          if (parent && current !== over) {
            const input = parent.querySelector('input, textarea');
            if (input) {
              input.style.cursor = over ? (clickable ? 'pointer' : 'default') : 'unset';
            }
            parent.style.cursor = over ? (clickable ? 'pointer' : 'default') : 'unset';
          }
          return over;
        });
      });
    }
  }, [readOnly, clickable, renderTooltipEvent]);

  useEffect(() => {
    if (active && id) {
      if (utils.isFunction(data)) {
        data(id, (res) => {
          setMeta(res?.[0]);
        });
      } else {
        const res = data.find((d) => d.id.toString() === id.toString());
        setMeta(res?.[0]);
      }
    }
  }, [active, data, id]);

  const handleClick = (e) => {
    onClick?.(e, id, display);
  }

  const handleKeyDown = (e) => {
    if (onClick && e.target === innerRef.current) {
      if (e.code === 'Space' || (e.code === 'Enter' && !dom.isNativeButton(e.target))) {
        e.target?.click();
        e.preventDefault();
      }
    }
  }

  const handleMouseEnter = () => {
    setActive(true);
  }

  const handleMouseLeave = () => {
    setActive(false);
  }

  innerProps.tabIndex = innerProps.tabIndex ?? (clickable ? 0 : -1);
  innerProps.className = utils.flattenClassName(innerProps.className, {
    active,
    clickable,
    hoverable,
    disabled
  });

  return <StyledMention ref={innerRef} {...innerProps}
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onKeyDown={handleKeyDown}>
    <Tooltip title={renderTooltipEvent?.(id, display, meta)}
             open={active}
             placement={'bottom'}
             {...TooltipProps}>
      <Span>{display}</Span>
    </Tooltip>
  </StyledMention>
});

Mention.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  display: PropTypes.string,
  regex: PropTypes.any,
  markup: PropTypes.string,
  displayTransform: PropTypes.func,
  appendSpaceOnAdd: PropTypes.bool,
  renderSuggestion: PropTypes.func,
  data: PropTypes.any,
  showTooltip: PropTypes.bool,
  renderTooltip: PropTypes.func,
  TooltipProps: PropTypes.any,
  readOnly: PropTypes.bool
};

Mention.defaultProps = {
  color: 'primary',
  showTooltip: true
};

export default Mention;
