import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import TasksContextCard from 'components/organisms/Cards/TasksContextCard/TasksContextCard';

const StyledEntityTasksContextCard = styled(TasksContextCard)` 
  ${ComponentStyles}
`;

StyledEntityTasksContextCard.propTypes = {
  theme: PropTypes.object
};

StyledEntityTasksContextCard.defaultProps = {}

export default StyledEntityTasksContextCard;
