import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItemButton from '@mui/material/ListItemButton';
import constants from 'helpers/constants';

const StyledListItemButton = styled(ListItemButton)`
  ${props => props.theme.color2Css('ListItemButton', null, props.$color, {
    interactive: true,
    type: constants.colorTypes.accentBackground
  })};

  &.Skeleton {
    width: 100%;
    max-width: unset;
    transform: unset;
  }
  
  &.ListItemButton-directional {
    ${props => props.theme.color2Css('ListItemButton', null, props.$color, {
      interactive: true,
      type: constants.colorTypes.outlined
    })};
    
    &.ListItemButton {
      border-radius: ${props => props.theme.radius(2)};
      border: ${props => props.theme.layout(1)} solid ${props => props.theme.property('palette.divider')};
      border-color: ${props => props.theme.property('palette.divider')} !important;

      .ListItemButton-directionIcon {
        color: ${props => props.theme.property('palette.divider')};
      }

      ${props => props.theme.state2Selector('ListItemButton', 'ListItemButton-directionIcon', ['hover', 'active', 'focus', 'focusVisible', 'selected'])} {
        color: ${props => props.theme.property('palette.text.disabled')};
      }
    }

    ${props => ['up', 'down'].includes(props.$direction) && css`
      flex-wrap: wrap;
      .ListItemButton-directionIcon {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    `}
    ${props => ['left', 'right'].includes(props.$direction) && css`
      .ListItemButton-directionIcon {
        display: flex;
        align-items: center;
      }
    `}
    ${props => props.$direction === 'left' && css`
      flex-direction: row-reverse;
      .ListItemText {
        text-align: right;
      }
      .ListItemIcon {
        justify-content: flex-end;
      }
    `}
    ${props => props.$direction === 'right' && css`
      .ListItemText + .ListItemIcon {
        justify-content: flex-end;
      }
    `}
  }
  
  ${ComponentStyles}
`;

StyledListItemButton.propTypes = {
  theme: PropTypes.object
};

StyledListItemButton.defaultProps = {
}

export default StyledListItemButton;
