import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import {rgba} from 'polished';

const StyledViewBar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  height: fit-content;
  min-height: unset;
  gap: ${props => props.theme.layout('2sp')};

  background-color: ${props => props.theme.property(`palette.${props.$color}.main`)};

  > .Typography {
    flex-grow: 1;
    width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
  }
  
  .Typography, .Button {
    color: ${props => props.theme.property(`palette.${props.$color}.contrastText`)} !important;
    
    &.Skeleton {
      color: rgba(0, 0, 0, 0) !important;
      background-color: ${props => rgba(props.theme.property(`palette.${props.$color}.contrastText`), 
              props.theme.property('palette.action.disabledOpacity'))} !important;
    }
  }
  
  .ViewBar-progress {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.layout('1sp')};

    .Typography {
      font-size: ${props => props.theme.fontSize(10)};
    }
    .LinearProgress {
      width: ${props => props.theme.layout(40)};

      .MuiLinearProgress-bar {
        background-color: ${props => props.theme.property(`palette.${props.$color}.contrastText`)} !important;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledViewBar.propTypes = {
  theme: PropTypes.object
};

StyledViewBar.defaultProps = {
};

export default StyledViewBar;
