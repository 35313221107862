import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionEntitiesSuggestionsBar
  from 'components/organisms/Bars/CollectionEntitiesSuggestionsBar/CollectionEntitiesSuggestionsBar.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';

const CollectionEntitiesSuggestionsBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesSuggestionsBar');

  const authorize = useAuthorize();
  const tableProvider = useTable();
  const collection = tableProvider.context?.data;

  const canUpdate = authorize({attribute: 'collection.update', meta: {collection}});

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntitiesSuggestionsBar ref={ref} {...innerProps} canUpdate={canUpdate}/>
});

CollectionEntitiesSuggestionsBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesSuggestionsBar.defaultProps = {
};

export default CollectionEntitiesSuggestionsBar;
