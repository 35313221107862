import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useEntityUploadStatus () {
  return useServiceStatus(path);
}

export function useEntityUploadList ({entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useEntityUploadGet ({entityId, uploadId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {entityId, uploadId}, queryOptions);
}

export function useEntityUploadAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityUploadUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useEntityUploadDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
