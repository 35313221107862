import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTabPanel from 'components/atoms/Tabs/TabPanel/TabPanel.styles';
import utils from 'helpers/utils';

const TabPanel = React.forwardRef((props, ref) => {
  const {
    value,
    index,
    ...innerProps
  } = useComponentProps(props, 'TabPanel');

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (value === index) {
    return <StyledTabPanel ref={ref} {...innerProps}>
      {innerProps.children}
    </StyledTabPanel>
  }
});

TabPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  index: PropTypes.number
};

TabPanel.defaultProps = {};

export default TabPanel;
