import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useTableReset} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import Checklist from '@mui/icons-material/Checklist';
import {useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDealflowEntitiesBarWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesBarWrapper/DealflowEntitiesBarWrapper.styles';
import EntitiesViewBar from 'components/organisms/Bars/EntitiesViewBar/EntitiesViewBar';
import utils from 'helpers/utils';

const DealflowEntitiesBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesBarWrapper');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const selectedEntityId = tableProvider.selected()?.entityId;
  const isLoading = !collection;
  const isTableEmpty = tableProvider?.list?.data?.length === 0;

  const [pathPrefix, pathPostfix] = useSplitPath('dealflow');

  useTableReset(pathPrefix, pathPostfix, 'table');

  const openProfileEvent = useEffectEvent(tableProvider.openProfile);
  const rightActions = useMemo(() => [
    {
      auth: utils.createAuth({ attribute: 'dealflow.todo'}),
      onClick: () => {},
      icon: Checklist,
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      }
    },
    {
      auth: utils.createAuth({attribute: 'dealflow.entity.create' }),
      onClick: () => {
        openProfileEvent?.(-1);
      },
      label: 'Add company',
      icon: Add,
      ActionButtonProps: {
        color: 'success',
        variant: 'contained',
        radius: 'round'
      }
    }
  ], [openProfileEvent]);

  const toggleActions = useMemo(() => {
    return [
      {
        label: 'Kanban',
        navigation: {
          to: `${pathPrefix}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
        }
      },
      {
        label: 'Table',
        navigation: {
          to: `${pathPrefix}/table`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
        }
      },
      {
        label: 'Profile',
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}`,
          keepSearchParams: true
        },
        auth: isTableEmpty ? utils.createAuth({attribute: 'system.null'}) : null,
        ActionToggleButtonProps: {
          showInactive: true,
        },
        ToggleButtonProps: {
          disabled: !selectedEntityId
        }
      },
      {
        label: 'Browser',
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}/browser`,
          keepSearchParams: true
        },
        auth: isTableEmpty ? utils.createAuth({attribute: 'system.null'}) : null,
        ActionToggleButtonProps: {
          showInactive: true,
        },
        ToggleButtonProps: {
          disabled: !selectedEntityId
        }
      },
      {
        label: 'Graphs',
        navigation: {
          to: `${pathPrefix}/graphs`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
        }
      },
    ];
  }, [pathPrefix, selectedEntityId, isTableEmpty]);

  return <StyledDealflowEntitiesBarWrapper ref={ref} {...innerProps}
                                           ToolbarProps={{
                                             title: 'Deal flow',
                                             rightActions,
                                             toggleActions,
                                             ViewBarComponent: EntitiesViewBar,
                                             isLoading
                                           }}>
    {innerProps.children}
  </StyledDealflowEntitiesBarWrapper>
});

DealflowEntitiesBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesBarWrapper.defaultProps = {
  children: 'DealflowEntitiesBarWrapper text',
};

export default DealflowEntitiesBarWrapper;
