import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledPopperField = styled(FormControl)`
  .PopperField-readOnly {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  &.FormControl {
    .PopperField-label {
      margin-right: 0;
    }
    
    &:not(.FormControl-disabled) {
      &:not(.FormControl-error) {
        ${props => props.theme.state2Selector('PopperField-button', null, 'hover', '')} {
          border-color: ${props => props.theme.property('palette.input.outlined.hoverBorder')} !important;
        }

        ${props => props.theme.state2Selector('PopperField-button', null, ['focus', 'focusVisible'], '')} {
          border-color: ${props => props.theme.property('palette.primary.main')} !important;
        }

        &.FormControl-focused {
          .PopperField-button {
            &.PopperField-button {
              border-color: ${props => props.theme.property('palette.primary.main')} !important;
            }
          }
        }
      }

      &.FormControl-error {
        .PopperField-button {
          &.PopperField-button {
            border-color: ${props => props.theme.property('palette.error.main')} !important;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledPopperField.propTypes = {
  theme: PropTypes.object
};

StyledPopperField.defaultProps = {
}

export default StyledPopperField;
