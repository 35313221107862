import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesTagDistributionGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;
  
  .CardContent {
    padding-top: 0;
    padding-bottom: 0;

    .CollectionEntitiesTagDistributionGraphCard {
      &-content {
        height: 100%;
        width: 100%;
        
        padding-bottom: ${props => props.theme.spacing(2.5)};
        
        .TagGroupPieChart {
          &-center {
            ${props => props.theme.breakpoints.down('lg')} {
              transform: scale(0.75);
            }
            ${props => props.theme.breakpoints.down('md')} {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesTagDistributionGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTagDistributionGraphCard.defaultProps = {}

export default StyledCollectionEntitiesTagDistributionGraphCard;
