import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import CollectionSourcesSectionPanel
  from 'components/organisms/SectionPanels/CollectionSourcesSectionPanel/CollectionSourcesSectionPanel';
import StyledSourceSelectionDialog
  from 'components/organisms/Dialogs/SourceSelectionDialog/SourceSelectionDialog.styles';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import constants from 'helpers/constants';

const SourceSelectionDialog = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    onSubmit,
    onError,
    collection,
    ...innerProps
  } = useComponentProps(props, 'SourceSelectionDialog');

  const innerRef = useRef(null);
  const [adding, setAdding] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const sections = useMemo(() => {
    const entitiesCount = collection?.newInCollection?.find((c) => c.scope === constants.collection.scopes.inside)?.total;

    return [
      // {
      //   name: 'fileUpload',
      //   position: 1,
      //   title: 'Upload file',
      //   variant: 'standard'
      // },
      {
        name: 'createSource',
        position: 2,
        title: 'Create source',
        variant: 'standard'
      },
      {
        name: 'wizardSource',
        position: 3,
        title: 'Source wizard',
        icon: AutoAwesome,
        variant: 'standard',
        tooltip: !(entitiesCount >= 3) ? 'The source wizard needs at least 3 companies in the collection to generate the best results' : null,
        disabled: !(entitiesCount >= 3),
        TooltipProps: {enterDelay: 0}
      },
      {
        name: 'databaseSource',
        position: 4,
        title: 'Sources database',
        variant: 'standard'
      }
    ];
  }, [collection]);

  const handleDialog = (open) => {
    setAdding(open);
  }

  return <StyledSourceSelectionDialog ref={innerRef} {...innerProps} hide={adding}>
    <DialogHeader title={title ?? 'Add source'}
                  subtitle={subtitle} />
    <DialogContent>
      <CollectionSourcesSectionPanel className="CollectionSourceProfileCardContent-newSource"
                                     collection={collection}
                                     sectionDefinitions={sections}
                                     isNewCollection={false}
                                     onDialog={handleDialog}
                                     onError={onError}
                                     onSubmit={onSubmit}/>
    </DialogContent>
  </StyledSourceSelectionDialog>
});

SourceSelectionDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onSubmit: PropTypes.func,
  collection: PropTypes.object
};

SourceSelectionDialog.defaultProps = {
  multiple: true
};

export default SourceSelectionDialog;
