import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTaskProgressProfileCard = styled(Box)`
  .CardHeader {
    padding-bottom: 0;
  }
  
  .TaskProgressProfileCard {
    &-content {
      padding-top: ${props => props.theme.spacing(1.5)};
    }
    &-list {
      padding: 0;
      
      .ListItem {
        display: flex;
        justify-content: space-between;
        gap: ${props => props.theme.layout('2sp')};
        padding: 0;
        
        .TaskProgressProfileCard {
          &-label {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: ${props => props.theme.layout('1sp')};
          }
          
          &-progress {
            width: ${props => props.theme.layout(120)};
          }
        }
      }
    }
  }
  
  &.TaskProgressProfileCard-isDialog {
    .TaskProgressProfileCard {
      &-content {
        padding: 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTaskProgressProfileCard.propTypes = {
  theme: PropTypes.object
};

StyledTaskProgressProfileCard.defaultProps = {
}

export default StyledTaskProgressProfileCard;
