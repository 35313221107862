import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTimelineList from 'components/organisms/Lists/TimelineList/TimelineList.styles';
import TimelineCard from 'components/molecules/Cards/TimelineCard/TimelineCard';

const TimelineList = React.forwardRef((props, ref) => {
  const {
    gap,
    timeline,
    onEdit,
    TimelineCardProps,
    ...innerProps
  } = useComponentProps(props, 'TimelineList');

  const renderItem = (item, state) => {
    return <TimelineCard item={item}
                         selected={state.selected}
                         onEdit={onEdit}
                         isLoading={state.isLoading}
                         {...TimelineCardProps}/>
  };

  return <StyledTimelineList ref={ref} {...innerProps}
                             data={timeline}
                             dataKey="timelineId"
                             renderItem={renderItem}
                             ListProps={{
                               virtualize: true,
                               gap: gap ? gap : 20
                             }} />
});

TimelineList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  gap: PropTypes.number,
  timeline: PropTypes.array,
  onEdit: PropTypes.func,
  TimelineCardProps: PropTypes.object
};

TimelineList.defaultProps = {
};

export default TimelineList;
