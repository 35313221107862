import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListItemButton from 'components/atoms/Buttons/ListItemButton/ListItemButton.styles';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const ListItemButton = withMemo(React.forwardRef((props, ref) => {
  const {
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ListItemButton', {
    static: ['selected', 'disabled'],
    variable: ['direction'],
    styled: ['color'],
    children: ['content', 'directionIcon']
  });

  const directionIcon = innerProps.variant === 'directional' ? (() => {
    switch (innerProps.direction) {
      case 'down':
        return <ExpandMore />;
      case 'left':
        return <ChevronLeft />;
      case 'up':
        return <ExpandLess />;
      default:
        return <ChevronRight />;
    }
  })() : null;

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledListItemButton ref={ref} {...innerProps} $direction={innerProps.direction}>
    {(directionIcon && ['up'].includes(innerProps.direction)) ? <Box className="ListItemButton-directionIcon">
      {directionIcon}
    </Box> : null}
    {innerProps.children}
    {(directionIcon && ['right', 'down', 'left'].includes(innerProps.direction)) ?
      <Box className="ListItemButton-directionIcon">
      {directionIcon}
    </Box> : null}
  </StyledListItemButton>
}));

ListItemButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ListItemButton.defaultProps = {
  children: 'ListItemButton text',
  color: 'primary'
};

export default ListItemButton;
