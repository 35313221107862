import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledInputOutline = styled.fieldset`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-color: ${props => props.theme.property('palette.input.outlined.enabledBorder')};
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0;
  
  > legend {
    float: unset;
    width: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 100%;
    transition: max-width 100ms cubic-bezier(0.0,0,0.2,1) 50ms;
    white-space: nowrap;
    
    > span {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      opacity: 0;
      visibility: visible;
    }
  }
  
  ${ComponentStyles}
`;

StyledInputOutline.propTypes = {
  theme: PropTypes.object
};

StyledInputOutline.defaultProps = {
}

export default StyledInputOutline;
