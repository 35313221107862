import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import CardContent from '@mui/material/CardContent';

const StyledCardContent = styled(CardContent)`
  && {
    padding: ${props => props.theme.spacing(2)};
  }

  &.CardContent-density-densest {
    padding: 0;
  }
  
  ${ComponentStyles}
`;

StyledCardContent.propTypes = {
  theme: PropTypes.object
};

StyledCardContent.defaultProps = {}

export default StyledCardContent;
