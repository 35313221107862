import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDatabaseEntityBrowserPage = styled(Page)`
  ${ComponentStyles}
`;

StyledDatabaseEntityBrowserPage.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityBrowserPage.defaultProps = {
};

export default StyledDatabaseEntityBrowserPage;
