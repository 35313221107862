import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ProfileCardContent from 'components/organisms/Cards/ProfileCardContent/ProfileCardContent';

const StyledTaskProfileCardContent = styled(ProfileCardContent)`
  .FormField-name{
    &-location {
      align-items: center;
    }
    &-responsible {
      .responsible-label {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: ${props => props.theme.layout('1sp')};
      }
    }
    &-collaborationType {
      .FormHelperText {
        display: contents;
      }
    }
  }
  ${ComponentStyles}
`;

StyledTaskProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledTaskProfileCardContent.defaultProps = {
}

export default StyledTaskProfileCardContent;
