import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard';

const StyledRecentCollectionsDashboardCard = styled(DashboardCard)`
  border: unset;
  
  .DashboardCard-header-title {
    ${props => props.theme.font2Css('h6')};
  }
  
  .CardHeader {
    padding-left: 0;
    padding-right: 0;
  }
  
  .CardContent {
    padding: 0;
  }
  
  .RecentCollectionsDashboardCard-section {
    height: ${props => props.theme.layout(192)};

    .CollectionCard {
      width: ${props => props.theme.layout(234)};
      height: ${props => props.theme.layout(192)};
    }
  }
  
  ${ComponentStyles}
`;

StyledRecentCollectionsDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledRecentCollectionsDashboardCard.defaultProps = {}

export default StyledRecentCollectionsDashboardCard;
