import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowEntityProfilePage
  from 'components/pages/Dealflow/Entity/DealflowEntityProfilePage/DealflowEntityProfilePage.styles';
import DealflowEntityProfile from 'components/organisms/Profiles/DealflowEntityProfile/DealflowEntityProfile';

const DealflowEntityProfilePage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntityProfilePage');

  return <StyledDealflowEntityProfilePage {...innerProps}>
    <DealflowEntityProfile />
  </StyledDealflowEntityProfilePage>
};

DealflowEntityProfilePage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntityProfilePage.defaultProps = {
};

export default DealflowEntityProfilePage;
