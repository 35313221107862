import React, {useMemo} from 'react'
import constants from 'helpers/constants';
import EntityProfileProvider from 'components/organisms/Providers/ProfileProvider/EntityProfileProvider';
import {useDatabaseEntityGroups, useDatabaseEntitySections} from 'services/entity/entity.utils';
import {useProviderView} from 'helpers/hooks/utils';

const DatabaseEntityProfileProvider = (props) => {
  const view = useProviderView('database.entity', props.skipView);

  const groups = useDatabaseEntityGroups(view);
  const sections = useDatabaseEntitySections(view);

  const isLoading = props?.isLoading;

  const cardDefinitions = useMemo(() => {
    return groups?.length > 0 ? groups : null;
  }, [groups]);

  const sectionDefinitions = useMemo(() => {
    return sections?.length > 0 ? sections : null;
  }, [sections]);

  const options = useMemo(() => ({
    cardState: {
      options: {
        name: 'databaseEntity',
        type: constants.appState.type.local
      }
    },
    cardDefinitions,
    sectionDefinitions
  }), [cardDefinitions, sectionDefinitions]);

  const fieldData = useMemo(() => {
    return {};
  }, []);

  const updaters = useMemo(() => {
    return {}
  }, []);

  return <EntityProfileProvider {...props}
                                view={view}
                                options={options}
                                fieldData={fieldData}
                                isLoading={isLoading}
                                updaters={updaters}>
    {props.children}
  </EntityProfileProvider>
};

DatabaseEntityProfileProvider.propTypes = {
}

export default DatabaseEntityProfileProvider;
