import PropTypes from 'prop-types';
import {withMemo} from 'helpers/wrapper';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import {Navigate as NavigateBase} from 'react-router-dom';
import constants from 'helpers/constants';

const Navigate = withMemo((props) => {
  const {
    debounce,
    ...rest
  } = props;

  return <Debounce timeout={debounce === true ? constants.debounce.micro : (!debounce ? 0 : debounce)}>
    <NavigateBase {...rest} />
  </Debounce>
})

Navigate.propTypes = {
  debounce: PropTypes.any
};

Navigate.defaultProps = {
  debounce: true
};

export default Navigate;
