import React from 'react';
import PropTypes from 'prop-types';
import StyledButtonBase from 'components/atoms/Buttons/ButtonBase/ButtonBase.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const ButtonBase = withMemo(React.forwardRef((props, ref) => {
  const {
    fullWidth,
    fullHeight,
    focusable,
    ...innerProps
  } = useComponentProps(props, 'ButtonBase', {
    static: ['selected', 'focusable', 'fullWidth', 'fullHeight']
  });

  innerProps.tabIndex = innerProps.tabIndex ?? (!focusable ? -1 : 0);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledButtonBase ref={ref} {...innerProps}>
    {innerProps.children || innerProps.label}
  </StyledButtonBase>
}));

ButtonBase.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  contents: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  focusable: PropTypes.bool
};

ButtonBase.defaultProps = {
  fullWidth: false,
  fullHeight: false,
  focusable: true
};

export default ButtonBase;
