import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledBrowserList = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: inherit;
  
  .BrowserList {
    &-browser {
      display: none;
      
      &.visible {
        display: flex;
      }
    }
  }
  ${ComponentStyles}
`;

StyledBrowserList.propTypes = {
  theme: PropTypes.object
};

StyledBrowserList.defaultProps = {
};

export default StyledBrowserList;
