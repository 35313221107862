import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledTimelineList = styled(DataList)`
  &.TimelineList {
    .ListItem {
      .ButtonBase {
        width: 100%;
      }
      &:focus {
        padding-left: 20px;
      }
    }
  }
  ${ComponentStyles}
`;

StyledTimelineList.propTypes = {
  theme: PropTypes.object
};

StyledTimelineList.defaultProps = {}

export default StyledTimelineList;
