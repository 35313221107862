import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntityMergeWizard from 'components/organisms/Wizards/EntityMergeWizard/EntityMergeWizard.styles';
import ChooseEntityWizardContent
  from 'components/organisms/WizardContent/ChooseEntityWizardContent/ChooseEntityWizardContent';
import ResolveEntityMergeWizardContent
  from 'components/organisms/WizardContent/ResolveEntityMergeWizardContent/ResolveEntityMergeWizardContent';
import {useEntitySettings} from 'services/entity/entity.utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const EntityMergeWizard = React.forwardRef((props, ref) => {
  const {
    entity,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntityMergeWizard');

  const authorize = useAuthorize();

  const [entityFieldData] = useEntitySettings();

  const steps = useMemo(() => ([
    {
      name: 'entity',
      label: 'Choose a company',
      shortLabel: 'Choose a company',
      Content: (props) => {
        return <ChooseEntityWizardContent ref={props.ref}
                                          className={props.className}
                                          wizard={props.wizard}
                                          step={props.step}
                                          entity={entity}
                                          fieldData={entityFieldData}
                                          onComplete={props.onComplete}
                                          onError={props.onError}
                                          onDirty={props.onDirty}/>
      }
    },
    {
      name: 'resolve',
      label: 'Resolve merge conflicts',
      shortLabel: 'Resolve conflicts',
      Content: (props) => {
        return <ResolveEntityMergeWizardContent ref={props.ref}
                                                className={props.className}
                                                wizard={props.wizard}
                                                step={props.step}
                                                entity={entity}
                                                fieldData={entityFieldData}
                                                onComplete={props.onComplete}
                                                onError={props.onError}
                                                onDirty={props.onDirty}/>
      },
      enableSubmit: (data) => {
        const dealflowConficts = Boolean(data.entityMerge?.merge?.conflicts?.includes('dealflow') || data.entityMerge?.merge?.conflictingDsi);

        return data.entityMerge?.merge?.mergeAble || !dealflowConficts ||
          authorize({attribute: 'entity.merge.force', meta: {entity}});
      }
    }
  ]), [entity, authorize, entityFieldData]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    return onSubmit?.(data.entityMerge, actions, onSuccess, onError);
  }

  return <StyledEntityMergeWizard ref={ref} {...innerProps}
                                  variant="simple"
                                  steps={steps}
                                  dataKey="entityMerge"
                                  onSubmit={handleSubmit}/>
});

EntityMergeWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  onSubmit: PropTypes.func
};

EntityMergeWizard.defaultProps = {};

export default EntityMergeWizard;
