import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import Typography from 'components/atoms/Text/Typography/Typography';
import {useClientTeamMembers} from 'services/client/team/team.utils';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import utils from 'helpers/utils';
import ListItemButton from 'components/atoms/Buttons/ListItemButton/ListItemButton';
import StyledUserList from 'components/molecules/Lists/UserList/UserList.styles';
import {useAuthTeamId} from 'services/auth/auth.utils';

const UserList = React.forwardRef((props, ref) => {
  const {
    user,
    proxies,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'UserList');

  const teamId = useAuthTeamId();
  const teamMembers = useClientTeamMembers(teamId, proxies);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (member) => {
    return (e) => {
      onClick?.(e, member?.profile ?? member);
    }
  }

  const selectedMemberIdx = (teamMembers ?? []).findIndex((member) => +member.userId === +user?.userId);
  useEffect(() => {
    if (selectedMemberIdx !== -1) {
      setSelectedIndex(selectedMemberIdx);
    }
  }, [selectedMemberIdx]);

  const handleSelectionChange = (e, idx) => {
    setSelectedIndex(idx);
  }

  return <StyledUserList ref={ref} {...innerProps}
                         divider={true}
                         onSelectionChange={handleSelectionChange}
                         selectedIndex={selectedIndex}>
    {teamMembers?.map((member) => {
      const avatar = `${utils.personName(member.profile.firstName, member.profile.lastName)} (${member.profile.username})`;
      const name = utils.personName(member.profile.firstName, member.profile.lastName);
      const color = utils.string2Color(avatar);

      return <ListItem key={member.userId}>
        <ListItemButton onClick={handleClick(member)} selected={+member.userId === +user?.userId}>
          <ActionAvatar action={{
                          label: utils.avatarLabel(avatar),
                          tooltip: avatar
                        }}
                        size="small"
                        color={color}
                        TooltipProps={{enterDelay: 0}}/>
          <Typography variant="body2"
                      min={8} max={20}>{name}</Typography>
        </ListItemButton>
      </ListItem>
    })}
  </StyledUserList>
});

UserList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  user: PropTypes.object,
  teamMembers: PropTypes.bool,
  proxies: PropTypes.bool,
  onClick: PropTypes.func
};

UserList.defaultProps = {};

export default UserList;
