import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextCard from 'components/molecules/Cards/ContextCard/ContextCard';

const StyledUserContextCard = styled(ContextCard)`
  .UserContextCard {
    &-edit {
      display: none;
      color: ${props => props.theme.property('palette.text.disabled')};
    }
    
    &-content {
      display: flex;
      align-items: center;
      
      > *:first-child {
        flex-grow: 1;
      }
    }
    
    &-subject {
      display: flex;
      align-items: center;
      column-gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  &.UserContextCard-canUpdate {
    .UserContextCard {
      &-content {
        cursor: pointer;

        &:hover {
          .UserContextCard {
            &-edit {
              display: block;
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledUserContextCard.propTypes = {
  theme: PropTypes.object
};

StyledUserContextCard.defaultProps = {}

export default StyledUserContextCard;
