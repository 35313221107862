import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Table from '@mui/material/Table';

const StyledTable = styled(Table)`
  ${ComponentStyles}
`;

StyledTable.propTypes = {
  theme: PropTypes.object
};

StyledTable.defaultProps = {
}

export default StyledTable;
