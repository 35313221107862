import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAppBar from 'components/atoms/Toolbars/AppBar/AppBar.styles';
import {withMemo} from 'helpers/wrapper';

const AppBar = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'AppBar');

  return <StyledAppBar ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledAppBar>
}));

AppBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

AppBar.defaultProps = {
  children: 'AppBar text'
};

export default AppBar;
