import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';

const StyledPeriodUpdateListItem = styled(ListItem)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: ${props => props.theme.layout('1sp')};
  padding: ${props => props.theme.spacing(3)} 0;
  
  .PeriodUpdateListItem {
    &-avatar {
      width: ${props => props.theme.layout(56)};
      height: ${props => props.theme.layout(56)};
    }
    
    &-text {
      margin: 0;
      padding-left: ${props => props.theme.spacing(2)};
      .MuiListItemText-primary {
        ${props => props.theme.font2Css('h3')};
        line-height: ${props => props.theme.lineHeight('1.285f')};
        color: ${props => props.theme.property('palette.primary.main')};
      }
    }
  }
  
  &.PeriodUpdateListItem {
    .MuiListItemSecondaryAction-root {
      position: static;
      transform: unset;
    }
  }
  
  ${ComponentStyles}
`;

StyledPeriodUpdateListItem.propTypes = {
  theme: PropTypes.object
};

StyledPeriodUpdateListItem.defaultProps = {}

export default StyledPeriodUpdateListItem;
