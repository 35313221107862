import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfileFoldout from 'components/organisms/Foldouts/EntityProfileFoldout/EntityProfileFoldout';

const StyledCollectionEntityProfileFoldout = styled(EntityProfileFoldout)`
  ${ComponentStyles}
`;

StyledCollectionEntityProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityProfileFoldout.defaultProps = {}

export default StyledCollectionEntityProfileFoldout;
