import {useServiceApiMutation} from 'stores/hooks/query';
import utils from 'helpers/utils';

const path = utils.servicePath(import.meta.url);

export function useCollectionUploadAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {},
    {
      overridePath: 'sources',
      refetchContext: true,
      ...mutationOptions
    });
}