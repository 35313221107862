import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import StyledStatusGroupCard from 'components/organisms/Cards/StatusGroupCard/StatusGroupCard.styles';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';

const StatusGroupCard = React.forwardRef((props, ref) => {
  const {
    statusGroup,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'StatusGroupCard');

  const renderStatuses = () => {
    const statuses = !isLoading ? statusGroup?.statuses : (new Array(Math.max(2, Math.ceil(Math.random() * 8))))
      .fill({value: utils.randomString(8, 16)});

    return <Box className="StatusGroupCard-statuses">
      <ChipList variant="compact"
                drawFromStart={true}>
        {statuses?.map((s, idx) => {
          return <Chip key={idx}
                       size="small" label={s.name}
                       variant="outlined"
                       isLoading={isLoading}
                       color={utils.deprecatedColor(statusGroup?.color) ?? 'secondary'}/>
        })}
      </ChipList>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledStatusGroupCard ref={ref} {...innerProps}>
    <Box className="StatusGroupCard-content">
      <Box className="StatusGroupCard-info">
        <Box className="StatusGroupCard-icon">
          <DealflowIcon isLoading={isLoading}
                        groupId={statusGroup?.groupId} />
        </Box>
        <H6 className="StatusGroupCard-title"
            showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>
          {statusGroup?.name}
        </H6>
      </Box>
    </Box>
    {renderStatuses()}
  </StyledStatusGroupCard>
});

StatusGroupCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statusGroup: PropTypes.object,
  isLoading: PropTypes.bool
};

StatusGroupCard.defaultProps = {};

export default StatusGroupCard;
