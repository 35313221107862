import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTabs from 'components/atoms/Tabs/Tabs/Tabs.styles';
import utils from 'helpers/utils';

const Tabs = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Tabs', {
    static: ['disabled'],
    variable: ['orientation']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTabs ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledTabs>
});

Tabs.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

Tabs.defaultProps = {
  children: 'Tabs text',
  textColor: 'primary',
  indicatorColor: 'primary'
};

export default Tabs;
