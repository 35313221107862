import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledSliderField = styled(FormControl)`
  .SliderField {
    &-container {
      padding: 0;
    }
    &-description {
      ${props => props.theme.font2Css('body2')};
      margin-top: ${props => props.theme.spacing(1)};
      
      .Markdown {
        display: inline-block;
      }
    }
  }
  
  &.FormControl-readOnly {
    .SliderField {
      &-container {
        padding: 0;
        
        .SliderField-readOnly { 
          display: flex;
          gap: ${props => props.theme.layout('1sp')};
          align-items: center;
          
          &:has(.SliderField-readOnly-item) {
            flex-direction: column;
            align-items: flex-start;
          }
          &-item {
            display: flex;
            gap: ${props => props.theme.layout('1sp')};
            align-items: center;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSliderField.propTypes = {
  theme: PropTypes.object
};

StyledSliderField.defaultProps = {
}

export default StyledSliderField;
