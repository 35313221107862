import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledToggleButtonGroup from 'components/atoms/Toggles/ToggleButtonGroup/ToggleButtonGroup.styles';

const ToggleButtonGroup = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ToggleButtonGroup');

  return <StyledToggleButtonGroup ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledToggleButtonGroup>
});

ToggleButtonGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  radius: PropTypes.any
};

ToggleButtonGroup.defaultProps = {
  children: 'ToggleButtonGroup text'
};

export default ToggleButtonGroup;
