import React, {useEffect} from 'react'
import {useAuthLogout} from 'services/auth/auth.hooks';
import utils from 'helpers/utils';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import {api} from 'helpers/hooks/api';
import {useAuth} from 'services/auth/auth.utils';
import {useRouter} from 'components/organisms/Providers/RouteProvider/RouteProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const AuthIntercept = (props) => {
  const auth = useAuth();

  const router = useRouter();
  const snackbar = useSnackbar();

  const doLogout = useAuthLogout();

  useEffect(() => {
    const authInterceptor = api.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      const location = router.state?.location;
      const intercept = !error?.request?.responseURL?.includes('intercept=false');

      if (intercept) {
        if ((error?.response?.status === constants.http.status.conflict && error.response?.data?.userDisabled) || error?.response?.status === constants.http.status.unauthorized) {
          logger.trace('Session expired', error);

          if (error.response.status === constants.http.status.unauthorized) {
            snackbar.show('Your session has expired. Please login again', null,
              {color: 'warning', autoHideDuration: constants.delay.warning});
          } else {
            snackbar.show('Your account has been suspended', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          }

          doLogout.mutation.mutateAsync({authId: auth?.authId}).finally(() => {
            if (router) {
              const state = {from: utils.comparePath(location?.pathname, '/auth/login') ? location?.state?.from : location};
              router.navigate('/auth/login', {replace: true, state});
            }
          });
        } else if (error?.response?.status === constants.http.status.forbidden) {
          logger.trace('Not authorized to see this information', error);

          if (auth?.loggedIn) {
            snackbar.show('You are not authorized to see this information. You are redirected to an allowed page.', null,
              {color: 'warning', autoHideDuration: constants.delay.warning});

            if (router) {
              router.navigate('/', {replace: true});
            }
          } else {
            doLogout.mutation.mutateAsync({authId: auth?.authId}).finally(() => {
              if (router) {
                const state = {from: utils.comparePath(location?.pathname, '/auth/login') ? location?.state?.from : location};
                router.navigate('/auth/login', {replace: true, state});
              }
            });
          }
        }
      }

      return Promise.reject(error);
    });

    return () => {
      api.interceptors.response.eject(authInterceptor);
    }
  }, [doLogout.mutation, snackbar, router, auth?.authId, auth?.loggedIn]);

  return <React.Fragment>
    {props.children}
  </React.Fragment>
};

AuthIntercept.propTypes = {
}

export default AuthIntercept;
