import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDealflowEntitySectionPanel
  from 'components/organisms/SectionPanels/DealflowEntitySectionPanel/DealflowEntitySectionPanel.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import DealflowEntityProfile from 'components/organisms/Profiles/DealflowEntityProfile/DealflowEntityProfile';

const DealflowEntitySectionPanel = React.forwardRef((props, ref) => {
  const {
    showProfile,
    newProfile,
    onCloseCard,
    ...innerProps
  } = useComponentProps(props, 'DealflowEntitySectionPanel');

  const profileProvider = useProfile();
  const sectionDefinitions = profileProvider?.sectionDefinitions;

  const authorize = useAuthorize();

  const onCloseCardEvent = useEffectEvent(onCloseCard);
  const sections = useMemo(() => {
    if (sectionDefinitions) {
      const sections = [];

      sectionDefinitions
        .sort((a, b) => a.position - b.position)
        .forEach((sectionDef) => {
          const canEditSection = (entity) => {
            return !(sectionDef.readOnly ?? false) && authorize({...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, entity}});
          }

          if (sectionDef.name === 'profile') {
            if (showProfile && !newProfile) {
              sections.push({
                ...sectionDef,
                Content: () => {
                  const handleCanUpdate = (entity) => {
                    return canEditSection(entity) && authorize(sectionDef.auth?.update ? {...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, entity}} : {});
                  }

                  return <DealflowEntityProfile gap={8} columns={1}
                                                onCloseCard={onCloseCardEvent}
                                                onCanUpdate={handleCanUpdate}/>
                }
              });
            }
          }
        });

      return sections;
    } else {
      return [];
    }
  }, [sectionDefinitions, authorize, showProfile, newProfile, onCloseCardEvent]);

  return <StyledDealflowEntitySectionPanel ref={ref} {...innerProps}
                                           sections={sections}
                                           showProfile={showProfile}
                                           newProfile={newProfile}/>
});

DealflowEntitySectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showProfile: PropTypes.bool
};

DealflowEntitySectionPanel.defaultProps = {};

export default DealflowEntitySectionPanel;
