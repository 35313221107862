import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SectionPanel from 'components/organisms/SectionPanels/SectionPanel/SectionPanel';

const StyledEntitySectionPanel = styled(SectionPanel)`
  ${ComponentStyles}
`;

StyledEntitySectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledEntitySectionPanel.defaultProps = {}

export default StyledEntitySectionPanel;
