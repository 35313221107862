import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledTaskProfileWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledTaskProfileWrapper.propTypes = {
  theme: PropTypes.object
};

StyledTaskProfileWrapper.defaultProps = {
};

export default StyledTaskProfileWrapper;
