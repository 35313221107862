import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import StyledSwitchField from 'components/molecules/Fields/SwitchField/SwitchField.styles';
import FormControlLabel from 'components/atoms/Labels/FormControlLabel/FormControlLabel';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import Switch from 'components/atoms/Switches/Switch/Switch';
import {withMemo} from 'helpers/wrapper';

const SwitchField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    size,
    placeholder,
    helperText,
    autoFocus,
    values,
    onBlur,
    onChange,
    inputProps,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'SwitchField', {
    static: ['disabled', 'focused', 'error'],
    children: ['switch', 'label', 'helper']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const inlineLabel = innerProps.variant === 'inlineLabel';
  const valueLabel = value === true ? values[0] : values[1];

  const handleChange = (e) => {
    onChange?.({
      target: {
        name: name,
        value: e?.target?.checked
      }
    });
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSwitchField ref={innerRef} {...innerProps}>
    {(innerProps.readOnly || inlineLabel) ? <InputLabel size={size === 'smallest' ? 'small' : size}
                                                        {...utils.cleanObject({
                                                          htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                                                          shrink: innerProps.readOnly || innerProps.disabled || null
                                                        })}
                                                        className={utils.classNames('SwitchField-label', InputLabelProps?.className)}
                                                        {...InputLabelProps}>
      {label}
    </InputLabel> : null}

    <InputContainer className="SwitchField-container">
      {!innerProps.readOnly ? <FormControlLabel label={inlineLabel ? '' : label}
                                                control={<Switch id={id}
                                                                 name={name}
                                                                 checked={Boolean(value)}
                                                                 size={size === 'smallest' ? 'small' : size}
                                                                 color="primary"
                                                                 className="SwitchField-switch"
                                                                 onChange={handleChange}
                                                                 onBlur={onBlur}
                                                                 inputProps={inputProps}
                                                                 {...InputProps}/>}
                                                {...InputLabelProps}
                                                className={utils.classNames('SwitchField-label', InputLabelProps?.className)}/> : null}
      {innerProps.readOnly ? <Box className="SwitchField-readOnly Input-readOnly">
        <Span>{valueLabel}</Span>
      </Box> : null}

      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('SwitchField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledSwitchField>
}));

SwitchField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.any,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  values: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

SwitchField.defaultProps = {
  inputProps: {},
  values: ['Yes', 'No'],
  size: 'medium',
};

export default SwitchField;
