import React, {useMemo} from 'react'
import utils from 'helpers/utils';
import ClientsSettingPage from 'components/pages/Setting/ClientsSettingPage/ClientsSettingPage';
import ClientSettingPage from 'components/pages/Setting/ClientSettingPage/ClientSettingPage';

export default function useSettingClientRoutes () {
  return useMemo(() => {
    const clientMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'client',
            path: 'client', // store path
            id: match?.params?.clientId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',

        element: <ClientsSettingPage />,

        handle: {
          title: 'Settings - clients',
          auth: utils.createAuth({
            attribute: 'settings.clients.route'
          })
        }
      },
      {
        path: ':clientId',
        element: <ClientSettingPage />,

        handle: {
          title: 'Settings - client',
          auth: utils.createAuth({
            attribute: 'settings.client.route',
            meta: clientMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, []);
};
