import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledCollectionAddDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledCollectionAddDialog.propTypes = {
  theme: PropTypes.object
};

StyledCollectionAddDialog.defaultProps = {}

export default StyledCollectionAddDialog;
