import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';

const StyledActionAvatar = styled(Avatar)`
  ${ComponentStyles}
`;

StyledActionAvatar.propTypes = {
  theme: PropTypes.object
};

StyledActionAvatar.defaultProps = {
}

export default StyledActionAvatar;
