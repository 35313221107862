import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledRelevancyBreakDownChart = styled(Box)`
  .RelevancyBreakDownChart {
    &-relevancy {
      display: flex;
      align-items: center;
      padding: ${props => props.theme.spacing(1)};
      gap: ${props => props.theme.layout('1.5sp')};
      
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      }
      
      .Chip {
        width: ${props => props.theme.layout(67)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledRelevancyBreakDownChart.propTypes = {
};

StyledRelevancyBreakDownChart.defaultProps = {
}

export default StyledRelevancyBreakDownChart;
