import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledIcon = styled.i`
  color: ${props => props.color ?
          (!props.theme.property(`palette.${props.color}`)?.main ?
                  props.theme.property(`palette.${props.color}`) : null) : null};

  ${props => props.theme.state2Selector('Icon', null, 'disabled')} {
    pointer-events: none;
    color: ${props => props.theme.property('palette.action.disabled')} !important;
  }
  
  // sizes
  &.Icon-size-mini {
    font-size: ${props => props.theme.fontSize(8)} !important;
  }
  &.Icon-size-tinier {
    font-size: ${props => props.theme.fontSize(10)} !important;
  }
  &.Icon-size-tiny {
    font-size: ${props => props.theme.fontSize(12)} !important;
  }
  &.Icon-size-smallest {
    font-size: ${props => props.theme.fontSize(14)} !important;
  }
  &.Icon-size-smaller {
    font-size: ${props => props.theme.fontSize(16)} !important;
  }
  &.Icon-size-small {
    font-size: ${props => props.theme.fontSize(20)} !important;
  }
  &.Icon-size-medium {
    font-size: ${props => props.theme.fontSize(24)} !important;
  }
  &.Icon-size-large {
    font-size: ${props => props.theme.fontSize(35)} !important;
  }
  &.Icon-size-larger {
    font-size: ${props => props.theme.fontSize(48)} !important;
  }
  
  ${ComponentStyles}
`;

StyledIcon.propTypes = {
  theme: PropTypes.object
};

StyledIcon.defaultProps = {
}

export default StyledIcon;
