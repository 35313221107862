import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledAnalysisScoreBarChart
  from 'components/organisms/Charts/AnalysisScoreBarChart/AnalysisScoreBarChart.styles';

const AnalysisScoreBarChart = React.forwardRef((props, ref) => {
  const {
    total,
    breakdown,
    showPercentage,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreBarChart');

  const breakdownMemo = useMemo(() => {
    const bd = (breakdown ?? [])
      .map((b) => {
        const points = b.questions.reduce((t, q) => t + +q.points, 0);
        return {
          ...b,
          points: points,
          count: points,
          tagGroupId: +b.tagGroup.groupId,
          color: b.tagGroup.color ? innerProps.theme.property(`palette.${b.tagGroup.color}.main`) :
            innerProps.theme.property('palette.tagGroupDefault.main'),
          position: +b.tagGroup.pos,
          barLabel: ''
        }
      });

    const sum = bd.reduce((t, s) => t + +s.points, 0);
    if (sum < total || total === 0) {
      const percentage = (total > 0 && sum >= 0) ? Math.round(sum / total * 100) : 0;
      bd.push({
        tagGroupId: 0,
        total: total,
        scored: sum,
        points: sum >= 0 ? (total - sum) : total,
        count: total === 0 ? 1 : (sum > 0 ? (total - sum) : total),
        color: utils.deprecatedColor('light-gray'),
        position: bd.reduce((t, s) => t + +s.position, 0) + 1,
        barLabel: showPercentage ? `${percentage}%` : ''
      });
    }

    return bd.filter((bd) => bd.count > 0);
  }, [breakdown, total, innerProps.theme, showPercentage]);

  const analysisData = useMemo(() => {
    return {
      dataKey: 'name',
      layout: 'vertical',
      bars: breakdownMemo
        .map((s) => {
          return {
            position: s.position,
            dataKey: s.tagGroupId,
            name: s.tagGroupId,
            color: s.color
          }
        }),
      data: [breakdownMemo
        .reduce((o, s) => {
          o[s.tagGroupId] = s.count;
          o[`${s.tagGroupId}-meta`] = s;
          return o;
        }, {})],
      XAxisProps: {
        allowDecimals: true
      },
      YAxisProps: {
        allowDecimals: true
      }
    };
  }, [breakdownMemo]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAnalysisScoreBarChart ref={ref} {...innerProps}
                                      {...analysisData}
                                      radius={8}
                                      gap={1}
                                      showLabels={showPercentage}
                                      showTooltip={false}
                                      stackOffset='expand'
                                      hideAxis={true}/>
});

AnalysisScoreBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  total: PropTypes.number,
  breakdown: PropTypes.array,
  showPercentage: PropTypes.bool
};

AnalysisScoreBarChart.defaultProps = {
};

export default AnalysisScoreBarChart;
