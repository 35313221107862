import React, {useLayoutEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import StyledSimpleQuery from 'components/organisms/Queries/SimpleQuery/SimpleQuery.styles';
import {default as searchUtils} from 'helpers/search';
import dom from 'helpers/dom';

const SimpleQuery = React.forwardRef((props, ref) => {
  const {
    query,
    search,
    filter,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'SimpleQuery');

  const searchFieldRef = useRef(null);
  const onChangeEvent = useEffectEvent(onChange);

  useLayoutEffect(() => {
    const newQuery = searchUtils.simpleQuery(query, search, filter);
    onChangeEvent?.(search, newQuery);
  }, [query, search, filter, onChangeEvent]);

  const handleChange = (field, value) => {
    const newQuery = searchUtils.simpleQuery(query, value, filter);
    onChangeEvent?.(value, newQuery);
  };

  const fields = useMemo(() => ([{
    name: 'search',
    placeholder: 'Search for companies',
    type: 'text',
    validation: 'text',
    initial: search,
    prefix: <Icon icon={Search} />,
    postfix: search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Close}/>,
      onClick: () => {
        searchFieldRef.current?.clear();
        dom.focusElement(searchFieldRef.current);
      }
    }} /> : null,
    FormFieldProps: {
      ref: searchFieldRef,
      debounce: constants.debounce.search,
      hiddenLabel: true,
      variant: 'outlined'
    }
  }]), [search]);

  return <StyledSimpleQuery ref={ref} {...innerProps}>
    <InlineForm className="SimpleQuery-form"
                onChange={handleChange}
                fields={fields}/>
  </StyledSimpleQuery>
});

SimpleQuery.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  search: PropTypes.string,
  filter: PropTypes.array,
  query: PropTypes.object,
  onChange: PropTypes.func
};

SimpleQuery.defaultProps = {};

export default SimpleQuery;
