import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledEntitiesFinalizeWizardContent = styled(Box)`
  &.EntitiesFinalizeWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('2sp')};
    width: 100%;
  }
    
  .EntitiesFinalizeWizardContent {
    &-subtitle {
      .H6 {
        color: ${props => props.theme.property('palette.text.secondary')};
      }
    }
    
    &-table {
      height: 100%;
      border-radius: ${props => props.theme.radius(2)};
      background-color: ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))};
      position: relative;
      overflow: hidden;

      .Table {
        padding: ${props => props.theme.layout('2.25sp')};

        .MuiPaper-root, tr, th, td {
          z-index: 0 !important;
          background-color: transparent !important;
        }
        
        .MuiTableRow-root {
          min-height: unset;
          height: ${props => props.theme.layout(36)};
          background-color: ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))};

          .TableCell, .TableHeader {
            border-bottom: unset;

            .TextField-multiline {
              .Input-readOnly {
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;

                * {
                  -webkit-line-clamp: 1; /* number of lines to show */
                  line-clamp: 1;
                }
              }
            }

            .FieldTableCell {
              &:has(.ChipList-compact:not(.ChipList-nowrap)) {
                align-items: center !important;
                
                .ChipList-compact {
                  flex-wrap: nowrap;
                }
              }
            }
          }
          &:hover td {
            background-color: unset !important;
          }
        }
      }
    }
    
    &-table::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 30%), linear-gradient(270deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 60%);
    }
  }
    
  ${ComponentStyles}
`;

StyledEntitiesFinalizeWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesFinalizeWizardContent.defaultProps = {}

export default StyledEntitiesFinalizeWizardContent;
