import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {H6, P, Span} from 'components/atoms/Text/Typography/Typography';
import Link from 'components/atoms/Links/Link/Link';
import StyledWelcomeEmailPage from 'components/pages/Dev/Email/WelcomeEmailPage/WelcomeEmailPage.styles';
import Button from 'components/atoms/Buttons/Button/Button';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const WelcomeEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'WelcomeEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledWelcomeEmailPage {...innerProps}
                                     config={clientConfig}
                                     preview="Welcome to Catalist! Your Catalist journey starts here.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>Welcome to Catalist! Our deal flow management tool and discovery tool. First you need to
              setup your password. Use this link to setup your password:</P>
          </TableCell>
        </TableRow>
        <TableRow className="WelcomeEmailPage-resetButtonRow">
          <TableCell colSpan={2}>
            <Button component="a" href="{{RESET_LINK}}" variant="contained" size="medium">Setup
              password</Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>
              Or cut and paste this text if the link doesn't work:<br/>
              <Link className="system" href="{{RESET_LINK}}">{'{{RESET_LINK}}'}</Link>
            </P>
          </TableCell>
        </TableRow>
        <TableRow className="WelcomeEmailPage-gettingStartedRow">
          <TableCell colSpan={2}>
            <H6>Getting started in Catalist:</H6>
            <List>
              <ListItem>
                When you log into Catalist you will find the Dashboard we set up for your company.
              </ListItem>
              <ListItem>
                Via the side menu you can navigate to the following pages:
              </ListItem>
              <List>
                <ListItem>
                  <P>
                    Teams<br/>
                    <Span color="text.secondary">
                      If your company uses multiple Teams in Catalist you can switch to another team by clicking on
                      the Team button in the side menu.
                    </Span>
                  </P>
                </ListItem>
                <ListItem>
                  <P>
                    Database<br/>
                    <Span color="text.secondary">
                      Via this page you can access all the company profiles in the Venture IQ database
                      or your own database and apply filters as desired. Results can be added to existing or new
                      collections.
                    </Span>
                  </P>
                </ListItem>
                <ListItem>
                  <P>
                    Collections<br/>
                    <Span color="text.secondary">
                      Via this page you can access all collections, which are lists of companies. Shared
                      team collections will be visible and you can also create private collections.
                    </Span>
                  </P>
                </ListItem>
                <ListItem>
                  <P>
                    Dealflow<br/>
                    <Span color="text.secondary">
                      Via this page you can access your deal flow.
                      The kanban display list all your companies and their status in your team's
                      dealflow.
                    </Span>
                  </P>
                </ListItem>
                <ListItem>
                  <P>
                    Search<br/>
                    <Span color="text.secondary">
                      Via the magnifier icon you can find the company profiles and
                      quickly search them by their name. By clicking ‘Show all’ you will go to the
                      database page.
                    </Span>
                  </P>
                </ListItem>
                <ListItem>
                  <P>
                    Settings<br/>
                    <Span color="text.secondary">
                      Via the cogwheel icon you can find the general settings.
                      There you can see your profile and edit general settings for your Catalist environment.
                    </Span>
                  </P>
                </ListItem>
              </List>
            </List>
          </TableCell>
        </TableRow>
        <TableRow className="WelcomeEmailPage-pluginRow">
          <TableCell colSpan={2}>
            <H6>Catalist plug-in:</H6>
            <List>
              <ListItem>
                With the Catalist plug-in you can add companies directly to your database while you are
                looking at the website. Install it right away, it’s free for our Catalist Users!
              </ListItem>
            </List>
          </TableCell>
        </TableRow>
        <TableRow className="WelcomeEmailPage-pluginButtonRow">
          <TableCell colSpan={2}>
            <Button component="a"
                    href="https://chrome.google.com/webstore/detail/venture-iq/faafdhglglkkdnmnklhhhaheilffmnhm"
                    variant="contained" size="medium">
              Install plugin
            </Button>
          </TableCell>
        </TableRow>
        <TableRow className="WelcomeEmailPage-pluginInstallRow">
          <TableCell colSpan={2}>
            <P>
              Or cut and paste this text if the link doesn't work:<br/>
              <Link
                href="https://chrome.google.com/webstore/detail/venture-iq/faafdhglglkkdnmnklhhhaheilffmnhm">
                https://chrome.google.com/webstore/detail/venture-iq/faafdhglglkkdnmnklhhhaheilffmnhm
              </Link>
            </P>
            <List>
              <ListItem>First click 'Add to Chrome' to install the plug-in.</ListItem>
              <ListItem>Then click 'Ok' on both pop-ups, this will take you to the options page.</ListItem>
              <ListItem>Finally switch the ‘Allow access to file URLs’ toggle on for optimal use.</ListItem>
            </List>
            <P>
              The extension icon in your browser will turn blue on every website you visit except Catalist
              and Google. Click the icon to see if it's already in Catalist, if not you can directly add the new
              company.
            </P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you can now log in. Are you still unsure, or do you still have questions?
              Please let us know.</P>
          </TableCell>
        </TableRow>
      </StyledWelcomeEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

WelcomeEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

WelcomeEmailPage.defaultProps = {};

export default WelcomeEmailPage;
