import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader.styles';
import utils from 'helpers/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Grow from '@mui/material/Grow';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoPaper from 'components/molecules/Papers/InfoPaper/InfoPaper';

const CollectionSectionPanelHeader = React.forwardRef((props, ref) => {
  const {
    title,
    info,
    buttons,
    ...innerProps
  } = useComponentProps(props, 'CollectionSectionPanelHeader', {
    styled: ['color']
  });

  const [showInfo, setShowInfo] = useState(false);

  const infoAction = useMemo(() => ({
    tooltip: 'Show info',
    onClick: () => setShowInfo((current) => !current),
    icon: InfoOutlined
  }), []);

  return <StyledCollectionSectionPanelHeader ref={ref} {...innerProps}>
    <Box className="CollectionSectionPanelHeader-head">
      <Box className="CollectionSectionPanelHeader-title">{utils.isReactElement(title) ? title : <H6>{title}</H6>}</Box>
      {(buttons || info) ? <Box className="CollectionSectionPanelHeader-buttons">
        {info ? <ActionIconButton variant="outlined"
                                  action={infoAction}
                                  flatBackground={true}
                                  radius="round"
                                  density="denser" /> : null}
        {buttons}
      </Box> : null}
    </Box>
    {showInfo ? <ClickAwayListener onClickAway={() => setShowInfo(false)}>
      <Grow in={showInfo}>
        <InfoPaper className="CollectionSectionPanelHeader-info" info={info} elevation={1} />
      </Grow>
    </ClickAwayListener>: null}
  </StyledCollectionSectionPanelHeader>
});

CollectionSectionPanelHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  info: PropTypes.any,
  buttons: PropTypes.any
};

CollectionSectionPanelHeader.defaultProps = {
  color: 'primary'
};

export default CollectionSectionPanelHeader;
