import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class SourceService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);
    super(path, app, {
      process: (data, meta) => {
        const processSource = (data) => {
          return utils.camelcaseEx(data, true);
        }

        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            data = data.map(processSource);
          } else {
            data = processSource(data);
          }
        } else {
          return data;
        }

        return data;
      }
    }, callbacks);
  }
}
