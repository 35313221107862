import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesSuggestionBrowser from 'components/organisms/Browsers/EntitiesSuggestionBrowser/EntitiesSuggestionBrowser';

const StyledCollectionEntitiesSuggestionBrowser = styled(EntitiesSuggestionBrowser)` 
  ${ComponentStyles}
`;

StyledCollectionEntitiesSuggestionBrowser.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesSuggestionBrowser.defaultProps = {}

export default StyledCollectionEntitiesSuggestionBrowser;
