function mergeSections (sectionsA, sectionsB) {
  return sectionsA.concat((sectionsB ?? []).map((section) => {
    const existingSectionIndex = sectionsA.findIndex((s) => {
      return s.name === section.name;
    })

    if (existingSectionIndex !== -1) {
      const cards = section.cards ?? [];
      sectionsA[existingSectionIndex].cards?.forEach?.((card) => {
        if (!section.cards.find((c) => c.name === card.name)) {
          cards.push(card);
        }
      });

      sectionsA[existingSectionIndex] = {
        ...sectionsA[existingSectionIndex],
        ...section,
        cards
      }

      return null;
    } else {
      return section;
    }
  }).filter((_) => (_)));
} 

const section = {
  mergeSections
};

export default section;
