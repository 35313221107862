import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useClientStatisticCreditDistribution} from 'services/client/statistic/statistic.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import StyledCreditDistributionGraphCard
  from 'components/organisms/Cards/CreditDistributionGraphCard/CreditDistributionGraphCard.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import CreditDistributionPieChart
  from 'components/organisms/Charts/CreditDistributionPieChart/CreditDistributionPieChart';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';

const CreditDistributionGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CreditDistributionGraphCard');

  const profileProvider = useProfile();
  const graphState = profileProvider.graphState;
  const [credits, setCredits] = useState(null);
  const months = graphState.graphSettings['creditDistribution']?.filter?.months ?? 1;

  const [creditDistribution, isLoading] = useClientStatisticCreditDistribution(months);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodMonths.find((opt) => opt.value === months);

    return [{
      name: 'months',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodMonths
    }];
  }, [months]);

  useEffect(() => {
    if (creditDistribution) {
      setCredits(creditDistribution);
    }
  }, [creditDistribution]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'creditDistribution': {
        ...current?.['creditDistribution'],
        filter: {
          ...current?.['creditDistribution']?.filter,
          months: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'creditDistribution': {
        ...current?.['creditDistribution'],
        visibility
      }
    }));
  };

  return <StyledCreditDistributionGraphCard ref={ref} {...innerProps}
                                            title="Credit distribution"
                                            context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                           fields={fields} /> : null)}>
    {(credits?.length > 0 || isLoading) ?
      <CreditDistributionPieChart credits={credits}
                                  showTotal={true}
                                  showLegend={true}
                                  showActiveShape={true}
                                  isLoading={isLoading}
                                  PieChartProps={{
                                    square: false
                                  }}
                                  visibility={graphState.graphSettings['creditDistribution']?.visibility}
                                  onVisibilityChange={handleVisibilityChange} /> : null}
    {(credits?.length === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No credit data found</P>
      </Box> : null}
  </StyledCreditDistributionGraphCard>
});

CreditDistributionGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CreditDistributionGraphCard.defaultProps = {
};

export default CreditDistributionGraphCard;
