import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledActionListItem from 'components/molecules/Lists/ActionListItem/ActionListItem.styles';
import Collapse from '@mui/material/Collapse';
import {useActionItem} from 'helpers/hooks/action';
import ListItemButton from 'components/atoms/Buttons/ListItemButton/ListItemButton';
import ListItemIcon from 'components/atoms/Icons/ListItemIcon/ListItemIcon';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ListItemText from 'components/atoms/Text/ListItemText/ListItemText';
import ActionList from 'components/molecules/Lists/ActionList/ActionList';
import utils from 'helpers/utils';
import Chip from 'components/atoms/Chips/Chip/Chip';

const ActionListItem = React.forwardRef((props, ref) => {
  const {
    action,
    depth,
    open,
    nowrap,
    onToggle,
    ListItemButtonProps,
    ...innerProps
  } = useComponentProps(props, 'ActionListItem');

  const itm = useActionItem(action);

  const childRef = useRef(null);
  const [internalState, setInternalState] = useState({
    open: false // initialise the internal state
  });

  // memoized function for the timeout variable
  const toggleSubMenu = (open, immediate = false) => () => {
    onToggle?.(open, immediate);
    setInternalState(utils.updater({open}, true));
  };

  const renderButton = () => {
    const handleClick = (e) => {
      itm.onClick?.(e);
      if (!e.defaultPrevented) {
        if (itm.children?.length > 0) {
          toggleSubMenu(!internalState.open, true)();
        }
      }
    }

    let ButtonProps = utils.mergeObjects({
      onClick: handleClick,
      selected: itm.active,
    }, ListItemButtonProps);

    const icon = <ListItemIcon>
      <Icon icon={itm.icon}/>
    </ListItemIcon>

    const chip = <Chip label={itm.chip}
                       size="small"
                       className="ActionListItem-chip"
                       color="primary"
                       variant="transparent" />

    return <ListItemButton {...ButtonProps}>
      {itm.icon && (!itm.iconPosition || itm.iconPosition === 'start') ? icon : null}
      {itm.chip && (!itm.chipPosition || itm.chipPosition === 'start') ? chip : null}
      <ListItemText primary={itm.label ?? itm.title} secondary={itm.subtitle} nowrap={nowrap}/>
      {itm.chip && itm.chipPosition === 'end' ? chip : null}
      {itm.children?.length > 0 ? (<Icon icon={internalState.open ? 'expand_less' : 'expand_more'} />) : null}
      {(itm.icon && itm.iconPosition === 'end') ? icon : null}
    </ListItemButton>
  }

  if (!itm) {
    return <React.Fragment/>;
  } else {
    return <StyledActionListItem ref={ref} {...innerProps} disablePadding
                                 onMouseEnter={toggleSubMenu(itm.children?.length > 0)}
                                 onMouseLeave={itm.children?.length > 0 ? toggleSubMenu(false) : null}>
      {renderButton()}
      {itm.children?.length > 0 ? (
        <Collapse in={internalState.open} timeout="auto" unmountOnExit>
          <ActionList depth={depth + 1}
                      ref={childRef}
                      actions={itm.children}
                      nowrap={nowrap}/>
        </Collapse>) : null}
    </StyledActionListItem>
  }
});

ActionListItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  depth: PropTypes.number,
  open: PropTypes.bool,
  nowrap: PropTypes.bool,
  onToggle: PropTypes.func,
  ListItemButtonProps: PropTypes.object
};

ActionListItem.defaultProps = {
};

export default ActionListItem;
