import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledStepLabel from 'components/atoms/Steppers/StepLabel/StepLabel.styles';
import {withMemo} from 'helpers/wrapper';

const StepLabel = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'StepLabel');

  return <StyledStepLabel ref={ref} {...innerProps} />
}));

StepLabel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

StepLabel.defaultProps = {};

export default StepLabel;
