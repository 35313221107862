import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatePicker from 'components/atoms/Inputs/DatePicker/DatePicker.styles';
import {withMemo} from 'helpers/wrapper';

const DatePicker = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatePicker');

  return <StyledDatePicker ref={ref} {...innerProps}/>
}));

DatePicker.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatePicker.defaultProps = {
};

export default DatePicker;
