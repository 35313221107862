import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Skeleton from '@mui/material/Skeleton';
import {rgba} from 'polished';

const StyledSkeleton = styled(Skeleton)` 
  pointer-events: none !important;
  color: rgba(0, 0, 0, 0) !important;
  background-color: ${props => rgba(props.theme.property('palette.text.primary'), 0.11)} !important;
  
  * {
    color: rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  
  &:has(.Chip, .Button) {
    transform: translate(0, 0) scale(1, 0.8);
    .Chip, .Button {
      visibility: hidden;
    }
  }

  &:has(.IconButton) {
    transform: translate(0, 0%) scale(0.8, 0.8);
    transform-origin: 50% 50%;
    border-radius: ${props => props.theme.radius(1.5)} !important;
    .IconButton {
      visibility: hidden;
    }
  }

  &:has(.Icon, .Img):not(:has(.Avatar, .Button, .IconButton, .Chip)) {
    display: flex;
    align-self: center;
    transform: translate(0, 0) scale(0.9, 0.9);
    transform-origin: 50% 50%;
    border-radius: ${props => props.theme.radius(1.5)} !important;
    .Icon, .Img {
      margin: 0;
      visibility: hidden;
    }
  }
  
  ${ComponentStyles}
`;

StyledSkeleton.propTypes = {
  theme: PropTypes.object
};

StyledSkeleton.defaultProps = {
}

export default StyledSkeleton;
