import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledFormControlLabel = styled(FormControlLabel)`
  ${ComponentStyles}
`;

StyledFormControlLabel.propTypes = {
  theme: PropTypes.object
};

StyledFormControlLabel.defaultProps = {
}

export default StyledFormControlLabel;
