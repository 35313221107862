import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledContextWrapper = styled(Box)`
  display: flex;
  min-width: 100%;
  min-height: 100%;
  position: relative;

  &.ContextWrapper {
    > .ContextWrapper {
      &-drawer {
        &.ContextDrawer-persistent {
          > .MuiPaper-root {
            transition: unset !important;
          }
        }
      }

      &-content {
        flex-grow: 1;
        
        .AppBar {
          z-index: ${props => props.theme.property('zIndex.context') - 1};
        }
      }
    }

    > .PrivateSwipeArea-root {
      width: 0 !important;
    }
  }
  
  &.ContextWrapper-animate {
    &:not(.ContextWrapper-foldout) {
      &.ContextWrapper-open {
        > .ContextWrapper {
          &-drawer {
            > .MuiPaper-root {
              transition: ${props => props.theme.transitions.create(['width'], {
                easing: props.theme.property('transitions.easing.easeIn'),
                duration: props.theme.property('transitions.duration.enteringScreen')
              })} !important;
            }
          }

          &-content {
            transition: ${props => props.theme.transitions.create(['width', 'margin'], {
              easing: props.theme.property('transitions.easing.easeIn'),
              duration: props.theme.property('transitions.duration.enteringScreen')
            })} !important;

            .ToolbarWrapper-toolbar, .ToolbarWrapper-content, .Page {
              transition: ${props => props.theme.transitions.create(['left', 'right', 'width'], {
                easing: props.theme.property('transitions.easing.easeIn'),
                duration: props.theme.property('transitions.duration.enteringScreen')
              })} !important;
            }
          }
        }
      }

      &:not(.ContextWrapper-open) {
        > .ContextWrapper {
          &-drawer {
            > .MuiPaper-root {
              transition: ${props => props.theme.transitions.create(['width'], {
                easing: props.theme.property('transitions.easing.sharp'),
                duration: props.theme.property('transitions.duration.leavingScreen')
              })} !important;
            }
          }

          &-content {
            transition: ${props => props.theme.transitions.create(['width', 'margin'], {
              easing: props.theme.property('transitions.easing.sharp'),
              duration: props.theme.property('transitions.duration.leavingScreen')
            })} !important;

            .ToolbarWrapper-toolbar, .ToolbarWrapper-content, .Page {
              transition: ${props => props.theme.transitions.create(['left', 'right', 'width'], {
                easing: props.theme.property('transitions.easing.sharp'),
                duration: props.theme.property('transitions.duration.leavingScreen')
              })} !important;
            }
          }
        }
      }
    }
  }
  
  &.ContextWrapper-temporary {
    > .PrivateSwipeArea-root {
      width: unset;
    }
  }
  
  ${ComponentStyles}
`;

StyledContextWrapper.propTypes = {
  theme: PropTypes.object
};

StyledContextWrapper.defaultProps = {}

export default StyledContextWrapper;
