import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFilesList from 'components/organisms/Lists/FilesList/FilesList.styles';
import FileCard from 'components/molecules/Cards/FileCard/FileCard';

const FilesList = React.forwardRef((props, ref) => {
  const {
    files,
    onClick,
    isLoading,
    FileCardProps,
    ...innerProps
  } = useComponentProps(props, 'FilesList');

  const renderCard = (file, state) => {
    const handleClick = () => {
      onClick?.(file);
    }

    return <FileCard file={file}
                     fullWidth={true}
                     selected={state.selected}
                     onClick={handleClick}
                     isLoading={state.isLoading}
                     {...FileCardProps} />
  };

  const isItemEqual = (file, selected) => {
    return file.uploadId === selected.uploadId;
  }

  return <StyledFilesList ref={ref} {...innerProps}
                          data={files}
                          count={files?.length}
                          dataKey="uploadId"
                          isItemEqual={isItemEqual}
                          renderItem={renderCard}
                          isLoading={isLoading}
                          showProgressBar={false}
                          onFetchMore={null}
                          ListProps={{
                            track: true,
                            virtualize: true,
                            gap: 8,
                          }}/>
});

FilesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  files: PropTypes.array,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  FileCardProps: PropTypes.object
};

FilesList.defaultProps = {
  loaderCount: 6
};

export default FilesList;
