import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import StyledFileUploadDialog from 'components/organisms/Dialogs/FileUploadDialog/FileUploadDialog.styles';

const FileUploadDialog = React.forwardRef((props, ref) => {
  const {
    onChange,
    onSubmit,
    onClose,
    FileFieldProps,
    ...innerProps
  } = useComponentProps(props, 'FileUploadDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const FileFieldPropsMemo = useEffectItem(FileFieldProps);
  const fields = useMemo(() => {
    return [{
      name: 'files',
      type: constants.formFieldTypes.file,
      validation: constants.formFieldValidationTypes.file,
      conversion: constants.formFieldConversionTypes.none,
      ...utils.mergeObjects({
        FormFieldProps: {
          variant: 'staticLabel',
          hiddenLabel: true,
          multiple: false
        }
      }, FileFieldPropsMemo)
    }]
  }, [FileFieldPropsMemo]);

  const handleChange = (field, value) => {
    onChange?.(value);
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['files'])
      .then(() => {
        onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Creating files failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              color="success"
              children={'Create files'}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledFileUploadDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Upload files" />
    <DialogContent>
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="FileUploadDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledFileUploadDialog>
});

FileUploadDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  FileFieldProps: PropTypes.object
};

FileUploadDialog.defaultProps = {};

export default FileUploadDialog;
