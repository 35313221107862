import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledActionChip from 'components/molecules/Chips/ActionChip/ActionChip.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ActionChip = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    onDelete,
    showInactive,
    showTooltip,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionChipProps}, 'ActionChip');

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);
  const handleDelete = useActionItemAction(itm?.onDelete, onDelete);

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    return <Tooltip title={showTooltip ? action.tooltip : null} showDisabled={true}
                    fullWidth={innerProps.fullWidth ?? action.ChipProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionChip ref={ref} {...innerProps}
                        icon={action.icon ? <Icon icon={action.icon}/> : null}
                        label={action.label}
                        color={action.color ?? innerProps.color ?? 'default'}
                        selected={itm?.active}
                        disabled={!itm}
                        badge={action.badge}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        showTooltip={!Boolean(action.tooltip) && showTooltip}
                        {...action.ChipProps}/>
    </Tooltip>
  }
});

ActionChip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  nowrap: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  showInactive: PropTypes.bool,
  showTooltip: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionChip.defaultProps = {
  label: 'ActionChip text',
  showTooltip: true
};

export default ActionChip;
