import {useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useSystemSynchroniseGet ({syncTime, ...rest}, queryOptions) {
  return useServiceApiQuery(path, 'get', {syncTime, ...rest}, {
    overridePath: 'system/synchronise',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
