import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfileBar from 'components/organisms/Bars/EntityProfileBar/EntityProfileBar';

const StyledDealflowEntityProfileBar = styled(EntityProfileBar)`
  ${ComponentStyles}
`;

StyledDealflowEntityProfileBar.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntityProfileBar.defaultProps = {
};

export default StyledDealflowEntityProfileBar;
