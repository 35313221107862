import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types';
import {ThemeProvider as ThemeProviderBase} from '@mui/material';
import themeDark from 'theme/dark/theme';
import themeLight from 'theme/light/theme';
import utils from 'helpers/utils';
import system from 'helpers/system';
import {themeFactory} from 'theme/base/base';

export const ThemeContext = React.createContext(null);

export function useTheme () {
  return React.useContext(ThemeContext)?.theme;
}

export function useThemes () {
  return React.useContext(ThemeContext)?.themes;
}

export function useStyles () {
  return React.useContext(ThemeContext)?.styles;
}

export function useThemeControl () {
  return React.useContext(ThemeContext)?.control;
}

const ThemeProvider = (props) => {
  const parentThemes = useThemes();
  const parentStyles = useStyles();
  const parentThemeControl = useThemeControl();
  const isChild = Boolean(parentThemeControl);

  const [name, setName] = useState(props.name);
  const [themes, setThemes] = useState(props.themes ?? [themeLight, themeDark]);

  const [styles, setStyles] = useState({
    fontBase: 16 // html font base of the browser
  });

  const theme = themes.find((t) => t.name === name) ?? themes.find((t) => t.name === 'light');
  const themeMemo = useMemo(() => {
    if (theme && props.overrides) {
      return themeFactory(utils.mergeObjects(theme.overrides, props.overrides, true));
    } else {
      return theme;
    }
  }, [theme, props.overrides])

  const context = useMemo(() => {
    return {
      theme: themeMemo,
      themes,
      styles,
      control: {
        setName: setName,
        setThemes: setThemes,
        addThemes: (themes) => {
          setThemes((current) => {
            let copy = [...current];
            utils.toArray(themes).forEach((t) => {
              const foundIndex = copy?.findIndex((th) => th.name === t.name);
              if (foundIndex !== -1) {
                copy[foundIndex] = t;
              } else {
                copy.push(t);
              }
            });

            return copy;
          })
        },
        resetThemes: () => {
          setThemes(props.themes ?? [themeLight, themeDark]);
        },
        toggle: () => {
          if (themes?.length > 0) {
            const idx = (themes.findIndex((t) => !name || t.name === name) + 1) % themes.length;
            setName(themes[idx].name);
          }
        }
      }
    }
  }, [themes, themeMemo, name, styles, props.themes]);

  useLayoutEffect(() => {
    if (props.name) {
      setName(props.name);
    }
  }, [props.name]);

  useLayoutEffect(() => {
    if (props.themes ?? parentThemes) {
      setThemes(props.themes ?? parentThemes);
    }
  }, [parentThemes, props.themes]);

  useLayoutEffect(() => {
    if (parentStyles) {
      setStyles(parentStyles);
    }
  }, [parentStyles]);

  useLayoutEffect(() => {
    if (!isChild && !system.isProduction()) {
      window.theme = themeMemo;
    }
  }, [themeMemo, name, isChild]);

  useEffect(() => {
    if (!isChild) {
      return utils.observeInterval(() => {
        setStyles(utils.updater({fontBase: utils.toNumber(getComputedStyle(document.documentElement).fontSize)}, true));
      }, 5000);
    }
  }, [isChild]);

  return <ThemeContext.Provider value={context}>
    <ThemeProviderBase theme={themeMemo}>
      {props.children}
    </ThemeProviderBase>
  </ThemeContext.Provider>
};

ThemeProvider.propTypes = {
  name: PropTypes.string,
  themes: PropTypes.array,
  overrides: PropTypes.object
}

ThemeProvider.defaultProps = {
};

export default ThemeProvider;
