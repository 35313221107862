import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledImg = styled.img`
  // sizes
  &.Img-size-mini {
    height: ${props => props.theme.layout(8)} !important;
  }
  &.Img-size-tiny {
    height: ${props => props.theme.layout(12)} !important;
  }
  &.Img-size-smallest {
    height: ${props => props.theme.layout(14)} !important;
  }
  &.Img-size-smaller {
    height: ${props => props.theme.layout(16)} !important;
  }
  &.Img-size-small {
    height: ${props => props.theme.layout(20)} !important;
  }
  &.Img-size-medium {
    height: ${props => props.theme.layout(24)} !important;
  }
  &.Img-size-large {
    height: ${props => props.theme.layout(35)} !important;
  }
  &.Img-size-larger {
    height: ${props => props.theme.layout(48)} !important;
  }
  
  ${ComponentStyles}
`;

StyledImg.propTypes = {
  theme: PropTypes.object
};

StyledImg.defaultProps = {
}

export default StyledImg;
