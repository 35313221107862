import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import StepContent from '@mui/material/StepContent';

const StyledStepContent = styled(StepContent)` 
  ${ComponentStyles}
`;

StyledStepContent.propTypes = {
  theme: PropTypes.object
};

StyledStepContent.defaultProps = {}

export default StyledStepContent;
