import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {StepIcon} from '@mui/material';

const StyledStepIcon = styled(StepIcon)`
  &.StepIcon-hideNumber {
    .MuiStepIcon-text {
      display: none
    }
  }
  
  ${ComponentStyles}
`;

StyledStepIcon.propTypes = {
  theme: PropTypes.object
};

StyledStepIcon.defaultProps = {}

export default StyledStepIcon;
