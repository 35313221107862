import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {BarChart} from 'recharts';
import ChartStyles from 'styles/mixins/Chart.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const StyledBarChart = styled(BarChart)`
  display: flex;

  &.BarChart {
    &-isLoading {
      .BarChart-bar {
        fill-opacity: 0.2;
        pointer-events: none;
      }
    }
    
    .BarChart-bar {
      &.hover {
        stroke: ${props => props.theme.property('palette.background.paper')};
        filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.4));
      }
      &.notHover {
        fill-opacity: 0.3;
      }
    }
  }
  
  .recharts-tooltip-cursor {
    fill: ${props => props.theme.state2Color('primary', 'focusVisible',
            constants.colorTypes.accentBackground, constants.colorTargets.background, 0.5)};
  }
  
  .recharts-label-list {
    pointer-events: none;
  }
  
  .recharts-cartesian {
    &-axis {
      &-tick-value {
        ${props => props.theme.font2Css('caption')};
        fill: ${props => props.theme.property('palette.text.primary')};
      }

      &-line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
    
    &-grid {
      line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
  }

  .recharts-label-list {
    .recharts-text {
      fill: ${props => props.theme.property('palette.white.main')}; 
    }
  }
  
  &.BarChart-hideAxis {
    ${props => props.$radius > 0 ? css`
      clip-path: inset(10% 0 round ${props.theme.layout(props.$radius)});
    ` : css``}

    .BarChart-bar {
      &.hover {
        stroke: none;
        filter: unset;
      }
    }
  }
  
  ${ChartStyles}
  ${ComponentStyles}
`;

StyledBarChart.propTypes = {
};

StyledBarChart.defaultProps = {
}

export default StyledBarChart;
