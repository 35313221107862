import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import StyledCollectionVisibilityTableCell
  from 'components/molecules/TableCells/CollectionVisibilityTableCell/CollectionVisibilityTableCell.styles';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

const CollectionVisibilityTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    visibility,
    disabled,
    onClick,
    size,
    ...innerProps
  } = useComponentProps(props, 'CollectionVisibilityTableCell');

  const onClickEvent = useEffectEvent(onClick);
  const ActionChipProps = useMemo(() => {
    const visibilityDef = constants.data.lookup('collectionVisibility', visibility);
    return {
      size: size,
      variant: 'outlined',
      showInactive: true,
      showTooltip: false,
      radius: size === 'large' ? 'round' : null,
      action: {
        auth: disabled ? utils.createAuth({attribute: 'system.null'}) : null,
        label: visibilityDef?.label,
        icon: visibilityDef?.icon,
        color: visibilityDef?.color,
        active: active,
        onClick: onClickEvent ? (e) => {
          onClickEvent?.(e);
          e.preventDefault();
        } : null
      }
    };
  }, [visibility, disabled, onClickEvent, active, size]);

  return <StyledCollectionVisibilityTableCell ref={ref} {...innerProps}
                                              ActionChipProps={ActionChipProps}/>
}));

CollectionVisibilityTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  visibility: PropTypes.string
};

CollectionVisibilityTableCell.defaultProps = {
  size: 'large'
};

export default CollectionVisibilityTableCell;


