import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDealflowEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesColumnsWrapper/DealflowEntitiesColumnsWrapper.styles';
import DealflowEntitiesTableColumns
  from 'components/organisms/TableColumns/DealflowEntitiesTableColumns/DealflowEntitiesTableColumns';
import {useSplitPath} from 'helpers/hooks/links';

const DealflowEntitiesColumnsWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesColumnsWrapper');

  const tableProvider = useTable();
  const [, pathPostfix] = useSplitPath('dealflow');

  return <StyledDealflowEntitiesColumnsWrapper ref={ref} {...innerProps}
                                               variant="foldout"
                                               context={<DealflowEntitiesTableColumns />}
                                               open={Boolean(pathPostfix && tableProvider.state?.showColumns)}
                                               size="huge"
                                               ContextDrawerProps={{
                                                 onToggle: (e, open) => {
                                                   if (open) {
                                                     tableProvider.openColumns();
                                                   } else {
                                                     tableProvider.closeColumns();
                                                   }
                                                 }
                                               }}>
    {innerProps.children}
  </StyledDealflowEntitiesColumnsWrapper>
});

DealflowEntitiesColumnsWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesColumnsWrapper.defaultProps = {
  children: 'DealflowEntitiesColumnsWrapper text',
};

export default DealflowEntitiesColumnsWrapper;
