import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledEntitiesMatchWizardContent = styled(Box)`
  &.EntitiesMatchWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('2sp')};
    padding-bottom: 0 !important;
  }

  .EntitiesMatchWizardContent {
    &-subtitle {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('2sp')};
      
      .H6 {
        flex-grow: 1;
        color: ${props => props.theme.property('palette.text.secondary')};
      }
      .Form {
        min-width: ${props => props.theme.layout(340)};
        max-width: ${props => props.theme.layout(340)};
      }
    }
    &-table {
      .MuiTableRow-head {
        background-color: ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))};
        .TableHeader {
          padding: ${props => props.theme.layout('0.75sp')} ${props => props.theme.layout('1sp')};
          .TableHeaderText {
            line-height: unset;
          }
        }
      }

      .MuiTableContainer-root {
        padding-bottom: ${props => props.theme.spacing(2)};
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledEntitiesMatchWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesMatchWizardContent.defaultProps = {}

export default StyledEntitiesMatchWizardContent;
