import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToggleButton from '@mui/material/ToggleButton';

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none;
  
  &.ToggleButton-size {
    &-small {
      height: ${props => props.theme.layout(36)};
      min-height: ${props => props.theme.layout(36)};
      font-size: ${props => props.theme.fontSize(14)};
      padding: ${props => props.theme.layout(5)} ${props => props.theme.layout(11)} ${props => props.theme.layout(5)} ${props => props.theme.layout(11)};
    }
    &-medium {
      height: ${props => props.theme.layout(48)};
      min-height: ${props => props.theme.layout(48)};
      font-size: ${props => props.theme.fontSize(15)};
      padding: ${props => props.theme.layout('1.5sp')};
    }
    &-large {
      height: ${props => props.theme.layout(56)};
      min-height: ${props => props.theme.layout(56)};
      font-size: ${props => props.theme.fontSize('1f')};
      padding: ${props => props.theme.spacing(2)};
    }
  }

  &.ToggleButton-color {
    &-primary {
      &.Mui-selected {
        background-color: ${props => props.theme.property('palette.primary.main')};
        color: ${props => props.theme.property('palette.white.main')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledToggleButton.propTypes = {
};

StyledToggleButton.defaultProps = {
}

export default StyledToggleButton;
