import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledMenuDrawer from 'components/organisms/Drawers/MenuDrawer/MenuDrawer.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import ActionLogo from 'components/molecules/Images/ActionLogo/ActionLogo';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const MenuDrawer = React.forwardRef((props, ref) => {
  const {
    open,
    width,
    animate,
    logoAction,
    ActionLogoProps,
    switchAction,
    ActionAvatarProps,
    menuItems,
    ActionIconButtonProps,
    onToggle,
    ...innerProps
  } = useComponentProps(props, 'MenuDrawer', {
    static: ['open'], // modifiers that be filled by theme styles, see classnames
    variable: ['anchor'], // classname and style only filled when variant is given
    children: ['content', 'logo', 'buttons', 'buttonsMiddle', 'buttonsTop', 'buttonsBottom']
  }); // possible child modifiers for theme

  const innerRef = useRef(null);
  const topButtonsRef = useRef(null);
  const middleButtonsRef = useRef(null);
  const bottomButtonsRef = useRef(null);
  const [internalState, setInternalState] = useState({open: false});

  const toggleDrawer = (open) => {
    return function (e, reason) {
      const canToggle = innerProps.variant === 'temporary';

      if (canToggle) {
        onToggle?.(e, open, reason ?? (open ? 'mouseEnter' : 'mouseLeave'));
        setInternalState(utils.updater({open}, true));
      }
    }
  };

  const menuDrawer = useMemo(() => ({
    refs: {
      ref: innerRef,
      topButtonsRef,
      middleButtonsRef,
      bottomButtonsRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({open})
    }
  }), [internalState, open]);

  useImperativeHandle(ref, () => menuDrawer);

  const contentBbox = useBbox(() => {
    return menuDrawer.refs.ref.current?.querySelector?.('.MuiPaper-root');
  }, ['height']);
  const middleButtonsBbox = useBbox(() => menuDrawer.refs.middleButtonsRef?.current, ['top', 'height']);

  const topItems = menuItems.filter((m) => !m.anchor || m.anchor === 'top');
  const middleItems = menuItems.filter((m) => m.anchor === 'middle');
  const bottomItems = menuItems.filter((m) => m.anchor === 'bottom');

  innerProps.anchor = innerProps.anchor ?? 'left';
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledMenuDrawer ref={menuDrawer.refs.ref} {...innerProps}
                           open={menuDrawer.state.open}
                           onClose={toggleDrawer(false)}
                           onOpen={toggleDrawer(true)}
                           $width={width}>
    <Box role="presentation"
         className="MenuDrawer-content"
         onMouseEnter={toggleDrawer(true)}
         onMouseLeave={toggleDrawer(false)}
    >
      {logoAction ? <ActionLogo action={logoAction} className="MenuDrawer-logo" {...ActionLogoProps}/> : null}
      {switchAction ? <ActionAvatar action={switchAction}
                                    className="MenuDrawer-avatar"
                                    {...ActionAvatarProps} /> : null}
      {topItems.length > 0 ?
        <Box ref={menuDrawer.refs.topButtonsRef}
             className={`MenuDrawer-buttons MenuDrawer-buttons-top`}>
          {topItems.map((itm, idx) => <ActionIconButton key={idx} action={itm} {...ActionIconButtonProps} />)}
        </Box>: null}
      {middleItems.length > 0 ?
        <Box ref={menuDrawer.refs.middleButtonsRef}
             style={{
               paddingTop: middleButtonsBbox ?
                 `calc(${contentBbox?.height}px / 2 - ${innerProps.theme.layout('5sp')} * ${middleItems.length} / 2 - ${middleButtonsBbox.top}px)` : null
             }}
             className={`MenuDrawer-buttons MenuDrawer-buttons-middle`}>
          {middleItems.map((itm, idx) => <ActionIconButton key={idx} action={itm} {...ActionIconButtonProps} />)}
        </Box>: null}
      {bottomItems.length > 0 ?
        <Box ref={menuDrawer.refs.bottomButtonsRef}
             className={`MenuDrawer-buttons MenuDrawer-buttons-bottom`}>
          {bottomItems.map((itm, idx) => <ActionIconButton key={idx} action={itm} {...ActionIconButtonProps} />)}
        </Box>: null}
    </Box>
  </StyledMenuDrawer>
});

MenuDrawer.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  open: PropTypes.bool,
  width: PropTypes.string,
  logoAction: PropTypes.object,
  ActionLogoProps: PropTypes.object,
  switchAction: PropTypes.object,
  ActionAvatarProps: PropTypes.object,
  menuItems: PropTypes.array,
  ActionIconButton: PropTypes.object,
  onToggle: PropTypes.func
};

MenuDrawer.defaultProps = {
  children: 'Menu drawer text'
};

export default MenuDrawer;
