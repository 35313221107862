import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Table from 'components/organisms/Tables/Table/Table';

const StyledTeamMembersTable = styled(Table)`
  &.TeamMembersTable.Table {
    .MuiTableContainer-root {
      padding: 0 ${props => props.theme.spacing(2)};
    }
    
    tr {
      min-height: ${props => props.theme.layout(60)};
      max-height: ${props => props.theme.layout(60)};
    }
    tr:last-child {
      td {
        border-bottom: unset !important;
      }
    }

    td {
      padding: ${props => props.theme.layout(0)} ${props => props.theme.layout('1sp')};
    }

    th:first-child, td:first-child {
      padding-left: 0;
    }

    th:last-child, td:last-child {
      padding-right: 0;
    }

    tr:hover td {
      background-color: unset !important;
    }
  }

  ${ComponentStyles}
`;

StyledTeamMembersTable.propTypes = {
  theme: PropTypes.object
};

StyledTeamMembersTable.defaultProps = {}

export default StyledTeamMembersTable;
