import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useImageLoaded} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import StyledLogo from 'components/atoms/Logos/Logo/Logo.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const Logo = withMemo(React.forwardRef((props, ref) => {
  const {
    logo,
    altLogo,
    fallbackIcon,
    variant,
    outlined,
    density,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Logo', {
    static: ['outlined']
  });

  const logoLoaded = useImageLoaded(logo);

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.variant = isLoading ? 'circular' : innerProps.variant;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledLogo ref={ref} {...innerProps}>
    <Avatar variant={variant} src={(logoLoaded === 'error' && altLogo) ? altLogo : logo}>
      {fallbackIcon ? <Icon className="fallback" icon={fallbackIcon} /> : null}
    </Avatar>
  </StyledLogo>;
}));

Logo.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  logo: PropTypes.string,
  altLogo: PropTypes.string,
  fallbackIcon: PropTypes.any,
  density: PropTypes.string,
  outlined: PropTypes.bool
};

Logo.defaultProps = {
};

export default Logo;
