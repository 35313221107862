import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntityAddSectionPanelContent
  from 'components/organisms/SectionPanels/EntityAddSectionPanelContent/EntityAddSectionPanelContent.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import SectionPanelFooter from 'components/molecules/SectionPanels/SectionPanelFooter/SectionPanelFooter';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import {useEntitySearchGoogle} from 'services/entity/entity.hooks';
import {useLinkSuggestions} from 'services/entity/entity.utils';
import Add from '@mui/icons-material/Add';

const EntityAddSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    onNext,
    onClose,
    collection,
    ...innerProps
  } = useComponentProps(props, 'EntityAddSectionPanelContent', {
    children: ['form']
  });

  const formRef = useRef(null);
  const [name, setName] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [linkOptions, setLinkOptions] = useState({});

  const profileProvider = useProfile();
  const fieldData = profileProvider.fieldData;

  const searchGoogle = useEntitySearchGoogle({query: `"${name}"`}, {
    ...constants.queryOptions.runOnce,
    enabled: name.length > 0
  })
  const createEvent = useEffectEvent(profileProvider.updaters?.createData);
  const assignEvent = useEffectEvent(profileProvider.updaters?.assignItems);

  const linkSuggestions = useLinkSuggestions(searchGoogle.data?.data, name);

  const fields = useMemo(() => {
    const fields = [];
    fields.push({
      name: 'name',
      label: 'Company name',
      placeholder: 'Type company name',
      type: constants.formFieldTypes.suggestion,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      options: 'names',
      required: true,
      onBlur: (e) => {
        const name = e.target?.value?.value?.trim();
        if (!utils.isEmpty(name)) {
          setName(name);
        }
      },
      FormFieldProps: {
        variant: 'staticLabel',
        explanation: 'these companies are already in our database',
        freeSolo: true,
        hideEmpty: true,
        hideOpenClose: true,
        autoComplete: false,
        renderOption: (option) => {
          return <EntityCard entity={option.entity}
                             variant="suggestion"
                             radius="square"
                             fullWidth={true}
                             showLink={true}
                             showStatus={false}
                             showRelevancy={false}/>
        }
      }
    });

    constants.data.links.forEach((link) => {
      fields.push({
        name: link.value,
        label: link.label,
        link: link,
        entity: 'entity',
        context: {name},
        options: linkOptions[link.value] ?? [],
        FormFieldProps: {
          variant: 'staticLabel',
          isLoading: (name.length > 0 && searchGoogle.status.isLoading),
          hideEmpty: true,
          hideOpenClose: !searchGoogle.status.isSettled
        }
      })
    });

    return fields;
  }, [linkOptions, name, searchGoogle.status.isLoading, searchGoogle.status.isSettled]);

  const gotoEntity = (e, entity) => {
    setRedirect(entity.name);
    onClose?.(null, 'closeButtonClick');
    onNext?.(entity, false);
  }

  useEffect(() => {
    setLinkOptions(linkSuggestions ?? {});
  }, [linkSuggestions]);

  const handleChangeDirect = (e) => {
    if (e?.target.name === 'name') {
      if (e?.target?.value?.entity) {
        const entity = e?.target?.value?.entity;
        gotoEntity(e, entity);
      }
    }
  }

  const handleSubmit = (values, actions) => {
    profileProvider.submit();

    createEvent?.(values)
      .then((res) => {
        const entity = utils.camelcase(res.response.data.data);
        if (!collection) {
          profileProvider.success('Saved');
          onNext?.(entity, true);
        } else {
          return assignEvent?.({
            collectionId: collection.collectionId,
            entityId: entity.entityId
          }).then(() => {
            profileProvider.success('Saved');
            onNext?.(entity, true);
          })
        }
      })
      .catch(() => {
        profileProvider.error('Saving company failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    profileProvider.dirty(isDirty);
    if (hasErrors) {
      profileProvider.validation('Please check if all fields have the correct values');
    } else {
      profileProvider.validation(null);
    }
  }

  const buttonActions = useMemo(() => {
    const actions = [];
    actions.push({
      auth: utils.createAuth({attribute: 'entity.create'}),
      icon: Add,
      label: 'Create company',
      onClick: () => {
        formRef.current?.submit();
      },
      ActionButtonProps: {
        type: 'submit',
        color: 'success'
      },
      ButtonProps: {
        disabled: profileProvider.state.isSubmitting
      }
    });

    return actions;
  }, [profileProvider.state.isSubmitting]);

  const renderFooter = () => {
    const err = profileProvider.state.submitError ?? profileProvider.state.validation;
    if (buttonActions?.length > 0 || err) {
      return <SectionPanelFooter className="EntityAddSectionPanelContent-footer"
                                 variant="split"
                                 buttons={buttonActions.map?.((action, idx) => {
                                   return <ActionButton key={idx} action={action} />
                                 })}
                                 info={err ? <Span color="error">{err}</Span> : null}/>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityAddSectionPanelContent ref={ref} {...innerProps}
                                             footer={renderFooter()}>
    {!redirect ? <InlineForm ref={formRef}
                             className="EntityAddSectionPanelContent-form"
                             onChangeDirect={handleChangeDirect}
                             onSubmit={handleSubmit}
                             onValidating={handleValidating}
                             fields={fields}
                             fieldData={fieldData}/> :
      <Box className="EntityAddSectionPanelContent-redirect">
        <CircularProgress />
        <H6>Redirecting to company</H6>
      </Box>}
  </StyledEntityAddSectionPanelContent>
});

EntityAddSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object
};

EntityAddSectionPanelContent.defaultProps = {};

export default EntityAddSectionPanelContent;
