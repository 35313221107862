import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import AutocompleteField from 'components/molecules/Fields/AutocompleteField/AutocompleteField';

const StyledSuggestionField = styled(AutocompleteField)`
  .SuggestionField {
    &-adornment {
      padding-top: ${props => props.theme.layout(3)};
      padding-bottom: ${props => props.theme.layout(3)};

      border-radius: ${props => props.theme.radius(1)};
      margin: ${props => props.theme.layout(-2)} 0 ${props => props.theme.layout(-2)} 0;
    }
  }
  
  &.AutocompleteField-freeSolo {
    .SuggestionField {
      &-adornment {
        margin-right: ${props => props.theme.layout(-1)};
      }
    }
  }
  
  &.SuggestionField-size-smaller {
    &.AutocompleteField-freeSolo {
      .SuggestionField {
        &-adornment {
          margin-right: ${props => props.theme.layout(0)};
        }
      }
    }

    .SuggestionField {
      &-adornment {
        min-width: unset;
        padding-left: ${props => props.theme.layout(12)};
        padding-right: ${props => props.theme.layout(8)};
        
        .MuiButton-endIcon {
          margin-left: ${props => props.theme.layout(2)};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSuggestionField.propTypes = {
  theme: PropTypes.object
};

StyledSuggestionField.defaultProps = {
}

export default StyledSuggestionField;
