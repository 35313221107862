import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledInputAdornment = styled(InputAdornment)`
  ${ComponentStyles}
`;

StyledInputAdornment.propTypes = {
  theme: PropTypes.object
};

StyledInputAdornment.defaultProps = {
}

export default StyledInputAdornment;
