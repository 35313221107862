import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class EntityUploadService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'uploadId',
      refreshEnabled: false,
      invalidateParent: true, // force invalidate entity when uploads change
      invalidateParentWithPriority: true,
      overrideListPath: 'uploads?entityId=:entityId',
      overrideEntityPath: 'uploads/:uploadId?entityId=:entityId',
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            data = data.map(utils.camelcase);
            data.forEach((f) => f.uploadId = f.fileUploadId);
          } else {
            data = utils.camelcase(data);
            data.uploadId = data.fileUploadId;
          }
        } else {
          return data;
        }

        return data;
      }
    }, callbacks);
  }
}
