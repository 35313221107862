import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Tooltip from '@mui/material/Tooltip';

const StyledTooltip = styled(({className, children, ...props}) => (
  <Tooltip classes={{ tooltip: className }} {...props}>{children}</Tooltip>
))`
  font-size: ${props => props.theme.fontSize(11)};
  
  // variant
  &.Tooltip {
    &-light {
      color: ${props => props.theme.property('palette.text.primary')};
      background-color: ${props => props.theme.property('palette.background.default')};
      box-shadow: ${props => props.theme.property('shadows.2')};

      .MuiTooltip-arrow {
        color: ${props => props.theme.property('palette.background.default')};
        
        &:before {
          box-shadow: ${props => props.theme.property('shadows.1')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTooltip.propTypes = {
  theme: PropTypes.object
};

StyledTooltip.defaultProps = {
}

export default StyledTooltip;
