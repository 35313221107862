import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Cards from 'components/atoms/Cards/Cards/Cards';

const StyledKanban = styled(Cards)`
  .Cards-column {
    width: 100%;
    .CardItem > * {
      height: unset;
    }
  }
  
  ${ComponentStyles}
`;

StyledKanban.propTypes = {
  theme: PropTypes.object
};

StyledKanban.defaultProps = {
};

export default StyledKanban;
