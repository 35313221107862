import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCircularProgress = styled(Box)` 
  position: relative;
  display: inline-flex;

  .CircularProgress-label {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  ${ComponentStyles}
`;

StyledCircularProgress.propTypes = {
  theme: PropTypes.object
};

StyledCircularProgress.defaultProps = {
}

export default StyledCircularProgress;
