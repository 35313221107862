import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledTeamAddDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: clamp(0px, ${props => props.theme.layout(440)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(440)}, 86vw);
  }
  
  .InlineForm {
    .FormControl {
      &.FormField-name-isPrivate {
        row-gap: ${props => props.theme.layout(0)};
        .List {
          width: fit-content;
        }
      }
    }
  }

  ${FormStyles}
  ${ComponentStyles}
`;

StyledTeamAddDialog.propTypes = {
  theme: PropTypes.object
};

StyledTeamAddDialog.defaultProps = {}

export default StyledTeamAddDialog;
