import React, {useImperativeHandle, useMemo} from 'react'
import PropTypes from 'prop-types'
import Snackbar from 'components/organisms/Snackbars/Snackbar/Snackbar';
import {useSystem} from 'components/organisms/Providers/SystemProvider/SystemProvider';
import {useEffectEvent} from 'helpers/hooks/utils';
import {SnackbarProvider as SnackbarProviderBase, useSnackbar as useSnackbarBase} from 'notistack';

export const SnackbarContext = React.createContext(null);

export function useSnackbar () {
  return React.useContext(SnackbarContext);
}

const SnackbarProviderContent = React.forwardRef((props, ref) => {
  const {
    SnackbarProps,
    children
  } = props;

  const systemInfo = useSystem();
  const snackbarBase = useSnackbarBase();

  // context
  const systemInfoEvent = useEffectEvent(systemInfo.info);
  const enqueueSnackbarEvent = useEffectEvent(snackbarBase.enqueueSnackbar);
  const closeSnackBarEvent = useEffectEvent(snackbarBase.closeSnackbar);
  const context = useMemo(() => ({
    show: (message, action = null, options = null, onAction = null, onClose = null, clickAway = true) => {
      if (systemInfoEvent?.().userIsActive) {
        let snackbarId;
        const actionClick = (e) => {
          onAction?.(e);

          if (snackbarId && !e.defaultPrevented) {
            closeSnackBarEvent?.(snackbarId);
          }
        }

        const closeClick = (e, reason) => {
          if (onClose){
            onClose(e, reason);
          } else {
            if (e && reason) {
              if (clickAway || reason !== 'clickaway') {
                if (snackbarId) {
                  closeSnackBarEvent?.(snackbarId);
                }
              }
            }
          }
        }

        snackbarId = enqueueSnackbarEvent?.(message, {
          action: action,
          onClose: closeClick,
          ButtonProps: {
            onClick: actionClick,
            ...SnackbarProps?.buttonProps
          },
          size: 'medium',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          ...SnackbarProps,
          ...options
        });

        return snackbarId;
      }
    },
    hide: (id) => {
      closeSnackBarEvent?.(id);
    }
  }), [systemInfoEvent, closeSnackBarEvent, enqueueSnackbarEvent, SnackbarProps]);

  useImperativeHandle(ref, () => context);

  return <SnackbarContext.Provider value={context}>
    {children}
  </SnackbarContext.Provider>
});

const SnackbarProvider = React.forwardRef((props, ref) => {
  const {
    SnackbarProps,
    children,
    ...rest
  } = props;

  return <SnackbarProviderBase {...rest}
                               Components={{
                                 default: Snackbar
                               }}>
    <SnackbarProviderContent ref={ref} SnackbarProps={SnackbarProps}>
      {children}
    </SnackbarProviderContent>
  </SnackbarProviderBase>
})

SnackbarProvider.propTypes = {
  SnackbarProps: PropTypes.object
}

SnackbarProvider.defaultProps = {
  dense: true,
  preventDuplicate: true
};

export default SnackbarProvider;
