import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListField from 'components/molecules/Fields/ListField/ListField';

const StyledCategoryField = styled(ListField)`
  ${ComponentStyles}
`;

StyledCategoryField.propTypes = {
  theme: PropTypes.object
};

StyledCategoryField.defaultProps = {
}

export default StyledCategoryField;
