import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionSourceProfileCardContent = styled(Box)`
  &.CollectionSourceProfileCardContent {
    display: flex;
    gap: ${props => props.theme.layout('1sp')};
    width: 100%;
    
    .CollectionSourceProfileCardContent {
      &-content {
        display: flex;
        flex-direction: row;
        column-gap: ${props => props.theme.layout('1sp')};
        width: 100%;

        .SourceCard {
          flex-grow: 1;
          width: 1px;
          
          &-suggested {
            width: unset;
          }
        }
        
        .CollectionSourcesSectionPanel {
          width: 100%;
          max-height: 60vh;
        }

        .FormControl {
          &.FormField-name-filterType {
            row-gap: ${props => props.theme.layout('1sp')};

            .FormLabel {
              align-self: center;
              margin-top: 0;  
            }
            
            .List {
              width: fit-content;
              .ListItem {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  &:not(.CollectionSourceProfileCardContent-isEditing) {
    &.CollectionSourceProfileCardContent-isAddNew {
      width: 100%;
      height: 100%;
      padding: 0;
      min-height: ${props => props.theme.layout(280)};
      max-height: ${props => props.theme.layout(280)};
      background-color: ${props => props.theme.property(`palette.primary.states.focus`)};

      .CollectionSourceProfileCardContent-content {
        width: 100%;
        height: 100%;

        .Card {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledCollectionSourceProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionSourceProfileCardContent.defaultProps = {}

export default StyledCollectionSourceProfileCardContent;
