import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import constants from 'helpers/constants';
import {MaterialDesignContent} from 'notistack';

const StyledSnackbar = styled(MaterialDesignContent)`
  ${props => props.theme.color2Css('Snackbar', null, props.$color, {type: constants.colorTypes.paper})}
  
  &.Snackbar-size-small {
    max-width: ${props => props.theme.layout(200)};
  }
  &.Snackbar-size-medium {
    max-width: ${props => props.theme.layout(400)};
  }
  &.Snackbar-size-large {
    max-width: ${props => props.theme.layout(600)};
  }
  
  ${props => props.anchorOrigin?.horizontal === 'left' ? css`
    left: calc(${props => props.theme.layout('3sp')} + ${props => props.$wrapper?.boundsCum?.left ?? 0});
  ` : css``}
  ${props => props.anchorOrigin?.horizontal === 'right' ? css`
    right: calc(${props => props.theme.layout('3sp')} + ${props => props.$wrapper?.boundsCum?.right ?? 0});
  ` : css``}
  ${props => props.anchorOrigin?.vertical === 'top' ? css`
    top: calc(${props => props.theme.layout('3sp')} + ${props => props.$wrapper?.boundsCum?.top ?? 0});
  ` : css``}
  ${props => props.anchorOrigin?.vertical === 'bottom' ? css`
    bottom: calc(${props => props.theme.layout('3sp')} + ${props => props.$wrapper?.boundsCum?.bottom ?? 0});
  ` : css``}
  
  ${ComponentStyles}
`;

StyledSnackbar.propTypes = {
};

export default StyledSnackbar;
