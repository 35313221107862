import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityProfile
  from 'components/organisms/Profiles/CollectionEntityProfile/CollectionEntityProfile.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const CollectionEntityProfile = (props) => {
  const innerProps = useComponentProps(props, 'CollectionEntityProfile');

  const profileProvider = useProfile();
  const collection = profileProvider.context?.data;

  const authorize = useAuthorize();

  const handleCanUpdate = useCallback((entity, field = null) => {
    return authorize({attribute: 'collection.entity.update', meta: {collection, entity}}) &&
      (!field || authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, collection, entity}}));
  }, [collection, authorize]);

  return <StyledCollectionEntityProfile {...innerProps}
                                        onCanUpdate={handleCanUpdate}/>
};

CollectionEntityProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityProfile.defaultProps = {
};

export default CollectionEntityProfile;
