import {useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useCollectionQuestionnaireList ({collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list',
    {collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useCollectionQuestionnaireGet ({collectionId, questionnaireId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {collectionId, questionnaireId}, queryOptions);
}

export function useCollectionQuestionnaireStatsList ({collectionId, questionnaireIds, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'stats', {collectionId, questionnaireIds, ...rest}, {
    overridePath: 'collections/:collectionId/questionnaires/:questionnaireId/stats',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

