import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledSourceDatabaseSectionPanelContent = styled(ListWrapper)` 
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${props => props.theme.layout('1sp')};
  padding: ${props => props.theme.spacing(2)};
  
  ${ComponentStyles}
`;

StyledSourceDatabaseSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledSourceDatabaseSectionPanelContent.defaultProps = {}

export default StyledSourceDatabaseSectionPanelContent;
