import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {defaultDropAnimationSideEffects, DragOverlay} from '@dnd-kit/core';
import {OverLayContext} from 'components/organisms/Utils/DragDrop/DndContext';

const SortableOverlay = React.forwardRef((props, ref) => {
  const dropAnimationConfig = useMemo(() => ({
    sideEffects: defaultDropAnimationSideEffects({
      styles: {}
    })
  }), []);

  return <OverLayContext.Provider value={true}>
    <DragOverlay ref={ref} {...props}
                 dropAnimation={props.animate ? dropAnimationConfig : null} />
  </OverLayContext.Provider>
});

SortableOverlay.propTypes = {
  animate: PropTypes.bool
};

SortableOverlay.defaultProps = {
  animate: true
};

export default SortableOverlay;
