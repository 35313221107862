import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesList
  from 'components/organisms/Lists/CollectionEntitiesList/CollectionEntitiesList.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useEntityCsi, useEntityRelevancy} from 'services/entity/entity.utils';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';
import {useCollectionEntityScore} from 'services/collection/entity/entity.utils';

const CollectionEntitiesList = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesList');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;

  const Card = ({entity, ...props}) => {
    const csi = useEntityCsi(entity);
    const relevancy = useEntityRelevancy(entity, collection);
    const score = useCollectionEntityScore(collection, entity);

    return <EntityCard entity={entity}
                       csi={csi}
                       relevancy={relevancy}
                       score={score}
                       {...props}/>
  };

  return <StyledCollectionEntitiesList ref={ref} {...innerProps}
                                       Card={Card} />
});

CollectionEntitiesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesList.defaultProps = {
};

export default CollectionEntitiesList;
