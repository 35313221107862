import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import dom from 'helpers/dom';
import StyledDropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper.styles';
import ContextPopper from 'components/molecules/Poppers/ContextPopper/ContextPopper';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const DropdownPopper = React.forwardRef((props, ref) => {
  const {
    open,
    autoClose,
    onClosed,
    anchorEl,
    onToggle,
    autoFocus,
    density,
    onMouseEnter,
    onMouseLeave,
    ContextPopperProps,
    ...innerProps
  } = useComponentProps(props, 'DropdownPopper');

  const innerRef = useRef(null);
  const popperRef = useRef(null);

  const dropdownPopper = useMemo(() => ({
    refs: {
      ref: innerRef,
      popperRef
    },
    open: (...args) => {
      popperRef?.current?.close?.(...args);
    },
    close: (...args) => {
      popperRef?.current?.close?.(...args);
    }
  }), []);

  useImperativeHandle(ref, () => dropdownPopper);

  const onToggleEvent = useEffectEvent(onToggle);
  const doClose = useCallback((e) => {
    // special case select
    const isSelect = e.currentTarget === document &&
      dom.isPartOfParent(e.currentTarget?.activeElement, innerRef.current);

    if (!isSelect) {
      onToggleEvent?.(false);
    }
  }, [onToggleEvent]);

  const handleClose = (e, reason) => {
    if (autoClose || reason === 'closeButtonClick') {
      doClose(e);
    }
  }

  useEffect(() => {
    if (autoFocus && open) {
      const focus = () => {
        return dom.focusElement(innerRef.current);
      }

      utils.retry(focus, 3, constants.delay.minimal);
    }
  }, [autoFocus, open]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      doClose(e);
      e.preventDefault();
    }
  }, [doClose]);

  useEffect(() => {
    return utils.observeEvent(window, 'keydown', handleKeyDown);
  }, [handleKeyDown]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <ContextPopper ref={popperRef}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        onClosed={onClosed}
                        onKeyDown={handleKeyDown}
                        open={open}
                        density={density}
                        offset={innerProps.offset ?? [0, 4]}
                        TransitionProps={{
                          timeout: 100
                        }}
                        PaperProps={{
                          variant: 'elevation',
                          onMouseEnter,
                          onMouseLeave
                        }}
                        {...ContextPopperProps}>
    <StyledDropdownPopper ref={innerRef} {...innerProps}>
      {innerProps.children}
    </StyledDropdownPopper>
  </ContextPopper>
});

DropdownPopper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  open: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoClose: PropTypes.bool,
  anchorEl: PropTypes.any,
  onToggle: PropTypes.func,
  onClosed: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  ContextPopperProps: PropTypes.object
};

DropdownPopper.defaultProps = {
  children: 'DropdownPopper text',
  autoClose: true,
  autoFocus: true
};

export default DropdownPopper;
