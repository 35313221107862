import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledEntitiesUploadWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0 !important;
    }
    &-step {
      &:has(.EntitiesMatchWizardContent, .EntitiesValidateWizardContent, .EntitiesFinalizeWizardContent) {
        height: 100% !important;
        overflow: hidden;
      }
      
      &-content {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      }
      
      .EntitiesUploadWizardContent {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      }
      
    }
    .ToolbarWrapper-toolbar {
      padding-right: 0;
    }
  }
  
  &.EntitiesUploadWizard {
    .ToolbarWrapper-toolbar {
      padding-right: 0 !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesUploadWizard.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesUploadWizard.defaultProps = {}

export default StyledEntitiesUploadWizard;
