import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {darken} from 'polished';

const StyledLegend = styled(Box)`
  display: flex;
  justify-content: center;

  &.Legend {
    &-isLoading {
      .Legend-list {
        pointer-events: none;
        .Icon {
          opacity: 0.2;
        }
      }
    }
    
    &-clickable {
      .Legend-list {
        .ListItem:hover {
          cursor: pointer;

          .Legend-item-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  .Legend {
    &-list {
      width: unset;
      padding: 0;

      ${props => utils.isDefined(props.$rows) ? css`
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(${props.$rows}, 1fr);
        gap: ${props.theme.spacing(1)} ${props.theme.spacing(3)};
      ` : css`
        display: flex;
        justify-content: space-between;
        gap: ${props.theme.spacing(1)};
        flex-wrap: wrap;
      `}

      .ListItem {
        width: 100%;
        overflow: hidden;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${props => props.theme.layout('0.75sp')};

        &:has(.Legend-item-explanation) {
          align-items: flex-start;
          > .Icon, > .Avatar {
            margin-top: ${props => props.theme.spacing(1/8)};
          }
          .Legend-item-title {
            font-weight: 500;
          }
        }
        
        .Legend-item {
          &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;
          }
          
          &-title {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            
            .Span {
              ${props => props.theme.font2Css('caption')};
              max-width: ${props => props.theme.layout(100)};
              text-overflow: ellipsis;
              overflow: hidden;
            }
            
            .Badge {
              margin-top: ${props => props.theme.layout(-1)};
              margin-left: ${props => props.theme.layout('0.5sp')};
            }
          }
          
          &-explanation {
            ${props => props.theme.font2Css('caption')};
          }
        }

        > .Icon {
          &.defaultColor {
            color: ${props => darken(0.5, props.theme.property('palette.divider'))};
          }
        }
        > .Avatar {
          margin-right: ${props => props.theme.spacing(1)};
          &.defaultColor {
            color: ${props => props.theme.palette.getContrastText(darken(0.5, props.theme.property('palette.divider')))};
            background-color: ${props => darken(0.5, props.theme.property('palette.divider'))};
          }
        }
        &:not(.active) {
          > .Icon {
            color: ${props => props.theme.property('palette.divider')};
          }
          > .Avatar {
            color: ${props => props.theme.property('palette.text.disabled')};
            background-color: ${props => props.theme.property('palette.divider')};
          }
        }
      }
    }
  }

  &.Legend-orientation-vertical {
    justify-content: flex-start;
    .Legend {
      &-list {
        row-gap: ${props => props.theme.layout('2sp')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledLegend.propTypes = {
  theme: PropTypes.object
};

StyledLegend.defaultProps = {
}

export default StyledLegend;
