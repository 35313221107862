import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledImageListItemBar from 'components/atoms/Lists/ImageListItemBar/ImageListItemBar.styles';

const ImageListItemBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ImageListItemBar');

  return <StyledImageListItemBar ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledImageListItemBar>
});

ImageListItemBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ImageListItemBar.defaultProps = {
  children: 'ImageListItemBar text'
};

export default ImageListItemBar;
