import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import AvatarGroup from '@mui/material/AvatarGroup';
import constants from 'helpers/constants';

const StyledAvatarGroup = styled(AvatarGroup)`
  .MuiAvatarGroup-avatar:not(.Avatar) {
    ${props => props.theme.color2Css('MuiAvatarGroup-avatar', null, props.$color, {type: constants.colorTypes.button})}
  }
  
  &.AvatarGroup-size-larger {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(36 - 2)};
      height: ${props => props.theme.layout(36 - 2)};
      font-size: ${props => props.theme.fontSize(14)} !important;
      
      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(13)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-large {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(32 - 2)};
      height: ${props => props.theme.layout(32 - 2)};
      font-size: ${props => props.theme.fontSize(14)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(13)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-medium {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(26 - 2)};
      height: ${props => props.theme.layout(26 - 2)};
      font-size: ${props => props.theme.fontSize(13)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(12)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-small {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(24 - 2)};
      height: ${props => props.theme.layout(24 - 2)};
      font-size: ${props => props.theme.fontSize(12)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(10)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-smaller {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(22 - 2)};
      height: ${props => props.theme.layout(22 - 2)};
      font-size: ${props => props.theme.fontSize(11)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(9)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-smallest {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(20 - 2)};
      height: ${props => props.theme.layout(20 - 2)};
      font-size: ${props => props.theme.fontSize(9)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(7)} !important;
        }
      ` : css``}
    }
  }
  &.AvatarGroup-size-tiny {
    .Avatar, .MuiAvatarGroup-avatar {
      width: ${props => props.theme.layout(18 - 2)};
      height: ${props => props.theme.layout(18 - 2)};
      font-size: ${props => props.theme.fontSize(8)} !important;

      ${props => (props.$overflow > 9) ? css`
        &:not(.Avatar) {
          font-size: ${props => props.theme.fontSize(6)} !important;
        }
      ` : css``}
    }
  }
  
  ${ComponentStyles}
`;

StyledAvatarGroup.propTypes = {
  theme: PropTypes.object
};

StyledAvatarGroup.defaultProps = {}

export default StyledAvatarGroup;
