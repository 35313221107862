import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';

const StyledDealflowIcon = styled(Icon)`
  &[data-testid="DealflowIcon"] { 
    text {
      font-size: ${props => props.theme.fontSize(15)};
      color: ${props => props.theme.property('palette.background.paper')};
      text-anchor: start;
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowIcon.propTypes = {
  theme: PropTypes.object
};

StyledDealflowIcon.defaultProps = {
}

export default StyledDealflowIcon;
