import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useClientDealflowGroup, useClientDealflowStatus} from 'services/client/client.utils';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import StyledDealflowStatusText from 'components/molecules/Text/DealflowStatusText/DealflowStatusText.styles';
import utils from 'helpers/utils';

const DealflowStatusText = React.forwardRef((props, ref) => {
  const {
    groupId,
    statusId,
    colored,
    dealflowIcon,
    dealflowIconPosition,
    DealflowIconProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusText');

  const group = useClientDealflowGroup(groupId);
  const status = useClientDealflowStatus(statusId);
  const label = group ? group.label : status?.label;

  const color = colored ? (utils.deprecatedColor(status?.groupColor || status?.color) ?? 'dealflowDefault.darker') : null;

  return <StyledDealflowStatusText ref={ref} {...innerProps} color={color}>
    {dealflowIcon && dealflowIconPosition === 'start' ? <DealflowIcon groupId={groupId} statusId={statusId} {...DealflowIconProps} /> : null}
    {label ?? 'Status was removed'}
    {dealflowIcon && dealflowIconPosition === 'end' ? <DealflowIcon groupId={groupId} statusId={statusId} {...DealflowIconProps} /> : null}
  </StyledDealflowStatusText>
});

DealflowStatusText.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  groupId: PropTypes.number,
  statusId: PropTypes.number,
  colored: PropTypes.bool,
  iconPosition: PropTypes.string,
  dealflowIcon: PropTypes.bool,
  DealflowIconProps: PropTypes.object
};

DealflowStatusText.defaultProps = {
  colored: true,
  dealflowIconPosition: 'start'
};

export default DealflowStatusText;
