import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTasksBarChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('2sp')};
  width: 100%;
  height: 100%;
  overflow: hidden;

  .TasksBarChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;
      
      .recharts-yAxis {
        .recharts-cartesian-axis-tick-value {
          ${props => props.theme.font2Css('subtitle2')};
        }
      }

      .recharts-label-list {
        .recharts-text {
          ${props => props.theme.font2Css('subtitle2')};
        }
      }
    }

    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledTasksBarChart.propTypes = {
};

StyledTasksBarChart.defaultProps = {
}

export default StyledTasksBarChart;
