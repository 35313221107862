import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItemIcon from '@mui/material/ListItemIcon';

const StyledListItemIcon = styled(ListItemIcon)`
  ${ComponentStyles}
`;

StyledListItemIcon.propTypes = {
  theme: PropTypes.object
};

StyledListItemIcon.defaultProps = {
}

export default StyledListItemIcon;
