import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function usePortalCollectionStatus () {
  return useServiceStatus(path);
}

export function usePortalCollectionGet ({collectionId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {collectionId}, queryOptions);
}
