import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesTableBarWrapper/CollectionEntitiesTableBarWrapper.styles';
import CollectionEntitiesTableBar
  from 'components/organisms/Bars/CollectionEntitiesTableBar/CollectionEntitiesTableBar';

const CollectionEntitiesTableBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTableBarWrapper');

  return <StyledCollectionEntitiesTableBarWrapper ref={ref} {...innerProps}
                                                  variant="sticky">
    {innerProps.children}
  </StyledCollectionEntitiesTableBarWrapper>
});

CollectionEntitiesTableBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTableBarWrapper.defaultProps = {
  ToolbarComponent: CollectionEntitiesTableBar
};

export default CollectionEntitiesTableBarWrapper;
