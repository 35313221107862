import {useTheme} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import React, {memo} from 'react';
import utils from 'helpers/utils';
import logger from 'helpers/logger';
import system from 'helpers/system';

// doesn't work well with storybook
export function withTheme(Component) {
  return React.forwardRef((props, ref) => {
    const theme = useTheme();

    return <Component ref={ref} {...props} theme={theme}/>
  });
}

export function withMemo (Component, {verbose = false, deep = false, compare = null} = {}) {
  const shallowCom = (v1, v2) => {
    return v1 === v2;
  }

  const deepComp = (v1, v2) => {
    const cc1 = utils.cloneable(v1);
    const cc2 = utils.cloneable(v2);

    if (!cc1 || !cc2) {
      return true; // ignore changes in complex objects
    } else {
      if (utils.isObject(v1, true) && utils.isObject(v2, true)) {
        const keys = utils.uniqueArray(Object.keys(v1).concat(Object.keys(v2)));
        return !keys.some((k) => {
          return !deepComp(v1[k], v2[k]);
        })
      } else {
        return utils.compare(v1, v2);
      }
    }
  };

  const comp = (v1, v2) => {
    const res = compare?.(v1, v2);

    if (utils.isDefined(res)) {
      return res;
    } else if (deep) {
      return deepComp(v1, v2);
    } else {
      return shallowCom(v1, v2);
    }
  };

  if (deep || verbose) {
    return memo(
      Component,
      (prev, next) => {
        if (system.isDevelopment()) {
          if (
            Object.keys(prev).some((k) => {
              if (!comp(prev[k], next[k])) {
                logger.trace('Redraw memo', k, prev[k], next[k]);
                return true;
              } else {
                return false;
              }
            })
          ) {
            logger.trace('Redraw memo', prev, next);
          }
        }
        return !(
          Object.keys(prev).some((k) => !comp(prev[k], next[k]))
        );
      }
    )
  } else {
    return memo(Component);
  }
}

export function wrapChartComponent (target, original) {
  const defaultProps = target.defaultProps;

  Object.assign(target, original);
  if (defaultProps) {
    if (!target.defaultProps) {
      target.defaultProps = {};
    }
    Object.assign(target.defaultProps, defaultProps);
  }
  return target;
}
