import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Chip from 'components/atoms/Chips/Chip/Chip';

const StyledActionChip = styled(Chip)`
  ${ComponentStyles}
`;

StyledActionChip.propTypes = {
  theme: PropTypes.object
};

StyledActionChip.defaultProps = {
}

export default StyledActionChip;
