import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledUserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell.styles';
import utils from 'helpers/utils';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import {withMemo} from 'helpers/wrapper';

const UserTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    user,
    short,
    showEmpty,
    ActionAvatarProps,
    ...innerProps
  } = useComponentProps(props, 'UserTableCell', {
    children: ['avatar', 'name']
  });

  const avatar = user ? `${utils.personName(user.firstName, user.lastName)} (${user.username})` : 'Not assigned';
  const name = user ? utils.personName(user.firstName, user.lastName) : 'Not assigned';
  const color = utils.string2Color(avatar);

  const renderAvatar = () => {
    if (showEmpty || user) {
      return <ActionAvatar className="UserTableCell-avatar"
                           action={{
                             label: utils.avatarLabel(avatar),
                             tooltip: avatar
                           }}
                           size="medium"
                           color={color}
                           TooltipProps={{enterDelay: 0}}
                           {...ActionAvatarProps}/>;
    }
  }

  return <StyledUserTableCell ref={ref} {...innerProps}
                              avatar={renderAvatar()}
                              title={(showEmpty || user) ? (short ? name : avatar) : ''} />
}));

UserTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  user: PropTypes.object,
  short: PropTypes.bool,
  ActionAvatarProps: PropTypes.object
};

UserTableCell.defaultProps = {
};

export default UserTableCell;


