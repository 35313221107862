import React, {useLayoutEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesTableFilters
  from 'components/organisms/TableFilters/EntitiesTableFilters/EntitiesTableFilters.styles';
import utils from 'helpers/utils';

const EntitiesTableFilters = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntitiesTableFilters');

  const initialised = useRef(false);

  const tableProvider = useTable();
  const fieldData = tableProvider.fieldData;
  const filterGroupDefinitions = tableProvider.filterGroupDefinitions;

  // initialize the test list
  const setSearchEvent = useEffectEvent(tableProvider?.testListState?.setSearch);
  const setFilterEvent = useEffectEvent(tableProvider?.testListState?.setFilter);
  const setPaginationEvent = useEffectEvent(tableProvider?.testListState?.setPagination);
  const setActiveEvent = useEffectEvent(tableProvider?.testListState?.setActive);
  useLayoutEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      setPaginationEvent?.({pageIndex: 0, pageSize: 0});
      setSearchEvent?.(tableProvider.listState.search);
      setFilterEvent?.(tableProvider.listState.filter);
      setActiveEvent?.(true);
    }
  }, [setSearchEvent, setFilterEvent, setPaginationEvent, setActiveEvent, tableProvider.listState.search, tableProvider.listState.filter]);

  const handleChange = (filter) => {
    tableProvider.testListState.setFilter(filter);
  };

  const handleClose = () => {
    tableProvider.closeFilters();
  }

  const handleSubmit = () => {
    tableProvider.listState.setFilter(tableProvider.testListState.filter);
    tableProvider.closeFilters();
  }

  return <StyledEntitiesTableFilters ref={ref} {...innerProps}
                                     count={tableProvider.testList?.meta?.resultsCount}
                                     filterGroups={filterGroupDefinitions}
                                     filter={tableProvider.testListState?.filter}
                                     isLoading={!utils.isDefined(tableProvider.testList?.meta?.resultsCount) || tableProvider.testList?.status?.isLoading}
                                     fieldData={fieldData}
                                     onClose={handleClose}
                                     onSubmit={handleSubmit}
                                     onChange={handleChange}>
    {innerProps.children}
  </StyledEntitiesTableFilters>
});

EntitiesTableFilters.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  filterGroups: PropTypes.array
};

EntitiesTableFilters.defaultProps = {};

export default EntitiesTableFilters;
