import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledFeedbackHelper from 'components/molecules/Helpers/FeedbackHelper/FeedbackHelper.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Link from 'components/atoms/Links/Link/Link';
import utils from 'helpers/utils';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useForm} from 'components/organisms/Forms/Form/Form';

const FeedbackHelper = withMemo(React.forwardRef((props, ref) => {
  const {
    feedback,
    acceptField,
    ignoreField,
    suggestionField,
    fallback,
    onIgnore,
    onAccept,
    variant,
    ...innerProps
  } = useComponentProps(props, 'FeedbackHelper');

  const [ignored, setIgnored] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const compact = variant === 'compact';
  const form = useForm();

  const ignoreAction = useMemo(() => ({
    label: compact ? '' : 'Ignore',
    tooltip: !compact ? '' : 'Ignore',
    icon: Close,
    ButtonProps: {
      disabled: ignored
    }
  }), [compact, ignored]);

  const acceptAction = useMemo(() => ({
    label: compact ? '' : 'Accept',
    tooltip: !compact ? '' : 'Accept',
    icon: Check,
    ButtonProps: {
      disabled: !feedback?.suggestion || accepted
    }
  }), [compact, feedback?.suggestion, accepted]);

  const handleIgnore = (e) => {
    onIgnore?.(e);

    if (!e.defaultPrevented) {
      setIgnored(true);
      if (ignoreField) {
        form.values({[ignoreField]: true}, true, true);
      }
    }
  }

  const handleAccept = (e) => {
    onAccept?.(e);

    if (!e.defaultPrevented) {
      setAccepted(true);
      if (feedback.suggestion && suggestionField) {
        if (acceptField) {
          form.values({
            [acceptField]: true,
            [suggestionField]: feedback.suggestion
          }, true, true);
        } else {
          form.values({
            [suggestionField]: feedback.suggestion
          }, true, true);
        }
      } else if (acceptField) {
        form.values({[acceptField]: true}, true, true);
      }
    }
  }

  if (accepted || ignored || !feedback) {
    return fallback;
  } else {
    return <StyledFeedbackHelper ref={ref} {...innerProps}>
      <Icon className="FeedbackHelper-icon"
            icon={feedback.icon}
            color={feedback.color ?? 'error'}
            size="smaller"/>
      <Box className="FeedbackHelper-info">
        {(feedback.suggestion && feedback.isLink) ? <Box className="FeedbackHelper-link">
          <Span>We suggest&nbsp;</Span>
          <Link href={utils.cleanExternalLink(feedback.suggestion)}
                target="_blank">{utils.cleanExternalLink(feedback.suggestion)}</Link>
        </Box> : null}
        {(feedback.suggestion && !feedback.isLink) ? <Box className="FeedbackHelper-value">
          <Span>We suggest&nbsp;</Span>
          <Span>{feedback.suggestion}</Span>
        </Box> : null}
        {feedback.explanation ? <Box className="FeedbackHelper-explanation">
          <Span>{feedback.explanation}</Span>
        </Box> : null}
      </Box>
      <ActionButton className="FeedbackHelper-ignore"
                    variant="text" size="small"
                    color="error"
                    onClick={handleIgnore}
                    action={ignoreAction}/>
      <ActionButton className="FeedbackHelper-accept"
                    variant="text" size="small"
                    color="primary"
                    onClick={handleAccept}
                    action={acceptAction}/>
    </StyledFeedbackHelper>
  }
}));

FeedbackHelper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  feedback: PropTypes.object,
  acceptField: PropTypes.string,
  ignoreField: PropTypes.string,
  suggestionField: PropTypes.string,
  fallback: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'compact']), PropTypes.string])
};

FeedbackHelper.defaultProps = {
};

export default FeedbackHelper;
