import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesTasksGraphCard = styled(GraphCard)` 
  &.CollectionEntitiesTasksGraphCard {
    padding-bottom: ${props => props.theme.spacing(2)};
    
    .GraphCard-header {
      padding-bottom: 0;
      .ActionSwitch {
        margin-right: ${props => props.theme.layout('1sp')};
      }
    }
    .GraphCard-content {
      padding-top: ${props => props.theme.spacing(1)};
      padding-bottom: 0;
      .TasksList {
        padding: 0;
      }
    }
  }

  .GraphCard-content {
    height: 1px;
    flex-grow: 1;
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesTasksGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesTasksGraphCard.defaultProps = {}

export default StyledCollectionEntitiesTasksGraphCard;
