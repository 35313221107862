import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledInputLabel = styled(InputLabel)`
  ${ComponentStyles}
`;

StyledInputLabel.propTypes = {
  theme: PropTypes.object
};

StyledInputLabel.defaultProps = {
}

export default StyledInputLabel;
