import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';

const StyledResponsibleWizardContent = styled(WizardContent)`
  &.ResponsibleWizardContent {
    padding-bottom: 0 !important;
    
    .Form {
      height: 100%;
      .Form-fields {
        height: 100%;
      }
      
      .FormField-name-responsible {
        .responsible-label {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          width: 100%;
          gap: ${props => props.theme.layout('1sp')};
          
          .Typography {
            width: 1px;
            flex-grow: 1;
            max-width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .InputContainer {
          flex-grow: 1;
          .List {
            flex-grow: 1;
            height: 1px;
            overflow: auto;
            margin-left: -${props => props.theme.layout('1sp')};
            padding-left: ${props => props.theme.layout('1sp')};
            width: calc(100% + ${props => props.theme.layout('1sp')});
            padding-bottom: ${props => props.theme.spacing(1)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledResponsibleWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledResponsibleWizardContent.defaultProps = {}

export default StyledResponsibleWizardContent;
