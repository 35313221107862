import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper.styles';
import Wrapper, {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import PageBar from 'components/organisms/Bars/PageBar/PageBar';
import utils from 'helpers/utils';
import {useStyles} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';

export const ToolbarWrapperContext = React.createContext(null)

export function useToolbarWrapper () {
  return React.useContext(ToolbarWrapperContext);
}

const ToolbarWrapper = React.forwardRef((props, ref) => {
  const {
    variant,
    anchor,
    collapsed,
    offset,
    ToolbarComponent,
    ToolbarProps,
    ...innerProps
  } = useComponentProps(props, 'ToolbarWrapper', {
    variable: ['anchor'],
    children: ['toolbar', 'content']
  });

  const innerRef = useRef(null);
  const toolbarRef = useRef(null);
  const contentRef = useRef(null);

  const dialog = useDialog();

  const toolbarWrapper = useMemo(() => ({
    refs: {
      ref: innerRef,
      toolbarRef,
      contentRef
    }
  }), []);

  useImperativeHandle(ref, () => toolbarWrapper);

  const toolbarBbox = useBbox(() => toolbarWrapper.refs.ref.current?.querySelector?.('.AppBar'), ['height']);

  const styles = useStyles();
  const parentWrapper = useWrapper();
  const wrapper = useMemo(() => {
    return toolbarBbox ? {
      bounds: {
        top: (anchor === 'top') ?
          utils.pixel2Rem((toolbarBbox.height ?? 0) + (offset ?? 0), styles?.fontBase) : '0rem',
        bottom: (anchor === 'bottom') ?
          utils.pixel2Rem((toolbarBbox.height ?? 0) + (offset ?? 0), styles?.fontBase) : '0rem'
      },
      scrollBounds: { // collapse does tell tables to preserve scroll-margins
        top: (anchor === 'top' && (!collapsed || !['collapse'].includes(variant))) ?
          utils.pixel2Rem((toolbarBbox.height ?? 0) + (offset ?? 0), styles?.fontBase) : '0rem',
        bottom: (anchor === 'bottom' && (!collapsed || !['collapse'].includes(variant))) ?
          utils.pixel2Rem((toolbarBbox.height ?? 0) + (offset ?? 0), styles?.fontBase) : '0rem'
      }
    } : null;
  }, [toolbarBbox, anchor, variant, styles?.fontBase, collapsed, offset]);

  const renderToolbar = () => {
    return <ToolbarComponent ref={toolbarWrapper.refs.toolbarRef}
                             className="ToolbarWrapper-toolbar"
                             variant={variant}
                             {...ToolbarProps} />
  }

  const renderContent = () => {
    return <Wrapper ref={toolbarWrapper.refs.contentRef}
                    className="ToolbarWrapper-content"
                    scroll={!['fixed'].includes(variant)}
                    collapse={['collapse'].includes(variant)}
                    wrapper={wrapper}>
      {innerProps.children}
    </Wrapper>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <ToolbarWrapperContext.Provider value={toolbarWrapper}>
    <StyledToolbarWrapper ref={toolbarWrapper.refs.ref} {...innerProps}
                          $dialog={Boolean(dialog)}
                          $wrapper={parentWrapper}>
      {anchor === 'top' ? renderToolbar() : renderContent()}
      {anchor === 'top' ? renderContent() : renderToolbar()}
    </StyledToolbarWrapper>
  </ToolbarWrapperContext.Provider>
});

ToolbarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ToolbarComponent: PropTypes.any,
  ToolbarProps: PropTypes.object,
  anchor: PropTypes.string,
  offset: PropTypes.number,
  collapsed: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['fixed', 'inset', 'sticky', 'collapse']), PropTypes.string])
};

ToolbarWrapper.defaultProps = {
  variant: 'sticky',
  children: 'Toolbar Wrapper text',
  anchor: 'top',
  ToolbarComponent: PageBar
};

export default ToolbarWrapper;
