import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledBox = styled.div`
  outline: none;
  
  ${ComponentStyles}
`;

StyledBox.propTypes = {
  theme: PropTypes.object
};

StyledBox.defaultProps = {
}

export default StyledBox;
