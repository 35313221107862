import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntityHeading = styled(Box)`
  display: flex;
  column-gap: ${props => props.theme.layout('1sp')};
  
  .EntityHeading {
    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
      line-height: ${props => props.theme.lineHeight('1.2f')};
    }
    
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 1px;
      flex-grow: 1;
    }

    &-links {
      display: flex;
      column-gap: ${props => props.theme.layout(4)};
      margin-left: ${props => props.theme.layout(-2)};
      
      .Icon {
        color: ${props => props.theme.property('palette.text.secondary')};
      }
    }
  }
  
  &.EntityHeading-isLoading {
    &:has(.EntityHeading-links) {
      .EntityHeading {
        &-name {
          line-height: ${props => props.theme.lineHeight('1.2f')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityHeading.propTypes = {
  theme: PropTypes.object
};

StyledEntityHeading.defaultProps = {}

export default StyledEntityHeading;
