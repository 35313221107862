import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledResetEmailPage from 'components/pages/Dev/Email/ResetEmailPage/ResetEmailPage.styles';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {P} from 'components/atoms/Text/Typography/Typography';
import Button from 'components/atoms/Buttons/Button/Button';
import Link from 'components/atoms/Links/Link/Link';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const ResetEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'ResetEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledResetEmailPage {...innerProps}
                                   config={clientConfig}
                                   preview="Create a new password for Catalist.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>You forgot your password. Fortunately, you can easily create a new one via the link below. Use this link
              to
              change your password:</P>
          </TableCell>
        </TableRow>
        <TableRow className="ResetEmailPage-resetButtonRow">
          <TableCell colSpan={2}>
            <Button component="a" href="{{RESET_LINK}}" variant="contained" size="medium">Reset password</Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>
              Or cut and paste this text if the link doesn't work:<br/>
              <Link className="system" href="{{RESET_LINK}}">{'{{RESET_LINK}}'}</Link>
            </P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you can now log in again. Are you still unsure, or do you still have questions? Please
              let us know.</P>
          </TableCell>
        </TableRow>
      </StyledResetEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

ResetEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ResetEmailPage.defaultProps = {
};

export default ResetEmailPage;
