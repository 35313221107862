import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledDealflowMonthlyBreakdownGraphCard = styled(GraphCard)`
  .GraphCard-content {
    padding-top: ${props => props.theme.spacing(1)};
    padding-bottom: ${props => props.theme.spacing(1.5)};
  }
  ${ComponentStyles}
`;

StyledDealflowMonthlyBreakdownGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledDealflowMonthlyBreakdownGraphCard.defaultProps = {}

export default StyledDealflowMonthlyBreakdownGraphCard;
