import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import MenuList from '@mui/material/MenuList';
import MenuStyles from 'styles/mixins/Menu.styles';

const StyledMenuList = styled(MenuList)`
  &.MenuList-density-dense {
    padding: 0;
  }
  
  ${MenuStyles}
  ${ComponentStyles}
`;

StyledMenuList.propTypes = {
  theme: PropTypes.object
};

StyledMenuList.defaultProps = {
}

export default StyledMenuList;
