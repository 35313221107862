import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFieldInlineEdit from 'components/organisms/InlineEdits/FieldInlineEdit/FieldInlineEdit.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const FieldInlineEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    onChange,
    onBusy,
    ...innerProps
  } = useComponentProps(props, 'FieldInlineEdit');

  const fieldsMemo = useMemo(() => {
    return fields.map((field) => {
      field = {
        ...field,
        FormFieldProps: utils.mergeObjects(field.FormFieldProps, {
          size: 'smaller',
          variant: 'inlineLabel',
          hiddenLabel: true,
          fullWidth: true,
          debounce: true
        }, true)
      };

      return field;
    });
  }, [fields]);

  return <StyledFieldInlineEdit ref={ref} {...innerProps}>
    <InlineForm fields={fieldsMemo}
                autoTouch={true}
                fieldData={fieldData}
                onChange={onChange}
                onBusy={onBusy}/>
  </StyledFieldInlineEdit>
}));

FieldInlineEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onBusy: PropTypes.func
};

FieldInlineEdit.defaultProps = {};

export default FieldInlineEdit;
