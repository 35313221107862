import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledButtonGroupTableCell
  from 'components/molecules/TableCells/ButtonGroupTableCell/ButtonGroupTableCell.styles';
import ActionButtonGroup from 'components/molecules/Buttons/ActionButtonGroup/ActionButtonGroup';
import {withMemo} from 'helpers/wrapper';

const ButtonGroupTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    ActionButtonGroupProps,
    ...innerProps
  } = useComponentProps(props, 'ButtonGroupTableCell');

  return <StyledButtonGroupTableCell ref={ref} {...innerProps}>
    <ActionButtonGroup {...ActionButtonGroupProps} />
  </StyledButtonGroupTableCell>
}));

ButtonGroupTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionButtonGroupProps: PropTypes.object
};

ButtonGroupTableCell.defaultProps = {
};

export default ButtonGroupTableCell;


