import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';

const StyledUserTableCell = styled(TextTableCell)`
  ${ComponentStyles}
`;

StyledUserTableCell.propTypes = {
  theme: PropTypes.object
};

StyledUserTableCell.defaultProps = {
}

export default StyledUserTableCell;
