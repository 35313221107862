import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesTableColumns
  from 'components/organisms/TableColumns/CollectionEntitiesTableColumns/CollectionEntitiesTableColumns.styles';

const CollectionEntitiesTableColumns = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTableColumns');

  return <StyledCollectionEntitiesTableColumns ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledCollectionEntitiesTableColumns>
});

CollectionEntitiesTableColumns.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTableColumns.defaultProps = {};

export default CollectionEntitiesTableColumns;
