import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledDatabaseEntitiesTableBarWrapper = styled(ToolbarWrapper)`
  ${ComponentStyles}
`;

StyledDatabaseEntitiesTableBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntitiesTableBarWrapper.defaultProps = {
};

export default StyledDatabaseEntitiesTableBarWrapper;
