import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledLinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';

const LinearProgress = React.forwardRef((props, ref) => {
  const {
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'LinearProgress');

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  return <StyledLinearProgress ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledLinearProgress>
});

LinearProgress.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

LinearProgress.defaultProps = {
};

export default LinearProgress;
