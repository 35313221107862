import utils from 'helpers/utils';

export function processClient (client) {
  client = utils.camelcase(client);

  const tagGroups = utils.camelcaseEx(client.tagGroups ?? []);

  return {
    ...client,
    tagGroups: tagGroups
  }
}
