import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextCard from 'components/molecules/Cards/ContextCard/ContextCard';
import {rgba} from 'polished';

const StyledFilesContextCard = styled(ContextCard)`
  &.FilesContextCard {
    .ContextCard-content {
      .FilesList {
        .DataList-list {
          max-height: ${props => props.theme.layout(200)};
        }
      }
    }
  }
  
  .FilesContextCard {
    &-empty {
      width: 100%;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: 0 0 ${props => props.theme.spacing(1)} 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFilesContextCard.propTypes = {
  theme: PropTypes.object
};

StyledFilesContextCard.defaultProps = {}

export default StyledFilesContextCard;
