import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import utils from 'helpers/utils';

const ListWrapper = React.forwardRef((props, ref) => {
  const {
    header,
    footer,
    stickyHeader,
    stickyFooter,
    ...innerProps
  } = useComponentProps(props, 'ListWrapper', {
    variable: ['stickyHeader', 'stickyFooter'],
    children: ['header', 'content']
  });

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const parentWrapper = useWrapper();

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledListWrapper ref={ref} {...innerProps} $wrapper={parentWrapper}>
    {header ? <Box ref={headerRef} className="ListWrapper-header">
      {header}
    </Box> : null}
    <Box className="ListWrapper-content" scroll={true}>
      {innerProps.children}
    </Box>
    {footer ? <Box ref={footerRef} className="ListWrapper-footer">
      {footer}
    </Box> : null}
  </StyledListWrapper>
});

ListWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  stickyHeader: PropTypes.bool,
  stickyFooter: PropTypes.bool,
  header: PropTypes.any,
  footer: PropTypes.any,
};

ListWrapper.defaultProps = {
  children: 'ListWrapper text',
};

export default ListWrapper;
