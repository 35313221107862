import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';
import StyledImg from 'components/atoms/Images/Img/Img.styles';

const Img = withMemo(React.forwardRef((props, ref) => {
  const {
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Img');

  innerProps.className = utils.flattenClassName(innerProps.className);

  const renderImg = () => {
    return <StyledImg ref={ref} {...innerProps} />;
  }

  if (isLoading) {
    return <Skeleton>{renderImg()}</Skeleton>
  } else {
    return renderImg();
  }
}));

Img.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool
};

Img.defaultProps = {
};

export default Img;
