import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFilledInput from 'components/atoms/Inputs/FilledInput/FilledInput.styles';
import {withMemo} from 'helpers/wrapper';

const FilledInput = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'FilledInput');

  return <StyledFilledInput ref={ref} {...innerProps} />
}));

FilledInput.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FilledInput.defaultProps = {
};

export default FilledInput;
