import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntityProfileBar from 'components/organisms/Bars/EntityProfileBar/EntityProfileBar';

const StyledCollectionEntityProfileBar = styled(EntityProfileBar)`
  ${ComponentStyles}
`;

StyledCollectionEntityProfileBar.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntityProfileBar.defaultProps = {
};

export default StyledCollectionEntityProfileBar;
