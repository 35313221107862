import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import Actionbar from 'components/organisms/Snackbars/Actionbar/Actionbar';
import utils from 'helpers/utils';
import Component from 'components/organisms/Utils/Component/Component';
export const ActionbarContext = React.createContext(null)

export function useActionbar () {
  return React.useContext(ActionbarContext);
}

const ActionbarProvider = (props) => {
  const {
    children,
  } = props;

  const [internalState, setInternalState] = useState({
    ActionbarComponent: null,
    listSelection: null,
    data: null,
    onClose: null,
    open: false,
    options: null
  });

  // context
  const context = useMemo(() => ({
    show: (ActionbarComponent, listSelection = null, data = null, onClose = null, open = true, options = {}) => {
      setInternalState(utils.updater({
        ActionbarComponent: ActionbarComponent ?? props.ActionbarComponent,
        listSelection: listSelection,
        data: data,
        open: open,
        onClose: onClose,
        options: options
      }, true))
    },
    hide: () => {
      setInternalState(utils.updater({
        ActionbarComponent: null,
        listSelection: null,
        data: null,
        open: false,
        onClose: null,
        options: null
      }, true))
    }
  }), [props.ActionbarComponent]);

  // inner
  const handleActionClick = () => {
    setInternalState(utils.updater({
      ActionbarComponent: null,
      listSelection: null,
      data: null,
      open: false,
      onClose: null,
      options: null
    }, true));
  }

  const handleClose = (e, reason) => {
    if (internalState.onClose) {
      internalState.onClose(e, reason);
    } else {
      setInternalState(utils.updater({
        ActionbarComponent: null,
        listSelection: null,
        data: null,
        open: false,
        onClose: null,
        options: null
      }, true));
    }
  }

  return <React.Fragment>
    <ActionbarContext.Provider value={context}>
      {children}
    </ActionbarContext.Provider>
    {internalState.ActionbarComponent ?
      <Component Original={internalState.ActionbarComponent}
                 listSelection={internalState.listSelection}
                 data={internalState.data}
                 open={internalState.open}
                 onClose={handleClose}
                 onActionClick={handleActionClick}
                 {...props.ActionbarProps}
                 {...internalState.options}/> : null}
  </React.Fragment>
};

ActionbarProvider.propTypes = {
  ActionbarComponent: PropTypes.elementType,
  ActionbarProps: PropTypes.object
}

ActionbarProvider.defaultProps = {
  ActionbarComponent: Actionbar
};

export default ActionbarProvider;
