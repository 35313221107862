import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useTableReset} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {useLinkNavigate, useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionEntitiesBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesBarWrapper/CollectionEntitiesBarWrapper.styles';
import Settings from '@mui/icons-material/Settings';
import {CheckList, Rejections, Suggestions} from 'assets/icons';
import Badge from 'components/atoms/Badges/Badge/Badge';
import {H4} from 'components/atoms/Text/Typography/Typography';
import CollectionEntitiesSuggestionsDialog
  from 'components/organisms/Dialogs/CollectionEntitiesSuggestionsDialog/CollectionEntitiesSuggestionsDialog';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import {useCollectionTimelineList} from 'services/collection/timeline/timeline.hooks';
import CollectionNotificationList
  from 'components/organisms/Lists/CollectionNotificationList/CollectionNotificationList';
import CollectionEntitiesViewBar from 'components/organisms/Bars/CollectionEntitiesViewBar/CollectionEntitiesViewBar';
import {useCollectionRejectionList, useCollectionSuggestionList} from 'services/collection/entity/entity.hooks';
import constants from 'helpers/constants';
import UploadFile from '@mui/icons-material/UploadFile';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import {useCollectionUploadCreate} from 'services/collection/upload/upload.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import FieldInline from 'components/molecules/Inlines/FieldInline/FieldInline';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import CallMerge from '@mui/icons-material/CallMerge';
import CollectionMergeDialog from 'components/organisms/Dialogs/CollectionMergeDialog/CollectionMergeDialog';
import {useCollectionMerge} from 'services/collection/collection.hooks';

const CollectionEntitiesBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesBarWrapper');

  const profileProvider = useProfile();
  const tableProvider = useTable();

  const snackbar = useSnackbar();
  const navigate = useLinkNavigate();
  const dialogControl = useDialogControl();
  const mergeCollection = useCollectionMerge();
  const createUpload = useCollectionUploadCreate();
  const authorize = useAuthorize();

  const collection = tableProvider.context?.data;
  const selectedEntityId = tableProvider.selected()?.entityId;
  const isLoading = !collection;
  const isTableEmpty = tableProvider?.list?.data?.length === 0;

  const [pathPrefix, pathPostfix] = useSplitPath('entities');
  const [showSuggestions, setShowSuggestions] = useState(false);

  useTableReset(pathPrefix, pathPostfix);

  const suggestionList = useCollectionSuggestionList({
    collectionId: collection?.collectionId,
    feedUpdatedAt: collection?.feedUpdatedAt,
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0 && !tableProvider.isLoading() && utils.isDefined(tableProvider?.list?.data)
  });
  const suggestionCount = suggestionList.meta?.resultsCount ?? 0;

  const rejectionList = useCollectionRejectionList({
    collectionId: collection?.collectionId,
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0 && !tableProvider.isLoading() && utils.isDefined(tableProvider?.list?.data)
  });
  const rejectionCount = rejectionList.meta?.resultsCount ?? 0;

  const notificationList = useCollectionTimelineList({
    collectionId: collection?.collectionId,
    filter: [
      {id: 'notification', value: true},
      {id: 'mentioned', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ],
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0,
    matchCallback: () => {
      // custom match to refetch on all watches that are invalidated to update the number
      return true;
    }
  });
  const notificationCount = notificationList.meta?.resultsCount ?? 0;

  const leftActions = useMemo(() => [
    {
      navigation: {
        to: '/collections'
      },
      tooltip: 'Back',
      icon: ArrowBack,
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      }
    },
  ], []);

  const openTableProfileEvent = useEffectEvent(tableProvider.openProfile);
  const openProfileEvent = useEffectEvent(profileProvider.openProfile);
  const rightActions = useMemo(() => [
    {
      auth: utils.createAuth({attribute: 'collection.suggestions', meta: {collection: collection} }),
      onClick: () => {
        setShowSuggestions((current) => !current);
      },
      icon: Suggestions,
      tooltip: 'Suggestions',
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        },
        BadgeProps: {
          isLoading: rejectionList.status.isLoading
        },
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            placement: 'bottom-end',
            closeOnNavigate: true,
            strategy: 'fixed'
          }
        }
      },
      IconButtonProps: {
        disabled: !(suggestionCount > 0)
      },
      badge: <Badge isLoading={isLoading}
                    max={999}
                    badgeContent={suggestionCount || '0'}
                    color="primary" />
    },
    {
      auth: utils.createAuth({ attribute: 'collection.rejections' }),
      onClick: (e) => {
        navigate({
          event: e,
          to: `?custom=rejections`,
          keepSearchParams: false
        });
      },
      icon: Rejections,
      tooltip: 'Rejections',
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller',
          color: rejectionCount > 0 ? 'error' : 'default'
        },
        BadgeProps: {
          isLoading: rejectionList.status.isLoading
        },
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            placement: 'bottom-end',
            closeOnNavigate: true,
            strategy: 'fixed'
          }
        }
      },
      IconButtonProps: {
        disabled: !(rejectionCount > 0)
      },
      badge: <Badge badgeContent={rejectionCount || '0'}
                    max={999}
                    color={rejectionCount > 0 ? 'error' : 'light'}/>
    },
    {
      auth: utils.createAuth({ attribute: 'collection.notifications' }),
      onClick: () => {},
      icon: CheckList,
      tooltip: 'Notifications',
      popper: <CollectionNotificationList collection={collection} />,
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        },
        BadgeProps: {
          isLoading: notificationList.status.isLoading
        },
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            placement: 'bottom-end',
            closeOnNavigate: true,
            strategy: 'fixed'
          }
        }
      },
      IconButtonProps: {
        disabled: isLoading
      },
      badge: <Badge badgeContent={notificationCount || '0'} color="light"/>
    },
    {
      auth: utils.createAuth({attribute: 'collection.merge', meta: {collection}}),
      tooltip: 'Merge collection',
      icon: CallMerge,
      onClick: (e) => {
        const handleSubmit = (data) => {
          return mergeCollection.mutation.mutateAsync({
            collectionId: data.targetCollectionId,
            mergedCollectionId: data.mergedCollectionId,
            deleteMergedGroupIds: data.deleteMergedGroupIds,
            deleteTargetGroupIds: data.deleteTargetGroupIds,
            deleteMergedSourceIds: data.deleteMergedSourceIds,
            deleteTargetSourceIds: data.deleteTargetSourceIds
          });
        };

        dialogControl.show(<CollectionMergeDialog collection={collection} onSubmit={handleSubmit} />, true);

        e.preventDefault();
      },
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      },
      IconButtonProps: {
        disabled: isLoading
      }
    },
    {
      auth: utils.createAuth({ attribute: 'collection.settings', meta: {collection: collection} }),
      onClick: () => {
        openProfileEvent?.();
      },
      icon: Settings,
      tooltip: 'Settings',
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      },
      IconButtonProps: {
        disabled: isLoading
      }
    },
    {
      label: 'Add company',
      auth: utils.createAuth({attribute: 'collection.entity.create', meta: {collection: collection} }),
      icon: Add,
      ActionButtonProps: {
        color: 'success',
        variant: 'contained',
        radius: 'round',
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            transparent: true,
            stretch: true,
            strategy: 'fixed',
            offset: [0, 8]
          }
        }
      },
      children: [
        {
          label: 'Upload',
          icon: UploadFile,
          onClick: () => {
            const handleSubmit = (upload) => {
              return createUpload({
                name: upload.name,
                params: upload.params,
                period: upload.period,
                subType: '',
                type: upload.type
              }, collection)
                .then(() => {
                  snackbar.show('File upload is scheduled', null,
                    {color: 'success', autoHideDuration: constants.delay.success});
                  dialogControl.hide();
                });
            };

            const handleClose = () => {
              dialogControl.hide();
            };

            dialogControl.show(<EntitiesUploadDialog onSubmit={handleSubmit}
                                                     EntitiesUploadWizardProps={{collection}}/>, true, handleClose);
          },
        },
        {
          label: 'Manual',
          icon: Add,
          onClick: () => {
            openTableProfileEvent?.(-1);
          },
        }
      ]
    },
  ], [
    snackbar, navigate, dialogControl, createUpload, collection, suggestionCount,
    isLoading, rejectionCount, rejectionList.status.isLoading, mergeCollection.mutation,
    notificationCount, notificationList.status.isLoading, openProfileEvent, openTableProfileEvent
  ]);

  const toggleActions = useMemo(() => {
    return [
      {
        label: 'Table',
        navigation: {
          to: pathPrefix,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
        }
      },
      {
        label: 'Profile',
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}`,
          keepSearchParams: true
        },
        auth: isTableEmpty ? utils.createAuth({attribute: 'system.null'}) : null,
        ActionToggleButtonProps: {
          showInactive: true,
        },
        ToggleButtonProps: {
          disabled: !selectedEntityId
        }
      },
      {
        label: 'Browser',
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}/browser`,
          keepSearchParams: true
        },
        auth: isTableEmpty ? utils.createAuth({attribute: 'system.null'}) : null,
        ActionToggleButtonProps: {
          showInactive: true,
        },
        ToggleButtonProps: {
          disabled: !selectedEntityId
        }
      },
      {
        label: 'Graphs',
        navigation: {
          to: `${pathPrefix}/graphs`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true
        }
      },
    ];
  }, [pathPrefix, selectedEntityId, isTableEmpty]);

  const nameFields = useMemo(() => [{
    name: 'name',
    placeholder: 'Type a collection name',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: collection?.name,
    required: true,
    FormFieldProps: {
      hiddenLabel: true,
      showTooltip: true,
      variant: 'outlined',
      size: 'small',
      InputLabelProps: {
        shrink: true,
      }
    }
  }], [collection?.name]);

  const handleNameChange = (field, value, onSuccess, onError) => {
    profileProvider.updaters.updateData(collection, {[field.name]: value})
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
      });
  }

  const handleSuggestionsClose = () => {
    setShowSuggestions(false);
  }

  const renderTitle = () => {
    return <Box className="CollectionEntitiesBarWrapper-title">
      <Icon icon={FolderSharp} isLoading={isLoading} color="primary"/>
      <H4 isLoading={isLoading} min={8} max={20}>
        {!isLoading ? <FieldInline fields={nameFields}
                                   canUpdate={authorize({attribute: 'collection.update', meta: {collection}})}
                                   autoClose={false}
                                   onChange={handleNameChange}
                                   DropDownPopperProps={{
                                     ContextPopperProps: {
                                       offset: [-32, 0],
                                       minWidth: 340
                                     }
                                   }}/> : null}
      </H4>
    </Box>
  }

  return <StyledCollectionEntitiesBarWrapper ref={ref} {...innerProps}
                                             ToolbarProps={{
                                               title: renderTitle(),
                                               leftActions,
                                               rightActions,
                                               toggleActions,
                                               ViewBarComponent: CollectionEntitiesViewBar,
                                               isLoading
                                             }}>
    {innerProps.children}
    {showSuggestions ? <CollectionEntitiesSuggestionsDialog open={true} onClose={handleSuggestionsClose}/> : null}
  </StyledCollectionEntitiesBarWrapper>
});

CollectionEntitiesBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesBarWrapper.defaultProps = {
  children: 'CollectionEntitiesBarWrapper text',
};

export default CollectionEntitiesBarWrapper;
