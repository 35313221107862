import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useComponentProps, useUploadFiles} from 'helpers/hooks/utils';
import StyledApplicantForm from 'components/organisms/Forms/ApplicantForm/ApplicantForm.styles';
import {useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {usePortalApplicantAdd, usePortalApplicantUploadLogo} from 'services/portal/applicant/applicant.hooks';
import {usePortalApplicantUploadAdd, usePortalApplicantUploadUpdate} from 'services/portal/applicant/upload/upload.hooks';
import {H6} from 'components/atoms/Text/Typography/Typography';
import {useGTM} from 'components/organisms/Providers/GTMProvider/GTMProvider';
import {usePortalCollectionGet} from 'services/portal/collection/collection.hooks';
import Form from 'components/organisms/Forms/Form/Form';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const pageConfigDefault = {
  pages: {
    0: {
      fields: {
        name: {
          label: 'Company name',
          inlineLabel: 'company name',
          type: 'text',
          validation: 'text',
          initial: null,
          required: true,
          FormFieldProps: {}
        },
        website: {
          label: 'Company website',
          inlineLabel: 'company website',
          type: 'text',
          validation: 'url',
          initial: null,
          required: true,
          FormFieldProps: {}
        }
      }
    }
  }
};

const ApplicantForm = (props) => {
  const innerProps = useComponentProps(props, 'ApplicantForm');

  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);

  const formRef = useRef(null);

  const doCreateApplicant = usePortalApplicantAdd();
  const doUploadLogo = usePortalApplicantUploadLogo();
  const doCreateUpload = usePortalApplicantUploadAdd();
  const doUpdateUpload = usePortalApplicantUploadUpdate();

  const [uploadState, setUploadState, uploadFiles] = useUploadFiles();

  const gtm = useGTM(innerProps.$componentName);
  const config = useClientConfig(innerProps.$componentName);

  const client = useAuthClient();
  const collection = usePortalCollectionGet({collectionId: config?.collectionId},
    {enabled: +config?.collectionId > 0});

  const pages = useMemo(() => {
    if (client && config) {
      const pages = utils.clone(config?.pages ?? pageConfigDefault.pages, true);
      Object.keys(pages).forEach((pk) => {
        const page = pages[pk];
        Object.keys(page.fields).forEach((name) => {
          const field = page.fields[name];
          field.entity = field.entity ?? 'entity';

          const isFile = field.type === constants.formFieldTypes.file;

          if (isFile) {
            const fileState = (isFile && uploadState) ?
              Object.keys(uploadState).reduce((o, k) => {
                const split = k.split('_');
                if (split[0] === name) {
                  o[split[1]] = uploadState[k];
                }
                return o;
              }, {}) : null;

            field.FormFieldProps = utils.mergeObjects({
              status: fileState
            }, field.FormFieldProps);
          }
        })
      });

      return pages;
    }
  }, [config, client, uploadState]);

  const handleSubmit = (values) => {
    gtm.send('submit', {detail: 'start'});

    const applicant = {};
    const files = {};
    let logo;

    setFormError(utils.updater(null));
    setFormSuccess(utils.updater(null));

    const fields = utils.object2Array(pages).reduce((a, page) => {
      return a.concat(utils.object2Array(page.fields));
    }, []);

    fields.forEach((field) => {
      if (utils.isDefined(values[field.name])) {
        if (field.type === constants.formFieldTypes.file) {
          if (field.name === 'logo' && values[field.name]) {
            logo = {id: 'logo_0', idx: 0, file: values[field.name], field};
          } else {
            utils.toArray(values[field.name], true)
              .forEach((file, idx) => {
                const id = `${field.name}_${idx}`;
                files[id] = {id, idx, file, field};
              });
          }
        } else if (field.tagGroupId) {
          applicant.tagGroups = {
            ...applicant.tagGroups,
            [field.tagGroupId]: utils.toArray(values[field.name], true)
          };
        } else {
          applicant[field.name] = values[field.name];
          if (field.meta) {
            applicant.userInfo = applicant.userInfo ?? {};
            applicant.userInfo[field.meta] = values[field.name];
          }
        }
      }
    });

    applicant.collectionId = config?.collectionId;

    doCreateApplicant.mutation.mutateAsync({...applicant, $httpParams: {lock: Boolean(config?.lock)}})
      .then((res) => {
        const applicantId = res.response.data.data.applicantId;

        if (applicantId) {
          let promises = [];

          if (logo) {
            const file = logo.file;

            promises.push(doUploadLogo.mutation.mutateAsync({
              applicantId,
              filename: file.name,
              filesize: file.size,
              filetype: file.type || constants.filetypes.default
            })
              .then((res) => {
                const filename = res.response.data.data.filename;
                const uploadUrl = res.response.data.data.uploadUrl;

                return uploadFiles([{...logo, url: uploadUrl, name: filename}], false)
                  .then(() => {
                    return doUploadLogo.mutation.mutateAsync({
                      applicantId,
                      filename: file.name,
                      filesize: file.size,
                      filetype: file.type || constants.filetypes.default,
                      finished: true
                    });
                  })
              }));
          }

          if (Object.keys(files || {}).length > 0) {
            promises = promises.concat(Object.keys(files || {}).map((fileId) => {
              const idx = files[fileId].idx;
              const file = files[fileId].file;
              const field = files[fileId].field;
              return doCreateUpload.mutation.mutateAsync({
                applicantId,
                filename: file.name,
                description: `${field.label} ${idx + 1}`,
                filesize: file.size,
                filetype: file.type || constants.filetypes.default
              })
                .then((res) => {
                  const uploadId = res.response.data.data.uploadId;
                  const filename = res.response.data.data.filename;
                  const uploadUrl = res.response.data.data.uploadUrl;

                  return uploadFiles([{...files[fileId], url: uploadUrl, name: filename}], true)
                    .then(() => {
                      return doUpdateUpload.mutation.mutateAsync({
                        applicantId,
                        uploadId, finished: true
                      });
                    })
                })
            }));
          }

          if (promises.length > 0) {
            return Promise.all(promises)
              .then(() => {
                gtm.send('submit', {detail: 'success'});
                setFormSuccess(utils.updater('Application saved'));
              })
              .catch((err) => {
                gtm.send('error', {detail: 'upload'});
                setFormError(utils.updater('File upload failed'));
              });
          } else {
            gtm.send('submit', {detail: 'success'});
            setFormSuccess(utils.updater('Application saved'));
          }
        }
      })
      .catch(() => {
        gtm.send('error', {detail: 'applicant'});
        setFormError(utils.updater('Create application failed'));
      });
  };

  useLayoutEffect(() => {
    if (pages) {
      gtm.send('load', {detail: 'load'});
    } else {
      gtm.send('config', {detail: 'config'});
    }
  }, [gtm, pages]);

  useEffect(() => {
    if (formError || formSuccess) {
      formRef.current?.formik.setSubmitting(false);

      return utils.observeTimeout(() => setUploadState({}), constants.delay.minimal);
    }
  }, [formError, formSuccess, setUploadState])

  const handleChange = () => {
    doCreateApplicant.status.clearAll();
    doCreateUpload.status.clearAll();
    setFormError(utils.updater(null));
    setFormSuccess(utils.updater(null));
  };

  const handleValidating = (validating, dirty, errors) => {
    if (errors) {
      setFormError(utils.updater('Please check if all fields have the correct values'));
    }
  };

  const tagGroups = useMemo(() => {
    return (client?.tagGroups || []).concat(collection.data?.tagGroups || []);
  }, [client?.tagGroups, collection.data?.tagGroups]);

  const render = () => {
    if (!pages) {
      return <Box className="loading">
        <CircularProgress />
        <H6>Loading configuration</H6>
      </Box>
    } else if (formSuccess) {
      return <H6>Thank you for your application</H6>
    } else {
      return <Form ref={formRef}
                   pages={pages}
                   fieldData={{
                     customFields: client?.customFields,
                     tagGroups
                   }}
                   error={formError}
                   success={formSuccess}
                   showLoader={true}
                   autoFocus={Boolean(config?.autoFocus)}
                   onChange={handleChange}
                   onSubmit={handleSubmit}
                   onValidating={handleValidating}
                   SubmitButtonProps={{children: 'Send application'}}/>
    }
  };

  return <StyledApplicantForm {...innerProps}>
    {render()}
  </StyledApplicantForm>
};

ApplicantForm.propTypes = {
};

ApplicantForm.defaultProps = {
};

export default ApplicantForm;
