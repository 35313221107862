import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledAccordionSummary from 'components/atoms/Accordions/AccordionSummary/AccordionSummary.styles';
import Component from 'components/organisms/Utils/Component/Component';

const AccordionSummary = React.forwardRef((props, ref) => {
  const {
    badge,
    ...innerProps
  } = useComponentProps(props, 'AccordionSummary', {
    children: ['badge']
  });

  const renderContent = () => {
    if (badge) {
      return <Component Original={badge}
                        className="AccordionSummary-badge"
                        variant="inline">{innerProps.children}</Component>;
    } else {
      return innerProps.children;
    }
  }

  return <StyledAccordionSummary ref={ref} {...innerProps}>
    {renderContent()}
  </StyledAccordionSummary>
});

AccordionSummary.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  badge: PropTypes.any
};

AccordionSummary.defaultProps = {};

export default AccordionSummary;
