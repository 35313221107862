import React, {useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledProfileFoldout from 'components/organisms/Foldouts/ProfileFoldout/ProfileFoldout.styles';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import Close from '@mui/icons-material/Close';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

export const ProfileFoldoutContext = React.createContext(null)

export function useProfileFoldout () {
  return React.useContext(ProfileFoldoutContext);
}

const ProfileFoldout = React.forwardRef((props, ref) => {
  const {
    title,
    actions,
    onClose,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ProfileFoldout', {
    children: ['toolbar', 'actions']
  });

  const innerRef = useRef(null);
  const profileProvider = useProfile();

  const onCloseEvent = useEffectEvent(onClose);
  const doClose = useCallback((e, reason) => {
    if ((!profileProvider.state.isSubmitting &&
        !profileProvider.state.isDirty &&
        !profileProvider.state.submitError &&
        !profileProvider.state.validation) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onCloseEvent?.();
    }
  }, [onCloseEvent, profileProvider.state.isSubmitting, profileProvider.state.isDirty,
    profileProvider.state.submitError, profileProvider.state.validation]);

  const profileFoldout = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    close: (e, reason) => {
      doClose(e, reason);
    }
  }), [doClose]);

  useImperativeHandle(ref, () => profileFoldout);

  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: (e) => {
      doClose?.(e, 'closeButtonClick');
    }
  }), [doClose]);

  const renderActions = () => {
    return actions.map((action, idx) => <ActionIconButton key={idx}
                                                          size="smaller"
                                                          density="sparse"
                                                          variant="outlined"
                                                          className="ProfileFoldout-action"
                                                          IconProps={{
                                                            size: 'smaller'
                                                          }}
                                                          isLoading={isLoading}
                                                          action={action} />);
  }

  return <ProfileFoldoutContext.Provider value={profileFoldout}>
    <StyledProfileFoldout ref={ref} {...innerProps}>
      <Toolbar className="ProfileFoldout-toolbar">
        {title ? <Box className="ProfileFoldout-toolbar-title">{utils.isReactElement(title) ? title : <H6>{title}</H6>}</Box> : null}
        {actions?.length > 0 ? <Box className="ProfileFoldout-actions">
          {renderActions(actions)}
        </Box> : null}
        <ActionIconButton size="smaller"
                          density="sparse"
                          variant="outlined"
                          className="ProfileFoldout-toolbar-close"
                          IconProps={{
                            size: 'smaller'
                          }}
                          action={closeAction} />
      </Toolbar>
      {innerProps.children}
    </StyledProfileFoldout>
  </ProfileFoldoutContext.Provider>
});

ProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  actions: PropTypes.array,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func
};

ProfileFoldout.defaultProps = {};

export default ProfileFoldout;
