import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledProfileCardDialog from 'components/organisms/Dialogs/ProfileCardDialog/ProfileCardDialog.styles';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import dom from 'helpers/dom';

const ProfileCardDialog = React.forwardRef((props, ref) => {
  const {
    title,
    content,
    info,
    buttons,
    autoFocus,
    showHeader,
    showFooter,
    wrapContent,
    ...innerProps
  } = useComponentProps(props, 'ProfileCardDialog');

  const innerRef = useRef(null);
  const contentRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(contentRef.current);
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledProfileCardDialog ref={innerRef} {...innerProps}>
    {showHeader ? <DialogHeader title={title} /> : null}
    {content ? (wrapContent ? <DialogContent ref={contentRef} className="ProfileCardDialog-content">
      {content}
    </DialogContent> : content) : null}
    {showFooter ? <DialogFooter info={info} buttons={buttons} /> : null}
  </StyledProfileCardDialog>
});

ProfileCardDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  content: PropTypes.any,
  info: PropTypes.any,
  buttons: PropTypes.any,
  autoFocus: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  wrapContent: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'basic']), PropTypes.string])
};

ProfileCardDialog.defaultProps = {
  autoFocus: true,
  showHeader: true,
  showFooter: true,
  wrapContent: true
};

export default ProfileCardDialog;
