import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledTestPage = styled(Page)`
  ${ComponentStyles}
`;

StyledTestPage.propTypes = {
  theme: PropTypes.object
};

StyledTestPage.defaultProps = {
};

export default StyledTestPage;
