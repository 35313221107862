import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTooltip from 'components/atoms/Tooltips/Tooltip/Tooltip.styles';
import Zoom from '@mui/material/Zoom';
import {Span} from 'components/atoms/Text/Typography/Typography';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

// see also theme/base for styles and props
const Tooltip = withMemo(React.forwardRef((props, ref) => {
  const {
    showDisabled,
    fullWidth,
    ...innerProps
  } = useComponentProps(props, 'Tooltip', {
    static: ['fullWidth']
  });

  innerProps.TransitionComponent = innerProps.TransitionComponent ?? Zoom;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTooltip ref={ref} {...innerProps}>
    {showDisabled ? <Span className="Tooltip-wrap"
                          style={{
                            display: 'inline-flex',
                            width: fullWidth ? '100%' : null,
                            maxWidth: fullWidth ? '100%' : 'fit-content'
                          }}>
      {innerProps.children}
    </Span> : innerProps.children}
  </StyledTooltip>
}));

Tooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['light']), PropTypes.string])
};

Tooltip.defaultProps = {
  children: 'Tooltip text',
  disableFocusListener: true
};

export default Tooltip;
