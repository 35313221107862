import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCards = styled(Box)`
  &.Cards-grid {
    .Cards {
      &-cards {
        display: grid;
        gap: ${props => props.theme.layout(props.$gap)};
      }
    }
    
    &.Cards-orientation-vertical {
      .Cards {
        &-cards {
          grid-template-columns: repeat(${props => props.$columns}, calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`}));
        }
      }
    }

    &.Cards-orientation-horizontal {
      .Cards {
        &-cards {
          grid-template-rows: repeat(${props => props.$columns}, calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`}));
        }
      }
    }
  }
  
  &.Cards-masonry {
    .Cards {
      &-cards {
        display: flex;
        align-content: flex-start;
        gap: ${props => props.theme.layout(props.$gap)};
        overflow: hidden;
        
        &-section {
          &.masonry {
            display: flex;
            align-content: flex-start;
            gap: ${props => props.theme.layout(props.$gap)};
            overflow: hidden;
          }
          
          &.grid {
            display: grid;
            gap: ${props => props.theme.layout(props.$gap)};
          }
        }
      }
    }
    
    &.Cards-orientation-vertical {
      .Cards {
        &-cards {
          flex-direction: column;

          &-section {
            &.masonry {
              flex-flow: column wrap;

              .CardItem {
                width: calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`});
              }
            }
            
            &.grid {
              grid-template-columns: repeat(${props => props.$columns}, calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`}));
            }
          }
        }
      }

      &.Cards-reverse {
        .Cards-cards {
          &-section {
            &.masonry {
              flex-flow: column wrap-reverse;
            }
          }
        }
      }
    }

    &.Cards-orientation-horizontal {
      .Cards {
        &-cards {
          flex-direction: row;

          &-section {
            &.masonry {
              flex-flow: row wrap;

              .CardItem {
                height: calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`});
              }
            }
            
            &.grid {
              grid-template-rows: repeat(${props => props.$columns}, calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`}));
            }
          }
        }
      }

      &.Cards-reverse {
        .Cards-cards {
          &-section {
            &.masonry {
              flex-flow: row wrap-reverse;
            }
            &.grid {
              grid-template-rows: repeat(${props => props.$columns}, calc(${props => `${100 / props.$columns}% - ${props.theme.layout(props.$columns > 1 ? (props.$gap * (props.$columns - 1)) / props.$columns : 0)}`}));
            }
          }
        }
      }
    }
  }

  &.Cards-flex {
    .Cards {
      &-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: ${props => props.theme.layout(props.$gap)};
      }
    }
    
    &.Cards-orientation-vertical {
      &-cards {
        flex-direction: column;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCards.propTypes = {
  theme: PropTypes.object
};

StyledCards.defaultProps = {
};

export default StyledCards;
