import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledEntitiesList = styled(ListWrapper)`
  &.EntitiesList {
    .ListHeader {
      height: ${props => props.theme.layout(36)};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
    }
    .List {
      padding: 0;
    }
    .ListItem {
      .Card-button {
        width: 100%
      }

      .EntityCard {
        border-radius: 0;
        box-shadow: unset;
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
  }
  ${ComponentStyles}
`;

StyledEntitiesList.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesList.defaultProps = {
};

export default StyledEntitiesList;
