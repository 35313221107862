import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useClientCustomFieldStatus () {
  return useServiceStatus(path);
}

export function useClientCustomFieldList ({clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useClientCustomFieldAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useClientCustomFieldUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useClientCustomFieldDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useClientCustomFieldCache ({clientId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId, includeDefault: true}, {
    overridePath: 'clients/:clientId/customFieldsCache',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
