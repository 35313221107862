import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTeamsSettingsCardHeader
  from 'components/molecules/Cards/TeamsSettingsCardHeader/TeamsSettingsCardHeader.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import utils from 'helpers/utils';
import FieldInline from 'components/molecules/Inlines/FieldInline/FieldInline';
import constants from 'helpers/constants';

const TeamsSettingsCardHeader = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    isPrivate,
    canUpdate,
    addUsersButtonAction,
    deleteTeamButtonAction,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'TeamsSettingsCardHeader');

  const fields = useMemo(() => [{
    name: 'name',
    placeholder: 'Type a team name',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: title,
    required: true,
    FormFieldProps: {
      hiddenLabel: true,
      variant: 'outlined',
      size: 'small',
      InputLabelProps: {
        shrink: true,
      }
    }
  }], [title]);

  const handleChange = (field, value, onSuccess, onError) => {
    onChange?.(field, value, onSuccess, onError)
  };

  return <StyledTeamsSettingsCardHeader ref={ref} {...innerProps}>
    <Box className="TeamsSettingsCardHeader-info">
      <Box className="TeamsSettingsCardHeader-info-title">
        <FieldInline fields={fields}
                     canUpdate={canUpdate}
                     autoClose={false}
                     onChange={handleChange} />
      </Box>
      <Box className="TeamsSettingsCardHeader-info-subtitle">
        {utils.isReactElement(subtitle) ? subtitle : <Typography variant="subtitle2"
                                                                 color="text.secondary">{subtitle}</Typography>}
        {isPrivate ? <Chip size="small" label="Private" /> : null}
      </Box>
    </Box>
    <Box className="TeamsSettingsCardHeader-buttons">
      <ActionButton action={addUsersButtonAction} />
      <ActionIconButton action={deleteTeamButtonAction} />
    </Box>
  </StyledTeamsSettingsCardHeader>
});

TeamsSettingsCardHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isPrivate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  addUsersButtonAction: PropTypes.object,
  deleteTeamButtonAction: PropTypes.object,
  onChange: PropTypes.func
};

TeamsSettingsCardHeader.defaultProps = {};

export default TeamsSettingsCardHeader;
