import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledClientPlanProfileCard = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  transition: ${props => props.theme.transitions.create(['height'], {
    easing: props.theme.property('transitions.easing.easeOut'),
    duration: props.theme.property('transitions.duration.leavingScreen')
  })} !important;

  .ProfileCardContent {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  .ProfileCard {
    &-header {
      padding: ${props => props.theme.spacing(1.5)};
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    
    &-title {
      display: none;
    }
  }

  &.ClientPlanProfileCard-isCustom {
    .ProfileCard-action {
      color: ${props => props.theme.property('palette.background.paper')};
    }
  }
  
  &.ClientPlanProfileCard-isDeleted {
    visibility: hidden;
  }
  
  ${ComponentStyles}
`;

StyledClientPlanProfileCard.propTypes = {
  theme: PropTypes.object
};

StyledClientPlanProfileCard.defaultProps = {
}

export default StyledClientPlanProfileCard;
