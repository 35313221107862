import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import LinearProgress from '@mui/material/LinearProgress';

const StyledLinearProgress = styled(LinearProgress)`
  &.LinearProgress-determinate {
    border-radius: ${props => props.theme.radius(1)};
    .MuiLinearProgress-bar1Determinate {
      border-radius: ${props => props.theme.radius(1)};
    }
  }
  ${ComponentStyles}
`;

StyledLinearProgress.propTypes = {
  theme: PropTypes.object
};

StyledLinearProgress.defaultProps = {
}

export default StyledLinearProgress;
