import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSplitBar from 'components/organisms/Bars/SplitBar/SplitBar.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import Box from 'components/atoms/Layout/Box/Box';

const SplitBar = React.forwardRef((props, ref) => {
  const {
    actions,
    context,
    ...innerProps
  } = useComponentProps(props, 'SplitBar');

  return <StyledSplitBar ref={ref} {...innerProps}>
    {actions?.length > 0 ? <Box className="SplitBar-actions">
      {actions?.map((action, idx) => {
        return <ActionButton key={idx}
                             action={action} />
      })}
    </Box> : null}
    {context ? <Box className="SplitBar-context">
      {context}
    </Box> : null}
  </StyledSplitBar>
});

SplitBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  context: PropTypes.any
};

SplitBar.defaultProps = {
  elevation: 2
};

export default SplitBar;
