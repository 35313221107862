import {useCallback} from 'react';
import {useSourceAdd} from 'services/source/source.hooks';
import {useCollectionAdd, useCollectionDelete} from 'services/collection/collection.hooks';
import utils from 'helpers/utils';
import sourceUtils from 'helpers/source';

export function useSourceCreate (mutationOptions = {}) {
  const addCollection = useCollectionAdd();
  const deleteCollection = useCollectionDelete();
  const addSource = useSourceAdd(mutationOptions);

  return useCallback((source) => {
    return addCollection.mutation.mutateAsync({
      name: source.name,
      visibility: 'public',
      labels: [],
      tagsGroups: [],
      $httpParams: {
        updateExisting: true
      }
    }).then(({response}) => {
      const newCollection = utils.camelcase(response.data?.data);
      const newSource = sourceUtils.createSource({
        ...source,
        params: {
          ...source.params,
          publicCollectionId: newCollection?.collectionId
        }
      }, newCollection);

      return addSource.mutation.mutateAsync({$body: newSource})
        .catch((error) => {
          deleteCollection.mutation.mutateAsync({collectionId: newCollection.collectionId});
          throw error;
        });
    })
  }, [addCollection.mutation, addSource.mutation, deleteCollection.mutation])
}
