import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom';
import useDatabaseEntityRoutes from 'routes/database/entities/entity/entity.routes';
import DatabaseEntitiesTableProvider from 'components/organisms/Providers/TableProvider/DatabaseEntitiesTableProvider';
import DatabaseEntitiesBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesBarWrapper/DatabaseEntitiesBarWrapper';
import DatabaseEntitiesTableBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesTableBarWrapper/DatabaseEntitiesTableBarWrapper';
import DatabaseEntitiesQueryBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesQueryBarWrapper/DatabaseEntitiesQueryBarWrapper';
import DatabaseEntityProfileWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityProfileWrapper/DatabaseEntityProfileWrapper';
import DatabaseEntitiesColumnsWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesColumnsWrapper/DatabaseEntitiesColumnsWrapper';
import utils from 'helpers/utils';
import DatabaseEntitiesPage from 'components/pages/Database/Entity/DatabaseEntitiesPage/DatabaseEntitiesPage';

export default function useDatabaseEntitiesRoutes () {
  const databaseEntityRoutes = useDatabaseEntityRoutes();
  return useMemo(() => {
    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'entity',
            path: 'entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <DatabaseEntitiesPage />,

        handle: {
          title: 'Database - companies',
          auth: utils.createAuth({
            attribute: 'database.entity.list'
          })
        }
      },
      {
        path: ':entityId',
        element: <databaseEntityRoutes.WrapperComponent><Outlet /></databaseEntityRoutes.WrapperComponent>,
        children: databaseEntityRoutes.route,

        handle: {
          title: 'Database - company',
          auth: utils.createAuth({
            attribute: 'database.entity.route',
            meta: entityMeta
          })
        }
      }
    ];

    return {
      WrapperComponent: ({children}) => {
        return <DatabaseEntitiesTableProvider>
          <DatabaseEntitiesColumnsWrapper>
            <DatabaseEntitiesBarWrapper>
              <DatabaseEntitiesQueryBarWrapper>
                <DatabaseEntitiesTableBarWrapper>
                  <DatabaseEntityProfileWrapper>
                    {children}
                  </DatabaseEntityProfileWrapper>
                </DatabaseEntitiesTableBarWrapper>
              </DatabaseEntitiesQueryBarWrapper>
            </DatabaseEntitiesBarWrapper>
          </DatabaseEntitiesColumnsWrapper>
        </DatabaseEntitiesTableProvider>
      },
      route: route
    };
  }, [
    databaseEntityRoutes
  ]);
};
