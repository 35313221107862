import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledSettingsWrapper from 'components/templates/Wrappers/Settings/SettingsWrapper/SettingsWrapper.styles';
import ActionTabs from 'components/molecules/Tabs/ActionTabs/ActionTabs';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useSplitPath} from 'helpers/hooks/links';

const SettingsWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'SettingsWrapper', {
    children: ['tabs', 'panels']
  });

  const profileProvider = useProfile();

  const isDirty = profileProvider?.state?.isDirty;
  const [, pathPostfix] = useSplitPath('settings');
  const pathKey = pathPostfix.split('/')?.[0] ?? 'profile';

  const actions = useMemo(() => {
    const actions = [];
    
    actions.push({
      label: 'Profile',
      navigation: {
        to: '/settings/profile',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'profile' && isDirty)
      }
    });

    actions.push({
      label: 'Plan',
      navigation: {
        to: '/settings/plan',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'plan' && isDirty)
      }
    });

    actions.push({
      label: 'Credits',
      navigation: {
        to: '/settings/credits',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'credits' && isDirty)
      }
    });

    actions.push({
      label: 'Users',
      navigation: {
        to: '/settings/users',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'users' && isDirty)
      }
    });

    actions.push({
      label: 'Clients',
      navigation: {
        to: '/settings/clients',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'clients' && isDirty)
      }
    });

    actions.push({
      label: 'Teams',
      navigation: {
        to: '/settings/teams',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'teams' && isDirty)
      }
    });

    actions.push({
      label: 'Fields',
      navigation: {
        to: '/settings/fields',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'fields' && isDirty)
      }
    });

    actions.push({
      label: 'Deal flow',
      navigation: {
        to: '/settings/dealflow',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'dealflow' && isDirty)
      }
    });

    actions.push({
      label: 'Categories',
      navigation: {
        to: '/settings/categories',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'categories' && isDirty)
      }
    });

    actions.push({
      label: 'Services',
      navigation: {
        to: '/settings/services',
        end: false
      },
      TabProps: {
        disabled: (pathKey !== 'services' && isDirty)
      }
    });

    return actions;
  }, [pathKey, isDirty]);
  
  const renderTabs = () => {
    return <Box className="SettingsWrapper-tabs">
      <ActionTabs orientation="vertical"
                  actions={actions} />
    </Box>
  }

  return <StyledSettingsWrapper ref={ref} {...innerProps}
                                anchor="left"
                                size="smaller"
                                open={true}
                                context={renderTabs()}>
    <Box className="SettingsWrapper-content">
      {innerProps.children}
    </Box>
  </StyledSettingsWrapper>
});

SettingsWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

SettingsWrapper.defaultProps = {};

export default SettingsWrapper;
