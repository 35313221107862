import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledChipTableCell from 'components/molecules/TableCells/ChipTableCell/ChipTableCell.styles';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import {withMemo} from 'helpers/wrapper';

const ChipTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    active,
    size,
    ActionChipProps,
    ...innerProps
  } = useComponentProps(props, 'ChipTableCell');

  return <StyledChipTableCell ref={ref} {...innerProps}>
    <ActionChip {...ActionChipProps} />
  </StyledChipTableCell>
}));

ChipTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionChipProps: PropTypes.object
};

ChipTableCell.defaultProps = {
};

export default ChipTableCell;


