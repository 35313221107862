import React, {useMemo} from 'react'
import DatabaseEntityProfileProvider
  from 'components/organisms/Providers/ProfileProvider/DatabaseEntityProfileProvider';
import DatabaseEntitySideBarWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntitySideBarWrapper/DatabaseEntitySideBarWrapper';
import DatabaseEntityContextWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityContextWrapper/DatabaseEntityContextWrapper';
import EntityTaskProfileWrapper
  from 'components/templates/Wrappers/Tasks/EntityTaskProfileWrapper/EntityTaskProfileWrapper';
import utils from 'helpers/utils';
import DatabaseEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityBrowserBarWrapper/DatabaseEntityBrowserBarWrapper';
import DatabaseEntityBrowserPage
  from 'components/pages/Database/Entity/DatabaseEntityBrowserPage/DatabaseEntityBrowserPage';
import DatabaseEntityProfileBarWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityProfileBarWrapper/DatabaseEntityProfileBarWrapper';
import DatabaseEntityProfilePage
  from 'components/pages/Database/Entity/DatabaseEntityProfilePage/DatabaseEntityProfilePage';

export default function useDatabaseEntityRoutes () {
  return useMemo(() => {
    const entityMeta = (match) => {
      // get extra mate data to verify if the route is authorized
      // { pathname, params } - could return object + id to retrieve
      return {
        lookup: [
          {
            name: 'entity',
            path: 'entity', // store path
            id: match?.params?.entityId, // id from match
            params: match?.params
          }
        ]
      };
    };

    const route = [
      {
        path: '',
        element: <DatabaseEntityProfileBarWrapper>
          <DatabaseEntityProfilePage />
        </DatabaseEntityProfileBarWrapper>,

        handle: {
          title: 'Database - company',
          auth: utils.createAuth({
            attribute: 'database.entity.profile.read',
            meta: entityMeta
          })
        }
      },
      {
        path: 'browser',
        element: <DatabaseEntityBrowserBarWrapper>
          <DatabaseEntityBrowserPage />
        </DatabaseEntityBrowserBarWrapper>,

        handle: {
          title: 'Database - browser',
          auth: utils.createAuth({
            attribute: 'dealflow.entity.browser.read',
            meta: entityMeta
          })
        }
      },
    ];

    return {
      WrapperComponent: ({children}) => <DatabaseEntityProfileProvider>
        <DatabaseEntitySideBarWrapper>
          <DatabaseEntityContextWrapper>
            <EntityTaskProfileWrapper>
              {children}
            </EntityTaskProfileWrapper>
          </DatabaseEntityContextWrapper>
        </DatabaseEntitySideBarWrapper>
      </DatabaseEntityProfileProvider>,
      route: route
    };
  }, []);
};
