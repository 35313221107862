import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionBasicsSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionBasicsSectionPanelContent/CollectionBasicsSectionPanelContent.styles';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';

const info = 'Manage the basic collection settings';

const CollectionBasicsSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionBasicsSectionPanelContent');

  const handleShowCard = (card) => {
    return ['basics'].includes(card.name);
  }

  return <StyledCollectionBasicsSectionPanelContent ref={ref} {...innerProps}>
    <CollectionSectionPanelHeader title={section.title} info={info} />
    <CollectionProfile columns={1}
                       onCanUpdate={onCanUpdate}
                       onShowCard={handleShowCard} />
  </StyledCollectionBasicsSectionPanelContent>
});

CollectionBasicsSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  onCanUpdate: PropTypes.func
};

CollectionBasicsSectionPanelContent.defaultProps = {};

export default CollectionBasicsSectionPanelContent;
