import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledSettingsWrapper = styled(ContextWrapper)`
  display: flex;
  
  .ContextDrawer {
    > * {
      border-right: unset;
      box-shadow: inset -1px 0px 0px ${props => props.theme.property('palette.divider')};
    }
  }
  
  .SettingsWrapper {
    &-tabs {
      height: 100%;
      overflow-y: auto;
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      
      .Tabs {
        padding: ${props => props.theme.spacing(2.5)} 0 ${props => props.theme.spacing(2.5)} ${props => props.theme.spacing(7)};
        
        .Tab {
          align-items: flex-end;
        }
      }
    }
    &-panels {
      width: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledSettingsWrapper.propTypes = {
  theme: PropTypes.object
};

StyledSettingsWrapper.defaultProps = {}

export default StyledSettingsWrapper;
