import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledDealflowBarChartTableCell = styled(BaseTableCell)`
  .DealflowBarChartTableCell {
    &-bar {
      width: 100%;
      height: ${props => props.theme.layout(10)};
      margin-top: ${props => props.theme.layout(1)};
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowBarChartTableCell.propTypes = {
  theme: PropTypes.object
};

StyledDealflowBarChartTableCell.defaultProps = {
}

export default StyledDealflowBarChartTableCell;
