import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntityQuestionnaireSectionPanelContent = styled(Box)`
  height: 100%;
  
  .DataList {
    .DataList-list {
      padding: ${props => props.theme.spacing(3)};

      scroll-margin-top: ${props => props.theme.layout(20)};
      scroll-margin-bottom: ${props => props.theme.layout(60)};
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityQuestionnaireSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityQuestionnaireSectionPanelContent.defaultProps = {}

export default StyledEntityQuestionnaireSectionPanelContent;
