import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSideMenuWrapper = styled(Box)`
  display: flex;
  width: 100%;
  min-height: 100vh;
  
  .SideMenuWrapper-content {
    flex-grow: 1;
    
    .Snackbar {
      ${props => props.$width ? css`
        margin-left: calc(${props.$width} - ${props.theme.layout('0.5sp')});
      ` : css``};
    }
  }

  &.SideMenuWrapper {
    > .SideMenuWrapper {
      &-drawer {
        &.MenuDrawer-persistent {
          > .MuiPaper-root {
            transition: unset !important;
          }
        }
      }
    }
  }

  &.SideMenuWrapper-animate.SideMenuWrapper {
    &-open {
      > .SideMenuWrapper {
        &-drawer {
          &.MenuDrawer-persistent {
            > .MuiPaper-root {
              transition: ${props => props.theme.transitions.create(['width'], {
                easing: props.theme.property('transitions.easing.easeIn'),
                duration: props.theme.property('transitions.duration.enteringScreen')
              })} !important;
            }
          }
        }
        
        &-content {
          transition: ${props => props.theme.transitions.create(['margin-left'], {
            easing: props.theme.property('transitions.easing.easeIn'),
            duration: props.theme.property('transitions.duration.enteringScreen')
          })} !important;
          
          .PageBar {
            transition: ${props => props.theme.transitions.create(['left', 'width'], {
              easing: props.theme.property('transitions.easing.easeIn'),
              duration: props.theme.property('transitions.duration.enteringScreen')
            })} !important;
          }
        }
      }
    }

    &:not(.SideMenuWrapper-open) {
      > .SideMenuWrapper {
        &-drawer {
          &.MenuDrawer-persistent {
            > .MuiPaper-root {
              transition: ${props => props.theme.transitions.create(['width'], {
                easing: props.theme.property('transitions.easing.sharp'),
                duration: props.theme.property('transitions.duration.leavingScreen')
              })} !important;
            }
          }
        }

        &-content {
          transition: ${props => props.theme.transitions.create(['margin-left'], {
            easing: props.theme.property('transitions.easing.sharp'),
            duration: props.theme.property('transitions.duration.leavingScreen')
          })} !important;
          
          .PageBar {
            transition: ${props => props.theme.transitions.create(['left', 'width'], {
              easing: props.theme.property('transitions.easing.sharp'),
              duration: props.theme.property('transitions.duration.leavingScreen')
            })} !important;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSideMenuWrapper.propTypes = {
  theme: PropTypes.object
};

StyledSideMenuWrapper.defaultProps = {
};

export default StyledSideMenuWrapper;
