import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ColumnsDialog from 'components/organisms/Dialogs/ColumnsDialog/ColumnsDialog';

const StyledKanbanColumnsDialog = styled(ColumnsDialog)`
  ${ComponentStyles}
`;

StyledKanbanColumnsDialog.propTypes = {
  theme: PropTypes.object
};

StyledKanbanColumnsDialog.defaultProps = {}

export default StyledKanbanColumnsDialog;
