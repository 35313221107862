import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from '@mui/material/Box';

const StyledSuggestionFieldExplanation = styled(Box)`
  width: 100%;
  padding: ${props => props.theme.spacing(0.5)} ${props => props.theme.spacing(1.5)};
  
  ${ComponentStyles}
`;

StyledSuggestionFieldExplanation.propTypes = {
  theme: PropTypes.object
};

StyledSuggestionFieldExplanation.defaultProps = {
}

export default StyledSuggestionFieldExplanation;
