import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {
  useAuthIsProxy,
} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import AvatarOption from 'components/molecules/Options/AvatarOption/AvatarOption';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useClientCallbacks} from 'services/client/client.utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledCollaboratorAddDialog
  from 'components/organisms/Dialogs/CollaboratorAddDialog/CollaboratorAddDialog.styles';

const CollaboratorAddDialog = React.forwardRef((props, ref) => {
  const {
    title,
    onSubmit,
    existingUserIds,
    ...innerProps
  } = useComponentProps(props, 'CollaboratorAddDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const fieldRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const snackbar = useSnackbar();

  const isProxy = useAuthIsProxy();
  const clientCallbacks = useClientCallbacks(isProxy);

  const fields = useMemo(() => {
    return  [{
      name: 'users',
      placeholder: 'Type a username',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.list,
      conversion: constants.formFieldConversionTypes.user,
      initial: [],
      options: clientCallbacks.users,
      filter: existingUserIds?.map((userId) => `-${userId}`),
      FormFieldProps: {
        ref: fieldRef,
        hiddenLabel: true,
        variant: 'outlined',
        size: 'smaller',
        multiple: true,
        fullWidth: true,
        getTagProps: (option) => {
          const avatar = `${utils.personName(option.firstName, option.lastName)} (${option.username})`;
          const color = utils.string2Color(avatar);
          return {
            icon: <Avatar color={color} size="tiny">{utils.avatarLabel(avatar)}</Avatar>
          };
        },
        renderOption: (option) => {
          const avatar = `${utils.personName(option.firstName, option.lastName)} (${option.username})`;
          const name = utils.personName(option.firstName, option.lastName);

          return <AvatarOption avatar={avatar}
                               label={name} />
        }
      }
    }];
  }, [clientCallbacks, existingUserIds]);

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    if (utils.isArray(values.users) && values.users.length > 0) {
      setSubmitting(true);
      utils.asPromise(onSubmit)(values.users)
        .then(() => {
          innerRef.current?.close?.();
        })
        .catch(() => {
          snackbar.show('Adding collaborator failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          actions.setSubmitting(false);
          setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const action = useMemo(() => ({
    label: 'Add',
    ButtonProps: {
      disabled: disabled || submitting
    },
    onClick: (e) => {
      formRef.current?.submit();
      e.preventDefault();
    }
  }), [disabled, submitting]);

  const handleChangeDirect = (e) => {
    if (e?.target?.name === 'users') {
      setDisabled(utils.isEmpty(e.target.value));
    }
  }

  const handleClose = (e, reason) => {
    if (disabled || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderAddButton = () => {
    return <ActionButton className="CollaboratorAddDialog-usersFormContent-button"
                         action={action}
                         showInactive={true} />
  };

  return <StyledCollaboratorAddDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title={title ? title : 'Add collaborators'} />
    <DialogContent className="CollaboratorAddDialog-content">
      <Box className="CollaboratorAddDialog-usersForm">
        <InlineForm ref={formRef}
                    className="CollaboratorAddDialog-usersForm-form"
                    autoFocus={true}
                    onSubmit={handleSubmit}
                    onChangeDirect={handleChangeDirect}
                    fields={fields}/>
        {renderAddButton()}
      </Box>
    </DialogContent>
  </StyledCollaboratorAddDialog>
});

CollaboratorAddDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  existingUserIds: PropTypes.array
};

CollaboratorAddDialog.defaultProps = {};

export default CollaboratorAddDialog;
