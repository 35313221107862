import React, {useMemo} from 'react'
import utils from 'helpers/utils';
import WeeklyEmailPage from 'components/pages/Dev/Email/WeeklyEmailPage/WeeklyEmailPage';
import ResetEmailPage from 'components/pages/Dev/Email/ResetEmailPage/ResetEmailPage';
import NewEmailPage from 'components/pages/Dev/Email/NewEmailPage/NewEmailPage';
import QuestionnaireEmailPage from 'components/pages/Dev/Email/QuestionnaireEmailPage/QuestionnaireEmailPage';
import DailyEmailPage from 'components/pages/Dev/Email/DailyEmailPage/DailyEmailPage';
import WelcomeEmailPage from 'components/pages/Dev/Email/WelcomeEmailPage/WelcomeEmailPage';
import ApplicantEmailPage from 'components/pages/Dev/Email/ApplicantEmailPage/ApplicantEmailPage';
import EmailBarWrapper from 'components/templates/Wrappers/Emails/EmailBarWrapper/EmailBarWrapper';

export default function useEmailRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <WelcomeEmailPage />,

        handle: {
          title: 'Welcome',
          auth: utils.createAuth({attribute: 'dev.email.welcome'})
        }
      },
      {
        path: 'welcome',
        element: <WelcomeEmailPage />,

        handle: {
          title: 'Welcome',
          auth: utils.createAuth({attribute: 'dev.email.welcome'})
        }
      },
      {
        path: 'reset',
        element: <ResetEmailPage />,

        handle: {
          title: 'Reset password',
          auth: utils.createAuth({attribute: 'dev.email.reset'})
        }
      },
      {
        path: 'new',
        element: <NewEmailPage />,

        handle: {
          title: 'New password',
          auth: utils.createAuth({attribute: 'dev.email.new'})
        }
      },
      {
        path: 'questionnaire',
        element: <QuestionnaireEmailPage />,

        handle: {
          title: 'Questionnaire',
          auth: utils.createAuth({attribute: 'dev.email.questionnaire'})
        }
      },
      {
        path: 'daily',
        element: <DailyEmailPage />,

        handle: {
          title: 'Daily',
          auth: utils.createAuth({attribute: 'dev.email.daily'})
        }
      },
      {
        path: 'weekly',
        element: <WeeklyEmailPage />,

        handle: {
          title: 'Weekly',
          auth: utils.createAuth({attribute: 'dev.email.weekly'})
        }
      },
      {
        path: 'applicant',
        element: <ApplicantEmailPage />,

        handle: {
          title: 'Applicant',
          auth: utils.createAuth({attribute: 'dev.email.applicant'})
        }
      }
    ];

    return {
      WrapperComponent: EmailBarWrapper,
      route: route
    };
  }, []);
};
