import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Slider from '@mui/material/Slider';
import {rgba} from 'polished';

const StyledSlider = styled(Slider)`
  .MuiSlider-thumb {
    border: 2px solid ${props => props.theme.property('palette.background.paper')};
    
    &.MuiSlider-thumbSizeMedium {
      ${props => props.theme.state2Selector('MuiSlider-thumb', null, ['hover', 'focus', 'focusVisible'])} {
        box-shadow: 0 0 0 ${props => props.theme.layout(4)} ${props => rgba(props.theme.property(`palette.${props.color}`)?.main, 0.16)};
      }
      &.Mui-active {
        box-shadow: 0 0 0 ${props => props.theme.layout(6)} ${props => rgba(props.theme.property(`palette.${props.color}`)?.main, 0.16)} !important;
      }
    }
  }

  .MuiSlider-markLabel {
    font-size: ${props => props.theme.fontSize(13)};
    top: ${props => props.theme.layout('3.5sp')};
  }
  
  &.Slider-hideThumbs {
    .MuiSlider-thumb {
      display: none;
    }
  }
  
  &.Slider-hideMarkLabels {
    margin-bottom: 0;
    .MuiSlider-markLabel {
      display: none;
    }
  }
  
  &.Slider-disabled {
    .MuiSlider-markLabel {
      color: ${props => props.theme.property('palette.action.disabled')} !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledSlider.propTypes = {
  theme: PropTypes.object
};

StyledSlider.defaultProps = {}

export default StyledSlider;
