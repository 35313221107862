import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItem from '@mui/material/ListItem';

const StyledListItem = styled(ListItem)`
  &.Skeleton {
    max-width: unset;
    transform: unset;
  }
  
  &.ListItem-density-densest {
    padding: 0;
  }
  
  &.ListItem-dragging {
    pointer-events: none;
    cursor: grabbing !important;
  }
  
  ${ComponentStyles}
`;

StyledListItem.propTypes = {
  theme: PropTypes.object
};

StyledListItem.defaultProps = {
}

export default StyledListItem;
