import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import StepLabel from '@mui/material/StepLabel';

const StyledStepLabel = styled(StepLabel)` 
  ${ComponentStyles}
`;

StyledStepLabel.propTypes = {
  theme: PropTypes.object
};

StyledStepLabel.defaultProps = {}

export default StyledStepLabel;
