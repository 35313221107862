import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledLinkTableCell from 'components/molecules/TableCells/LinkTableCell/LinkTableCell.styles';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import {withMemo} from 'helpers/wrapper';

const LinkTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    ActionLinkProps,
    ...innerProps
  } = useComponentProps(props, 'LinkTableCell');

  return <StyledLinkTableCell ref={ref} {...innerProps}>
    <ActionLink {...ActionLinkProps} />
  </StyledLinkTableCell>
}));

LinkTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  ActionLinkProps: PropTypes.object
};

LinkTableCell.defaultProps = {
};

export default LinkTableCell;


