import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntityScoreGraphProfileCardContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('2sp')};
  
  .AnalysisScoreInline {
    width: 100%;
    height: ${props => props.theme.layout(25)};
  }
  
  ${ComponentStyles}
`;

StyledEntityScoreGraphProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityScoreGraphProfileCardContent.defaultProps = {}

export default StyledEntityScoreGraphProfileCardContent;
