import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTableHeaderIcon from 'components/molecules/TableHeaders/TableHeaderIcon/TableHeaderIcon.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const TableHeaderIcon = React.forwardRef((props, ref) => {
  const {
    icon,
    tooltip,
    ...innerProps
  } = useComponentProps(props, 'TableHeaderIcon');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const handleRef = (element) => {
    innerRef.current = element;
    if (element?.parentElement) {
      element.parentElement.title = '';
    }
  }

  return <Tooltip title={tooltip} placement="top">
    <StyledTableHeaderIcon ref={handleRef} {...innerProps}>
      <Icon icon={icon} />
    </StyledTableHeaderIcon>
  </Tooltip>
});

TableHeaderIcon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tooltip: PropTypes.any,
  icon: PropTypes.any
};

TableHeaderIcon.defaultProps = {
};

export default TableHeaderIcon;
