import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledQuestionnaireEmailPage
  from 'components/pages/Dev/Email/QuestionnaireEmailPage/QuestionnaireEmailPage.styles';
import TableRow from 'components/atoms/Tables/TableRow/TableRow';
import TableCell from 'components/atoms/Tables/TableCell/TableCell';
import {P} from 'components/atoms/Text/Typography/Typography';
import Button from 'components/atoms/Buttons/Button/Button';
import Link from 'components/atoms/Links/Link/Link';
import {useClientComponent, useClientConfig} from 'components/organisms/Providers/ClientProvider/ClientProvider';
import ThemeProvider from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import utils from 'helpers/utils';

const path = utils.componentPath(import.meta.url);

const QuestionnaireEmailPage = (props) => {
  const innerProps = useComponentProps(props, 'QuestionnaireEmailPage');

  const clientConfig = useClientConfig(innerProps.$componentName);
  const component = useClientComponent(clientConfig, `${path}/${innerProps.$componentName}`, props);

  const renderEmail = () => {
    if (component) {
      return component;
    } else {
      return <StyledQuestionnaireEmailPage {...innerProps}
                                           config={clientConfig}
                                           preview="You have been invited to answer a questionnaire.">
        <TableRow>
          <TableCell colSpan={2}>
            <P>You have been invited to take part in a Catalist questionnaire. You can easily join via the link below.&nbsp;
              {'{{QUESTIONNAIRE_OPEN_START_END[The questionnaire will be open from {{QUESTIONNAIRE_START}} until {{QUESTIONNAIRE_END}}. ]}}'}
              {'{{QUESTIONNAIRE_OPEN_START[The questionnaire will be open from {{QUESTIONNAIRE_START}}. ]}}'}
              {'{{QUESTIONNAIRE_OPEN_END[The questionnaire will be open until {{QUESTIONNAIRE_END}}. ]}}'}
              Use this link to go to the questionnaire:</P>
          </TableCell>
        </TableRow>
        <TableRow className="QuestionnaireEmailPage-questionnaireButtonRow">
          <TableCell colSpan={2}>
            <Button component="a" href="{{QUESTIONNAIRE_LINK}}" variant="contained" size="medium">Open
              questionnaire</Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>
              Or cut and paste this text if the link doesn't work:<br/>
              <Link className="system" href="{{QUESTIONNAIRE_LINK}}">{'{{QUESTIONNAIRE_LINK}}'}</Link>
            </P>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            <P>If all went well, you can now fill in your answer(s). Are you still unsure, or do you still have
              questions?
              Please let us know.</P>
          </TableCell>
        </TableRow>
      </StyledQuestionnaireEmailPage>
    }
  }

  if (clientConfig?.theme) {
    return <ThemeProvider name={clientConfig?.theme.name} overrides={clientConfig?.theme?.overrides}>
      {renderEmail()}
    </ThemeProvider>
  } else {
    return renderEmail();
  }
};

QuestionnaireEmailPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

QuestionnaireEmailPage.defaultProps = {
};

export default QuestionnaireEmailPage;
