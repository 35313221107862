import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntitiesList
  from 'components/organisms/Lists/DatabaseEntitiesList/DatabaseEntitiesList.styles';

const DatabaseEntitiesList = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesList');

  return <StyledDatabaseEntitiesList ref={ref} {...innerProps} />
});

DatabaseEntitiesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesList.defaultProps = {
};

export default DatabaseEntitiesList;
