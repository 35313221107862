import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionServiceProfileCard
  from 'components/organisms/Cards/CollectionServiceProfileCard/CollectionServiceProfileCard.styles';

const CollectionServiceProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    service,
    collection,
    isDialog,
    renderedHeader,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'CollectionServiceProfileCard', {
    static: ['isReadOnly', 'isEditing', 'isDialog'],
    children: ['content']
  });

  const innerRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const isReadOnly = !card.card.editable;

  useLayoutEffect(() => {
    const editing = card.state.isEditing ?? card.state.card.isEditing ?? card.state.dialog.isEditing;
    setIsEditing(editing);
  }, [card.state.isEditing, card.state.card.isEditing, card.state.dialog.isEditing, card.state.card.submitSuccess]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isReadOnly: isReadOnly,
    isEditing: isEditing,
  });

  return <StyledCollectionServiceProfileCard ref={innerRef} {...innerProps}>
    {renderedHeader}
    {renderedContent}
  </StyledCollectionServiceProfileCard>
});

CollectionServiceProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  onHide: PropTypes.func,
  collection: PropTypes.object,
  service: PropTypes.object,
  fieldData: PropTypes.object,
  isDialog: PropTypes.bool,
  renderedHeader: PropTypes.any,
  renderedContent: PropTypes.any
};

CollectionServiceProfileCard.defaultProps = {
};

export default CollectionServiceProfileCard;
