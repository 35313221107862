import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Paper from 'components/atoms/Papers/Paper/Paper';

const StyledTinderBar = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.5)};
  column-gap: ${props => props.theme.layout('1.5sp')};
  border-radius: ${props => props.theme.radius(22)};
  background-color: ${props => props.theme.property('palette.background.paper')};

  ${ComponentStyles}
`;

StyledTinderBar.propTypes = {
  theme: PropTypes.object
};

StyledTinderBar.defaultProps = {}

export default StyledTinderBar;
