import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledInfoPaper from 'components/molecules/Papers/InfoPaper/InfoPaper.styles';
import utils from 'helpers/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from 'components/atoms/Layout/Box/Box';

const InfoPaper = withMemo(React.forwardRef((props, ref) => {
  const {
    icon,
    info,
    ...innerProps
  } = useComponentProps(props, 'InfoPaper', {
    styled: ['color']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledInfoPaper ref={ref} {...innerProps}>
    <Icon className="InfoPaper-icon"
          size="small"
          color={innerProps.$color} icon={icon ?? InfoOutlined}/>
    <Box className="InfoPaper-info">
      {utils.isReactElement(info) ? info :
        <Typography variant="caption" color="text.secondary">
          {info}
        </Typography>}
    </Box>
  </StyledInfoPaper>
}));

InfoPaper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  info: PropTypes.any,
  color: PropTypes.string
};

InfoPaper.defaultProps = {
  color: 'primary',
  elevation: 0
};

export default InfoPaper;
