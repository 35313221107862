import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledDealflowStatusGroupProfileCardContent = styled(Box)`
  &.DealflowStatusGroupProfileCardContent  {
    display: flex;
    gap: ${props => props.theme.layout('1sp')};
    width: 100%;
    
    .DealflowStatusGroupProfileCardContent  {
      &-content {
        display: flex;
        flex-direction: row;
        column-gap: ${props => props.theme.layout('1sp')};
        width: 100%;

        .StatusGroupCard {
          flex-grow: 1;
          width: 1px;
        }

        .FormControl {
          &.FormField-name-filterType {
            row-gap: ${props => props.theme.layout('1sp')};

            .FormLabel {
              align-self: center;
              margin-top: 0;  
            }
            
            .List {
              width: fit-content;
              .ListItem {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  &:not(.DealflowStatusGroupProfileCardContent-isEditing) {
    &.DealflowStatusGroupProfileCardContent-isAddNew {
      width: 100%;
      height: 100%;
      padding: 0;
      min-height: ${props => props.theme.layout(238)};
      max-height: ${props => props.theme.layout(238)};
      background-color: ${props => props.theme.property(`palette.primary.states.focus`)};

      .DealflowStatusGroupProfileCardContent-content {
        width: 100%;
        height: 100%;

        .Card {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
        }
      }
    }
  }

  &.DealflowStatusGroupProfileCardContent-isEditing  {
    .DealflowStatusGroupProfileCardContent {
      &-form {
        display: flex;
        flex-direction: column;
        
        .DialogHeader {
          .FormField-name-name {
            min-width: ${props => props.theme.layout(356)};

            .MuiFormHelperText-root:has(*) {
              display: flex;
              position: absolute;
              width: 100%;
              bottom: ${props => props.theme.layout(-14)};
              height: ${props => props.theme.layout(16)};

              > * {
                width: 1px;
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                p {
                  display: contents;
                }
              }
            }
          }
        }
        
        .DialogContent {
          flex-grow: 1;
          height: 1px;
          overflow: hidden;
        }
        
        &-content {
          height: 100%;  
          overflow-y: auto;
          row-gap: ${props => props.theme.layout('1sp')} !important;
          align-content: flex-start;

          padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          
          .FormControl {
            .MuiInputBase-root {
              background-color: ${props => props.theme.property('palette.background.paper')};
            }
          }

          .FormField-name-statuses {
            > .InputLabel, > .FormLabel {
              ${props => props.theme.font2Css('h6')};
              margin-bottom: calc(${props => props.theme.spacing(1)} - 1px);
            }
          }
        }
        
        &-context {
          height: 100%;
          overflow-y: auto;
          padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};

          .Form-fields {
            row-gap: ${props => props.theme.layout('0.5sp')};
          
            .Form-section {
              margin-bottom: ${props => props.theme.spacing(0.5)};
            }
            
            .FormControl-inlineLabel {
              .InputLabel, .FormLabel {
                ${props => props.theme.font2Css('subtitle2')};
              }
            }

            .FormField-name-color, .FormField-name-icon {
              margin-top: ${props => props.theme.spacing(0.5)};
              
              .IconButton-disabled {
                .Icon {
                  color: inherit;
                }
              }
            }

            .FormField-name-color, .FormField-name-icon {
              justify-content: space-between;

              .InputContainer {
                flex-grow: unset;
              }

              .InputLabel {
                white-space: nowrap;
                max-width: unset;
                min-width: ${props => props.theme.layout(80)};
              }
            }
          }
        }
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledDealflowStatusGroupProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledDealflowStatusGroupProfileCardContent.defaultProps = {}

export default StyledDealflowStatusGroupProfileCardContent;
