import styled from 'styled-components';
import Link from '@mui/material/Link';
import ComponentStyles from 'styles/mixins/Component.styles';
import PropTypes from 'prop-types';

const StyledLink = styled(Link)`
  outline: none;
  text-decoration: none;
  cursor: pointer;
  
  ${props => props.theme.state2Selector('Link', null, 'focus')} {
    text-decoration: underline;
  }
  
  ${props => props.theme.state2Selector('Link', null, 'disabled')} {
    color: ${props => props.theme.property('palette.action.disabled')};
    cursor: default;
    pointer-events: none;
  }
  
  ${ComponentStyles}
`;

StyledLink.propTypes = {
  theme: PropTypes.object
};

StyledLink.defaultProps = {
}

export default StyledLink;
