import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledEntityTaskSectionPanel
  from 'components/organisms/SectionPanels/EntityTaskSectionPanel/EntityTaskSectionPanel.styles';
import TaskProfileSectionPanelContent
  from 'components/organisms/SectionPanels/TaskProfileSectionPanelContent/TaskProfileSectionPanelContent';
import utils from 'helpers/utils';

const EntityTaskSectionPanel = React.forwardRef((props, ref) => {
  const {
    onClose,
    showProfile,
    ...innerProps
  } = useComponentProps(props, 'EntityTaskSectionPanel');

  const profileProvider = useProfile();
  const entity = profileProvider?.context?.data;
  const sectionDefinitions = profileProvider?.sectionDefinitions;

  const authorize = useAuthorize();

  const onCloseEvent = useEffectEvent(onClose);

  const sections = useMemo(() => {
    if (sectionDefinitions) {
      const sections = [];

      sectionDefinitions
        .sort((a, b) => a.position - b.position)
        .forEach((sectionDef) => {
          const canEditSection = (task) => {
            return !(sectionDef.readOnly ?? false) && authorize({...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, entity, task}});
          }

          if (sectionDef.name === 'profile') {
            sections.push({
              ...sectionDef,
              Content: ({data, isLoading}) => {
                const markDoneAction = useMemo(() => ({
                  auth: utils.createAuth({attribute: 'entity.task.markDone', meta: {task: data}})
                }), [data]);

                return <TaskProfileSectionPanelContent task={data}
                                                       closeOnShow={true}
                                                       isLoading={isLoading}
                                                       markDoneAction={markDoneAction}
                                                       onCanUpdate={canEditSection}
                                                       onClose={onCloseEvent}/>
              }
            });
          }
        });

      return sections;
    } else {
      return [];
    }
  }, [sectionDefinitions, authorize, entity, onCloseEvent]);

  return <StyledEntityTaskSectionPanel ref={ref} {...innerProps}
                                       sections={sections}/>
});

EntityTaskSectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityTaskSectionPanel.defaultProps = {};

export default EntityTaskSectionPanel;
