import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionEntityProfileBar
  from 'components/organisms/Bars/CollectionEntityProfileBar/CollectionEntityProfileBar.styles';

const CollectionEntityProfileBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityProfileBar');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntityProfileBar ref={ref} {...innerProps} />
});

CollectionEntityProfileBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityProfileBar.defaultProps = {
};

export default CollectionEntityProfileBar;
