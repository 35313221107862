import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';

const StyledTableBar = styled(HeaderBar)`
  .PageBar {
    &-title {
      flex-grow: 1;
      white-space: nowrap;
    }
    
    &-rightFields {
      width: unset;
      min-width: ${props => props.theme.layout(315)};

      .MuiFormControl-root {
        .MuiInputBase-root {
          &:not(${props => props.theme.state2Selector('MuiInputBase-root', null, ['active', 'hover', 'focus', 'focusVisible', 'selected'], '')}) {
            .MuiOutlinedInput-notchedOutline {
              border-color: ${props => props.theme.property('palette.divider')};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTableBar.propTypes = {
  theme: PropTypes.object
};

StyledTableBar.defaultProps = {
};

export default StyledTableBar;
