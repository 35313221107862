import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledMainLoader = styled(Box)`
  top: 0;
  position: fixed;
  
  z-index: ${props => props.theme.property('zIndex.loader')};
  
  width: ${props => props.theme.layout('100vw')};
  height: ${props => props.theme.layout('100vh')};
  
  opacity: 0.7;
  background-color: ${props => props.theme.property('palette.background.default')};
  
  ${ComponentStyles}
`;

StyledMainLoader.propTypes = {
  theme: PropTypes.object
};

StyledMainLoader.defaultProps = {
}

export default StyledMainLoader;
