import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledLinkTableCell = styled(BaseTableCell)`
  > * {
    white-space: nowrap;
    width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  
  ${ComponentStyles}
`;

StyledLinkTableCell.propTypes = {
  theme: PropTypes.object
};

StyledLinkTableCell.defaultProps = {
}

export default StyledLinkTableCell;
