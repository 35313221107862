import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionsTableBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionsTableBarWrapper/CollectionsTableBarWrapper.styles';
import CollectionsTableBar from 'components/organisms/Bars/CollectionsTableBar/CollectionsTableBar';

const CollectionsTableBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionsBarWrapper');

  return <StyledCollectionsTableBarWrapper ref={ref} {...innerProps}
                                           variant="sticky">
    {innerProps.children}
  </StyledCollectionsTableBarWrapper>
});

CollectionsTableBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsTableBarWrapper.defaultProps = {
  ToolbarComponent: CollectionsTableBar
};

export default CollectionsTableBarWrapper;
