import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledEntityMergeWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0;
    }

    &-step {
      .WizardContent {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};

        .FormField {
          row-gap: ${props => props.theme.layout('0.5sp')};

          .FormLabel, .InputLabel {
            ${props => props.theme.font2Css('body2')};
            transform: scale(1);
          }
        }
      }
      
      &:has(.ChooseEntityWizardContent) {
        height: 100% !important;
        overflow: hidden;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityMergeWizard.propTypes = {
  theme: PropTypes.object
};

StyledEntityMergeWizard.defaultProps = {}

export default StyledEntityMergeWizard;
