import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ChartTooltip from 'components/molecules/Tooltips/ChartTooltip/ChartTooltip';

const StyledPatentsTooltip = styled(ChartTooltip)`
  .PatentsTooltip {
    &-content {
      padding: ${props => props.theme.spacing(0.5)};
      min-width: ${props => props.theme.layout(200)};

      .Badge {
        .MuiBadge-badge {
          font-size: ${props => props.theme.fontSize(11)};
          height: ${props => props.theme.layout(16)};
        }
      }

      .Span {
        font-size: ${props => props.theme.fontSize(13)};
        margin-left: 0;
        margin-right: ${props => props.theme.layout('1sp')};
      }
      
      .Divider {
        margin-top: ${props => props.theme.spacing(0.75)};
        margin-bottom: ${props => props.theme.spacing(0.75)};
      }

      .title {
        display: flex;
        align-items: center;
        padding: ${props => props.theme.spacing(0.5)} 0;
        ${props => props.theme.font2Css('caption')};
        &:not(:has(.Icon, .Avatar)) {
          font-weight: 500;
        }
        
        .Icon {
          font-size: inherit;
          height: ${props => props.theme.layout(12)};
          width: ${props => props.theme.layout(12)};
          margin-right: ${props => props.theme.layout('0.5sp')};

          &.Avatar {
            font-size: ${props => props.theme.fontSize(13)};
            height: ${props => props.theme.layout(16)};
            width: ${props => props.theme.layout(16)};
          }
        }
        
        .Span {
          flex-grow: 1;
        }
      }
      
      .description {
        ${props => props.theme.font2Css('body2')};
      }
      
      .Divider {
        margin-left: ${props => props.theme.layout('-1sp')};
        margin-right: ${props => props.theme.layout('-1sp')};
      }
    }
  }

  ${ComponentStyles}
`;

StyledPatentsTooltip.propTypes = {
  theme: PropTypes.object
};

StyledPatentsTooltip.defaultProps = {
}

export default StyledPatentsTooltip;
