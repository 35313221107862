import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityBrowserBarWrapper/DatabaseEntityBrowserBarWrapper.styles';
import DatabaseEntityBrowserBar from 'components/organisms/Bars/DatabaseEntityBrowserBar/DatabaseEntityBrowserBar';

const DatabaseEntityBrowserBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityBrowserBarWrapper');

  return <StyledDatabaseEntityBrowserBarWrapper ref={ref} {...innerProps}
                                                variant="sticky">
    {innerProps.children}
  </StyledDatabaseEntityBrowserBarWrapper>
});

DatabaseEntityBrowserBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityBrowserBarWrapper.defaultProps = {
  ToolbarComponent: DatabaseEntityBrowserBar
};

export default DatabaseEntityBrowserBarWrapper;
