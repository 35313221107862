import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';
import {rgba} from 'polished';

const StyledProfileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  &:not(.ProfileCard-Card) {
    &:not(:has(.ProfileCard-content)) {
      &.ProfileCard-emptyText {
        .ProfileCard-empty {
          display: flex;
        }
      }
      &:not(.ProfileCard-emptyText) {
        display: none;
      }
    }
  }
  &:has(.ProfileCardContent-empty) {
    display: none;
  }
  
  .ProfileCard {
    &-header {
      .ProfileCard-action {
        visibility: hidden;
        margin-right: ${props => props.theme.layout(4)};

        &.Skeleton {
          margin-right: 0;
        }
      }
    }
    
    &-content {
      width: 100%;
    }

    &-empty {
      height: 100%;
      width: 100%;
      display: none;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: 0 0 ${props => props.theme.spacing(3)} 0;
      }
    }
  }
  
  &.ProfileCard {
    &:not(:has(.Skeleton)):hover {
      .ProfileCard-action {
        visibility: visible;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledProfileCard.propTypes = {
  theme: PropTypes.object
};

StyledProfileCard.defaultProps = {
}

export default StyledProfileCard;
