import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledUserTableCellEdit = styled(DropdownTableCellEdit)` 
  ${ComponentStyles}
`;

StyledUserTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledUserTableCellEdit.defaultProps = {}

export default StyledUserTableCellEdit;
