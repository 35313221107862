import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import utils from 'helpers/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import CallMerge from '@mui/icons-material/CallMerge';
import StyledCollectionMergeDialog
  from 'components/organisms/Dialogs/CollectionMergeDialog/CollectionMergeDialog.styles';
import constants from 'helpers/constants';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useEntityCallbacks} from 'services/entity/entity.utils';
import {useSourceList} from 'services/source/source.hooks';
import {useCollectionGet} from 'services/collection/collection.hooks';
import Box from 'components/atoms/Layout/Box/Box';

const CollectionMergeDialog = React.forwardRef((props, ref) => {
  const {
    collection,
    onChange,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'CollectionMergeDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [mergedCollectionId, setMergedCollectionId] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  const callbacks = useEntityCallbacks();

  const mergedCollection = useCollectionGet({collectionId: mergedCollectionId}, {enabled: +mergedCollectionId > 0}).data;
  const sources = useMemo(() => {
    return ((collection?.projectSources ?? []).map((s) => ({...s, pot: true})))
      .concat((mergedCollection?.projectSources ?? [])
        .filter((s) => +s.sourceId < 0 || !(collection?.projectSources ?? []).find((st) => +st.sourceId === +s.sourceId)));
  }, [collection?.projectSources, mergedCollection?.projectSources]);

  const sourceList = useSourceList({
    page: 0,
    pageSize: sources.length ?? 0,
    filter: [
      {id: 'sourceId', value: sources.map((s) => s.sourceId)},
    ]
  }, {
    enabled: Boolean(mergedCollection) && sources.length > 0
  });

  const sourceOptions = useMemo(() => {
    return sources.map((s) => {
      const sourceCollectionId = s.pot ? collection.collectionId : mergedCollection?.collectionId;
      if (+s.sourceId <= 0) {
        return {
          source: s,
          label: s.type === constants.sources.types.suggestions ?
            constants.data.lookup('sourceTypes', constants.sources.types.suggestions).label : s.name,
          value: `${sourceCollectionId}_${s.sourceId}`,
          color: s.pot ? 'primary' : 'info'
        }
      } else {
        const found = sourceList.data?.find((sl) => +sl.sourceId === +s.sourceId);
        if (found) {
          return {
            source: {...s, ...found},
            label: found.name,
            value: `${sourceCollectionId}_${s.sourceId}`,
            color: s.pot ? 'primary' : 'info'
          }
        }
      }

      return null;
    }).filter((_) => (_));
  }, [sources, collection.collectionId, mergedCollection?.collectionId, sourceList.data]);

  const tagGroupOptions = useMemo(() => {
    return ((collection?.tagGroups ?? []).map((tg) => ({...tg, pot: true})).sort((a, b) => +a.pos - +b.pos))
      .concat((mergedCollection?.tagGroups ?? []).map((tg) => tg).sort((a, b) => +a.pos - +b.pos))
      .map((tg) => ({
        tagGroup: tg,
        label: tg.name,
        value: tg.groupId,
        color: tg.pot ? 'primary' : 'info'
      }));
  }, [collection.tagGroups, mergedCollection?.tagGroups]);

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'mergedCollection',
      label: 'Choose a collection',
      placeholder: 'Choose a collection',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.collection,
      filter: [`-${collection.collectionId}`],
      options: callbacks.collections,
      required: true,
      FormFieldProps: {
        multiple: false
      }
    });

    if (mergedCollection && tagGroupOptions.length > 0) {
      fields.push({
        name: 'tagGroups',
        label: 'Select collection tags',
        type: constants.formFieldTypes.cloud,
        validation: constants.formFieldValidationTypes.list,
        initial: tagGroupOptions,
        options: tagGroupOptions,
        FormFieldProps: {
          showAutocomplete: false,
          emptyText: 'No collection tags found.'
        }
      });
    }

    if (mergedCollection && sourceOptions.length > 0) {
      fields.push({
        name: 'sources',
        label: 'Select sources',
        type: constants.formFieldTypes.cloud,
        validation: constants.formFieldValidationTypes.list,
        initial: sourceOptions,
        options: sourceOptions,
        FormFieldProps: {
          showAutocomplete: false,
          emptyText: 'No sources found.'
        }
      });
    }

    return fields;
  }, [collection, mergedCollection, sourceOptions, tagGroupOptions, callbacks.collections]);

  const handleChange = (field, value) => {
    onChange?.(value);
    if (field.name === 'mergedCollection') {
      setMergedCollectionId(+value.collection?.collectionId);
    }
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    const data = {
      targetCollectionId: collection?.collectionId,
      mergedCollectionId: mergedCollectionId,
      deleteMergedGroupIds: tagGroupOptions.filter((tg) => !tg.tagGroup.pot && !values['tagGroups']?.find((v) => +v.value === +tg.value))
        .map((tg) => +tg.value),
      deleteTargetGroupIds: tagGroupOptions.filter((tg) => tg.tagGroup.pot && !values['tagGroups']?.find((v) => +v.value === +tg.value))
        .map((tg) => +tg.value),
      deleteMergedSourceIds: sourceOptions.filter((s) => !s.source.pot && !values['sources']?.find((v) => v.value === s.value))
        .map((s) => +s.source.sourceId),
      deleteTargetSourceIds: sourceOptions.filter((s) => s.source.pot && !values['sources']?.find((v) => v.value === s.value))
        .map((s) => +s.source.sourceId)
    };

    utils.asPromise(onSubmit)(data)
      .then(() => {
        innerProps.onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Merge collection failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderExplanation = () => {
    if (mergedCollection) {
      return <Box className="CollectionMergeDialog-explanation">
        <Typography variant="subtitle2"><Span className="name">{mergedCollection?.name}</Span> will be merged into <Span
          className="name">{collection?.name}</Span>. If data exists for both collections, the target collection
          will be prioritised.</Typography>
        <Typography variant="body2" color="text.secondary"><Span
          className="name">{mergedCollection?.name}</Span> will be permanently removed from your database after merging.</Typography>
      </Box>
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              color="success"
              children={'Merge collection'}
              startIcon={<Icon icon={CallMerge}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledCollectionMergeDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Merge collection" />
    <DialogContent>
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
      {renderExplanation()}
    </DialogContent>
    <DialogFooter className="CollectionMergeDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledCollectionMergeDialog>
});

CollectionMergeDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

CollectionMergeDialog.defaultProps = {};

export default CollectionMergeDialog;
