import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledCollectionCategoriesSectionPanelContent = styled(ListWrapper)`
  &.CollectionCategoriesSectionPanelContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    .Profile {
      flex-grow: 1;
      min-height: unset;
      padding-top: ${props => props.theme.spacing(1)};
      padding-bottom: ${props => props.theme.spacing(2)};
    }

    .ListWrapper-content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionCategoriesSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionCategoriesSectionPanelContent.defaultProps = {}

export default StyledCollectionCategoriesSectionPanelContent;
