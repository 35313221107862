import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import StyledEntitiesList from 'components/organisms/Lists/EntitiesList/EntitiesList.styles';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import ListHeader from 'components/molecules/Headers/ListHeader/ListHeader';
import DataList from 'components/organisms/Lists/DataList/DataList';
import {useEntityCsi, useEntityRelevancy} from 'services/entity/entity.utils';
import Component from 'components/organisms/Utils/Component/Component';
import utils from 'helpers/utils';

const EntitiesList = React.forwardRef((props, ref) => {
  const {
    columns,
    showHeader,
    selectFirst,
    filter,
    Card,
    onClick,
    EntityCardProps,
    DataListProps,
    ...innerProps
  } = useComponentProps(props, 'EntitiesList');

  const tableProvider = useTable();
  const context = tableProvider.context?.data;
  const entity = tableProvider.data?.data;
  const selectedEntity = tableProvider.selected();

  const columnsMemo = useMemo(() => {
    return columns ?? [
      {
        header: 'Company'
      },
      {
        header: 'Dealflow'
      }
    ];
  }, [columns]);

  const onCompareEvent = useEffectEvent(tableProvider.onCompare);
  const dataMemo = useMemo(() => {
    return tableProvider.list?.data?.filter((itmA) => !filter || !filter.find((itmB) => {
      return onCompareEvent?.(itmA, itmB);
    }));
  }, [filter, tableProvider.list?.data, onCompareEvent]);

  const EntityCardDefault = ({entity, ...props}) => {
    const csi = useEntityCsi(entity);
    const relevancy = useEntityRelevancy(entity, context);

    return <EntityCard entity={entity}
                       csi={csi}
                       relevancy={relevancy}
                       {...props}/>
  };

  const renderHeader = () => {
    return <ListHeader columns={columnsMemo} />
  }

  const CardMemo = useEffectItem(Card ?? EntityCardDefault);
  const renderCard = (entity, state) => {
    return <Component Original={CardMemo}
                      entity={entity}
                      selected={state.selected}
                      isLoading={state.isLoading}
                      activateSelected={true}
                      onClick={onClick}
                      {...EntityCardProps} />
  };

  // force initialise the list if needed
  const onClickEvent = useEffectEvent(onClick);
  useEffect(() => {
    if (selectFirst && selectedEntity && !entity) {
      onClickEvent?.(utils.createEvent('click'), selectedEntity);
    }
  }, [entity, selectedEntity, selectFirst, onClickEvent]);

  return <StyledEntitiesList ref={ref} {...innerProps}
                             header={showHeader ? renderHeader() : null}>
    <DataList className="EntitiesList-list"
              data={dataMemo}
              dataKey={tableProvider.dataKey}
              isItemEqual={tableProvider.onCompare}
              renderItem={renderCard}
              count={tableProvider.list?.meta?.resultsCount}
              selected={selectedEntity}
              onFetchMore={tableProvider.list?.query?.fetchNextPage}
              isLoading={tableProvider.list?.status?.isLoading}
              showProgressBar={tableProvider.list?.status?.isLoadingNext}
              debounce={true}
              selectionEnabled={true}
              ListProps={{
                virtualize: true
              }}
              {...DataListProps}/>
  </StyledEntitiesList>
});

EntitiesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  columns: PropTypes.array,
  showHeader: PropTypes.bool,
  selectFirst: PropTypes.bool,
  filter: PropTypes.array,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  EntityCardProps: PropTypes.object,
  DataListProps: PropTypes.object
};

EntitiesList.defaultProps = {
  showHeader: true
};

export default EntitiesList;
