import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import NavLink from 'components/atoms/Links/NavLink/NavLink';

const StyledActionLink = styled(NavLink)`
  ${ComponentStyles}
`;

StyledActionLink.propTypes = {
  theme: PropTypes.object
};

StyledActionLink.defaultProps = {
}

export default StyledActionLink;
