import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard';

const StyledPeriodUpdateDashboardCard = styled(DashboardCard)`
  &.PeriodUpdateDashboardCard {
    .DashboardCard-header {
      padding-bottom: 0;
    }
    
    .List {
      .ListItem:not(:first-child) {
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledPeriodUpdateDashboardCard.propTypes = {
  theme: PropTypes.object
};

StyledPeriodUpdateDashboardCard.defaultProps = {}

export default StyledPeriodUpdateDashboardCard;
