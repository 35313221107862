import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';

const StyledDatabaseEntityProfileBarWrapper = styled(ToolbarWrapper)`
  ${ComponentStyles}
`;

StyledDatabaseEntityProfileBarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDatabaseEntityProfileBarWrapper.defaultProps = {
};

export default StyledDatabaseEntityProfileBarWrapper;
