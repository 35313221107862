import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTaskProfileSectionPanelContent
  from 'components/organisms/SectionPanels/TaskProfileSectionPanelContent/TaskProfileSectionPanelContent.styles';
import {useTaskCompanyAction, useTaskResponsibility} from 'services/comment/comment.utils';
import constants from 'helpers/constants';
import {useAuthUserId} from 'services/auth/auth.utils';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import TaskProfile from 'components/organisms/Profiles/TaskProfile/TaskProfile';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {Span} from 'components/atoms/Text/Typography/Typography';
import SectionPanelFooter from 'components/molecules/SectionPanels/SectionPanelFooter/SectionPanelFooter';

const TaskProfileSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    task,
    onCanUpdate,
    onClose,
    markDoneAction,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'TaskProfileSectionPanelContent');

  const [error, setError] = useState(null);

  const userId = useAuthUserId();
  const profileProvider = useProfile();
  const toggleDoneEvent = useEffectEvent(profileProvider.updaters?.toggleDone);

  const [isResponsible, isDone] = useTaskResponsibility(task, userId);
  const [done, toggleDoneOptimistic] = useOptimistic(isDone, toggleDoneEvent);

  const taskAction = useTaskCompanyAction(task);

  const onCloseEvent = useEffectEvent(onClose);
  const buttonActions = useMemo(() => {
    const actions = [];
    if (isResponsible && (!task?.actionItem?.type || task?.actionItem?.type === constants.task.types.other)) {
      actions.push({
        auth: utils.createAuth({attribute: 'task.markDone'}),
        label: !done ? 'Mark as done' : 'Mark as not done',
        onClick: () => {
          setError(null);
          toggleDoneOptimistic?.(!done, task, !done)
            .catch(() => {
              setError('Marking task failed');
            });
        },
        ActionButtonProps: {
          color: 'success'
        },
        ...markDoneAction
      });
    }

    actions.push({
      ...taskAction,
      label: `Show compan${task?.on?.ids?.length > 1 ? 'ies' : 'y'}`,
      icon: ChevronRight,
      iconPosition: 'end',
      onClick: (e) => {
        onCloseEvent?.(e, 'closeButtonClick');
      }
    });

    return actions;
  }, [task, markDoneAction, isResponsible, done, toggleDoneOptimistic, taskAction, onCloseEvent]);

  const renderFooter = () => {
    if (buttonActions?.length > 0 || error) {
      return <SectionPanelFooter className="TaskProfileSectionPanelContent-footer"
                                 variant="split"
                                 buttons={buttonActions.map?.((action, idx) => {
                                   return <ActionButton key={idx} action={action} isLoading={isLoading}/>
                                 })}
                                 info={error ? <Span color="error">{error}</Span> : null}/>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTaskProfileSectionPanelContent ref={ref} {...innerProps} footer={renderFooter()}>
    <TaskProfile gap={8} columns={1}
                 onCanUpdate={onCanUpdate}
                 onClose={(e) => onCloseEvent?.(e, 'closeButtonClick')}/>
  </StyledTaskProfileSectionPanelContent>
});

TaskProfileSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  task: PropTypes.object,
  onCanUpdate: PropTypes.func,
  onClose: PropTypes.func,
  markDoneAction: PropTypes.object,
  isLoading: PropTypes.bool
};

TaskProfileSectionPanelContent.defaultProps = {};

export default TaskProfileSectionPanelContent;
