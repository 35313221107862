import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledKanbanColumnsDialog from 'components/organisms/Dialogs/KanbanColumnsDialog/KanbanColumnsDialog.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';

const KanbanColumnsDialog = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'KanbanColumnsDialog');

  const tableProvider = useTable();

  const panelDefinitions = tableProvider.panelDefinitions;
  const panelVisibility = tableProvider.kanbanState.panelVisibility;

  const kanbanColumns = useMemo(() => {
    const columns = [];
    if (panelDefinitions) {
      panelDefinitions.forEach((panelDef) => {
        const column = {
          ...panelDef,
          id: panelDef.name,
          label: panelDef.title,
          position: panelDef.position,
          visible: panelVisibility[panelDef.name] ?? true
        }

        columns.push(column);
      })

      return columns;
    } else {
      return null;
    }
  }, [panelDefinitions, panelVisibility]);

  const handleSubmit = (columns) => {
    const columnsVisibility = kanbanColumns.reduce((o, column) => {
      o[column.id] = Boolean(columns.find((c) => c.id === column.id));
      return o;
    }, {});

    tableProvider.kanbanState?.setPanelVisibility((current) => ({
      ...current,
      ...columnsVisibility
    }));

    tableProvider.closeColumns?.();
  }

  const handleClose = () => {
    tableProvider.closeColumns?.();
  }

  return <StyledKanbanColumnsDialog ref={ref} {...innerProps}
                                    columns={kanbanColumns}
                                    onSubmit={handleSubmit}
                                    onClose={handleClose}/>
});

KanbanColumnsDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

KanbanColumnsDialog.defaultProps = {};

export default KanbanColumnsDialog;
