import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledBaseTableCellEdit = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: inherit;
  overflow: hidden;

  && {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }
  
  &.BaseTableCellEdit-popper {
    max-height: inherit;
    max-width: inherit;
  }

  ${ComponentStyles}
`;

StyledBaseTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledBaseTableCellEdit.defaultProps = {
}

export default StyledBaseTableCellEdit;
