import {useClientCurrencyList} from 'services/client/currency/currency.hooks';
import utils from 'helpers/utils';
import {useMemo} from 'react';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

export function useClientCurrencyRates () {
  const client = useAuthClient();

  const currencyList = useClientCurrencyList({
    clientId: client?.clientId,
    search: '1'
  }, {
    enabled: utils.isDefined(client?.clientId) && Boolean(client?.props?.currency)
  });

  return currencyList.data;
}

export function useClientCurrencyConversion (original) {
  const rates = useClientCurrencyRates();

  return useMemo(() => {
    if (!utils.isEmpty(original)) {
      return (rates ?? []).map((r) => ({
        ...r,
        original,
        converted: +original * +r.converted
      }))
    } else {
      return [];
    }
  }, [rates, original]);
}
