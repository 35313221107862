import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Stepper from '@mui/material/Stepper';

const StyledStepper = styled(Stepper)` 
  &:not(.Stepper-connectors) {
    .MuiStepConnector-root {
      display: none;
    }
    
    .StepContent {
      border-left: unset;
    }
  }
  
  ${ComponentStyles}
`;

StyledStepper.propTypes = {
  theme: PropTypes.object
};

StyledStepper.defaultProps = {}

export default StyledStepper;
