import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesTableColumns from 'components/organisms/TableColumns/EntitiesTableColumns/EntitiesTableColumns';

const StyledDealflowEntitiesTableColumns = styled(EntitiesTableColumns)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesTableColumns.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesTableColumns.defaultProps = {}

export default StyledDealflowEntitiesTableColumns;
