import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Paper from 'components/atoms/Papers/Paper/Paper';
import StyledTimelineCard from 'components/molecules/Cards/TimelineCard/TimelineCard.styles';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import FormField from 'components/organisms/Fields/FormField/FormField';
import constants from 'helpers/constants';
import Chip from 'components/atoms/Chips/Chip/Chip';
import DealflowStatusText from 'components/molecules/Text/DealflowStatusText/DealflowStatusText';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import {useTimelineLocation} from 'services/client/timeline/timeline.utils';
import {useClientCallbacks} from 'services/client/client.utils';
import Box from 'components/atoms/Layout/Box/Box';
import Visibility from '@mui/icons-material/Visibility';
import {useCommentPatch, useTaskCompanyAction} from 'services/comment/comment.utils';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Download from '@mui/icons-material/Download';
import {useExportDownloadGet} from 'services/export/export.hooks';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useCommentDelete} from 'services/comment/comment.hooks';
import {useEntityCommentDelete} from 'services/entity/comment/comment.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import CommentDialog from 'components/organisms/Dialogs/CommentDialog/CommentDialog';
import {useEntityCommentPatch} from 'services/entity/comment/comment.utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import Link from 'components/atoms/Links/Link/Link';

const TimelineCard = React.forwardRef((props, ref) => {
  const {
    item,
    onEdit,
    variant,
    isEntity,
    isLoading,
    AvatarProps,
    ...innerProps
  } = useComponentProps({...props, variant: (() => {
    return props.item?.timelineType;
  })()}, 'TimelineCard', {
    static: ['clampText'],
    children: ['avatar', 'content']
  });

  const contentRef = useRef(null);

  const name = !item?.user ? 'Unknown author' : (
    item?.user?.type === constants.user.types.agent ? 'System' :
    `${utils.personName(item?.user?.firstName, item?.user?.lastName)} (${item?.user?.username})`
  );
  const firstName = !item?.user ? 'Unknown' : (
    item?.user?.type === constants.user.types.agent ? 'System' : item?.user?.firstName
  );

  const profileProvider = useProfile();
  const timelineLocation = useTimelineLocation(item);

  const avatarLabel = utils.avatarLabel(name);
  const avatarBgColor = utils.string2Color(name);

  const taskAction = useTaskCompanyAction(item, true);
  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();

  const clientCallbacks = useClientCallbacks();
  const itemCount = utils.formatNumber(item?.count ?? 0);

  const contentBox = useBbox(() => contentRef.current?.querySelector('.TextField-readOnly'), ['clamped']);

  const mentions = useMemo(() => [{
    trigger: '@',
    data: (search, callback) => clientCallbacks.members({search, callback})
  }], [clientCallbacks]);

  const patchComment = useCommentPatch();
  const patchEntityComment = useEntityCommentPatch();
  const deleteComment = useCommentDelete();
  const deleteEntityComment = useEntityCommentDelete();

  const isClampable = Boolean(item.recordType === 'evidence');
  const isOverviewItem = !isEntity && (item.entity || item.count > 1);

  const [clampText, setClampText] = useState(isClampable);
  const [clampActive, setClampActive] = useState(false);
  const [download, setDownload] = useState({});
  const file = useExportDownloadGet({taskId: download?.taskId}, {
    ...constants.queryOptions.runOnceFresh,
    enabled: +download?.taskId > 0
  });

  useEffect(() => {
    if (file.status.isSettled) {
      if (file?.data?.filename && file?.data?.downloadUrl) {
        utils.fileDownload(file.data.filename, file.data.downloadUrl);
      }
      setDownload(null);
    }
  }, [file?.data?.filename, file?.data?.downloadUrl, file.status.isSettled]);

  useEffect(() => {
    if (clampText) {
      setClampActive(contentBox?.clamped);
    }
  }, [contentBox?.clamped, clampText]);

  const openTaskEvent = useEffectEvent(profileProvider.openTask);
  const onEditEvent = useEffectEvent(onEdit);
  const showAction = useMemo(() => ({
    auth: item.timelineType !== 'task' ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'task.read'}),
    icon: Visibility,
    tooltip: 'View task',
    onClick: (e) => {
      openTaskEvent?.(item.commentId);
      onEditEvent?.(e, item, true);
    }
  }), [item, onEditEvent, openTaskEvent]);

  const editAction = useMemo(() => ({
    icon: Edit,
    tooltip: 'Edit comment',
    auth: item.timelineType !== 'comment' ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'comment.update', meta: {comment: item}}),
    onClick: (e) => {
      const handleSubmit = (text) => {
        const comment = {commentId: item.commentId, text: item.text};

        return (isEntity ? patchEntityComment({entityId: item.relationId}, comment, {text}) :
          patchComment(comment, {text}));
      }

      const handleClose = (e) => {
        onEditEvent?.(e, item, false);
        dialogControl.hide();
      }

      dialogControl.show(<CommentDialog text={item.text}
                                        onSubmit={handleSubmit} />, true, handleClose);

      onEditEvent?.(e, item, true);
      e.preventDefault();
    }
  }), [isEntity, item, patchComment, patchEntityComment, onEditEvent, dialogControl]);

  const deleteAction = useMemo(() => ({
    icon: Delete,
    tooltip: 'Remove comment',
    color: 'error',
    auth: item.timelineType !== 'comment' ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'comment.delete', meta: {comment: item}}),
    onClick: (e) => {
      const handleConfirm = () => {
        return (isEntity ? deleteEntityComment.mutation : deleteComment.mutation)
          .mutateAsync({commentId: item.commentId})
          .catch(() => {
            snackbar.show('Removing comment failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          });
      }

      const handleClose = (e) => {
        onEditEvent?.(e, item, false);
        dialogControl.hide();
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this comment?"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove comment',
                                          color: 'error'
                                        }}/>, true, handleClose);

      onEditEvent?.(e, item, true);
      e.preventDefault();
    }
  }), [item, isEntity, onEditEvent, deleteComment.mutation, deleteEntityComment.mutation, dialogControl, snackbar]);

  const renderSubjectLink = (footer) => {
    if (item) {
      if (footer) {
        if (['comment', 'evidence', 'download', 'upload'].includes(item.recordType)) {
          if (!isEntity && item.entity && !['task'].includes(item.timelineType)) {
            if (timelineLocation.value === constants.timeline.locations.collection) {
              return <ActionLink action={{
                label: item.entity.name,
                navigation: {
                  to: `/collections/${item.collection.collectionId}/entities/${item.entity.entityId}?custom=entity:${item.entity.entityId}`,
                  resetSearchParams: true
                }
              }}/>
            } else if (timelineLocation.value === constants.timeline.locations.dealflow) {
              return <ActionLink action={{
                label: item.entity.name,
                navigation: {
                  to: `/dealflow/${item.entity.entityId}?custom=entity:${item.entity.entityId}`,
                  resetSearchParams: true
                }
              }}/>
            } else {
              return <ActionLink action={{
                label: item.entity.name,
                navigation: {
                  to: `/database/${item.entity.redirectStackId}?custom=entity:${item.entity.entityId}`,
                  resetSearchParams: true
                }
              }}/>
            }
          } else if (timelineLocation.value === constants.timeline.locations.collection) {
            return <ActionLink action={{
              label: item.collection.name,
              navigation: {
                to: `/collections/${item.collection.collectionId}/entities`,
                resetSearchParams: true
              }
            }}/>
          } else {
            if (['download', 'upload'].includes(item.recordType)) {
              return <ActionLink action={{
                label: 'Dealflow',
                navigation: {
                  to: `/Dealflow`,
                  resetSearchParams: true
                }
              }}/>
            } else if (['task'].includes(item.timelineType)) {
              if (timelineLocation.value === constants.timeline.locations.dealflow) {
                return <ActionLink action={{
                  label: 'Dealflow',
                  navigation: {
                    to: `/dealflow`,
                    resetSearchParams: true
                  }
                }}/>
              } else if (timelineLocation.value === constants.timeline.locations.database) {
                return <ActionLink action={{
                  label: 'Database',
                  navigation: {
                    to: `/database`,
                    resetSearchParams: true
                  }
                }}/>
              }
            }
          }
        } else if (item.recordType === 'timeline') {
          if (isOverviewItem) { // overview
            if (timelineLocation.value === constants.timeline.locations.collection) {
              return <ActionLink action={{
                label: item.collection.name,
                navigation: {
                  to: `/collections/${item.collection.collectionId}/entities`,
                  resetSearchParams: true
                }
              }}/>
            } else if (item.timelineType === 'status' || timelineLocation.value === constants.timeline.locations.dealflow) {
              return <ActionLink action={{
                label: 'Dealflow',
                navigation: {
                  to: `/dealflow`,
                  resetSearchParams: true
                }
              }}/>
            } else {
              return <ActionLink action={{
                label: 'Database',
                navigation: {
                  to: `/database`,
                  resetSearchParams: true
                }
              }}/>
            }
          } else if (item.timelineType === 'csi') {
            if (timelineLocation.value === constants.timeline.locations.collection) {
              return <ActionLink action={{
                label: item.collection.name,
                navigation: {
                  to: `/collections/${item.collection.collectionId}/entities`,
                  resetSearchParams: true
                }
              }}/>
            } else if (utils.isDefined(item.extraInfo?.newRelevancy)) {
              if (timelineLocation.value === constants.timeline.locations.dealflow) {
                return <ActionLink action={{
                  label: 'Dealflow',
                  navigation: {
                    to: `/dealflow`,
                    resetSearchParams: true
                  }
                }}/>
              } else {
                return <ActionLink action={{
                  label: 'Database',
                  navigation: {
                    to: `/database`,
                    resetSearchParams: true
                  }
                }}/>
              }
            }
          }
        } else if (timelineLocation.value === constants.timeline.locations.collection) {
          return <ActionLink action={{
            label: item.collection.name,
            navigation: {
              to: `/collections/${item.collection.collectionId}/entities`,
              resetSearchParams: true
            }
          }}/>
        } else if (item.recordType === 'database') {
          return <ActionLink action={{
            label: 'Database',
            navigation: {
              to: `/database`,
              resetSearchParams: true
            }
          }}/>
        }
      } else {
        // inline link
        if (['timeline', 'collection', 'database'].includes(item.recordType)) {
          if (isOverviewItem) {
            if (!isEntity && item.entity) {
              if (timelineLocation.value === constants.timeline.locations.collection) {
                return <ActionLink action={{
                  label: item.entity.name,
                  navigation: {
                    to: `/collections/${item.collection.collectionId}/entities/${item.entity.entityId}?custom=entity:${item.entity.entityId}`,
                    resetSearchParams: true
                  }
                }}/>
              } else if (item.recordType !== 'database' && timelineLocation.value === constants.timeline.locations.dealflow) {
                return <ActionLink action={{
                  label: item.entity.name,
                  navigation: {
                    to: `/dealflow/${item.entity.entityId}?custom=entity:${item.entity.entityId}`,
                    resetSearchParams: true
                  }
                }}/>
              } else {
                return <ActionLink action={{
                  label: item.entity.name,
                  navigation: {
                    to: `/database/${item.entity.redirectStackId}?custom=entity:${item.entity.entityId}`,
                    resetSearchParams: true
                  }
                }}/>
              }
            } else if (timelineLocation.value === constants.timeline.locations.collection) {
              return <ActionLink action={{
                label: item.collection.name,
                navigation: {
                  to: `/collections/${item.collection.collectionId}/entities`,
                  resetSearchParams: true
                }
              }}/>
            } else if (timelineLocation.value === constants.timeline.locations.dealflow) {
              return <ActionLink action={{
                label: 'Dealflow',
                navigation: {
                  to: `/dealflow`,
                  resetSearchParams: true
                }
              }}/>
            } else if (timelineLocation.value === constants.timeline.locations.database) {
              return <ActionLink action={{
                label: 'Database',
                navigation: {
                  to: `/database`,
                  resetSearchParams: true
                }
              }}/>
            }
          }
        }
      }
    }
  }

  const renderContent = () => {
    if (item) {
      const subject = renderSubjectLink(false);
      if (item.recordType === 'comment' || item.recordType === 'evidence') {
        if (item.timelineType === 'task') {
          return <Box className="title taskTitle">
            <Typography variant="body2">
              <FormField field={{
                            name: `${item.timelineId}_title`,
                            type: constants.formFieldTypes.markdown
                         }}
                         mentions={mentions}
                         value={item.actionItem?.title ?? 'Task'}
                         fullWidth={false}
                         readOnly={true}
                         hiddenHelperText={true}
                         hiddenLabel={true}
                         hiddenPlaceholder={true}/><React.Fragment> for <ActionLink action={taskAction} /></React.Fragment>
            </Typography>
            <ActionIconButton density="normal"
                              size="smaller"
                              variant="transparent"
                              showInactive={true}
                              IconProps={{
                                size: 'smaller'
                              }}
                              action={showAction} />
          </Box>
        } else if (item.recordType === 'evidence') {
          return <Typography as="div" variant="body2">
            <FormField field={{
                         name: `${item.timelineId}_text`,
                         type: constants.formFieldTypes.markdown,
                       }}
                       className="text"
                       value={item.text}
                       fullWidth={true}
                       readOnly={true}
                       hiddenHelperText={true}
                       hiddenLabel={true}
                       hiddenPlaceholder={true}/>
          </Typography>
        } else {
          return <Typography as="div" variant="body2">
            <FormField field={{
                         name: `${item.timelineId}_text`,
                         type: constants.formFieldTypes.markdown
                       }}
                       className="text"
                       mentions={mentions}
                       value={item.text}
                       fullWidth={true}
                       readOnly={true}
                       hiddenHelperText={true}
                       hiddenLabel={true}
                       hiddenPlaceholder={true}/>
          </Typography>
        }
      } else if (item.recordType === 'collection') {
        return item.count === 1 ? <Typography variant="body2">
          <Span className="sender">{firstName}</Span> added company {subject}
        </Typography> : <Typography variant="body2">
            <Span className="sender">{firstName}</Span> added {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
        </Typography>
      } else if (item.recordType === 'database') {
        return item.count === 1 ? <Typography variant="body2">
          <Span className="sender">{firstName}</Span> added company {subject}
        </Typography> : <Typography variant="body2">
          <Span className="sender">{firstName}</Span> added {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
        </Typography>
      } else if (item.recordType === 'timeline') {
        if (item.timelineType === 'status') {
          const knownStatus = (+item.extraInfo?.status > 0 || +item.data?.status > 0);
          return item.count > 1 ? <Typography variant="body2">
            <Span className="sender">{firstName}</Span> changed deal flow status{
            knownStatus ? <React.Fragment> to <DealflowStatusText statusId={item.data?.status ?? item.extraInfo?.status} /></React.Fragment> : null
            } for {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
          </Typography> : <Typography variant="body2">
            <Span className="sender">{firstName}</Span> {!knownStatus ? 'removed' : 'changed'} deal flow status{
            knownStatus ? <React.Fragment> to <DealflowStatusText statusId={item.data?.status ?? item.extraInfo?.status} /></React.Fragment> : null
          }{isOverviewItem ? <React.Fragment> for company {subject}</React.Fragment> : ''}
          </Typography>
        } else if (item.timelineType === 'deal_leader') {
          if (!isOverviewItem) {
            if (item.extraInfo?.newLeader) {
              const name = utils.personName(item.extraInfo?.newLeader?.firstName, item.extraInfo?.newLeader?.lastName);
              return <Typography variant="body2">
                <Span className="sender">{firstName}</Span> changed deal leader to '{name}'
              </Typography>
            } else {
              return <Typography variant="body2">
                <Span className="sender">{firstName}</Span> removed the deal leader
              </Typography>
            }
          } else {
            return item.count > 1 ? <Typography variant="body2">
              <Span className="sender">{firstName}</Span> assigned a deal leader
              to {item.count} compan{item.count > 1 ? 'ies' : 'y'}
            </Typography> : <Typography variant="body2">
              <Span className="sender">{firstName}</Span> assigned a deal leader to company {subject}
            </Typography>
          }
        } else if (item.timelineType === 'csi' && item.data?.statusNotes) {
          return item.count > 1 ? <Typography variant="body2">
            <Span className="sender">{firstName}</Span> changed the status notes for {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
          </Typography> : <Typography variant="body2">
            <Span className="sender">{firstName}</Span> changed the status notes{isOverviewItem ? <React.Fragment> for company {subject}</React.Fragment> : ''}
          </Typography>
        } else if (item.timelineType === 'csi') {
          if (utils.isDefined(item.extraInfo?.newRelevancy)) {
            const oldRelevancyDef = utils.isDefined(item.extraInfo?.oldRelevancy) ? constants.data.lookup('hml', item.extraInfo?.oldRelevancy) : null;
            const oldRelevancyChip = utils.isDefined(oldRelevancyDef) ? <Chip variant="transparent"
                                                                              size="small"
                                                                              color={'secondary'}
                                                                              label={oldRelevancyDef.label}/> : null;
            const relevancyDef = constants.data.lookup('hml', item.extraInfo?.newRelevancy);
            const relevancyChip = <Chip variant="transparent"
                                        size="small"
                                        color={relevancyDef.chipColor}
                                        label={relevancyDef.label}/>
            return item.count > 1 ? <Typography variant="body2">
              <Span className="sender">{firstName}</Span>
              {oldRelevancyDef ? <React.Fragment> changed company relevance rating for {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
                to {relevancyChip}</React.Fragment> : null}
              {!oldRelevancyDef ?
                <React.Fragment> rated {itemCount} compan{item.count > 1 ? 'ies' : 'y'} relevance as {relevancyChip}</React.Fragment> : null}
            </Typography> : <Typography variant="body2">
              <Span className="sender">{firstName}</Span>
              {oldRelevancyDef ? <React.Fragment> changed company relevance rating
                from {oldRelevancyChip} to {relevancyChip}</React.Fragment> :
                <React.Fragment> rated company relevance as {relevancyChip}</React.Fragment>}
              {isOverviewItem ? <React.Fragment> for company {subject}</React.Fragment> : ''}
            </Typography>
          } else { // only happens on overview
            return <Typography variant="body2">
              <Span className="sender">{firstName}</Span>
              {itemCount > 1 ? <React.Fragment> rated {itemCount} compan{item.count > 1 ? 'ies' : 'y'}</React.Fragment> :
                <React.Fragment> rated {subject}</React.Fragment>}
            </Typography>
          }
        }
      } else if (item.timelineType === 'downloads') {
        const renderTitle = () => {
          if (item?.extraInfo?.status === 'active') {
            return <Typography variant="body2">
              <Span className="sender">{firstName}</Span> is downloading {itemCount} compan{item.count > 1 ? 'ies' : 'y'}
            </Typography>
          } else if (item?.extraInfo?.status === 'failed') {
            return <Typography variant="body2">
              Download for <Span className="sender">{firstName}</Span> has <Span color="error" className="status">failed</Span>
            </Typography>
          } else {
            return <Typography variant="body2">
              Download for <Span className="sender">{firstName}</Span> is <Span className="status">ready</Span>
            </Typography>
          }
        }

        const handleClick = (e) => {
          setDownload({taskId: item.relationId});
          e.preventDefault();
        }

        return <Box className="downLoadTitle">
          {renderTitle()}
          <ActionIconButton density="denser"
                            variant="transparent"
                            showInactive={true}
                            IconProps={{
                              size: 'small'
                            }}
                            action={{
                              auth: utils.createAuth({attribute: 'entity.download'}),
                              icon: Download,
                              tooltip: item?.extraInfo?.status === 'finished' ? 'Download' : (
                                item?.extraInfo?.status === 'failed' ? 'Failed' : 'Processing'
                              ),
                              onClick: handleClick,
                              IconButtonProps: {
                                disabled: item?.extraInfo?.status !== 'finished'
                              }
                            }} />
        </Box>
      } else if (item.timelineType === 'uploads') {
        if (item?.extraInfo?.status === 'active') {
          return <Typography variant="body2">
            <Span className="sender">{firstName}</Span> is uploading a file
          </Typography>
        } else if (item?.extraInfo?.status === 'failed') {
          return <Typography variant="body2">
            Upload for <Span className="sender">{firstName}</Span> has <Span color="error" className="status">failed</Span>
          </Typography>
        } else {
          return <Typography variant="body2">
            Upload for <Span className="sender">{firstName}</Span> has <Span className="status">finished</Span>
          </Typography>
        }
      }
    }
  }

  const renderFooter = () => {
    const subject = renderSubjectLink(true);
    return <Typography className="footer" variant="caption" min={8} max={20} isLoading={isLoading}>
      {utils.timeAgo(item.createdAt)}{subject ? ' at ' : ''}{subject}
    </Typography>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clampText: clampText
  });

  return <StyledTimelineCard ref={ref} {...innerProps} isLoading={isLoading}>
    <Box className="TimelineCard-prefix">
      <ActionAvatar className="TimelineCard-avatar"
                    action={{
                      label: avatarLabel,
                      tooltip: name
                    }}
                    isLoading={isLoading}
                    color={avatarBgColor}
                    size="small"
                    outlined={true}
                    TooltipProps={{enterDelay: 0}}
                    {...AvatarProps}/>
      <Box className="TimelineCard-prefix-actions">
        <ActionIconButton tabIndex={-1}
                          action={editAction} density="denser"
                          size="smaller" variant="outlined"
                          IconProps={{size: 'tinier'}} />
        <ActionIconButton tabIndex={-1}
                          action={deleteAction} density="denser"
                          size="smaller" variant="contained"
                          IconProps={{size: 'tinier'}} />
      </Box>
    </Box>
    <Paper ref={contentRef} elevation={1} className="TimelineCard-content">
      {renderContent()}
      {renderFooter()}
      {clampActive ? <Link className="readMore"
                           onClick={() => setClampText((current) => !current)}>
        Show {clampText ? 'more' : 'less'}
      </Link> : null}
    </Paper>
  </StyledTimelineCard>
});

TimelineCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  item: PropTypes.object,
  onEdit: PropTypes.func,
  isEntity: PropTypes.bool,
  isLoading: PropTypes.bool,
  AvatarProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'comment']), PropTypes.string]),
};

TimelineCard.defaultProps = {
  elevation: 0
};

export default TimelineCard;
