import React from 'react';
import PropTypes from 'prop-types';
import {DndContext as DndContextBase} from '@dnd-kit/core';

export const OverLayContext = React.createContext(null);
export const DragHandleContext = React.createContext(null);
export const DndWrapContext = React.createContext(null);

export function useDnd () {
  return React.useContext(DndWrapContext);
}

export function useDragHandle () {
  return React.useContext(DragHandleContext);
}

export function useOverlay () {
  return React.useContext(OverLayContext);
}

const DndContext = React.forwardRef((props, ref) => {
  return <DndWrapContext.Provider value={{active: true}}>
    <DndContextBase ref={ref} {...props} />
  </DndWrapContext.Provider>
});

DndContext.propTypes = {
  placeholder: PropTypes.any,
  timeout: PropTypes.number
};

DndContext.defaultProps = {
};

export default DndContext;
