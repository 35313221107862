import React, {useMemo} from 'react'
import {useParams} from 'react-router-dom';
import constants from 'helpers/constants';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import TaskProfileProvider from 'components/organisms/Providers/ProfileProvider/TaskProfileProvider';
import {useEntityCommentDelete, useEntityCommentGet} from 'services/entity/comment/comment.hooks';
import {useEntityGet} from 'services/entity/entity.hooks';
import {
  useEntityCommentToggleTaskDone,
  useEntityTaskGroups,
  useEntityTaskPatch,
  useEntityTaskSections
} from 'services/entity/comment/comment.utils';
import {useProviderDataState, useProviderView} from 'helpers/hooks/utils';

const EntityTaskProfileProvider = (props) => {
  const params = useParams();
  const tableProvider = useTable();
  const { entityId, taskId } = {...utils.cleanObject({
    entityId: tableProvider?.context?.data?.entityId,
    taskId: tableProvider?.selected()?.taskId
  }), ...params, ...props};

  const view = useProviderView('entity.task', props.skipView);

  const entity = useEntityGet({entityId}, {enabled: +entityId > 0});
  const task = useEntityCommentGet({entityId, commentId: taskId},
    {enabled: (+entityId > 0 && +taskId > 0)});

  const [context, onContext] = useProviderDataState(entity);
  const [data, onData] = useProviderDataState(task);

  const groups = useEntityTaskGroups(view);
  const sections = useEntityTaskSections(view);

  const isLoading = props?.isLoading || entity.status?.isLoading || task.status?.isLoading;

  const cardDefinitions = useMemo(() => {
    return groups?.length > 0 ? groups : null;
  }, [groups]);

  const sectionDefinitions = useMemo(() => {
    return sections?.length > 0 ? sections : null;
  }, [sections]);

  const options = useMemo(() => ({
    cardState: {
      options: {
        name: 'entityTask',
        type: constants.appState.type.local
      }
    },
    cardDefinitions,
    sectionDefinitions
  }), [cardDefinitions, sectionDefinitions]);

  const update = useEntityTaskPatch();
  const deleteTask = useEntityCommentDelete();
  const toggleDone = useEntityCommentToggleTaskDone();
  const updaters = useMemo(() => {
    return {
      updateData: (task, changes) => {
        return update(entity.data, task, changes);
      },
      deleteData: (data) => {
        return deleteTask.mutation.mutateAsync({entityId: entity.data?.entityId, ...data});
      },
      toggleDone: (task, done) => {
        return toggleDone(entity.data, task, done);
      }
    }
  }, [update, toggleDone, deleteTask.mutation, entity.data]);

  return <TaskProfileProvider {...props}
                              view={view}
                              context={context}
                              data={data}
                              onContext={onContext}
                              onData={onData}
                              options={options}
                              updaters={updaters}
                              isLoading={isLoading}>
    {props.children}
  </TaskProfileProvider>
};

EntityTaskProfileProvider.propTypes = {
}

export default EntityTaskProfileProvider;
