import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Form from 'components/organisms/Forms/Form/Form';

const StyledInlineForm = styled(Form)`
  ${ComponentStyles}
`;

StyledInlineForm.propTypes = {
  theme: PropTypes.object
};

StyledInlineForm.defaultProps = {
}

export default StyledInlineForm;
