import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import AccordionDetails from '@mui/material/AccordionDetails';

const StyledAccordionDetails = styled(AccordionDetails)` 
  ${ComponentStyles}
`;

StyledAccordionDetails.propTypes = {
  theme: PropTypes.object
};

StyledAccordionDetails.defaultProps = {}

export default StyledAccordionDetails;
