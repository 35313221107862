import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSection = styled(Box)`
  &.Section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    column-gap: ${props => props.theme.layout(props.$gap)};
    row-gap: ${props => props.theme.layout(props.$gap)};
  }
  
  ${ComponentStyles}
`;

StyledSection.propTypes = {
  theme: PropTypes.object
};

StyledSection.defaultProps = {}

export default StyledSection;
