import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionProfileFoldout
  from 'components/organisms/Foldouts/CollectionProfileFoldout/CollectionProfileFoldout.styles';
import {H5} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const CollectionProfileFoldout = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionProfileFoldout');

  const profileProvider = useProfile();

  const renderTitle = () => {
    return <Box className="CollectionProfileFoldout-title">
      <H5>Collection settings</H5>
    </Box>
  }

  return <StyledCollectionProfileFoldout ref={ref} {...innerProps}
                                         title={renderTitle()}
                                         isLoading={profileProvider.isLoading()}/>
});

CollectionProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionProfileFoldout.defaultProps = {};

export default CollectionProfileFoldout;
