import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';

const path = utils.servicePath(import.meta.url);

export function useClientTeamMemberStatus () {
  return useServiceStatus(path);
}

export function useClientTeamMemberList ({clientId, teamId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, teamId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useClientTeamMemberAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useClientTeamMemberDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
