import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent.styles';

const DialogContent = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DialogContent');

  return <StyledDialogContent ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDialogContent>
});

DialogContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DialogContent.defaultProps = {
  children: 'DialogContent text'
};

export default DialogContent;
