import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from '@mui/material/Card';
import constants from 'helpers/constants';
import {rgba} from 'polished';

const StyledCard = styled(Card)`
  outline: none;

  &.Card-isLoading {
    pointer-events: none;
  }
  
  &:not(.Card-isLoading) {
    ${props => props.theme.color2Css('Card', null, props.$color, {
      interactive: false,
      type: constants.colorTypes.accentBackground
    })}
  }

  &.Card {
    ${props => props.theme.state2Selector('Card', null, 'disabled')} {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  
  &.Card-outlined {
    ${props => props.theme.state2Selector('Card', null, ['selected'])} {
      color: ${props => props.theme.property(`palette.${props.$color}.main`)};
      border-color: ${props => props.theme.property(`palette.${props.$color}.main`)};
    }
    
    ${props => props.theme.state2Selector('Card', null, 'disabled')} {
      color: ${props => rgba(props.theme.property(`palette.${props.$color}.main`), 
              props.theme.property('palette.action.disabledOpacity'))} !important;
      border-color: ${props => rgba(props.theme.property(`palette.${props.$color}.main`), 
              props.theme.property('palette.action.disabledOpacity'))} !important;
    }
  }

  &.Card-clickable {
    ${props => props.theme.color2Css('Card', null, props.$color, {
      interactive: true,
      type: constants.colorTypes.accentBackground
    })}
    
    cursor: pointer;

    &:not(.Card-focusable) {
      ${props => props.theme.state2Selector('Card', null, ['focus', 'focusVisible'])} {
        background-color: ${props => props.theme.property('palette.background.paper')} !important;
      }
    }
  }

  &.Card-radius-round {
    border-radius: ${props => props.theme.radius(2)};
  }
  &.Card-radius-rounder {
    border-radius: ${props => props.theme.radius(3)};
  }
  &.Card-radius-roundest {
    border-radius: ${props => props.theme.radius(4)};
  }
  &.Card-radius-square {
    border-radius: ${props => props.theme.radius(0)};
  }

  &.Card-fullHeight {
    height: 100% !important;
  }
  &.Card-fullWidth {
    width: 100% !important;
  }
  
  ${ComponentStyles}
`;

StyledCard.propTypes = {
  theme: PropTypes.object
};

StyledCard.defaultProps = {}

export default StyledCard;
