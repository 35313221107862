import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowPieChart from 'components/organisms/Charts/DealflowPieChart/DealflowPieChart.styles';
import {useClientDealflowInGroups} from 'services/client/client.utils';
import DealflowTooltip from 'components/molecules/Tooltips/DealflowTooltip/DealflowTooltip';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Apartment from '@mui/icons-material/Apartment';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import PieChart from 'components/organisms/Charts/PieChart/PieChart';
import Legend from 'components/molecules/Charts/Legend/Legend';

const DealflowPieChart = React.forwardRef((props, ref) => {
  const {
    dealflow,
    total,
    showLegend,
    showNoDealflow,
    orientation,
    extended,
    isLoading,
    variant,
    visibility,
    onClick,
    onVisibilityChange,
    PieChartProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowPieChart', {
    variable: ['orientation'],
    children: ['chart', 'legend']
  });

  const groups = useClientDealflowInGroups(dealflow, total, showNoDealflow);

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (groups) {
      legend = groups
        .map((g) => {
          return {
            id: g.groupId,
            position: g.position,
            label: g.label,
            color: utils.deprecatedColor(g.color),
            active: visibility?.[g.groupId] ?? true,
            meta: g,
          }
        });
    }

    // orientation = vertical legend -> horizontal
    const rows = orientation === 'vertical' ?
      legend.length <= 4 ? 2 : (legend.length > 6 ? 4 : 3) : legend.length;

    return [legend, rows];
  }, [groups, visibility, orientation]);

  const [dealflowData, totalCount] = useMemo(() => {
    if (groups) {
      const showEmpty = showNoDealflow && total === 0;
      const dealflowData = {
        dataKey: 'value',
        nameKey: 'label',
        data: groups
          .map((g) => {
            return {
              id: g.groupId,
              position: g.position,
              label: g.label,
              color: utils.deprecatedColor(g.color),
              value: (showEmpty && g.noDealflow) ? 1 : g.count,
              meta: g,
            }
          })
          .filter((g) => {
            return Boolean(legend.find((l) => l.active && +l.id === +g.id))
          }),
        TooltipComponent: DealflowTooltip
      };

      const totalCount = dealflowData.data.reduce((t, d) => t + d.meta.count, 0);

      return [dealflowData, totalCount];
    } else {
      return [null, 0];
    }
  }, [groups, legend, total, showNoDealflow]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowPieChart ref={ref} {...innerProps}>
    <Box className="DealflowPieChart-chart">
      <Box className="DealflowPieChart-chart-wrapper">
        {dealflowData ? <PieChart {...dealflowData}
                                  hoveredId={internalState.hoveredId}
                                  TooltipComponent={DealflowTooltip}
                                  variant={variant}
                                  isLoading={isLoading}
                                  showTooltip={true}
                                  onClick={onClick}
                                  TooltipProps={{
                                    variant: 'extended'
                                  }}
                                  {...PieChartProps} /> : null}

        <Box className="DealflowPieChart-center">
          <Icon color="primary" icon={Apartment} isLoading={isLoading}/>

          <Typography color="primary"
                      className="totalCount"
                      isLoading={isLoading}>
            {totalCount || 0}
          </Typography>

          <H6 className="title" isLoading={isLoading}>Companies</H6>
        </Box>
      </Box>
    </Box>
    {showLegend ? <Legend className="DealflowPieChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          orientation={orientation === 'vertical' ? 'horizontal' : 'vertical'}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledDealflowPieChart>
});

DealflowPieChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  total: PropTypes.number,
  showLegend: PropTypes.bool,
  showNoDealflow: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onClick: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  PieChartProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['full', 'half']), PropTypes.string])
};

DealflowPieChart.defaultProps = {
  variant: 'full',
  orientation: 'vertical'
};

export default DealflowPieChart;
