import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Email from 'components/pages/Base/Email/Email';

const StyledWeeklyEmailPage = styled(Email)`
  &.WeeklyEmailPage {
    .Table-outer {
      .Table.Table-inner {
        .TableRow {
          &.WeeklyEmailPage {
            &-dashboardButtonRow {
              .TableCell {
                padding-top: ${props => props.theme.spacing(2)};
                padding-bottom: ${props => props.theme.spacing(2)};
              }
            }

            &-recentRow, &-commentsAndTasksRow, &-recentCommentsRow, &-newTasksRow {
              .TableCell {
                padding-bottom: ${props => props.theme.spacing(2)};

                .H6 {
                  font-weight: 500;
                }

                .P {
                  padding: 0;
                  margin: 0;
                }

                .List {
                  padding: 0 0 0 ${props => props.theme.spacing(2)};

                  .ListItem {
                    list-style-type: disc;
                    display: list-item;

                    padding: ${props => props.theme.spacing(0.5)} 0;
                  }
                }
              }
            }

            &-newTasksRow {
              .TableCell {
                .P {
                  &.due {
                    ${props => props.theme.font2Css('body2')};
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledWeeklyEmailPage.propTypes = {
  theme: PropTypes.object
};

StyledWeeklyEmailPage.defaultProps = {
};

export default StyledWeeklyEmailPage;
