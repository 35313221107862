import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog.styles';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import utils from 'helpers/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import EntitiesUploadWizard from 'components/organisms/Wizards/EntitiesUploadWizard/EntitiesUploadWizard';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';

const EntitiesUploadDialog = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    onClose,
    onSubmit,
    EntitiesUploadWizardProps,
    ...innerProps
  } = useComponentProps(props, 'EntitiesUploadDialog');

  const innerRef = useRef(null);
  const wizardRef = useRef(null);

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const handleSubmit = (uploadData) => {
    utils.asPromise(onSubmit)(uploadData)
      .catch(() => {
        snackbar.show('Uploading entities failed', null, {color: 'error', autoHideDuration: constants.delay.error});
      });
  };

  const handleClose = (e, reason) => {
    if (!wizardRef.current?.isDirty()) {
      onClose?.(e, reason);
    } else if (['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      dialogControl.show(<ConfirmDialog question="Are you sure you want to exit this wizard?"
                                        explanation="The dialog will close and your changes will be lost"
                                        onConfirm={() => onClose?.(e, reason)}/>, true);
    }
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesUploadDialog ref={innerRef} {...innerProps}
                                     size="large"
                                     onClose={handleClose}>
      <DialogHeader className="EntitiesUploadDialog-header"
                    title={title ?? 'Upload companies'}
                    subtitle={subtitle}/>
      <DialogContent className="EntitiesUploadDialog-content">
        <EntitiesUploadWizard ref={wizardRef}
                              className="EntitiesUploadDialog-wizard"
                              showButtons={true}
                              showProgressBar={true}
                              onSubmit={handleSubmit}
                              {...EntitiesUploadWizardProps} />
      </DialogContent>
  </StyledEntitiesUploadDialog>
});

EntitiesUploadDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any,
  steps: PropTypes.array,
  taskTypes: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

EntitiesUploadDialog.defaultProps = {
};

export default EntitiesUploadDialog;
