import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledAdvancedQuery = styled(Box)`
  width: 100%;
  overflow: hidden;
  
  ${ComponentStyles}
`;

StyledAdvancedQuery.propTypes = {
  theme: PropTypes.object
};

StyledAdvancedQuery.defaultProps = {}

export default StyledAdvancedQuery;
