import {useCallback, useMemo} from 'react';
import utils from 'helpers/utils';
import commentUtils from 'helpers/comment';
import {
  useEntityCommentAdd,
  useEntityCommentMarkDone,
  useEntityCommentMarkUndone,
  useEntityCommentUpdate
} from 'services/entity/comment/comment.hooks';
import constants from 'helpers/constants';

export function useEntityCommentToggleTaskDone () {
  const commentDone = useEntityCommentMarkDone();
  const commentUndone = useEntityCommentMarkUndone();

  return useCallback((entity, comment, done) => {
    done = utils.isDefined(done) ? done : !comment.isDone;

    return (!done ? commentUndone.mutation.mutateAsync : commentDone.mutation.mutateAsync)({
      entityId: entity?.entityId,
      commentId: comment.commentId
    });
  }, [commentDone.mutation, commentUndone.mutation])
}

export function useEntityCommentCreate (mutationOptions = {}) {
  const commentAdd = useEntityCommentAdd(mutationOptions);

  return useCallback((
    text,
    entityIds,
    collectionId = null,
    mentions = null,
    task = false,
    title = null,
    type = null,
    responsible = null,
    collaborationType = null,
    dueDate = null
  ) => {
    const comment = commentUtils.createComment(
      text,
      'entity',
      entityIds,
      collectionId,
      mentions,
      task,
      title,
      type,
      responsible,
      collaborationType,
      dueDate
    );

    return commentAdd.mutation.mutateAsync({
      ...comment
    });
  }, [commentAdd.mutation])
}

export function useEntityTaskCreate (mutationOptions = {}) {
  const commentCreate = useEntityCommentCreate(mutationOptions);

  return useCallback((entities, collectionId, task) => {
    const entityIds = entities?.map((e) => +e.entityId);

    return commentCreate(
      task.comment,
      entityIds,
      collectionId,
      null,
      true,
      task.type.value === constants.task.types.other ? task.title : task.type.label,
      task.type.value,
      task.responsible,
      (task.responsible?.length === 1) ? constants.task.collaborationTypes.individual : task.collaborationType,
      task.dueDate,
    )
  }, [commentCreate])
}

export function useEntityCommentPatch (mutationOptions = {}) {
  const commentPatch = useEntityCommentUpdate(mutationOptions);

  return useCallback((entity, comment, changes) => {
    changes = commentUtils.calcCommentChanges(comment, changes);

    if (!utils.isEmpty(changes)) {
      return commentPatch.mutation.mutateAsync({
        entityId: entity.entityId,
        commentId: comment.commentId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [commentPatch.mutation])
}

export function useEntityTaskPatch (mutationOptions = {}) {
  const commentPatch = useEntityCommentPatch(mutationOptions);

  return useCallback((entity, task, changes) => {
    const changed = {};
    if (changes.hasOwnProperty('comment')) {
      changed.text = changes.comment;
    }
    return commentPatch(entity, task, changed);
  }, [commentPatch]);
}

export function useEntityTaskGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.entity.task.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.task.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('entity.task', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => {
              return {
                ...f,
                entity: 'task',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute: `entity.task.field.${f.name}.read`}),
                  update: utils.createAuth({attribute: `entity.task.field.${f.name}.update`})
                },
                required: f.required ?? false
              };
            }) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useEntityTaskSections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.entity.task.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.task.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('entity.task', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `entity.task.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `entity.task.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            auth: {
              read: utils.createAuth({attribute: `entity.task.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `entity.task.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

