import React, {useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {arrayMove} from '@dnd-kit/sortable';
import StyledStatusesForm from 'components/organisms/Forms/StatusesForm/StatusesForm.styles';
import StatusForm from 'components/organisms/Forms/StatusForm/StatusForm';

const StatusesForm = React.forwardRef((props, ref) => {
  const {
    statuses,
    readOnly,
    disabled,
    error,
    onChange,
    onBlur,
    onErrors,
    ...innerProps
  } = useComponentProps(props, 'StatusesForm');

  const statusRefs = useRef({});

  const [internalState, setInternalState] = useState({
    statuses: []
  });

  useLayoutEffect(() => {
    let maxPos = 0, minId = 0;

    const sts = (statuses ?? [])
      .map((s, idx) => {
        maxPos = Math.max(maxPos, idx);
        if (!utils.isDefined(s.statusId)) {
          minId = minId - 1;
        } else if (+s.statusId < 0) {
          minId = Math.min(minId, +s.statusId);
        }

        return {
          ...s,
          position: idx,
          statusId: !utils.isDefined(s.statusId) ? minId : +s.statusId
        }
      });

    if (!(readOnly || disabled) && sts.length === 0) {
      sts.push({statusId: minId - 1, position: maxPos + 1});
    }

    setInternalState(utils.updater({
      statuses: sts.sort((a, b) => +a.position - +b.position)
    }, true));
  }, [statuses, readOnly, disabled]);


  const handleAddStatus = () => {
    setInternalState((current) => {
      const maxPos = current.statuses.reduce((p, s) => Math.max(p, +s.position), 0);
      const minId = current.statuses.reduce((i, s) => s.statusId < 0 ? Math.min(i, +s.statusId) : i, 0);

      return {
        ...current,
        statuses: current.statuses.concat([{statusId: minId - 1, position: maxPos + 1}])
      }
    });
  }

  const doErrors = () => {
    const errors = Object.keys(statusRefs.current).reduce((e, k) => e || statusRefs.current[k].errors, false);
    onErrors?.(errors);
    setInternalState(utils.updater({errors}, true));
  }

  const handleDragDrop = (id, containerId, position) => {
    let newValue = utils.clone(internalState.statuses, true);
    const idx = internalState.statuses.findIndex((s) => +s.statusId === +id);

    if (idx !== position) {
      newValue = arrayMove(internalState.statuses, idx, position);
      newValue.forEach((s, idx) => {
        s.position = idx;
      });

      onChange?.(newValue);
      onBlur?.();
    }
  }

  const handleChange = (status) => (values) => {
    const idx = internalState.statuses.findIndex((s) => +s.statusId === +status.statusId);
    
    if (idx !== -1) {
      onChange?.([
        ...internalState.statuses.slice(0, idx),
        {...status, ...values},
        ...internalState.statuses.slice(idx + 1),
      ].map((s) => ({
        ...s,
        statusId: s.statusId <= 0 ? null : s.statusId
      })));
    }
  }

  const handleDelete = (status) => () => {
    delete statusRefs.current[status.statusId];

    onChange?.(internalState.statuses
      .filter((s) => +s.statusId !== +status.statusId)
      .map((s) => ({
        ...s,
        statusId: s.statusId <= 0 ? null : s.statusId
      })));
    doErrors();
    onBlur?.();
  }

  const handleRef = (status) => (ref) => {
    statusRefs.current[status.statusId] = {
      ...statusRefs.current[status.statusId],
      ref,
      errors: ref ? statusRefs.current[status.statusId]?.errors : null,
    };
  }

  const handleErrors = (status) => (errors) => {
    statusRefs.current[status.statusId] = {
      ...statusRefs.current[status.statusId],
      ref,
      errors
    };

    doErrors();
  }

  const renderStatus = (status, first) => {
    const hasError = internalState.statuses.find((s) => s.statusId !== status.statusId && s.value === status.value);

    return <StatusForm ref={handleRef(status)}
                       key={status.statusId}
                       className="StatusesForm-status"
                       status={status}
                       first={first}
                       error={Boolean(hasError)}
                       readOnly={readOnly}
                       disabled={disabled}
                       active={internalState.statuses.length > 1}
                       onChange={handleChange(status)}
                       onDelete={handleDelete(status)}
                       onErrors={handleErrors(status)}/>
  }

  return <StyledStatusesForm ref={ref} {...innerProps} onBlur={onBlur}>
    <DndContext>
      <List sortable={!(readOnly || disabled)}
            dragHandle={true}
            gap={8} catchFocus={false}
            onDragDrop={handleDragDrop}>
        {internalState.statuses?.map((status, idx) => {
          return <ListItem key={status.statusId} data-key={status.statusId} density="densest">
            {renderStatus(status, idx === 0)}
          </ListItem>
        })}
      </List>
      {!(readOnly || disabled) ?
        <Button variant="text"
                disabled={internalState.errors}
                startIcon={<Icon icon={Add} />}
                onClick={handleAddStatus}>
          Add status
        </Button> : null}
    </DndContext>
  </StyledStatusesForm>
});

StatusesForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statuses: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onChange: PropTypes.func,
  onErrors: PropTypes.func
};

StatusesForm.defaultProps = {};

export default StatusesForm;
