import {useCallback} from 'react';
import utils from 'helpers/utils';
import {useClientUserUpdate} from 'services/client/user/user.hooks';

export function processUser (user) {
  user = utils.camelcase(user);

  return {
    ...user,
    holder: utils.camelcaseEx(user.holder),
    passes: utils.camelcaseEx(user.passes ?? [])
  }
}

export function useClientUserPatch () {
  const userUpdate = useClientUserUpdate();
  const userUpdateRefetch = useClientUserUpdate({refetchContext: true});

  return useCallback((clientId, user, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(user, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return (utils.isDefined(changes.active) ? userUpdateRefetch.mutation : userUpdate.mutation).mutateAsync({
        clientId,
        userId: user.userId,
        ...utils.underscore(changes)
      });
    } else {
      return Promise.resolve();
    }
  }, [userUpdate.mutation, userUpdateRefetch.mutation]);
}
