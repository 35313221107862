import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import StyledCollectionEntitiesSuggestionBrowser
  from 'components/organisms/Browsers/CollectionEntitiesSuggestionBrowser/CollectionEntitiesSuggestionBrowser.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import FolderSharp from '@mui/icons-material/FolderSharp';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useEntityExternalRelevancy, useEntityPersonalRelevancy} from 'services/entity/entity.utils';
import RelevancyContextCard from 'components/organisms/Cards/RelevancyContextCard/RelevancyContextCard';

const CollectionEntitiesSuggestionBrowser = React.forwardRef((props, ref) => {
  const {
    onChange,
    ...innerProps
  } = useComponentProps(props, 'CollectionEntitiesSuggestionBrowser');

  const authorize = useAuthorize();
  const tableProvider = useTable();
  const profileProvider = useProfile();

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const collection = profileProvider.context?.data;
  const entity = profileProvider.data?.data;
  const count = tableProvider.list?.meta?.resultsCount ?? 0;

  const canUpdate = authorize({attribute: 'collection.update', meta: {collection}});

  const entityUnAssignEvent = useEffectEvent(tableProvider.updaters?.unAssignItems);
  const entityAssignEvent = useEffectEvent(tableProvider.updaters?.assignItems);
  const entityAcceptEvent = useEffectEvent(tableProvider.updaters?.acceptItems);

  const updateCsiEvent = useEffectEvent(profileProvider.updaters?.updateCsi);

  const personalRelevancyValue = useEntityPersonalRelevancy(entity, collection);
  const [personalRelevancy, togglePersonalRelevancyOptimistic] = useOptimistic(personalRelevancyValue || 0, updateCsiEvent);
  const externalRelevancyValue = useEntityExternalRelevancy(entity, collection);
  const [externalRelevancy, toggleExternalRelevancyOptimistic] = useOptimistic(externalRelevancyValue || 0, updateCsiEvent);

  const onChangeEvent = useEffectEvent(onChange);
  const addEntityActionMemo = useMemo(() => ({
    tooltip: 'Add to collection',
    auth: utils.createAuth({attribute: 'collection.update', meta: {collection, entity}}),
    onClick: (e) => {
      entityAcceptEvent?.({collectionId: collection.collectionId, entityIds: [entity.entityId]})
        .then(() => {
          onChangeEvent?.(entity, count - 1);
        })
        .catch(() => {
          snackbar.show(`Adding company '${entity.name}' to collection '${collection.name}' failed`, null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
      e.preventDefault();
    },
  }), [collection, entity, count, entityAcceptEvent, onChangeEvent, snackbar]);

  const rejectEntityActionMemo = useMemo(() => ({
    label: 'Remove suggestion',
    tooltip: 'Remove suggestion',
    auth: utils.createAuth({attribute: 'collection.update', meta: {collection, entity}}),
    onClick:(e) => {
      entityUnAssignEvent?.({collectionId: collection.collectionId, entityIds: [entity.entityId]})
        .then(() => {
          onChangeEvent?.(entity, count - 1);
        })
        .catch(() => {
          snackbar.show(`Removing suggestion '${entity.name}' from collection '${collection.name}' failed`, null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });

      e.preventDefault();
    },
  }), [collection, entity, count, entityUnAssignEvent, onChangeEvent, snackbar]);

  const advancedActions = useMemo(() => {
    return [
      {
        ...rejectEntityActionMemo,
        label: 'Remove suggestion',
        tooltip: null,
        icon: Delete,
        ActionButtonProps: {
          color: 'error'
        }
      },
      {
        ...addEntityActionMemo,
        label: 'Add to collection',
        tooltip: null,
        icon: Add
      },
      {
        label: 'Add to other collection',
        auth: utils.createAuth({attribute: 'collection.update'}),
        icon: FolderSharp,
        onClick: (e) => {
          const collections = entity?.collections;
          const handleSubmit = (collections) => {
            if (collections.length > 0) {
              const message = `Adding the company to the collection${collections.length > 1 ? 's' : ''}`;
              return utils.observePromise(Promise.all(collections.map((collection) => {
                return entityAssignEvent?.({
                  collectionId: collection.collectionId,
                  entityId: entity.entityId
                });
              })), () => {
                return snackbar.show(`${message}...`, null, {
                  color: 'info',
                  autoHideDuration: null
                });
              }, () => {
                return snackbar.show(`The company was added to the collection${collections.length > 1 ? 's' : ''}`, null,
                  {color: 'success', autoHideDuration: constants.delay.success});
              }, () => {
                return snackbar.show(`${message} failed`, null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              }, (id) => {
                snackbar.hide(id);
              });
            }
          };

          dialogControl.show(<CollectionSelectionDialog title="Add to collection"
                                                        subtitle="For 1 company"
                                                        collections={collections}
                                                        hideCollections={true}
                                                        onSubmit={handleSubmit}/>, true);

          e.preventDefault();
        },
        ActionButtonProps: {
          variant: 'outlined'
        }
      }
    ];
  }, [entity, dialogControl, snackbar, rejectEntityActionMemo, addEntityActionMemo, entityAssignEvent]);

  const renderAdvancedContext = () => {
    const handlePersonalChange = (value) => {
      return togglePersonalRelevancyOptimistic?.(value, entity, {
        relevancy: value,
        collection_id: collection?.collectionId
      }).catch((err) => {
        snackbar.show('Saving relevance failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});

        throw err;
      })
    };

    const handleExternalChange = (value) => {
      return toggleExternalRelevancyOptimistic?.(value, entity, {
        relevancy: value,
        collection_id: collection?.collectionId
      }).catch((err) => {
        snackbar.show('Saving relevance failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});

        throw err;
      })
    };

    const canUpdatePersonal = authorize({attribute: 'collection.entity.update', meta: {collection, entity}}) &&
      authorize(utils.createAuth({attribute: `collection.entity.field.personalRelevancy.update`}));
    const canUpdateExternal = authorize({attribute: 'collection.entity.update', meta: {collection, entity}}) &&
      authorize(utils.createAuth({attribute: `collection.entity.field.externalRelevancy.update`}));

    return <React.Fragment>
      {canUpdatePersonal ? <RelevancyContextCard variant="inline"
                                                 relevancy={personalRelevancy}
                                                 onChange={handlePersonalChange}
                                                 canUpdate={canUpdatePersonal}
                                                 isLoading={tableProvider.isLoading()}/> : null}
      {canUpdateExternal ? <RelevancyContextCard variant="inline"
                                                 relevancy={externalRelevancy}
                                                 title="External relevance"
                                                 onChange={handleExternalChange}
                                                 canUpdate={canUpdateExternal}
                                                 isLoading={tableProvider.isLoading()}/> : null}
    </React.Fragment>

  }

  return <StyledCollectionEntitiesSuggestionBrowser ref={ref} {...innerProps}
                                                    canUpdate={canUpdate}
                                                    isLoading={!entity}
                                                    addAction={addEntityActionMemo}
                                                    deleteAction={rejectEntityActionMemo}
                                                    advancedActions={advancedActions}
                                                    advancedContext={renderAdvancedContext()} />
});

CollectionEntitiesSuggestionBrowser.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesSuggestionBrowser.defaultProps = {};

export default CollectionEntitiesSuggestionBrowser;
