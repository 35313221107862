import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledCollectionOnboarding
  from 'components/organisms/Onboardings/CollectionOnboarding/CollectionOnboarding.styles';
import TaskAlt from '@mui/icons-material/TaskAlt';
import utils from 'helpers/utils';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Onboarding from 'components/organisms/Onboardings/Onboarding/Onboarding';
import ActionFab from 'components/molecules/Fabs/ActionFab/ActionFab';
import {Slide} from '@mui/material';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import constants from 'helpers/constants';

const CollectionOnboarding = withMemo(React.forwardRef((props, ref) => {
  const {
    collection,
    showEmpty,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'CollectionOnboarding');

  const popperRef = useRef(null);
  const profileProvider = useProfile();

  const client = useAuthClient();

  const openProfileEvent = useEffectEvent(profileProvider.openProfile);
  const [steps, todo] = useMemo(() => {
    let steps = [];
    if (collection) {
      steps = steps.concat([
        {
          label: 'Setup the basics',
          description: 'What are you looking for? Choose a description, topics and many more',
          completed: collection?.projectTopics?.length > 0,
          action: {
            label: 'Basics',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({activeSection: 'basics'});
            }
          }
        },
        {
          label: 'Add categories',
          description: 'Organise your collection with categories and let Catalist auto-assign them for you',
          completed: collection?.tagGroups?.length > 0,
          action: {
            label: 'Categories',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({activeSection: 'categories'});
            }
          }
        },
        {
          label: 'Add sources',
          description: 'By adding sources and (optional) filters you can let Catalist search companies for you',
          completed: collection?.projectSources?.length > 0,
          action: {
            label: 'Sources',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({activeSection: 'sources'});
            }
          }
        },
        {
          label: 'Enable services',
          description: 'Turn on services like \'Autotags\', \'Enrichment\' and many more',
          completed: constants.data.services.filter((service) => {
            if (service.value === constants.services.types.autotags) {
              return Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) || Boolean(collection?.autotagPeriod !== constants.services.periods.never);
            } else if (service.value === constants.services.types.linkedinEnrichment) {
              return Boolean(client?.props?.enrichmentPeriod !== constants.services.periods.never) || Boolean(collection?.enrichmentPeriod !== constants.services.periods.never);
            } else if (service.value === constants.services.types.companyInfoEnrichment) {
              return Boolean(client?.props?.companyInfoPeriod !== constants.services.periods.never) || Boolean(collection?.companyInfoPeriod !== constants.services.periods.never);
            } else {
              return false
            }
          }).length > 0,
          action: {
            label: 'Services',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({activeSection: 'services'});
            }
          }
        }
      ]);
    }

    return [steps, steps.filter((s) => !s.completed).length];
  }, [collection, client, openProfileEvent]);

  const onboarding = useMemo(() => {
    return {
      tooltip: 'Setup your collection',
      icon: TaskAlt,
      color: 'primary',
      auth: utils.createAuth({attribute: 'collection.update', meta: {collection}}),
      badge: todo > 0 ? <Badge badgeContent={todo} color="error"/> : null,
      popper: <Onboarding title="How to setup your collection"
                          steps={steps}/>,
      ActionFabProps: {
        DropDownPopperProps: {
          ref: popperRef,
          autoFocus: false,
          ContextPopperProps: {
            elevation: 2,
            placement: 'top-end',
            outlined: false,
            reverseShadow: false,
            fixed: true,
            density: 'densest',
            offset: [
              0,
              -utils.rem2Pixel(innerProps.theme.layout('7sp'), innerProps.theme.typography.htmlFontSize)
            ],
            TransitionComponent: Slide,
            TransitionProps: {
              direction: 'left'
            }
          }
        }
      }
    }
  }, [collection, steps, todo, innerProps.theme]);

  if (!isLoading && (todo > 0 || showEmpty)) {
    return <StyledCollectionOnboarding ref={ref} {...innerProps}>
      <ActionFab action={onboarding} isLoading={isLoading}/>
    </StyledCollectionOnboarding>
  }
}));

CollectionOnboarding.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  showEmpty: PropTypes.bool,
  isLoading: PropTypes.bool
};

CollectionOnboarding.defaultProps = {
};

export default CollectionOnboarding;
