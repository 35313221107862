import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledSuggestionFieldExplanation
  from 'components/molecules/Fields/SuggestionField/SuggestionFieldExplanation.styles';
import Typography from 'components/atoms/Text/Typography/Typography';

const SuggestionFieldExplanation = React.forwardRef((props, ref) => {
  const {
    explanation,
    ...innerProps
  } = useComponentProps(props, 'SuggestionFieldExplanation');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSuggestionFieldExplanation ref={ref} {...innerProps}>
    <Typography variant="caption">{explanation}</Typography>
  </StyledSuggestionFieldExplanation>
});

SuggestionFieldExplanation.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

SuggestionFieldExplanation.defaultProps = {
};

export default SuggestionFieldExplanation;
