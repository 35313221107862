import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';

const StyledSwitchField = styled(FormControl)`
  &.SwitchField {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(32)};
    }

    .FormControlLabel {
      width: fit-content;
      align-items: center;

      .MuiFormControlLabel-label {
        display: inline-flex;
        align-self: center;
      }
    }

    .InputContainer {
      .SwitchField-label {
        margin-right: 0;
        margin-left: -5px !important;
      }
    }
  }
  
  &.SwitchField-size-small, &.SwitchField-size-smallest {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(30)} !important;

      .FormControlLabel {
        min-height: ${props => props.theme.layout(30)} !important;
      }
    }
    
    .MuiFormControlLabel-label {      
      font-size: ${props => props.theme.fontSize(14)};
    }

    .InputContainer {
      .SwitchField-label {
        margin-left: -5px !important;
      }
    }
    
    .FormHelperText {
      left: ${props => props.theme.layout(-8)};
    }
  }

  &.SwitchField-size-smallest {
    &:not(.FormControl-readOnly) {
      min-height: ${props => props.theme.layout(30)} !important;
      
      .FormControlLabel {
        min-height: ${props => props.theme.layout(30)} !important;
      } 
    }
  }
  
  ${ComponentStyles}
`;

StyledSwitchField.propTypes = {
  theme: PropTypes.object
};

StyledSwitchField.defaultProps = {
}

export default StyledSwitchField;
