import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SectionPanel from 'components/organisms/SectionPanels/SectionPanel/SectionPanel';

const StyledCollectionSourcesSectionPanel = styled(SectionPanel)`
  &.CollectionSourcesSectionPanel {
    .SectionPanel-panel {
      padding: 0;
      height: unset;
      overflow: hidden;
    }
  }
  ${ComponentStyles}
`;

StyledCollectionSourcesSectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledCollectionSourcesSectionPanel.defaultProps = {}

export default StyledCollectionSourcesSectionPanel;
