import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSkeleton from 'components/atoms/Skeletons/Skeleton/Skeleton.styles';
import {withMemo} from 'helpers/wrapper';

const Skeleton = withMemo(React.forwardRef((props, ref) => {
  const {
    show,
    ...innerProps
  } = useComponentProps(props, 'Skeleton');

  if (!['text', 'circular', 'rectangular', 'rounded'].includes(innerProps.variant)) {
    innerProps.variant = 'text';
  }

  const filteredProps = Object.keys(innerProps).reduce((o, k) => {
    if (k.startsWith('data-')) {
      o[k] = innerProps[k];
    }

    return o;
  }, {});

  if (show) {
    return <StyledSkeleton ref={ref}
                           {...filteredProps}
                           variant={innerProps.variant}
                           style={innerProps.style}
                           className={innerProps.className}
                           tabIndex="-1">
      {innerProps.children}
    </StyledSkeleton>
  } else {
    return <React.Fragment>{innerProps.children}</React.Fragment>
  }
}));

Skeleton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  show: PropTypes.bool
};

Skeleton.defaultProps = {
  animation: 'wave',
  show: true
};

export default Skeleton;


