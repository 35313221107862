import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';
import {rgba} from 'polished';

const StyledFileField = styled(FormControl)`
  outline: none;
  ${props => props.theme.convert2Css('typography.body1')}
  
  .FileField {
    &-dropzone {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: ${props => props.theme.spacing(0.75)} ${props => props.theme.spacing(1)};
      border-radius: ${props => props.theme.radius(1)};

      aspect-ratio: 552/148;
      max-height: ${props => props.theme.layout(148)};

      cursor: pointer;
      &.disabled {
        cursor: default;
      }

      input {
        display: none;
      }

      .InputOutline {
        border-style: dashed;
      }
      
      &-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        align-items: center;
        justify-content: center;
        gap: ${props => props.theme.layout('1sp')};
        
        .description {
          color: ${props => props.theme.property('palette.text.secondary')};
        }
      }
    }
    
    &-files {
      display: flex;
      flex-direction: column;
    }
    
    &-file {
      display: flex;
      gap: ${props => props.theme.layout('2sp')};
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(2)};
      
      .middle {
        display: flex;
        flex-direction: column;
        min-width: ${props => props.theme.layout(220)};
        
        .Typography {
          max-width: fit-content;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
        
        .TextField, .LinearProgress {
          max-width: ${props => props.theme.layout(220)};
        }
        
        .info {
          display: flex;
          gap: ${props => props.theme.layout('0.5sp')};
          color: ${props => props.theme.property('palette.text.secondary')};
          
          .split {
            transform: translateY(-20%);
          }
        }
        
        .LinearProgress {
          margin-top: ${props => props.theme.spacing(0.5)};
        }
      }
      
      .button {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding: ${props => props.theme.spacing(0.25)};
        
        .IconButton {
          color: ${props => props.theme.property('palette.action.active')};
          
          ${props => props.theme.state2Selector('IconButton', null, ['hover', 'focus', 'focusVisible'])} {
            svg {
              color: ${props => props.theme.property('palette.text.primary')};
            }
          }
        }
      }
    }
  }

  .FormHelperText > * {
    margin-top: ${props => props.theme.spacing(1)};
  }
  
  &.FileField-fullWidth {
    width: 100%;
  }

  &:not(.FileField-disabled) .InputContainer:hover {
    .InputOutline {
      border-color: ${props => props.theme.property('palette.input.outlined.hoverBorder')};
    }
  }
  
  &:not(.FileField-disabled).FileField-focused {
    .InputOutline {
      border-color: ${props => props.theme.property('palette.primary.main')};
      background-color: ${props => props.theme.property('palette.primary.states.selected')};
      border-width: 2px;
    }
  }

  &:not(.FileField-disabled).FileField-error {
    .InputOutline {
      border-color: ${props => props.theme.property('palette.error.main')};
      background-color: ${props => props.theme.property('palette.error.states.hover')};
    }
  }
  
  &.FileField-disabled {
    color: ${props => props.theme.property('palette.text.disabled')};

    .Avatar {
      svg {
        color: ${props => props.theme.property('palette.text.disabled')} !important;
      }
    }
    
    .description {
      color: ${props => props.theme.property('palette.text.disabled')} !important;
    }

    .FileField-dropzone {
      background-color: ${props => rgba(props.theme.property('palette.action.disabledBackground'), 0.01)} !important;
    }
    
    .FileField-file {
      .info {
        color: ${props => props.theme.property('palette.text.disabled')} !important;

        > * {
          color: ${props => props.theme.property('palette.text.disabled')} !important;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFileField.propTypes = {
  theme: PropTypes.object
};

StyledFileField.defaultProps = {
}

export default StyledFileField;
