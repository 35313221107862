import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionsBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionsBarWrapper/CollectionsBarWrapper.styles';
import Add from '@mui/icons-material/Add';
import utils from 'helpers/utils';
import CollectionAddDialog from 'components/organisms/Dialogs/CollectionAddDialog/CollectionAddDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useCollectionAdd} from 'services/collection/collection.hooks';
import {useLinkNavigate} from 'helpers/hooks/links';

const CollectionsBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionsBarWrapper');

  const title = 'Collections';

  const dialogControl = useDialogControl();
  const navigate = useLinkNavigate();

  const addCollection = useCollectionAdd();

  const rightActions = useMemo(() => [
    {
      label: 'Add collection',
      icon: Add,
      auth: utils.createAuth({ attribute: 'collection.create' }),
      onClick: () => {
        const handleSubmit = (collection) => {
          return addCollection.mutation.mutateAsync(collection)
            .then(({response}) => {
              const newCollection = utils.camelcase(response.data?.data);
              navigate({to: `/collections/${newCollection.collectionId}/entities`});
            });
        };

        dialogControl.show(<CollectionAddDialog onSubmit={handleSubmit} />, true);
      },
      ActionButtonProps: {
        color: 'success',
        radius: 'round'
      }
    }
  ], [navigate, dialogControl, addCollection.mutation]);

  return <StyledCollectionsBarWrapper ref={ref} {...innerProps}
                                      ToolbarProps={{
                                        title,
                                        rightActions
                                      }}>
    {innerProps.children}
  </StyledCollectionsBarWrapper>
});

CollectionsBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsBarWrapper.defaultProps = {
  children: 'CollectionsBar Wrapper text',
};

export default CollectionsBarWrapper;
