import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import StyledComponentField from 'components/molecules/Fields/ComponentField/ComponentField.styles';
import {default as ComponentBase} from 'components/organisms/Utils/Component/Component';
import {withMemo} from 'helpers/wrapper';

const ComponentField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    size,
    inset,
    required,
    autoFocus,
    helperText,
    onChange,
    onBlur,
    valueProp,
    Component,
    ComponentProps,
    FormHelperTextProps,
    InputLabelProps,
    ...innerProps
  } = useComponentProps(props, 'ComponentField', {
    static: ['inset', 'disabled', 'focused', 'error'],
    children: ['component', 'label', 'helper']
  });

  const innerRef = useRef(null);
  const valueRef = useRef({
    errors: false,
    ...value
  });
  const [internalValue, setInternalValue] = useState(valueRef.current);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('.ComponentField-container'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const onChangeEvent = useEffectEvent(onChange);
  const onBlurEvent = useEffectEvent(onBlur);

  const handleChange = useCallback((value) => {
    valueRef.current = {
      value,
      errors: false
    };

    setInternalValue(utils.updater({value, errors: false}, true));

    onChangeEvent?.({
      target: {
        name: name,
        value: valueRef.current
      }
    });
  }, [onChangeEvent, name]);

  const handleBlur = useCallback((e) => {
    if (!e || !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
      onBlurEvent?.({
        target: {
          name: name,
          value: valueRef.current
        }
      });
    }
  }, [onBlurEvent, name]);

  const handleErrors = useCallback((errors) => {
    if (internalValue.errors !== errors) {
      valueRef.current = {
        value: valueRef.current.value,
        errors
      };

      setInternalValue(utils.updater({errors}, true));

      onChangeEvent?.({
        target: {
          name: name,
          value: valueRef.current
        }
      });
    }
  }, [onChangeEvent, name, internalValue.errors]);

  const valueMemo = useEffectItem(value);
  useLayoutEffect(() => {
    if (!valueMemo.errors) {
      valueRef.current = valueMemo;
      setInternalValue(utils.updater(valueMemo, true));
    }
  }, [valueMemo]);

  const valuePropObj = useMemo(() => ({
      [valueProp]: internalValue?.value
  }), [valueProp, internalValue?.value]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledComponentField ref={innerRef} {...innerProps}>
    <InputLabel {...utils.cleanObject({
                  htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                  shrink: innerProps.readOnly || innerProps.disabled || null
                })}
                {...InputLabelProps}
                className={utils.classNames('ComponentField-label', InputLabelProps?.className)}>
      {label}
    </InputLabel>
    <InputContainer className="ComponentField-container">
      <ComponentBase Original={Component}
                     className="ComponentField-component"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     onErrors={handleErrors}
                     error={required ?? innerProps.error}
                     success={innerProps.success}
                     readOnly={innerProps.readOnly}
                     disabled={innerProps.disabled}
                     {...valuePropObj}
                     {...ComponentProps} />
      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('ComponentField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledComponentField>
}));

ComponentField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  inset: PropTypes.bool,
  size: PropTypes.string,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  valueProp: PropTypes.string,
  Component: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  ComponentProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object
};

ComponentField.defaultProps = {
};

export default ComponentField;
