import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import QueryBar from 'components/organisms/Bars/QueryBar/QueryBar';

const StyledEntitiesQueryBar = styled(QueryBar)`
  ${ComponentStyles}
`;

StyledEntitiesQueryBar.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesQueryBar.defaultProps = {
};

export default StyledEntitiesQueryBar;
