import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import {useServiceStatus} from 'stores/hooks/service';
import utils from 'helpers/utils';
import {useAuthHolderToken} from 'services/auth/auth.utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useAuthStatus() {
  return useServiceStatus('auth');
}

export function useAuthLogin(mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/login',
    ...mutationOptions
  });
}

export function useAuthResetPassword (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/reset',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useAuthResetHash (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/resetHash',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useAuthPassword (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/password',
    ...mutationOptions
  });
}

export function useAuthRenew (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/renew?refresh=:refresh',
    ...mutationOptions
  });
}

export function useAuthLogout(mutationOptions = {}) {
  return useServiceApiMutation(path, 'logout', {}, {
    overridePath: 'auth/logout',
    ...mutationOptions
  });
}

export function useAuthPassLogin(mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'auth/pass/:userId/login',
    ...mutationOptions
  });
}

export function useAuthPassLogout(mutationOptions = {}) {
  const holderToken = useAuthHolderToken();

  return useServiceApiMutation(path, 'post', {}, {
    overrideApiToken: holderToken,
    overridePath: 'auth/renew',
    ...mutationOptions
  });
}

export function useAuthDataTokenGet (apiParams = {}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', apiParams, {
    overridePath: 'auth/dataToken',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useAuthPortalTokenGet (apiParams = {}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', apiParams, {
    overridePath: 'auth/portalToken',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
