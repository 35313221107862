import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntitiesTableColumns
  from 'components/organisms/TableColumns/DatabaseEntitiesTableColumns/DatabaseEntitiesTableColumns.styles';

const DatabaseEntitiesTableColumns = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTableColumns');

  return <StyledDatabaseEntitiesTableColumns ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledDatabaseEntitiesTableColumns>
});

DatabaseEntitiesTableColumns.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTableColumns.defaultProps = {};

export default DatabaseEntitiesTableColumns;
