import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledToggleButton from 'components/atoms/Toggles/ToggleButton/ToggleButton.styles';
import {withMemo} from 'helpers/wrapper';

const ToggleButton = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ToggleButton');

  return <StyledToggleButton ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledToggleButton>
}));

ToggleButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ToggleButton.defaultProps = {
  children: 'ToggleButton text'
};

export default ToggleButton;
