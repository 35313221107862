import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const StyledListItemAvatar = styled(ListItemAvatar)` 
  ${ComponentStyles}
`;

StyledListItemAvatar.propTypes = {
  theme: PropTypes.object
};

StyledListItemAvatar.defaultProps = {}

export default StyledListItemAvatar;
