import React, {useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesSuggestionsDialog
  from 'components/organisms/Dialogs/CollectionEntitiesSuggestionsDialog/CollectionEntitiesSuggestionsDialog.styles';
import CollectionEntityBrowserBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityBrowserBarWrapper/CollectionEntityBrowserBarWrapper';
import CollectionEntitiesSuggestionBrowser from 'components/organisms/Browsers/CollectionEntitiesSuggestionBrowser/CollectionEntitiesSuggestionBrowser';
import CollectionEntitySideBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntitySideBarWrapper/CollectionEntitySideBarWrapper';
import CollectionEntitiesTableProvider
  from 'components/organisms/Providers/TableProvider/CollectionEntitiesTableProvider';
import CollectionEntityProfileProvider
  from 'components/organisms/Providers/ProfileProvider/CollectionEntityProfileProvider';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import CollectionEntitiesSuggestionsBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesSuggestionsBarWrapper/CollectionEntitiesSuggestionsBarWrapper';
import utils from 'helpers/utils';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';

const CollectionEntitiesSuggestionsDialog = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesSuggestionsDialog');

  const innerRef = useRef(null);
  const [entityId, setEntityId] = useState(null);
  const [filter, setFilter] = useState([]);

  useImperativeHandle(ref, () => innerRef.current);

  const tableProvider = useTable();
  const profileProvider = useProfile();
  const collection = profileProvider.data?.data;

  const snackbar = useSnackbar();
  const navigate = useLinkNavigate();

  const showScore = collection?.tagGroups?.some((tg) => tg.hasPoints);

  const tableAction = {
    onClick: (e) => {
      navigate({
        event: e,
        to: `?custom=suggestions`,
        keepSearchParams: false
      });
      innerProps.onClose?.(e, 'closeButtonClick');
      e.preventDefault();
    }
  };

  const nextAction = {
    onClick: (e, entity) => {
      setEntityId(entity.entityId);
      e.preventDefault();
    }
  };

  const prevAction = {
    onClick: (e, entity) => {
      setEntityId(entity.entityId);
      e.preventDefault();
    }
  };

  const handleOpen = (id, settings) => {
    tableProvider.openProfile?.(id, settings);
  }

  const handleChange = (entity, count) => {
    setFilter((current) => current.concat(entity));

    if (count <= 0) {
      innerProps.onClose?.(null, 'closeButtonClick');
      snackbar.show('You have processed all the suggestions for this collection', null,
        {color: 'success', autoHideDuration: constants.delay.success});
    }
  };

  return <StyledCollectionEntitiesSuggestionsDialog ref={innerRef} {...innerProps}>
    <CollectionEntitiesTableProvider entityId={entityId}
                                     skipStorage={true}
                                     skipView={true}
                                     defaults={{
                                       list: {
                                         filter: [
                                           {id: 'scope', value: constants.collection.scopes.outside}
                                         ].concat(
                                           !utils.isDefined(constants.collection.suggestion.threshold) ? [] :
                                             {id: 'collection_analysis_points_basic', value:`${constants.collection.suggestion.threshold}:${constants.numbers.maxInt}`}
                                         ),
                                         sort: [
                                           {id: 'collection_analysis_similarity', desc: true},
                                           {id: 'collection_analysis_points_normalized', desc: true},
                                           {id: 'added_at', desc: true}
                                         ],
                                         feedUpdatedAt: collection?.feedUpdatedAt
                                       }
                                     }}>
      <CollectionEntityProfileProvider entityId={entityId}>
        <CollectionEntitiesSuggestionsBarWrapper>
          <CollectionEntitySideBarWrapper stateKey="browser"
                                          size="smaller"
                                          CollectionEntitiesListProps={{
                                            showHeader: false,
                                            overflowHeader: true,
                                            selectFirst: true,
                                            filter: filter,
                                            EntityCardProps: {
                                              variant: 'scoring',
                                              showScore: showScore,
                                              showStatus: false,
                                              showRelevancy: false
                                            },
                                            onClick: (e, entity) => {
                                              setEntityId(entity.entityId);
                                              e.preventDefault();
                                            }
                                          }}>
              <CollectionEntityBrowserBarWrapper ToolbarProps={{
                                                   tableAction,
                                                   nextAction,
                                                   prevAction,
                                                   showScore,
                                                   density: 'sparse',
                                                   onOpen: handleOpen
                                                 }}>
                <CollectionEntitiesSuggestionBrowser onChange={handleChange}/>
              </CollectionEntityBrowserBarWrapper>
          </CollectionEntitySideBarWrapper>
        </CollectionEntitiesSuggestionsBarWrapper>
      </CollectionEntityProfileProvider>
    </CollectionEntitiesTableProvider>
  </StyledCollectionEntitiesSuggestionsDialog>
});

CollectionEntitiesSuggestionsDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesSuggestionsDialog.defaultProps = {};

export default CollectionEntitiesSuggestionsDialog;
