import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import StyledFooterBar from 'components/organisms/Bars/FooterBar/FooterBar.styles';

const FooterBar = React.forwardRef((props, ref) => {
  const {
    title,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'FooterBar');

  const renderTitle = () => {
    return utils.isReactElement(title) ? title :
        <H6 isLoading={isLoading} min={8} max={20}>{title}</H6>;
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledFooterBar ref={ref} {...innerProps}
                          title={title ? renderTitle() : null}
                          showViewBar={false}
                          isLoading={isLoading} />
});

FooterBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'inset']), PropTypes.string])
};

FooterBar.defaultProps = {
  color: 'primary'
};

export default FooterBar;
