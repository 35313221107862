import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowDashboardCard from 'components/organisms/Cards/DealflowDashboardCard/DealflowDashboardCard.styles';
import DealflowPieChart from 'components/organisms/Charts/DealflowPieChart/DealflowPieChart';
import {useClientStatisticDealflow} from 'services/client/statistic/statistic.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';

const DealflowDashboardCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowDashboardCard');

  const [dealflow, setDealflow] = useState(null);
  const [dealflowBreakdown, isLoading] = useClientStatisticDealflow();

  const navigate = useLinkNavigate();

  useEffect(() => {
    if (dealflowBreakdown) {
      setDealflow(dealflowBreakdown
        .filter((df) => df.id > 0)
        .map((df) => ({
          count: df.value,
          label: df.label,
          status: df.id
        })));
    }
  }, [dealflowBreakdown]);

  const handleClick = (e, release, data) => {
    if (data?.id) {
      const prefix = data?.id;
      navigate({
        event: e,
        to: `/dealflow/table?custom=dealflow:${prefix}_-1`,
        keepSearchParams: true
      });
    }
  };

  return <StyledDealflowDashboardCard ref={ref} {...innerProps}
                                      title="Dealflow summary">
    {(dealflow?.length > 0 || isLoading) ?
      <DealflowPieChart dealflow={dealflow}
                        showNoDealflow={false}
                        onClick={handleClick}
                        isLoading={isLoading} /> : null}

    {(dealflow?.length === 0 && !isLoading) ?
      <Box className="DashboardCard-empty">
        <P>No dealflow data found</P>
      </Box> : null}
  </StyledDealflowDashboardCard>
});

DealflowDashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowDashboardCard.defaultProps = {};

export default DealflowDashboardCard;
