import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';
import {rgba} from 'polished';

const StyledWizard = styled(ToolbarWrapper)`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  &.Wizard {
    .PageBar {
      height: ${props => props.theme.layout(67)};
      border-top: unset;

      &-toolbar {
        padding: 0 ${props => props.theme.spacing(3)};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
  }
  
  .Wizard {
    &-content {
      position: relative;
      padding: ${props => props.theme.spacing(3)};

      .notistack-SnackbarContainer {
        position: absolute;
        left: ${props => props.theme.spacing(1.5)};
        bottom: ${props => props.theme.spacing(1.25)};
      }
    }
    
    &-step {
      height: 100%;
      width: 100%;
      display: flex;
      
      &-content {
        flex-grow: 1;
      }
    }
    
    &-info {
      position: fixed;
      top: calc(${props => props.theme.spacing(3)} + ${props => props.$wrapper?.boundsCum?.top ?? '0px'});
      right: calc(${props => props.theme.spacing(3)} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'});
      ${props => props.theme.breakpoints.down('lg')} {
        display: none;
      }
      
      &-content {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)}
                 ${props => props.theme.spacing(4)} ${props => props.theme.spacing(3)};
        row-gap: ${props => props.theme.layout('2sp')};
        border-radius: ${props => props.theme.radius(2)};
        width: ${props => props.theme.layout(423)};
        background-color: ${props => props.theme.property('palette.primary.main')};
        color: ${props => props.theme.property('palette.primary.contrastText')};
  
        &-backgroundIcon {
          position: absolute;
          font-size: ${props => props.theme.fontSize(40)};
          top: ${props => props.theme.layout(24)};
          right: ${props => props.theme.layout(24)};
        }

        &-stepper {
          row-gap: ${props => props.theme.layout('0.5sp')};
          .MuiStep-root {
            max-height: ${props => props.theme.layout(20)};
            min-height: ${props => props.theme.layout(20)};
          }
          .MuiStepLabel-root {
            padding: 0;
            margin-top: ${props => props.theme.layout(2)};
            .MuiStepIcon-root {
              font-size: ${props => props.theme.fontSize(16)};
              color: ${props => props.theme.property('palette.primary.dark')};
            }
            .Mui-completed {
              color: ${props => props.theme.property('palette.primary.contrastText')};
              font-weight: normal;
            }
            .MuiStepLabel-label {
              color: ${props => props.theme.property('palette.primary.contrastText')};
              
              &.Mui-active {
                font-weight: 900;
              }
            }
          }

          &-stepLabel-disabled {
            .MuiStepLabel-iconContainer.Mui-completed, .MuiStepLabel-labelContainer {
              * {
                color: ${props => rgba(props.theme.property('palette.primary.contrastText'), props.theme.property('palette.action.disabledOpacity'))} !important;
              }
            }
          }
        }
      }
    }
    
    &-navigation {
      &-progress {
        padding: 0;
        width: 100%;
        background-color: transparent;
        margin-bottom: ${props => props.theme.layout(-1)};
        
        .MuiMobileStepper-progress {
          width: 100%;
          height: ${props => props.theme.layout(2)};
          background-color: unset;

          .MuiMobileStepper-progress-bar {
            height: ${props => props.theme.layout(2)};
          }
        }
      }
      
      &-stepper {
        display: flex;
        align-items: center;
        height: ${props => props.theme.layout(65)};
        
        > .Stepper {
          flex-grow: 1;
          justify-content: center;
        }
        
        .MuiStepLabel-iconContainer {
          .Mui-completed {
            color: ${props => props.theme.property('palette.success.main')};
          }
        }

        .MuiStepLabel-root {
          .MuiStepLabel-label {
            font-weight: 500;
            &.Mui-active {
              font-weight: 900;
            }
          }
        }
        
        &-stepLabel-disabled {
          .MuiStepLabel-iconContainer, .MuiStepLabel-labelContainer {
            * {
              color: ${props => props.theme.property('palette.text.disabled')} !important;
            }
          }
        }
      }
    }
  }

  &.Wizard-simple {
    .Wizard {
      &-content {
        padding: 0;
      }
    }
    
    // > .ToolbarWrapper-content {
    //   margin: 0 0 ${props => props.theme.layout(1)} 0 !important;
    //   scroll-margin: 0 0 ${props => props.theme.layout(1)} 0 !important;
    // }

    .PageBar {
      height: fit-content;
      border-top: unset !important;
      margin-top: ${props => props.theme.layout(-1)};
      margin-bottom: ${props => props.theme.layout(-1)};
      background-color: transparent;

      &-toolbar {
        height: fit-content;
      }
    }
  }
  
  ${props => props.$dialog ? css`
    > .Wrapper {
      overflow: hidden;

      .Wizard-content {
        overflow: auto;
        height: 100%;

        .Wizard-step {
          height: fit-content;
        }
      }
    }
    
    .Wizard-navigation-stepper {
      padding-left: ${props => props.theme.spacing(2)};
      padding-right: ${props => props.theme.spacing(2)};
    }
  ` : css``}
  
  ${ComponentStyles}
`;

StyledWizard.propTypes = {
  theme: PropTypes.object
};

StyledWizard.defaultProps = {}

export default StyledWizard;
