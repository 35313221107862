import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Box from 'components/atoms/Layout/Box/Box';
import RelevancyDistributionPieChart
  from 'components/organisms/Charts/RelevancyDistributionPieChart/RelevancyDistributionPieChart';
import {useClientStatisticRelevancyDistribution} from 'services/client/statistic/statistic.utils';
import {useAuthIsProxyClient} from 'services/auth/auth.utils';
import StyledCollectionEntitiesRelevancyDistributionGraphCard
  from 'components/organisms/Cards/CollectionEntitiesRelevancyDistributionGraphCard/CollectionEntitiesRelevancyDistributionGraphCard.styles';
import Table from 'components/organisms/Tables/Table/Table';
import EntityRelevancyTableCell
  from 'components/molecules/TableCells/EntityRelevancyTableCell/EntityRelevancyTableCell';
import LinkTableCell from 'components/molecules/TableCells/LinkTableCell/LinkTableCell';
import ScoreTableCell from 'components/molecules/TableCells/ScoreTableCell/ScoreTableCell';
import Typography, {H6, P} from 'components/atoms/Text/Typography/Typography';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import {useLinkNavigate} from 'helpers/hooks/links';

const CollectionEntitiesRelevancyDistributionGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesRelevancyDistributionGraphCard');

  const proxyClient = useAuthIsProxyClient();

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const graphState = tableProvider.graphState;

  const [distribution, setDistribution] = useState(null);

  const navigate = useLinkNavigate();

  const listState = tableProvider.appliedListState();
  const distributionType = graphState.graphSettings['relevancyDistribution']?.filter?.distributionType ??
    constants.data.relevancyDistributionTypes.filter((opt) => proxyClient || opt.value !== 'teamVsExternal')[0].value;

  const [relevancyDistribution, isLoading] = useClientStatisticRelevancyDistribution(
    collection?.collectionId, listState.search, listState.filter, {
      enabled: collection?.collectionId > 0
    });

  const [distributionMemo, total] = useMemo(() => {
    if (relevancyDistribution) {
      let total;
      const distribution = [];
      if (distributionType === constants.client.relevancyDistributionType.teamVsExternal) {
        const entities = relevancyDistribution.relevancyDistribution.filter((e) => +e.relevancyUs > 0 && +e.relevancyClient > 0);
        const consensus = entities.filter((e) => +e.relevancyUs === +e.relevancyClient);
        const disagreement = entities.filter((e) => +e.relevancyUs !== +e.relevancyClient);

        const getBreakdown = (entities, consensus) => {
          return entities.reduce((a, e) => {
            const team = +e.relevancyClient;
            const other = +e.relevancyUs;

            let found = a.find((a) => a.team === team && a.other === other);
            if (!found) {
              found = {
                team,
                other,
                consensus,
                type: constants.client.relevancyDistributionType.teamVsExternal,
                count: 0
              };
              a.push(found);
            }
            found.count += 1
            found.percentage = found.count / entities.length * 100;

            return a;
          }, []).sort((a, b) => b.team - a.team);
        }

        distribution.push({
          label: 'Consensus',
          id: 'consensus',
          position: 0,
          explanation: '(more than 50% has the same relevance)',
          color: innerProps.theme.property('palette.success.main'),
          count: consensus.length,
          breakdown: getBreakdown(consensus, true)
        });

        distribution.push({
          label: 'Disagreement',
          id: 'disagreement',
          position: 1,
          explanation: '(less than 50% has the same relevance)',
          color: innerProps.theme.property('palette.error.main'),
          count: disagreement.length,
          breakdown: getBreakdown(disagreement, false)
        });

        total = entities.length;
      } else {
        const entities = relevancyDistribution.relevancyDistribution.filter((e) => +e.relevancyClient > 0);
        const consensus = entities.filter((e) => {
          const all = +e.low + +e.medium + +e.high;
          const cnt = e.relevancyClient === 1 ? +e.low : ((e.relevancyClient === 2) ? +e.medium : +e.high);
          return ((all > 0 ? (cnt / all) : 0) > 0.5);
        });
        const disagreement = entities.filter((e) => {
          const all = +e.low + +e.medium + +e.high;
          const cnt = e.relevancyClient === 1 ? +e.low : ((e.relevancyClient === 2) ? +e.medium : +e.high);
          return ((all > 0 ? (cnt / all) : 0) <= 0.5);
        });

        const getBreakdown = (entities, consensus) => {
          return entities.reduce((a, e) => {
            const team = +e.relevancyClient;
            const other = (+e.low > +e.medium && +e.low > +e.high) ? 1 : (
              (+e.medium > +e.low && +e.medium > +e.high) ? 2 : (
                (+e.high > +e.low && +e.high > +e.medium) ? 3 : -1
              )
            );

            let found = a.find((a) => a.team === team && a.other === other);
            if (!found) {
              found = {
                id: `${team}_${other}`,
                team,
                other,
                consensus,
                type: constants.client.relevancyDistributionType.teamMembers,
                count: 0
              };
              a.push(found);
            }
            found.count += 1
            found.percentage = found.count / entities.length * 100;

            return a;
          }, []).sort((a, b) => b.other - a.other);
        }

        distribution.push({
          label: 'Consensus',
          id: 'consensus',
          position: 0,
          explanation: '(more than 50% has the same relevance)',
          color: innerProps.theme.property('palette.success.main'),
          count: consensus.length,
          breakdown: getBreakdown(consensus, true)
        });
        distribution.push({
          label: 'Disagreement',
          id: 'disagreement',
          position: 1,
          explanation: '(less than 50% has the same relevance)',
          color: innerProps.theme.property('palette.error.main'),
          count: disagreement.length,
          breakdown: getBreakdown(disagreement, false)
        });

        total = entities.length;
      }

      return [distribution, total];
    } else {
      return [null, 0];
    }
  }, [relevancyDistribution, distributionType, innerProps.theme]);

  useEffect(() => {
    if (distributionMemo) {
      setDistribution({
        distribution: distributionMemo,
        total: total
      });
    }
  }, [distributionMemo, total]);

  const fields = useMemo(() => {
    const options = constants.data.relevancyDistributionTypes.filter((opt) => proxyClient || opt.value !== 'teamVsExternal');

    const initialOption = options.find((opt) => opt.value === distributionType);

    return [{
      name: 'distributionType',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a category',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: options
    }];
  }, [distributionType, proxyClient]);

  const tableColumns = useMemo(() => {
    return [
      {
        accessorKey: 'team',
        id: 'team',
        minSize: 0,
        size: 67,
        header: distributionType === constants.client.relevancyDistributionType.teamVsExternal ? 'Team' : 'Average',
        Cell: ({cell}) => {
          const relevancy = cell.getValue();
          const tie = relevancy === -1;

          return <EntityRelevancyTableCell relevancy={tie ? 0 : relevancy}
                                           tie={tie}
                                           disabled={false}
                                           size="small"/>
        }
      },
      {
        accessorKey: 'other',
        id: 'other',
        minSize: 0,
        size: 67 + 16,
        header: distributionType === constants.client.relevancyDistributionType.teamVsExternal ? 'External' : 'Majority',
        Cell: ({cell}) => {
          const relevancy = cell.getValue();
          const tie = relevancy === -1;

          return <EntityRelevancyTableCell relevancy={tie ? 0 : relevancy}
                                           tie={tie}
                                           disabled={false}
                                           size="small"/>
        }
      },
      {
        accessorKey: 'count',
        id: 'companies',
        header: '',
        Cell: ({cell, row}) => {
          const count = cell.getValue();
          const prefix = (row.original.consensus ? 'c' : 'd') + '_' + (row.original.type === constants.client.relevancyDistributionType.teamVsExternal ?
            'e' : 'i');

          const ActionLinkProps = {
            action: {
              label: `${count} companies`,
              navigation: {
                to: `/collections/${collection.collectionId}/entities?custom=relevancy:${prefix}_${+row.original.team}_${+row.original.other >= 0 ? +row.original.other : 0}`,
                keepSearchParams: true
              }
            }
          }

          return <LinkTableCell ActionLinkProps={ActionLinkProps} />
        }
      },
      {
        accessorKey: 'percentage',
        id: 'percentage',
        header: '',
        minSize: 0,
        size: 134 + 8, // see also render...table
        Cell: ({cell}) => {
          const percentage = cell.getValue();

          const ScoreLinearProgressProps = {
            score: percentage,
            size: 'small',
            color: 'primary',
            showEmpty: true
          }

          return <ScoreTableCell ScoreLinearProgressProps={ScoreLinearProgressProps} />
        }
      }
    ];
  }, [distributionType, collection]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'relevancyDistribution': {
        ...current?.['relevancyDistribution'],
        filter: {
          ...current?.['relevancyDistribution']?.filter,
          distributionType: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'relevancyDistribution': {
        ...current?.['relevancyDistribution'],
        visibility
      }
    }));
  }

  const renderConsensusTable = () => {
    const data = distributionMemo?.find((d) => d.id === 'consensus');
    const visible = graphState.graphSettings['relevancyDistribution']?.visibility?.['consensus'] ?? true;

    if ((data?.breakdown?.length > 0 || isLoading) && visible) {
      return <Box className="CollectionEntitiesRelevancyDistributionGraphCard-consensus">
        <Box className="CollectionEntitiesRelevancyDistributionGraphCard-consensus-header">
          <H6 isLoading={isLoading} min={12} max={20}>{data?.label}</H6>
          <Box className="CollectionEntitiesRelevancyDistributionGraphCard-consensus-header-subtitle">
            <Typography variant="caption"
                        color="text.secondary"
                        min={30} max={40}
                        isLoading={isLoading}>{data?.explanation}</Typography>
            <ScoreLinearProgress size="small"
                                 color="success"
                                 score={total > 0 ? ((data?.count ?? 0) / total * 100) : 0}
                                 showEmpty={true}
                                 isLoading={isLoading} />
          </Box>
        </Box>
        <Table className="CollectionEntitiesRelevancyDistributionGraphCard-consensus-table"
               dataKey="id"
               enableEditing={false}
               enableTableHead={true}
               enableParentScroll={false}
               enableRowVirtualization={false}
               enableColumnVirtualization={false}
               enableColumnActions={false}
               columns={tableColumns}
               data={data?.breakdown}
               rowCount={data?.breakdown?.length}
               debounce={false}
               state={{
                 isLoading: isLoading,
                 pagination: {
                   pageIndex: 0,
                   pageSize: data?.breakdown?.length || 2
                 }
               }}/>
      </Box>
    }
  }

  const renderDisagreementTable = () => {
    const data = distributionMemo?.find((d) => d.id === 'disagreement');
    const visible = graphState.graphSettings['relevancyDistribution']?.visibility?.['disagreement'] ?? true;

    if ((data?.breakdown?.length > 0 || isLoading) && visible) {
      return <Box className="CollectionEntitiesRelevancyDistributionGraphCard-disagreement">
        <Box className="CollectionEntitiesRelevancyDistributionGraphCard-disagreement-header">
          <H6 isLoading={isLoading} min={12} max={20}>{data?.label}</H6>
          <Box className="CollectionEntitiesRelevancyDistributionGraphCard-disagreement-header-subtitle">
            <Typography variant="caption"
                        color="text.secondary"
                        min={30} max={40}
                        isLoading={isLoading}>{data?.explanation}</Typography>
            <ScoreLinearProgress size="small"
                                 color="error"
                                 score={total > 0 ? ((data?.count ?? 0) / total * 100) : 0}
                                 showEmpty={true}
                                 isLoading={isLoading} />
          </Box>
        </Box>
        <Table className="CollectionEntitiesRelevancyDistributionGraphCard-disagreement-table"
               dataKey="id"
               enableEditing={false}
               enableTableHead={true}
               enableParentScroll={false}
               enableRowVirtualization={false}
               enableColumnVirtualization={false}
               enableColumnActions={false}
               columns={tableColumns}
               data={data?.breakdown}
               rowCount={data?.breakdown?.length}
               debounce={false}
               state={{
                 isLoading: isLoading,
                 pagination: {
                   pageIndex: 0,
                   pageSize: data?.breakdown?.length || 2
                 }
               }}/>
      </Box>
    }
  }

  const handleClick = (e, release, data) => {
    if (data?.id) {
      const prefix = (data?.id === 'disagreement' ? 'd' : 'c') + '_' + (
        distributionType === constants.client.relevancyDistributionType.teamVsExternal ? 'e' : 'i'
      );
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities?custom=relevancy:${prefix}_0_0`,
        keepSearchParams: true
      });
    }
  }

  return <StyledCollectionEntitiesRelevancyDistributionGraphCard ref={ref} {...innerProps}
                                                                 title="Relevance distribution"
                                                                 context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                                fields={fields} /> : null)}>
    <Box className="CollectionEntitiesRelevancyDistributionGraphCard-content">
      {(distribution?.total > 0 || isLoading) ? <Box className="CollectionEntitiesRelevancyDistributionGraphCard-chart">
        <RelevancyDistributionPieChart distribution={distribution?.distribution}
                                       total={distribution?.total}
                                       showName={true}
                                       showLegend={true}
                                       orientation="horizontal"
                                       isLoading={isLoading}
                                       onClick={handleClick}
                                       visibility={graphState.graphSettings['relevancyDistribution']?.visibility}
                                       onVisibilityChange={handleVisibilityChange}/>
      </Box> : null}
      {(distribution?.total > 0 || isLoading) ? <Box className="CollectionEntitiesRelevancyDistributionGraphCard-tables">
        {renderConsensusTable()}
        {renderDisagreementTable()}
      </Box> : null}

      {(distribution?.total === 0 && !isLoading) ? <Box className="GraphCard-empty">
        <P>No relevance data to compare found</P>
      </Box> : null}
    </Box>
  </StyledCollectionEntitiesRelevancyDistributionGraphCard>
});

CollectionEntitiesRelevancyDistributionGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesRelevancyDistributionGraphCard.defaultProps = {};

export default CollectionEntitiesRelevancyDistributionGraphCard;
