import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';
import {processCollectionEntity} from 'services/collection/entity/entity.utils';

const path = utils.servicePath(import.meta.url);

export class CollectionEntityService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'entityId',
      entity: 'entity',
      list: 'entities',
      separateEntityData: true, // single item contain more info than the list
      invalidateParent: true, // collection contains items count so update it
      invalidateFromParent: true,
      overrideEntityPath: 'entities/:entityId?collectionId=:collectionId&returnObject=true',
      refreshEntityParams: { scope: constants.collection.scopes.all },
      refreshListParams: { scope: constants.collection.scopes.all },
      match: (cache, entity, id) => {
        return +cache.data?.entityId === +id || +cache.data?.originalEntityId === +id || +cache.data?.stackId === +id;
      },
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processCollectionEntity);
          } else {
            return processCollectionEntity(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
