import React, {useImperativeHandle, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledActionToggleButtonGroup
  from 'components/molecules/Toggles/ActionToggleButtonGroup/ActionToggleButtonGroup.styles';
import ActionToggleButton from 'components/molecules/Toggles/ActionToggleButton/ActionToggleButton';

const ActionToggleButtonGroup = React.forwardRef((props, ref) => {
  const {
    actions,
    value,
    exclusive,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'ActionToggleButtonGroup');

  const [internalState, setInternalState] = useState({value: null});

  const actionToggleButtonGroup = useMemo(() => ({
    state: {
      ...internalState,
      ...utils.cleanObject({value})
    }
  }), [internalState, value]);

  useImperativeHandle(ref, () => actionToggleButtonGroup);

  const handleToggleChange = (e, value) => {
    onChange?.(value);
    setInternalState((current) => {
      const oldVal = utils.toArray(current.value).sort((a, b) => {
        return utils.sha1(a).localeCompare(utils.sha1(b));
      });
      const newVal = utils.toArray(value).sort((a, b) => {
        return utils.sha1(a).localeCompare(utils.sha1(b));
      });

      if (!utils.compare(oldVal, newVal)) {
        return {...current, value: exclusive ? (newVal.length > 0 ? newVal[0] : null) :
            utils.uniqueArray([...oldVal, ...newVal])};
      } else {
        return current;
      }
    });
  }

  const handleToggleButtonRef = (value, toggleButton) => {
    if (toggleButton?.active) {
      handleToggleChange(null, value);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledActionToggleButtonGroup {...innerProps}
                                        onChange={handleToggleChange}
                                        exclusive={exclusive}
                                        value={actionToggleButtonGroup.state.value}>
    {actions.map((action, idx) => <ActionToggleButton key={idx}
                                                      ref={(toggleButton) => handleToggleButtonRef(idx, toggleButton)}
                                                      value={idx}
                                                      action={action}
                                                      {...action.ToggleButtonProps} />)}
  </StyledActionToggleButtonGroup>
});

ActionToggleButtonGroup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  value: PropTypes.any,
  exclusive: PropTypes.bool,
  onChange: PropTypes.func
};

ActionToggleButtonGroup.defaultProps = {
  actions: [],
  exclusive: true
};

export default ActionToggleButtonGroup;
