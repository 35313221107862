import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Switch from '@mui/material/Switch';
import constants from 'helpers/constants';

const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase {
    ${props => props.theme.color2Css('MuiSwitch-switchBase', null, 'switch', {
      interactive: true,
      type: constants.colorTypes.accentBackground
    })};
  }

  height: ${props => props.theme.layout(34)};
  width: ${props => props.theme.layout(50)};
  padding: ${props => props.theme.layout(10)} ${props => props.theme.layout(9)} ${props => props.theme.layout(10)} ${props => props.theme.layout(8)};
  .MuiSwitch-switchBase {
    padding: ${props => props.theme.layout(5)};
    margin-top: ${props => props.theme.layout(3)};
  }
  .MuiSwitch-thumb {
    height: ${props => props.theme.layout(18)};
    width: ${props => props.theme.layout(18)};
  }
  
  &.Switch-size-small {
    height: ${props => props.theme.layout(26)};
    width: ${props => props.theme.layout(44)};
    padding: ${props => props.theme.layout(8)};
    .MuiSwitch-switchBase {
      padding: ${props => props.theme.layout(5)};
      margin-top: 0;
    }
    .MuiSwitch-thumb {
      height: ${props => props.theme.layout(16)};
      width: ${props => props.theme.layout(16)};
    }
  }
  
  ${ComponentStyles}
`;

StyledSwitch.propTypes = {
  theme: PropTypes.object
};

StyledSwitch.defaultProps = {}

export default StyledSwitch;
