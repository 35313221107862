import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import EntityRelevancyTableCell from 'components/molecules/TableCells/EntityRelevancyTableCell/EntityRelevancyTableCell';
import StyledEntityTeamRelevancyTableCellEdit
  from 'components/organisms/TableCellEdits/EntityTeamRelevancyTableCellEdit/EntityTeamRelevancyTableCellEdit.styles';
import RelevancyBreakDownChart from 'components/organisms/Charts/RelevancyBreakDownChart/RelevancyBreakDownChart';
import {withMemo} from 'helpers/wrapper';

const EntityTeamRelevancyTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    relevancy,
    relevancies,
    size,
    ...innerProps
  } = useComponentProps(props, 'EntityTeamRelevancyTableCellEdit');

  return <StyledEntityTeamRelevancyTableCellEdit ref={ref} {...innerProps}
                                                 cell={cell}
                                                 table={table}
                                                 TableCellPopperProps={{
                                                   density:'densest'
                                                 }}
                                                 Anchor={<EntityRelevancyTableCell relevancy={relevancy}
                                                                                   active={true}
                                                                                   size={size}/>}>
    <RelevancyBreakDownChart relevancies={relevancies} />
  </StyledEntityTeamRelevancyTableCellEdit>
}));

EntityTeamRelevancyTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  relevancy: PropTypes.number,
  size: PropTypes.string
};

EntityTeamRelevancyTableCellEdit.defaultProps = {
  size: 'medium'
};

export default EntityTeamRelevancyTableCellEdit;


