import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledStatusForm = styled(Card)`
  width: 100%;
  padding: ${props => props.theme.spacing(1.5)};
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .StatusForm {
    &-form {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1.5sp')};
    }
    
    &-header {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      align-items: flex-start;
      
      .Form-fields {
        flex-grow: 1;
        flex-wrap: nowrap;
      }
    }

    &-actions {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledStatusForm.propTypes = {
  theme: PropTypes.object
};

StyledStatusForm.defaultProps = {}

export default StyledStatusForm;
