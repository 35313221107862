import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListHeader from 'components/molecules/Headers/ListHeader/ListHeader.styles';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const ListHeader = React.forwardRef((props, ref) => {
  const {
    title,
    columns,
    ...innerProps
  } = useComponentProps(props, 'ListHeader', {
    children: ['title', 'columns']
  });

  return <StyledListHeader ref={ref} {...innerProps}>
    {title ? <Box className="ListHeader-title">{utils.isReactElement(title) ? title : <H6>{title}</H6>}</Box> : null}
    {columns && columns?.length > 0 ? <Box className="ListHeader-columns">
      {columns?.map((column, idx) => {
        return <Typography variant="tableHeader"
                           key={idx}>{column.header}</Typography>
      })}
    </Box> : null}
  </StyledListHeader>
});

ListHeader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  resultsCount: PropTypes.number,
  columnTitles: PropTypes.array
};

ListHeader.defaultProps = {
};

export default ListHeader;
