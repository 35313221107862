import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledTagForm = styled(Card)`
  width: 100%;
  padding: ${props => props.theme.spacing(1.5)};
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .TagForm {
    &-form {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1.5sp')};
    }
    
    &-header {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      align-items: flex-start;
      
      .Form-fields {
        flex-grow: 1;
        flex-wrap: nowrap;
      }
      .FormField-name-value {
        flex-grow: 1;
        max-width: ${props => props.theme.layout(334)};
      }
      .FormField-name-points {
        min-width: ${props => props.theme.layout(114)};
        max-width: ${props => props.theme.layout(114)};
        .FormHelperText {
          white-space: nowrap;
        }
      }
    }

    &-actions {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledTagForm.propTypes = {
  theme: PropTypes.object
};

StyledTagForm.defaultProps = {}

export default StyledTagForm;
