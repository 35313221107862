import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledButtonGroupTableCell = styled(BaseTableCell)`
  ${ComponentStyles}
`;

StyledButtonGroupTableCell.propTypes = {
  theme: PropTypes.object
};

StyledButtonGroupTableCell.defaultProps = {
}

export default StyledButtonGroupTableCell;
