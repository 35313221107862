import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSimpleLoader from 'components/molecules/Loaders/SimpleLoader/SimpleLoader.styles';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import Typography from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';

const SimpleLoader = (props) => {
  const {
    title,
    progressProps,
    ...innerProps
  } = useComponentProps(props, 'SimpleLoader', {children: ['progress', 'title']});

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSimpleLoader {...innerProps}>
    <CircularProgress className="SimpleLoader-progress" {...progressProps}></CircularProgress>
    {title ? <Typography className="SimpleLoader-title">{title}</Typography> : null}
  </StyledSimpleLoader>
};

SimpleLoader.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  progressProps: PropTypes.object
};

SimpleLoader.defaultProps = {
};

export default SimpleLoader;
