import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6, P} from 'components/atoms/Text/Typography/Typography';
import StyledCreditWalletGraphCard from 'components/organisms/Cards/CreditWalletGraphCard/CreditWalletGraphCard.styles';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import utils from 'helpers/utils';
import {useClientStatisticCreditDistribution} from 'services/client/statistic/statistic.utils';
import CreditWalletPieChart from 'components/organisms/Charts/CreditWalletPieChart/CreditWalletPieChart';
import constants from 'helpers/constants';

const CreditWalletGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CreditWalletGraphCard');

  const [usage, setUsage] = useState(null);

  const client = useAuthClient();
  const [creditDistribution, isLoading] = useClientStatisticCreditDistribution(1);

  useEffect(() => {
    if (creditDistribution) {
      const creditsUsed = Math.min(+client?.props?.monthlyCreditLimit,
        Math.ceil(creditDistribution?.reduce((t, cd) => t + +cd.count, 0)));

      setUsage({
        total: +client?.props?.monthlyCreditLimit,
        used: creditsUsed
      });
    }
  }, [creditDistribution, client]);

  const renderDescription = () => {
    let nextRenew = (new Date());
    nextRenew.setMonth(nextRenew.getMonth() + 1);
    nextRenew = new Date(`${nextRenew.toISOString().split('-').slice(0, 2).join('-')}-01T00:00:00.000Z`);

    const plan = constants.data.lookup('plans', client?.props?.plan);

    return <Box className="CreditWalletGraphCard-description">
      <H6 isLoading={isLoading}>{utils.formatNumber((+usage?.total ?? 0) - (+usage?.used ?? 0))} of {utils.formatNumber(+usage?.total ?? 0)} credits left</H6>
      <Typography variant="body2" isLoading={isLoading}>On {utils.dayjs(nextRenew).format('MMM D')}, you will receive {utils.formatNumber(+client.props?.monthlyCreditLimit)} new credits in accordance with your {plan.label} subscription</Typography>
    </Box>
  }

  return <StyledCreditWalletGraphCard ref={ref} {...innerProps}
                                      title="My wallet">
    {(utils.isDefined(usage) || isLoading) ?
      <Box className="CreditWalletGraphCard-chart">
        <CreditWalletPieChart total={usage?.total ?? 0}
                              used={usage?.used ?? 0}
                              showTotal={true}
                              isLoading={isLoading}
                              PieChartProps={{
                                square: false,
                                PieProps: {
                                  innerRadius: '82%'
                                }
                              }} />
        {renderDescription()}
      </Box> : null}
    {(!utils.isDefined(usage) && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No credit data found</P>
      </Box> : null}
  </StyledCreditWalletGraphCard>
});

CreditWalletGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CreditWalletGraphCard.defaultProps = {
};

export default CreditWalletGraphCard;
