import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: ${props => props.theme.radius(2)};

  &.ToggleButtonGroup > .ToggleButton, > * > .ToggleButton {
    border: 1px solid ${props => props.theme.property('palette.divider')};
    border-radius: 0;
    margin-left: 0 !important;
    border-left-width: 0.5px !important;
    border-right-width: 0.5px !important;
  }
  > .ToggleButton:first-of-type, > *:first-of-type > .ToggleButton {
    border-radius: ${props => props.theme.radius(2)} 0 0 ${props => props.theme.radius(2)};
    border-left-width: 1px !important;
  }
  > .ToggleButton:last-of-type, > *:last-of-type > .ToggleButton {
    border-radius: 0 ${props => props.theme.radius(2)} ${props => props.theme.radius(2)} 0;
    border-right-width: 1px !important;
  }

  &.ToggleButtonGroup-radius-square {
    border-radius: ${props => props.theme.radius(1)};
    
    > .ToggleButton:first-of-type, > *:first-of-type > .ToggleButton {
      border-radius: ${props => props.theme.radius(1)} 0 0 ${props => props.theme.radius(1)};
    }
    > .ToggleButton:last-of-type, > *:last-of-type > .ToggleButton {
      border-radius: 0 ${props => props.theme.radius(1)} ${props => props.theme.radius(1)} 0;
    }
  }

  ${ComponentStyles}
`;

StyledToggleButtonGroup.propTypes = {
};

StyledToggleButtonGroup.defaultProps = {
}

export default StyledToggleButtonGroup;
