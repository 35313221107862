import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import {AreaChart} from 'recharts';
import ChartStyles from 'styles/mixins/Chart.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const StyledAreaChart = styled(AreaChart)`
  display: flex;

  &.AreaChart {
    &-isLoading {
      .AreaChart-area {
        pointer-events: none;
        .recharts-area {
          &-curve {
            stroke-opacity: 0.2;
          }
          &-area {
            fill-opacity: 0.04;
          }
        }
      }
    }
    
    .AreaChart-area {
      .recharts-area {
        &-area {
          fill-opacity: 0.08;
        }
      }
      &.hover {
        .recharts-area {
          &-curve {
            filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 0.2));
          }
        }
      }
      
      &.notHover {
        .recharts-area {
          &-curve {
            stroke-opacity: 0.3;
          }

          &-area {
            fill-opacity: 0.04;
          }
        }
      }
    }
  }
  
  .recharts-tooltip-cursor {
    fill: ${props => props.theme.state2Color('primary', 'focusVisible',
            constants.colorTypes.accentBackground, constants.colorTargets.background, 0.5)};
  }

  .recharts-label-list {
    pointer-events: none;
  }
  
  .recharts-cartesian {
    &-axis {
      &-tick-value {
        ${props => props.theme.font2Css('caption')};
        fill: ${props => props.theme.property('palette.text.primary')};
      }

      &-line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
    
    &-grid {
      line {
        stroke: ${props => utils.rgba2Rgb(props.theme.property('palette.divider'))};
      }
    }
  }
  
  ${ChartStyles}
  ${ComponentStyles}
`;

StyledAreaChart.propTypes = {
};

StyledAreaChart.defaultProps = {
}

export default StyledAreaChart;
