import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import EntitiesList from 'components/organisms/Lists/EntitiesList/EntitiesList';

const StyledDealflowEntitiesList = styled(EntitiesList)`
  ${ComponentStyles}
`;

StyledDealflowEntitiesList.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesList.defaultProps = {
};

export default StyledDealflowEntitiesList;
