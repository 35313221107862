import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import {Span} from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';

const StyledMention = styled(Span)`
  outline: none;
  position: relative;
  background-color: ${props => props.theme.state2Color(
          props.$color, 'focusVisible', constants.colorTypes.accentBackground, constants.colorTargets.background)};

  &.Mention-readOnly {
    pointer-events: all;
    background-color: unset !important;
    color: ${props => props.theme.state2Color(
            props.$color, 'default', constants.colorTypes.base, constants.colorTargets.background)};
    font-weight: 700;

    ${props => props.theme.state2Selector('Mention', null, 'disabled')} {
      color: ${props => props.theme.property('palette.action.disabled')};
      cursor: default;
      pointer-events: none;
    }
  }

  &:not(.Mention-readOnly) {
    &.Mention-active {
      cursor: default;
    }
  }
  
  &.Mention-hoverable {
    &:hover {
      cursor: default;
    }
  }
  
  &.Mention-clickable {
    cursor: pointer;
    pointer-events: all;

    ${props => props.theme.state2Selector('Mention', null, 'focus')} {
      text-decoration: underline;
    }
  }
  
  ${ComponentStyles}
`;

StyledMention.propTypes = {
  theme: PropTypes.object
};

StyledMention.defaultProps = {
}

export default StyledMention;
