import styled from 'styled-components';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledDivider = styled(Divider)`
  &.Divider-fullWidth {
    width: 100%;
  }
  
  ${ComponentStyles}
`;

StyledDivider.propTypes = {
  theme: PropTypes.object
};

StyledDivider.defaultProps = {
}

export default StyledDivider;
