import * as yup from 'yup';
import utils from 'helpers/utils';
import {isValidPhoneNumber} from 'libphonenumber-js'
import constants from 'helpers/constants';
import searchUtils from 'helpers/search';

function file (message, types) {
  return this.test({
    name: 'file',
    message,
    params: { types: utils.toArray(types)?.join(`, `) },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((f) => !types || utils.matchFileType(f.name, f.type, utils.toArray(types)))
          .length;
      }
    }
  });
}

function fileSize (message, minSize, maxSize) {
  minSize = minSize ?? 1;
  maxSize = maxSize ?? constants.numbers.GB;

  return this.test({
    name: 'fileSize',
    message,
    params: {minSize: utils.bytesToFileSize(minSize), maxSize: utils.bytesToFileSize(maxSize)},
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((f) => (!minSize || f.size >= minSize) &&
            (!maxSize || f.size <= maxSize))
          .length;
      }
    }
  });
}

function tag (message) {
  return this.test({
    name: 'tag',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((t) => {
            return !(/[^A-Za-z0-9_\- ]+/.test(t?.label?.toString?.() ?? t))
          })
          .length;
      }
    }
  });
}

function collection (message) {
  return this.test({
    name: 'collection',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        //value = utils.toArray(value);

        // no actual checks yet
        return true;
      }
    }
  });
}

function status (message) {
  return this.test({
    name: 'status',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function component (message) {
  return this.test({
    name: 'component',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return utils.isObject(value) && !value.errors;
      }
    }
  });
}

function query (message) {
  return this.test({
    name: 'query',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return searchUtils.queryFilterCount(value) > 0;
      }
    }
  });
}

function unique (message) {
  return this.test({
    name: 'unique',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === utils.uniqueArray(value).length &&
          value.length === utils.uniqueArray(value, 'value', true).length &&
          value.length === utils.uniqueArray(value, 'label', true).length;
      }
    }
  });
}

function truthy (message) {
  return this.test({
    name: 'truthy',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return value === true;
      }
    }
  });
}

function country (message) {
  return this.test({
    name: 'country',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return Boolean(constants.data.lookup('countries', value));
      }
    }
  });
}

function phone (message) {
  return this.test({
    name: 'phone',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return isValidPhoneNumber(value);
      }
    }
  });
}

function username (message) {
  return this.test({
    name: 'username',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return !(/[^A-Za-z0-9_]+/.test(value?.toString?.()));
      }
    }
  });
}

function number (message) {
  return this.test({
    name: 'number',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return utils.isNumber(value);
      }
    }
  });
}

function year (message) {
  return this.test({
    name: 'year',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        const d = new Date(`${utils.fillYear(value)}-01-01T00:00:00.000Z`);
        return utils.fillYear(value).toString().length === 4 && utils.isDate(d);
      }
    }
  });
}

// array
yup.addMethod(yup.mixed, 'file', file);
yup.addMethod(yup.mixed, 'fileSize', fileSize);
yup.addMethod(yup.mixed, 'year', year);
yup.addMethod(yup.mixed, 'tag', tag);
yup.addMethod(yup.mixed, 'collection', collection);
yup.addMethod(yup.mixed, 'status', status);
yup.addMethod(yup.mixed, 'query', query);

yup.addMethod(yup.array, 'unique', unique);
yup.addMethod(yup.object, 'component', component);

yup.addMethod(yup.bool, 'truthy', truthy);

yup.addMethod(yup.string, 'country', country);
yup.addMethod(yup.string, 'phone', phone);
yup.addMethod(yup.string, 'username', username);
yup.addMethod(yup.string, 'number', number);

const validation = yup;

export default validation;
