import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledAnalysisScoreTableCell
  from 'components/molecules/TableCells/AnalysisScoreTableCell/AnalysisScoreTableCell.styles';
import {useCollectionEntityScoreBreakDown} from 'services/collection/entity/entity.utils';
import AnalysisScoreBarChart from 'components/organisms/Charts/AnalysisScoreBarChart/AnalysisScoreBarChart';
import {withMemo} from 'helpers/wrapper';

const AnalysisScoreTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    onClick,
    onHover,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreTableCell');

  const [analysisBreakdown, total] = useCollectionEntityScoreBreakDown(collection, entity);

  return <StyledAnalysisScoreTableCell ref={ref} {...innerProps}>
    <Box className="AnalysisScoreTableCell-bar">
      <AnalysisScoreBarChart total={total}
                             breakdown={analysisBreakdown}
                             onClick={onClick}
                             onHover={onHover}
                             animate={false}
                             isLoading={isLoading}/>
    </Box>
  </StyledAnalysisScoreTableCell>
}));

AnalysisScoreTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  isLoading: PropTypes.bool
};

AnalysisScoreTableCell.defaultProps = {
};

export default AnalysisScoreTableCell;


