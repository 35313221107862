import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';

const StyledActionListItem = styled(ListItem)`
  .ActionListItem {
    &-chip {
      margin-right: ${props => props.theme.spacing(2)};
      margin-left: ${props => props.theme.spacing(2)};
    }
  }
  ${ComponentStyles}
`;

StyledActionListItem.propTypes = {
  theme: PropTypes.object
};

StyledActionListItem.defaultProps = {
}

export default StyledActionListItem;
