import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityBrowserPage
  from 'components/pages/Collection/Entity/CollectionEntityBrowserPage/CollectionEntityBrowserPage.styles';
import EntitiesBrowserList from 'components/organisms/Browsers/EntitiesBrowserList/EntitiesBrowserList';

const CollectionEntityBrowserPage = (props) => {
  const innerProps = useComponentProps(props, 'CollectionEntityBrowserPage');

  return <StyledCollectionEntityBrowserPage {...innerProps}>
    <EntitiesBrowserList />
  </StyledCollectionEntityBrowserPage>
};

CollectionEntityBrowserPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntityBrowserPage.defaultProps = {
};

export default CollectionEntityBrowserPage;
