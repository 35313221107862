import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Profile from 'components/organisms/Profiles/Profile/Profile';

const StyledClientProfile = styled(Profile)`
  ${ComponentStyles}
`;

StyledClientProfile.propTypes = {
  theme: PropTypes.object
};

StyledClientProfile.defaultProps = {
};

export default StyledClientProfile;
