import themeLight from 'theme/light/theme';
import utils from 'helpers/utils'
import {getPalette, themeFactory} from 'theme/base/base';
import constants from 'helpers/constants';

const palette = getPalette(constants.palettes.dark);

const themeOverrides = {
  name: 'dark',
  palette: {
    mode: 'dark',
    ...palette
  },
  components: {
    // Button: {
    //   styleOverrides: {
    //     root: {
    //     },
    //     schemaBlue: {
    //       backgroundColor: (theme) => theme.property('palette.blue.A400'),
    //       border: (theme) => `2px solid ${theme.property('palette.blue.A700')}`
    //     },
    //     sizeLarge: {
    //       fontSize: (theme) => theme.fontSize(32)
    //     },
    //     sizeMedium: {
    //       backgroundColor: 'red'
    //     }
    //   }
    // },
  },
};

const themeDark = themeFactory(utils.mergeObjects(themeLight.overrides, themeOverrides, true));

export default themeDark;

