import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDealflowEntitiesKanbanPage = styled(Page)`
  background-color: ${props => props.theme.property('palette.primary.states.hover')};

  padding: ${props => props.theme.spacing(3)};
  
  ${ComponentStyles}
`;

StyledDealflowEntitiesKanbanPage.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesKanbanPage.defaultProps = {
};

export default StyledDealflowEntitiesKanbanPage;
