import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import React, {useMemo} from 'react';
import StyledBannerWrapper from 'components/templates/Wrappers/Banners/BannerWrapper/BannerWrapper.styles';
import Wrapper, {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';

const BannerWrapper = React.forwardRef((props, ref) => {
  const {
    banner,
    ...innerProps
  } = useComponentProps(props, 'BannerWrapper', {
    children: ['banner', 'content']
  });

  const parentWrapper = useWrapper();

  const width = innerProps.theme.layout(440);
  const wrapper = useMemo(() => {
    return {
      bounds: { left: width },
      scrollBounds: {left: width}
    }
  }, [width]);

  return <StyledBannerWrapper ref={ref} {...innerProps}
                              $width={width}
                              $wrapper={parentWrapper}>
    <Box className="BannerWrapper-banner">
      {banner}
    </Box>
    <Wrapper className="BannerWrapper-content" wrapper={wrapper}>
      {innerProps.children}
    </Wrapper>
  </StyledBannerWrapper>;
});

export default BannerWrapper;
