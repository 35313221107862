import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDatabaseEntitiesTableBar
  from 'components/organisms/Bars/DatabaseEntitiesTableBar/DatabaseEntitiesTableBar.styles';
import {useSplitPath} from 'helpers/hooks/links';

const DatabaseEntitiesTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTableBar');

  const [, pathPostfix] = useSplitPath('database');

  const columnAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'database.entity.columns' }),
  }), []);

  const saveSearchAction = null;
  // todo: for advanced
  // useMemo(() => ({
  //   label: 'Save search',
  //   auth: utils.createAuth({ attribute: 'database.entity.search.create' }),
  // }), []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDatabaseEntitiesTableBar ref={ref} {...innerProps}
                                         rightFields={[]}
                                         columnAction={columnAction}
                                         saveAction={saveSearchAction}
                                         hideColumn={Boolean(pathPostfix)}
                                         hideFilter={true}
                                         hideSave={true} />
});

DatabaseEntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTableBar.defaultProps = {
};

export default DatabaseEntitiesTableBar;
