import React, {useMemo} from 'react'
import utils from 'helpers/utils';
import DealflowEntitiesGraphsPage
  from 'components/pages/Dealflow/Entity/Graph/DealflowEntitiesGraphsPage/DealflowEntitiesGraphsPage';

export default function useDealflowEntitiesGraphsRoutes () {
  return useMemo(() => {
    const route = [
      {
        path: '',
        element: <DealflowEntitiesGraphsPage />,

        handle: {
          title: 'Dealflow - graphs',
          auth: utils.createAuth({ attribute: 'dealflow.entity.graph.list' })
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, []);
};
