import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowBarChart from 'components/organisms/Charts/DealflowBarChart/DealflowBarChart.styles';
import DealflowTooltip from 'components/molecules/Tooltips/DealflowTooltip/DealflowTooltip';
import {useClientDealflowInGroups} from 'services/client/client.utils';

const DealflowBarChart = React.forwardRef((props, ref) => {
  const {
    total,
    dealflow,
    showNoDealflow,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'DealflowBarChart');

  const groups = useClientDealflowInGroups(dealflow, total, showNoDealflow);

  const dealflowData = useMemo(() => {
    return {
      dataKey: 'name',
      layout: 'vertical',
      bars: groups
        .map((g) => {
          return {
            position: g.position,
            dataKey: g.groupId,
            name: g.groupId,
            label: g.label,
            color: utils.deprecatedColor(g.color)
          }
        }),
      data: [groups
        .reduce((o, g) => {
          o[g.groupId] = g.relative;
          o[`${g.groupId}-meta`] = g;
          return o;
        }, {})],
      XAxisProps: {
        allowDecimals: true
      },
      YAxisProps: {
        allowDecimals: true
      },
      TooltipComponent: DealflowTooltip
    };
  }, [groups]);

  innerProps.TooltipComponent = DealflowTooltip;
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowBarChart ref={ref} {...innerProps}
                                 {...dealflowData}
                                 radius={8}
                                 gap={1}
                                 stackOffset='expand'
                                 hideAxis={true}
                                 TooltipProps={{
                                   variant: 'minimal'
                                 }}/>
});

DealflowBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  total: PropTypes.number,
  showNoDealflow: PropTypes.bool
};

DealflowBarChart.defaultProps = {
  showNoDealflow: true
};

export default DealflowBarChart;
