import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogTitle from '@mui/material/DialogTitle';

const StyledDialogTitle = styled(DialogTitle)` 
  ${ComponentStyles}
`;

StyledDialogTitle.propTypes = {
  theme: PropTypes.object
};

StyledDialogTitle.defaultProps = {}

export default StyledDialogTitle;
