import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledTaskProfileSectionPanelContent = styled(ListWrapper)`
  .ListWrapper-content {
    overflow-y: auto;
  }
  
  ${ComponentStyles}
`;

StyledTaskProfileSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledTaskProfileSectionPanelContent.defaultProps = {}

export default StyledTaskProfileSectionPanelContent;
