import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledToolbarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  min-width: 100%;

  ${props => props.$dialog ? css`
    width: 100%; 
  ` : css``}
  
  > .ToolbarWrapper {
    &-toolbar {
      top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
      left: ${props => props.$wrapper?.boundsCum?.left ?? 0};
      overflow: hidden;

      ${props => !props.$dialog ? css`
        position: fixed;
        width: calc(100vw - (${props.$wrapper?.boundsCum?.left ?? '0px'} + ${props.$wrapper?.boundsCum?.right ?? '0px'}));
      ` : css`
        position: absolute;
        width: 100%;
      `}
    }
    
    &-content {
      height: 100%;
      width: fit-content;
      min-width: 100%;
      align-self: center;

      ${props => props.$dialog ? css`
        flex-grow: 1;
        height: 1px; 
        width: 100%
      ` : css``}
    }
  }

  &.ToolbarWrapper-anchor-bottom {
    > .ToolbarWrapper {
      &-toolbar {
        flex-direction: column-reverse;
        
        top: unset;
        bottom: ${props => props.$wrapper?.boundsCum?.bottom ?? 0};
        
        border-bottom: unset;
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
  }

  &.ToolbarWrapper-sticky {
    > .ToolbarWrapper {
      &-toolbar {
        position: sticky;
        top: ${props => props.$wrapper?.boundsCum?.top ?? 0};
      }
    }

    &.ToolbarWrapper-anchor-bottom {
      > .ToolbarWrapper {
        &-toolbar {
          top: unset;
          bottom: ${props => props.$wrapper?.boundsCum?.bottom ?? 0};
        }
      }
    }
  }

  &.ToolbarWrapper-inset {
    > .ToolbarWrapper {
      &-toolbar {
        position: sticky;
        z-index: 0 !important;
        top: unset;
        bottom: unset;
      }
    }
  }

  &.ToolbarWrapper-collapse {
    > .ToolbarWrapper {
      &-toolbar {
        position: sticky;
        top: unset;
        bottom: unset;
        z-index: 0 !important;
      }  
    }
  }
  
  ${ComponentStyles}
`;

StyledToolbarWrapper.propTypes = {
  theme: PropTypes.object
};

StyledToolbarWrapper.defaultProps = {
};

export default StyledToolbarWrapper;
