import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledListItemIcon from 'components/atoms/Icons/ListItemIcon/ListItemIcon.styles';
import {withMemo} from 'helpers/wrapper';

const ListItemIcon = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ListItemIcon');

  return <StyledListItemIcon ref={ref} {...innerProps}>{innerProps.children}</StyledListItemIcon>
}));

ListItemIcon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ListItemIcon.defaultProps = {
  children: 'ListItemIcon text'
};

export default ListItemIcon;
