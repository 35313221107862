import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSourceUploadSectionPanelContent
  from 'components/organisms/SectionPanels/SourceUploadSectionPanelContent/SourceUploadSectionPanelContent.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import {useCollectionUploadCreate} from 'services/collection/upload/upload.utils';

const SourceUploadSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    collection,
    isNewCollection,
    onSubmit,
    onDirty,
    onDialog,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'SourceUploadSectionPanelContent');

  const formRef = useRef(null);

  const createUpload = useCollectionUploadCreate();

  const [internalState, setInternalState] = useState({
    file: null,
    showEntitiesUploadDialog: false,
  });

  const fields = useMemo(() => {
    return [{
      name: 'file',
      label: 'File',
      entity: 'source',
      type: constants.formFieldTypes.file,
      validation: constants.formFieldValidationTypes.file,
      conversion: constants.formFieldConversionTypes.none,
      initial: internalState.file,
      ...utils.mergeObjects({
        FormFieldProps: {
          hiddenLabel: true,
          multiple: false,
          addDescription: false,
          fileDescription: 'Excel, Csv, Json or Pdf document',
          types: ['xls', 'xlsx', 'csv', 'json', 'pdf']
        }
      })
    }]
  }, [internalState.file]);

  const doClose = () => {
    onDialog?.(false);
    setInternalState(utils.updater({
      showEntitiesUploadDialog: false,
      file: null
    }, true));
    onDirty?.(false);
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;
    onDirty?.(true);

    if (field && field.name === 'file' && value !== null) {
      onDialog?.(true);
      setInternalState(utils.updater({
        file: value,
        showEntitiesUploadDialog: true
      }, true));
    }
  };

  const renderEntitiesUploadDialog = () => {
    if (internalState.showEntitiesUploadDialog) {
      const handleSubmit = (upload) => {
        if (!isNewCollection) {
          return createUpload({
            name: upload.name,
            params: upload.params,
            period: upload.period,
            subType: '',
            type: upload.type
          }, collection).then(() => {
            doClose();
          });
        } else {
          onSubmit?.(upload);
          doClose();
        }
      }

      const handleClose = (e) => {
        doClose();
        e.preventDefault()
      };

      return <EntitiesUploadDialog open={true}
                                   onSubmit={handleSubmit}
                                   onClose={handleClose}
                                   EntitiesUploadWizardProps={{
                                     collection,
                                     file: internalState.file
                                   }}/>
    }
  };

  return <StyledSourceUploadSectionPanelContent ref={ref} {...innerProps}>
    <InlineForm ref={formRef}
                fields={fields}
                onChangeDirect={handleChangeDirect} />
    {renderEntitiesUploadDialog()}
  </StyledSourceUploadSectionPanelContent>
});

SourceUploadSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  isNewCollection: PropTypes.bool,
  onCanUpdate: PropTypes.func,
  onDialog: PropTypes.func,
  onSubmit: PropTypes.func,
  onDirty: PropTypes.func
};

SourceUploadSectionPanelContent.defaultProps = {};

export default SourceUploadSectionPanelContent;
